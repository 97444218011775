<template>
  <tbody>
    <tr v-if="!isStartFlrVoting && stage == 3 && !isEditMode && isActive && user.groupId < 7">
      <td colspan="3">
        <button class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addNew"> <i class="mdi mdi-play-box-outline"></i> Start FLR Voting</button>
      </td>
    </tr>
    <tr v-if="canStop && isStartFlrVoting && !isFlrFinalized && stage == 3 && !isEditMode && isActive && user.groupId < 7">
      <td colspan="3">
        <button class="btn btn-icon waves-effect waves-light btn-warning" @click.prevent="cancelVote"> <i class="mdi mdi-stop-circle"></i> Pause FLR Voting</button>
      </td>
    </tr>
    <tr v-if="!isStartRrVoting && stage == 4 && !isEditMode && isActive && user.groupId < 7">
      <td colspan="3">
        <button class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addNew2"> <i class="mdi mdi-play-box-outline"></i> Start RR Voting</button>
      </td>
    </tr>
    <tr v-if="canStop && isStartRrVoting && !isRrFinalized && stage == 4 && !isEditMode && isActive && user.groupId < 7">
      <td colspan="3">
        <button class="btn btn-icon waves-effect waves-light btn-warning" @click.prevent="cancelVote2"> <i class="mdi mdi-stop-circle"></i> Pause RR Voting</button>
      </td>
    </tr>
    <tr v-if="!votes && (isStartFlrVoting || isStartRrVoting)">
      <td colspan="3" class="text-center">No Votes to show</td>
    </tr>
    <tr v-if="!votes && !isActive">
      <td colspan="3" class="text-center">Deal is inactive</td>
    </tr>
    <tr v-if="!votes && !(isStartFlrVoting || isStartRrVoting) && stage < 3 && !isEditMode">
      <td colspan="3" class="text-center">No Vote in progress</td>
    </tr>
    <tr>
      <td colspan="3">
          <router-link :to="{name: 'FlrSummary', params: {id: fundId}}" class="btn btn-primary btn-icon mr-1" v-if="isStartFlrVoting">
              <i class="mdi mdi-thought-bubble"></i> FLR Summary
          </router-link>
          <router-link :to="{name: 'RrSummary', params: {id: fundId}}" class="btn btn-primary btn-icon" v-if="isStartRrVoting">
              <i class="mdi mdi-thought-bubble-outline"></i> RR Summary
          </router-link>
      </td>
    </tr>
    <tr v-for="(vote, index) in votes"
        :key="`flr` + index">
        <td v-if="vote.score < 100">
          <a @click.prevent="select(vote.votingId)" href="javascript:void(0)">
          <AppUserPic :userId="vote.createdBy" :size="2" />
          <AppUser :userId="vote.createdBy" :show="'text'" :showpic="false"/></a>
        </td>
        <td v-if="vote.score < 100">
          <span v-tooltip="vote.isShowFLRVotes ? '' : 'Votes are hidden'">
            {{vote.isShowFLRVotes ? vote.score : '?'}}
          </span>
        </td>
        <td v-if="vote.score < 100" class="action-col1">
            <button v-if="vote.createdBy === user.userId && !isFlrFinalized" class="btn btn-success btn-sm btn-mini btn-icon" @click.prevent="update(vote.votingId)"><i class="mdi mdi-lead-pencil"></i></button>
        </td>
    </tr>
    <tr v-for="(vote, index) in votes"
        :key="`rr` + index" class="rr-vote-row">
        <td v-if="vote.score > 99">
          <a @click.prevent="select2(vote.votingId)" href="javascript:void(0)">
          <AppUserPic :userId="vote.createdBy" :size="2" />
          <AppUser :userId="vote.createdBy" :show="'text'" :showpic="false"/></a>
        </td>
        <td v-if="vote.score > 99">
          <span v-if="vote.isShowFLRVotes">
            {{vote.score == 111 ? 'Yes' : vote.score == 100 ? 'No' : '-' }}
          </span>
          <span v-else v-tooltip="'Votes are hidden'">
            ?
          </span>
        </td>
        <td v-if="vote.score > 99" class="action-col1">
            <button v-if="vote.createdBy === user.userId && !isRrFinalized" class="btn btn-success btn-sm btn-mini btn-icon" @click.prevent="update2(vote.votingId)"><i class="mdi mdi-lead-pencil"></i></button>
        </td>
    </tr>
  </tbody>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    votes: {
      type: Array,
      default: []
    },
    stage: {
      type: Number,
      required: true,
      default: 0
    },
    fundId: {
      type: Number,
      required: true,
      default: 0
    },
    isEditMode: {
      required: true,
      type: Boolean,
      default: false
    },
    isStartFlrVoting: {
      required: true,
      type: Boolean,
      default: false
    },
    isStartRrVoting: {
      required: true,
      type: Boolean,
      default: false
    },
    isFlrFinalized: {
      required: true,
      type: Boolean,
      default: false
    },
    isRrFinalized: {
      required: true,
      type: Boolean,
      default: false
    },
    isActive: {
      required: true,
      type: Boolean,
      default: false
    },
    canStop: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['deleteFundFlrVotings', 'deleteFundRrVotings']),
    addNew () {
      this.hideRightbar()
      this.$emit('onClickNew')
    },
    cancelVote () {
      this.$emit('onCancelVote')
    },
    addNew2 () {
      this.hideRightbar()
      this.$emit('onClickNew2')
    },
    cancelVote2 () {
      this.$emit('onCancelVote2')
    },
    select (id) {
      this.$emit('onSelect', id)
    },
    select2 (id) {
      this.$emit('onSelect2', id)
    },
    update (id) {
      this.$emit('onSelectUpdate', id)
    },
    update2 (id) {
      this.$emit('onSelectUpdate2', id)
    },
    onDelete (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deleteFundFlrVotings({votingId: id})
                .then((response) => {
                  this.$snotify.success('Vote Deleted.')
                  this.$emit('onUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    onDelete2 (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deleteFundRrVotings({votingId: id})
                .then((response) => {
                  this.$snotify.success('Vote Deleted.')
                  this.$emit('onUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  }
}
</script>
