<template>
  <select class="form-control" :id="id" @input="event => { $emit('input', parseInt(event.target.value)) }">
      <option value=0>0</option>
      <option value=5 :selected="selectedOption == 5">0.5</option>
      <option value=10 :selected="selectedOption == 10">1</option>
      <option value=15 :selected="selectedOption == 15">1.5</option>
      <option value=20 :selected="selectedOption == 20">2</option>
      <option value=25 :selected="selectedOption == 25">2.5</option>
      <option value=30 :selected="selectedOption == 30">3</option>
      <option value=35 :selected="selectedOption == 35">3.5</option>
      <option value=40 :selected="selectedOption == 40">4</option>
      <option value=45 :selected="selectedOption == 45">4.5</option>
      <option value=50 :selected="selectedOption == 50">5</option>
  </select>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: Number
    },
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      selectedOption: null
    }
  },
  mounted () {
    this.selectedOption = this.value
  },
  watch: {
    value: function (newValue) {
      this.selectedOption = parseInt(newValue)
    }
  }
}
</script>
