<template>
  <a href="javascript:void(0)" class="waves-effect waves-light" :class="{'user-pic' : size > 1, 'nav-user' : size === 1 }">
    <img :src="pic" v-tooltip="user" class="rounded-circle" :class="{'img-thumbnail' : size === 0 }">
  </a>
</template>

<script>
export default {
  props: {
    userId: {
      required: false,
      type: Number,
      default: null
    },
    size: {
      required: true,
      type: Number
    }
  },
  computed: {
    pic () {
      if (this.userId === 0) {
        return require(`/static/img/users/system.png`)
      }
      const usr = this.$store.state.allUsers.find(e => e.userId === this.userId)
      
      try {
        if (usr != null) {
        return require(`/static/img/users/${usr.userName.toLowerCase()}.jpg`)
        }
      } catch {
        return require(`/static/img/users/user.jpg`)
      }

      
      return require(`/static/img/users/user.jpg`)
    },
    user () {
      const usr = this.$store.state.allUsers.find(e => e.userId === this.userId)
      if (usr != null) {
        return usr.firstName + ' ' + usr.lastName
      }
      return ''
    }
  }
}
</script>
