import Constants from '../constants.js'
import Axios from 'axios'

export const DashService = {
  getUserDeals () {
    return Axios.get(Constants.API_URL + '/fund/user-dashdeals/')
  },
  getActiveVotes () {
    return Axios.get(Constants.API_URL + '/dashvote/')
  },
  getUserTasks () {
    return Axios.get(Constants.API_URL + '/firmoutreachtasks/mytasks/')
  }
}
