<template>
  <div>
    <Topbar/>
    <Sidebar :activeItem="'ops-add'"/>

    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid">
          <PageTitle :title="'Ops Workflow'" :icon="'mdi mdi-opera'" />
          <div class="card-box">
            <h4 class="header-title m-t-0">Submit a request to Ops</h4>
            <p class="text-muted font-14 m-b-20">Please fill the form.</p>

            <form action="#" autocomplete="off" @submit.prevent="save">
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <div class="form-group">
                      <label for="typeId">Type <span class="text-danger">*</span></label>
                      <span :class="{ 'parsley-error-radio': $v.opsRequest.typeId.$error }">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="type1" :value="1" v-model="opsRequest.typeId" name="type" class="custom-control-input">
                          <label class="custom-control-label" for="type1">Internal</label>
                        </div>
                        <div class="custom-control custom-radio" v-if="isOpsTeam">
                          <input type="radio" id="type2" :value="2" v-model="opsRequest.typeId" name="type" class="custom-control-input">
                          <label class="custom-control-label" for="type2">External</label>
                        </div>
                      </span>
                      <template v-if="$v.opsRequest.typeId.$error">
                        <ul class="parsley-errors-list filled">
                          <li v-if="!$v.opsRequest.typeId.required" class="parsley-required">This value is required</li>
                        </ul>
                      </template>
                  </div>

                  <div class="form-group">
                      <label>Category <span class="text-danger">*</span></label>
                      <span :class="{ 'parsley-error-radio': $v.opsRequest.categoryId.$error }">
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 1">
                          <input type="radio" id="category1" :value="1" v-model="opsRequest.categoryId" name="category" class="custom-control-input" :disabled="true">
                          <label class="custom-control-label" for="category1">ODD</label>
                        </div>
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 1">
                          <input type="radio" id="category2" :value="2" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category2">Data</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="category3" :value="3" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category3">Other</label>
                        </div>
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 2">
                          <input type="radio" id="category4" :value="4" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category4">Investor (LP)/Client</label>
                        </div>
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 2">
                          <input type="radio" id="category5" :value="5" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category5">Investment</label>
                        </div>
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 2">
                          <input type="radio" id="category6" :value="6" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category6">Service provider</label>
                        </div>
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 2">
                          <input type="radio" id="category7" :value="7" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category7">AML/KYC/CDD</label>
                        </div>
                      </span>
                      <template v-if="$v.opsRequest.categoryId.$error">
                        <ul class="parsley-errors-list filled">
                          <li v-if="!$v.opsRequest.categoryId.required" class="parsley-required">This value is required</li>
                        </ul>
                      </template>
                  </div>

                  <div class="form-group" v-show="opsRequest.typeId == 2 && opsRequest.categoryId == 4">
                    <label for="investor" class="col-form-label">
                      Investor <span class="text-danger">*</span>
                    </label>
                    <multiselect id="investor" v-model="investorObj" :options="allOprInvestors" label="investorName" track-by="investorId" :allow-empty="false" @input="investorSelected"></multiselect>
                    <template v-if="$v.opsRequest.requestorId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.requestorId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group" v-show="opsRequest.categoryId == 1 || opsRequest.categoryId == 5 || opsRequest.categoryId == 7">
                    <label for="requestor" class="col-form-label">
                      Deal <span class="text-danger">*</span>
                    </label>
                    <multiselect id="fund" v-model="fundObj" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search Deals"
                      @input="fundSelected" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                    <template v-if="$v.opsRequest.requestorId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.requestorId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <template v-if="opsRequest.categoryId == 1">
                    <div class="form-group">
                      <label for="fund-contact-name">Contact Person's Name <span class="text-danger">*</span></label>
                      <input if="fund-contact-name" type="text" v-model.trim="opsRequest.fundContactName" @blur="$v.opsRequest.fundContactName.$touch()" placeholder="Enter Name" class="form-control" :class="{ 'parsley-error': $v.opsRequest.fundContactName.$error }">
                      <template v-if="$v.opsRequest.fundContactName.$error">
                        <ul class="parsley-errors-list filled">
                          <li v-if="!$v.opsRequest.fundContactName.required" class="parsley-required">This value is required</li>
                        </ul>
                      </template>
                    </div>
                    <div class="form-group">
                      <label for="fund-contact-email">Contact Person's Email <span class="text-danger">*</span></label>
                      <input if="fund-contact-email" type="text" v-model.trim="opsRequest.fundContactEmail" @blur="$v.opsRequest.fundContactEmail.$touch()" placeholder="Enter Email" class="form-control" :class="{ 'parsley-error': $v.opsRequest.fundContactEmail.$error }">
                      <template v-if="$v.opsRequest.fundContactEmail.$error">
                        <ul class="parsley-errors-list filled">
                          <li v-if="!$v.opsRequest.fundContactEmail.required" class="parsley-required">This value is required</li>
                          <li v-if="$v.opsRequest.fundContactEmail.required && !$v.opsRequest.fundContactEmail.isEmail" class="parsley-required">Must be valid email</li>
                        </ul>
                      </template>
                    </div>
                  </template>

                  <div class="form-group" v-show="opsRequest.typeId == 2 && opsRequest.categoryId == 6">
                    <label for="provider" class="col-form-label">
                      Service Provider <span class="text-danger">*</span>
                    </label>
                    <multiselect id="provider" v-model="providerObj" :options="allOprServiceProviders" label="serviceProviderName" track-by="serviceProviderId" :allow-empty="false" @input="providerSelected"></multiselect>
                    <template v-if="$v.opsRequest.requestorId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.requestorId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group" v-show="opsRequest.categoryId == 2 || opsRequest.categoryId == 3">
                    <label for="requestorName" class="col-form-label">
                      Requestor Name <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="requestorName" v-model="opsRequest.requestorName" @blur="$v.opsRequest.requestorName.$touch()" :class="{ 'parsley-error': $v.opsRequest.requestorName.$error}" />
                    <template v-if="$v.opsRequest.requestorName.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.requestorName.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.opsRequest.requestorName.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                      <label>Source <span class="text-danger">*</span></label>
                      <span :class="{ 'parsley-error-radio': $v.opsRequest.sourceId.$error }">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="source1" :value="1" v-model="opsRequest.sourceId" name="source" class="custom-control-input">
                          <label class="custom-control-label" for="source1">Email</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="source2" :value="2" v-model="opsRequest.sourceId" name="source" class="custom-control-input">
                          <label class="custom-control-label" for="source2">Mail</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="source3" :value="3" v-model="opsRequest.sourceId" name="source" class="custom-control-input">
                          <label class="custom-control-label" for="source3">Phone</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="source4" :value="4" v-model="opsRequest.sourceId" name="source" class="custom-control-input" :disabled="true">
                          <label class="custom-control-label" for="source4">System</label>
                        </div>
                      </span>
                      <template v-if="$v.opsRequest.sourceId.$error">
                        <ul class="parsley-errors-list filled">
                          <li v-if="!$v.opsRequest.sourceId.required" class="parsley-required">This value is required</li>
                        </ul>
                      </template>
                  </div>

                  <div class="form-group">
                    <label for="other" class="col-form-label">
                      Description <span class="text-danger">*</span>
                    </label>
                    <textarea class="form-control" rows="5" id="other" v-model="opsRequest.comments" @blur="$v.opsRequest.comments.$touch()" :class="{ 'parsley-error': $v.opsRequest.comments.$error }"></textarea>
                    <template v-if="$v.opsRequest.comments.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.comments.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.opsRequest.comments.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="requestDate">
                      Request Date <span class="text-danger">*</span>
                    </label>
                    <date-picker placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                    format="MM/dd/yyyy" :bootstrap-styling="true" v-model="opsRequest.requestDate" @blur="$v.opsRequest.requestDate.$touch()" @input="setRequestDate" :input-class="{ 'form-control parsley-error': $v.opsRequest.requestDate.$error }"></date-picker>
                    <template v-if="$v.opsRequest.requestDate.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.requestDate.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="deadline">
                      Deadline <span class="text-danger">*</span>
                    </label>
                    <date-picker open placeholder="Select Date" calendar-class="calendar form-control open-up" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                    format="MM/dd/yyyy" :bootstrap-styling="true" v-model="opsRequest.deadline" @blur="$v.opsRequest.deadline.$touch()" @input="setDeadline" :input-class="{ 'form-control parsley-error': $v.opsRequest.deadline.$error }"></date-picker>
                    <template v-if="$v.opsRequest.deadline.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.deadline.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label">Kroll background check required?</label>
                      <toggle-button v-model="opsRequest.isKrollBackground" :labels="{checked: 'Yes', unchecked: 'No'}" />
                      <div class="form-group" v-show="opsRequest.isKrollBackground">
                        <label class="col-form-label">Please list the entities and individuals that need to have a Kroll background check <span class="text-danger">*</span></label>
                        <textarea class="form-control" rows="3" id="krollBackgroundCheck" v-model="opsRequest.krollBackgroundCheck" @blur="$v.opsRequest.krollBackgroundCheck.$touch()" :class="{ 'parsley-error': $v.opsRequest.krollBackgroundCheck.$error }"></textarea>
                        <template v-if="$v.opsRequest.krollBackgroundCheck.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.opsRequest.krollBackgroundCheck.required" class="parsley-required">This value is required</li>
                          </ul>
                        </template>
                        <label class="col-form-label">Kroll background check Due Date <span class="text-danger">*</span></label>
                        <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                          format="MM/dd/yyyy" :bootstrap-styling="true" v-model="opsRequest.krollBackgroundCheckDueDate" @blur="$v.opsRequest.krollBackgroundCheckDueDate.$touch()" @input="setPickerDateKrollBackground" :input-class="{ 'form-control parsley-error': $v.opsRequest.krollBackgroundCheckDueDate.$error }"></date-picker>
                        <template v-if="$v.opsRequest.krollBackgroundCheckDueDate.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.opsRequest.krollBackgroundCheckDueDate.required" class="parsley-required">This value is required</li>
                          </ul>
                        </template>
                      </div>
                  </div>
                  
                  <div class="form-group" v-show="isOpsTeam">
                      <label>Assign to Myself</label>
                      <toggle-button v-model="opsRequest.assignedToMyself" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                  </div>

                  <div class="form-group">
                      <div class="checkbox checkbox-primary">
                          <input id="checkbox6a" type="checkbox" v-model="addMore">
                          <label for="checkbox6a">
                              Add Another
                          </label>
                      </div>
                  </div>

                  <div class="form-group text-right m-b-0">
                    <router-link :to="{name: 'LegalNew'}">
                        <button type="button" class="btn btn-secondary waves-effect mr-2">
                            Back
                        </button>
                    </router-link>

                    <button v-if="user.groupId < 11" class="btn btn-primary waves-effect waves-light" type="submit">Submit Request</button>
                  </div>

                </div>

              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import {mapGetters, mapActions} from 'vuex'
import { required, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { OpsTeamService } from '@/services/opsteam-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import moment from 'moment'

export default {
   props: {
    id: {
      required: false,
      type: Number
    }
  },
  components: {
    DatePicker,
    Multiselect
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      opsRequest: {},
      isSearchingList: false,
      searchFunds: [],
      addMore: false,
      fundObj: null,
      investorObj: null,
      providerObj: null
    }
  },
  validations: {
    opsRequest: {
      typeId: { required },
      categoryId: { required },
      sourceId: { required },
      requestorId: { required: requiredIf(function () { return parseInt(this.opsRequest.categoryId) !== 2 && parseInt(this.opsRequest.categoryId) !== 3 }) },
      requestorName: { required: requiredIf(function () { return parseInt(this.opsRequest.categoryId) === 2 || parseInt(this.opsRequest.categoryId) === 3 }), max: maxLength(300) },
      comments: { required, min: minLength(2), max: maxLength(1000) },
      requestDate: { required },
      deadline: { required },
      krollBackgroundCheck: { required: requiredIf(function () { return this.opsRequest.isKrollBackground }), max: maxLength(1000) },
      krollBackgroundCheckDueDate: { required: requiredIf(function () { return this.opsRequest.isKrollBackground }) },
      fundContactName: { required: requiredIf(function () { return parseInt(this.opsRequest.categoryId) === 1 }) },
      fundContactEmail: { required: requiredIf(function () { return parseInt(this.opsRequest.categoryId) === 1 }) },
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allUsers () {
      return this.$store.state.allUsers.filter(x => x.userId > 0 && (x.groupId === 4 || x.groupId === 7 || x.groupId === 8))
    },
    isOpsTeam () {
      return this.user.groupId === 4 || this.user.groupId === 7 || this.user.groupId === 8 || this.user.groupId === 5
    },
    allOprInvestors () {
      return this.$store.state.allOprInvestors
    },
    allOprServiceProviders () {
      return this.$store.state.allOprServiceProviders
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers', 'fetchAllOprInvestors', 'fetchAllOprServiceProviders', 'searchAllFunds', 'fetchFund']),
    fundSelected (value, id) {
      this.opsRequest.requestorId = value ? value.fundId : null
    },
    providerSelected (value, id) {
      this.opsRequest.requestorId = value ? value.serviceProviderId : null
    },
    investorSelected (value, id) {
      this.opsRequest.requestorId = value ? value.investorId : null
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    },
    reset () {
      this.opsRequest = {
        deadline: null,
        requestor: null,
        comments: null,
        sourceId: null
      }

      this.$v.$reset()
    },
    save () {
      this.$v.opsRequest.$touch()
      if (this.$v.opsRequest.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      OpsTeamService.add(this.opsRequest)
      .then(
        (response) => {
          this.$snotify.success('Ops Request Submitted.')
          if (!this.addMore) {
            setTimeout(() => {
              this.$router.push({name: 'OpsDetail', params: {id: response.data.requestId}})
            }, 400)
          } else {
            this.reset()
          }
        })
        .catch(function (error) {
          console.error('SERVER Error:', error)
        })
    },
    setRequestDate (value) {
      if (value != null) {
        this.opsRequest.requestDate = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0)
      } else {
        this.opsRequest.requestDate = null
      }
    },
    setPickerDateKrollBackground (value) {
      if (value != null) {
        this.opsRequest.krollBackgroundCheckDueDate = moment(value).format('YYYY-M-D')
      } else {
        this.opsRequest.krollBackgroundCheckDueDate = null
      }
    },
    setDeadline (value) {
      if (value != null) {
        this.opsRequest.deadline = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0)
      } else {
        this.opsRequest.deadline = null
      }
    }
  },
  created () {
    if (!this.isOpsTeam) {
      this.opsRequest.typeId = 1
    }
    if(this.id > 0) {      
      if (this.$route.query.categoryId == 1) {
        this.opsRequest.categoryId = 1
        this.opsRequest.typeId = 1
        this.opsRequest.sourceId = 4
      }

      this.opsRequest.requestorId = this.id
      this.$v.opsRequest.requestorId.$touch()
      this.$v.opsRequest.requestorId.$reset()

      this.fetchFund({fundId: this.opsRequest.requestorId})
      .then((fResponse) => {
        this.fundObj = fResponse.data
      }) 

    }
    this.fetchAllUsers()
    this.fetchAllOprInvestors()
    this.fetchAllOprServiceProviders()
    this.opsRequest.requestDate = new Date(Date.now())
    this.asyncDataStatus_fetched('Submit an Ops Workflow request')
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
