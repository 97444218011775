<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
            Secondary Funds
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <div v-if="isAdd">
            <label for="FirmId">General Partner<span v-show="isEditMode" class="text-danger">*</span></label>
            <span v-if="searching"> <i class="fa fa-spinner fa-spin"></i></span>
            <div class="row" style="margin-right:0;">
              <div class="col-11">
                <span :class="{ 'invalid': $v.secFund.firmId.$error }">
                  <multiselect id="FirmId" v-model="firmObj" :options="searchFirms" label="firmName" track-by="firmId" :allow-empty="false" v-show="isEditMode" :preselectFirst="true" placeholder="Type to Search"
                    @input="firmSelected" @search-change="asyncSearchFirm" :loading="isSearchingList" :options-limit="50"></multiselect>
                </span>
              </div>
              <div class="col-1" style="padding:0;">
                <button class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="search"><i class="mdi mdi-magnify"></i></button>
              </div>
            </div>
            <span class="text-white" v-if="relatedFundsNotFound">Did not find any related fund for this GP.</span>
            <table class="table" v-if="relatedFunds">
              <tr v-for="fund in relatedFunds" :key="fund.fundId">
                <td>
                  <div class="checkbox checkbox-primary checkbox-single">
                      <input :id="'checkboxf'+fund.fundId" type="checkbox" v-model="fund.selected">
                      <label :for="'checkboxf'+fund.fundId"></label>
                  </div>
                </td>
                <td>
                  <label :for="'checkboxf'+fund.fundId" :class="{'text-primary': fund.selected}">
                    {{fund.fundName}}
                  </label>
                  <div v-if="fund.selected">
                      <div class="form-group">
                          <label for="commitment">Commitment (M)</label>
                          <div class="input-group">
                              <div class="input-group-prepend">
                                  <span class="input-group-text">{{fund.currencySymbol}}</span>
                              </div>
                              <input type="text" v-model.number="fund.commitment" class="form-control" id="commitment">
                          </div>
                      </div>
                      <div class="form-group">
                          <label for="called">Called (M)</label>
                          <div class="input-group">
                              <div class="input-group-prepend">
                                  <span class="input-group-text">{{fund.currencySymbol}}</span>
                              </div>
                              <input type="text" v-model.number="fund.called" class="form-control" id="called">
                          </div>
                      </div>
                      <div class="form-group">
                          <label for="unfunded">Unfunded (M)</label>
                          <div class="input-group">
                              <div class="input-group-prepend">
                                  <span class="input-group-text">{{fund.currencySymbol}}</span>
                              </div>
                              <input type="text" v-model.number="fund.unfunded" class="form-control" id="unfunded">
                          </div>
                      </div>
                      <div class="form-group">
                          <label for="netAssetValue">Net Asset Value (M)</label>
                          <div class="input-group">
                              <div class="input-group-prepend">
                                  <span class="input-group-text">{{fund.currencySymbol}}</span>
                              </div>
                              <input type="text" v-model.number="fund.netAssetValue" class="form-control" id="netAssetValue">
                          </div>
                      </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div v-else>
              <div class="form-group">
                <label>Firm</label>
                <h5 class="text-primary">{{secFund.firmName}}</h5>
                <label>Fund</label>
                <h5 class="text-primary">{{secFund.fundName}}</h5>
              </div>
              <div class="form-group">
                  <label for="commitment">Commitment (M)</label>
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">{{secFund.symbol}}</span>
                      </div>
                      <input type="text" v-model.number="secFund.commitment" class="form-control" id="commitment">
                  </div>
              </div>
              <div class="form-group">
                  <label for="called">Called (M)</label>
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">{{secFund.symbol}}</span>
                      </div>
                      <input type="text" v-model.number="secFund.called" class="form-control" id="called">
                  </div>
              </div>
              <div class="form-group">
                  <label for="unfunded">Unfunded (M)</label>
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">{{secFund.symbol}}</span>
                      </div>
                      <input type="text" v-model.number="secFund.unfunded" class="form-control" id="unfunded">
                  </div>
              </div>
              <div class="form-group">
                  <label for="netAssetValue">Net Asset Value (M)</label>
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">{{secFund.symbol}}</span>
                      </div>
                      <input type="text" v-model.number="secFund.netAssetValue" class="form-control" id="netAssetValue">
                  </div>
              </div>
          </div>
      </div>
      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="secFund.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="secFund.createdBy" />
          </div>
        </div>
        <div class="form-group" v-if="secFund.updatedOn">
            <label>Modified On</label>
            <AppDate :date="secFund.updatedOn" :show="'input'"/>
        </div>
        <div class="form-group" v-if="secFund.updatedOn">
            <label>Modified By</label>
            <AppUser :userId="secFund.updatedBy" :emptyIfNull="true"/>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isAdd" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onAdd"><i class="mdi mdi-content-save m-r-5"></i> Add</button>
        <button v-if="!isAdd && isEditMode" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { required, minValue } from 'vuelidate/lib/validators'
import { SecFundService } from '@/services/fund-service'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      showLogs: false,
      firmObj: null,
      searchFirms: [],
      searching: false,
      relatedFunds: null,
      relatedFundsNotFound: false,
      isSearchingList: false
    }
  },
  validations: {
    secFund: {
      firmId: { required, min: minValue(1) }
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['searchAllFirms', 'addSecFunds', 'updateSecFunds']),
    close () {
      this.$v.secFund.$reset()
      this.doubleAddError = false
      this.hideRightbar()
    },
    firmSelected (value, id) {
      this.secFund.firmId = value ? value.firmId : null
      this.relatedFunds = null
      this.relatedFundsNotFound = false
    },
    search () {
      this.$v.secFund.$touch()
      if (this.$v.secFund.$error) {
        return
      }
      this.relatedFundsNotFound = false
      SecFundService.getByFirmId(this.firmObj.firmId)
      .then((response) => {
        if (response.data.length > 0) {
          this.relatedFunds = response.data
        } else {
          this.relatedFunds = null
          this.relatedFundsNotFound = true
        }
      })
    },
    onSave () {
      this.updateSecFunds({'secFundId': this.secFund.secfundId, 'secFund': this.secFund})
        .then((response) => {
          if (response == null) {
            this.$snotify.error('Server Error!')
          } else {
            this.$snotify.success('Fund updated.')
            this.$emit('onUpdate')
          }
        })
    },
    onAdd () {
      const secFunds = this.relatedFunds.filter(x => x.selected)
      if (secFunds.length === 0) {
        this.$snotify.error('No Fund is selected')
        return
      }
      const fCnt = secFunds.length > 1 ? 'Funds' : 'Fund'
      SecFundService.add(this.secFund.fundId, secFunds)
        .then((response) => {
          if (response == null) {
            this.$snotify.error('Server Error!')
          } else {
            this.$snotify.success(fCnt + ' Added.')
            this.$emit('onUpdate')
          }
        })
    },
    asyncSearchFirm (query) {
      this.isSearchingList = true
      this.searchAllFirms({query}).then((result) => {
        this.searchFirms = result
        this.isSearchingList = false
      })
    }
  },
  created () {
  },
  computed: {
    secFund () {
      return this.$store.state.rb_secFund
    }
  }
}
</script>
