export default {
    /* How to use mixin: 
    1. inject into component, call prepQuarterDropdown in the components created hook
    2. Add Quarters.vue to the component - Quarters.vue will provide buttons, dropdowns for the compnent
    3. define quartersReqObj & loadedQuarters in component, update quarterSelection in component and pass it as a prop to Quarters.vue
     */
    data () {
      return {
        currYear: new Date().getFullYear(),
        yearsArr: null,
        quarterTree: null,
        jumpToArr: [],
        quarterSelection: null, // value set to default on load, but updated by the component using the mixin
        loadedQuarters: {}, //this value will be set by the component using the mixin
        quarterReqObj: {}, // this value will be set by the component using the mixin
        /* Computed properties that depend on quarterReqObj:
        currentQuarter (), previousQuarter (), quarterReqObjPrevQ ()
         */
        // canAutofillQ
      }
    },
    methods: {
        prepQuarterDropdown() { //call this function in created hook to set up the mixin
            this.generateYearsArray()
            const currQ = this.getQuarter()
            const futureQuarters = []
            for (let i = currQ; i >= 1; i--) {
              futureQuarters.push(i)
            }
            this.quarterTree = this.yearsArr.map((y, index) => {
              const quarters = index < 1 ? futureQuarters : [4,3,2,1] 
              return {
                id: y,
                label: y,
                children: quarters.map(q => {
                  return {
                      id: '' + y + q,
                      label: 'Q' + q,
                      customLabel: `${y} Q${q}`
                  }
                })
              }
            })
            this.prepJumpTo(this.currYear, currQ)
        },
        prepJumpTo (currY, currQ) {
            const prevY = currY - 1
            const nextY = currY + 1
            this.jumpToArr = []
            const pushArr = function (y,q) {
              this.jumpToArr.push({
                value: ''+y+q,
                label:`${y} Q${q}`
               })
            }
            if (currQ === 3) {
              [1,2,3,4].forEach(q => {
                pushArr.call(this,currY, q)
              })
            }
            else if (currQ == 1) {
              [3,4,1,2].forEach((q, i) => {
               const y = i < 2 ? prevY : currY
               pushArr.call(this,y, q)
              })
            }
            else if (currQ == 2) {
              [4,1,2,3].forEach((q,i) => {
                const y = i < 1 ? prevY : currY
                pushArr.call(this, y, q)
              })
            } else {
              [2,3,4,1].forEach((q,i) => {
                const y = i < 3 ? currY : nextY
                pushArr.call(this, y, q)
              })
            }
        },
        generateYearsArray () {
            const min = 2020
            const years = []
            for (let i = this.currYear + 1; i >= min; i--) {
              years.push(i)
            }
            this.yearsArr = years
        },
        getQuarter () {
            const q = Math.floor(new Date().getMonth() / 3 + 1);
            this.quarterSelection = `${this.currYear}${q}`
            return q
        },
        createQString ({year, quarter}) {
            return '' + year + quarter
        },
    },
    computed: {
        currentQuarter () {
            if(Object.keys(this.quarterReqObj).length < 1) return null
              return this.createQString(this.quarterReqObj)
            //   value changes when quarterReqObj changes (during fetch request)
            // if fail saves and you want to change quarter selection back to currentQuarter, then quarterSelection = currentQuarter
            // Once component confirms it should change quarters, update quarterReqObj then set quarterSelection === currentQuarter
          },
          previousQuarter () {
            if(!this.quarterReqObjPrevQ || Object.keys(this.quarterReqObjPrevQ).length < 1) return null
              return this.createQString(this.quarterReqObjPrevQ)
          },
          quarterReqObjPrevQ () {
            if(!this.currentQuarter) return null
            if(this.currentQuarter === this.minQ) return false
            let quarter = +this.currentQuarter.slice(-1) - 1 
            let year = +this.currentQuarter.slice(0,4)
            if (quarter < 1) {
                quarter = 4;
                year--
            }
            return {year, quarter, fundId: this.id}
          },
          minQ () {
            // traversing quarter Tree for the lowest minimum quarter
            // used to disable prev quarter button
            return this.quarterTree.slice(-1)[0].children.slice(-1)[0].id
          },
          maxQ () {
            // traversing quarter tree for the maximum quarter
            // used to disable next quarter button
            return this.quarterTree.slice(0,1)[0].children.slice(0,1)[0].id
        },
    }
  }
  