<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Comment
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="comment">
      <div class="form-group">
          <label>IF Code</label>
          <div>
            <label class="text-primary">{{comment.oprCode}}</label>
          </div>
          <label>Investment</label>
          <div>
            <label class="text-primary">{{comment.investment}}</label>
          </div>
          <label>Region </label>
          <div>
            <label class="text-primary">{{comment.region}}</label>
          </div>
          <div class="form-group">
              <label for="filledPosition">Commentary<span class="text-danger">*</span></label>
              <textarea rows="5" class="form-control" v-model.trim="comment.commentary" @blur="$v.comment.commentary.$touch()" :class="{ 'parsley-error': $v.comment.commentary.$error }"></textarea>
              <template v-if="$v.comment.commentary.$error">
                <ul class="parsley-errors-list filled">
                  <li v-if="!$v.comment.commentary.required" class="parsley-required">This value is required</li>
                </ul>
              </template>
          </div>
          <div class="form-group" v-if="comment.hasMarketCommentary">
              <label for="filledPosition">Market Commentary<span class="text-danger">*</span></label>
              <textarea rows="5" class="form-control" v-model.trim="comment.marketCommentary" @blur="$v.comment.marketCommentary.$touch()" :class="{ 'parsley-error': $v.comment.marketCommentary.$error }"></textarea>
              <template v-if="$v.comment.marketCommentary.$error">
                <ul class="parsley-errors-list filled">
                  <li v-if="!$v.comment.marketCommentary.required" class="parsley-required">This value is required</li>
                </ul>
              </template>
          </div>
      </div>
      
      <div v-if="comment.updatedOn">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group" v-if="comment.updatedOn">
              <label>Modified On</label>
              <AppDate :date="comment.updatedOn" :show="'input'"/>
          </div>
          <div class="form-group" v-if="comment.updatedOn">
              <label>Modified By</label>
              <AppUser :userId="comment.updatedBy" :emptyIfNull="true"/>
          </div>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { MonitoringCommentService } from '@/services/monitoringcomment-service'

export default {
  props: {
    comment: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showLogs: false
    }
  },
  mixins: [rightbar],
  validations: {
    comment: {
      commentary: { required },
      marketCommentary: { required: requiredIf(function () { return this.comment.hasMarketCommentary }) }
    }
  },
  methods: {
    close () {
      this.$v.comment.$reset()
      this.hideRightbar()
    },
    onSave () {
      this.$v.comment.$touch()
      if (!this.$v.comment.$error) {
        const upd = {qmcId: this.comment.qmcId, commentary: this.comment.commentary, marketCommentary: this.comment.marketCommentary}
        MonitoringCommentService.update(this.comment.qmcId, upd)
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Updated.')
              this.$emit('onUpdate', upd)
            }
          })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    }
  }
}
</script>
