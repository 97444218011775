import Constants from '../constants.js'
import Axios from 'axios'

export const CalendarService = {
  getCalendar (year, month) {
    return Axios.get(Constants.API_URL + '/calendar/' + year + '/' + month)
  },
  getCalendarDay (day) {
    return Axios.get(Constants.API_URL + '/calendar/day/' + day)
  }
}
