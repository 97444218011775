import Constants from '../constants.js'
import Axios from 'axios'

export const CalMeetingService = {
  getDashList () {
    return Axios.get(Constants.API_URL + '/cal-meeting/dash')
  },
  joinMeeting (meetingId) {
    return Axios.put(Constants.API_URL + '/cal-meeting/join/' + meetingId)
  },
  skipMeeting (meetingId) {
    return Axios.put(Constants.API_URL + '/cal-meeting/skip/' + meetingId)
  },
  couldNotMeeting (meetingId) {
    return Axios.put(Constants.API_URL + '/cal-meeting/couldnot/' + meetingId)
  },
  cancelMeeting (meetingId, value) {
    return Axios.put(Constants.API_URL + '/cal-meeting/cancel/' + meetingId, value)
  },
  assignDeal (meetingId, value) {
    return Axios.put(Constants.API_URL + '/cal-meeting/assign/' + meetingId, value)
  },
  scoreMeeting (meetingId, value) {
    return Axios.put(Constants.API_URL + '/cal-meeting/score/' + meetingId, value)
  },
  getFundMeetings (fundId) {
    return Axios.get(Constants.API_URL + '/cal-meeting/fund/' + fundId)
  },
  getContactMeetings (contactId) {
    return Axios.get(Constants.API_URL + '/cal-meeting/contact/' + contactId)
  },
  assignContact (meetingId, value) {
    return Axios.post(Constants.API_URL + '/cal-meeting/assign-contact/' + meetingId, value, {
      headers: {
        'content-type': 'application/json'
      }
    })
  },
  addContact (meetingId, value) {
    return Axios.post(Constants.API_URL + '/cal-meeting/add-contact/' + meetingId, value)
  },
  getTypes () {
    return Axios.get(Constants.API_URL + '/cal-meeting/types/')
  },
  get (id) {
    return Axios.get(Constants.API_URL + '/cal-meeting/' + id)
  },
  search (title, attendeeId, userId, startDate, fundId, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/cal-meeting/search?title=' + title + '&attendeeId=' + attendeeId + '&userId=' + userId + '&startDate=' + startDate + '&fundId=' + fundId + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  screening () {
    return Axios.get(Constants.API_URL + '/cal-meeting/screening')
  },
  calendar (year, month, attendeeId, userId, fundId) {
    return Axios.get(Constants.API_URL + '/cal-meeting/calendar?year=' + year + '&month=' + month + '&attendeeId=' + attendeeId + '&userId=' + userId + '&fundId=' + fundId)
  },
  invite (meetingid, inviteList) {
    return Axios.post(Constants.API_URL + '/cal-meeting/invite/' + meetingid, inviteList)
  },
  loadInvites (meetingid) {
    return Axios.get(Constants.API_URL + '/cal-meeting/invite/' + meetingid)
  }
}
