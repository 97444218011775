<template>
  <div>
    <table class="table table-striped table-hover table-files">
      <tbody>
        <tr v-if="files === null">
          <td class="text-center pt-3" colspan="2">
            <p>No Documents to show</p>
          </td>
        </tr>
        <tr v-for="file in files" :key="file.fileId">
          <td>
            <a href="javascript:void(0)" class="file-item-icon" v-tooltip="file.fileType">
              <i class="mdi file-type-icon" :class="fileIcon(file.fileType)"></i>
            </a>
          </td>
          <td>
              {{file.originalFileName}}
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-success btn-sm btn-icon" @click.prevent="view(file)" v-tooltip="'Download'"><i class="mdi mdi-download"></i></button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { PortalFundService } from '@/services/portal-service'

export default {
  props: {
    files: {
      type: Array,
      default: []
    }
  },
  methods: {
    view (file) {
      PortalFundService.download(file.fileId)
      .then(
        (response) => {
           var ext = file.originalFileName.split('.').reverse()[0]
          if(ext.toLowerCase()=='pdf') {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            window.open(fileURL)
          } else {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.target = "_blank"
            fileLink.href = fileURL
            fileLink.setAttribute('download', file.originalFileName)
            document.body.appendChild(fileLink)
            fileLink.click()
          }
        }
      )
    },
    fileIcon (type) {
      switch (type.toLowerCase()) {
        case 'pdf':
          return 'mdi mdi-file-pdf'
        case 'doc':
        case 'docx':
          return 'mdi mdi-file-word'
        case 'xls':
        case 'xlsx':
          return 'mdi mdi-file-excel'
        case 'png':
        case 'gif':
        case 'jpg':
        case 'jpeg':
          return 'mdi mdi-file-image'
        default:
          return 'mdi mdi-file'
      }
    }
  }
}
</script>
