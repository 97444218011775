import Constants from '../constants.js'
import Axios from 'axios'

export const FirmService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/firm/' + id)
  },
  getAll () {
    return Axios.get(Constants.API_URL + '/firm')
  },
  getList () {
    return Axios.get(Constants.API_URL + '/firm/list')
  },
  search (name, isActive, sortField, sortType, pageNumber, pageSize) {
    if(isActive==false){
      isActive=null
    }
    
    return Axios.get(Constants.API_URL + '/firm/search?name=' + name + '&isActive=' + isActive + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/firm/' + id, value)
  },
  add (firm) {
    return Axios.post(Constants.API_URL + '/firm', firm)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/firm/' + id)
  },
  getFunds (firmId) {
    return Axios.get(Constants.API_URL + '/fund/firm/' + firmId)
  },
  doubleCheckFirms (firmId, values) {
    return Axios.post(Constants.API_URL + '/firm/check-firm-exists/' + firmId, values)
  }
}

export const FirmContactService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/firmcontact/' + id)
  },
  getFirm (id) {
    return Axios.get(Constants.API_URL + '/firmcontact/firm/' + id)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/firmcontact', value)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/firmcontact/' + id, value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/firmcontact/' + id)
  }
}

export const FirmAnnualMeetingService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/firmannualmeeting/' + id)
  },
  getFirm (id) {
    return Axios.get(Constants.API_URL + '/firmannualmeeting/firm/' + id)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/firmannualmeeting', value)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/firmannualmeeting/' + id, value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/firmannualmeeting/' + id)
  }
}
