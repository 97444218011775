<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'legal-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Legal Workflow'" :icon="'mdi mdi-scale-balance'" />
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box table-responsive">
                                <h4 class="m-t-0 header-title mb-4">Submited Requests</h4>                                
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-6">
                                      <div style="width:50%">
                                          <multiselect id="statusFilter" v-model="statusFilter" tag-placeholder="Add this as new tag" placeholder="Search or add a Status" 
                                              label="statusName" track-by="statusId" :options="allStatuses" :multiple="true" :taggable="true" width="50%" @input="submitSearch"></multiselect>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div>
                                          <span v-if="user.groupId != 2">
                                            <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                            <label class="col-form-label">
                                              Only My Requests
                                              <toggle-button v-model="onlyMine" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" @input="submitSearch"/>
                                            </label>
                                          </span>
                                          <div class="col-md-6">
                                              <multiselect id="userFilter" v-model="userObj" :options="allUsers" label="fullName" track-by="userId" :allow-empty="true" v-if="user.groupId == 2"
                                                placeholder="Filter by Requestor" @input="submitSearch"></multiselect>
                                              <!-- <button type="button" class="btn btn-rounded waves-effect waves-light btn-success col-md-1" @click="clearUser" v-show="userObj" v-tooltip="'clear Name'">
                                                <i class="mdi mdi-delete"></i>
                                              </button> -->
                                          </div>
                                        </div>
                                    </div>
                                </div>

                                <table class="table table-bordered table-hover table-sortable">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)"> Category
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)"> Related
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(2)"> Status
                                          <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(3)"> Submited By
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(4)"> Submited On
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(5)"> Deadline
                                          <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(6)"> Assigned To
                                          <i v-if="sortIndex == 6 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 6 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                         
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="item in legalRequests" :key="item.requestId">
                                          <td>
                                              <router-link :to="{path: item.detailFormUrl + '/' + item.requestId}">
                                                  {{item.categoryName}}
                                              </router-link>
                                          </td>
                                          <td> {{item.category2}}</td>
                                          <td :class="item.colorClass"> {{item.statusName}}</td>
                                          <td> <AppUser :userId="item.requestorId" :show="'text'" v-if="item.requestorId > 0" /> </td>
                                          <td> <AppDate :date="item.createdOn" :show="'text'" /> </td>
                                          <td> <AppDate :date="item.deadline" :show="'text'" /> </td>
                                          <td> <AppUser :userId="item.assignedTo" :show="'text'" v-if="item.assignedTo > 0" /> </td>
                                      </tr>
                                    </tbody>
                                </table>
                                
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div role="status">Page {{currentPage}} of {{totalPages}} - {{totalRecords}} requests</div>
                                    <div class="px-3">
                                          <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                              <option value="250">250</option>
                                          </select>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- end container -->
            </div>
            <!-- end content -->

            <Footer />
        </div>
    </div>
</template>

<script>
import { LegalService } from '@/services/legal-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Multiselect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      legalRequests: null,
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      sortBy: ['CategoryName', 'Category2', 'StatusName', 'RequestorFullName', 'CreatedOn', 'Deadline', 'AssignedTo'],
      sortIndex: 4,
      sortType: 'ASC',
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0,
      statusFilter: [],
      onlyMine: false,
      userObj: null
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    ...mapActions(['fetchAllLegalRequestStatuses', 'fetchAllUsers']),
    getList () {
      this.isLoading = true
      const status = this.statusFilter.map(a => a.statusId)
      let requestorId = ''
      if (this.onlyMine) {
        requestorId = this.user.userId
      }
      if (this.userObj) {
        requestorId = this.userObj.userId
      }
      const filters = {
        'requestorId': requestorId,
        'sortIndex': this.sortIndex,
        'sortType': this.sortType,
        'pageSize': this.pageSize,
        'onlyMine': this.onlyMine,
        'status': this.statusFilter
      }
      
      localStorage.setItem('umb_legal_filters', JSON.stringify(filters))

      LegalService.search('', requestorId, '', status, this.sortBy[this.sortIndex], this.sortType, this.currentPage, this.pageSize)
      .then((response) => {
        this.isLoading = false
        this.legalRequests = response.data.requests
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Legal Workflow Lists') })
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    clearUser () {
      this.userObj = null
      this.submitSearch()
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.legalRequests = []

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    resetFilter () {
      this.onlyMine = false
      this.statusFilter = this.allStatuses.filter(x => x.statusId <= 2)
      this.startSearch()
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allStatuses () {
      return this.$store.state.allLegalRequestStatuses
    },
    allUsers () {
      return this.$store.state.allUsers
    },
    hasFilter () {
      return this.onlyMine || this.statusFilter.filter(x => x.statusId <= 2).length !== 2 || this.statusFilter.filter(x => x.statusId > 2).length > 0
    }
  },
  created () {
    this.fetchAllLegalRequestStatuses()
    this.fetchAllUsers()
    this.statusFilter = this.allStatuses.filter(x => x.statusId <= 2)
    const oldFilters = JSON.parse(localStorage.getItem('umb_legal_filters'))
    if (oldFilters != null) {
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
      if (oldFilters.sortIndex != null) {
        this.sortIndex = oldFilters.sortIndex
      }
      if (oldFilters.sortType != null) {
        this.sortType = oldFilters.sortType
      }
      if (oldFilters.onlyMine != null) {
        this.onlyMine = oldFilters.onlyMine
      }
      if (oldFilters.status != null) {
        this.statusFilter = oldFilters.status
      }
    }
    this.startSearch()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
