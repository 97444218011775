import Constants from '../constants.js'
import Axios from 'axios'

export const ConnectService = {
  getOrgList () {
    return Axios.get(Constants.API_URL + '/connect-organization/')
  },
  getFundStat (fundId) {
    return Axios.get(Constants.API_URL + '/connect-organization/fund-score/' + fundId)
  },
  getCommentList (fundId) {
    return Axios.get(Constants.API_URL + '/connect-organization/cofund-interests/' + fundId)
  },
  getCommentDetails (interestId) {
    return Axios.get(Constants.API_URL + '/connect-organization/cofund-interest/' + interestId)
  }
}
