<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'funds-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Add Deal'" :icon="'mdi mdi-square-inc-cash'"/>
                        <div class="card-box add-next-fund-box">
                          <div class="row">
                            <div class="col-lg-6">
                              <h4 class="header-title m-t-0">Add next Deal: <span class="text-primary">{{fundName}}</span> </h4>
                              <p class="text-muted font-14 m-b-20">
                                Confirm new fund information.
                              </p>

                              <form action="#" autocomplete="off">
                                  <div class="form-group">
                                      <label for="fundName">Name <span class="text-danger">*</span></label>
                                      <input type="text" v-model.trim="$v.fund.fundName.$model" @blur="$v.fund.fundName.$touch()" parsley-trigger="change" placeholder="Enter Name" class="form-control" id="fundName" :class="{ 'parsley-error': $v.fund.fundName.$error }">
                                      <template v-if="$v.fund.fundName.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.fundName.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.fund.fundName.min" class="parsley-required">This value is too short</li>
                                          <li v-if="!$v.fund.fundName.max" class="parsley-required">This value is too long</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                      <label for="expectedstartdate">Expected Start Date <span class="text-danger">*</span></label><i class="text-muted font-10 m-l-10">Prior deal: <AppDate :date="startDate" :show="'text'"/></i>
                                      <date-picker v-model="fund.expectedStartDate" @blur="$v.fund.expectedStartDate.$touch()" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.expectedStartDate.$error }"></date-picker>
                                      <template v-if="$v.fund.expectedStartDate.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.expectedStartDate.required" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                      <label for="expectedclosedate">Expected Close Date <span v-show="!fund.isOpenEnded" class="text-danger">*</span></label><i class="text-muted font-10 m-l-10">Prior deal: <AppDate :date="closeDate" :show="'text'"/></i>
                                      <date-picker v-model="fund.expectedCloseDate" @blur="$v.fund.expectedCloseDate.$touch()" :disabled="fund.isOpenEnded" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.expectedCloseDate.$error }"></date-picker>
                                      <template v-if="$v.fund.expectedCloseDate.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.expectedCloseDate.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.fund.expectedCloseDate.validCloseDate" class="parsley-required">This value is not valid.</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <!-- <div class="form-group">
                                    <label>Open Ended</label>
                                    <toggle-button v-model="fund.isOpenEnded" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" @input="openEnded"/>
                                  </div> -->

                                  <div class="form-group" v-if="fund.fundTypeId != 3">
                                      <label for="targetSize">Target Size (M) <span class="text-danger">*</span></label><i class="text-muted font-10 m-l-10">Prior deal: {{targetSize}}</i>
                                      <input type="text" v-model.number="fund.targetSize" required @blur="$v.fund.targetSize.$touch()" placeholder="Enter Target Size" class="form-control" id="targetSize" :class="{ 'parsley-error': $v.fund.targetSize.$error }">
                                      <template v-if="$v.fund.targetSize.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.targetSize.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.fund.targetSize.min || !$v.fund.targetSize.max" class="parsley-required">This value is not valid.</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group text-right m-b-0">
                                    <button type="reset" class="btn btn-secondary waves-effect mr-2" @click.prevent="back">
                                        Back
                                    </button>
                                    <button class="btn btn-primary waves-effect waves-light" type="submit" @click.prevent="save" :disabled="isLoading">
                                        <span v-if="!isLoading">Add Fund</span>
                                        <span v-else>Saving <i class="fa fa-spinner fa-spin"></i></span>
                                    </button>
                                  </div>
                              </form>
                            </div>
                          </div>

                        </div>
                </div>
            </div>
        </div>
        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <SimilarList :list="SimilarFundList" @onConfirm="confirmedSave"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { FundService } from '@/services/fund-service'
import moment from 'moment'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import SimilarList from '@/components/SimilarList'
import rightbar from '@/mixins/rightbar'

export default {
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  components: {
    DatePicker,
    SimilarList
  },
  data () {
    return {
      fund: {fundName: '', fundTypeId: null, expectedStartDate: '', sectorId: 0},
      fundName: null,
      startDate: null,
      closeDate: null,
      targetSize: null,
      SimilarFundList: null,
      saveConfirmed: false,
      isLoading: false
    }
  },
  validations: {
    fund: {
      fundName: { required, min: minLength(3), max: maxLength(100) },
      expectedStartDate: { required },
      expectedCloseDate: { required },
      targetSize: { required }
      // expectedCloseDate: { required: requiredIf(function () { return !this.fund.isOpenEnded }), validCloseDate (value) { return validCloseDate(this.fund.expectedStartDate, value, this.fund.isOpenEnded) } }
      // targetSize: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) < 3 }), decimal, min: validateIf12('fundTypeId', minValue(1)), max: maxValue(10000000) }
    }
  },
  mixins: [rightbar, asyncDataStatus],
  methods: {
    confirmedSave () {
      this.saveConfirmed = true
      this.save()
    },
    save () {
      this.sidebarAction(false)
      this.$v.fund.$reset()
      this.$v.fund.$touch()
      if (!this.$v.fund.$error) {
        // if is Open-Ended
        if (this.fund.isOpenEnded) {
          this.fund.expectedCloseDate = null
        } else {
          this.fund.expectedCloseDate = moment(this.fund.expectedCloseDate).format('MM/DD/YYYY')
        }
        this.fund.expectedStartDate = moment(this.fund.expectedStartDate).format('MM/DD/YYYY')

        // check similar names
        FundService.doublecheckName(this.fund.fundName)
        .then(
          (response) => {
            if (response.data.length > 0) {
              this.SimilarFundList = response.data
              const isExact = this.SimilarFundList.filter(x => x.fundName.toLowerCase() === this.fund.fundName.toLowerCase()).length > 0
              if (isExact) {
                this.$snotify.warning('"' + this.fund.fundName + '" already exists')
                return
              }
              // response.data
              // .forEach(item => {
              //   item = {...item, '_name': item.fundName}
              //   this.SimilarFundList.push(item)
              // })
              if (!this.saveConfirmed) {
                this.sidebarAction(true)
              }
            } else {
              this.saveConfirmed = true // no need
            }
            if (this.saveConfirmed) {
              this.isLoading = true
              FundService.addNext(this.id, this.fund)
              .then(
                (response) => {
                  this.$snotify.success('Fund created.')
                  if (!this.addMore) {
                    setTimeout(() => {
                      this.$router.push({name: 'FundsDetail', params: {id: response.data.fundId}})
                    }, 500)
                  } else {
                    this.isLoading = false
                    this.reset()
                  }
                })
                .catch(error => this.$snotify.error('Server Error! ' + error.message)) // add
            }
          }
        )
        .catch(error => this.$snotify.error('Server Error! ' + error.message)) // doublecheckName
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    back () {
      this.$router.push({name: 'FundsDetail', params: {id: this.id}})
    },
    guessNextName (name) {
      let gn = name.trim()
      let words = gn.split(' ')
      const n = words[words.length - 1].toUpperCase()
      const s = gn.lastIndexOf(' ')
      let p = gn.substr(0, s).trim()
      let nn = 0
      if (isNaN(n)) {
        nn = this.fromRoman(n)
        if (nn > 0) {
          nn++
          nn = this.toRoman(nn)
        } else {
          p = gn
          nn = 2
        }
      } else {
        nn = Number(n) + 1
      }
      return p + ' ' + nn
    },
    fromRoman (str) {
      let result = 0
      const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
      const roman = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I']
      for (var i = 0; i <= decimal.length; i++) {
        while (str.indexOf(roman[i]) === 0) {
          result += decimal[i]
          str = str.replace(roman[i], '')
        }
      }
      return result
    },
    toRoman (num) {
      let result = ''
      const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
      const roman = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I']
      for (var i = 0; i <= decimal.length; i++) {
        while (num % decimal[i] < num) {
          result += roman[i]
          num -= decimal[i]
        }
      }
      return result
    },
    sidebarAction (isOpen) {
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    }
  },
  created () {
    this.sidebarAction(false)
    FundService.get(this.id)
    .then((response) => {
      this.fund = response.data
      this.fundName = this.fund.fundName
      this.fund.fundName = this.guessNextName(this.fund.fundName)
      this.startDate = this.fund.expectedStartDate
      this.closeDate = this.fund.expectedCloseDate
      this.targetSize = this.fund.targetSize

      let startDate = moment(this.fund.expectedCloseDate).add(2, 'year')
      let closeDate = moment(this.fund.expectedCloseDate).add(3, 'year')
      this.fund.expectedStartDate = startDate.toDate()
      this.fund.expectedCloseDate = closeDate.toDate()
      if (this.fund.targetSize > 0) {
        this.fund.targetSize = this.fund.targetSize * 1.25
        this.fund.targetSize = Math.ceil(this.fund.targetSize / 50) * 50
      }
    })
    .then(() => {
      this.asyncDataStatus_fetched('Copy Deal')
    })
  },
  computed: {
    // expectedStartDate () {
    //   const date = moment(this.fund.expectedStartDate).format('MM/DD/YYYY')
    //   return date
    // },
    // expectedCloseDate () {
    //   const date = moment(this.fund.expectedCloseDate).format('MM/DD/YYYY')
    //   return date
    // },
  }
}
</script>
