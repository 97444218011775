<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'funds-screen'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Screening'" :icon="'mdi mdi-square-inc-cash'" />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card-box">
                          <div class="tab-content">
                            <div class="tab-pane active">
                                <div class="row">
                                  <div class="col-md-12">
                                      <div class="row">
                                        <div class="col-md-6 card card-box">
                                          <h4 class="m-t-0 header-title m-b-20">Statistics</h4>
                                            <table class="table table-striped table-bordered no-footer">
                                              <thead>
                                                <tr>
                                                  <th></th>
                                                  <th>Total</th>
                                                  <th>High Scoring</th>
                                                  <th>Missed</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Last Week</td>
                                                  <td>{{stats.lastWeekCnt}}</td>
                                                  <td>{{stats.lastWeekGood}}</td>
                                                  <td>{{stats.lastWeekMiss}}</td>
                                                </tr>
                                                <tr>
                                                  <td>Previous Week</td>
                                                  <td>{{stats.prevWeekCnt}}</td>
                                                  <td>{{stats.prevWeekGood}}</td>
                                                  <td>{{stats.prevWeekMiss}}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                        </div>
                                      </div>

                                      <p class="mt-5">Meetings with average score 4 and above in last 7 days.</p>
                                      <table class="table table-striped table-bordered table-hover no-footer">
                                          <thead>
                                          <tr role="row">
                                              <th></th>
                                              <th> Deal Name</th>
                                              <th> Stage</th>
                                              <th> Date/Time
                                              </th>
                                              <th> Avg Score
                                                <i class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                              </th>
                                              <th> Type</th>
                                              <th> Title</th>
                                              <th> Attendees </th>
                                          </tr>
                                          </thead>
                                  
                                          <tbody>
                                            <tr v-for="item in list" :key="item.meetingId">
                                              <td>
                                                <div class="checkbox checkbox-primary checkbox-single">
                                                    <input :id="'checkboxap'+item.meetingId" type="checkbox">
                                                    <label :for="'checkboxap'+item.meetingId"></label>
                                                </div>
                                              </td>
                                                <td>
                                                    <router-link :to="{name: 'FundsDetail', params: {id: item.fundId}}">
                                                        {{item.fundName}} <span class="badge badge-success push-right">{{item.fundTypeName}}</span>
                                                    </router-link>
                                                </td>
                                                <td :class="stageClass(item.stageId)">{{ item.stageName }}</td>
                                                <td>
                                                    <AppDate :date="item.startTime" :show="'text'"/>
                                                </td>
                                                <td class="text-center">
                                                  {{item.avgScore}}
                                                </td>
                                                <td>
                                                  {{item.typeName}}
                                                </td>
                                                <td>
                                                  <router-link :to="{name: 'CalendarDetail', params: {id: item.meetingId}}">
                                                    {{item.title}}
                                                  </router-link>
                                                </td>
                                                <td>
                                                  <span v-for="user in attendeeList(item.meetingId)" :key="user.userId">
                                                    <AppUserPic :userId="user.userId" :size="2" class="mr-1"/>
                                                  </span>
                                                </td>
                                            </tr>
                                          </tbody>
                                      </table>

                                      <div class="row soursing-footer">
                                        <div class="col-sm-12 col-md-7 col-lg-7" v-if="list">
                                          <div class="" role="status">Total: {{list.length}} Meetings</div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <!-- end container -->
        </div>
        <!-- end content -->
        <Footer />
    </div>
</template>

<script>
import { CalMeetingService } from '@/services/calmeeting-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  data () {
    return {
      list: null,
      scoreList: null,
      stats: {}
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    getList () {
      CalMeetingService.screening()
      .then((response) => {
        this.list = response.data.meetings
        this.scoreList = response.data.attendees
        this.stats = response.data.statistic
      })
      .then(() => { this.asyncDataStatus_fetched('Deal Screening') })
    },
    stageClass (stageId) {
      if (stageId >= 3) {
        return 'text-white stage' + stageId
      }
    },
    attendeeList (meetingId) {
      return this.scoreList.filter(x => x.meetingId === meetingId)
    }
  },
  mounted () {
    this.getList()
  }
}
</script>
