import Constants from '../constants.js'
import Axios from 'axios'

export const ReportService = {
  getAll () {
    return Axios.get(Constants.API_URL + '/report')
  },
  getByObject (objectId) {
    return Axios.get(Constants.API_URL + '/report/object/' + objectId)
  },
  getPowerBIEmbedInfo (reportId) {
    return Axios.get(Constants.API_URL + '/report/pbi-embed-info/' + reportId)
  }
}
