<template>
  <div class="row">
    <div class="col-xl-3 col-lg-4" v-for="user in users" :key="user.userId">
        <div class="text-center card-box">
            <div class="member-card">
                <div class="thumb-xl member-thumb m-b-10 center-block">
                    <img :src="pic(user.userName)" class="rounded-circle img-thumbnail" style="width:90px;height:90px;" />
                </div>

                <div class="">
                    <h5 class="m-b-5">
                      <router-link v-if="adminUser" :to="{name: 'UserInfo', params: {id: user.userId}}">{{user.fullName}}</router-link>
                      <span v-else>{{user.fullName}}</span>
                      <span class="text-danger" v-if="!user.isActive">(Inactive)</span>
                    </h5>
                    <h6>
                        {{ user.titleName }}
                    </h6>
                </div>

                <div class="text-left m-t-40">
                    <p class="text-muted font-13">
                        <strong>Email:</strong>
                        <span class="m-l-15">
                            <a :href="`mailto:` + user.email">{{ user.email }}</a>
                        </span>
                    </p>

                    <p class="text-muted font-13">
                        <strong>Group:</strong>
                        <span class="m-l-15"> {{ user.groupName }} </span>
                    </p>

                    <!-- <strong class="text-muted font-13">Roles:</strong>
                    <ul class="text-muted font-13">
                      <li v-for="role in user.roles" :key="role.roleId">
                        {{ role.roleName }}
                      </li>
                    </ul> -->

                    <p class="text-muted font-13">
                        <strong>Birthday :</strong>
                        <span class="m-l-15" v-if="user.dob"> {{user.dob | birthday}} </span>
                    </p>

                    <!-- <p class="text-muted font-13">
                        <strong>Timezone :</strong>
                        <span class="m-l-15"> {{user.timezone}} </span>
                    </p> -->

                </div>
            </div>

        </div> 
        <!-- end card-box -->

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  props: {
    users: {
      required: true,
      type: Array
    }
  },
  filters: {
    birthday (date) {
      return moment(date).format('MMMM D')
    }
  },
  methods:{
    pic(userName) {
      try {
        return require(`/static/img/users/${userName.toLowerCase()}.jpg`)
      } catch {
        return require(`/static/img/users/user.jpg`)
      }
      
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    adminUser () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0
    }
  }
}
</script>
