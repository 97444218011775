<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portal-firms'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="firm">
                    <Toolbar :title="'Update Firm - '+firm.firmName" :icon="'mdi mdi-web'" :isAgency="firm.isAgency" :agencyName="firm.agencyFirmName"
                    :showToolbar="true" :inEditMode="false"  :isSaving="inSaving"
                   :isDeleted="false" :isUpdated="false" :isNew="false"
                    :firmImported ="false" :isFund="false" :isAdminEdit="true" :isNavAdmin="isNavAdmin"
                     @onSave="saveForm" @onCancelAdminEdit="onCancelAdminEdit" />
                   
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-box">
                                <ul class="nav nav-tabs tabs-bordered">
                                    <li class="nav-item">
                                        <a href="javascript:void(0)"  aria-expanded="false" class="nav-link" >
                                            <i class="mdi mdi-information-outline"></i> Summary
                                        </a>
                                    </li>
                                   
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane fade active show" >
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label for="firmName">Name <span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="firm.firmName" @blur="$v.firm.firmName.$touch()" placeholder="Enter Name" class="form-control" id="firmName" :disabled="!inEditMode" :class="{ 'parsley-error': $v.firm.firmName.$error }">
                                                    <template v-if="$v.firm.firmName.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.firmName.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.firmName.min" class="parsley-required">This value is too short</li>
                                                        <li v-if="!$v.firm.firmName.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="Website">Website <span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="firm.website" placeholder="Website" class="form-control" id="Website" :disabled="!inEditMode" @blur="$v.firm.website.$touch()" :class="{ 'parsley-error': $v.firm.website.$error, 'updated-portal' : firmUmbrella != null && firm.website !== firmUmbrella.website }">
                                                    <template v-if="$v.firm.website.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.website.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.website.isValidURL && $v.firm.website.required" class="parsley-required">Website is not valid</li>
                                                        <li v-if="!$v.firm.website.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="FirmOverview">Firm Overview <span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <textarea placeholder="Enter Overview" v-model.trim="firm.overview" :disabled="!inEditMode" class="form-control" rows="6" id="FirmOverview" @blur="$v.firm.overview.$touch()" :class="{ 'parsley-error': $v.firm.overview.$error, 'updated-portal' : firmUmbrella != null && firm.overview !== firmUmbrella.overview }"/>
                                                    <template v-if="$v.firm.overview.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.overview.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.overview.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="address1">Address</label>
                                                    <input type="text" v-model.trim="firm.address1" class="form-control" id="address1" :disabled="!inEditMode" @blur="$v.firm.address1.$touch()" :class="{ 'parsley-error': $v.firm.address1.$error, 'updated-portal' : firmUmbrella != null && firm.address1 !== firmUmbrella.address1 }" />
                                                    <template v-if="$v.firm.address1.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.address1.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.address1.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                    <input type="text" v-model.trim="firm.address2" class="form-control" id="address2" :disabled="!inEditMode" :class="{ 'updated-portal' : firmUmbrella != null && firm.address2 !== firmUmbrella.address2 }" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="city">City</label>
                                                    <input type="text" v-model.trim="firm.city" class="form-control" id="city" :disabled="!inEditMode" @blur="$v.firm.city.$touch()" :class="{ 'parsley-error': $v.firm.city.$error, 'updated-portal' : firmUmbrella != null && firm.city !== firmUmbrella.city }" />
                                                    <template v-if="$v.firm.city.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.city.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.city.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="region">State</label>
                                                    <input type="text" v-model.trim="firm.region" class="form-control" id="region" :disabled="!inEditMode" @blur="$v.firm.region.$touch()" :class="{ 'parsley-error': $v.firm.region.$error, 'updated-portal' : firmUmbrella != null && firm.region !== firmUmbrella.region }" />
                                                    <template v-if="$v.firm.region.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.region.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.region.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="zipcode">Zip Code</label>
                                                    <input type="text" v-model.trim="firm.zipCode" class="form-control" id="zipcode" :disabled="!inEditMode" @blur="$v.firm.zipCode.$touch()" :class="{ 'parsley-error': $v.firm.zipCode.$error, 'updated-portal' : firmUmbrella != null && firm.zipCode !== firmUmbrella.zipCode }" />
                                                    <template v-if="$v.firm.zipCode.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.zipCode.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.zipCode.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="country">Country</label>
                                                    <input type="text" v-model.trim="firm.country" class="form-control" id="country" :disabled="!inEditMode" @blur="$v.firm.country.$touch()" :class="{ 'parsley-error': $v.firm.country.$error, 'updated-portal' : firmUmbrella != null && firm.country !== firmUmbrella.country }" />
                                                    <template v-if="$v.firm.country.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.country.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.country.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                               
                                                <div >
                                                    <div class="form-group">
                                                        <label>Created On</label>
                                                        <AppDate :date="firm.createdOn"/>
                                                    </div>
                                                    <div class="form-group" v-if="firm.updatedOn">
                                                        <label>Modified On</label>
                                                        <AppDate :date="firm.updatedOn" :emptyIfNull="true"/>
                                                    </div>
                                                </div>

                                            </div>

                                           
                                        </div>
                                    </div>
                                   

                                </div>
                            </div>
                        </div> 
                        <!-- end col -->
                    </div>
                </div>
            </div>
        </div>

      
        <Footer />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'

import { PortalFirmService } from '@/services/portal-service'
import { FirmService } from '@/services/firm-service'

import asyncDataStatus from '@/mixins/asyncDataStatus'
import Toolbar from '@/components/portal/PortalToolbar'

const isValidURL = (string) => {
  if (string == null) {
    return false
  }
  // eslint-disable-next-line no-useless-escape
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
  return (res !== null)
}

export default {
  components: {
    
    Toolbar
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      
      inEditMode: true,
      inSaving: false,
      
      firm: {firmName: ''},
      firmUmbrella: null,
      rightbarItem: null,
      addSidebar: null,
      firmTypeObj: null,
      ownershipIdObj: null,
      
      
      saveConfirmed: false
    }
  },
  validations: {
    firm: {
      firmName: { required, max: maxLength(300) },
      overview: { required, max: maxLength(2000) },
      website: { required, max: maxLength(250), isValidURL (value) { return isValidURL(value) } },
      address1: { required, max: maxLength(200) },
      address2: { max: maxLength(200) },
      city: { required, max: maxLength(100) },
      region: { required, max: maxLength(100) },
      zipCode: { required, max: maxLength(10) },
      country: { required, max: maxLength(100) }
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers', 'fetchAllCurrencies', 'fetchActivity']),
    
   
    saveForm () {
      this.$v.firm.$touch()
      if (!this.$v.firm.$error) {
        
         PortalFirmService.save(this.firm.firmId, this.firm).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {            
              this.$snotify.success('Firm updated.')
              this.$router.push({name: 'PortalFirmsDetail', params: {id: this.firm.firmId}})
            }
            this.inEditMode = false
            this.inSaving = false
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
   
   
    getDetails () {
      PortalFirmService.get(this.id)
      .then((response) => {
        this.firm = response.data.firm
        this.firmContacts = response.data.contacts
        this.relatedFunds = response.data.funds
        this.relatedDiversity = response.data.diversity
      }).then(() => {
        
      }).then(() => {
        this.asyncDataStatus_fetched('Portal Firm Details')
      }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    getTables () {
      this.fetchAllUsers()
      this.fetchAllCurrencies()
    },
    
    onCancelAdminEdit() {
      this.$router.push({name: 'PortalFirmsDetail', params: {id: this.firm.firmId}})
    },
    AddUser() {
      this.sidebarAction('newuser', true, true, true)
    }
  },
  created () {
    
    this.getTables()
    this.getDetails()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allUsers () {
      return this.$store.state.allUsers
    },
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    canbeEdited () {
      return this.user.groupId < 11 && (this.firm.umbFirmId === 0 || !this.firm.umbrellaCheck)
    },
    uAddress () {
      if (this.firmUmbrella == null) {
        return ''
      }
      return (this.firmUmbrella.address1 !== null ? this.firmUmbrella.address1 : '') + (this.firmUmbrella.address2 !== null ? this.firmUmbrella.address2 : '')
    },
    uCity () {
      if (this.firmUmbrella == null) {
        return ''
      }
      return this.firmUmbrella.city !== null ? this.firmUmbrella.city : ''
    },
    uRegion () {
      if (this.firmUmbrella == null) {
        return ''
      }
      return this.firmUmbrella.region !== null ? this.firmUmbrella.region : ''
    },
    uZipCode () {
      if (this.firmUmbrella == null) {
        return ''
      }
      return this.firmUmbrella.zipCode !== null ? this.firmUmbrella.zipCode : ''
    },
    uCountry () {
      if (this.firmUmbrella == null) {
        return ''
      }
      return this.firmUmbrella.country !== null ? this.firmUmbrella.country : ''
    },
    isNavAdmin () {
      
      return this.user.roles.filter(x => x.roleId === this.$userRoles.NAVIGATOR_ADMINISTRATOR).length > 0
    },

  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
