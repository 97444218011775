<template>
  <div>
    <Topbar />
    <Sidebar :activeItem="'cache-admin'" />

    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid">
          <PageTitle :title="'Cache Administration'" />
          <div class="row">
            <div class="card-box table-responsive">
              <h4 class="header-title">Cache list</h4>
              <table
                class="table table-striped table-bordered table-hover no-footer table-sortable"
              >
                <thead>
                  <tr role="row">
                    <th>Name</th>
                    <th>Content</th>
                    <th>Clear Cache</th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" v-for="cache in cacheList" :key="cache.key">
                    <td>
                      {{ cache.label }}
                      <input
                        type="text"
                        v-model="userId"
                        placeholder="User ID"
                        v-if="cache.key == 'user'"
                      />
                      <button
                        class="btn btn-icon waves-effect waves-light btn-primary mr-2"
                        @click.prevent="getStatus(cache)"
                      >
                        Get Status
                      </button>
                      <br />Status:<span class="badge badge-secondary">{{
                        cache.content
                      }}</span>
                    </td>
                    <td>
                      <json-viewer
                        :value="cache.jsonData"
                        :expand-depth="2"
                        copyable
                        boxed
                        sort
                      ></json-viewer>
                    </td>

                    <td>
                      <button
                        class="btn btn-icon waves-effect waves-light btn-warning mr-2"
                        @click.prevent="clearCache(cache)"
                      >
                        Clear Cache
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import asyncDataStatus from "@/mixins/asyncDataStatus";
import { CacheService } from "@/services/cache-service";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    id: {
      required: false,
      default: null,
    },
  },
  components: {},
  mixins: [asyncDataStatus],
  data() {
    return {
      userId: 0,
      cacheList: [
        {
          key: "activitytypes",
          label: "Activity Types",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "annualmeetingattendances",
          label: "Annual Meeting Attendances",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "countries",
          label: "Countries",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "filepurposes",
          label: "File Purposes",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "industryfocuses",
          label: "Industry Focuses",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "offices",
          label: "Offices",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "quartiles",
          label: "Quartiles",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "strategies",
          label: "Strategies",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "strategymodels",
          label: "Strategymodels",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "firmclienttypes",
          label: "Firm Client Types",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "firmownershiptypes",
          label: "Firmownership Types",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "firmtypes",
          label: "Firmtypes",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "boardseats",
          label: "Boardseats",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "currencies",
          label: "Currencies",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "fundinactivereasons",
          label: "Fund inactive reasons",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "fundperformancesources",
          label: "Fund performance sources",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "fundteamroles",
          label: "Fund team roles",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "fundtiers",
          label: "Fund tiers",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "fundtypes",
          label: "Fundtypes",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "geographies",
          label: "Geographies",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "managementfeestructures",
          label: "Management fee structures",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "monitoringtypes",
          label: "Monitoring types",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "sectors",
          label: "Sectors",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "subsectors",
          label: "Subsectors",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "requestcategories",
          label: "Request categories",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "requeststatuses",
          label: "Request statuses",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "oprinvestors",
          label: "Opr investors",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "oprserviceproviders",
          label: "Opr service providers",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "user",
          label: "User Id",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "calmeetingtypes",
          label: "Cal meeting types",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "roles",
          label: "Roles",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "titles",
          label: "Titles",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
        {
          key: "groups",
          label: "Groups",
          content: "Click Get Status button to see the content.",
          jsonData: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      activeUser: "authUser",
    }),

    isAdmin() {
      return this.activeUser.roles.some(
        (x) => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR
      );
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getStatus(cache) {
      cache.content = "Loading data, please wait......";
      var cacheKey = cache.key=='user'? 'user/' + this.userId: cache.key;
      CacheService.get(cacheKey)
        .then((res) => {
          if (!res || res.status !== 200) {
            cache.content = "Cache not found.";
            return;
          }

          cache.content = "Cache data fetched.";
          cache.jsonData = res.data;
          this.$snotify.success("Cache data retrived. Key:" + cacheKey);
        })
        .catch((err) => {
          this.$snotify.error(err.message);
        })
        .finally(() => {});
    },
    clearCache(cache) {
      cache.content = "Cache deleted.";
      var cacheKey = cache.key=='user'? 'user/'+this.userId: cache.key;
      CacheService.delete(cacheKey)
        .then((res) => {
          if (!res || res.status !== 200)
            throw new Error("Error clearing cache. Key:" + cacheKey);
          cache.jsonData = [];
          this.$snotify.success("Cache data cleared. Key:" + cacheKey);
        })
        .catch((err) => {
          this.$snotify.error(err.message);
        })
        .finally(() => {});
    },
  },
  async created() {
    this.asyncDataStatus_fetched("Cache Profile");
  },
};
</script>
