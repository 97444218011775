<template>
  <div>
    <div
      class="tab-pane fade"
      :class="{ 'active show': inEditMode ? activeTab == 1 : compareTabToggle ? activeTab == 3 : activeTab == 2 }"
    >
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="strategyObj2" class="col-form-label"
              >Strategy
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <multiselect
              id="strategyObj2"
              :class="{ 'updated_multi': fundUmbrella != null && fund.dealCurrencyId !== fundUmbrella.dealCurrencyId && !inEditMode }"
              v-model="strategyObj"
              :options="allStrategies"
              label="fullName"
              track-by="modelId"
              :allow-empty="false"
              :disabled='!inEditMode'
             />
          </div>

          <div class="form-group">
            <label for="parentFundName" class="col-form-label"
              >Parent Fund
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <input
              maxlength="300"
              type="text"
              @blur="$v.fund.parentFundName.$touch()"
              :class="{ 'parsley-error': $v.fund.parentFundName.$error, 'updated-portal' : fundUmbrella != null && fund.parentFundName !== fundUmbrella.parentFundName }"
              class="form-control"
              id="parentFundName"
              placeholder="Your Answer"
              v-model="fund.parentFundName"
              :disabled='!inEditMode'
            />
            <template v-if="$v.fund.parentFundName.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.parentFundName.$error"
                  class="parsley-required"
                >
                  Must be between 1 and 300 characters in length.
                </li>
              </ul>
            </template>
          </div>
          <div class="form-group">
            <label for="ltmRevenue" class="col-form-label"
              >LTM Revenue (M)
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                @blur="$v.fund.ltmRevenue.$touch()"
                :class="{ 'parsley-error': $v.fund.ltmRevenue.$error, 'updated-portal' : fundUmbrella != null && fund.ltmRevenue !== fundUmbrella.ltmRevenue }"
                class="form-control"
                id="ltmRevenue"
                placeholder="Your Answer"
                v-model.number="fund.ltmRevenue"
                :disabled='!inEditMode'
              />
            </div>
            <template v-if="$v.fund.ltmRevenue.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="$v.fund.ltmRevenue.$error" class="parsley-required">
                  Must be a number between 0.1 and 1,000,000
                </li>
              </ul>
            </template>
          </div>
          <div class="form-group">
            <label for="ltmEbitda" class="col-form-label"
              >LTM EBITDA (M)
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                @blur="$v.fund.ltmEbitda.$touch()"
                :class="{ 'parsley-error': $v.fund.ltmEbitda.$error, 'updated-portal' : fundUmbrella != null && fund.ltmEbitda !== fundUmbrella.ltmEbitda }"
                class="form-control"
                id="ltmEbitda"
                placeholder="Your Answer"
                v-model.number="fund.ltmEbitda"
                :disabled='!inEditMode'
              />
            </div>
            <template v-if="$v.fund.ltmEbitda.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="$v.fund.ltmEbitda.$error" class="parsley-required">
                  Must be a number between 0.1 and 1,000,000
                </li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="enterpriseValue">Enterprise Value (M)</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                @blur="$v.fund.enterpriseValue.$touch()"
                :class="{ 'parsley-error': $v.fund.enterpriseValue.$error, 'updated-portal' : fundUmbrella != null && fund.enterpriseValue !== fundUmbrella.enterpriseValue }"
                class="form-control"
                id="enterpriseValue"
                placeholder="Your Answer"
                v-model.number="fund.enterpriseValue"
                :disabled='!inEditMode'
              />
            </div>
            <template v-if="$v.fund.enterpriseValue.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.enterpriseValue.$error"
                  class="parsley-required"
                >
                  Must be a number between 0.1 and 1,000,000
                </li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="totalCompanyDebt" class="col-form-label"
              >Total Company Debt (M)
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                @blur="$v.fund.totalCompanyDebt.$touch()"
                :class="{ 'parsley-error': $v.fund.totalCompanyDebt.$error, 'updated-portal' : fundUmbrella != null && fund.totalCompanyDebt !== fundUmbrella.totalCompanyDebt }"
                class="form-control"
                id="totalCompanyDebt"
                placeholder="Your Answer"
                v-model.number="fund.totalCompanyDebt"
                :disabled='!inEditMode'
              />
            </div>
            <template v-if="$v.fund.totalCompanyDebt.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.totalCompanyDebt.$error"
                  class="parsley-required"
                >
                  Must be a number between 0.1 and 1,000,000
                </li>
              </ul>
            </template>
          </div>
          <div class="form-group">
            <label for="totalCompanyEquity" class="col-form-label"
              >Total Company Equity (M)
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                @blur="$v.fund.totalCompanyEquity.$touch()"
                :class="{ 'parsley-error': $v.fund.totalCompanyEquity.$error, 'updated-portal' : fundUmbrella != null && fund.totalCompanyEquity !== fundUmbrella.totalCompanyEquity }"
                class="form-control"
                id="totalCompanyEquity"
                placeholder="Your Answer"
                v-model.number="fund.totalCompanyEquity"
                :disabled='!inEditMode'
              />
            </div>
            <template v-if="$v.fund.totalCompanyEquity.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.totalCompanyEquity.$error"
                  class="parsley-required"
                >
                  Must be a number between 0.1 and 1,000,000
                </li>
              </ul>
            </template>
          </div>
          <div class="form-group">
            <label for="amountOffered" class="col-form-label"
              >Amount Offered All LPs (M)
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                @blur="$v.fund.amountOffered.$touch()"
                :class="{ 'parsley-error': $v.fund.amountOffered.$error, 'updated-portal' : fundUmbrella != null && fund.amountOffered !== fundUmbrella.amountOffered }"
                class="form-control"
                id="amountOffered"
                placeholder="Your Answer"
                v-model.number="fund.amountOffered"
                :disabled='!inEditMode'
              />
            </div>
            <template v-if="$v.fund.amountOffered.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.amountOffered.$error"
                  class="parsley-required"
                >
                  Must be a number between 0.1 and 1,000,000
                </li>
              </ul>
            </template>
          </div>
          <div class="form-group">
            <label for="amountOfferedWpm" class="col-form-label"
              >Amount Offered WPM (M)
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                @blur="$v.fund.amountOfferedWpm.$touch()"
                :class="{ 'parsley-error': $v.fund.amountOfferedWpm.$error, 'updated-portal' : fundUmbrella != null && fund.amountOfferedWpm !== fundUmbrella.amountOfferedWpm }"
                class="form-control"
                id="amountOfferedWpm"
                placeholder="Your Answer"
                v-model.number="fund.amountOfferedWpm"
                :disabled='!inEditMode'
              />
            </div>
            <template v-if="$v.fund.amountOfferedWpm.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.amountOfferedWpm.$error"
                  class="parsley-required"
                >
                  Must be a number between 0.1 and 1,000,000
                </li>
              </ul>
            </template>
          </div>
          <div class="form-group">
            <label for="managementFeePercent" class="col-form-label"
              >Management Fee - Investment Period
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">%</span>
              </div>
              <input
                type="text"
                @blur="$v.fund.managementFeePercent.$touch()"
                :class="{
                  'parsley-error': $v.fund.managementFeePercent.$error, 'updated-portal' : fundUmbrella != null && fund.managementFeePercent !== fundUmbrella.managementFeePercent
                }"
                class="form-control"
                id="managementFeePercent"
                placeholder="Your Answer"
                v-model.number="fund.managementFeePercent"
                :disabled='!inEditMode'
              />
            </div>
            <template v-if="$v.fund.managementFeePercent.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.managementFeePercent.$error"
                  class="parsley-required"
                >
                  Must be a number between 0.1 and 1,000,000
                </li>
              </ul>
            </template>
          </div>
          <div class="form-group" :class="{ 'updated-portal': fundUmbrella != null && fund.managementFeeStructureId !== fundUmbrella.managementFeeStructureId }">
            <label for="Structure1b" class="col-form-label" :class="{ 'updated-portal-radio' : fundUmbrella != null && fund.managementFeeStructureId !== fundUmbrella.managementFeeStructureId && !inEditMode }"
              >Management Fee Structure
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <span>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="Structure1b"
                  value="1"
                  v-model="fund.managementFeeStructureId"
                  name="Structureb"
                  class="custom-control-input"
                  :disabled='!inEditMode'
                />
                <label class="custom-control-label" for="Structure1b"
                  >Committed</label
                >
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="Structure2b"
                  value="2"
                  v-model="fund.managementFeeStructureId"
                  name="Structureb"
                  class="custom-control-input"
                  :disabled='!inEditMode'
                />
                <label class="custom-control-label" for="Structure2b"
                  >Invested</label
                >
              </div>
            </span>
          </div>

          <div class="form-group">
            <label for="carriedInterestPercent" class="col-form-label"
              >Carried Interest
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <input
              type="text"
              @blur="$v.fund.carriedInterestPercent.$touch()"
              :class="{
                'parsley-error': $v.fund.carriedInterestPercent.$error, 'updated-portal' : fundUmbrella != null && fund.carriedInterestPercent !== fundUmbrella.carriedInterestPercent
              }"
              class="form-control"
              id="carriedInterestPercent"
              placeholder="Your Answer"
              v-model.number="fund.carriedInterestPercent"
              :disabled='!inEditMode'
            />
            <template v-if="$v.fund.carriedInterestPercent.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.carriedInterestPercent.$error"
                  class="parsley-required"
                >
                  Must be a number between 0.1 and 1,000,000
                </li>
              </ul>
            </template>
          </div>
          <div class="form-group">
            <label for="carriedInterestDesc" class="col-form-label"
              >Carried Interest - Waterfall Type</label
            >
            <input
              type="text"
              maxlength="100"
              @blur="$v.fund.carriedInterestDesc.$touch()"
              :class="{ 'parsley-error': $v.fund.carriedInterestDesc.$error, 'updated-portal' : fundUmbrella != null && fund.carriedInterestDesc !== fundUmbrella.carriedInterestDesc }"
              class="form-control"
              id="carriedInterestDesc"
              placeholder="Your Answer"
              v-model="fund.carriedInterestDesc"
              :disabled='!inEditMode'
            />
            <template v-if="$v.fund.carriedInterestDesc.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.carriedInterestDesc.$error"
                  class="parsley-required"
                >
                  Must be between 1 and 100 characters in length
                </li>
              </ul>
            </template>
          </div>
          <div class="form-group">
            <label for="preferredReturnPercent" class="col-form-label"
              >Preferred Return
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">%</span>
              </div>
              <input
                type="text"
                :disabled='!inEditMode'
                @blur="$v.fund.preferredReturnPercent.$touch()"
                :class="{
                  'parsley-error': $v.fund.preferredReturnPercent.$error, 'updated-portal' : fundUmbrella != null && fund.preferredReturnPercent !== fundUmbrella.preferredReturnPercent
                }"
                class="form-control"
                id="preferredReturnPercent"
                placeholder="Your Answer"
                v-model.number="fund.preferredReturnPercent"
              />
            </div>
            <template v-if="$v.fund.preferredReturnPercent.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.preferredReturnPercent.$error"
                  class="parsley-required"
                >
                  Must be between 0 and 100
                </li>
              </ul>
            </template>
          </div>
          <div class="form-group">
            <label for="dealDescription" class="col-form-label"
              >Deal Description
              <span v-show="inEditMode" class="text-danger">*</span></label
            >
            <textarea
              maxlength="2000"
              @blur="$v.fund.dealDescription.$touch()"
              :class="{ 'parsley-error': $v.fund.dealDescription.$error, 'updated-portal' : fundUmbrella != null && fund.dealDescription !== fundUmbrella.dealDescription }"
              class="form-control"
              id="dealDescription"
              placeholder="Enter Deal Description"
              v-model="fund.dealDescription"
              rows="5"
              :disabled='!inEditMode'
            />
            <template v-if="$v.fund.dealDescription.$error">
              <ul class="parsley-errors-list filled">
                <li
                  v-if="$v.fund.dealDescription.$error"
                  class="parsley-required"
                >
                  Must be between 1 and 2000 characters in length
                </li>
              </ul>
            </template>
          </div>
        </div>
        
        <div class="col-lg-6" v-if='compareTabToggle'>
          <CompareTabIS
            :fund='fund'
            :fundUmbrella='fundUmbrella'
            :inEditMode='inEditMode'
            :strategyObj='strategyObj'
            :symbol='symbol'
            :compareTabToggle='compareTabToggle'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FundDocumentList from '@/components/portal/FundDocumentList'
import FundKeyPersonnelList from '@/components/portal/FundKeyPersonnelList'
import FundPerformancesList from '@/components/portal/FundPerformancesList'
import {
  required,
  decimal,
  maxLength,
  minValue,
  maxValue
} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import ActivityList from '@/components/ActivityList'
import CompareTabIS from '@/components/portal/CompareTabIS'
import { mapActions } from 'vuex'

export default {
  components: {
    FundDocumentList,
    FundKeyPersonnelList,
    FundPerformancesList,
    Multiselect,
    DatePicker,
    ActivityList,
    CompareTabIS
  },
  data () {
    return {
      activeISTab: 1,
      showLogs: false
    }
  },
  props: {
    fund: {
      type: Object
    },
    fundUmbrella: {
      type: Object
    },
    activeTab: {
      type: Number
    },
    inEditMode: {
      type: Boolean
    },
    strategyObj: {
      type: Array
    },
    symbol: {
      type: String
    },
    compareTabToggle: {
      type: Boolean
    }
  },
  methods: {
    ...mapActions(['fetchAllStrategies']),
    showISTab (tabId) {
      this.activeISTab = tabId
    },
    onActivityUpdate () {
      this.$emit('onActivityUpdate')
    },
    clickAddActivity () {
      this.$emit('clickAddActivity')
    },
    showActivity () {
      this.$emit('showActivity')
    },
    editActivity () {
      this.$emit('editActivity')
    },
    updateStrategy (strategy) {
      this.$emit('updateStrategy', strategy)
    }
  },
  computed: {
    allStrategies () {
      return this.$store.state.allStrategies
    }
  },
  validations: {
    fund: {
      sectorId: { required, min: minValue(1) },
      finalCloseAmount: { decimal, max: maxValue(1000000) },
      dealDescription: {
        required,
        max: maxLength(2000)
      },
      managementFeePercent: {
        required,
        decimal,
        min: minValue(0),
        max: maxValue(100)
      },
      managementFeeStructureId: {
        required
      },
      carriedInterestPercent: {
        required,
        decimal,
        min: minValue(0),
        max: maxValue(100)
      },
      carriedInterestDesc: { max: maxLength(100) },
      preferredReturnPercent: {
        required,
        decimal,
        min: minValue(0),
        max: maxValue(100)
      },
      parentFundName: {
        required,
        max: maxLength(300)
      },
      ltmRevenue: {
        required,
        decimal,
        min: minValue(0.1),
        max: maxValue(1000000)
      },
      ltmEbitda: {
        required,
        decimal,
        min: minValue(0.1),
        max: maxValue(1000000)
      },
      enterpriseValue: {
        required,
        decimal,
        min: minValue(0.1),
        max: maxValue(1000000)
      },
      totalCompanyDebt: {
        required,
        decimal,
        min: minValue(0.1),
        max: maxValue(1000000)
      },
      totalCompanyEquity: {
        required,
        decimal,
        min: minValue(0.1),
        max: maxValue(1000000)
      },
      amountOffered: {
        required,
        decimal,
        min: minValue(0.1),
        max: maxValue(1000000)
      },
      amountOfferedWpm: {
        required,
        decimal,
        min: minValue(0.1),
        max: maxValue(1000000)
      }
    }
  },
  created () {
    this.fetchAllStrategies()
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>