<template>
  <div class="row clocks">
    <div class="col-sm-6 col-lg-4">
      <div class="card-box">
        <p class="text-nowrap m-b-10" :class="{'text-primary' : officeTime(sm)}">Santa Monica <img v-if="officeTime(sm)" :src="require('/static/img/usa-flag-16.png')" alt="United States" v-tooltip="'United States'" height="16" />
        <span class="pull-right">
          <router-link :to="{name: 'Office', params: {id: 'Santa-Monica'}}" v-tooltip="'Santa Monica Users'">
            <i class="mdi mdi-account-multiple"></i>
          </router-link>
        </span>
        </p>
        <h4 class="text-muted text-center">{{formatting(sm)}}</h4>
      </div>
    </div>
      <div class="col-sm-6 col-lg-4">
        <div class="card-box">
          <p class="text-nowrap m-b-10" :class="{'text-primary' : officeTime(ch)}">Chicago <img v-if="officeTime(ch)" :src="require('/static/img/usa-flag-16.png')" alt="United States" v-tooltip="'United States'" height="16" />
          <span class="pull-right">
            <router-link :to="{name: 'Office', params: {id: 'Chicago'}}" v-tooltip="'Chicago Users'">
              <i class="mdi mdi-account-multiple"></i>
            </router-link>
          </span>
          </p>
          <h4 class="text-muted text-center">{{formatting(ch)}}</h4>
        </div>
      </div>

    <div class="col-sm-6 col-lg-4">
      <div class="card-box">
        <p class="text-nowrap m-b-10" :class="{'text-primary' : officeTime(pt)}">Pittsburgh <img v-if="officeTime(pt)" :src="require('/static/img/usa-flag-16.png')" alt="United States" v-tooltip="'United States'" height="16" />
        <span class="pull-right">
          <router-link :to="{name: 'Office', params: {id: 'Pittsburgh'}}" v-tooltip="'Pittsburgh Users'">
            <i class="mdi mdi-account-multiple"></i>
          </router-link>
        </span>
        </p>
        <h4 class="text-muted text-center">{{formatting(pt)}}</h4>
      </div>
    </div>

    <div class="col-sm-6 col-lg-4">
      <div class="card-box">
        <p class="text-nowrap m-b-10" :class="{'text-primary' : officeTime(ny)}">New York <img v-if="officeTime(ny)" :src="require('/static/img/usa-flag-16.png')" alt="United States" v-tooltip="'United States'" height="16" />
        <span class="pull-right">
          <router-link :to="{name: 'Office', params: {id: 'New-York'}}" v-tooltip="'New York Users'">
            <i class="mdi mdi-account-multiple"></i>
          </router-link>
        </span>
        </p>
        <h4 class="text-muted text-center">{{formatting(ny)}}</h4>
      </div>
    </div>

    <div class="col-sm-6 col-lg-4">
      <div class="card-box">
        <p class="text-nowrap m-b-10" :class="{'text-primary' : officeTime(nd)}">Amsterdam <img  v-if="officeTime(nd)" :src="require('/static/img/netherlands-flag-16.png')" alt="Netherlands" v-tooltip="'Netherlands'" height="16" />
          <span class="pull-right">
          <router-link :to="{name: 'Office', params: {id: 'Amsterdam'}}" v-tooltip="'Amsterdam Users'">
            <i class="mdi mdi-account-multiple"></i>
          </router-link>
          </span>
        </p>
        <h4 class="text-muted text-center">{{formatting(nd)}}</h4>
      </div>
    </div>

    <div class="col-sm-6 col-lg-4">
      <div class="card-box">
        <p class="text-nowrap m-b-10" :class="{'text-primary' : officeTime(hk)}">Hong Kong <img v-if="officeTime(hk)" :src="require('/static/img/hongkong-flag-16.png')" alt="Hong Kong" v-tooltip="'Hong Kong'" height="16" />
          <span class="pull-right">
            <router-link :to="{name: 'Office', params: {id: 'Hong-Kong'}}" v-tooltip="'Hong Kong Users'">
              <i class="mdi mdi-account-multiple"></i>
            </router-link>
          </span>
        </p>
        <h4 class="text-muted text-center">{{formatting(hk)}}</h4>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      sm: null,
      ch: null,
      pt: null,
      nd: null,
      hk: null,
      smClicks: 0,
      ptClicks: 0,
      ndClicks: 0,
      hkClicks: 0,
      smFlagClicks: 0,
      ptFlagClicks: 0,
      ndFlagClicks: 0,
      hkFlagClicks: 0
    }
  },
  created () {
    this.setClocks()
    setInterval(() => {
      this.setClocks()
    }, 30000)
  },
  methods: {
    setClocks () {
      this.sm = new Date().toLocaleString('en-US', {timeZone: 'America/Los_Angeles'})
      this.ch = new Date().toLocaleString('en-US', {timeZone: 'America/Chicago'})
      this.pt = new Date().toLocaleString('en-US', {timeZone: 'America/New_York'})
      this.ny = new Date().toLocaleString('en-US', {timeZone: 'America/New_York'})
      this.nd = new Date().toLocaleString('en-US', {timeZone: 'Europe/Amsterdam'})
      this.hk = new Date().toLocaleString('en-US', {timeZone: 'Asia/Hong_Kong'})
    },
    officeTime (tm) {
      const mtime = moment(tm)
      return mtime.hour() >= 8 && mtime.hour() < 18 && mtime.weekday() > 0 && mtime.weekday() < 6
    },
    formatting (tm) {
      return moment(tm).format('MMM D - h:mm A')
    },
  }
}
</script>