<template>
  <pie-chart :piedata="chartData" style="max-width: 500px;"></pie-chart>
</template>

<script>
  import PieChart from '@/components/charts/pieBase.js'

  export default {
    props: {
      chartData: {
        type: Object,
        required: true
      }
    },
    components: {
      PieChart
    }
}
</script>