<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'user-admin'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">

                    <PageTitle :title="'User Profile'"/>                    
                        <div class="row" v-if="user">
                            <div class="col-xl-3 col-lg-4">
                                <div class="text-center card-box">
                                    <div class="member-card">
                                        <div class="thumb-xl member-thumb m-b-10 center-block">
                                            <AppUserPic :userId="user.userId" :size="0" />
                                        </div>

                                        <div class="">
                                            <h5 class="m-b-5">{{user.fullName || 'New User'}}</h5>
                                            <h6>
                                                {{ user.titleName }}
                                            </h6>
                                            <span class="text-danger" v-if="user.userId && !user.isActive">(Inactive)</span>
                                        </div>

                                        <!-- <button type="button" class="btn btn-success btn-sm w-sm waves-effect m-t-10 waves-light" disabled>Message</button> -->

                                        <div class="text-left m-t-40">
                                            <p class="text-muted font-13">
                                                <strong>User Name :</strong>
                                                <span class="m-l-15">{{ user.userName }}</span>
                                            </p>

                                            <p class="text-muted font-13">
                                                <strong>Email :</strong>
                                                <span class="m-l-15">
                                                    <a :href="`mailto:` + user.email">{{ user.email }}</a>
                                                </span>
                                            </p>

                                            <p class="text-muted font-13">
                                                <strong>Group :</strong>
                                                <span class="m-l-15"> {{ user.groupName }} </span>
                                            </p>

                                            <p class="text-muted font-13">
                                                <strong>Office :</strong>
                                                <span class="m-l-15"> {{user.officeName}} </span>
                                            </p>

                                            <p class="text-muted font-13">
                                                <strong>Timezone :</strong>
                                                <span class="m-l-15"> {{user.timezone}} </span>
                                            </p>

                                        </div>
                                    </div>

                                </div> 
                                <!-- end card-box -->

                            </div>
                            <!-- end col -->

                            <div class="col-lg-8 col-xl-9">
                                <div class="">
                                    <div class="card-box">
                                        <ul class="nav nav-tabs tabs-bordered">
                                            <li class="nav-item">
                                                <a href="javascript:void(0)" @click="showTab(1)" data-toggle="tab" aria-expanded="false" class="nav-link" :class="{'active' : activeTab == 1}">
                                                    Info
                                                </a>
                                            </li>
                                            <li v-if="!isNew" class="nav-item">
                                                <a href="javascript:void(0)" @click="showTab(2)" data-toggle="tab" aria-expanded="false" class="nav-link" :class="{'active' : activeTab == 2}">
                                                    Browse History
                                                </a>
                                            </li>
                                            <li v-if="!isNew" class="nav-item">
                                                <a href="javascript:void(0)" @click="showTab(3)" data-toggle="tab" aria-expanded="false" class="nav-link" :class="{'active' : activeTab == 3}">
                                                    Login History
                                                </a>
                                            </li>
                                            <div class="ml-auto">
                                              <button @click.prevent="goBack" class="btn-sm text-white btn btn-secondary"><i class="mr-1 mdi mdi-arrow-left"></i>Back</button>
                                                <!-- <router-link :to="{name: 'Office', params: {id: 'all'}}" class="btn-sm text-white btn btn-secondary"><i class="mr-1 mdi mdi-arrow-left"></i>Back</router-link> -->
                                              <template v-if="isAdmin">
                                                <template v-if="isNew">
                                                  <button @click="addUser" class="btn btn-success ml-2 btn-sm" :disabled="disableButtons"><i class="mr-1 mdi mdi-account-plus"></i>Add</button>
                                                </template>
                                                <template v-else>
                                                  <button v-if="!inEditMode" @click="onDelete" class="btn btn-warning ml-2 btn-sm" :disabled="disableButtons || !user.isActive"><i class="mr-1 mdi mdi-account-remove"></i>Deactivate</button>
                                                  <button v-if="!inEditMode" @click="inEditMode = true" class="btn btn-success ml-2 btn-sm" :disabled="disableButtons"><i class="mr-1 mdi mdi-lead-pencil"></i>Edit</button>
                                                  <button v-if="inEditMode" @click="getUser" class="btn-sm btn btn-primary ml-2" :disabled="disableButtons"> <span v-if="isLoading"><i class="fa fa-spinner fa-spin mr-1"></i></span><i v-else class="mdi mdi-backup-restore mr-1"></i>Cancel</button>
                                                  <button v-if="inEditMode" @click="updateUser" class="btn btn-success ml-2 btn-sm" :disabled="disableButtons"><span v-if="isSaving"><i class="fa fa-spinner fa-spin mr-1"></i></span><i v-else class="mr-1 mdi mdi-content-save"></i>Save</button>
                                                </template>
                                              </template>
                                            </div>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane" :class="{'active': activeTab == 1}">
                                                <form role="form" autocomplete="off">
                                                    <div class="form-group">
                                                      <label for="FirstName">First Name</label>
                                                      <input type="text" v-model="user.firstName" @focus="$v.user.firstName.$reset" class="form-control" :class="{'parsley-error': $v.user.firstName.$error}" id="FirstName" :disabled="!inEditMode">
                                                      <template v-if="$v.user.firstName.$error">
                                                        <ul class="parsley-errors-list filled">
                                                          <li class="parsley-required">Required</li>
                                                        </ul>
                                                      </template>
                                                    </div>
                                                    <div class="form-group">
                                                      <label for="LastName">Last Name</label>
                                                      <input type="text" v-model="user.lastName" @focus="$v.user.lastName.$reset" class="form-control" :class="{'parsley-error': $v.user.lastName.$error}" id="LastName" :disabled="!inEditMode">
                                                      <template v-if="$v.user.lastName.$error">
                                                        <ul class="parsley-errors-list filled">
                                                          <li class="parsley-required">Required</li>
                                                        </ul>
                                                      </template>
                                                    </div>
                                                    <div class="form-group">
                                                      <label for="user-email">Email</label>
                                                      <input type="email" v-model="user.email" @focus="$v.user.email.$reset" @blur="calcUserName" class="form-control" :class="{'parsley-error': $v.user.email.$error}" id="user-email" :disabled="!inEditMode">
                                                      <template v-if="$v.user.email.$error">
                                                        <ul class="parsley-errors-list filled">
                                                          <li v-if="!$v.user.email.email" class="parsley-required">Invalid Email</li>
                                                          <li v-if="!$v.user.email.required" class="parsley-required">Required</li>
                                                        </ul>
                                                      </template>
                                                    </div>
                                                    <div class="form-group">
                                                      <label for="birthday">Birthday</label>
                                                      <date-picker v-if="inEditMode && isAdmin" v-model="user.dob" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                      <p v-else style="min-height: 38px" class="form-control bg-disabled" id="birthday">{{user.dob | birthday}}</p>
                                                    </div>
                                                    <div class="form-group">
                                                      <label for="Mobile">Mobile</label>
                                                      <input ref="mobile" type="tel" v-model="user.mobile" class="form-control" id="Mobile" :disabled="!inEditMode">
                                                    </div>
                                                    <!-- <div class="form-group">
                                                        <label for="birthday">Birthday</label>
                                                        <date-picker id="birthday" v-model="user.dob" calendar-class="calendar form-control" :disabled="!isEditMode" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                    </div> -->
                                                    <div class="form-group">
                                                      <label for="user-title">Title</label>
                                                      <div v-if="inEditMode" class="multiselect-wrapper" :class="{'parsley-error': $v.user.titleId.$error}">
                                                        <multiselect @open="$v.user.titleId.$reset" id="user-title" class="vueselect" :class="{'parsley-error': $v.user.titleId.$error}" :max-height="250" v-model="titleObj" :options="allUserTitles" label="titleName" track-by="titleId" :allow-empty="false" @input="titleSelected" :disabled="!inEditMode"></multiselect>
                                                      </div>
                                                      <p v-else class="form-control bg-disabled" id="user-title">{{user.titleName}}</p>
                                                      <template v-if="$v.user.titleId.$error">
                                                        <ul class="parsley-errors-list filled">
                                                          <li class="parsley-required">Required</li>
                                                        </ul>
                                                      </template>
                                                    </div>
                                                    <div class="form-group">
                                                      <label for="Group">Group</label>
                                                      <div v-if="inEditMode" class="multiselect-wrapper" :class="{'parsley-error': $v.user.groupId.$error}">
                                                      <multiselect @open="$v.user.groupId.$reset" id="Group" class="vueselect" v-model="groupObj" :options="allUserGroups" label="groupName" :max-height="250" track-by="groupId" :allow-empty="false" @input="groupSelected" :disabled="!inEditMode"></multiselect>
                                                      </div>
                                                      <p v-else class="form-control bg-disabled" id="Group">{{user.groupName}}</p>
                                                      <template v-if="$v.user.groupId.$error">
                                                        <ul class="parsley-errors-list filled">
                                                          <li class="parsley-required">Required</li>
                                                        </ul>
                                                      </template>
                                                    </div>
                                                    <div class="form-group">
                                                      <label for="Office">Office</label>
                                                      <div v-if="inEditMode" class="multiselect-wrapper" :class="{'parsley-error': $v.user.officeId.$error}">
                                                      <multiselect @open="$v.user.officeId.$reset" id="Office" class="vueselect" v-model="officeObj" :options="allOffices" label="officeName" :max-height="250" track-by="officeId" :allow-empty="false" @input="officeSelected" :disabled="!inEditMode"></multiselect>
                                                      </div>
                                                      <p v-else class="form-control bg-disabled" id="Group">{{user.officeName}}</p>
                                                      <template v-if="$v.user.officeId.$error">
                                                        <ul class="parsley-errors-list filled">
                                                          <li class="parsley-required">Required</li>
                                                        </ul>
                                                      </template>
                                                    </div>
                                                    <div v-if="!isNew" class="form-group">
                                                      <label for="user-roles">Roles</label>
                                                      <a v-if="isAdmin" href="javascript:void(0);" @click.prevent="showRolesModal" class="ml-2"><i class="mdi mdi-lead-pencil close-sidebar"></i></a>
                                                      <p style="min-height: 38px" id="user-roles" class="form-control bg-disabled">
                                                        <span v-for="role in rolesSorted" :key="role.roleId" style="font-size: 14.4px" class="role-item badge badge-primary">{{role.roleName}}</span>
                                                      </p>  
                                                      <!-- <p style="min-height: 38px" id="user-roles" class="form-control bg-disabled">{{roles}}</p> -->
                                                    </div>
                                                    <div class="form-group checkbox-primary checkbox" v-if="isAdmin">
                                                      <input id='is-track-meeting' type="checkbox" v-model="user.isTrackMeeting" :disabled="!inEditMode" />
                                                      <label for='is-track-meeting'>Track Meeting</label>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="tab-pane" v-if="!isNew" :class="{'active': activeTab == 2}">
                                              <table class="table table-hover table-striped m-0 table-centered" role="grid">
                                                <thead>
                                                  <tr>
                                                    <th>Date/Time</th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Id</th>
                                                    <th>Version</th>
                                                  </tr>
                                                </thead>
                                                <tbody class="font-16">
                                                  <tr v-if="browseList.length == 0">
                                                    <td colspan="5" class="text-center">
                                                      User has No History to show!
                                                    </td>
                                                  </tr>
                                                  <tr role="row" v-for="his in browseList" :key="his.logId">
                                                    <td>
                                                      <AppDate :date="his.logDate" :show="'full'"/>
                                                    </td>
                                                    <td>{{his.urlFromName}}</td>
                                                    <td>{{his.urlToName}}</td>
                                                    <td>{{his.urlToId}}</td>
                                                    <td>{{his.uiVersion}}</td>
                                                  </tr>
                                                </tbody>
                                                <tfoot>
                                                  <tr>
                                                    <td colspan="4" class="text-center">
                                                      <button type="button" class="btn btn-sm btn-primary btn-rounded waves-effect" v-if="nomore && browseList.length > 0" @click="loadMore">Load more <i v-show="isLoading" class="fa fa-spinner fa-spin"></i></button>
                                                    </td>
                                                  </tr>
                                                </tfoot>
                                              </table>
                                            </div>
                                            <div v-if="!isNew" class="tab-pane" :class="{'active': activeTab == 3}">
                                              <table class="table table-hover table-striped m-0 table-centered" role="grid">
                                                <thead>
                                                  <tr>
                                                    <th>Date/Time</th>
                                                    <th>IP Address</th>
                                                  </tr>
                                                </thead>
                                                <tbody class="font-16">
                                                  <tr v-if="signList.length == 0">
                                                    <td colspan="2" class="text-center">
                                                      User has No History to show!
                                                    </td>
                                                  </tr>
                                                  <tr role="row" v-for="his in signList" :key="his.logId">
                                                    <td>
                                                      <AppDate :date="his.createdOn" :show="'full'"/>
                                                    </td>
                                                    <td>{{his.ipAddress}}</td>
                                                  </tr>
                                                </tbody>
                                                <tfoot>
                                                  <tr>
                                                    <td colspan="2" class="text-center">
                                                      <button type="button" class="btn btn-sm btn-primary btn-rounded waves-effect" v-if="nomoreSigns && signList.length > 0" @click="loadMoreSigns">Load more <i v-show="isLoading" class="fa fa-spinner fa-spin"></i></button>
                                                    </td>
                                                  </tr>
                                                </tfoot>
                                              </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- end col -->
                        </div>
                        <Modal @close="closeRolesModal" :show="showModal && isAdmin">
                          <template #header>
                                <h3>Add roles</h3>
                                <div>
                                  <AppUserPic :userId="user.userId" :size="2" />
                                  <span class="font-12">{{user.username}}</span>
                                </div>
                          </template>
                          <template #body>
                            <p>Manage Roles for <strong>{{user.fullName}}</strong></p>
                            <div class="flex-list">
                              <button v-for="r in allUserRoles" :class="{'active': hasRole(r.roleId)}" :key="r.roleId" @click.prevent="handleRole(r)">{{r.roleName}}</button>
                            </div>
                          </template>
                          <template #footer>
                            <button @click.prevent="closeRolesModal" class="btn btn-secondary waves-effect waves-light" :disabled="disableButtons">Cancel</button>
                            <button @click.prevent="updateRoles" class="btn btn-primary waves-effect waves-light" :disabled="disableButtons"><span v-if="isSaving"><i class="fa fa-spinner fa-spin mr-1"></i></span><i v-else class="mdi mdi-account-plus"></i> Save</button>
                          </template>
                        </Modal>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { UserService, BrowseService } from '@/services/user-service'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import { required, email } from 'vuelidate/lib/validators'
import Modal from '@/components/custom/Modal.vue'
import DatePicker from 'vuejs-datepicker'



export default {
  props: {
    id: {
      required: false,
      default: null
    }
  },
  components: {
    Multiselect,
    DatePicker,
    Modal
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      inEditMode: false,
      user: null,
      browseList: [],
      signList: [],
      isLoading: false,
      isSaving: false,
      activeTab: 1,
      nomore: true,
      nomoreSigns: true,
      isNew: false,
      groupObj: null,
      titleObj: null,
      officeObj: null,
      showModal: false,
      rolesCopy: null
    }
  },
  computed: {
    ...mapGetters({
      'activeUser': 'authUser',
      'allUserGroups': 'getAllUserGroups',
      'allUserRoles': 'getAllUserRoles',
      'allUserTitles': 'getAllUserTitles',
      'allOffices': 'getAllOffices'
    }),
    // roles () {
    //   let r = ''
    //   if (this.isNew) return r
    //   else return r.concat(this.user.roles.map(x => ' ' + x.roleName)).trim()
    // },
    rolesSorted () {
      const copy = [...this.user.roles]
      return copy.sort((a, b) => {
        return a.roleName < b.roleName ? -1 : a.roleName > b.roleName ? 1 : 0
      })
    },
    isAdmin () {
      return this.activeUser.roles.some(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR)
    },
    disableButtons () {
      return this.isLoading || this.isSaving
    }
  },
  methods: {
    ...mapActions(['fetchAllUserGroups', 'fetchAllUserRoles', 'fetchAllUserTitles', 'fetchAllOffices']),
    async getTables () {
      return Promise.all([this.fetchAllUserGroups(), this.fetchAllUserRoles(), this.fetchAllUserTitles(), this.fetchAllOffices()])
    },
    hasRole(id) {
      if (!this.rolesCopy) return
      return this.rolesCopy[id]?.isActive
    },
    handleRole(r) {
      const id = r.roleId
      const activeStatus = !!this.hasRole(id) //und, true, fals
      if (!activeStatus) {
        this.$set(this.rolesCopy, id, {...r, isActive: !activeStatus, userId: this.user.userId})
      } else {
        this.$delete(this.rolesCopy, id)
      }
    },
    showRolesModal () {
      if (!this.isAdmin || !this.user.userId) return
      if (!Array.isArray(this.user.roles)) this.rolesCopy = {}
      else {
        const array2D = this.user.roles.map(obj => [obj.roleId, obj])
        this.rolesCopy = Object.fromEntries(array2D)
      }
      this.showModal = true
    },
    updateRoles () {
      this.isSaving = true
      const rolesArr = Object.values(this.rolesCopy)
      UserService.updateRoles(this.user.userId, rolesArr).then(res => {
        if (!res || res.status !== 200) throw new Error('Error Updating Roles')
        this.user = res.data
        this.$snotify.success('User Roles Updated')
        this.closeRolesModal()
      }).catch((err) => {
        this.$snotify.error(err.message)
      }).finally (() => {
        this.isSaving = false
      })
    },
    closeRolesModal () {
      this.showModal = false
      this.rolesCopy = {}
    },
    getUser () {
      this.isLoading = true
      UserService.get(this.id)
      .then((response) => {
        this.user = response.data
        // this.isLoading = false
      })
      .then(() => {
        this.loadBrowses(999999999)
      })
      .then(() => {
        this.loadSigns(999999999)
      }).then(() => {
        this.prepDropdowns()
      })
      .catch(error => this.$snotify.error('Server Error! ' + error.message))
      .finally(() => {
        this.inEditMode = false
        this.isLoading = false
      })
    },
    loadBrowses (lastId) {
      BrowseService.getList(this.id, lastId)
      .then((response) => {
        this.browseList = response.data
      })
    },
    loadSigns (lastId) {
      UserService.getSignHistory(this.id, lastId)
      .then((response) => {
        this.signList = response.data
      })
    },
    loadMore () {
      const lastId = this.browseList[this.browseList.length - 1].logId
      this.isLoading = true
      BrowseService.getList(this.id, lastId)
      .then(
        (response) => {
          if (response.data.length > 0) {
            response.data.forEach(newHis => {
              this.browseList.push(newHis)
            })
          } else {
            this.nomore = false
          }
          this.isLoading = false
        }
      )
    },
    calcUserName () {
      const name = this.$v.user.email.$invalid ? '' : this.user.email.split("@")[0]  
      this.$set(this.user, 'userName', name)
    },
    loadMoreSigns () {
      const lastId = this.signList[this.signList.length - 1].logId
      this.isLoading = true
      UserService.getSignHistory(this.id, lastId)
      .then(
        (response) => {
          if (response.data.length > 0) {
            response.data.forEach(newHis => {
              this.signList.push(newHis)
            })
          } else {
            this.nomoreSigns = false
          }
          this.isLoading = false
        }
      )
    },
    showTab (index) {
      this.activeTab = index
    },
    formatedDateAgo (date) {
      return moment(date).fromNow()
    },
    formatedDate (date) {
      return moment(date).format('M/D/YYYY, h:mm a')
    },
    groupSelected () {
      this.user = Object.assign({}, this.user, this.groupObj)
    },
    titleSelected () {
      this.user = Object.assign({}, this.user, this.titleObj)
    },
    officeSelected () {
      this.user = Object.assign({}, this.user, this.officeObj)
    },
    prepDropdowns () {
      if (this.isNew) return
      this.groupObj = this.allUserGroups.filter(x => x.groupId === this.user.groupId)
      this.titleObj = this.allUserTitles.filter(x => x.titleId === this.user.titleId)
      this.officeObj = this.allOffices.filter(x => x.officeId === this.user.officeId)
    },
    checkValidations () {
      this.$v.user.$touch()
      if (this.$v.user.$error) {
        this.$snotify.error('Fix Errors')
      }
      return this.$v.user.$error
    },
    addUser () {
      const ready = !this.checkValidations()
      if (!ready) return

      this.isSaving = true
      this.user.emailAddress = this.user.email
      if (!this.user.isTrackMeeting) this.user.isTrackMeeting = false
      if (this.user.mobile === '') this.user.mobile = null

      UserService.addUser(this.user).then(res => {
        if (!res || res.status !== 200) throw new Error('Error Adding User')
        const newUserId = res.data.userId
        this.$snotify.success('User Roles Updated')
        this.isSaving = false
        this.inEditMode = false
        this.$router.push({name: 'UserInfo', params: {id: newUserId}})
      }).catch((err) => {
        this.isSaving = false
        this.$snotify.error(err.message)
      })
    },
    updateUser () {
      const ready = !this.checkValidations()
      if (!ready) return
      this.isSaving = true
      this.user.emailAddress = this.user.email
      if (this.user.mobile === '') this.user.mobile = null

      UserService.updateUser(this.user.userId, this.user)
      .then(res => {
        if (!res || res.status !== 200) throw new Error('Error Updating User')
        this.user = res.data
        this.$snotify.success('User Updated')
        this.inEditMode = false
      })
      .catch(err => {
        this.$snotify.error(err.message)
      }).finally(() => {
        this.isSaving = false
      })
    },
    onDelete () {
      this.$snotify.clear()
      this.$snotify.confirm('Deactive user?',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                if (!this.user.userId) throw new Error('Error deleting user')
                UserService.deleteUser(this.user.userId)
                .then((res) => {
                  if (!res || res.status !== 200) throw new Error('Error Deleting User')
                  this.user.isActive = false
                  this.$snotify.success('User deactivated.')
                }).catch(err => {
                  this.$snotify.error(err.message)
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    goBack() {
      this.$router.back()
    }
  },
  filters: {
    birthday (date) {
      return moment(date).format('MMMM D')
    }
  },
  async created () {
    await this.getTables()
    if (this.id) {
      this.getUser()
    } else {
      this.user = {}
      this.isNew = true
      this.inEditMode = true
    }
    this.asyncDataStatus_fetched('User Profile')
  },

  validations: {
    user: {
      email: { required, email },
      firstName: { required },
      lastName: { required },
      titleId: { required },
      groupId: { required },
      officeId: { required }
    }
  }
}
</script>
<style scoped>
.multiselect-wrapper {
  border: 1px solid transparent;
  border-radius: 5px
}
.flex-list {
  flex-direction: column;
  display: flex;
  height: 300px;
  overflow-y: auto;
}
.flex-list button {
  color: #c1c8cc;
  border: 1px solid #98a6ad;
  background-color: transparent;
  padding: 5px 7px;
  font-size: 13px;
  transition: 0.1s ease-in-out;
}
.flex-list button.active {
  background-color: #3bafda;
  border-color: #ffffff;
  color: #ffffff
}
.flex-list button.active:hover {
  background-color: #2d9ec7;
  color: #ffffff
}
.flex-list button:hover {
  background-color: #3d4853;
  color: #ffffff;
}
.flex-list button:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.flex-list button:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.btn-warning:disabled:hover, .btn-warning.disabled:hover {
  color: #212529
}
.role-item:not(:last-child) {
  margin-right: 5px
}
</style>
