<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'contacts-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Contacts'" :icon="'mdi mdi-contacts'"/>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box table-responsive">
                                <h4 class="header-title">Contacts list</h4>
                                <!-- <p class="text-muted font-14 m-b-30">
                                    Contacts list
                                </p> -->
                                
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-6">
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div>
                                            <label>
                                                <input type="search" v-model="searchName" class="form-control" placeholder="Search by name" @keyup.enter="startSearch">
                                            </label>
                                            <button @click="startSearch" class="btn btn-icon waves-effect waves-light btn-primary m-b-5 mr-2"> <i class="fa fa-search"></i> </button>
                                            <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                        </div>
                                    </div>
                                </div>

                                <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)"> Full Name
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)"> E-Mail
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(2)"> Firm
                                          <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(3)"> Job Title
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(4)"> Address
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(5)"> Phone
                                          <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(6)"> Mobile
                                          <i v-if="sortIndex == 6 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 6 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                            
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="contact in contacts"
                                          :key="contact.contactId">
                                          <td>
                                              <router-link :to="{name: 'ContactsDetail', params: {id: contact.contactId}}">
                                                  {{ contact.fullName }}
                                              </router-link>
                                          </td>
                                          <td>{{ contact.email }}</td>
                                          <td>{{ contact.firmName }}</td>
                                          <td>{{ contact.jobTitle }}</td>
                                          <td>{{ contact.adrLine1 }}</td>
                                          <td>{{ contact.phoneNo }}</td>
                                          <td>{{ contact.mobileNo }}</td>
                                      </tr>
                                    </tbody>
                                </table>
                   
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div role="status">Page {{currentPage}} of {{totalPages}} - {{totalRecords | thousandNumber}} contacts</div>
                                    <div class="px-3">
                                          <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                              <option value="250">250</option>
                                          </select>
                                    </div>
                                    
                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end container -->
        </div>
        <!-- end content -->

        <Footer />
    </div>
</template>

<script>
import { ContactService } from '@/services/contact-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
  },
  data () {
    return {
      contacts: [],
      isLoading: null,
      searchName: '',
      pageSize: 25,
      currentPage: 1,
      sortBy: ['FirstName', 'Email', 'FirmId', 'JobTitle', 'AdrLine1', 'PhoneNo', 'MobileNo'],
      sortIndex: 0,
      sortType: 'ASC',
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0
    }
  },
  methods: {
    getList () {
      const filters = {
        'searchName': this.searchName,
        'pageSize': this.pageSize
      }
      
      localStorage.setItem('umb_contacts_filters', JSON.stringify(filters))
      this.isLoading = true
      ContactService.search(this.searchName.trim(), this.sortBy[this.sortIndex], this.sortType, this.currentPage, this.pageSize)
      .then((response) => {
        this.isLoading = false
        this.contacts = response.data.contacts
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Contacts Lists') })
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.contacts = []
      if (this.searchName == null) {
        this.searchName = ''
      }

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    resetFilter () {
      this.searchName = ''
      this.startSearch()
    }
  },
  mounted () {
    const oldFilters = JSON.parse(localStorage.getItem('umb_contacts_filters'))
    if (oldFilters != null) {
      if (oldFilters.searchName != null) {
        this.searchName = oldFilters.searchName
      }
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
    }
    this.startSearch()
  },
  computed: {
    hasFilter () {
      return this.searchName.length > 0
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  },
  mixins: [asyncDataStatus]
}
</script>
