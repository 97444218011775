<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              {{!isAdd ? 'Update ' : ''}}Strategy
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="vehicle">
      <div v-if="isAdd" class="form-group">
        <label for="tree-strategy">Sector<span class="text-danger">*</span></label>
        <Treeselect :disabled="!isAdd" @input="sectorSelected" id="tree-strategy" :class="{ 'tree-invalid': $v.vehicle.sectorId.$error }" :options="sectorOptions" v-model="sectorObj" :disable-branch-nodes="true" :showCount="true" placeholder="Select option">
          <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
        </Treeselect>
        <template v-if="$v.vehicle.sectorId.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.vehicle.sectorId.required" class="parsley-required">This value is required</li>
            <li v-if="!$v.vehicle.sectorId.double" class="parsley-required">Portfolio already has this sector</li>
          </ul>
        </template>
      </div>
      <div v-else class="form-group">
        <label for="sector-name">Sector</label>
        <p class="form-control" id="sector-name" readonly><i v-if="!sectorName" class="fa fa-spinner fa-spin"></i>{{sectorName}}</p>
      </div>

      <div v-if="clientType === 2">
        <h4>Guideline</h4>
        <div class="row" >
          <div class="col-md-6">
            <div class="form-group">
                <label for="GuidelineMin">Min<span class="text-danger" v-if="isEditMode">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">%</span>
                    </div>
                    <input type="text" v-model.number="vehicle.guidelineMin" class="form-control" :disabled="!isEditMode" id="GuidelineMin" @blur="$v.vehicle.guidelineMin.$touch()" :class="{ 'parsley-error': $v.vehicle.guidelineMin.$error }">
                </div>
                <template v-if="$v.vehicle.guidelineMin.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="!$v.vehicle.guidelineMin.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.vehicle.guidelineMin.min" class="parsley-required">This value is too small</li>
                    <li v-if="!$v.vehicle.guidelineMin.max" class="parsley-required">This value is too big</li>
                  </ul>
                </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
                <label for="GuidelineMax">Max<span class="text-danger" v-if="isEditMode">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">%</span>
                    </div>
                    <input type="text" v-model.number="vehicle.guidelineMax" class="form-control" :disabled="!isEditMode" id="GuidelineMax" @blur="$v.vehicle.guidelineMax.$touch()" :class="{ 'parsley-error': $v.vehicle.guidelineMax.$error }">
                </div>
                <template v-if="$v.vehicle.guidelineMax.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="!$v.vehicle.guidelineMax.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.vehicle.guidelineMax.min" class="parsley-required">This value is too small</li>
                    <li v-if="!$v.vehicle.guidelineMax.max" class="parsley-required">This value is too big</li>
                  </ul>
                </template>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
          <label for="targetSectorPercent">Target Position<span class="text-danger">*</span></label>
          <div class="input-group">
              <div class="input-group-prepend">
                  <span class="input-group-text">%</span>
              </div>
              <input type="text" v-model.number="vehicle.targetSectorPercent" @keyup="calPosition" placeholder="Enter New Positions" class="form-control" id="targetSectorPercent" @blur="$v.vehicle.targetSectorPercent.$touch()" :class="{ 'parsley-error': $v.vehicle.targetSectorPercent.$error || isExceed }">
          </div>
          <template v-if="$v.vehicle.targetSectorPercent.$error || isExceed">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.vehicle.targetSectorPercent.required" class="parsley-required">This value is required</li>
              <li v-if="!$v.vehicle.targetSectorPercent.min" class="parsley-required">This value is required</li>
              <li v-if="isExceed" class="parsley-required">Total commitment will exceed the max</li>
            </ul>
          </template>
      </div>

      <div class="form-group">
          <label for="targetSectorPosition">Target Position Value</label>
          <div class="input-group">
              <div class="input-group-prepend">
                  <span class="input-group-text">{{currency}}</span>
              </div>
          <input type="text" :value="vehicle.targetSectorPosition" class="form-control" id="targetSectorPosition" readonly="readonly">
          </div>
      </div>

      <div class="form-group" v-if="isAdd">
          <label for="slotNo">Number of Slots</label>
          <select class="form-control form-control-sm" v-model="vehicle.slotNo">
              <option value=0>0</option>
              <option value=1>1</option>
              <option value=2>2</option>
              <option value=3>3</option>
              <option value=4>4</option>
              <option value=5>5</option>
              <option value=6>6</option>
              <option value=7>7</option>
              <option value=8>8</option>
              <option value=9>9</option>
          </select>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect waves-light w-md" @click.prevent="onSave" :disabled="isLoading">
          <span v-if="isLoading">
            <span class="fa fa-spinner fa-spin mr-1" role="status"></span> Updating...
          </span>
          <span v-else><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</span>
        </button>
      </div>
    </form>
    <span v-else class="fa fa-spinner fa-spin mr-1" role="status">Loading...</span>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { required, requiredIf, minValue, maxValue, decimal, numeric } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    },
    clientType: {
      required: true,
      type: Number
    },
    currency: {
      required: true,
      type: String
    },
    totalCommitment: {
      required: true,
      type: Number
    },
    sectorOptions: {
      required: true,
      type: Array
    },
    sectorLabels: {
      required: true,
      type: Object
    },
    usedSectors: {
      required: true,
      type: Array
    },
    lastSum: {
      required: true,
      type: Number
    }
  },
  components: {
    Multiselect,
    Treeselect
  },
  data () {
    return {
      sectorObj: null,
      targetSectorPosition: 0,
      isExceed: false,
      isLoading: false
    }
  },
  mixins: [rightbar],
  validations: {
    vehicle: {
      sectorId: { required, min: minValue(1), double: function(x) {return !this.isAdd || !this.usedSectors.some(id => id == x)} },
      guidelineMin: { required: requiredIf(function () { return this.clientType === 2 }), numeric, min: minValue(0), max: maxValue(100) },
      guidelineMax: { required: requiredIf(function () { return this.clientType === 2 }), numeric, min: minValue(0), max: maxValue(100) },
      targetSectorPercent: { required, min: minValue(0), decimal }
    }
  },
  methods: {
    ...mapActions(['addPortfolioSector', 'updatePortfolioSector', 'checkBeforePortfolioUpdate']),
    close () {
      this.isExceed = false
      this.hideRightbar()
    },
    sectorSelected (value, id) {
      this.$v.vehicle.sectorId.$touch()
      this.vehicle.sectorId = value ? +value.slice(1) : null
    },
    onSave () {
      this.$v.vehicle.$touch()
      if (!this.$v.vehicle.$error) {
        this.isLoading = true
        this.isExceed = false
        const chkmodel = {portfolioId: this.vehicle.portfolioId, type: 'sec', paramId: this.vehicle.sectorId, newPercent: this.vehicle.targetSectorPercent}
        this.checkBeforePortfolioUpdate(chkmodel)
        .then((resonse) => {
          if (!resonse) {
            this.isExceed = true
            this.isLoading = false
          }
        })
        .then(() => {
          if (this.isExceed) {
            this.isLoading = false
            return
          }
          if (this.isAdd) {
            this.addPortfolioSector({portfolioSector: this.vehicle})
              .then((response) => {
                if (response == null) {
                  this.$snotify.error('Server Error!')
                } else {
                  this.$snotify.success('Sector Added.')
                  this.$emit('onUpdate', response, 1)
                }
              })
          } else {
            this.updatePortfolioSector({portfolioSectorId: this.vehicle.portfolioSectorId, portfolioSector: this.vehicle})
              .then((response) => {
                this.$snotify.success('Sector Updated.')
                this.$emit('onUpdate', this.vehicle, 0)
              })
          }
        })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    calPosition () {
      const c = ((this.vehicle.targetSectorPercent * this.totalCommitment) / 100).toFixed(2)
      this.vehicle.targetSectorPosition = c
    }
  },
  created () {
    // this.sectorObj = this.allSectors.filter(t => t.sectorId === this.vehicle.sectorId)
  },
  computed: {
    vehicle () {
      return this.$store.state.rb_portfolioSector
    },
    sectorName () {
      return this.sectorLabels[`C${this.vehicle.sectorId}`]
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>

<style scoped>
.tree-invalid {
  border: 1px solid var(--danger);
  border-radius: 5px
}
</style>