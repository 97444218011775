<template>
  <div>
    <Topbar/>
    <Sidebar :activeItem="'legal-add'"/>

    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid">
          <PageTitle :title="'Legal Workflow'" :icon="'mdi mdi-scale-balance'" />
          <div class="card-box">
            <h4 class="header-title m-t-0">Submit an Investment/Fund Closings request</h4>
            <p class="text-muted font-14 m-b-20">Please fill the form.</p>

            <form action="#" autocomplete="off" @submit.prevent="save">
              <div class="row">
                <div class="col-sm-6 col-xs-12">

                  <div class="form-group">
                    <label>Requestor</label>
                    <input type="text" v-model="user.fullName" disabled class="form-control">
                  </div>

                  <div class="form-group">
                    <label for="deadline" v-tooltip="'Deadline'">
                      Comments Due Date <span class="text-danger">*</span>
                    </label>
                    <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                      format="MM/dd/yyyy" :bootstrap-styling="true" :disabledDates="{to: endDate}" v-model="legalRequest.deadline" @blur="$v.legalRequest.deadline.$touch()" @input="setPickerDateDeadline" :input-class="{ 'form-control parsley-error': $v.legalRequest.deadline.$error }"></date-picker>
                    <template v-if="$v.legalRequest.deadline.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.deadline.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="closingDate">
                      Closing Date <span v-if="!legalRequest.closingDateNa" class="text-danger">*</span>
                    </label>
                    <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                      format="MM/dd/yyyy" :bootstrap-styling="true" :disabledDates="{to: endDate}" v-model="legalRequest.closingDate" @blur="$v.legalRequest.closingDate.$touch()" @input="setPickerDateClosing" :input-class="{ 'form-control parsley-error': $v.legalRequest.closingDate.$error }"></date-picker>
                    <template v-if="$v.legalRequest.closingDate.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.closingDate.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                    <div class="checkbox-primary checkbox">
                        <input id='closingDateNA' type="checkbox" v-model="legalRequest.closingDateNa" />
                        <label for='closingDateNA'>TBD</label>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      Type of investment
                      <span class="text-danger">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.investment.$error }">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="investment1" name="investment" class="custom-control-input" value=1 v-model="legalRequest.investment" @click="enableOptions">
                        <label class="custom-control-label" for="investment1">Fund</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="investment2" name="investment" class="custom-control-input" value=2 v-model="legalRequest.investment" @click="enableOptions">
                        <label class="custom-control-label" for="investment2">Co-investment</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="investment3" name="investment" class="custom-control-input" value=3 ref="secondaryInvestment" v-model="legalRequest.investment" @click="enableOptions">
                        <label class="custom-control-label" for="investment3">Secondary Investment</label>
                      </div>
                    </span>
                    <template v-if="$v.legalRequest.investment.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.investment.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="fundId" class="col-form-label">
                      Fund <span class="text-danger">*</span>
                    </label>
                    <span :class="{ 'invalid': $v.legalRequest.fundId.$error }">
                      <multiselect id="fundId" v-model="fundObj" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search"
                        @select="fundSelected" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                    </span>
                    <template v-if="$v.legalRequest.fundId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.fundId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="fundConcoleContact" class="col-form-label">
                      Fund Counsel Contact(s) Information <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="fundConcoleContact" v-model="legalRequest.fundConcoleContact" @blur="$v.legalRequest.fundConcoleContact.$touch()" :class="{ 'parsley-error': $v.legalRequest.fundConcoleContact.$error }">
                    <template v-if="$v.legalRequest.fundConcoleContact.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.fundConcoleContact.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.fundConcoleContact.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.fundConcoleContact.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="FundGeneralPartner" class="col-form-label">
                      General Partner of the Fund (Entity Name) <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="FundGeneralPartner" v-model="fundObj.generalPartner" disabled>
                  </div>

                  <div class="form-group">
                    <label for="FundManagerContact" class="col-form-label">
                      Fund Manager (Entity Name) <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="FundManagerContact" v-model="legalRequest.fundManagerContact" @blur="$v.legalRequest.fundManagerContact.$touch()" :class="{ 'parsley-error': $v.legalRequest.fundManagerContact.$error }">
                    <template v-if="$v.legalRequest.fundManagerContact.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.fundManagerContact.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.fundManagerContact.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.fundManagerContact.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="secondary-seller-contact" class="col-form-label">
                      Secondary Seller Contact Name
                    </label>
                    <input type="text" class="form-control" id="secondary-seller-contact" v-model="legalRequest.secondarySellerContact" @blur="$v.legalRequest.secondarySellerContact.$touch()" :class="{ 'parsley-error': $v.legalRequest.secondarySellerContact.$error }">
                    <template v-if="$v.legalRequest.secondarySellerContact.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.secondarySellerContact.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      Subscription Documents Due Date <span class="text-danger">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.subscriptionDueDateTypeId.$error }">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="subscriptionDueDateTypeId5" name="subscriptionDueDateTypeId" class="custom-control-input" value=5 v-model="legalRequest.subscriptionDueDateTypeId">
                        <label class="custom-control-label" for="subscriptionDueDateTypeId5">Before Closing Date</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="subscriptionDueDateTypeId1" name="subscriptionDueDateTypeId" class="custom-control-input" value=1 v-model="legalRequest.subscriptionDueDateTypeId">
                        <label class="custom-control-label" for="subscriptionDueDateTypeId1">Closing Date</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="subscriptionDueDateTypeId2" name="subscriptionDueDateTypeId" class="custom-control-input" value=2 v-model="legalRequest.subscriptionDueDateTypeId">
                        <label class="custom-control-label" for="subscriptionDueDateTypeId2">Post Closing Sufficent</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="subscriptionDueDateTypeId3" name="subscriptionDueDateTypeId" class="custom-control-input" value=3 v-model="legalRequest.subscriptionDueDateTypeId">
                        <label class="custom-control-label" for="subscriptionDueDateTypeId3">TBD</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="subscriptionDueDateTypeId4" name="subscriptionDueDateTypeId" class="custom-control-input" value=4 v-model="legalRequest.subscriptionDueDateTypeId">
                        <label class="custom-control-label" for="subscriptionDueDateTypeId4">Other</label>
                      </div>
                      <div class="form-group" v-show="legalRequest.subscriptionDueDateTypeId == 4">
                        <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                          format="MM/dd/yyyy" :bootstrap-styling="true" v-model="legalRequest.subscriptionDueDate" @blur="$v.legalRequest.subscriptionDueDate.$touch()" @input="setPickerDateSubscription" :input-class="{ 'form-control parsley-error': $v.legalRequest.subscriptionDueDate.$error }"></date-picker>
                        <template v-if="$v.legalRequest.subscriptionDueDate.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.legalRequest.subscriptionDueDate.required" class="parsley-required">This value is required</li>
                          </ul>
                        </template>
                      </div>                      
                    </span>
                    <template v-if="$v.legalRequest.subscriptionDueDateTypeId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.subscriptionDueDateTypeId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      AML/KYC Due Date <span class="text-danger">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.amlKycDueDateTypeId.$error }">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="amlKycDueDateTypeId1" name="amlKycDueDateTypeId" class="custom-control-input" value=1 v-model="legalRequest.amlKycDueDateTypeId">
                        <label class="custom-control-label" for="amlKycDueDateTypeId1">Closing Date</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="amlKycDueDateTypeId2" name="amlKycDueDateTypeId" class="custom-control-input" value=2 v-model="legalRequest.amlKycDueDateTypeId">
                        <label class="custom-control-label" for="amlKycDueDateTypeId2">Post Closing Sufficent</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="amlKycDueDateTypeId3" name="amlKycDueDateTypeId" class="custom-control-input" value=3 v-model="legalRequest.amlKycDueDateTypeId">
                        <label class="custom-control-label" for="amlKycDueDateTypeId3">TBD</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="amlKycDueDateTypeId4" name="amlKycDueDateTypeId" class="custom-control-input" value=4 v-model="legalRequest.amlKycDueDateTypeId">
                        <label class="custom-control-label" for="amlKycDueDateTypeId4">Other</label>
                      </div>
                      <div class="form-group" v-show="legalRequest.amlKycDueDateTypeId == 4">
                        <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                          format="MM/dd/yyyy" :bootstrap-styling="true" v-model="legalRequest.amlKycDueDate" @blur="$v.legalRequest.amlKycDueDate.$touch()" @input="setPickerDateAmlKycDueDate" :input-class="{ 'form-control parsley-error': $v.legalRequest.amlKycDueDate.$error }"></date-picker>
                        <template v-if="$v.legalRequest.amlKycDueDate.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.legalRequest.amlKycDueDate.required" class="parsley-required">This value is required</li>
                          </ul>
                        </template>
                      </div>                      
                    </span>
                    <template v-if="$v.legalRequest.amlKycDueDateTypeId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.amlKycDueDateTypeId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label">Subscription Documents and AML/KYC submitted through on Online</label>
                      <toggle-button v-model="legalRequest.isSubscriptionOnline" :labels="{checked: 'Yes', unchecked: 'No'}" />
                      <div class="form-group" v-show="legalRequest.isSubscriptionOnline">
                        <label for="SubscriptionOnline" class="col-form-label">Platform Link <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="SubscriptionOnline" v-model="legalRequest.subscriptionOnline" @blur="$v.legalRequest.subscriptionOnline.$touch()" :class="{ 'parsley-error': $v.legalRequest.subscriptionOnline.$error }">
                        <template v-if="$v.legalRequest.amlKycDueDate.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.legalRequest.amlKycDueDate.required" class="parsley-required">This value is required</li>
                          </ul>
                        </template>
                      </div>
                    <template v-if="$v.legalRequest.amlKycDueDateTypeId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.amlKycDueDateTypeId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      Type of relationship <span class="text-danger">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.relationship.$error }">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="relationship1" name="relationship" class="custom-control-input" value=1 v-model="legalRequest.relationship">
                        <label class="custom-control-label" for="relationship1">Re-up</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="relationship2" name="relationship" class="custom-control-input" value=2 v-model="legalRequest.relationship">
                        <label class="custom-control-label" for="relationship2">New</label>
                      </div>
                    </span>
                    <template v-if="$v.legalRequest.relationship.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.relationship.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      Type of review <span class="text-danger">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.reviewType.$error }">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="review1" name="review" class="custom-control-input" value=1 v-model="legalRequest.reviewType">
                        <label class="custom-control-label" for="review1">Full review of the docs</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="review2" name="review" class="custom-control-input" value=2 v-model="legalRequest.reviewType">
                        <label class="custom-control-label" for="review2">Red flags review</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input :disabled="disablePSA" type="radio" id="review3" name="review" class="custom-control-input" value=3 v-model="legalRequest.reviewType">
                        <label class="custom-control-label" for="review3">Provide / Review PSA (For Secondary Transactions Only)</label>
                      </div>
                    </span>
                    <template v-if="$v.legalRequest.reviewType.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.reviewType.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="FundDocument" class="col-form-label">
                      Fund Documents for Review (LPA, Subscription Agreement) <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="FundDocument" v-model="legalRequest.fundDocument" @blur="$v.legalRequest.fundDocument.$touch()" :class="{ 'parsley-error': $v.legalRequest.fundDocument.$error }">
                    <template v-if="$v.legalRequest.fundDocument.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.fundDocument.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.fundDocument.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.fundDocument.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="InvestmentAuthorization" class="col-form-label">
                      Investment Authorization <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="InvestmentAuthorization" v-model="legalRequest.investmentAuthorization" @blur="$v.legalRequest.investmentAuthorization.$touch()" :class="{ 'parsley-error': $v.legalRequest.investmentAuthorization.$error }">
                    <template v-if="$v.legalRequest.investmentAuthorization.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.investmentAuthorization.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.investmentAuthorization.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.investmentAuthorization.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="NegotiationPower" class="col-form-label">
                      Negotiation Power <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="NegotiationPower" v-model="legalRequest.negotiationPower" @blur="$v.legalRequest.negotiationPower.$touch()" :class="{ 'parsley-error': $v.legalRequest.negotiationPower.$error }">
                    <template v-if="$v.legalRequest.negotiationPower.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.negotiationPower.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.negotiationPower.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.negotiationPower.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="other" class="col-form-label">
                      Any other relevant information
                    </label>
                    <textarea class="form-control" rows="5" id="other" v-model="legalRequest.comments" @blur="$v.legalRequest.comments.$touch()" :class="{ 'parsley-error': $v.legalRequest.comments.$error }"></textarea>
                    <template v-if="$v.legalRequest.comments.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.comments.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                      <div class="checkbox checkbox-primary">
                          <input id="checkbox6a" type="checkbox" v-model="addMore">
                          <label for="checkbox6a">
                              Add Another
                          </label>
                      </div>
                  </div>

                  <div class="form-group text-right m-b-0">
                    <router-link :to="{name: 'LegalNew'}">
                        <button type="button" class="btn btn-secondary waves-effect mr-2">
                            Back
                        </button>
                    </router-link>

                    <button v-if="user.groupId < 11" class="btn btn-primary waves-effect waves-light" type="submit">Submit Request</button>
                  </div>

                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import {mapActions, mapGetters} from 'vuex'
import { required, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { LegalIfcService } from '@/services/legal-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import moment from 'moment'

export default {
  props: {
    id: {
      required: false,
      type: Number
    }
  },
  components: {
    DatePicker,
    Multiselect
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      legalRequest: {},
      endDate: null,
      fundObj: {},
      searchFunds: [],
      isSearchingList: false,
      addMore: false,
      disablePSA: true
    }
  },
  validations: {
    legalRequest: {
      deadline: { required },
      closingDate: { required: requiredIf(function () { return !this.legalRequest.closingDateNa }) },
      investment: { required },
      fundId: { required },
      relationship: { required },
      reviewType: { required },
      subscriptionDueDateTypeId: { required },
      subscriptionDueDate: { required: requiredIf(function () { return parseInt(this.legalRequest.subscriptionDueDateTypeId) === 4 }) },
      amlKycDueDateTypeId: { required },
      amlKycDueDate: { required: requiredIf(function () { return parseInt(this.legalRequest.amlKycDueDateTypeId) === 4 }) },
      subscriptionOnline: { required: requiredIf(function () { return this.legalRequest.IsubscriptionOnline }) },
      fundDocument: { required, min: minLength(2), max: maxLength(150) },
      investmentAuthorization: { required, min: minLength(2), max: maxLength(150) },
      negotiationPower: { required, max: maxLength(100) },
      fundConcoleContact: { required, max: maxLength(100) },
      fundManagerContact: { required, max: maxLength(100) },
      // add helper val
      secondarySellerContact: { max: maxLength(100) },
      comments: { max: maxLength(500) }
    }
  },
  methods: {
    ...mapActions(['searchAllFunds', 'fetchFund']),
    fundSelected (value, id) {
      this.legalRequest.fundId = value ? value.fundId : null
      this.$v.legalRequest.fundId.$touch()
      this.$v.legalRequest.fundId.$reset()
      if (!this.legalRequest.fundId) return
      this.fetchFund({fundId: this.legalRequest.fundId})
      .then((fResponse) => {
        this.fundObj = fResponse.data
        if (this.fundObj.fundTypeId == 3) {
          this.legalRequest.investment = 2
          this.disablePSA = true
        } else if (this.fundObj.fundTypeId == 2) {
          this.legalRequest.investment = 3
          this.disablePSA = false
        } else {
          this.legalRequest.investment = 1
          this.disablePSA = true
          }
      }) 
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    },
    enableOptions () {
      this.disablePSA = !this.$refs.secondaryInvestment.checked
    },
    reset () {
      this.legalRequest = {
        deadline: null,
        investment: null,
        fundId: null,
        relationship: null,
        reviewType: null,
        subscriptionDueDateTypeId: null,
        subscriptionDueDate: null,
        amlKycDueDateTypeId: null,
        amlKycDueDate: null,
        subscriptionOnline: null,
        krollBackgroundCheck: null,
        krollBackgroundCheckDueDate: null,
        negotiationPower: null,
        fundConcoleContact: null,
        fundManagerContact: null,
        secondarySellerContact: null,
        fundDocument: null,
        investmentAuthorization: null,
        comments: null
      }

      this.fundObj = null
      this.$v.$reset()
    },
    save () {
      this.$v.legalRequest.$touch()
      if (this.$v.legalRequest.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      this.legalRequest.requestorId = this.user.userId

      LegalIfcService.add(this.legalRequest)
      .then(
        (response) => {
          this.$snotify.success('Investment/Fund Closings Request Submitted.')
          if (!this.addMore) {
            setTimeout(() => {
              // this.$router.push({name: 'LegalList'})
              this.$router.push({name: 'LegalIfc', params: {id: response.data.requestId}})
            }, 400)
          } else {
            this.reset()
          }
        })
        .catch(function (error) {
          console.error('SERVER Error:', error)
        })
    },
    setPickerDateDeadline (value) {
      if (value != null) {
        this.legalRequest.deadline = moment(value).format('YYYY-M-D')
      } else {
        this.legalRequest.deadline = null
      }
    },
    setPickerDateClosing (value) {
      if (value != null) {
        this.legalRequest.closingDate = moment(value).format('YYYY-M-D')
      } else {
        this.legalRequest.closingDate = null
      }
    },
    setPickerDateSubscription (value) {
      if (value != null) {
        this.legalRequest.subscriptionDueDate = moment(value).format('YYYY-M-D')
      } else {
        this.legalRequest.subscriptionDueDate = null
      }
    },
    setPickerDateAmlKycDueDate (value) {
      if (value != null) {
        this.legalRequest.amlKycDueDate = moment(value).format('YYYY-M-D')
      } else {
        this.legalRequest.amlKycDueDate = null
      }
    },
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  },
  created () {
    if(this.id > 0) {
      this.legalRequest.fundId = this.id
      this.$v.legalRequest.fundId.$touch()
      this.$v.legalRequest.fundId.$reset()

      this.fetchFund({fundId: this.legalRequest.fundId})
      .then((fResponse) => {
        this.fundObj = fResponse.data
        if (this.fundObj.fundTypeId == 3) {
          this.legalRequest.investment = 2
        } else if (this.fundObj.fundTypeId == 2) {
          this.legalRequest.investment = 3
          this.disablePSA = false
        } else this.legalRequest.investment = 1
      })     
    }
    this.endDate = new Date(Date.now())
    this.asyncDataStatus_fetched('Submit a Legal Workflow request')
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
