<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portfolio-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="portfolioVote">

                    <PageTitle :title="'Portfolio Voting Details - ' + portfolioVote.typeName"/>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="mb-3">
                          <router-link :to="{name: 'PortfolioDetail', params: {id: portfolioVote.portfolioId}}">
                            <button class="btn btn-primary btn-custom waves-effect w-md waves-light m-b-5 mr-2"><i class="mdi mdi-keyboard-backspace"></i> Back</button>
                          </router-link>
                          <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5" v-if="!portfolioVote.isFinalized && isICMember" @click="onSubmitVote"> <i class="mdi mdi-checkbox-marked-outline m-r-5"></i> <span>Vote <i v-show="isLoading" class="fa fa-spinner fa-spin"></i></span> </button>
                          
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <form action="#" autocomplete="off" style="width:100%">
                        <div class="col-md-12">
                            <div class="card-box">
                              <h4 class="header-title text-primary m-t-0">Voting Summary {{portfolioVote.isFinalized ? '(Closed)' : ''}}</h4>
                              <p class="text-muted font-14 m-b-20">
                                <strong>{{portfolioVote.portfolioName}}</strong> <span v-if="portfolioVote.isFinalized" class="header-title" :class="{'text-danger' : !portfolioVote.isApproved, 'text-success' : portfolioVote.isApproved}">{{portfolioVote.isApproved ? 'Approved' : 'Declined'}}</span>
                              </p>
                              <form action="#" autocomplete="off" class="vote-summary">
                                <div class="row">
                                  <div class="col-sm-6 col-xs-12">

                                    <div class="form-group">
                                      <table class="table table-hover">
                                        <thead>
                                          <tr>
                                            <th>Vote</th>
                                            <th>Count</th>
                                            <th>Percentage</th>
                                          </tr>
                                        </thead>
                                        <tbody v-if="portfolioVote.isDisplayVotes">
                                          <tr v-for="(stat, index) in statData" :key="index">
                                            <td>{{portfolioVote.isDisplayVotes ? stat.rate : '?'}}</td>
                                            <td>{{stat.result}}</td>
                                            <td>{{stat.percent.toFixed(0)}}%</td>
                                          </tr>
                                        </tbody>
                                        <tfoot>
                                          <tr>
                                            <td class="text-right">Total:</td>
                                            <td>{{portfolioVote.votes.length}}</td>
                                            <td></td>
                                          </tr>
                                        </tfoot>
                                      </table>
                                    </div>

                                    <div class="form-group" v-if="portfolioVote.comments">
                                      <h4 class="header-title text-primary">Comments</h4>
                                      <p class="vote-question">{{portfolioVote.comments}}</p>
                                    </div>

                                    <div class="form-group" v-if="portfolioVote.votes.filter(x => x.comment).length > 0">
                                      <h4 class="header-title text-primary">Questions</h4>
                                      <ul>
                                        <li v-for="(vote, index) in portfolioVote.votes.filter(x => x.comment != null)" :key="vote.votingResultId">
                                          <div class="checkbox-primary" :class="{'checkbox' : !portfolioVote.isFinalized}" @mouseover="hoverShow = 'v'+vote.votingResultId" @mouseleave="hoverShow = null">
                                              <input :id="`comm`+vote.votingResultId" type="checkbox" v-if="!portfolioVote.isFinalized" v-model="comm[index]" class="voting-summary-checkbox" />
                                              <label :for="`comm`+vote.votingResultId">
                                                <span class="vote-question" :class="{'del-text' : comm[index]}">{{vote.comment}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                                                <button class="btn btn-primary btn-sm btn-mini btn-icon" v-tooltip="'Answer'" type="submit" @click.prevent="showAnswer(vote.votingResultId)" v-if="!portfolioVote.isFinalized && hoverShow == 'v'+vote.votingResultId" >
                                                    <i class="mdi mdi-comment-plus-outline"></i>
                                                </button>
                                              </label>
                                          </div>
                                          <ul>
                                            <li v-for="(answer, index) in portfolioVote.answers.filter(x => x.votingResultId === vote.votingResultId)" :key="answer.votingAnswerId">
                                              <div class="checkbox-primary" :class="{'checkbox' : !portfolioVote.isFinalized}" @mouseover="hoverShow = 'a'+answer.votingAnswerId" @mouseleave="hoverShow = null">
                                                  <input :id="`answer`+answer.votingAnswerId" type="checkbox" v-if="!portfolioVote.isFinalized" v-model="answer[index]" class="voting-summary-checkbox" />
                                                  <label :for="`answer`+answer.votingAnswerId">
                                                    <span class="vote-question" :class="{'del-text' : answer[index]}">{{answer.answer}}</span> <i class="text-dark">{{showUserName(answer.createdBy)}} - <AppDate :date="answer.createdOn" :show="'label'"/></i>
                                                    <button class="btn btn-primary btn-sm btn-mini btn-icon" v-tooltip="'Answer'" type="submit" @click.prevent="showAnswer(vote.votingResultId)" v-if="!portfolioVote.isFinalized && hoverShow == 'a'+answer.votingAnswerId">
                                                        <i class="mdi mdi-comment-plus-outline"></i>
                                                    </button>
                                                  </label>
                                              </div>
                                            </li>
                                          </ul>
                                          <div v-show="vote.votingResultId == answeIndex">
                                            <textarea class="form-control" rows="3" id="answer" v-model.trim="form.answer" @blur="$v.form.answer.$touch()" :class="{ 'parsley-error': $v.form.answer.$error }"></textarea>
                                            <template v-if="$v.form.answer.$error">
                                              <ul class="parsley-errors-list filled">
                                                <li v-if="!$v.form.answer.required" class="parsley-required">This value is required</li>
                                              </ul>
                                            </template>
                                            <button class="btn btn-success btn-sm btn-icon mr-1" v-tooltip="'Submit Answer'" type="submit" @click.prevent="submitAnswer" >
                                                <i v-if="isAnswering" class="fa fa-spinner fa-spin"></i>
                                                <i v-else class="mdi mdi-content-save"></i>
                                            </button>
                                            <button class="btn btn-primary btn-sm btn-icon" v-tooltip="'Close'" type="submit" @click.prevent="hideAnswer" >
                                                <i class="mdi mdi-close-circle"></i>
                                            </button>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>

                                    <div class="form-group" v-if="portfolioVote.missingVotes.length > 0">
                                      <h4 class="header-title text-warning m-t-0">Missing Votes:</h4>
                                      <ol>
                                        <li v-for="mis in portfolioVote.missingVotes" :key="mis.userId">
                                          {{mis.fullName}}
                                        </li>
                                      </ol>
                                    </div>
                                    
                                  </div>

                                  <div class="col-sm-6 col-xs-12" v-if="portfolioVote.isDisplayVotes">
                                    <h4 class="text-center mb-3 mt-3">Committee Members</h4>
                                    <div class="d-flex justify-content-center" v-if="showPie">
                                      <pie-chart :chartData="pieData" />
                                    </div>

                                    <div class="mt-4">
                                        <table class="table table-bordered table-hover">
                                          <thead>
                                            <tr>
                                              <th>
                                                Committee Member
                                              </th>
                                              <th>
                                                Vote
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td colspan="2" v-if="icVotes.length == 0" class="text-center">
                                                No Voting to Show
                                              </td>
                                            </tr>
                                            <tr v-for="(vote, index) in icVotes" :key="index">
                                              <td>
                                                <AppUser :userId="vote.createdBy" :show="'text'" :showpic="true"/>
                                              </td>
                                              <td>
                                                {{vote.score == 1 ? 'Yes' : vote.score == null ? '-' : 'No'}}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>                  
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-sm-6 col-xs-12">
                                    <div class="form-group text-right ml-3">
                                      <button class="btn btn-primary btn-custom waves-effect w-md waves-light mr-2" v-if="canFinalize && !portfolioVote.isDisplayVotes" @click.prevent="showVotes"><i class="mdi mdi-eye-outline"></i> <span>Display Votes</span></button>
                                      <button class="btn btn-primary btn-custom waves-effect w-md waves-light" v-if="canFinalize && portfolioVote.isDisplayVotes && !portfolioVote.isFinalized" @click.prevent="finalize"><i class="mdi mdi-approval"></i> <span>Close Voting</span></button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                        </div>
                      </form>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <SubmitVote @onUpdate="onVoteUpdate" :vote="sideVote" :type="1"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { PortfolioVotingService, PortfolioVotingResultService } from '@/services/portfolio-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import PieChart from '@/components/charts/pieChart'
import { mapGetters } from 'vuex'
import rightbar from '@/mixins/rightbar'
import SubmitVote from '@/components/portfolio/SubmitVote'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    PieChart,
    SubmitVote
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      activeTab: 1,
      portfolioVote: null,
      showLogs: false,
      statData: [],
      showPie: false,
      pieData: null,
      yesCount: 0,
      noCount: 0,
      comm: [],
      sideVote: {},
      hoverShow: false,
      isLoading: false,
      isAnswering: false,
      answeIndex: null,
      form: {answer: null}
    }
  },
  validations: {
    form: {
      answer: { required }
    }
  },
  mixins: [asyncDataStatus, rightbar],
  methods: {
    getDetails () {
      PortfolioVotingService.get(this.id)
      .then((response) => {
        this.portfolioVote = response.data
        this.makeDataSet()
        this.pieData = {
          datasets: [
            {
              data: [this.yesCount, this.noCount],
              backgroundColor: ['#5aa762', '#007177']
            }
          ],
          labels: ['Yes', 'No']
        }
      })
      .then(() => {
        this.showPie = true
        this.asyncDataStatus_fetched('Portfolio Voting Details')
      })
    },
    showTab (tabId) {
      this.activeTab = tabId
    },
    makeDataSet () {
      const t = this.portfolioVote.votes.length
      const ry = this.portfolioVote.votes.filter(x => x.score != null && x.score && x.roleId > 0).length
      if (ry > 0) {
        this.statData.push({rate: 'Approve', result: ry, percent: (ry / t) * 100})
        this.yesCount = ry
      }

      const rn = this.portfolioVote.votes.filter(x => x.score != null && !x.score && x.roleId > 0).length
      if (rn > 0) {
        this.statData.push({rate: 'Not Approve', result: rn, percent: (rn / t) * 100})
        this.noCount = rn
      }
    },
    showVotes () {
      this.$snotify.clear()
      if (this.portfolioVote.missingVotes.length > 0) {
        this.$snotify.warning('Portfolio is missing votes from Committee members!')
      }

      this.$snotify.confirm('Are you sure you want to Display Votes? It cannot be undone!', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                PortfolioVotingService.showVotes(this.id)
                .then((response) => {
                  if (response.data) {
                    this.portfolioVote.isDisplayVotes = true
                    this.$snotify.success('Done.')
                  }
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    finalize () {
      this.$snotify.clear()
      if (this.portfolioVote.missingVotes.length > 0) {
        this.$snotify.warning('Portfolio is missing votes from Committee members!')
      }

      this.$snotify.confirm('Are you sure you want to finalize? It cannot be undone!', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                PortfolioVotingService.finalize(this.id)
                .then((response) => {
                  this.portfolioVote.isFinalized = true
                  this.canFinalize = false
                  this.$snotify.clear()
                  this.$snotify.success('Voting closed.')
                  if (response.data) {
                    this.portfolioVote.isApproved = response.data
                    this.$snotify.info('Portfolio Approved!')
                  } else {
                    this.$snotify.info('Portfolio Declined!')
                  }
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    onSubmitVote () {
      this.isLoading = true
      PortfolioVotingResultService.check(this.id)
      .then(
        (response) => {
          if (response.status !== 200) {
            this.sideVote = {portfolioVotingId: this.id, score: 'null'}
          } else {
            this.sideVote = response.data
            this.sideVote.portfolioVotingId = this.id
          }
          this.isLoading = false
          this.showRightbar()
        }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    onVoteUpdate (vt) {
      if (vt == null) {
        this.portfolioVote.isFinalized = true
        this.hideRightbar()
        return
      }
      if (vt.score != null) {
        const i = this.portfolioVote.missingVotes.findIndex(x => x.createdBy === this.user.userId)
        if (i > -1) {
          this.portfolioVote.missingVotes.splice(i, 1)
        }
      }
      this.hideRightbar()
    },
    showAnswer (id) {
      if (this.answeIndex === id) {
        this.answeIndex = null
      } else {
        this.$v.form.$reset()
        this.form.answer = null
        this.answeIndex = id
      }
    },
    hideAnswer () {
      this.answeIndex = null
    },
    showUserName (userId) {
      let result = ''
      const usr = this.allUsers.find(e => e.userId === userId)
      if (this.userId === 0) {
        return 'System'
      }
      if (usr != null) {
        return usr.firstName + ' ' + usr.lastName
      }
      return result
    },
    submitAnswer () {
      this.$v.form.$touch()
      if (this.$v.form.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      const ans = { 'votingResultId': this.answeIndex, 'answer': this.form.answer, 'createdOn': new Date(), 'createdBy': this.user.userId }
      this.isAnswering = true
      PortfolioVotingResultService.answer(this.answeIndex, ans)
      .then(
        (response) => {
          if (response.status === 200) {
            this.portfolioVote.answers.push(ans)
            this.$snotify.success('Your Answer submitted.')
          }
          this.isAnswering = false
          this.form.answer = null
          this.answeIndex = null
        }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    proformaClass (obj) {
      let c = 'text-danger'
      if (obj.proforma >= obj.guidelineMin && obj.proforma <= obj.guidelineMax) {
        c = 'text-success'
      }
      return c
    },
    sectorSlotTotal (sector) {
      let delAmount = 0
      const dels = this.portfolioVote.slots.filter(x => x.sectorName === sector && x.isDeleted)
      if (dels.length > 0) {
        delAmount = dels.map(m => m.targetPosition).reduce((a, b) => a + b)
      }
      return this.portfolioVote.slots.filter(x => x.sectorName === sector && !x.isDeleted).map(m => m.targetPosition).reduce((a, b) => a + b) - delAmount
    },
    proformaCompairClass (value) {
      let c = ''
      if (value > 0) {
        c = 'text-success'
      } else if (value < 0) {
        c = 'text-danger'
      }
      return c
    },
    proformaCompairBadge (value) {
      let c = ''
      if (value > 0) {
        c = 'badge-success'
      } else if (value < 0) {
        c = 'badge-danger'
      }
      return c
    },
    proformaCompairSign (value) {
      let c = ''
      if (value > 0) {
        c = 'mdi-arrow-up-bold'
      } else if (value < 0) {
        c = 'mdi-arrow-down-bold'
      } else {
        c = 'mdi mdi-arrow-right-bold'
      }
      return c
    },
    proformaCalc (obj) {
      return (obj.proforma - obj.proformaOld).toFixed(2)
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    total () {
      if (this.portfolioVote.slots.length === 0) {
        return 0
      }
      let delAmount = 0
      const dels = this.portfolioVote.slots.filter(x => x.isDeleted)
      if (dels.length > 0) {
        delAmount = dels.map(m => m.targetPosition).reduce((a, b) => a + b)
      }
      return this.portfolioVote.slots.filter(x => !x.isDeleted).map(m => m.targetPosition).reduce((a, b) => a + b) - delAmount
    },
    overCommit () {
      return parseInt((this.total / this.portfolioVote.totalCommitment) * 100 * 100) / 100
    },
    allUsers () {
      return this.$store.state.allUsers
    },
    isICMember () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.INVESTMENT_COMMITTEE).length > 0
    },
    symbol () {
      return this.portfolioVote.curSymbol
    },
    sectorlist () {
      return [...new Set(this.portfolioVote.slots.map(x => x.sectorName))]
    },
    icVotes () {
      return this.portfolioVote.votes.filter(x => x.roleId === this.$userRoles.INVESTMENT_COMMITTEE)
    },
    canFinalize () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.BUSINESS_COMMITTEE).length > 0
    },
    lastPositionClass () {
      let lp = 'badge-danger'
      if (this.portfolioVote.isApproved) {
        lp = 'badge-success'
      }
      return lp
    }
  },
  created () {
    this.getDetails()
  }
}
</script>
