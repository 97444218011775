import { Radar, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins

export default {
  extends: Radar,
  mixins: [reactiveProp],
  // props: ['options'],
  props: {
    radardata: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      baseOption: {
        responsive: true,
        tooltips: false,
        maintainAspectRatio: false,
        legend: {
          position: 'top'
        },
        scale: {
          ticks: {
            display: false,
            beginAtZero: true,
            maxTicksLimit: 5,
            min: 0,
            max: 5
          },
          pointLabels: {
            fontSize: 14,
            fontStyle: '300',
            fontColor: 'rgba(204, 204, 204, 1)',
            fontFamily: "'Lato', sans-serif"
          },
          angleLines: {
            display: true,
            color: 'rgba(128, 128, 128, 0.2)'
          }
        },
        plugins: {
          datalabels: {
            color: '#007bff',
            opacity: 1,
            textAlign: 'left',
            borderColor: 'rgba(128, 128, 128, 0.2)',
            backgroundColor: 'white',
            borderWidth: 2,
            borderRadius: 50,
            labels: {
              title: {
                font: {
                  size: 11,
                  weight: 'bold'
                }
              }
            }
          }
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.radardata, this.baseOption)
  },
  watch: {
    radardata () {
      this.$data._chart.update()
    }
  }
}
