<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Add a Contact
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <p>Please choose the best option</p>
      <div>
          <div class="custom-control custom-radio">
            <input @input='resetNew' type="radio" id="contactType1" value=1 v-model="searchRadio" name="radioInput1" class="custom-control-input">
            <label class="custom-control-label" for="contactType1">Find Existing Contact</label>
          </div>
        <div class="form-group col-md-11 m-2" v-if="searchRadio == 1">
          <multiselect id="searchContact"
            @input='$v.contactObj.$touch()'
            v-model="contactObj" 
            :options="searchContact" 
            label="email" 
            track-by="contactId" 
            :allow-empty="true" 
            placeholder="Type to Search"
            @search-change="asyncSearchContact" 
            :loading="isSearchingList" 
            :options-limit="50">
          </multiselect>
          <template v-if="$v.contactObj.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.contactObj.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" id="contactType2" value=2 v-model="searchRadio" name="radioInput2" @input='resetExisting' class="custom-control-input">
          <label class="custom-control-label" for="contactType2">Add New Contact</label>
        </div>
        <div class='form-group col-md-11 m-2' v-if="searchRadio == 2">
            <div class="form-group" >
              <label for="first-name">First Name<span class="text-danger">*</span></label>
              <input type='text' maxlength='50' name='first-name' id='first-name' class='form-control' placeholder='Enter First Name' v-model='newContact.firstName' @blur='$v.newContact.firstName.$touch()' :class="{ 'parsley-error': $v.newContact.firstName.$error }"/>
              <template v-if="$v.newContact.firstName.$error">
                <ul class="parsley-errors-list filled">
                  <li v-if="!$v.newContact.firstName.required" class="parsley-required">This value is required</li>
                </ul>
              </template>
            </div>
            <div class="form-group" >
              <label for="last-name">Last Name<span class="text-danger">*</span></label>
              <input type='text' maxlength='50' name='last-name' id='last-name' class='form-control' placeholder='Enter Last Name' v-model='newContact.lastName' @blur='$v.newContact.lastName.$touch()' :class="{ 'parsley-error': $v.newContact.lastName.$error }"/>
              <template v-if="$v.newContact.lastName.$error">
                <ul class="parsley-errors-list filled">
                  <li v-if="!$v.newContact.lastName.required" class="parsley-required">This value is required</li>
                </ul>
              </template>
            </div>
            <div class="form-group" >
              <label for="name">Email<span class="text-danger">*</span></label>
              <input type='email' maxlength='100' name='email' id='email' class='form-control' placeholder='Enter Email Address' v-model='newContact.email' @blur='$v.newContact.email.$touch()' :class="{ 'parsley-error': $v.newContact.email.$error }"/>
              <template v-if="$v.newContact.email.$error">
                <ul class="parsley-errors-list filled">
                  <li v-if="!$v.newContact.email.required" class="parsley-required">This value is required</li>
                </ul>
                <ul class="parsley-errors-list filled">
                  <li v-if="$v.newContact.email.$error" class="parsley-required">Please enter a valid email address</li>
                </ul>
              </template>
            </div>
            <div class="form-group" >
              <label for="job-title">Job Title</label>
              <input type='text' maxlength='200' name='job-title' id='job-title' class='form-control' placeholder='Enter Job Title' v-model='newContact.jobTitle'/>
            </div>
            <div class="form-group" >
              <label for="phone-number">Phone Number</label>
              <input type='tel' maxlength='20' name='phone-number' id='phone-number' class='form-control' placeholder='Enter Phone Number' v-model='newContact.phoneNo'/>
            </div>
            <div class="form-group" >
              <label for="mobile-number">Mobile Number</label>
              <input type='tel' maxlength='20' name='mobile-number' id='mobile-number' class='form-control' placeholder='Enter Mobile Number' v-model='newContact.mobileNo'/>
            </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary m-1" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button :disabled='searchRadio == 2 && $v.newContact.$anyError || searchRadio == 2 && !$v.$anyDirty || (searchRadio == 1 && !$v.contactObj.$anyDirty || $v.contactObj.$anyError)' class="btn btn-success waves-effect w-md waves-light m-1" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Add</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { CalMeetingService } from '@/services/calmeeting-service'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    Multiselect
  },
  props: {
    meetingId: {
      type: Number,
      default: null
    }
  },
  validations: {
    contactObj: { required },
    newContact: {
      firstName: { required },
      lastName: { required },
      email: { required, email }
    }
  },
  data () {
    return {
      searchContact: [],
      isSearchingList: false,
      contactObj: null,
      searchRadio: 1,
      newContact: {
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: '',
        phoneNo: '',
        mobileNo: ''
      }
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['searchAllContacts']),
    resetExisting () {
      this.contactObj = null
      this.$v.contactObj.$reset()
    },
    resetNew () {
      this.$v.newContact.$reset()
      this.newContact = {
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: '',
        phoneNo: '',
        mobileNo: ''
      }
    },
    close () {
      this.$v.contactObj.$reset()
      this.$v.newContact.$reset()
      this.searchRadio = 1
      this.contactObj = null
      this.newContact = {
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: '',
        phoneNo: '',
        mobileNo: ''
      }
      this.hideRightbar()
    },
    contactSelected (value) {
      this.$emit('contactSelected', value)
    },
    saveConfirmed () {
      this.$v.contactObj.$reset()
      this.$v.newContact.$reset()
      this.searchRadio = 1
      this.contactObj = null
      this.newContact = {
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: '',
        phoneNo: '',
        mobileNo: ''
      }
      this.$emit('saved')
    },
    onSave () {
      if (this.$v.contactObj.$anyError || this.$v.newContact.$anyError) {
        this.$snotify.error('Server Error a')
        return
      }
      if (this.searchRadio === 1) {
        CalMeetingService.assignContact(this.meetingId, this.contactObj.contactId)
        .then((response) => {
          this.hideRightbar()
          this.$snotify.success('Meeting Updated.')
          this.saveConfirmed()
        }).catch(() => this.$snotify.error('Server Error. User may already be added to list.'))
      } else {
        CalMeetingService.addContact(this.meetingId, this.newContact)
        .then((response) => {
          this.hideRightbar()
          this.$snotify.success('Meeting Updated.')
          this.saveConfirmed()
        }).catch(() => this.$snotify.error('Server Error C'))
      }
    },
    asyncSearchContact (query) {
      this.isSearchingList = true
      this.searchAllContacts({query}).then((result) => {
        this.searchContact = result
        this.isSearchingList = false
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>