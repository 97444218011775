<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'dashboard'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Browses'" :icon="'mdi mdi-edge'"/>
                    <div class="row">
                      <div class="col-12">
                          <h4 class="m-t-0 header-title mb-4">Browse History</h4>
                          <div class="card-box">
                              <div class="container-fluid">
                                <div class="row">
                                  <div class="col-md-12 table-responsive">
                                    <table class="table table-hover table-striped m-0 table-centered" role="grid">
                                      <thead>
                                        <tr>
                                          <th>User</th>
                                          <th>Date/Time</th>
                                          <th>From</th>
                                          <th>To</th>
                                          <th>Id</th>
                                          <th>Version</th>
                                        </tr>
                                      </thead>
                                      <tbody class="font-16">
                                        <tr v-if="browseList.length == 0">
                                          <td colspan="5" class="text-center">
                                            User has No History to show!
                                          </td>
                                        </tr>
                                        <tr role="row" v-for="his in browseList" :key="his.logId">
                                          <td>
                                              <img :src="pic(his.userId)" class="img-responsive rounded-circle thumb-sm" alt="user">
                                              <span class="ml-2">{{userName(his.userId)}}</span>
                                          </td>
                                          <td>
                                            <AppDate :date="his.logDate" :show="'full'"/>
                                          </td>
                                          <td>{{his.urlFromName}}</td>
                                          <td>{{his.urlToName}}</td>
                                          <td>{{his.urlToId}}</td>
                                          <td>{{his.uiVersion}}</td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colspan="6" class="text-center">
                                            <button type="button" class="btn btn-sm btn-primary btn-rounded waves-effect" v-if="nomore && browseList.length > 0" @click="loadMore">Load more <i v-show="isLoading" class="fa fa-spinner fa-spin"></i></button>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div><!-- end col -->
                    </div>
              </div>
              <!-- end content -->
              <Footer />
        </div>
    </div>
    </div>

</template>

<script>
import { mapActions } from 'vuex'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { BrowseService } from '@/services/user-service'
import moment from 'moment'

export default {
  components: {
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      isLoading: null,
      browseList: [],
      nomore: true,
      readMore: false
    }
  },
  created () {
    this.fetchAllUsers()
    BrowseService.getLast(999999999)
    .then(
      (response) => {
        this.browseList = response.data
      }
    ).then(() => {
      this.asyncDataStatus_fetched('Browse History')
    })
  },
  methods: {
    ...mapActions(['fetchAllUsers']),
    readAll () {
      if (this.browseList.length > 0) {
        const lastId = this.browseList[0].logId
        BrowseService.dismiss(lastId).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.readMore = true
              this.browseList
                .filter(x => x.readOn == null)
                .forEach(read => {
                  read.readOn = new Date()
                })
            }
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      }
    },
    loadMore () {
      const lastId = this.browseList[this.browseList.length - 1].logId
      this.isLoading = true
      BrowseService.getLast(lastId)
      .then(
        (response) => {
          if (response.data.length > 0) {
            response.data.forEach(newNfy => {
              this.browseList.push(newNfy)
            })
          } else {
            this.nomore = false
          }
          this.isLoading = false
        }
      )
    },
    formatedDateAgo (date) {
      return moment(date).fromNow()
    },
    formatedDate (date) {
      return moment(date).format('M/D/YYYY, h:mm a')
    },
    pic (userId) {     
      if (this.userId === 0) {
        return require(`/static/img/users/system.png`)
      }
      const usr = this.allUsers.find(e => e.userId === userId)
      try {
        if (usr != null) {
        return require(`/static/img/users/${usr.userName.toLowerCase()}.jpg`)
      }
      } catch  {
        return require(`/static/img/users/user.jpg`)
      }
      return require(`/static/img/users/user.jpg`)
    },
    userName (userId) {
      let result = '(Not Found)'
      const usr = this.allUsers.find(e => e.userId === userId)
      if (this.userId === 0) {
        return 'System'
      }
      if (usr != null) {
        return usr.firstName + ' ' + usr.lastName
      }
      return result
    }
  },
  computed: {
    allUsers () {
      return this.$store.state.allUsers
    },
    hasNew () {
      return this.notifications.filter(x => x.readOn == null).length > 0
    }
  }
}
</script>
