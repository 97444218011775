<template>
  <div>
    <!-- <Header /> -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <svg class="svg-box" width="380px" height="500px" viewBox="0 0 837 1045" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                        sketch:type="MSPage">
                        <path d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
                                id="Polygon-1" stroke="#3bafda" stroke-width="6" sketch:type="MSShapeGroup"></path>
                        <path d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
                                id="Polygon-2" stroke="#7266ba" stroke-width="6" sketch:type="MSShapeGroup"></path>
                        <path d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
                                id="Polygon-3" stroke="#f76397" stroke-width="6" sketch:type="MSShapeGroup"></path>
                        <path d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
                                id="Polygon-4" stroke="#00b19d" stroke-width="6" sketch:type="MSShapeGroup"></path>
                        <path d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
                                id="Polygon-5" stroke="#ffaa00" stroke-width="6" sketch:type="MSShapeGroup"></path>
                    </g>
                </svg>
            </div>

            <div class="col-lg-6" v-if="!isLatest">
                <div class="message-box">
                    <h1 class="m-b-0">New Version</h1>
                    <h3>{{newVersion}} is Avaliable!</h3>
                    <p class="text-muted">Please <strong class="text-white">Hard Refresh</strong> using <strong class="text-white">CTRL+F5</strong> on Windows <br/><strong>⌘+SHIFT+R</strong> on Mac to get the latest version</p>
                </div>
            </div>
            <div class="col-lg-6" v-else>
                <div class="message-box">
                    <h1 class="m-b-0">UPDATED</h1>
                    <h3>You are All set!</h3>
                    <div class="buttons-con">
                        <div class="action-link-wrap">
                          <router-link :to="{name: 'Home'}" class="btn btn-custom btn-primary waves-effect waves-light m-t-20">Go to Home Page</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- end container -->
    </div>

    <Footer />
  </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Constants from '@/constants'
import { BrowseService } from '@/services/user-service'

export default {
  name: 'VersionError',
  mixins: [asyncDataStatus],
  data () {
    return {
      oldVersion: Constants.VERSION,
      newVersion: null
    }
  },
  computed: {
    isLatest () {
      return this.oldVersion === this.newVersion
    }
  },
  created () {
    BrowseService.addLog({urlFromName: 'Version', urlToName: 'Version', urlToId: '', uiVersion: Constants.version})
      .then((response) => {
        this.newVersion = response.data
      })
    this.asyncDataStatus_fetched('Version conflict')
  }
}
</script>
