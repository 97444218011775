// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vuelidate from 'vuelidate'
import ToggleButton from 'vue-js-toggle-button'
import VueCookies from 'vue-cookies'
import Paginate from 'vuejs-paginate'
import Sidebar from '@/components/TheSidebar'
import PageTitle from '@/components/ThePageTitle'
import Topbar from '@/components/TheTopbar'
import Footer from '@/components/TheFooter'
import VCalendar from 'v-calendar'
// import 'v-calendar/lib/v-calendar.min.css'
import VueAnalytics from 'vue-analytics'

import Vue from 'vue'
import App from './App'
import { router } from './router'
import store from '@/store/index'
import AppDate from '@/components/custom/AppDate'
import AppUser from '@/components/custom/AppUser'
import AppUserPic from '@/components/custom/AppUserPic'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import VTooltip from 'v-tooltip'
import 'vue-snotify/styles/material.css'
import VueAppInsights from 'vue-application-insights'
import constants from './constants'
import Axios from 'axios'
import { UmbrellaMsalService } from './services/umbrella-msal-service'
import { PowerBIReportEmbed } from 'powerbi-client-vue-js'
import JsonViewer from 'vue-json-viewer'

// import './registerServiceWorker'

const sfOptions = {
  toast: {
    position: SnotifyPosition.rightTop,
    showProgressBar: false
  }
}
Vue.component('AppDate', AppDate)
Vue.component('AppUser', AppUser)
Vue.component('AppUserPic', AppUserPic)
Vue.use(Snotify, sfOptions)
Vue.use(VTooltip)
Vue.use(JsonViewer)

Vue.config.productionTip = false
Vue.prototype.$msalInstance = null
Vue.prototype.$userRoles = constants.USER_ROLES

// id: 'UA-146013135-2', PROD
Vue.use(VueAnalytics, {
  id: 'UA-146013135-2',
  router
})

// Application Insight
if (constants.ENABLE_CLOUD) {
  Vue.use(VueAppInsights, {
    baseName: 'Umbrella', // prefix to track route changes as page views with AppInsights
    id: constants.APPINSIGHT_INSTRUMENTATION_KEY,
    router
  })
}


Vue.use(Vuelidate)
Vue.use(ToggleButton)
Vue.use(VueCookies)

Vue.use(VCalendar, {firstDayOfWeek: 2})

Vue.component('Sidebar', Sidebar)
Vue.component('PageTitle', PageTitle)
Vue.component('Topbar', Topbar)
Vue.component('Footer', Footer)

Vue.component('paginate', Paginate)
Vue.component('PowerBIReportEmbed', PowerBIReportEmbed)

// set default config
VueCookies.config('7d')

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
Axios.interceptors.response.use(
  function(response) { return response }, 
  function(error) {
      // handle error
      var err = error.toJSON()
      if (err.status == 401) {       
        UmbrellaMsalService.login()       
      } else {
        console.log('Error', err)
        // router.push({name: 'ServerError', params: { errorObject: err, routeName: window.location.href}})
      }
      
  });

// const prod = process.env.NODE_ENV === 'production'
// const shouldSW = 'serviceWorker' in navigator && prod
// if (shouldSW) {
//   navigator.serviceWorker.register('/service-worker.js').then(() => {
//     console.log('Service Worker Registered!')
//   })
// }

/* eslint-disable no-new */
new Vue({
  //el: '#wrapper',
  router,
  store,
  render: h => h(App)
  //template: '<App/>',
  //components: { App },
  //created () {
    // var authCookie = this.$cookie.get('webapp')

    // if (authCookie !== null) {
    //   Vue.http.headers.common['Authorization'] = 'Bearer ' + authCookie
    // }
  //}
}).$mount('#wrapper')
