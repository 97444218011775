<template>
    <div class="row align-items-center justify-content-between">
        <div class="form-group col-3 d-flex align-items-center">
            <Treeselect @select="quarterSelected" id="tree-quarter" :options="quarterTree" :deleteRemoves="false" :clearable="false" :value="quarterSelection" :disable-branch-nodes="true" placeholder="Select quarter"  :disabled="sendingReq">
                <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}   <i v-if="sendingReq" class="fa fa-spinner fa-spin"></i></div>
            </Treeselect>
            <a href="javascript:void(0)" :class="{'q-btn-disabled': disableLeftArrow}" class="hover-light" @click="incrementQ(-1)"><i class="mdi mdi-arrow-left-bold-circle ml-2" style="font-size:1.9rem"></i></a>
            <a href="javascript:void(0)" :class="{'q-btn-disabled': disableRightArrow}" class="hover-light" @click="incrementQ(1)"><i class="mdi mdi-arrow-right-bold-circle ml-3" style="font-size:1.9rem"></i></a>
        </div>
        <div v-if="enableAutofill" class="col d-flex align-items-center justify-content-center">
            <button class="btn btn-primary btn-custom waves-effect w-md waves-light px-4" :disabled="!canAutofillQ || sendingReq" @click.prevent="autofillClicked">Autofill<i class="mdi mdi-import pl-1"></i></button>
            <i class="mdi mdi-help-circle-outline ml-1" v-tooltip="'Use data from previous quarter'"></i>
        </div>
        <div class="col">
            <ul class="pagination float-right">
            <li class="page-item disabled"><a style="white-space: nowrap" class="page-link" href="#">Jump to</a></li>
            <li v-for="q in jumpToArr" :key="q.value" class="page-item" :class="{'active disabled': q.value === quarterSelection, 'disabled': sendingReq}">
                <a href="javascript:void(0)" @click="quarterChanged(q.value)" class="page-link">{{ q.label }} <span><i v-show="isLoading && q.value === quarterSelection" class="fa fa-spinner fa-spin"></i></span></a>
            </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    components: {
        Treeselect
    },
    props: {
        isLoading: { //created by parent component
            type: Boolean,
            required: true
        },
        inSaving: { //created by parent component
            type: Boolean,
            required: true
        },
        quarterTree: { //created by mixin
            type: Array,
            required: true
        },
        minQ: { //created by mixin
            type: String,
            required: true
        },
        maxQ: { //created by mixin
            type: String,
            required: true
        },
        enableAutofill: { //created by parent component
            type: Boolean,
            default: false,
            required: false
        },
        canAutofillQ: { //created by parent component
            type: Boolean,
            default: false,
            required: false
        },
        jumpToArr: { //created by mixin
            type: Array,
            required: true
        },
        quarterSelection: { //created by mixin, updated by parent component
            type: String,
            required: true
            // default: function () {
            //     const q = Math.floor(new Date().getMonth() / 3 + 1);
            //     const y = new Date().getFullYear()
            // return `${y}${q}`
            // }
        },
    },
    data () {
        return {
        }
    },
    computed: {
        sendingReq () {
            return this.isLoading || this.inSaving
        },
        disableLeftArrow () {
            return this.sendingReq || this.quarterSelection === this.minQ
        },
        disableRightArrow () {
            return this.sendingReq || this.quarterSelection === this.maxQ
        }
    },
    methods: {
        autofillClicked (e) {
            this.$emit('autofillClicked') //parent component will handle click event.
        },
        quarterSelected(q) {
            if (q.id === this.quarterSelection) return //do nothing if same quarter selectred
            else {
                this.quarterChanged(q.id) //tell parent component to update quarterSelection
            }
        },
        quarterChanged (q) {
            this.$emit('quarterChanged', q) //parent compnent will update quarterSelection; prop will pick up the update)
        },
        incrementQ(val) {
            // will not be called if quarter selection === minQ || maxQ
            if (this.isLoading) return
            let q = +this.quarterSelection.slice(-1)
            let y = +this.quarterSelection.slice(0, -1)
            q+= val
            if (q > 4) {
                q = 1
                y++
            }
            else if (q < 1) {
                q = 4
                y--
            }
            const newSelection = '' + y + q //new quarter has now been calculated
            this.quarterChanged(newSelection) //tells parent component to change quarterSelection with new value
        },
    }
}
</script>
<style scoped>
.q-btn-disabled {
  color: var(--gray) !important;
  pointer-events: none !important
}
.hover-light {
  color: #98a6ad
}
.hover-light:active {
  transform: scale(.95)
}
.hover-light:hover {
  color: #b3c1c7
}
</style>