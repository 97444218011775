<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'contacts-add'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Add Contact'" :icon="'mdi mdi-contacts'"/>
                        <div class="card-box">
                          <div class="row">
                            <div class="col-lg-6">
                              <h4 class="header-title m-t-0">Add a new Contact</h4>
                              <p class="text-muted font-14 m-b-20">
                                  Enter Contact information.
                              </p>

                              <form action="#" autocomplete="off">
                                  <div class="form-group">
                                      <label for="FirstName">First Name</label>
                                      <input type="text" v-model.trim="contact.firstName" @blur="$v.contact.firstName.$touch()" placeholder="Enter First Name" class="form-control" id="FirstName" :class="{ 'parsley-error': $v.contact.firstName.$error }">
                                      <template v-if="$v.contact.firstName.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.contact.firstName.max" class="parsley-required">This value is too long</li>
                                        </ul>
                                      </template>
                                  </div>
                                  <div class="form-group">
                                      <label for="LastName">Last Name<span class="text-danger">*</span></label>
                                      <input type="text" v-model.trim="contact.lastName" @blur="$v.contact.lastName.$touch()" required placeholder="Enter Last Name" class="form-control" id="LastName" :class="{ 'parsley-error': $v.contact.lastName.$error }">
                                      <template v-if="$v.contact.lastName.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.contact.lastName.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.contact.lastName.min" class="parsley-required">This value is too short</li>
                                          <li v-if="!$v.contact.lastName.max" class="parsley-required">This value is too long</li>
                                        </ul>
                                      </template>
                                  </div>
                                  <div class="form-group">
                                      <label for="FirmId">Firm<span class="text-danger">*</span></label>
                                      <span :class="{ 'invalid': $v.contact.firmId.$error }">
                                        <multiselect id="generalPartnerId" v-model="firmObj" :options="searchFirms" label="firmName" track-by="firmName" :allow-empty="false" placeholder="Type to Search"
                                        @input="firmSelected" @search-change="asyncSearchFirm" :loading="isSearchingList" :options-limit="50"></multiselect>
                                      </span>
                                      <template v-if="$v.contact.firmId.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.contact.firmId.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.contact.firmId.min" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>
                                  <div class="form-group">
                                      <label for="JobTitle">Job Title</label>
                                      <input type="text" v-model="contact.jobTitle" @blur="$v.contact.jobTitle.$touch()" placeholder="Enter Job Title" class="form-control" id="JobTitle" :class="{ 'parsley-error': $v.contact.jobTitle.$error }">
                                      <template v-if="$v.contact.jobTitle.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.contact.jobTitle.max" class="parsley-required">This value is too long</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                    <label for="Email">Email</label>
                                    <input type="email" v-model="contact.email" @blur="$v.contact.email.$touch()" required placeholder="Enter Email" class="form-control" id="Email" :class="{ 'parsley-error': $v.contact.email.$error }">
                                    <template v-if="$v.contact.email.$error">
                                      <ul class="parsley-errors-list filled">
                                        <li v-if="!$v.contact.email.max" class="parsley-required">This value is too long</li>
                                        <li v-if="!$v.contact.email.email" class="parsley-required">This value is not valid.</li>
                                      </ul>
                                    </template>
                                  </div>

                                  <div class="form-group">
                                    <label for="PhoneNo">Phone Number</label>
                                    <input type="text" v-model="contact.phoneNo" @blur="$v.contact.phoneNo.$touch()" placeholder="Enter Phone Number" class="form-control" id="PhoneNo" :class="{ 'parsley-error': $v.contact.phoneNo.$error }">
                                    <template v-if="$v.contact.phoneNo.$error">
                                      <ul class="parsley-errors-list filled">
                                        <li v-if="!$v.contact.phoneNo.max" class="parsley-required">This value is too long</li>
                                      </ul>
                                    </template>
                                  </div>

                                  <div class="form-group">
                                    <label for="MobileNo">Mobile Number</label>
                                    <input type="text" v-model="contact.mobileNo" @blur="$v.contact.mobileNo.$touch()" placeholder="Enter Mobile Number" class="form-control" id="MobileNo" :class="{ 'parsley-error': $v.contact.mobileNo.$error }">
                                    <template v-if="$v.contact.mobileNo.$error">
                                      <ul class="parsley-errors-list filled">
                                        <li v-if="!$v.contact.mobileNo.max" class="parsley-required">This value is too long</li>
                                      </ul>
                                    </template>
                                  </div>

                                  <div class="form-group">
                                      <div class="checkbox checkbox-primary">
                                          <input id="checkbox6a" type="checkbox" v-model="addMore">
                                          <label for="checkbox6a">
                                              Add Another
                                          </label>
                                      </div>
                                  </div>

                                  <div class="form-group text-right m-b-0">
                                      <button type="reset" class="btn btn-secondary waves-effect m-l-5 mr-2" @click.prevent="reset">
                                          Reset
                                      </button>
                                      <button class="btn btn-primary waves-effect waves-light" type="submit" @click.prevent="save" v-if="user.groupId < 11">
                                          Add Contact
                                      </button>
                                  </div>
                              </form>
                            </div>
                            <div class="col-lg-6">
                            </div>
                          </div>

                        </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
import { required, minLength, maxLength, minValue, email } from 'vuelidate/lib/validators'
import { ContactService } from '@/services/contact-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      addMore: false,
      contact: null,
      firmObj: null,
      searchFirms: [],
      isSearchingList: false
    }
  },
  validations: {
    contact: {
      firstName: { max: maxLength(100) },
      lastName: { required, min: minLength(2), max: maxLength(100) },
      firmId: { required, min: minValue(1) },
      jobTitle: { max: maxLength(100) },
      email: { email, max: maxLength(100) },
      phoneNo: { max: maxLength(20) },
      mobileNo: { max: maxLength(20) }
    }
  },
  methods: {
    ...mapActions(['searchAllFirms']),
    firmSelected () {
      this.$v.contact.firmId.$touch()
      this.contact.firmId = this.firmObj ? this.firmObj.firmId : null
    },
    save () {
      this.$v.contact.$touch()
      if (!this.$v.contact.$error) {
        ContactService.add(this.contact).then(
          (response) => {
            this.$snotify.success('Contact Created.')
            if (!this.addMore) {
              setTimeout(() => {
                this.$router.push({name: 'ContactsDetail', params: {id: response.data.contactId}})
              }, 500)
            } else {
              this.reset()
            }
          })
          .catch(function (error) {
            console.error('SERVER Error:', error)
            // this.$snotify.error('SERVER Errors.' + error)
          })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    reset () {
      this.contact = {
        lastName: '',
        firstName: '',
        firmId: 0
      }

      this.firmObj = null
      this.$v.$reset()
    },
    asyncSearchFirm (query) {
      this.isSearchingList = true
      this.searchAllFirms({query}).then((result) => {
        this.searchFirms = result
        this.isSearchingList = false
      })
    }
  },
  mixins: [asyncDataStatus],
  created () {
    this.reset()
    this.asyncDataStatus_fetched('Add a New Contact')
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
