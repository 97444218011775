<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portal-deals'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Navigator'" :icon="'mdi mdi-web'" />
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box table-responsive">
                                <h4 class="header-title">Deals List</h4>
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-6 form-inline">
                                      <label class="col-form-label">
                                        <span class="mr-1">Show only new items</span>
                                        <toggle-button v-model="onlyNew" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="startSearch"/>
                                      </label>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div>
                                            <label>
                                                <input type="search" v-model="searchName" class="form-control" placeholder="Search by name" @keyup.enter="startSearch">
                                            </label>
                                            <button @click="startSearch" class="btn btn-icon waves-effect waves-light btn-primary m-b-5"> <i class="fa fa-search"></i> </button>
                                            <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                        </div>
                                    </div>
                                </div>

                                <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                                    <thead>
                                    <tr role="row">
                                        <th @click="setOrder(0)"> Name
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)"> Firm
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th>
                                          <span @click="setOrder(2)"> Type</span>
                                          <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                          <button class="btn btn-mini btn-primary pull-right" v-tooltip="'Filter'" @click.prevent="showFilter(2)" :class="{'btn-warning' : selectedTypes.length != allTypes.length}"><i class="mdi push-right mdi-filter-outline"></i></button>
                                          <div class="pull-right"  style="position: absolute;">
                                            <ul class="dropdown-menu filter-dropdown" :class="{'show' : filterIndex == 2}" style="position: relative;">
                                              <li v-for="typ in allTypes" :key="typ.id">
                                                <div class="checkbox checkbox-primary">
                                                    <input :id="`typ`+typ.id" type="checkbox" :value="typ.id" v-model="selectedTypes">
                                                    <label :for="`typ`+typ.id">
                                                      {{typ.name}}
                                                    </label>
                                                </div>
                                              </li>
                                              <li class="pull-left">
                                                  <button class="btn btn-mini btn-success pull-right p-1" @click.prevent="showFilter(0)">Ok</button>
                                              </li>
                                            </ul>
                                          </div>
                                        </th>
                                        <th>
                                          <span @click="setOrder(3)"> Geography Focus</span>
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                          <button class="btn btn-mini btn-primary pull-right" v-tooltip="'Filter'" @click.prevent="showFilter(3)" :class="{'btn-warning' : selectedGeos.length != allGeoFocuses.length}"><i class="mdi push-right mdi-filter-outline"></i></button>
                                          <div class="pull-right"  style="position: absolute;">
                                            <ul class="dropdown-menu filter-dropdown" :class="{'show' : filterIndex == 3}" style="position: relative;">
                                              <li v-for="geo in allGeoFocuses" :key="geo.geographicId">
                                                <div class="checkbox checkbox-primary">
                                                    <input :id="`geo`+geo.geographicId" type="checkbox" :value="geo.geographicId" v-model="selectedGeos">
                                                    <label :for="`geo`+geo.geographicId">
                                                      {{geo.geographicName}}
                                                    </label>
                                                </div>
                                              </li>
                                              <li class="pull-left">
                                                  <button class="btn btn-mini btn-success pull-right p-1" @click.prevent="showFilter(0)">Ok</button>
                                              </li>
                                            </ul>
                                          </div>
                                        </th>
                                        <th>
                                          <span @click="setOrder(4)"> Strategy</span>
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                          <button class="btn btn-mini btn-primary pull-right" v-tooltip="'Filter'" @click.prevent="showFilter(4)" :class="{'btn-warning' : selectedSectors.length != allSectors.length}"><i class="mdi push-right mdi-filter-outline"></i></button>
                                          <div class="pull-right"  style="position: absolute;">
                                            <div class="pull-right">
                                              <ul class="dropdown-menu filter-dropdown" :class="{'show' : filterIndex == 4}" style="position: relative;">
                                                <li v-for="sec in allSectors" :key="sec.sectorId">
                                                  <div class="checkbox checkbox-primary">
                                                      <input :id="`sec`+sec.sectorId" type="checkbox" :value="sec.sectorId" v-model="selectedSectors">
                                                      <label :for="`sec`+sec.sectorId">
                                                        {{sec.sectorName}}
                                                      </label>
                                                  </div>
                                                </li>
                                                <li class="pull-left">
                                                    <button class="btn btn-mini btn-success pull-right p-1" @click.prevent="showFilter(0)">Ok</button>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>  
                                        </th>
                                        <th @click="setOrder(5)">Target Size (M)
                                          <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(6)">Expected Close Date
                                          <i v-if="sortIndex == 6 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 6 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(7)">Submit Date
                                          <i v-if="sortIndex == 7 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 7 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                            
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                       <tr role="row" v-for="fund in funds" :key="fund.fundId">
                                           <td>
                                               <router-link :to="{name: 'PortalDealsDetail', params: {id: fund.fundId}}" >
                                                   {{ fund.fundName }}
                                               </router-link>
                                           </td>
                                           <td>{{ fund.firmName }}</td>
                                           <td>{{ fund.typeName }}</td>
                                           <td>{{ fund.geographicName }}</td>
                                           <td>{{ fund.sectorName }}</td>
                                           <td>{{ fund.currencySymbol }}{{ fund.targetSize | thousandNumber }}</td>
                                           <td>
                                             <span v-if="fund.isOpenEnded">Open Ended</span>
                                             <AppDate v-else :date="fund.expectedCloseDate != null ? fund.expectedCloseDate : ''" :show="'text'" />
                                            </td>
                                           <td>
                                              <AppDate :date="fund.createdOn" :show="'text'" />
                                              <span v-if="fund.umbFundId > 0 && fund.umbrellaCheck" class="badge badge-success">Imported</span>
                                              <span v-if="fund.umbFundId == 0 && !fund.umbrellaCheck" class="badge badge-warning">New</span>
                                              <span v-if="fund.umbFundId > 0 && !fund.umbrellaCheck" class="badge badge-warning">Updated</span>
                                              <span v-if="fund.deletedOn != null" class="badge badge-danger">Deleted</span>
                                              <span v-if="!fund.isFirmImported && !fund.umbrellaCheck"><i class='mdi mdi-flag text-danger' v-tooltip="'Associated firm must be imported before deal can be imported.'"/></span>
                                              <span v-if="fund.isFirmImported && !fund.umbrellaCheck"><i class='mdi mdi-flag text-success' v-tooltip="'Deal can be imported.'"/></span>
                                           </td>
                                       </tr>
                                    </tbody>
                                </table>
                   
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div class="" role="status">Page {{currentPage}} of {{totalPages}} - {{totalRecords | thousandNumber}} deals</div>
                                    <div class="px-3">
                                      <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                          <option value="25">25</option>
                                          <option value="50">50</option>
                                          <option value="100">100</option>
                                          <option value="250">250</option>
                                      </select> 
                                    </div>

                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end container -->
        </div>
        <!-- end content -->

        <Footer />
    </div>
</template>

<script>
import { PortalFundService } from '@/services/portal-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      funds: [],
      onlyNew: false,
      selectedGeos: [],
      selectedSectors: [],
      selectedTypes: [],
      searchName: '',
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      allTypes: [{id: 1, name: 'Primary'}, {id: 2, name: 'Secondary'}, {id: 3, name: 'Co-Investment'}],
      sortBy: ['FundName', 'firmName', 'FundTypeId', 'GeographicFocusId', 'SectorId', 'TargetSize', 'ExpectedCloseDate', 'CreatedOn'],
      sortIndex: 0,
      sortType: 'ASC',
      filterIndex: null,
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    ...mapActions(['fetchAllCountries', 'fetchAllSectors', 'fetchAllGeoFocus', 'fetchAllFundtires']),
    getList () {
      this.isLoading = true
      this.refreshCoockie()

      PortalFundService.search(this.searchName.trim(), null, this.onlyNew, this.sortBy[this.sortIndex], this.sortType, this.currentPage, this.pageSize, this.selectedTypes, this.selectedGeos, this.selectedSectors)
      .then((response) => {
        this.isLoading = false
        this.funds = response.data.funds
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Deals List') })
    },
    refreshCoockie () {
      const filters = {
        'searchName': this.searchName,
        // 'geoId': this.geoId,
        'geo': this.selectedGeos,
        'type': this.selectedTypes,
        'sector': this.selectedSectors,
        'pageSize': this.pageSize,
        'sortIndex': this.sortIndex
      }
      
      localStorage.setItem('umb_portal_funds_filters', JSON.stringify(filters))
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.funds = []
      if (this.searchName == null) {
        this.searchName = ''
      }

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    stageClass (stageId) {
      if (stageId >= 3) {
        return 'text-white stage' + stageId
      }
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.sortIndexBackup = this.sortIndex
      this.sortTypeBackup = this.sortType
      this.startSearch()
    },
    showFilter (id) {
      if (this.filterIndex !== id) {
        this.filterIndex = id
        if (id === 0) {
          this.startSearch()
        }
      } else {
        this.filterIndex = null
      }
    },
    setupFilters () {
      this.selectedGeos = this.allGeoFocuses.map(x => x.geographicId)
      this.selectedTypes = this.allTypes.map(x => x.id)
      this.selectedSectors = this.allSectors.map(x => x.sectorId)
    },
    resetFilter () {
      this.searchName = ''
      this.setupFilters()
      this.startSearch()
    }
  },
  mounted () {
    this.fetchAllSectors()
    this.fetchAllGeoFocus()
    this.fetchAllCountries()
    this.fetchAllFundtires()
    this.setupFilters()
    const oldFilters = JSON.parse(localStorage.getItem('umb_portal_funds_filters'))
    if (oldFilters != null) {
      if (oldFilters.searchName != null) {
        this.searchName = oldFilters.searchName
      }
      if (oldFilters.geo != null) {
        this.selectedGeos = oldFilters.geo
      }
      if (oldFilters.type != null) {
        this.selectedTypes = oldFilters.type
      }
      if (oldFilters.sector != null) {
        this.selectedSectors = oldFilters.sector
      }
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
      if (oldFilters.sortIndex != null) {
        this.sortIndex = oldFilters.sortIndex
      }
    }
    this.startSearch()
  },
  computed: {
    allSectors () {
      return this.$store.state.allSectors
    },
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    hasFilter () {
      return this.searchName.length > 0 || this.selectedGeos.length < 6 || this.selectedTypes.length < 3 || this.selectedSectors.length < this.allSectors.length || this.onlyNew
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
