<template>
  <div>
    <Topbar/>
    <Sidebar :activeItem="'ops-list'"/>

    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid">
          <PageTitle :title="'Ops Workflow'" :icon="'mdi mdi-opera'" />
          <div class="card-box">
            <h4 class="header-title m-t-0">Ops Workflow</h4>
            <p class="text-muted font-14 m-b-20">Request Info</p>
            <form action="#" autocomplete="off" @submit.prevent="save">
              <div class="row">
                <div class="col-sm-6 col-xs-12">

                  <div class="form-group" v-if="opsRequest.assignedTo > 0">
                    <label>Assigned To</label>
                    <AppUser :userId="opsRequest.assignedTo" />
                  </div>
                  <div v-else class="form-group">
                    <label>Not Assigned</label>
                  </div>

                  <div class="form-group">
                    <label for="deadline">
                      Deadline <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" :disabled="!isOwner"
                    format="MM/dd/yyyy" :bootstrap-styling="true" :disabledDates="{to: endDate}" v-model="opsRequest.deadline" @blur="$v.opsRequest.deadline.$touch()" @input="setPickerDate" :input-class="{ 'form-control parsley-error': $v.opsRequest.deadline.$error }"></date-picker>
                    <template v-if="$v.opsRequest.deadline.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.deadline.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="requestDate">
                      Request Date <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" :disabled="!isOwner"
                    format="MM/dd/yyyy" :bootstrap-styling="true" v-model="opsRequest.requestDate" @blur="$v.opsRequest.requestDate.$touch()" :input-class="{ 'form-control parsley-error': $v.opsRequest.requestDate.$error }"></date-picker>
                    <template v-if="$v.opsRequest.requestDate.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.requestDate.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group" v-show="opsRequest.categoryId == 3">
                    <label for="requestorName" class="col-form-label">
                      Requestor Name <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <input type="text" class="form-control" id="requestorName" v-model="opsRequest.requestorName" @blur="$v.opsRequest.requestorName.$touch()" :class="{ 'parsley-error': $v.opsRequest.requestorName.$error}" :disabled="!isOwner" />
                    <template v-if="$v.opsRequest.requestorName.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.requestorName.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.opsRequest.requestorName.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group" v-show="opsRequest.typeId == 2 && opsRequest.categoryId == 4">
                    <label for="investor" class="col-form-label">
                      Investor <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <multiselect id="investor" v-model="investorObj" :options="allOprInvestors" label="investorName" track-by="investorId" :allow-empty="false" @input="investorSelected"></multiselect>
                    <template v-if="$v.opsRequest.requestorId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.requestorId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group" v-show="opsRequest.categoryId == 1 || opsRequest.categoryId == 5 || opsRequest.categoryId == 7">
                    <label for="requestor" class="col-form-label">
                      Deal <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <multiselect id="fund" v-model="fundObj" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search Deals"
                      @input="fundSelected" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                    <template v-if="$v.opsRequest.requestorId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.requestorId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <template v-if="opsRequest.categoryId == 1">
                    <div class="form-group">
                      <label for="fund-contact-name">Contact Person's Name <span class="text-danger" v-if="isOwner">*</span></label>
                      <input if="fund-contact-name" type="text" v-model.trim="opsRequest.fundContactName" placeholder="Enter Name" class="form-control" :disabled="!isOwner" @blur="$v.opsRequest.fundContactName.$touch()" :class="{ 'parsley-error': $v.opsRequest.fundContactName.$error }">
                      <template v-if="$v.opsRequest.fundContactName.$error">
                        <ul class="parsley-errors-list filled">
                          <li v-if="!$v.opsRequest.fundContactName.required" class="parsley-required">This value is required</li>
                        </ul>
                      </template>
                    </div>
                    <div class="form-group">
                      <label for="fund-contact-email">Contact Person's Email <span class="text-danger" v-if="isOwner">*</span></label>
                      <input if="fund-contact-email" type="text" v-model.trim="opsRequest.fundContactEmail" placeholder="Enter Email" class="form-control" :disabled="!isOwner" @blur="$v.opsRequest.fundContactEmail.$touch()" :class="{ 'parsley-error': $v.opsRequest.fundContactEmail.$error }">
                      <template v-if="$v.opsRequest.fundContactEmail.$error">
                        <ul class="parsley-errors-list filled">
                          <li v-if="!$v.opsRequest.fundContactEmail.required" class="parsley-required">This value is required</li>
                        </ul>
                      </template>
                    </div>
                  </template>

                  <div class="form-group" v-show="opsRequest.typeId == 2 && opsRequest.categoryId == 6">
                    <label for="provider" class="col-form-label">
                      Service Provider <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <multiselect id="provider" v-model="providerObj" :options="allOprServiceProviders" label="serviceProviderName" track-by="serviceProviderId" :allow-empty="false" @input="providerSelected"></multiselect>
                    <template v-if="$v.opsRequest.fundContactEmail.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.fundContactEmail.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>                  

                  <div class="form-group">
                    <label for="other" class="col-form-label">
                      Description <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <textarea class="form-control" rows="5" id="other" v-model="opsRequest.comments" @blur="$v.opsRequest.comments.$touch()" :class="{ 'parsley-error': $v.opsRequest.comments.$error }" :disabled="!isOwner"></textarea>
                    <template v-if="$v.opsRequest.comments.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.opsRequest.comments.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                      <label for="typeId">Type <span class="text-danger" v-if="isOwner">*</span></label>
                      <span :class="{ 'parsley-error-radio': $v.opsRequest.typeId.$error }" v-if="isOwner">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="type1" :value="1" v-model="opsRequest.typeId" name="type" class="custom-control-input">
                          <label class="custom-control-label" for="type1">Internal</label>
                        </div>
                        <div class="custom-control custom-radio" v-if="isOpsTeam">
                          <input type="radio" id="type2" :value="2" v-model="opsRequest.typeId" name="type" class="custom-control-input">
                          <label class="custom-control-label" for="type2">External</label>
                        </div>
                      </span>
                      <span v-else>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="type1" :value="1" v-model="opsRequest.typeId" name="type" class="custom-control-input">
                          <label class="custom-control-label" for="type1" v-if="opsRequest.typeId == 1">Internal</label>
                          <input type="radio" id="type2" :value="2" v-model="opsRequest.typeId" name="type" class="custom-control-input">
                          <label class="custom-control-label" for="type2" v-if="opsRequest.typeId == 2">External</label>
                        </div>
                      </span>
                      <template v-if="$v.opsRequest.typeId.$error">
                        <ul class="parsley-errors-list filled">
                          <li v-if="!$v.opsRequest.typeId.required" class="parsley-required">This value is required</li>
                        </ul>
                      </template>
                  </div>

                  <div class="form-group">
                      <label>Category <span class="text-danger" v-if="isOwner">*</span></label>
                      <span :class="{ 'parsley-error-radio': $v.opsRequest.categoryId.$error }" v-if="isOwner">
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 1">
                          <input type="radio" id="category1" :value="1" v-model="opsRequest.categoryId" name="category" class="custom-control-input" :disabled="true">
                          <label class="custom-control-label" for="category1">ODD</label>
                        </div>
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 1">
                          <input type="radio" id="category2" :value="2" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category2">Data</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="category3" :value="3" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category3">Other</label>
                        </div>
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 2">
                          <input type="radio" id="category4" :value="4" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category4">Investor (LP)/Client</label>
                        </div>
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 2">
                          <input type="radio" id="category5" :value="5" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category5">Investment</label>
                        </div>
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 2">
                          <input type="radio" id="category6" :value="6" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category6">Service provider</label>
                        </div>
                        <div class="custom-control custom-radio" v-show="opsRequest.typeId == 2">
                          <input type="radio" id="category7" :value="7" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category7">AML/KYC/CDD</label>
                        </div>
                      </span>
                      <span v-else>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="category1" :value="1" v-model="opsRequest.categoryId" name="category" class="custom-control-input" :disabled="true">
                          <label class="custom-control-label" for="category1" v-if="opsRequest.categoryId == 1">ODD</label>
                          <input type="radio" id="category2" :value="2" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category2" v-if="opsRequest.categoryId == 2">Data</label>
                          <input type="radio" id="category3" :value="3" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category3" v-if="opsRequest.categoryId == 3">Other</label>
                          <input type="radio" id="category4" :value="4" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category4" v-if="opsRequest.categoryId == 4">Investor (LP)/Client</label>
                          <input type="radio" id="category5" :value="5" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category5" v-if="opsRequest.categoryId == 5">Investment</label>
                          <input type="radio" id="category6" :value="6" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category6" v-if="opsRequest.categoryId == 6">Service provider</label>
                          <input type="radio" id="category7" :value="7" v-model="opsRequest.categoryId" name="category" class="custom-control-input">
                          <label class="custom-control-label" for="category7" v-if="opsRequest.categoryId == 7">AML/KYC/CDD</label>
                        </div>
                      </span>
                      <template v-if="$v.opsRequest.categoryId.$error">
                        <ul class="parsley-errors-list filled">
                          <li v-if="!$v.opsRequest.categoryId.required" class="parsley-required">This value is required</li>
                        </ul>
                      </template>
                  </div>

                  <div class="form-group">
                      <label>Source <span class="text-danger" v-if="isOwner">*</span></label>
                      <span :class="{ 'parsley-error-radio': $v.opsRequest.sourceId.$error }" v-if="isOwner">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="source1" :value="1" v-model="opsRequest.sourceId" name="source" class="custom-control-input">
                          <label class="custom-control-label" for="source1">Email</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="source2" :value="2" v-model="opsRequest.sourceId" name="source" class="custom-control-input">
                          <label class="custom-control-label" for="source2">Mail</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="source3" :value="3" v-model="opsRequest.sourceId" name="source" class="custom-control-input">
                          <label class="custom-control-label" for="source3">Phone</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="source4" :value="4" v-model="opsRequest.sourceId" name="source" class="custom-control-input" :disabled="true">
                          <label class="custom-control-label" for="source4">System</label>
                        </div>
                      </span>
                      <span v-else>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="source2" :value="1" v-model="opsRequest.sourceId" name="source" class="custom-control-input">
                          <label class="custom-control-label" for="source1" v-if="opsRequest.sourceId == 1">Email</label>
                          <input type="radio" id="source2" :value="2" v-model="opsRequest.sourceId" name="source" class="custom-control-input">
                          <label class="custom-control-label" for="source2" v-if="opsRequest.sourceId == 2">Mail</label>
                          <input type="radio" id="source3" :value="3" v-model="opsRequest.sourceId" name="source" class="custom-control-input">
                          <label class="custom-control-label" for="source3" v-if="opsRequest.sourceId == 3">Phone</label>
                          <input type="radio" id="source4" :value="4" v-model="opsRequest.sourceId" name="source" class="custom-control-input">
                          <label class="custom-control-label" for="source4" v-if="opsRequest.sourceId == 4">System</label>
                        </div>
                      </span>
                      <template v-if="$v.opsRequest.sourceId.$error">
                        <ul class="parsley-errors-list filled">
                          <li v-if="!$v.opsRequest.sourceId.required" class="parsley-required">This value is required</li>
                        </ul>
                      </template>
                  </div>
                  
                  <div class="form-group">
                    <label class="col-form-label">Kroll background check required?</label>
                      <toggle-button :sync="true" v-model="opsRequest.isKrollBackground" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!isOwner" />
                      <div class="form-group" v-show="opsRequest.isKrollBackground">
                        <label class="col-form-label">Please list the entities and individuals that need to have a Kroll background check <span v-if="isOwner" class="text-danger">*</span></label>
                        <textarea class="form-control" rows="3" id="krollBackgroundCheck" :disabled="!isOwner" v-model="opsRequest.krollBackgroundCheck" @blur="$v.opsRequest.krollBackgroundCheck.$touch()" :class="{ 'parsley-error': $v.opsRequest.krollBackgroundCheck.$error }"></textarea>
                        <template v-if="$v.opsRequest.krollBackgroundCheck.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.opsRequest.krollBackgroundCheck.required" class="parsley-required">This value is required</li>
                          </ul>
                        </template>
                        <label class="col-form-label">Kroll background Check Due Date <span v-if="isOwner" class="text-danger">*</span></label>
                        <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :disabled="!isOwner" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                          format="MM/dd/yyyy" :bootstrap-styling="true" v-model="opsRequest.krollBackgroundCheckDueDate" @blur="$v.opsRequest.krollBackgroundCheckDueDate.$touch()" @input="setPickerDateKrollBackground" :input-class="{ 'form-control parsley-error': $v.opsRequest.krollBackgroundCheckDueDate.$error }"></date-picker>
                        <template v-if="$v.opsRequest.krollBackgroundCheckDueDate.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.opsRequest.krollBackgroundCheckDueDate.required" class="parsley-required">This value is required</li>
                          </ul>
                        </template>
                      </div>
                  </div>

                  <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                  <div :class="{'collapse': !showLogs }">
                      <div class="form-group">
                          <label>Created On</label>
                          <AppDate :date="opsRequest.createdOn"/>
                      </div>
                      <div class="form-group">
                          <label>Created By</label>
                          <AppUser :userId="opsRequest.createdBy" />
                      </div>
                      <div class="form-group" v-if="opsRequest.updatedOn">
                          <label>Modified On</label>
                          <AppDate :date="opsRequest.updatedOn" :emptyIfNull="true"/>
                      </div>
                      <div class="form-group" v-if="opsRequest.updatedOn">
                          <label>Modified By</label>
                          <AppUser :userId="opsRequest.updatedBy" :emptyIfNull="true"/>
                      </div>
                  </div>

                  <div class="form-group text-right m-b-0">
                    <router-link :to="{name: 'OpsList'}">
                        <button type="button" class="btn btn-custom btn-secondary waves-effect mr-2">
                            Back
                        </button>
                    </router-link>

                    <button class="btn btn-custom waves-effect btn-danger w-md waves-light mr-2" v-if="isOwner && opsRequest.statusId < 4"
                      @click.prevent="withdraw"><i class="mdi mdi-stop"></i> <span>Withdraw</span>
                    </button>

                    <button class="btn btn-custom waves-effect btn-defualt w-md waves-light mr-2" v-if="isOwner && opsRequest.statusId === 4"
                      @click.prevent="resubmit"><i class="mdi mdi-step-forward"></i> <span>Resubmit</span>
                    </button>

                    <button v-if="isOwner && opsRequest.statusId < 4" class="btn btn-custom btn-primary waves-effect waves-light mr-2" type="submit">Update Request</button>
                  </div>

                  <div class="form-group mt-3" v-if="isOwner && opsRequest.statusId < 4">
                    <vue-dropzone :options="dropzoneOptions" @vdropzone-success="vsuccess"></vue-dropzone>
                    <p class="text-muted">Max file size: 5MB</p>
                  </div>
                </div>

                <div class="col-lg-6 col-xs-12">
                  <div>
                    <ul class="nav nav-tabs tabs-bordered nav-justified">
                      <li class="nav-item">
                          <a class="nav-link active show">
                              Attachments <span class="badge badge-primary noti-icon-badge" v-if="fileList != null">{{fileList.length}}</span>
                          </a>
                      </li>
                    </ul>
                    <span v-if="isLoadingFiles">Loading <i class="fa fa-spinner fa-spin"></i></span>
                    <FileList :files="fileList" :isEditMode="false" :canEdit="false" :canDelete="isOwner" @onSelect="showFile" />
                  </div>
                  <StatusBar :currentStatusId="opsRequest.statusId" />
                  <div class="form-group" v-if="isOpsTeam">
                    <hr />
                    <OpsActions :currentStatusId="opsRequest.statusId" @onUpdateStatus="updateStatus" />
                      <label for="fund.Employee">Assign to</label>
                      <span :class="{ 'invalid': assignError }">
                        <multiselect id="fund.Employee" v-model="userObj" :options="opsUsers" label="fullName" track-by="userId" :allow-empty="true"></multiselect>
                      </span>
                      <template v-if="assignError">
                        <ul class="parsley-errors-list filled">
                          <li class="parsley-required">Select a valid user</li>
                        </ul>
                      </template>
                      <button class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="onAssign"><i class="mdi mdi-account-plus"></i> Submit</button>
                  </div>
                  <StatusUpdate :id="opsRequest.requestId" :canAdd="isOpsTeam" />
                  <DealInfo :fundFiles = "fundFiles" />
                </div>

              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="side-bar right-bar">
      <div class="col-sm-12">
          <FileItem v-if="rightbarItem == 'file'" :objectId="id" :objectTypeId="10" :isEditMode="false" :isAdd="false"/>
          <FileItem  :objectId="opsRequest.requestorId" :objectTypeId="1" :isEditMode="true" :isAdd="true" :forOps="true" @onUpdate="onFileUpdate" />       
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import { mapGetters, mapActions } from 'vuex'
import { required, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { OpsTeamService } from '@/services/opsteam-service'
import { FileService } from '@/services/file-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import StatusBar from '@/components/opsteam/TheStatusBar'
import OpsActions from '@/components/opsteam/TheActionBar'
import vue2Dropzone from 'vue2-dropzone'
import Constants from '@/constants.js'
import FileList from '@/components/FileListAll'
import FileItem from '@/components/FileItem'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { FundService } from '@/services/fund-service'
import StatusUpdate from '@/components/opsteam/TheStatusUpdate'
import DealInfo from '@/components/opsteam/DealInfo'
import moment from 'moment'

export default {
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  mixins: [asyncDataStatus, rightbar],
  components: {
    DatePicker,
    Multiselect,
    StatusBar,
    OpsActions,
    FileList,
    FileItem,
    StatusUpdate,
    vueDropzone: vue2Dropzone,
    DealInfo
  },
  data () {
    return {
      opsRequest: { statusId:0, requestId:0, requestorId:0 },
      searchFunds: [],
      isSearchingList: false,
      endDate: null,
      authCookie: null,
      isLoadingFiles: false,
      fileList: null,
      rightbarItem: null,
      fundObj: null,
      userObj: null,
      investorObj: null,
      providerObj: null,
      assignError: false,
      showLogs: false,
      fundFiles: []
    }
  },
  validations: {
    opsRequest: {
      typeId: { required },
      categoryId: { required },
      sourceId: { required },
      requestDate: { required },
      deadline: { required },
      comments: { required, min: minLength(2), max: maxLength(1000) },
      requestorId: { required: requiredIf(function () { return parseInt(this.opsRequest.categoryId) !== 2 && parseInt(this.opsRequest.categoryId) !== 3 }) },
      requestorName: { required: requiredIf(function () { return parseInt(this.opsRequest.categoryId) === 2 || parseInt(this.opsRequest.categoryId) === 3 }), max: maxLength(300) },
      krollBackgroundCheck: { required: requiredIf(function () { return this.opsRequest.isKrollBackground }), max: maxLength(1000) },
      krollBackgroundCheckDueDate: { required: requiredIf(function () { return this.opsRequest.isKrollBackground }) },
      fundContactName: { required: requiredIf(function () { return parseInt(this.opsRequest.categoryId) === 1 }) },
      fundContactEmail: { required: requiredIf(function () { return parseInt(this.opsRequest.categoryId) === 1 }) }
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    isAdmin () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0
    },
    isOwner () {
      return this.isAdmin || (this.opsRequest.createdBy === this.user.userId && this.opsRequest.statusId !== 5)
    },
    opsUsers () {
      return this.$store.state.allUsers.filter(x => (x.groupId === 2 || x.groupId === 4 || x.groupId === 5 || x.groupId === 7 || x.groupId === 8) && x.isActive && x.userId > 0)
    },
    allOprInvestors () {
      return this.$store.state.allOprInvestors
    },
    allOprServiceProviders () {
      return this.$store.state.allOprServiceProviders
    },
    isOpsTeam () {
      return this.isAdmin || (this.user.groupId === 4 || this.user.groupId === 7 || this.user.groupId === 8 || this.user.groupId === 5)
    },
    dropzoneOptions () {
      return {
        url: Constants.API_URL + '/file/upload/dropzone/10/' + this.id + '/7',
        thumbnailWidth: 150,
        maxFilesize: 5,
        dictDefaultMessage: 'Drag and drop or click to select files to upload',
        headers: { 'Authorization': 'Bearer ' + this.authCookie }
      }
    }
  },
  methods: {
    ...mapActions(['fetchFile', 'fetchAllUsers', 'fetchAllOprInvestors', 'fetchAllOprServiceProviders', 'searchAllFunds']),
    clickAddFile () {
      this.$store.commit('setNewFile', this.opsRequest.requestorId)
      this.showRightbar()
    }, 
    onFileUpdate () {
      this.fetchFundFileList()
      this.hideRightbar()
    },
    fetchFundFileList () {      
      FileService.getFundFiles(this.opsRequest.requestorId).then(
          (response) => {            
            if (response.data.length > 0) {
              this.fundFiles = response.data
            } else {
              this.fundFiles = []
            }
          }
        )
    },
    getDetails () {
      OpsTeamService.get(this.id)
      .then((response) => {
        this.opsRequest = response.data
        // fund
        if (this.opsRequest.categoryId === 1 || this.opsRequest.categoryId === 5 || this.opsRequest.categoryId === 7) {
          FundService.get(this.opsRequest.requestorId)
          .then((response) => {
            this.fundObj = response.data
            this.fetchFundFileList()
          })
        } else if (this.opsRequest.categoryId === 2 || this.opsRequest.categoryId === 6) { // ServiceProvider
          const r = this.allOprServiceProviders.filter(x => x.serviceProviderId === this.opsRequest.requestorId)
          if (r != null) {
            this.providerObj = r
          }
        } else if (this.opsRequest.typeId === 2 && this.opsRequest.categoryId === 4) { // OprInvestors
          const r = this.allOprInvestors.filter(x => x.investorId === this.opsRequest.requestorId)
          if (r != null) {
            this.investorObj = r
          }
        }
      })
      .then(() => { this.fetchFileList() })
      .then(() => { this.asyncDataStatus_fetched('Ops Team Workflow') })
    },
    save () {
      this.$v.opsRequest.$touch()
      if (this.$v.opsRequest.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }

      OpsTeamService.save(this.id, this.opsRequest)
      .then(
        (response) => {
          this.$snotify.success('Request Updated.')
          setTimeout(() => {
            this.$router.push({name: 'OpsList'})
          }, 400)
        })
        .catch(function (error) {
          console.error('SERVER Error:', error)
        })
    },
    withdraw () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to withdraw this request?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.updateStatus(4)
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    resubmit () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to resubmit this request?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.updateStatus(1)
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    updateStatus (statusId) {
      if (this.opsRequest.categoryId==1 && statusId === 5) {
        const needs17 = !this.fundFiles.some(file => file.purposeId == 17) 
        const needs18 = !this.fundFiles.some(file => file.purposeId == 18) 
        const needs8 = this.opsRequest.isKrollBackground && !this.fundFiles.some(file => file.purposeId == 8)
        const docMissing = (needs17 || needs18 || needs8)
        
        if (docMissing) {
         if (needs8) this.$snotify.warning('Please upload Background Check.', '', {timeout:6000})
         if (needs17 && needs18) {
          this.$snotify.warning('Please upload ODD Reports', '', {timeout:6000})
         } else if (needs17 || needs18) {
          const typeODD = needs17 ? '(INTERNAL)' : '(CLIENT)'
           this.$snotify.warning(`Please Upload ODD Report ${typeODD}`, '', {timeout:6000})
         }
         return
        }
      }
      this.opsRequest.statusId = statusId
      OpsTeamService.updateStatus(this.id, this.opsRequest)
      .then(
        (response) => {
          this.$snotify.success('Request Status Updated.')
        })
        .catch(function (error) { console.error('SERVER Error:', error) })
    },
    setPickerDate (value) {
      if (value != null) {
        this.opsRequest.deadline = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0)
      } else {
        this.opsRequest.deadline = null
      }
    },
    setPickerDateKrollBackground (value) {
      if (value != null) {
        this.opsRequest.krollBackgroundCheckDueDate = moment(value).format('YYYY-M-D')
      } else {
        this.opsRequest.krollBackgroundCheckDueDate = null
      }
    },
    fetchFileList () {
      this.isLoadingFiles = true
      FileService.getOpsRequestFiles(this.id).then(
        (response) => {
          this.isLoadingFiles = false
          if (response.data.length > 0) {
            this.fileList = response.data
          } else {
            this.fileList = null
          }
        }
      )
    },
    sidebarAction (item, isOpen) {
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    showFile (id) {
      this.fetchFile({fileId: id})
      this.sidebarAction('file', true)
    },
    vsuccess (file, response) {
      this.fetchFileList()
    },
    onAssign () {
      if (this.userObj == null || this.userObj.userId === this.opsRequest.assignedTo) {
        this.assignError = true
        return
      } else {
        this.assignError = false
      }
      let req = {...this.opsRequest}
      req.assignedTo = this.userObj.userId
      OpsTeamService.updateAssign(this.id, req)
      .then(
        (response) => {
          this.opsRequest.assignedTo = this.userObj.userId
          this.userObj = null
          this.$snotify.success('Assignee Updated')
        })
        .catch(function (error) { console.error('SERVER Error:', error) })
    },
    fundSelected (value, id) {
      this.opsRequest.requestorId = value ? value.fundId : null
    },
    providerSelected (value, id) {
      this.opsRequest.requestorId = value ? value.serviceProviderId : null
    },
    investorSelected (value, id) {
      this.opsRequest.requestorId = value ? value.investorId : null
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    }
  },
  created () {
    this.authCookie = localStorage.getItem('auth_token')
    this.endDate = new Date(Date.now())
    this.fetchAllUsers()
    this.fetchAllOprInvestors()
    this.fetchAllOprServiceProviders()
    this.getDetails()
  }
}
</script>

<style src="vue2-dropzone/dist/vue2Dropzone.min.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
