<template>
  <div class="col-lg-6 col-md-12">
    <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
    <div v-if="list !== null && list.length > 0" class="card-box">
      <h4 class="header-title m-t-0">Ops Team Requests</h4>
      <p class="text-muted m-b-25 font-13">
        Active requests assigned to you ({{list.length}})
      </p>
      <div class="table-responsive">
          <div class="inbox-widget1 notes-box" style="overflow: auto; outline: none;">
            <table class="table mb-0 table-sortable table-striped">
                <thead>
                <tr>
                    <th>#</th>
                    <th @click="setOrder(0)">Request
                      <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                      <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                    </th>
                    <th @click="setOrder(1)">Type
                      <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                      <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                    </th>
                    <th @click="setOrder(2)">Deliver By
                      <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                      <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in sortedList" :key="index">
                    <td>{{index + 1}}</td>
                    <td>
                      <router-link :to="{name: 'OpsDetail', params: {id: item.requestId}}">
                        <div>
                          <div>
                            <p class='mb-0'>
                              {{item.categoryName}}
                            </p>
                          </div>
                        </div>
                      </router-link>
                    </td>
                    <td>
                      {{item.typeId == 1 ? 'Internal' : 'External' }}
                    </td>
                    <td>
                      <span  :class="lateClass(item.deadline)">
                        {{ displayDate(item.deadline) }}
                      </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OpsTeamService } from '@/services/opsteam-service'
import moment from 'moment'

export default {
  data () {
    return {
      list: null,
      isLoading: true,
      sortIndex: 2,
      sortType: 'ASC'
    }
  },
  created () {
    OpsTeamService.getDashboardList()
    .then((response) => {
      this.list = response.data
      this.isLoading = false
    })
  },
  methods: {
    isLate (deadline) {
      return moment().diff(deadline, 'days') > 0
    },
    lateClass (deadline) {
      let className = 'text-primary'
      const d = moment().diff(deadline, 'days')
      if (d > -2 && d <= 0) {
        className = 'text-warning'
      } else if (d > 0) {
        className = 'text-danger'
      }
      return className
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
    },
    displayDate (date) {
      return moment(date).format('MMMM D')
    }
  },
  computed: {
    sortedList () {
      let result = this.list
      switch (this.sortIndex) {
        case 0:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.categoryName > b.categoryName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.categoryName < b.categoryName) ? 1 : -1)
          }
          break
        case 1:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.requestorFullName > b.requestorFullName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.requestorFullName < b.requestorFullName) ? 1 : -1)
          }
          break
        case 2:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.deadline > b.deadline) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.deadline < b.deadline) ? 1 : -1)
          }
          break
      }
      return result
    }
  }
}
</script>