<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a class="nav-link active show">
              Import {{firmOrDeal}}
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <p>Please choose the best option</p>
      <div class="form-group">
          <div class="custom-control custom-radio" v-if="relatedList && relatedList.length > 0">
            <input type="radio" id="conflictType1" value=4 v-model="choose" name="new-name" class="custom-control-input" @change="formValidate">
            <label class="custom-control-label" for="conflictType1">Related</label>
          </div>
          <div class="form-group" v-if="choose == 4">
            <ol>
              <li v-for="(item, index) in relatedList" :key="index" class="mb-2">
                <router-link :to="{name: linkName, params: {id: relatedList[index][valueName]}}" target="_blank" v-tooltip="'Open details'">
                  {{relatedList[index][displayName]}} <i class="mdi mdi-open-in-new"></i>
                </router-link>
                <button class="btn btn-mini btn-icon waves-effect waves-light btn-primary" @click.prevent="bindUmbrella(relatedList[index][valueName])"><i class="mdi mdi-check"></i></button>
              </li>
            </ol>
          </div>
          <div class="custom-control custom-radio" v-if="list.length > 0">
            <input type="radio" id="conflictType1" value=1 v-model="choose" name="new-name" class="custom-control-input" @change="formValidate">
            <label class="custom-control-label" for="conflictType1">Matching</label>
          </div>
          <div class="form-group" v-if="choose == 1">
            <ol>
              <li v-for="(item, index) in list" :key="index" class="mb-2">
                <router-link :to="{name: linkName, params: {id: list[index][valueName]}}" target="_blank" v-tooltip="'Open details'">
                  {{list[index][displayName]}} <i class="mdi mdi-open-in-new"></i>
                </router-link>
                <button class="btn btn-mini btn-icon waves-effect waves-light btn-primary" @click.prevent="bindUmbrella(list[index][valueName])"><i class="mdi mdi-check"></i></button>
              </li>
            </ol>
          </div>
        <div class="custom-control custom-radio">
          <input type="radio" id="conflictType2" value=2 v-model="choose" name="new-name" class="custom-control-input" @change="formValidate">
          <label class="custom-control-label" for="conflictType2">Existing</label>
        </div>
          <div class="form-group" v-if="choose == 2 && firmOrDeal == 'Firm'">
              <label for="oldfirm">Firm</label>
              <multiselect id="oldfirm" 
                v-model="firmObj" 
                :options="searchFirms" 
                label="firmName" 
                track-by="firmId" 
                :allow-empty="true" 
                placeholder="Type to Search"
                @input="firmSelected" 
                @search-change="asyncSearchFirm" 
                :loading="isSearchingList" 
                :options-limit="50">
              </multiselect>
          </div>

          <div class="form-group" v-if="choose == 2 && firmOrDeal == 'Deal'"> 
              <label for="oldfund">Deal</label>
              <multiselect id="oldfund" 
                v-model="fundObj" 
                :options="searchFunds" 
                label="fundName" 
                track-by="fundId" 
                :allow-empty="true" 
                placeholder="Type to Search"
                @input="fundSelected" 
                @search-change="asyncSearchFund" 
                :loading="isSearchingList" 
                :options-limit="50">
              </multiselect>
          </div>

        <div class="custom-control custom-radio">
          <input type="radio" id="conflictType3" value=3 v-model="choose" name="new-name" class="custom-control-input" @change="formValidate">
          <label class="custom-control-label" for="conflictType3">New</label>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button class="btn btn-success waves-effect w-md waves-light" :disabled="!isConfirm" @click.prevent="confirmedSave"><i class="mdi mdi-check-circle-outline m-r-5"></i> Confirm</button>
      </div>
    </form>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'

export default {
  props: {
    list: {
      required: true,
      type: Array
    },
    relatedList: {
      required: false,
      type: Array
    },
    displayName: {
      required: true,
      type: String
    },
    valueName: {
      required: true,
      type: String
    },
    linkName: {
      required: true,
      type: String
    },
    firmOrDeal: {
      required: true,
      type: String
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      isConfirm: false,
      searchFirms: [],
      searchFunds: [],
      isSearchingList: false,
      firmObj: null,
      fundObj: null,
      choose: null
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['searchAllFirms', 'searchAllFunds']),
    bindUmbrella (id) {
      this.hideRightbar()
      this.isConfirm = false
      this.$emit('onConfirm', id)
    },
    confirmedSave () {
      const choose = parseInt(this.choose)
      if (choose === 2 && this.firmOrDeal === 'Firm') {
        this.bindUmbrella(this.firmObj.firmId)
      } else if (choose === 2 && this.firmOrDeal === 'Deal') {
        this.bindUmbrella(this.fundObj.fundId)
      } else if (choose === 3) {
        this.hideRightbar()
        this.isConfirm = false
        this.$emit('onConfirm', 0)
      }
    },
    close () {
      this.hideRightbar()
      this.isConfirm = false
    },
    firmSelected (value) {
      if (value) {
        this.firmObj.firmId = value ? value.firmId : null
        this.isConfirm = true
      } else {
        this.isConfirm = false
      }
    },
    fundSelected (value) {
      if (value) {
        this.fundObj.fundId = value ? value.fundId : null
        this.isConfirm = true
      } else {
        this.isConfirm = false
      }
    },
    asyncSearchFirm (query) {
      this.isSearchingList = true
      this.searchAllFirms({query}).then((result) => {
        this.searchFirms = result
        this.isSearchingList = false
      })
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    },
    formValidate () {
      switch (parseInt(this.choose)) {
        case 1:
          this.isConfirm = false
          break
        case 2:
          this.firmOrDeal === 'Firm' ? this.isConfirm = this.firmObj != null : this.isConfirm = this.fundObj != null
          break
        case 3:
          this.isConfirm = true
          break
      }
    }
  },
  created () {
    this.list.length > 0 ? this.choose = 1 : this.choose = 2
    if (this.relatedList.length > 0) {
      this.choose = 4
    }
  }
}
</script>