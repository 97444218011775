import Constants from '../constants.js'
import Axios from 'axios'

export const FundMonitoringService = {
  get({fundId, quarter, year}) {
    return Axios.get(Constants.API_URL + '/fundmonitoring/fund/' + fundId + '/' + quarter + '/' + year)
  },
  save(id, value) {
    return Axios.put(Constants.API_URL + '/fundmonitoring/' + id, value)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/fundmonitoring', value)
  },
  getPortfolio({fundId, quarter, year}) {
    return Axios.get(Constants.API_URL + '/fundmonitoringportfolios/fund/' + fundId + '/' + quarter + '/' + year)
  },
  savePortfolio(id, value) {
    return Axios.put(Constants.API_URL + '/fundmonitoringportfolios/' + id, value)
  },
  addPortfolio (value) {
    return Axios.post(Constants.API_URL + '/fundmonitoringportfolios', value)
  },
  deletePortfolio (value) {
    return Axios.delete(Constants.API_URL + '/fundmonitoringportfolios/'+ value)
  },
  importPrevQData ({FundId, QuarterFrom, YearFrom, QuarterTo, YearTo}) {
    return Axios.post(Constants.API_URL + `/fundmonitoring/autofill?FundId=${FundId}&QuarterFrom=${QuarterFrom}&YearFrom=${YearFrom}&QuarterTo=${QuarterTo}&YearTo=${YearTo}`)
  },
  // The below request is used in PortfolioDetail.vue
  getByPortfolio({portfolioId, quarter, year}) { //returns multiple funds that belong to a portfolio 
    return Axios.get(Constants.API_URL + '/fundmonitoring/' + '/portfolio/' + portfolioId + '/' + quarter + '/' + year)
  },
}
export const FundService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/fund/' + id)
  },
  getModel (id) {
    //this is subset of fund data
    return Axios.get(Constants.API_URL + '/fund/model/' + id)
  },
  search (name, sortField, sortType, pageNumber, pageSize, countryId, geos, stages, types, sectors, statuses, tier) {
    if(statuses=="1"){
      statuses=1
    } else if (statuses=="0"){
      statuses = 0
    } else {
      statuses=null
    }
    return Axios.get(Constants.API_URL + '/fund/search?name=' + name + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&countryId=' + countryId + '&geoId=' + geos + '&stageId=' + stages + '&typeId=' + types + '&sectorId=' + sectors + '&statusId=' + statuses + '&tireId=' + tier)
  },
  pipeline (pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/fund/pipeline?pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/fund/' + id, value)
  },
  add (fund) {
    return Axios.post(Constants.API_URL + '/fund', fund)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/fund/' + id)
  },
  startScreening (id) {
    return Axios.put(Constants.API_URL + '/fund/start-screening/' + id)
  },
  startFlrVoting (id) {
    return Axios.put(Constants.API_URL + '/fund/startvoting-flr/' + id)
  },
  stopFlrVoting (id) {
    return Axios.put(Constants.API_URL + '/fund/stopvoting-flr/' + id)
  },
  startRrVoting (id) {
    return Axios.put(Constants.API_URL + '/fund/startvoting-rr/' + id)
  },
  stopRrVoting (id) {
    return Axios.put(Constants.API_URL + '/fund/stopvoting-rr/' + id)
  },
  getForMeetingPMMrc (targetDate) {
    return Axios.get(Constants.API_URL + '/fund/meeting-pmmrc/' + targetDate)
  },
  getForMeetingMAMrc (targetDate) {
    return Axios.get(Constants.API_URL + '/fund/meeting-mamrc/' + targetDate)
  },
  doublecheckName (fundName) {
    return Axios.get(Constants.API_URL + '/fund/doublecheck/' + fundName)
  },
  doubleCheckFunds (fundId, values) {
    return Axios.post(Constants.API_URL + '/fund/check-fund-exists/' + fundId, values)
  },
  sourcing (geoId) {
    return Axios.get(Constants.API_URL + '/fund/sourcing/' + geoId)
  },
  sourcingPost (value) {
    return Axios.post(Constants.API_URL + '/fund/sourcing-post/', value)
  },
  startFlr (id) {
    return Axios.put(Constants.API_URL + '/fund/start-flr/' + id)
  },
  startAr (id) {
    return Axios.put(Constants.API_URL + '/fund/start-ar/' + id)
  },
  addNext (id, fund) {
    return Axios.post(Constants.API_URL + '/fund/addnext/' + id, fund)
  },
  getPorfolioFunds (id) {
    return Axios.get(Constants.API_URL + '/portfolio/fund/' + id)
  }
}

export const FundTeamService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/fundteam/' + id)
  },
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/fundteam/fund/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/fundteam/' + id, value)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/fundteam', value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/fundteam/' + id)
  },
  getActiveStat () {
    return Axios.get(Constants.API_URL + '/fundteam/active-teams-stat')
  }
}

export const FundIndustryFocusService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/fundindustryfocus/' + id)
  },
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/fundindustryfocus/fund/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/fundindustryfocus/' + id, value)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/fundindustryfocus', value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/fundindustryfocus/' + id)
  }
}

export const FundTrackRecordService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/fundtrackreport/' + id)
  },
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/fundtrackreport/fund/' + id)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/fundtrackreport/', value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/fundtrackreport/' + id)
  }
}

export const FundRegionalFocusService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/fundcountryfocus/' + id)
  },
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/fundcountryfocus/fund/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/fundcountryfocus/' + id, value)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/fundcountryfocus', value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/fundcountryfocus/' + id)
  }
}

export const FundMeritConcernService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/fundmeritconcern/' + id)
  },
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/fundmeritconcern/fund/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/fundmeritconcern/' + id, value)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/fundmeritconcern', value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/fundmeritconcern/' + id)
  }
}

export const FundOpportunityService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/fundopportunityset/' + id)
  },
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/fundopportunityset/fund/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/fundopportunityset/' + id, value)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/fundopportunityset', value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/fundopportunityset/' + id)
  }
}

export const FundVotingService = {
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/fundvotings/fund/' + id)
  },
  get (id) {
    return Axios.get(Constants.API_URL + '/fundvotings/flr/' + id)
  },
  getFlrVersion (id) {
    return Axios.get(Constants.API_URL + '/fundvotings/flr/summary/version/' + id)
  },
  getRrVersion (id) {
    return Axios.get(Constants.API_URL + '/fundvotings/rr/summary/version/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/fundvotings/flr/' + id, value)
  },
  add (fund) {
    return Axios.post(Constants.API_URL + '/fundvotings/flr', fund)
  },
  createFlrVersion (fund) {
    return Axios.post(Constants.API_URL + '/fundvotings/flr/version/' + fund)
  },
  createRrVersion (fund) {
    return Axios.post(Constants.API_URL + '/fundvotings/rr/version/' + fund)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/fundvotings/flr/' + id)
  },
  checkFlr (vote) {
    return Axios.post(Constants.API_URL + '/fundvotings/flr/check', vote)
  },
  getRr (id) {
    return Axios.get(Constants.API_URL + '/fundvotings/rr/' + id)
  },
  saveRr (id, value) {
    return Axios.put(Constants.API_URL + '/fundvotings/rr/' + id, value)
  },
  addRr (value) {
    return Axios.post(Constants.API_URL + '/fundvotings/rr', value)
  },
  deleteRr (id) {
    return Axios.delete(Constants.API_URL + '/fundvotings/rr/' + id)
  },
  checkRr (vote) {
    return Axios.post(Constants.API_URL + '/fundvotings/rr/check', vote)
  },
  getRrSummary (id) {
    return Axios.post(Constants.API_URL + '/fundvotings/rr/summary/' + id)
  },
  getFlrSummary (id) {
    return Axios.post(Constants.API_URL + '/fundvotings/flr/summary/' + id)
  },
  checkFlrFinalize (id) {
    return Axios.post(Constants.API_URL + '/fundvotings/flr/checkfinalize/' + id)
  },
  checkRrFinalize (id) {
    return Axios.post(Constants.API_URL + '/fundvotings/rr/checkfinalize/' + id)
  },
  showFlrVotes (id) {
    return Axios.post(Constants.API_URL + '/fundvotings/flr/showvotes/' + id)
  },
  showRrVotes (id) {
    return Axios.post(Constants.API_URL + '/fundvotings/rr/showvotes/' + id)
  },
  finalizeFlr (id) {
    return Axios.post(Constants.API_URL + '/fundvotings/flr/finalize/' + id)
  },
  finalizeRr (id) {
    return Axios.post(Constants.API_URL + '/fundvotings/rr/finalize/' + id)
  }
}

export const FundKeySeniorService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/fundkeysenior/' + id)
  },
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/fundkeysenior/fund/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/fundkeysenior/' + id, value)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/fundkeysenior', value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/fundkeysenior/' + id)
  }
}

export const FundOnsiteMeetingService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/fundonsitemeeting/' + id)
  },
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/fundonsitemeeting/fund/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/fundonsitemeeting/' + id, value)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/fundonsitemeeting', value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/fundonsitemeeting/' + id)
  }
}

export const SecFundService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/secfund/' + id)
  },
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/secfund/fund/' + id)
  },
  getByFirmId (id) {
    return Axios.get(Constants.API_URL + '/secfund/firm/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/secfund/' + id, value)
  },
  add (id, value) {
    return Axios.post(Constants.API_URL + '/secfund/' + id, value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/secfund/' + id)
  }
}

export const CoInvestmentClientService = {
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/coinvestment-client/fund/' + id)
  },
  get (id) {
    return Axios.get(Constants.API_URL + '/coinvestment-client/' + id)
  },
  add (id, value) {
    return Axios.post(Constants.API_URL + '/coinvestment-client/' + id, value)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/coinvestment-client/' + id, value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/coinvestment-client/' + id)
  }
}

export const NotesService = {
  getTemplates () {
    return Axios.get(Constants.API_URL + '/activity-templates/')
  }
}
