<template>
  <div class="form-group mt-4">
    <form action="" autocomplete="off">
      <div>
        <ul class="nav nav-tabs tabs-bordered nav-justified">
          <li class="nav-item">
              <a class="nav-link active show">
                Status Updates <span class="badge badge-primary noti-icon-badge">{{statusUpdates.length}}</span>
              </a>
          </li>
        </ul>
        <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
        <table class="table table-striped table-hover">
          <tbody>
            <tr v-if="statusUpdates.length === 0">
              <td class="text-center" colspan="2">
                <p>No Status Update</p>
              </td>
            </tr>
            <tr v-for="su in statusUpdates" :key="su.statusUpdateId">
              <td>
                <AppUserPic :userId="su.createdBy" :show="'text'" :size="1" />
                <AppDate :date="su.createdOn" :show="'text'" /> - {{su.comments}}
              </td>
              <td>
                <div class="pull-right" v-if="canAdd">
                  <button class="btn btn-danger btn-sm btn-icon" @click.prevent="onDelete(su.statusUpdateId)"><i class="mdi mdi-delete"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span v-if="canAdd">
        <label for="newStatusUpdate">New Update</label>
        <input type="text" class="form-control" id="newStatusUpdate" v-model="form.newStatusUpdate" :class="{ 'parsley-error': $v.form.newStatusUpdate.$error }" >
        <template v-if="$v.form.newStatusUpdate.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.form.newStatusUpdate.required" class="parsley-required">This value is required</li>
            <li v-if="!$v.form.newStatusUpdate.min" class="parsley-required">This value is too short</li>
            <li v-if="!$v.form.newStatusUpdate.max" class="parsley-required">This value is too long</li>
          </ul>
        </template>
        <button class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addnewStatusUpdate" :disabled="isAdding" ><i class="mdi mdi-comment-plus-outline"></i> 
          <span v-if="!isAdding">Add</span> <i v-else class="fa fa-spinner fa-spin"></i>
        </button>
      </span>
    </form>
  </div>  
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { LegalStatusUpdateService } from '@/services/legal-service'

export default {
  props: {
    id: {
      required: true,
      type: Number
    },
    canAdd: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      statusUpdates: [],
      isLoading: false,
      isAdding: false,
      form: {
        newStatusUpdate: null
      }
    }
  },
  validations: {
    form: {
      newStatusUpdate: { required, min: minLength(3), max: maxLength(500) }
    }
  },
  mounted () {
    setTimeout(() => {
      this.loadList()
    }, 1000)
  },
  methods: {
    loadList () {
      this.isLoading = true
      LegalStatusUpdateService.get(this.id)
      .then(
        (response) => {
          this.statusUpdates = response.data
          this.isLoading = false
        })
    },
    addnewStatusUpdate () {
      this.$v.form.newStatusUpdate.$touch()
      if (this.$v.form.$error) {
        return
      }
      const newComment = {'comments': this.form.newStatusUpdate}
      this.isAdding = true
      LegalStatusUpdateService.add(this.id, newComment)
      .then(() => {
        this.loadList()
        this.isAdding = false
        this.form.newStatusUpdate = null
        this.$v.form.$reset()
        this.$snotify.success('Status Added.')
      })
    },
    onDelete (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                LegalStatusUpdateService.delete(id)
                .then((response) => {
                  // delete from Array
                  const i = this.statusUpdates.findIndex(x => x.statusUpdateId === id)
                  this.statusUpdates.splice(i, 1)
                  this.$snotify.success('Status Deleted.')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    }
  }
}
</script>