<template>
<div>
<ul class="nav nav-tabs tabs-bordered nav-justified">
    <li class="nav-item">
        <a class="nav-link active show">
            Deal Documents <span class="badge badge-primary noti-icon-badge" v-if="fundFiles">{{fundFiles.length}}</span>
        </a>
    </li>
</ul>                    
<FileList :files="fundFiles" :isEditMode="true" :canEdit="true"  @onClickNew="clickAddFile"   />
</div>
</template>

<script>
import FileList from '@/components/FileList'
import rightbar from '@/mixins/rightbar'

export default {
  props: {  
    fundFiles: {
      required: true,
      type: Array
    }
  },
  components: {
    FileList
  },
  data () {
    return {       
    }
  },
  mixins: [rightbar],
  methods: {
    clickAddFile () {
      this.$store.commit('setNewFile', this.fundId)
      this.showRightbar()
    }   
  }
}
</script>
