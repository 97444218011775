<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portfolio-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Portfolios Lists'" :icon="'mdi mdi-briefcase'" />
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box table-responsive">
                                <h4 class="header-title">Portfolio list</h4>
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-6 form-inline mt-3">
                                      
                                      <select class="form-control form-control-sm" v-model="clientTypeId" @change="startSearch">
                                          <option value="0">All Types</option>
                                          <option value="1">Advisory</option>
                                          <option value="2">Discretionary</option>
                                      </select>
                                      <div class="ml-2">
                                        <label class="col-form-label">
                                          <span class="mr-1">Only Open</span>
                                          <toggle-button v-model="onlyOpen" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="startSearch"/>
                                        </label>
                                      </div>
                                      <div class="ml-2">
                                        <label class="col-form-label">
                                          <span class="mr-1">Only Mine</span>
                                          <toggle-button v-model="onlyUser" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="startSearch"/>
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div>
                                            <label>
                                                <input type="search" v-model="searchName" class="form-control" placeholder="Search by name" @keyup.enter="submitSearch">
                                            </label>
                                            <button @click="startSearch" class="btn btn-icon waves-effect waves-light btn-primary m-b-5"> <i class="fa fa-search"></i> </button>
                                            <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                        </div>
                                    </div>
                                </div>

                                <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)"> Client Name
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)"> Client Type
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th> Commitment
                                        </th>
                                        <th @click="setOrder(3)"> Currency
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(4)"> Status
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                            
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="portfolio in portfolios"
                                          :key="portfolio.portfolioId">
                                          <td>
                                              <router-link :to="{name: 'PortfolioDetail', params: {id: portfolio.portfolioId}}">
                                                  {{ portfolio.portfolioName }}
                                              </router-link>
                                          </td>
                                          <td>{{ portfolio.clientTypeId === 1 ? 'Advisory' : 'Discretionary' }}</td>
                                          <td>
                                            <span v-if="portfolio.clientTypeId == 2">
                                              {{ showSymbol(portfolio.currencyId) }}{{ portfolio.totalCommitment | thousandNumber }}
                                            </span>
                                          </td>
                                          <td>{{ showCurrency(portfolio.currencyId) }}</td>
                                          <td>
                                            <span v-if="portfolio.clientTypeId == 1">-</span>
                                            <span v-else>
                                              <span class="badge badge-warning" v-if="portfolio.isClosed">Closed</span>
                                              <span class="badge badge-success" v-if="portfolio.isActive">Active</span>
                                              <span class="badge white-bg" v-if="!portfolio.isActive && !portfolio.isClosed">Inactive</span>
                                            </span>
                                          </td>
                                      </tr>
                                    </tbody>
                                </table>
                   
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div role="status">Page {{currentPage}} of {{totalPages}} - {{totalRecords | thousandNumber}} Client Portfolios</div>
                                    <div class="px-3">
                                          <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                              <option value="250">250</option>
                                          </select>
                                    </div>
                                    
                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end container -->
        </div>
        <!-- end content -->

        <Footer />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PortfolioService } from '@/services/portfolio-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
  },
  data () {
    return {
      portfolios: [],
      isLoading: null,
      searchName: '',
      pageSize: 25,
      currentPage: 1,
      sortBy: ['PortfolioName', 'ClientTypeId', 'TotalCommitment', 'CurrencyId', 'IsClosed'],
      sortIndex: 0,
      sortType: 'ASC',
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0,
      clientTypeId: 0,
      onlyOpen: false,
      onlyUser: false
    }
  },
  methods: {
    ...mapActions(['fetchAllCurrencies']),
    getList () {
      
      const filters = {
        'searchName': this.searchName,
        'clientTypeId': this.clientTypeId,
        'onlyUser': this.onlyUser,
        'onlyOpen': this.onlyOpen,
        'pageSize': this.pageSize,
        'sortIndex': this.sortIndex,
        'sortType': this.sortType
      }
      
      localStorage.setItem('umb_portfolios_filters', JSON.stringify(filters))
      this.isLoading = true
      
      PortfolioService.search(this.searchName.trim(), this.clientTypeId, this.onlyOpen, this.onlyUser ? this.user.userId : 0, this.sortBy[this.sortIndex], this.sortType, this.currentPage, this.pageSize)
      .then((response) => {
        this.isLoading = false
        
        if (this.sortIndex === 4) {
          this.sortByStatus(response.data.portfolios, this.sortType === 'ASC')
        }
        
        this.portfolios = response.data.portfolios
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Portfolios Lists') })
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    startSearch () {
      
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.portfolios = []
      if (this.searchName == null) {
        this.searchName = ''
      }

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    showCurrency (cur) {
      return this.allCurrencies.find(x => x.currencyId === cur).currencyCode
    },
    showSymbol (cur) {
      return this.allCurrencies.find(x => x.currencyId === cur).symbol
    },
    resetFilter () {
      
      this.searchName = ''
      this.clientTypeId = 0
      this.onlyOpen = false
      this.onlyUser = false
      this.startSearch()
    },
    sortByStatus(list, asc) {
      // sort order = clientTypeId > isClosed > isActive
      list?.sort((a, b) => {
        if (a.clientTypeId != 1 && b.clientTypeId == 1) {
          return asc ? 1 : -1
        } else if (a.clientTypeId == 1 && b.clientTypeId != 1) {
          return asc ? -1 : 1
        }
        else if (a.isClosed < b.isClosed) {
          return asc ? 1 : -1
        } else if (a.isClosed > b.isClosed) {
          return asc ? -1 : 1
        }
        else if (a.isActive > b.isActive) {
          return asc ? 1 : -1
        } else if (a.isActive < b.isActive) {
          return asc ? -1 : 1
        } else return 0
      })
    },
  },
  mounted () {
    const oldFilters = JSON.parse(localStorage.getItem('umb_portfolios_filters'))
    if (oldFilters != null) {
      
      if (oldFilters.clientTypeId > 0) {
        this.clientTypeId = oldFilters.clientTypeId
      }
      if (oldFilters.searchName != null) {
        this.searchName = oldFilters.searchName
      }
      if (oldFilters.onlyOpen != null) {
        this.onlyOpen = oldFilters.onlyOpen
      }
      if (oldFilters.onlyUser != null) {
        this.onlyUser = oldFilters.onlyUser
      }
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
      if (oldFilters.sortIndex != null) {
        this.sortIndex = oldFilters.sortIndex
      }
      if (oldFilters.sortType != null) {
        this.sortType = oldFilters.sortType
      }
    }
    this.fetchAllCurrencies()
    this.startSearch()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    hasFilter () {
      return this.searchName.length > 0 || this.clientTypeId >0 || this.onlyOpen || this.onlyUser
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  },
  mixins: [asyncDataStatus]
}
</script>
