<template>
    <div class="account-pages mt-5 mb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card">
                        <div class="card-body p-4">                            
                            <div class="text-center w-85 m-auto">
                                <div class="text-center">
                                    <a class="logo-lg">
                                        <i class="mdi mdi-umbrella"></i>
                                        <span>Umbrella</span>
                                    </a>
                                </div>
                                <p class="text-muted mb-3 mt-3 pb-3">Seems it is a rainy day for you.<br/> Enter your username and password to open THE Umbrella.</p>
                            </div>

                            <form @submit.prevent="signIn">
                                <div class="form-group row">
                                    <div class="col-12">
                                        <div class="input-group" :class="{ 'has-error': $v.form.userName.$error, 'valid': !$v.form.userName.$invalid }">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="mdi mdi-account"></i></span>
                                            </div>
                                            <input v-model="form.userName" @blur="$v.form.userName.$touch()" class="form-control" type="text" placeholder="Username">
                                        </div>
                                        <template v-if="$v.form.userName.$error">
                                          <ul class="parsley-errors-list filled">
                                            <li v-if="!$v.form.userName.required" class="parsley-required">User name is required</li>
                                            <li v-if="!$v.form.userName.min && $v.form.userName.required" class="parsley-required">User name is too short</li>
                                            <li v-if="!$v.form.userName.max && $v.form.userName.min" class="parsley-required">User name is too long</li>
                                          </ul>
                                        </template>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-12">
                                        <div class="input-group" :class="{ 'has-error': $v.form.password.$error }">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="mdi mdi-key"></i></span>
                                            </div>
                                            <input v-model="form.password" @blur="$v.form.password.$touch()" class="form-control" type="password" placeholder="Password">
                                        </div>
                                        <template v-if="$v.form.password.$error">
                                          <ul class="parsley-errors-list filled">
                                            <li v-if="!$v.form.password.required" class="parsley-required">Password is required</li>
                                            <li v-if="!$v.form.password.min && $v.form.password.required" class="parsley-required">Password is too short</li>
                                            <li v-if="!$v.form.password.max && $v.form.password.min" class="parsley-required">Password is too long</li>
                                          </ul>
                                        </template>
                                    </div>
                                </div>

                                <p class="text-center error mt-3" v-if="errorMessage">
                                    {{ errorMessage }}
                                </p>

                                <div class="form-group mb-0 text-center">
                                    <button class="btn btn-primary btn-block" type="submit" :disabled="isLoading">
                                      
                                      <span v-if="isLoading">
                                        Logging you in <i class="fa fa-spinner fa-spin"></i>
                                      </span>
                                      <span v-else>Log In</span>
                                    </button>
                                </div>

                            </form>
                        </div> 
                        <!-- end card-body -->
                        <p class="text-center mt-3">
                            {{year}} &copy; Wilshire Private Markets ❤️<br/>Version: {{version}}
                        </p>
                    </div>
                    <!-- end card -->

                    <!-- <div class="row mt-3">
                        <div class="col-12 text-center">
                            <p> <a href="pages-recoverpw.html" class="text-muted ml-1">Forgot your password?</a></p>
                            <p class="text-muted">Don't have an account? <a href="pages-register.html" class="text-primary font-weight-medium ml-1">Sign Up</a></p>
                        </div>
                    </div> -->
                    <!-- end row -->
                </div> <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { AuthService } from '@/services/auth-service'
import { UserService } from '@/services/user-service'
import Axios from 'axios'
import store from '@/store'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Constants from '@/constants'

export default {
  data () {
    return {
      form: {
        userName: '',
        password: ''
      },
      errorMessage: null,
      isLoading: false,
      year: new Date().getFullYear(),
      version: Constants.VERSION
    }
  },
  mixins: [asyncDataStatus],
  validations: {
    form: {
      userName: { required, min: minLength(3), max: maxLength(100) },
      password: { required, min: minLength(6), max: maxLength(100) }
    }
  },
  created () {
    this.$cookies.remove('auth_token')
    store.commit('setActiveUser', null)
    sessionStorage.clear()
    this.asyncDataStatus_fetched('Signin')
  },
  methods: {
    signIn () {
      this.errorMessage = null
      this.$v.form.$touch()
      store.commit('setActiveUser', null)
      if (this.$v.form.$error) return

      this.isLoading = true
      AuthService.login(this.form.userName, this.form.password)
      .then((response) => {
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data
        UserService.current()
        .then((userResponse) => {
          store.commit('setActiveUser', userResponse.data)
          if (userResponse.data !== '') {
            sessionStorage.setItem('currentUser', JSON.stringify(userResponse.data))
          }
        })

        localStorage.setItem('auth_token', response.data)
        this.successRedirect()
      })
      .catch(error => {
        console.error('Umbrella login: ', error.message)
        // this.errorMessage = error.message.indexOf('Network Error') >= 0 ? 'Network Error! Please try again later.' : 'Could not verify username and password'
        this.errorMessage = 'Could not verify username and password'
        this.isLoading = false
      })
    },
    successRedirect () {
      const redirectTo = this.$route.query.redirectTo || {name: 'Home'}
      this.$router.push(redirectTo)
    }
  }
}
</script>
