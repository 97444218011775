<template>
  <div>
    <Topbar />
    <Sidebar :activeItem="'dashboard'"/>

    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid">
          <PageTitle :title="'Voting'"  :icon="'mdi mdi-square-inc-cash'"/>
          <div class="card-box">
            <h4 class="header-title m-t-0">
              <span class="text-primary">RR Voting: </span> {{vote.fundName}} <span class="badge badge-success noti-icon-badge">{{typeName}}</span>
              <router-link :to="{name: 'FundsDetail', params: {id}}" target="_blank" v-tooltip="'see deal details'">
                <i class="mdi mdi-open-in-new"></i>
              </router-link>
            </h4>
            <h5>
              Target RR Date: <span class="text-info">{{targetDate}}</span>
            </h5>
            
            <p class="text-muted font-14 m-b-20">Please fill the form.</p>

            <form action="#" autocomplete="off" @submit.prevent="save">
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label>Your rating</label>
                    <select id="rate" class="form-control col-2" v-model="vote.score">
                        <option value="null">Vote</option>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                  </div>

                  <div v-if="vote.fundTypeId == 1">
                      <div class="form-group">
                        <label for="organization" class="col-form-label">
                          Organization - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="organization" v-model.trim="vote.organization" @blur="$v.vote.organization.$touch()" :class="{ 'parsley-error': $v.vote.organization.$error }"></textarea>
                        <template v-if="$v.vote.organization.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.organization.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="team" class="col-form-label">
                          Team - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="team" v-model.trim="vote.team" @blur="$v.vote.team.$touch()" :class="{ 'parsley-error': $v.vote.team.$error }"></textarea>
                        <template v-if="$v.vote.team.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.team.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="strategy" class="col-form-label">
                          Strategy - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="strategy" v-model.trim="vote.strategy" @blur="$v.vote.strategy.$touch()" :class="{ 'parsley-error': $v.vote.strategy.$error }"></textarea>
                        <template v-if="$v.vote.strategy.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.strategy.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="process" class="col-form-label">
                          Process - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="process" v-model.trim="vote.process" @blur="$v.vote.process.$touch()" :class="{ 'parsley-error': $v.vote.process.$error }"></textarea>
                        <template v-if="$v.vote.process.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.process.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="market" class="col-form-label">
                          Market - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="market" v-model.trim="vote.market" @blur="$v.vote.market.$touch()" :class="{ 'parsley-error': $v.vote.market.$error }"></textarea>
                        <template v-if="$v.vote.market.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.market.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="performance" class="col-form-label">
                          Performance - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="performance" v-model.trim="vote.performance" @blur="$v.vote.performance.$touch()" :class="{ 'parsley-error': $v.vote.performance.$error }"></textarea>
                        <template v-if="$v.vote.performance.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.performance.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="terms" class="col-form-label">
                          Terms - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="terms" v-model.trim="vote.terms" @blur="$v.vote.terms.$touch()" :class="{ 'parsley-error': $v.vote.terms.$error }"></textarea>
                        <template v-if="$v.vote.terms.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.terms.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="terms" class="col-form-label">
                          ESG - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="terms" v-model.trim="vote.esg" @blur="$v.vote.esg.$touch()" :class="{ 'parsley-error': $v.vote.esg.$error }"></textarea>
                        <template v-if="$v.vote.esg.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.esg.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>
                  </div>

                  <div v-if="vote.fundTypeId == 2">
                      <div class="form-group">
                        <label for="generalPartner" class="col-form-label">
                          General Partner(s) - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="generalPartner" v-model.trim="vote.generalPartner" @blur="$v.vote.generalPartner.$touch()" :class="{ 'parsley-error': $v.vote.generalPartner.$error }"></textarea>
                        <template v-if="$v.vote.generalPartner.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.generalPartner.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="trackRecord" class="col-form-label">
                          Track Record - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="trackRecord" v-model.trim="vote.trackRecord" @blur="$v.vote.trackRecord.$touch()" :class="{ 'parsley-error': $v.vote.trackRecord.$error }"></textarea>
                        <template v-if="$v.vote.trackRecord.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.trackRecord.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="transactions" class="col-form-label">
                          Transaction - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="transactions" v-model.trim="vote.transactions" @blur="$v.vote.transactions.$touch()" :class="{ 'parsley-error': $v.vote.transactions.$error }"></textarea>
                        <template v-if="$v.vote.transactions.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.transactions.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="portfolio" class="col-form-label">
                          Portfolio - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="portfolio" v-model.trim="vote.portfolio" @blur="$v.vote.portfolio.$touch()" :class="{ 'parsley-error': $v.vote.portfolio.$error }"></textarea>
                        <template v-if="$v.vote.portfolio.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.portfolio.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="potentialReturns" class="col-form-label">
                          Potential Returns - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="potentialReturns" v-model.trim="vote.potentialReturns" @blur="$v.vote.potentialReturns.$touch()" :class="{ 'parsley-error': $v.vote.potentialReturns.$error }"></textarea>
                        <template v-if="$v.vote.potentialReturns.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.potentialReturns.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>
                  </div>

                  <div v-if="vote.fundTypeId == 3">
                      <div class="form-group">
                        <label for="generalPartner" class="col-form-label">
                          General Partner - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="generalPartner" v-model.trim="vote.generalPartner" @blur="$v.vote.generalPartner.$touch()" :class="{ 'parsley-error': $v.vote.generalPartner.$error }"></textarea>
                        <template v-if="$v.vote.generalPartner.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.generalPartner.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="company" class="col-form-label">
                          Company - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="company" v-model.trim="vote.company" @blur="$v.vote.company.$touch()" :class="{ 'parsley-error': $v.vote.company.$error }"></textarea>
                        <template v-if="$v.vote.company.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.company.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="transactions" class="col-form-label">
                          Transaction - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="transactions" v-model.trim="vote.transactions" @blur="$v.vote.transactions.$touch()" :class="{ 'parsley-error': $v.vote.transactions.$error }"></textarea>
                        <template v-if="$v.vote.transactions.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.transactions.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="valueCreationPlan" class="col-form-label">
                          Value Creation Plan - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="valueCreationPlan" v-model.trim="vote.valueCreationPlan" @blur="$v.vote.valueCreationPlan.$touch()" :class="{ 'parsley-error': $v.vote.valueCreationPlan.$error }"></textarea>
                        <template v-if="$v.vote.valueCreationPlan.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.valueCreationPlan.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="targetMarket" class="col-form-label">
                          Target Market - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="targetMarket" v-model.trim="vote.targetMarket" @blur="$v.vote.targetMarket.$touch()" :class="{ 'parsley-error': $v.vote.targetMarket.$error }"></textarea>
                        <template v-if="$v.vote.targetMarket.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.targetMarket.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>

                      <div class="form-group">
                        <label for="potentialReturns" class="col-form-label">
                          Potential Returns - Request Discussion or DD
                        </label>
                        <textarea class="form-control" rows="5" id="potentialReturns" v-model.trim="vote.potentialReturns" @blur="$v.vote.potentialReturns.$touch()" :class="{ 'parsley-error': $v.vote.potentialReturns.$error }"></textarea>
                        <template v-if="$v.vote.potentialReturns.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.vote.potentialReturns.max" class="parsley-required">This value is too long</li>
                          </ul>
                        </template>
                      </div>
                  </div>

                  <div class="form-group">
                    <label for="other" class="col-form-label">
                      Other - Request Discussion or DD
                    </label>
                    <textarea class="form-control" rows="5" id="other" v-model.trim="vote.other" @blur="$v.vote.other.$touch()" :class="{ 'parsley-error': $v.vote.other.$error }"></textarea>
                    <template v-if="$v.vote.other.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.vote.other.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group text-right m-b-0">
                    <router-link :to="{name: 'Home'}">
                      <button type="button" class="btn btn-secondary waves-effect mr-2">Back</button>
                    </router-link>

                    <button class="btn btn-primary waves-effect waves-light" type="submit" :disabled="isDouble">Submit</button>
                  </div>
                </div>
                <div class="col-lg-6 col-xs-12">
                  <ul class="nav nav-tabs tabs-bordered">
                      <li class="nav-item">
                          <a href="javascript:void(0)" class="nav-link active">
                              Documents
                          </a>
                      </li>
                  </ul>
                  <div class="tab-pane fade show active">
                    <span v-if="isLoadingFundFiles">Loading <i class="fa fa-spinner fa-spin"></i></span>
                    <FileList :files="fundFiles" :isEditMode="false" :canEdit="false" @onSelect="showFile" :showMoreTiggle="false"/>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="side-bar right-bar">
        <div class="col-sm-12">
          <FileItem v-if="rightbarItem == 'file'" :objectId="id" :objectTypeId="1" :isEditMode="false" :isAdd="false"/>
        </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { maxLength } from 'vuelidate/lib/validators'
import {mapGetters, mapActions} from 'vuex'
import { FundVotingService } from '@/services/fund-service'
import { FileService } from '@/services/file-service'
import FileList from '@/components/FileList'
import FileItem from '@/components/FileItem'
import rightbar from '@/mixins/rightbar'

export default {
  components: {
    FileList,
    FileItem
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      vote: {},
      isDouble: true,
      fundFiles: null,
      isLoadingFundFiles: false,
      rightbarItem: null
    }
  },
  validations: {
    vote: {
      organization: { max: maxLength(50000) },
      team: { max: maxLength(50000) },
      strategy: { max: maxLength(50000) },
      process: { max: maxLength(50000) },
      market: { max: maxLength(50000) },
      performance: { max: maxLength(50000) },
      terms: { max: maxLength(50000) },
      operation: { max: maxLength(50000) },
      fgrmc: { max: maxLength(50000) },
      esg: { max: maxLength(50000) },
      other: { max: maxLength(50000) },
      generalPartner: { max: maxLength(50000) },
      company: { max: maxLength(50000) },
      transactions: { max: maxLength(50000) },
      valueCreationPlan: { max: maxLength(50000) },
      targetMarket: { max: maxLength(50000) },
      potentialReturns: { max: maxLength(50000) },
      trackRecord: { max: maxLength(50000) },
      portfolio: { max: maxLength(50000) }
    }
  },
  mixins: [rightbar, asyncDataStatus],
  methods: {
    ...mapActions(['fetchFile']),
    save () {
      this.$v.vote.$touch()
      if (this.$v.vote.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      this.vote.userId = this.user.userId
      this.vote.fundId = this.id
      FundVotingService.addRr(this.vote)
      .then(
        (response) => {
          this.$snotify.success('Your Vote has been Submitted.')
          setTimeout(() => {
            this.$router.push({name: 'Home'})
          }, 400)
        })
        .catch(function (error) {
          console.error('SERVER Error:', error)
        })
    },
    checkForExsiting () {
      this.vote.userId = this.user.userId
      this.vote.fundId = this.id
      FundVotingService.checkRr(this.vote)
      .then(
        (response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error: link is not valid!')
          } else {
            this.vote = response.data
            if (this.vote.score !== null) {
              this.$snotify.info('You already voted for this Fund.')
              setTimeout(() => {
                this.$router.push({name: 'Home'})
              }, 1000)
            } else {
              this.isDouble = false
            }
          }
        }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    fetchFileList () {
      this.isLoadingFundFiles = true
      FileService.getFundFiles(this.id).then(
        (response) => {
          this.isLoadingFundFiles = false
          if (response.data.length > 0) {
            this.fundFiles = response.data
          } else {
            this.fundFiles = null
          }
        }
      )
    },
    showFile (id) {
      this.fetchFile({fileId: id})
      this.sidebarAction('file', false, true)
    },
    sidebarAction (item, isAdd, isOpen, isEdit) {
      this.addSidebar = isAdd
      this.sideInEditMode = isEdit || isAdd
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    targetDate () {
      if (this.vote.targetRRDate === undefined) {
        return 'loading'
      }
      const dt = new Date(this.vote.targetRRDate)
      return dt.toDateString()
    },
    typeName () {
      if (this.vote.fundTypeId === 1) {
        return 'Primary'
      } else if (this.vote.fundTypeId === 2) {
        return 'Secondary'
      }
      return 'Co-Investment'
    }
  },
  created () {
    this.asyncDataStatus_fetched('Submit RR Vote')
    this.checkForExsiting()
    this.fetchFileList()
  }
}
</script>