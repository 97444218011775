<template>
  <tbody>
    <tr v-if="meritsConcerns === null">
      <td colspan="3" class="text-center">No Data to show</td>
    </tr>
    <tr v-for="item in meritsConcerns"
        :key="item.meritConcernId">
        <td>
          <a href="javascript:void(0)" @click="select(item.meritConcernId)">{{item.meritConcernText}}</a></td>
        <td>{{item.meritConcernTypeId == 1 ? 'Merit' : 'Concern'}}</td>
        <td>
            <button class="btn btn-success btn-sm btn-mini btn-icon" v-if="canEdit" @click.prevent="update(item.meritConcernId)"><i class="mdi mdi-lead-pencil"></i></button>
            <button class="btn btn-danger btn-sm btn-mini btn-icon" v-if="canEdit" @click.prevent="onDelete(item)"><i class="mdi mdi-delete"></i></button>
        </td>
    </tr>
    <tr>
      <td colspan="3">
        <button v-if="!isEditMode && canEdit" class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addNew"> <i class="mdi mdi-comment-plus-outline"></i> New</button>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rightbar from '@/mixins/rightbar'

export default {
  props: {
    meritsConcerns: {
      type: Array,
      default: null
    },
    isEditMode: {
      required: true,
      type: Boolean
    },
    canEdit: {
      default: false,
      type: Boolean
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['deleteMeritConcern']),
    addNew () {
      this.hideRightbar()
      this.$emit('onClickNew')
    },
    select (id) {
      this.$emit('onSelect', id)
    },
    update (id) {
      this.$emit('onSelectUpdate', id)
    },
    onDelete (item) {
      const tp = item.meritConcernTypeId === 1 ? 'Merit' : 'Concern'
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deleteMeritConcern({meritConcernId: item.meritConcernId})
                .then((response) => {
                  this.$snotify.success(tp + ' Deleted.')
                  this.$emit('onUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  }
}
</script>
