<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'calendar'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Calendar'" :icon="'mdi mdi-calendar'"/>
                    <div class="card-box">

                      <div class="row m-b-10">
                            <div class="col-sm-12 col-md-12 form-inline">
                              <label class="col-form-label ml-2">
                                Private Markets MR &nbsp;
                                <toggle-button v-model="onlyPrivateMarketsMR" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="submitSearch"/>
                              </label>
                              <label class="col-form-label ml-2">
                                Marketable Alts MR &nbsp;
                                <toggle-button v-model="onlyMarketableAltsMR" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="submitSearch"/>
                              </label>
                              <label class="col-form-label ml-2">
                                Birthdays &nbsp;
                                <toggle-button v-model="onlyBirthdays" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="submitSearch"/>
                              </label>
                              <label class="col-form-label ml-2">
                                Others &nbsp;
                                <toggle-button v-model="onlyOthers" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="submitSearch"/>
                              </label>
                            </div>
                           
                        </div>

                        <v-calendar is-expanded is-dark
                          class="custom-calendar max-w-full" 
                          :attributes="attrs" 
                          :masks="masks"
                          :first-day-of-week="2"                                             
                          @update:from-page="getMonth"
                          >
                          <div slot="day-content"
                            slot-scope="attrs"
                            class="flex flex-col h-full z-10 overflow-hidden" @click="dayClicked(attrs.day)">
                            <span class="day-label rounded-sm today-bg" v-if="attrs.day.isToday">Today</span>
                              <span class="day-label" v-else>{{ attrs.day.day }}</span>
                            <div class="flex-grow overflow-x-auto">
                              <div  v-for="dayEvent in attrs.attributes" :key="dayEvent.key"  >
                                <span class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1" :style="'display:' + dayEvent.customData.display + ';background-color:' + dayEvent.customData.bgcolor + ';color: ' + dayEvent.customData.color">
                                  {{dayEvent.customData.title}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </v-calendar>
                      <div class="mt-2">
                        <span class="badge mr-2" style="backgroundColor: orange; color: black;">Today</span>
                        <span class="badge mr-2" style="backgroundColor: pink; color: black;">Company Holiday</span>
                        <span class="badge mr-2" style="backgroundColor: #F9E5BC; color: black;">Birthday</span>
                        <span class="badge stage3 mr-2" style="color: white;">Target FLR</span>
                        <span class="badge stage5 mr-2" style="color: white;">Target RR</span>
                        <span class="badge calendar-annual-meeting mr-2" style="color: black;">Annual Meeting</span>
                        <span class="badge calendar-onsite-meeting mr-2" style="color: white;">On-site Meeting</span>
                        <span class="badge calendar-deadline mr-2" style="color: white;">Deadline</span>
                        <span v-show="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
                      </div>
                      <div>
                        <div v-if="selectedDay" class='cal-selected-day mt-2'>
                            <h4>{{ selectedDay.date.toDateString() }}</h4>
                            <ul>
                                <li v-for="attr in selectedDay.attributes" :key="attr.key">
                                  <div v-if="attr.customData != null">
                                    <router-link :to="{name: attr.customData.link, params: {id: attr.customData.id}}" target="_blank" v-tooltip="'Open details'" v-if="attr.customData.id > 0 && attr.customData.code != 2">
                                      <span class="badge badge-pill" :class="getColorClass(attr.customData.code)">&nbsp;</span>
                                        {{ attr.customData.title }} <i class="mdi mdi-open-in-new"></i>
                                    </router-link>
                                    <div v-else>
                                      <span class="badge badge-pill" :class="getColorClass(attr.customData.code)">&nbsp;</span>
                                        <AppUserPic :userId="attr.customData.id" :size="2" v-if="attr.customData.code == 2" />
                                        {{ attr.customData.title }}
                                      <img :src="require('/static/img/usa-flag-16.png')" alt="United States" v-tooltip="'United States'" height="16" v-if="attr.customData.class == 'calendar-holiday-us' || attr.customData.class === 'calendar-holiday-all'"/>
                                      <img :src="require('/static/img/netherlands-flag-16.png')" alt="Amsterdam" v-tooltip="'Amsterdam'" height="16" v-if="attr.customData.class == 'calendar-holiday-nd' || attr.customData.class == 'calendar-holiday-nd-hk' || attr.customData.class == 'calendar-holiday-all'"/>
                                      <img :src="require('/static/img/hongkong-flag-16.png')" alt="Hong Kong" v-tooltip="'Hong Kong'" height="16" v-if="attr.customData.class == 'calendar-holiday-hk' || attr.customData.class == 'calendar-holiday-nd-hk' || attr.customData.class == 'calendar-holiday-all'"/>
                                    </div>
                                  </div>
                                </li>
                            </ul>
                        </div>
                        <div v-else class='cal-selected-day mt-2'>
                          <h4>No Date Selected</h4>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { CalendarService } from '@/services/calendar-service'

const color = '#fafafa'
const bgColor = '#333333'

export default {
  components: {
  },
  data () {    
    return {
      masks: {
        weekdays: 'WWW',
      },
      onlyPrivateMarketsMR: true,
      onlyMarketableAltsMR: true,
      onlyBirthdays: true,
      onlyOthers: true,
      isLoading: null,
      loadedCal: [],
      selectedDay: null,
      attrs: []
    }
  },
  mixins: [asyncDataStatus],
  created () {
    const oldFilters = JSON.parse(localStorage.getItem('umb_cal_filters'))
    if (oldFilters != null) {
      this.onlyPrivateMarketsMR = oldFilters.onlyPrivateMarketsMR
      this.onlyMarketableAltsMR = oldFilters.onlyMarketableAltsMR
      this.onlyBirthdays = oldFilters.onlyBirthdays
      this.onlyOthers = oldFilters.onlyOthers
    }
    // const dt = new Date()
    // const pg1 = {key: dt.getFullYear().toString() + '.' + (dt.getMonth() + 1).toString(), year: dt.getFullYear(), month: dt.getMonth() + 1}
    // this.getMonth(pg1)
    this.asyncDataStatus_fetched('Calendar')
  },
  methods: {
    dayClicked (day) {
      this.selectedDay = day
    },
    submitSearch () {
      const filters = {
        'onlyPrivateMarketsMR': this.onlyPrivateMarketsMR,
        'onlyMarketableAltsMR': this.onlyMarketableAltsMR,
        'onlyBirthdays': this.onlyBirthdays,
        'onlyOthers': this.onlyOthers
      }
      
      localStorage.setItem('umb_cal_filters', JSON.stringify(filters))
      this.attrs.forEach(item => {
        item.customData.display = this.getDisplay(item.customData.code)
      })
    },
    getMonth (page) {
      this.selectedDay = null
      if (this.loadedCal.indexOf(page.month) < 0) {
        this.isLoading = true
        CalendarService.getCalendar(page.year, page.month)
        .then((response) => {
          this.loadedCal.push(page.month)
          response.data.forEach(cal => {
            this.addDate(page, cal)
          })
          this.isLoading = false
        }).catch(error => this.$snotify.error('Server Error! ' + error.message))
      }
    },
    addDate (pg, cal) {
      this.attrs.push(
        {
          key: 'event-' + pg.month + cal.calendarId + cal.calendarCode,
          customData: {
            id: cal.calendarId,
            link: cal.calendarLink,
            code: cal.calendarCode,
            title: cal.calendarTitle,
            class: cal.calendarClass,
            bgcolor: this.getColor(cal.calendarCode),
            color: this.getFontColor(cal.calendarCode),
            display: this.getDisplay(cal.calendarCode)
          },
          dates: [new Date(cal.calendarDate)]
        })
    },
    getDisplay (code) {
      let appliedFilters = []
      if (this.onlyBirthdays) {
        appliedFilters.push(2)
      }
      if (this.onlyPrivateMarketsMR) {
        appliedFilters.push(3)
        appliedFilters.push(4)
      }
      if (this.onlyMarketableAltsMR) {
        appliedFilters.push(13)
        appliedFilters.push(14)
      }
      if (this.onlyOthers) {
        appliedFilters.push(0)
        appliedFilters.push(1)
        appliedFilters.push(5)
        appliedFilters.push(6)
        appliedFilters.push(7)
      }
      if (appliedFilters.length > 0) {
        return (appliedFilters.indexOf(code) > -1) ? 'inline-block' : 'none'
      }
      return 'none'
    },
    getColor (code) {
      let c = null
      switch (code) {
        case 0:
          c = 'orange'
          break
        case 1:
          c = 'pink'
          break
        case 2:
          c = '#F9E5BC'
          break
        case 3:
        case 13:
          c = '#0071c1'
          break
        case 4:
        case 14:
          c = '#5aa762'
          break
        case 5:
          c = 'yellow'
          break
        case 6:
          c = '#7d4199'
          break
        case 7:
          c = '#ff2400'
          break
      }
      return c
    },
    getColorClass (code) {
      let c = null
      switch (code) {
        case 0:
          c = 'btn-warning'
          break
        case 1:
          c = 'calendar-holiday'
          break
        case 2:
          c = 'calendar-birthday'
          break
        case 3:
          c = 'stage3'
          break
        case 4:
          c = 'stage5'
          break
        case 5:
          c = 'calendar-annual-meeting'
          break
        case 6:
          c = 'calendar-onsite-meeting'
          break
        case 7:
          c = 'calendar-deadline'
          break
      }
      return c
    },
    getTitle (c) {
      let t = null
      switch (c.calendarCode) {
        case 1:
          t = c.calendarLabel
          break
        case 2:
          t = c.calendarTitle + '`s birthday'
          break
        case 3:
          t = 'FLR - ' + c.calendarTitle
          break
        case 4:
          t = 'RR - ' + c.calendarTitle
          break
        case 5:
          t = 'Annual Meeting - ' + c.calendarTitle
          break
        case 6:
          t = 'Onsite Meeting - ' + c.calendarTitle
          break
        case 7:
          t = 'Deadline - ' + c.calendarTitle
          break
      }
      return t
    },
    getFontColor (code) {
      let c = 'white'
      switch (code) {
        case 1:
        case 2:
        case 5:
          c = 'black'
          break
      }
      return c
    }
  }
}
</script>