<template>
  <div>
    <Topbar/>
    <Sidebar :activeItem="'legal-add'"/>
    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid">
          <PageTitle :title="'Legal Workflow'" :icon="'mdi mdi-scale-balance'" />
          <div class="card-box">
            <h4 class="header-title m-t-0">Submit an Advisory/Consulting Agreements request</h4>
            <p class="text-muted font-14 m-b-20">Please fill the form.</p>

            <form action="#" autocomplete="off" @submit.prevent="save">
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label>Requestor</label>
                    <input type="text" v-model="user.fullName" disabled class="form-control">
                  </div>

                  <div class="form-group">
                    <label for="deadline">
                      Deadline
                      <span class="text-danger">*</span>
                    </label>
                    <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                    format="MM/dd/yyyy" :bootstrap-styling="true" :disabledDates="{to: endDate}" v-model="legalRequest.deadline" @blur="$v.legalRequest.deadline.$touch()" @input="setPickerDate" :input-class="{ 'form-control parsley-error': $v.legalRequest.deadline.$error }"></date-picker>
                    <template v-if="$v.legalRequest.deadline.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.deadline.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="CounterpartyId" class="col-form-label">
                      Firm <span class="text-danger">*</span>
                    </label>
                    <span :class="{ 'invalid': $v.legalRequest.counterpartyId.$error }">
                      <multiselect id="CounterpartyId" v-model="firmObj" :options="searchFirms" label="firmName" track-by="firmId" :allow-empty="false" placeholder="Type to Search"
                        @select="firmSelected" @search-change="asyncSearchFirm" :loading="isSearchingList" :options-limit="50"></multiselect>
                    </span>
                    <template v-if="$v.legalRequest.counterpartyId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.counterpartyId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      Is this a current client?<span class="text-danger">*</span>
                      <toggle-button v-model="legalRequest.isCoinvestment" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                    </label>
                  </div>

                  <div class="form-group">
                    <label for="purpose" class="col-form-label">
                      What is the purpose of this request?
                      <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="purpose" v-model="legalRequest.purpose" @blur="$v.legalRequest.purpose.$touch()" :class="{ 'parsley-error': $v.legalRequest.purpose.$error }">
                    <template v-if="$v.legalRequest.purpose.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.purpose.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.purpose.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>                  

                  <div class="form-group">
                    <label for="other" class="col-form-label">
                      Any other relevant information
                    </label>
                    <textarea class="form-control" rows="5" id="other" v-model="legalRequest.comments" @blur="$v.legalRequest.comments.$touch()" :class="{ 'parsley-error': $v.legalRequest.comments.$error }"></textarea>
                    <template v-if="$v.legalRequest.comments.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.comments.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                      <div class="checkbox checkbox-primary">
                          <input id="checkbox6a" type="checkbox" v-model="addMore">
                          <label for="checkbox6a">
                              Add Another
                          </label>
                      </div>
                  </div>

                  <div class="form-group text-right m-b-0">
                    <router-link :to="{name: 'LegalNew'}">
                        <button type="button" class="btn btn-secondary waves-effect mr-2">
                            Back
                        </button>
                    </router-link>

                    <button v-if="user.groupId < 11" class="btn btn-primary waves-effect waves-light" type="submit">Submit Request</button>
                  </div>

                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import {mapActions, mapGetters} from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import { LegalAcaService } from '@/services/legal-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
    DatePicker,
    Multiselect
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      legalRequest: {},
      endDate: null,
      firmObj: null,
      searchFirms: [],
      isSearchingList: false,
      addMore: false
    }
  },
  validations: {
    legalRequest: {
      deadline: { required },
      counterpartyId: { required },
      purpose: { required, max: maxLength(100) },
      comments: { max: maxLength(500) }
    }
  },
  methods: {
    ...mapActions(['searchAllFirms']),
    firmSelected (value, id) {
      this.legalRequest.counterpartyId = value ? value.firmId : null
      this.$v.legalRequest.counterpartyId.$touch()
      this.$v.legalRequest.counterpartyId.$reset()
    },
    asyncSearchFirm (query) {
      this.isSearchingList = true
      this.searchAllFirms({query}).then((result) => {
        this.searchFirms = result
        this.isSearchingList = false
      })
    },
    reset () {
      this.legalRequest = {
        deadline: null,
        isCoinvestment: null,
        counterpartyId: null,
        counterpartyType: null,
        relationship: null,
        disclosing: null,
        purpose: null,
        confidentialInfo: null,
        comments: null
      }

      this.firmObj = null
      this.$v.$reset()
    },
    save () {
      this.$v.legalRequest.$touch()
      if (this.$v.legalRequest.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      this.legalRequest.requestorId = this.user.userId

      LegalAcaService.add(this.legalRequest)
      .then(
        (response) => {
          this.$snotify.success('Advisory/Consulting Agreements Request Submitted.')
          if (!this.addMore) {
            setTimeout(() => {
              // this.$router.push({name: 'LegalList'})
              this.$router.push({name: 'LegalAca', params: {id: response.data.requestId}})
            }, 400)
          } else {
            this.reset()
          }
        })
        .catch(function (error) {
          console.error('SERVER Error:', error)
          // this.$snotify.error('SERVER Errors.' + error)
        })
    },
    setPickerDate (value) {
      if (value != null) {
        this.legalRequest.deadline = new Date(value).toISOString()
      } else {
        this.legalRequest.deadline = null
      }
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  },
  created () {
    this.endDate = new Date(Date.now())
    this.asyncDataStatus_fetched('Submit a Legal Workflow request')
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
