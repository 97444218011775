<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a class="nav-link active show">
              Similar Names
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
        <ol>
          <li v-for="(item, index) in list" :key="index">{{item.fundName}}</li>
        </ol>
      </div>

      <div class="form-group">
          <div class="checkbox checkbox-primary">
              <input id="checkboxcmf" type="checkbox" v-model="isConfirm">
              <label for="checkboxcmf">
                  None of the above
              </label>
          </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button class="btn btn-success waves-effect w-md waves-light" :disabled="!isConfirm" @click.prevent="confirm"><i class="mdi mdi-check-circle-outline m-r-5"></i> Confirm</button>
      </div>
    </form>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'

export default {
  props: {
    list: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      isConfirm: false
    }
  },
  mixins: [rightbar],
  methods: {
    confirm () {
      this.hideRightbar()
      this.isConfirm = false
      this.$emit('onConfirm')
    },
    close () {
      this.hideRightbar()
      this.isConfirm = false
    }
  }
}
</script>