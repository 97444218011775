<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'legal-status'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Legal Workflow'" :icon="'mdi mdi-scale-balance'" />
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box table-responsive">
                                <h4 class="m-t-0 header-title mb-4">Requests Status Update</h4>                                
                                <table class="table table-bordered table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)"> Item
                                          <!-- <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(5)"> Category
                                          <!-- <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(1)"> Status
                                          <!-- <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(4)"> Deadline
                                          <i class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <!-- <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                    </tr>
                                    </thead>
                         
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="item in legalRequests" :key="item.requestId">
                                          <td>
                                              <router-link :to="{path: item.detailFormUrl + '/' + item.requestId}">
                                                  {{item.category2}}
                                              </router-link>
                                          </td>
                                          <td> {{item.categoryName}} </td>
                                          <td> 
                                            {{item.statusName}} <button v-if="isLegal" class="btn btn-sm btn-icon waves-effect waves-light btn-primary" @click.prevent="addnewStatusUpdate(item.requestId)"><i class="mdi mdi-comment-plus-outline"></i></button>
                                            <ul>
                                              <li v-for="updates in getUpdates(item.requestId)" :key="updates.statusUpdateId">
                                                {{updates.comments}}
                                              </li>
                                            </ul>
                                          </td>
                                          <td> <AppDate :date="item.deadline" :show="'text'" /> </td>
                                      </tr>
                                    </tbody>
                                </table>
                                <div>
                                  {{legalRequests.length}} Requests
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end container -->
            </div>
            <!-- end content -->
            <div class="side-bar right-bar">
                <div class="col-sm-12">
                  <StatusUpdate @onUpdate="onStatusUpdate" v-if="rightbarItem == 'statusupdate'" :id="selectedId" />
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import { LegalService } from '@/services/legal-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import StatusUpdate from '@/components/legal/StatusUpdate'
import rightbar from '@/mixins/rightbar'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    StatusUpdate
  },
  data () {
    return {
      legalRequests: [],
      statusUpdates: null,
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      sortBy: ['CategoryName', 'StatusName', 'RequestorFullName', 'CreatedOn', 'Deadline', 'Category2'],
      sortIndex: 4,
      sortType: 'ASC',
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0,
      statusFilter: [],
      onlyMine: false,
      userObj: null,
      isLegal: null,
      rightbarItem: null,
      selectedId: 0
    }
  },
  mixins: [asyncDataStatus, rightbar],
  methods: {
    ...mapActions(['fetchAllLegalRequestStatuses', 'fetchAllUsers']),
    getList () {
      this.isLoading = true

      LegalService.getStatusUpdateList()
      .then((response) => {
        this.isLoading = false
        this.legalRequests = response.data.requests
        this.statusUpdates = response.data.statusUpdates
      })
      .then(() => { this.asyncDataStatus_fetched('Legal Workflow Status Update') })
    },
    setOrder (id) {
    },
    getUpdates (id) {
      return this.statusUpdates.filter(x => x.requestId === id)
    },
    addnewStatusUpdate (id) {
      this.showRightbar()
      this.selectedId = id
      this.rightbarItem = 'statusupdate'
    },
    onStatusUpdate (newStatus) {
      this.hideRightbar()
      const addStatus = { requestId: this.selectedId, comments: newStatus.comments }
      this.statusUpdates.push(addStatus)
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  },
  created () {
    this.getList()
    this.isLegal = this.user.roles.filter(x => x.roleId === this.$userRoles.LEGAL_TEAM_WORKFLOW || x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0
  }
}
</script>
