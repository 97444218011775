<template>
    <div>
        <label for="fund.Employee">Assign to</label>
            <span :class="{ 'invalid': assignError }">
                <multiselect id="fund.Employee" v-model="userObj" :options="legalUsers" label="fullName" track-by="userId" :allow-empty="true"></multiselect>
            </span>
            <template v-if="assignError">
              <ul class="parsley-errors-list filled">
                <li class="parsley-required">Select a valid user</li>
              </ul>
            </template>
            <button class="btn btn-icon waves-effect waves-light btn-primary mt-2" @click.prevent="onAssign"><i class="mdi mdi-account-plus"></i> Submit</button>
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect'
import { LegalService } from '@/services/legal-service'

export default {
  components: {
    Multiselect
  },
  props: {
    assignedTo: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      userObj: null,
      assignError: false
    }
  },
  methods: {
    onAssign () {
      if (this.userObj == null || this.userObj.userId === this.assignedTo) {
        this.assignError = true
        return
      } else {
        this.assignError = false
      }
      let req = {...this.legalRequest}
      req.assignedTo = this.userObj.userId
      LegalService.updateAssign(this.id, req)
      .then(
        () => {
          this.onAssignTo(this.userObj.userId)
          this.userObj = null
          this.$snotify.success('Assignee Updated')
        })
        .catch(function (error) { console.error('SERVER Error:', error) })
    },
    onAssignTo (user) {
      this.$emit('onAssignTo', user)
    }
  },
  computed: {
    legalUsers () {
      return this.$store.state.allUsers.filter(x => x.groupId === 2 && x.isActive && x.userId > 0)
    }
  }
}
</script>
