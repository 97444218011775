<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
            Annual Meeting
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="meetingDate">Date<span v-show="isEditMode" class="text-danger">*</span></label>
          <date-picker v-model="annualMeeting.meetingDate" @blur="$v.annualMeeting.meetingDate.$touch()" :disabled="!isEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.annualMeeting.meetingDate.$error }"></date-picker>
          <template v-if="$v.annualMeeting.meetingDate.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.annualMeeting.meetingDate.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>
      <div class="form-group">
          <label for="city">City<span v-show="isEditMode" class="text-danger">*</span></label>
          <input type="text" v-model="annualMeeting.city" @blur="$v.annualMeeting.city.$touch()" :disabled="!isEditMode" placeholder="Enter City" class="form-control" id="city" :class="{ 'parsley-error': $v.annualMeeting.city.$error }">
          <template v-if="$v.annualMeeting.city.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.annualMeeting.city.required" class="parsley-required">This value is required</li>
              <li v-if="!$v.annualMeeting.city.max" class="parsley-required">This value is too long</li>
            </ul>
          </template>
      </div>
      <div class="form-group">
          <label for="">Attendance<span v-show="isEditMode" class="text-danger">*</span></label>
          <multiselect v-show="!isEditMode" :disabled="!isEditMode" v-model="attendanceObj" :options="allAnnualMeetingAttendances" label="attendanceName" track-by="attendanceId"></multiselect>
          <span :class="{ 'invalid': $v.annualMeeting.attendanceId.$error }">
            <multiselect id="" v-show="isEditMode" :disabled="!isEditMode" v-model="attendanceObj2" :options="allAnnualMeetingAttendances" label="attendanceName" track-by="attendanceId" :allow-empty="false" @input="attendanceSelected"></multiselect>
          </span>
          <template v-if="$v.annualMeeting.attendanceId.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.annualMeeting.attendanceId.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>
      <div class="form-group">
          <label for="">Employee</label>
          <multiselect v-show="!isEditMode" :disabled="!isEditMode" v-model="userObj" :options="allActiveUsers" label="fullName" track-by="userId"></multiselect>
          <multiselect id="" v-show="isEditMode" :disabled="!isEditMode" v-model="userObj2" :options="allActiveUsers" label="fullName" track-by="userId" :allow-empty="true" @input="userSelected"></multiselect>
      </div>
      <div class="form-group">
        <label for="comment">Notes</label>
        <textarea type="text" v-model="annualMeeting.comment" :disabled="!isEditMode" class="form-control" id="inactiveNotes" rows="3" placeholder="Enter Notes" @blur="$v.annualMeeting.comment.$touch()" :class="{ 'parsley-error': $v.annualMeeting.comment.$error }"/>
        <template v-if="$v.annualMeeting.comment.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.annualMeeting.comment.max" class="parsley-required">This value is too long</li>
          </ul>
        </template>
      </div>

      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="annualMeeting.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="annualMeeting.createdBy" />
          </div>
          <div class="form-group" v-if="annualMeeting.updatedOn">
              <label>Modified On</label>
              <AppDate :date="annualMeeting.updatedOn" :show="'input'"/>
          </div>
          <div class="form-group" v-if="annualMeeting.updatedOn">
              <label>Modified By</label>
              <AppUser :userId="annualMeeting.updatedBy" :emptyIfNull="true"/>
          </div>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import { required, maxLength, minValue } from 'vuelidate/lib/validators'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect,
    DatePicker
  },
  data () {
    return {
      showLogs: false,
      userObj2: null,
      attendanceObj2: null
    }
  },
  validations: {
    annualMeeting: {
      meetingDate: { required },
      city: { required, max: maxLength(100) },
      attendanceId: { required, min: minValue(1) },
      comment: { max: maxLength(500) }
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['fetchAllAnnualMeetingAttendances', 'addFirmAnnualMeetings', 'updateFirmAnnualMeetings']),
    close () {
      this.hideRightbar()
    },
    userSelected (value, id) {
      this.annualMeeting.attendeeId = value ? value.userId : null
    },
    attendanceSelected (value, id) {
      this.$v.annualMeeting.attendanceId.$touch()
      this.annualMeeting.attendanceId = value ? value.attendanceId : null
    },
    onSave () {
      this.$v.annualMeeting.$touch()
      if (this.$v.annualMeeting.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }

      if (this.isAdd) {
        this.addFirmAnnualMeetings({firmAnnualMeeting: this.annualMeeting})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Annual Meeting Added.')
              this.$emit('onUpdate')
            }
          })
      } else {
        this.updateFirmAnnualMeetings({meetingId: this.annualMeeting.meetingId, firmAnnualMeeting: this.annualMeeting})
          .then((response) => {
            this.$snotify.success('Annual Meeting Updated.')
            this.$emit('onUpdate')
          })
      }
    }
  },
  created () {
    this.fetchAllAnnualMeetingAttendances()
  },
  computed: {
    annualMeeting () {
      return this.$store.state.rb_firmAnnualMeeting
    },
    allUsers () {
      return this.$store.state.allUsers
    },
    allActiveUsers () {
      return this.allUsers.filter(u => u.isActive || u.userId === this.annualMeeting.attendeeId)
    },
    allAnnualMeetingAttendances () {
      return this.$store.state.allAnnualMeetingAttendances
    },
    userObj () {
      if (this.annualMeeting != null) {
        const user = this.allUsers.filter(t => t.userId === this.annualMeeting.attendeeId)
        this.userObj2 = user
        return user
      } else {
        return null
      }
    },
    attendanceObj () {
      if (this.annualMeeting != null) {
        const att = this.allAnnualMeetingAttendances.filter(t => t.attendanceId === this.annualMeeting.attendanceId)
        this.attendanceObj2 = att
        return att
      } else {
        return null
      }
    },
    allTeamRoles () {
      return this.$store.state.allFundTeamRoles
    },
    roleObj () {
      if (this.annualMeeting != null) {
        return this.allTeamRoles.filter(t => t.fundTeamRoleId === this.annualMeeting.fundTeamRoleId)
      } else {
        return null
      }
    }
  }
}
</script>
