<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Portfolio Geography
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="portfolioGeo">
      <div class="form-group">
          <label for="geographicId">Geography<span class="text-danger" v-if="isAdd">*</span></label>
          <span :class="{ 'invalid': $v.portfolioGeo.geographicId.$error }" v-if="isAdd">
            <multiselect id="geographicId" v-model="geoObj" :options="availableList" label="geographicName" track-by="geographicId" :allow-empty="false" @input="geoSelected"></multiselect>
          </span>
          <div>
            <strong v-if="!isAdd">
            {{geoName}}
            </strong>
          </div>
          <template v-if="$v.portfolioGeo.geographicId.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.portfolioGeo.geographicId.required" class="parsley-required">This value is required</li>
              <li v-if="!$v.portfolioGeo.geographicId.min" class="parsley-required">This value is not valid</li>
            </ul>
          </template>
      </div>

      <div v-if="clientType === 2">
        <h4>Guideline</h4>
        <div class="row" >
          <div class="col-md-6">
            <div class="form-group">
                <label for="GuidelineMin">Min<span class="text-danger" v-if="isEditMode">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">%</span>
                    </div>
                    <input type="text" v-model.number="portfolioGeo.guidelineMin" class="form-control" :disabled="!isEditMode" id="GuidelineMin" @blur="$v.portfolioGeo.guidelineMin.$touch()" :class="{ 'parsley-error': $v.portfolioGeo.guidelineMin.$error }">
                </div>
                <template v-if="$v.portfolioGeo.guidelineMin.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="!$v.portfolioGeo.guidelineMin.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.portfolioGeo.guidelineMin.min" class="parsley-required">This value is too small</li>
                    <li v-if="!$v.portfolioGeo.guidelineMin.max" class="parsley-required">This value is too big</li>
                  </ul>
                </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
                <label for="GuidelineMax">Max<span class="text-danger" v-if="isEditMode">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">%</span>
                    </div>
                    <input type="text" v-model.number="portfolioGeo.guidelineMax" class="form-control" :disabled="!isEditMode" id="GuidelineMax" @blur="$v.portfolioGeo.guidelineMax.$touch()" :class="{ 'parsley-error': $v.portfolioGeo.guidelineMax.$error }">
                </div>
                <template v-if="$v.portfolioGeo.guidelineMax.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="!$v.portfolioGeo.guidelineMax.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.portfolioGeo.guidelineMax.min" class="parsley-required">This value is too small</li>
                    <li v-if="!$v.portfolioGeo.guidelineMax.max" class="parsley-required">This value is too big</li>
                  </ul>
                </template>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="CommitmentPercent">Commitment % <span v-show="isEditMode" class="text-danger">*</span></label>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">%</span>
            </div>
            <input type="text" v-model.number="portfolioGeo.commitmentPercent" @keyup="calcPositionPerc" :disabled="!isEditMode" @blur="$v.portfolioGeo.commitmentPercent.$touch()" placeholder="Enter Commitment %" class="form-control" id="CommitmentPercent" :class="{ 'parsley-error': $v.portfolioGeo.commitmentPercent.$error || isExceed }">
        </div>
        <template v-if="$v.portfolioGeo.commitmentPercent.$error || isExceed">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.portfolioGeo.commitmentPercent.required" class="parsley-required">This value is required</li>
            <li v-if="!$v.portfolioGeo.commitmentPercent.decimal && $v.portfolioGeo.commitmentPercent.required" class="parsley-required">This value is not valid</li>
            <li v-if="!$v.portfolioGeo.commitmentPercent.min" class="parsley-required">This value is too small</li>
            <li v-if="isExceed" class="parsley-required">Total commitment will exceed the max</li>
          </ul>
        </template>
      </div>

      <div class="form-group">
        <label for="Commitment">Commitment (M) <span v-show="isEditMode" class="text-danger">*</span></label>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">{{currency}}</span>
            </div>
            <input type="text" v-model.number="portfolioGeo.commitment" :disabled="!isEditMode" class="form-control" id="Commitment" readonly="readonly">
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect waves-light w-md" @click.prevent="onSave" :disabled="isLoading">
          <span v-if="isLoading">
            <span class="fa fa-spinner fa-spin mr-1" role="status"></span> Updating...
          </span>
          <span v-else><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</span>
        </button>
      </div>
    </form>
    <span v-else class="fa fa-spinner fa-spin mr-1" role="status">Loading...</span>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { required, requiredIf, decimal, minValue, maxValue, numeric } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    },
    currency: {
      required: true,
      type: String
    },
    totalCommitment: {
      required: true,
      type: Number
    },
    clientType: {
      required: true,
      type: Number
    },
    availableList: {
      required: true,
      type: Array
    },
    lastSum: {
      required: true,
      type: Number
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      portfolioGeoCopy: null,
      geoObj: null,
      isExceed: false,
      isLoading: false
    }
  },
  mixins: [rightbar],
  validations: {
    portfolioGeo: {
      geographicId: { required, min: minValue(1) },
      guidelineMin: { required: requiredIf(function () { return this.clientType === 2 }), numeric, min: minValue(0), max: maxValue(100) },
      guidelineMax: { required: requiredIf(function () { return this.clientType === 2 }), numeric, min: minValue(0), max: maxValue(100) },
      commitmentPercent: { required, decimal, min: minValue(0) }
    }
  },
  methods: {
    ...mapActions(['addPortfolioGeo', 'updatePortfolioGeo', 'checkBeforePortfolioUpdate']),
    close () {
      this.$v.portfolioGeo.$reset()
      this.hideRightbar()
      this.isExceed = false
    },
    onSave () {
      this.$v.portfolioGeo.$touch()
      if (!this.$v.portfolioGeo.$error) {
        this.isLoading = true
        this.isExceed = false
        const chkmodel = {portfolioId: this.portfolioGeo.portfolioId, type: 'geo', paramId: this.portfolioGeo.geographicId, newPercent: this.portfolioGeo.commitmentPercent}
        this.checkBeforePortfolioUpdate(chkmodel)
        .then((resonse) => {
          if (!resonse) {
            this.isExceed = true
            this.isLoading = false
          }
        })
        .then(() => {
          if (this.isExceed) {
            this.isLoading = false
            return
          }
          if (this.isAdd) {
            this.addPortfolioGeo({portfolioGeo: this.portfolioGeo})
              .then((response) => {
                if (response == null) {
                  this.$snotify.error('Server Error!')
                } else {
                  this.$snotify.success('Geograpgy Added.')
                  response.geographicName = this.geoObj.geographicName
                  this.$emit('onUpdate', response, 1)
                }
              })
          } else {
            this.updatePortfolioGeo({portfolioId: this.portfolioGeo.portfolioId, portfolioGeo: this.portfolioGeo})
              .then((response) => {
                this.$snotify.success('Geograpgy Updated.')
                this.portfolioGeo.geographicName = this.geoName
                this.$emit('onUpdate', this.portfolioGeo, 0)
              })
          }
        })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    geoSelected (value, id) {
      this.$v.portfolioGeo.geographicId.$touch()
      this.portfolioGeo.geographicId = value ? value.geographicId : null
    },
    calcPositionPerc () {
      const p = (this.portfolioGeo.commitmentPercent * this.totalCommitment) / 100
      this.portfolioGeo.commitment = p.toFixed(2)
    }
  },
  computed: {
    portfolioGeo () {
      return this.$store.state.rb_portfolioGeo
    },
    geoName () {
      const r = this.$store.state.allGeoFocus.filter(x => x.geographicId === this.portfolioGeo.geographicId)
      if (r.length > 0) {
        return r[0].geographicName
      }
      return null
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>
