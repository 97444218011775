<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Portfolio Investment Types
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="portfolioInv">
      <div class="form-group">
          <label for="geographicId">Avaliable Types<span class="text-danger" v-if="isAdd">*</span></label>
          <span :class="{ 'invalid': $v.portfolioInv.invTypeId.$error }" v-if="isAdd">
            <multiselect id="geographicId" v-model="typeObj" :options="availableList" label="typeName" track-by="typeId" :allow-empty="false" @input="typeSelected"></multiselect>
          </span>
          <div>
            <strong v-if="!isAdd">
            {{invName}}
            </strong>
          </div>
          <template v-if="$v.portfolioInv.invTypeId.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.portfolioInv.invTypeId.required" class="parsley-required">This value is required</li>
              <li v-if="!$v.portfolioInv.invTypeId.min" class="parsley-required">This value is not valid</li>
            </ul>
          </template>
      </div>

      <div v-if="clientType === 2">
        <h4>Guideline</h4>
        <div class="row" >
          <div class="col-md-6">
            <div class="form-group">
                <label for="GuidelineMin">Min<span class="text-danger" v-if="isEditMode">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">%</span>
                    </div>
                    <input type="text" v-model.number="portfolioInv.guidelineMin" class="form-control" :disabled="!isEditMode" id="GuidelineMin" @blur="$v.portfolioInv.guidelineMin.$touch()" :class="{ 'parsley-error': $v.portfolioInv.guidelineMin.$error }">
                </div>
                <template v-if="$v.portfolioInv.guidelineMin.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="!$v.portfolioInv.guidelineMin.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.portfolioInv.guidelineMin.min" class="parsley-required">This value is too small</li>
                    <li v-if="!$v.portfolioInv.guidelineMin.max" class="parsley-required">This value is too big</li>
                  </ul>
                </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
                <label for="GuidelineMax">Max<span class="text-danger" v-if="isEditMode">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">%</span>
                    </div>
                    <input type="text" v-model.number="portfolioInv.guidelineMax" class="form-control" :disabled="!isEditMode" id="GuidelineMax" @blur="$v.portfolioInv.guidelineMax.$touch()" :class="{ 'parsley-error': $v.portfolioInv.guidelineMax.$error }">
                </div>
                <template v-if="$v.portfolioInv.guidelineMax.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="!$v.portfolioInv.guidelineMax.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.portfolioInv.guidelineMax.min" class="parsley-required">This value is too small</li>
                    <li v-if="!$v.portfolioInv.guidelineMax.max" class="parsley-required">This value is too big</li>
                  </ul>
                </template>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="CommitmentPercent">Commitment % <span v-show="isEditMode" class="text-danger">*</span></label>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">%</span>
            </div>
            <input type="text" v-model.number="portfolioInv.commitmentPercent" @keyup="calcPositionPerc" :disabled="!isEditMode" @blur="$v.portfolioInv.commitmentPercent.$touch()" placeholder="Enter Commitment %" class="form-control" id="CommitmentPercent" :class="{ 'parsley-error': $v.portfolioInv.commitmentPercent.$error || isExceed }">
        </div>
        <template v-if="$v.portfolioInv.commitmentPercent.$error || isExceed">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.portfolioInv.commitmentPercent.required" class="parsley-required">This value is required</li>
            <li v-if="!$v.portfolioInv.commitmentPercent.decimal && $v.portfolioInv.commitmentPercent.required" class="parsley-required">This value is not valid</li>
            <li v-if="!$v.portfolioInv.commitmentPercent.min" class="parsley-required">This value is too small</li>
            <li v-if="isExceed" class="parsley-required">Total commitment will exceed the max</li>
          </ul>
        </template>
      </div>

      <div class="form-group">
        <label for="Commitment">Commitment (M) <span v-show="isEditMode" class="text-danger">*</span></label>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">{{currency}}</span>
            </div>
            <input type="text" v-model.number="portfolioInv.commitment" :disabled="!isEditMode" class="form-control" id="Commitment" readonly="readonly">
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect waves-light w-md" @click.prevent="onSave" :disabled="isLoading">
          <span v-if="isLoading">
            <span class="fa fa-spinner fa-spin mr-1" role="status"></span> Updating...
          </span>
          <span v-else><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</span>
        </button>
      </div>
    </form>
    <span v-else class="fa fa-spinner fa-spin mr-1" role="status">Loading...</span>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { required, requiredIf, decimal, minValue, maxValue, numeric } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    },
    currency: {
      required: true,
      type: String
    },
    totalCommitment: {
      required: true,
      type: Number
    },
    clientType: {
      required: true,
      type: Number
    },
    availableList: {
      required: true,
      type: Array
    },
    lastSum: {
      required: true,
      type: Number
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      typeObj: null,
      isExceed: false,
      isLoading: false
    }
  },
  mixins: [rightbar],
  validations: {
    portfolioInv: {
      invTypeId: { required, min: minValue(1) },
      guidelineMin: { required: requiredIf(function () { return this.clientType === 2 }), numeric, min: minValue(0), max: maxValue(100) },
      guidelineMax: { required: requiredIf(function () { return this.clientType === 2 }), numeric, min: minValue(0), max: maxValue(100) },
      commitmentPercent: { required, decimal, min: minValue(0) }
    }
  },
  methods: {
    ...mapActions(['addPortfolioInv', 'updatePortfolioInv', 'checkBeforePortfolioUpdate']),
    close () {
      this.$v.portfolioInv.$reset()
      this.isExceed = false
      this.hideRightbar()
    },
    onSave () {
      this.$v.portfolioInv.$touch()
      if (!this.$v.portfolioInv.$error) {
        this.isLoading = true
        this.isExceed = false
        const chkmodel = {portfolioId: this.portfolioInv.portfolioId, type: 'inv', paramId: this.portfolioInv.invTypeId, newPercent: this.portfolioInv.commitmentPercent}
        this.checkBeforePortfolioUpdate(chkmodel)
        .then((resonse) => {
          if (!resonse) {
            this.isExceed = true
            this.isLoading = false
          }
        })
        .then(() => {
          if (this.isExceed) {
            this.isLoading = false
            return
          }
          if (this.isAdd) {
            this.addPortfolioInv({portfolioInv: this.portfolioInv})
              .then((response) => {
                if (response == null) {
                  this.$snotify.error('Server Error!')
                } else {
                  this.$snotify.success('Investment Type Added.')
                  this.$emit('onUpdate')
                }
              })
          } else {
            this.updatePortfolioInv({portfolioId: this.portfolioInv.portfolioId, portfolioInv: this.portfolioInv})
              .then((response) => {
                this.$snotify.success('Investment Type Updated.')
                this.$emit('onUpdate')
              })
          }
        })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    calcPositionPerc () {
      const p = (this.portfolioInv.commitmentPercent * this.totalCommitment) / 100
      this.portfolioInv.commitment = p.toFixed(2)
    },
    typeSelected (value, id) {
      this.$v.portfolioInv.invTypeId.$touch()
      this.portfolioInv.invTypeId = value ? value.typeId : null
    }
  },
  computed: {
    portfolioInv () {
      return this.$store.state.rb_portfolioInv
    },
    invName () {
      const r = this.$store.state.allFundtypes.filter(x => x.invTypeId === this.portfolioInv.invTypeId)
      if (r.length > 0) {
        return r[0].invTypeName
      }
      return null
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>
