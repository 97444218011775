<template>
    <div class="row">
        <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
            <div role="status">
                Page {{currentPage}} of {{totalPages}} - {{totalRecords | thousandNumber}} {{topic}}
            </div>
            <div class="px-3">
                <select class="form-control form-control-sm" v-model="size" @input="onPageResize">
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                </select>
            </div>
        </div>
        <div class="col-sm-12 col-md-5 col-lg-5">
            <div class="dataTables_paginate paging_simple_numbers">
                <paginate :page-count="totalPages"
                  v-model="page"
                  :click-handler="onPageChanged"
                  :prev-text="'Previous'"
                  :next-text="'Next'"
                  :container-class="'pagination'"
                  :page-class="'paginate_button page-item '"
                  :prev-class="'paginate_button page-item previous mr-2'"
                  :next-class="'paginate_button page-item next'">
                </paginate>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      page: 1,
      size: 25
    }
  },
  props: {
    pageSize: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    totalRecords: {
      type: Number,
      required: true
    },
    topic: {
      type: String,
      required: true
    }
  },
  methods: {
    onPageResize () {
      setTimeout(() => {
        this.$emit('onPageResize', parseInt(this.size))
      }, 250)
    },
    onPageChanged () {
      this.$emit('onPageChanged', this.page)
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>