<template>
  <div class='overflow-auto'>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Risk Score
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
        <div class="form-group">
            <label class='form-label' for="fundId">Deal <span class="text-danger">*</span></label>
            <span :class="{ 'invalid': $v.dealObj.$error }"> 
              <multiselect @open='$v.dealObj.$touch()' id="fundId" v-model="dealObj" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search"
                @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
            </span>
            <template v-if="$v.dealObj.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.dealObj.required" class="parsley-required">This value is required</li>
              </ul>
            </template>
        </div>
        <div class="form-group">
          <label for="size" class="form-label"
            >Size<span class="text-danger"> *</span>
          </label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ cSymbol }}</span>
              </div>
              <input type="text" class="form-control" id="size" :class="{ 'parsley-error': $v.size.$error }" placeholder="Your Answer" v-model.number="$v.size.$model"/>
            </div>
          <template v-if="$v.size.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.size.required" class="parsley-required">This value is required</li>
              <li v-if="!$v.size.decimal" class="parsley-required">Please enter a number</li>
            </ul>
          </template>
          <button class='btn btn-secondary mt-3 mr-2' @click.prevent='close'><i class="mdi mdi-close-circle-outline"></i> Close</button>
          <button v-if='isLoading' :disabled='true' class='btn btn-primary mt-3 pull-right mr-2'><i class="fa fa-spinner fa-spin"></i> Calculate</button>
          <button v-else @click.prevent='onCalc' :disabled='disableButton' class='btn btn-primary mt-3 pull-right'><i class="mdi mdi-calculator"></i> Calculate</button>
        </div>
        <div v-if='riskChart.length > 0'>
            <table class='table'>
                <tbody>
                    <tr v-for='(row, idx) in riskChart.filter(item => item.titleName.length > 0)' :key='idx'>
                        <th scope='row'>{{row.titleName}}</th>
                        <td>{{row.title}}</td>
                        <td>{{row.score}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="input-group mt-3" v-if="isMatch">
                <div class="input-group-prepend">
                    <span class="input-group-text">Score</span>
                </div> 
                <input type="text" readonly="readonly" class="form-control" :class="finalScoreBg" :value="finalScore.toFixed(2)">
            </div>
            <p v-else class="form-control notmach">One or more Deal parameters are outside of this Portfolio's guidelines</p>
            <p v-if="isMatch">1.0 = Good<br/> -1.0 = Bad</p>
        </div>
    </form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import { required, decimal } from 'vuelidate/lib/validators'
import { PortfolioService } from '@/services/portfolio-service'
import { BrowseService } from '@/services/user-service'

export default {
  components: {
    Multiselect
  },
  props: {
    portfolioId: {
      type: Number,
      required: true
    },
    cSymbol: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dealObj: null,
      isSearchingList: false,
      searchFunds: [],
      size: null,
      riskChart: [],
      isLoading: false,
      isMatch: false
    }
  },
  validations: {
    dealObj: { required },
    size: { required, decimal }
  },
  methods: {
    ...mapActions(['searchAllFunds']),
    close () {
      this.$emit('close')
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    },
    onCalc () {
      this.isLoading = true
      this.isMatch = true
      this.riskChart = []
      PortfolioService.calculateRisk({portfolioId: this.portfolioId, fundId: this.dealObj.fundId, size: this.size})
      .then(
        (response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error!')
          } else {
            this.riskChart = response.data
            if (this.riskChart.length < 4) {
              this.isMatch = false
            }
            this.isLoading = false
            const logString = 'Risk: P_' + this.portfolioId + '-F_' + this.dealObj.fundId + '-S_' + this.size + '-R=' + this.finalScore.toFixed(2)
            BrowseService.addLog({urlFromName: 'Portfolio', urlToName: logString})
          }
        })
        .catch(error => this.$snotify.error('Server Error! ' + error.message))
    }
  },
  computed: {
    allDeals () {
      return this.$store.state.allFundList
    },
    disableButton () {
      return this.dealObj === null || this.size === null || this.size === ''
    },
    finalScore () {
      return this.riskChart.filter(x => x.titleName.length === 0)[0].score
    },
    finalScoreBg () {
      return this.finalScore >= 0 ? 'positive-score' : 'negetive-score'
    }
  }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scope>
.positive-score {
  background-color: green !important;
}
.negetive-score {
  background-color: red !important;
}
.notmach {
  background-color: orange !important;
}
</style>
