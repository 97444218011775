<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'funds-pipe'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Deals'" :icon="'mdi mdi-square-inc-cash'" />
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box table-responsive">
                                <h4 class="header-title">Deals pipeline</h4>
                                <div class="row mb-3" style="gap: 1rem">
                                  <div class="col" style="flex-grow: 0">
                                    <div class="dt-buttons btn-group">
                                      <a class="btn btn-secondary buttons-html5" href="javascript:void(0)" @click="filterSearchGeo(0)" :class="{'btn-primary' : geoId == 0}">
                                        <span>All</span>
                                      </a>
                                      <a class="btn btn-secondary buttons-html5" href="javascript:void(0)" v-for="geo in allGeoFocuses" :key="geo.geographicId" 
                                      @click="filterSearchGeo(geo.geographicId)" :class="{'btn-primary' : geoId == geo.geographicId}">
                                        <span>{{geo.geographicName}}</span>
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="flex-grow: 0">
                                    <div class="dt-buttons btn-group">
                                      <a class="btn btn-secondary buttons-html5" href="javascript:void(0)" @click="filterSearchType(0)" :class="{'btn-primary' : typId == 0}">
                                        <span>All</span>
                                      </a>
                                      <a class="btn btn-secondary buttons-html5" href="javascript:void(0)" v-for="typ in allTypes" :key="typ.typeId" 
                                      @click="filterSearchType(typ.typeId)" :class="{'btn-primary' : typId == typ.typeId}">
                                        <span>{{typ.typeName}}</span>
                                      </a>
                                    </div>
                                  </div>
                                  <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                </div>
                                <table class="table table-striped table-bordered table-hover no-footer">
                                    <thead>
                                    <tr role="row">
                                        <th></th>
                                        <th> Name
                                        </th>
                                        <th> Geographic Focus
                                        </th>
                                        <th> Stage
                                        </th>
                                        <th> Type
                                        </th>
                                        <th> Sector
                                        </th>
                                        <th> Target Size (M)
                                        </th>
                                        <th> Expected Close Date
                                        </th>
                                    </tr>
                                    </thead>
                            
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                       <tr role="row" v-for="fund in filtertedFunds" :key="fund.fundId">
                                         <td>
                                          <div class="checkbox checkbox-primary checkbox-single">
                                              <input :id="'checkboxap'+fund.fundId" type="checkbox">
                                              <label :for="'checkboxap'+fund.fundId"></label>
                                          </div>
                                         </td>
                                           <td>
                                               <router-link :to="{name: 'FundsDetail', params: {id: fund.fundId}}">
                                                   {{ fund.fundName }}
                                               </router-link>
                                           </td>
                                           <td>{{ fund.geographicName }}</td>
                                           <td :class="stageClass(fund.stageId)">{{ fund.stageName }}</td>
                                           <td>{{ fund.typeName }}</td>
                                           <td>{{ fund.sectorName }}</td>
                                           <td>{{ fund.currencySymbol }}{{ fund.targetSize | thousandNumber }}</td>
                                           <td> <AppDate :date="fund.expectedCloseDate != null ? fund.expectedCloseDate : ''" :show="'text'" /> </td>
                                       </tr>
                                    </tbody>
                                </table>
                   
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div class="" role="status">Page {{currentPage}} of {{totalPages}} - {{totalRecords | thousandNumber}} deals<br/><strong v-if="hasFilter" class="text-primary">Filter Count: {{filtertedFunds.length}}</strong></div>
                                    <div class="px-3">
                                      <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                          <option value="25">25</option>
                                          <option value="50">50</option>
                                          <option value="100">100</option>
                                          <option value="250">250</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end container -->
        </div>
        <!-- end content -->

        <Footer />
    </div>
</template>

<script>
import { FundService } from '@/services/fund-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import {mapActions} from 'vuex'

export default {
  data () {
    return {
      geoId: 0,
      typId: 0,
      funds: [],
      pageSize: 250,
      currentPage: 1,
      isLoading: false,
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    ...mapActions(['fetchAllGeoFocus', 'fetchAllFundtypes']),
    getList () {
      this.isLoading = true
      FundService.pipeline(this.currentPage, this.pageSize)
      .then((response) => {
        this.isLoading = false
        this.funds = response.data.funds
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Deal Pipeline') })
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    filterSearchGeo (geoId) {
      this.geoId = geoId
    },
    filterSearchType (id) {
      this.typId = id
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.funds = []
      if (this.searchName == null) {
        this.searchName = ''
      }

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    stageClass (stageId) {
      if (stageId >= 3) {
        return 'text-white stage' + stageId
      }
    },
    resetFilter () {
      this.geoId = 0
      this.typId = 0
    }
  },
  computed: {
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    allTypes () {
      return this.$store.state.allFundtypes
    },
    filtertedFunds () {
      if (this.geoId === 0 && this.typId === 0) {
        return this.funds
      }
      let flist = this.funds
      if (this.geoId > 0) {
        flist = flist.filter(x => x.geographicFocusId === this.geoId)
      }
      if (this.typId > 0) {
        flist = flist.filter(x => x.fundTypeId === this.typId)
      }
      return flist
    },
    hasFilter () {
      return this.geoId > 0 || this.typId > 0
    }
  },
  mounted () {
    this.fetchAllGeoFocus()
    this.fetchAllFundtypes()
    this.startSearch()
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>
