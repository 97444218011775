<template>
<div>
  <ul class="nav nav-tabs tabs-bordered nav-justified">
    <li class="nav-item">
        <a class="nav-link show" :class="{'active': tabId == 1}" @click="tabId = 1" href="javascript:void(0)">
          Deal Team <span class="badge badge-primary noti-icon-badge" v-if="members">{{members.length}}</span>
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link show" :class="{'active': tabId == 2}" @click="tabId = 2" href="javascript:void(0)">
          Allocations <span class="badge badge-primary noti-icon-badge" v-if="vehicleList">{{vehicleList.length}}</span>
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link show" :class="{'active': tabId == 3}" @click="tabId = 3" href="javascript:void(0)">
          Deal Documents <span class="badge badge-primary noti-icon-badge" v-if="fundFiles">{{fundFiles.length}}</span>
        </a>
    </li>
  </ul>
  <table class="table table-striped table-hover" v-show="tabId == 1">
    <thead>
      <tr>
        <th>Employee</th>
        <th>Role</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="members === null">
        <td colspan="2" class="text-center">
            <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
            <span v-else>No Member to show</span>
        </td>
      </tr>
      <tr v-for="member in members" :key="member.fundTeamId">
        <td>
          <a href="javascript:void(0)">
            <AppUserPic :userId="member.userId" :size="2" />
            {{member.userName}}
          </a>
        </td>
        <td>{{member.fundTeamRoleName}}</td>
      </tr>
    </tbody>
  </table>
  <table class="table table-striped table-hover" v-show="tabId == 2">
    <thead>
      <tr>
        <th>Name</th>
        <th>Amount</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="vehicleList === null">
        <td colspan="3" class="text-center">
            <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
            <span v-else>No Vehicle to show</span>
        </td>
      </tr>
      <tr v-for="vehicle in vehicleList" :key="vehicle.allocationVehicleId">
        <td>
            <span class="mr-4">{{vehicle.portfolioName}}</span>
            <span class="badge badge-pill px-2" :class="{'badge-secondary' : !isActive(vehicle), 'badge-success' : isActive(vehicle)}">{{isActive(vehicle) ? 'Active' : 'Inactive'}}</span>
        </td>
        <td>
          {{vehicle.currency}}{{vehicle.amount}}
        </td>
        <td>
          <span :class="getApprovalClass(vehicle)"> {{isApproved(vehicle)}}</span>
        </td>
      </tr>
      <tr v-if="vehicleList.length > 0">
        <td colspan="3" class="text-center"><em>Allocation Page  </em> 
          <router-link :to="{name: 'Allocation', params: {id: fundId}}" target="_blank" >
            <i class="mdi mdi-open-in-new"></i>
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
  <FileList :files="fundFiles" :isEditMode="true" :canEdit="true"  @onClickNew="clickAddFile"  v-show="tabId == 3" />
</div>
</template>

<script>
import { FundTeamService } from '@/services/fund-service'
import { AllocationService } from '@/services/allocation-service'
import FileList from '@/components/FileList'
import rightbar from '@/mixins/rightbar'

export default {
  props: {
    fundId: {
      required: true,
      type: Number
    },
    fundFiles: {
      required: true,
      type: Array
    }
  },
  components: {
    FileList
  },
  data () {
    return {
      isLoading: true,
      tabId: 1,
      members: null,      
      allocation: null,
      vehicleList: null
    }
  },
  mixins: [rightbar],
  methods: {
    clickAddFile () {
      this.$store.commit('setNewFile', this.fundId)
      this.showRightbar()
    },
    isActive (v) {
      return v.versionNo === this.getMaxVersion
    },
    isApproved (v) {
      if (!v.isFinalized) return 'In Process'
      else return v.isApproved ? 'Approved' : 'Declined'
    },
    getApprovalClass (v) {
      if (!this.isActive(v)) return ''
      else {
        const status = this.isApproved(v)
        return status === 'Approved' ? 'text-success' : status === 'Declined' ? 'text-danger' : ''
      }
    },
    sortArray(arr) {
      // NOTE: this method mutates the original array
      return arr.sort((a,b) => {
        if (a.versionNo > b.versionNo) return -1
        else return 1
      })
    }
  },
  computed: {
    getMaxVersion () {      
      if (this.vehicleList && this.vehicleList.length > 0) {
        return Math.max.apply(null, this.vehicleList.map(function(v) { return v.versionNo }))
      }
      return 0
    },
  },
  created () {
    FundTeamService.getByFundId(this.fundId).then(
      (response) => {
        if (response.data.length > 0) {
          this.members = response.data
        }
      }
    )    
    AllocationService.geeLegalList(this.fundId)
    .then((response) => {
      this.vehicleList = this.sortArray(response.data)
      this.isLoading = false
    })
  }
}
</script>
