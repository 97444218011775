<template>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th>Fund</th>
        <th>GP</th>
        <th>Commitment</th>
        <th>Called</th>
        <th>Unfunded</th>
        <th>Net Asset Value</th>
        <th style="width:75px"></th>
      </tr>
    </thead>
    <tbody v-if="funds != null">
      <tr v-for="fund in funds" :key="fund.secfundId">
          <td><a @click.prevent="select(fund.secfundId)" href="javascript:void(0)">{{fund.fundName}}</a></td>
          <td>{{fund.firmName}}</td>
          <td>{{fund.symbol}}{{fund.commitment}}</td>
          <td>{{fund.symbol}}{{fund.called}}</td>
          <td>{{fund.symbol}}{{fund.unfunded}}</td>
          <td>{{fund.symbol}}{{fund.netAssetValue}}</td>
          <td>
            <button class="btn btn-success btn-sm btn-mini btn-icon" v-if="canEdit" @click.prevent="updateRow(fund.secfundId)" v-tooltip="'Update'"><i class="mdi mdi-lead-pencil"></i></button>
            <button class="btn btn-danger btn-sm btn-mini btn-icon" v-if="canEdit" @click.prevent="deleteRow(fund.secfundId)" v-tooltip="'Delete'"><i class="mdi mdi-delete"></i></button>
          </td>
      </tr>
    </tbody>
    <tfoot>
      <tr v-if="funds === null">
        <td colspan="7" class="text-center">No Fund to show</td>
      </tr>
      <tr v-if="funds != null">
        <td colspan="2">Total</td>
        <td>{{totalCommitment}}</td>
        <td>{{totalCalled}}</td>
        <td>{{totalUnfunded}}</td>
        <td>{{totalNAV}}</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="7">
          <button v-if="!isEditMode && canEdit" class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addNew"> <i class="mdi mdi mdi-playlist-plus"></i> Add</button>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'

export default {
  props: {
    funds: {
      type: Array,
      default: null
    },
    isEditMode: {
      required: true,
      type: Boolean
    },
    canEdit: {
      default: false,
      type: Boolean
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['deleteSecFunds']),
    addNew () {
      this.hideRightbar()
      this.$emit('onClickNew')
    },
    select (id) {
      this.$emit('onSelect', id)
    },
    updateRow (id) {
      this.$emit('onUpdate', id)
    },
    deleteRow (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deleteSecFunds({secFundId: id})
                .then((response) => {
                  this.$snotify.success('Fund Deleted.')
                  this.$emit('onAfterUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    }
  },
  computed: {
    totalCommitment () {
      return this.funds.map(x => x.commitment).reduce((a, b) => a + b)
    },
    totalCalled () {
      return this.funds.map(x => x.called).reduce((a, b) => a + b)
    },
    totalUnfunded () {
      return this.funds.map(x => x.unfunded).reduce((a, b) => a + b)
    },
    totalNAV () {
      return this.funds.map(x => x.netAssetValue).reduce((a, b) => a + b)
    }
  }
}
</script>
