<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="''"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">

                    <PageTitle :title="'Your Profile'"/>                    
                        <div class="row">
                            <div class="col-xl-3 col-lg-4">
                                <div class="text-center card-box">
                                    <div class="member-card">
                                        <div class="thumb-xl member-thumb m-b-10 center-block">
                                            <AppUserPic :userId="user.userId" :size="0" />
                                        </div>

                                        <div class="">
                                            <h5 class="m-b-5">{{user.fullName}}</h5>
                                        </div>

                                        <!-- <button type="button" class="btn btn-success btn-sm w-sm waves-effect m-t-10 waves-light" disabled>Message</button> -->

                                        <div class="text-left m-t-40">
                                            <p class="text-muted font-13">
                                                <strong>User Name :</strong>
                                                <span class="m-l-15">{{ user.userName }}</span>
                                                <span class="text-danger" v-if="!user.isActive">(Inactive)</span>
                                            </p>

                                            <p class="text-muted font-13">
                                                <strong>Email :</strong>
                                                <span class="m-l-15">
                                                    <a :href="`mailto:` + user.email">{{ user.email }}</a>
                                                </span>
                                            </p>

                                            <p class="text-muted font-13">
                                                <strong>Group :</strong>
                                                <span class="m-l-15"> {{ user.groupName }} </span>
                                            </p>

                                            <p class="text-muted font-13">
                                                <strong>Office :</strong>
                                                <span class="m-l-15"> {{user.officeName}} </span>
                                            </p>

                                            <p class="text-muted font-13">
                                                <strong>Timezone :</strong>
                                                <span class="m-l-15"> {{user.timezone}} </span>
                                            </p>

                                        </div>
                                    </div>

                                </div> 
                                <!-- end card-box -->

                            </div>
                            <!-- end col -->

                            <div class="col-lg-8 col-xl-9">
                                <div class="">
                                    <div class="card-box">
                                        <ul class="nav nav-tabs tabs-bordered">
                                            <li class="nav-item">
                                                <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link active">
                                                    Info
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane active">
                                                <form role="form" autocomplete="off">
                                                    <div class="form-group">
                                                        <label for="FirstName">First Name</label>
                                                        <input type="text" v-model="user.firstName" class="form-control" id="FirstName" :disabled="!inEditMode">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="LastName">Last Name</label>
                                                        <input type="text" v-model="user.lastName" class="form-control" id="LastName" :disabled="!inEditMode">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="Group">Title</label>
                                                        <input type="text" v-model="user.titleName" class="form-control" id="Group" :disabled="!inEditMode">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="Group">Group</label>
                                                        <input type="text" v-model="user.groupName" class="form-control" id="Group" :disabled="!inEditMode">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="Office">Office</label>
                                                        <input type="text" v-model="user.officeName" class="form-control" id="Office" :disabled="!inEditMode">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="Mobile">Mobile</label>
                                                        <input type="text" v-model="user.mobile" class="form-control" id="Mobile" :disabled="!inEditMode">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="Role">Role{{roles.length > 1 ? 's' : ''}} </label>
                                                        <input type="text" v-model="roles" class="form-control" id="Role" :disabled="!inEditMode">
                                                    </div>
                                                    <button class="btn btn-primary waves-effect waves-light w-md" type="submit" disabled>Save</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- end col -->
                        </div>

                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  mixins: [asyncDataStatus],
  data () {
    return {
      inEditMode: false
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    roles () {
      let r = ''
      return r.concat(this.user.roles.map(x => ' ' + x.roleName)).trim()
    }
  },
  created () {
    this.asyncDataStatus_fetched('Your Profile')
  }
}
</script>
