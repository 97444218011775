import Constants from '../constants.js'
import Axios from 'axios'

export const CacheService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/cache-admin/' + id)
  },  
  delete (id) {
    return Axios.delete(Constants.API_URL + '/cache-admin/' + id)
  }
}

