<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Firm Employees
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="ContactId">Contact<span v-show="isAdd" class="text-danger">*</span></label>
          <input type="text" v-model="firmContact.fullName" class="form-control" id="ContactId" v-show="!isAdd" readonly="readonly">
          <span :class="{ 'invalid': $v.firmContact.contactId.$error }">
            <multiselect id="ContactId" v-model="contactObj" v-show="isAdd" :options="searchContacts" label="fullName" track-by="contactId" :allow-empty="false" placeholder="Type to Search"
              @input="contactSelected" @search-change="asyncSearchContact" :loading="isSearchingList" :options-limit="50"></multiselect>
          </span>
      </div>

      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="firmContact.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="firmContact.createdBy" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="!isEditMode && isAdd" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Add </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { required, minValue } from 'vuelidate/lib/validators'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      showLogs: false,
      contactObj: null,
      searchContacts: [],
      isSearchingList: false
    }
  },
  validations: {
    firmContact: {
      contactId: { required, min: minValue(1) }
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['searchAllContacts', 'addFirmContact']),
    close () {
      this.$v.firmContact.$reset()
      this.hideRightbar()
    },
    contactSelected (value, id) {
      this.firmContact.contactId = value ? value.contactId : null
    },
    onSave () {
      this.$v.firmContact.$touch()
      if (this.$v.firmContact.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      if (this.isAdd) {
        this.addFirmContact({firmContact: this.firmContact})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Contact Added.')
              this.$emit('onUpdate')
            }
          })
      }
    },
    asyncSearchContact (query) {
      this.isSearchingList = true
      this.searchAllContacts({query}).then((result) => {
        this.searchContacts = result
        this.isSearchingList = false
      })
    }
  },
  computed: {
    firmContact () {
      return this.$store.state.rb_firmContact
    }
  }
}
</script>
