<template>
  <div>
    <Topbar/>
    <Sidebar :activeItem="'legal-add'"/>

    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid" v-if="legalRequest">
          <PageTitle :title="'Legal Workflow'" :icon="'mdi mdi-scale-balance'" />
          <div class="card-box">
            <h4 class="header-title m-t-0">Investment/Fund Closings request</h4>
            <p class="text-muted font-14 m-b-20">Please fill the form.</p>

            <form action="#" autocomplete="off" @submit.prevent="save">
              <div class="row">
                <div class="col-sm-6 col-xs-12">

                  <div class="form-group">
                    <label>Requestor</label>
                    <AppUser :userId="legalRequest.requestorId" />
                  </div>
                  <div class="form-group" v-if="legalRequest.assignedTo > 0">
                    <label>Assigned To</label>
                    <AppUser :userId="legalRequest.assignedTo" />
                  </div>

                  <div class="form-group">
                    <label for="deadline" v-tooltip="'Deadline'">
                      Comments Due Date<span v-if="isOwner" class="text-danger">*</span>
                    </label>
                    <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" :disabled="!isOwner"
                      format="MM/dd/yyyy" :bootstrap-styling="true" :disabledDates="{to: endDate}" v-model="legalRequest.deadline" @blur="$v.legalRequest.deadline.$touch()" @input="setPickerDateDeadline" :input-class="{ 'form-control parsley-error': $v.legalRequest.deadline.$error }"></date-picker>
                    <template v-if="$v.legalRequest.deadline.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.deadline.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="closingDate">
                      Closing Date <span v-if="!legalRequest.closingDateNa && isOwner" class="text-danger">*</span>
                    </label>
                    <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" :disabled="!isOwner"
                      format="MM/dd/yyyy" :bootstrap-styling="true" :disabledDates="{to: endDate}" v-model="legalRequest.closingDate" @blur="$v.legalRequest.closingDate.$touch()" @input="setPickerDateClosing" :input-class="{ 'form-control parsley-error': $v.legalRequest.closingDate.$error }"></date-picker>
                    <template v-if="$v.legalRequest.closingDate.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.closingDate.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                    <div class="checkbox-primary checkbox" v-if="!legalRequest.closingDate">
                        <input id='closingDateNA' type="checkbox" v-model="legalRequest.closingDateNa" />
                        <label for='closingDateNA'>TBD</label>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      Type of investment
                      <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.investment.$error }">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="investment1" name="investment" class="custom-control-input" value=1 v-model="legalRequest.investment" :disabled="!isOwner">
                        <label class="custom-control-label" for="investment1">Fund</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="investment2" name="investment" class="custom-control-input" value=2 v-model="legalRequest.investment" :disabled="!isOwner">
                        <label class="custom-control-label" for="investment2">Co-investment</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="investment3" name="investment" class="custom-control-input" value=3 v-model="legalRequest.investment" :disabled="!isOwner">
                        <label class="custom-control-label" for="investment3">Secondary Investment</label>
                      </div>
                    </span>
                    <template v-if="$v.legalRequest.investment.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.investment.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="fundId" class="col-form-label">
                      Fund <span class="text-danger" v-if="isOwner">*</span>
                      <router-link :to="{name: 'FundsDetail', params: {id: legalRequest.fundId}}" target="_blank" >
                        <i class="mdi mdi-open-in-new"></i>
                      </router-link>
                    </label>
                    <span class="badge" :class="stageClass(fundObj.stageId)">
                      {{fundObj.stageName}}
                    </span>
                    <span :class="{ 'invalid': $v.legalRequest.fundId.$error }">
                      <multiselect id="fundId" v-model="fundObj" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search" :disabled="!isOwner"
                        @select="fundSelected" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                      <span> GP: <strong>{{fundObj.generalPartner}}</strong></span>
                    </span>
                    <template v-if="$v.legalRequest.fundId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.fundId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="fundConcoleContact" class="col-form-label">
                      Fund Counsel Contact(s) Information <span v-if="isOwner" class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="fundConcoleContact" :disabled="!isOwner" v-model="legalRequest.fundConcoleContact" @blur="$v.legalRequest.fundConcoleContact.$touch()" :class="{ 'parsley-error': $v.legalRequest.fundConcoleContact.$error }">
                    <template v-if="$v.legalRequest.fundConcoleContact.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.fundConcoleContact.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.fundConcoleContact.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.fundConcoleContact.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="FundManagerContact" class="col-form-label">
                      Fund Manager (Entity Name) <span v-if="isOwner" class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="FundManagerContact" :disabled="!isOwner" v-model="legalRequest.fundManagerContact" @blur="$v.legalRequest.fundManagerContact.$touch()" :class="{ 'parsley-error': $v.legalRequest.fundManagerContact.$error }">
                    <template v-if="$v.legalRequest.fundManagerContact.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.fundManagerContact.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.fundManagerContact.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.fundManagerContact.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="secondary-seller-contact" class="col-form-label">
                      Secondary Seller Contact Name
                    </label>
                    <input type="text" class="form-control" id="secondary-seller-contact" :disabled="!isOwner" v-model="legalRequest.secondarySellerContact" @blur="$v.legalRequest.secondarySellerContact.$touch()" :class="{ 'parsley-error': $v.legalRequest.secondarySellerContact.$error }">
                    <template v-if="$v.legalRequest.secondarySellerContact.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.secondarySellerContact.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      Subscription Documents Due Date <span v-if="isOwner" class="text-danger">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.subscriptionDueDateTypeId.$error }">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="subscriptionDueDateTypeId5" name="subscriptionDueDateTypeId" class="custom-control-input" :disabled="!isOwner" value=5 v-model="legalRequest.subscriptionDueDateTypeId">
                        <label class="custom-control-label" for="subscriptionDueDateTypeId5">Before Closing Date</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="subscriptionDueDateTypeId1" name="subscriptionDueDateTypeId" class="custom-control-input" :disabled="!isOwner" value=1 v-model="legalRequest.subscriptionDueDateTypeId">
                        <label class="custom-control-label" for="subscriptionDueDateTypeId1">Closing Date</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="subscriptionDueDateTypeId2" name="subscriptionDueDateTypeId" class="custom-control-input" :disabled="!isOwner" value=2 v-model="legalRequest.subscriptionDueDateTypeId">
                        <label class="custom-control-label" for="subscriptionDueDateTypeId2">Post Closing Sufficent</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="subscriptionDueDateTypeId3" name="subscriptionDueDateTypeId" class="custom-control-input" :disabled="!isOwner" value=3 v-model="legalRequest.subscriptionDueDateTypeId">
                        <label class="custom-control-label" for="subscriptionDueDateTypeId3">TBD</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="subscriptionDueDateTypeId4" name="subscriptionDueDateTypeId" class="custom-control-input" :disabled="!isOwner" value=4 v-model="legalRequest.subscriptionDueDateTypeId">
                        <label class="custom-control-label" for="subscriptionDueDateTypeId4">Other</label>
                      </div>
                      <div class="form-group" v-show="legalRequest.subscriptionDueDateTypeId == 4">
                        <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :disabled="!isOwner" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                          format="MM/dd/yyyy" :bootstrap-styling="true" v-model="legalRequest.subscriptionDueDate" @blur="$v.legalRequest.subscriptionDueDate.$touch()" @input="setPickerDateSubscription" :input-class="{ 'form-control parsley-error': $v.legalRequest.subscriptionDueDate.$error }"></date-picker>
                        <template v-if="$v.legalRequest.subscriptionDueDate.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.legalRequest.subscriptionDueDate.required" class="parsley-required">This value is required</li>
                          </ul>
                        </template>
                      </div>                      
                    </span>
                    <template v-if="$v.legalRequest.subscriptionDueDateTypeId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.subscriptionDueDateTypeId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      AML/KYC Documents Date <span v-if="isOwner" class="text-danger">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.amlKycDueDateTypeId.$error }">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="amlKycDueDateTypeId1" name="amlKycDueDateTypeId" class="custom-control-input" :disabled="!isOwner" value=1 v-model="legalRequest.amlKycDueDateTypeId">
                        <label class="custom-control-label" for="amlKycDueDateTypeId1">Closing Date</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="amlKycDueDateTypeId2" name="amlKycDueDateTypeId" class="custom-control-input" :disabled="!isOwner" value=2 v-model="legalRequest.amlKycDueDateTypeId">
                        <label class="custom-control-label" for="amlKycDueDateTypeId2">Post Closing Sufficent</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="amlKycDueDateTypeId3" name="amlKycDueDateTypeId" class="custom-control-input" :disabled="!isOwner" value=3 v-model="legalRequest.amlKycDueDateTypeId">
                        <label class="custom-control-label" for="amlKycDueDateTypeId3">TBD</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="amlKycDueDateTypeId4" name="amlKycDueDateTypeId" class="custom-control-input" :disabled="!isOwner" value=4 v-model="legalRequest.amlKycDueDateTypeId">
                        <label class="custom-control-label" for="amlKycDueDateTypeId4">Other</label>
                      </div>
                      <div class="form-group" v-show="legalRequest.amlKycDueDateTypeId == 4">
                        <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :disabled="!isOwner" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                          format="MM/dd/yyyy" :bootstrap-styling="true" v-model="legalRequest.amlKycDueDate" @blur="$v.legalRequest.amlKycDueDate.$touch()" @input="setPickerDateAmlKycDueDate" :input-class="{ 'form-control parsley-error': $v.legalRequest.amlKycDueDate.$error }"></date-picker>
                        <template v-if="$v.legalRequest.amlKycDueDate.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.legalRequest.amlKycDueDate.required" class="parsley-required">This value is required</li>
                          </ul>
                        </template>
                      </div>                      
                    </span>
                    <template v-if="$v.legalRequest.amlKycDueDateTypeId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.amlKycDueDateTypeId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label">Subscription Documents and AML/KYC submitted through on Online</label>
                      <toggle-button :sync="true" v-model="legalRequest.isSubscriptionOnline" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!isOwner" />
                      <div class="form-group" v-show="legalRequest.isSubscriptionOnline">
                        <label for="SubscriptionOnline" class="col-form-label">Platform Link <span v-if="isOwner" class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="SubscriptionOnline" :disabled="!isOwner" v-model="legalRequest.subscriptionOnline" @blur="$v.legalRequest.subscriptionOnline.$touch()" :class="{ 'parsley-error': $v.legalRequest.subscriptionOnline.$error }">
                        <template v-if="$v.legalRequest.amlKycDueDate.$error">
                          <ul class="parsley-errors-list filled">
                            <li v-if="!$v.legalRequest.amlKycDueDate.required" class="parsley-required">This value is required</li>
                          </ul>
                        </template>
                      </div>
                    <template v-if="$v.legalRequest.amlKycDueDateTypeId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.amlKycDueDateTypeId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      Type of relationship <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.relationship.$error }">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="relationship1" name="relationship" class="custom-control-input" value=1 v-model="legalRequest.relationship" :disabled="!isOwner">
                        <label class="custom-control-label" for="relationship1">Re-up</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="relationship2" name="relationship" class="custom-control-input" value=2 v-model="legalRequest.relationship" :disabled="!isOwner">
                        <label class="custom-control-label" for="relationship2">New</label>
                      </div>
                    </span>
                    <template v-if="$v.legalRequest.relationship.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.relationship.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for class="col-form-label">
                      Type of review <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.reviewType.$error }">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="review1" name="review" class="custom-control-input" value=1 v-model="legalRequest.reviewType" :disabled="!isOwner">
                        <label class="custom-control-label" for="review1">Full review of the docs</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="review2" name="review" class="custom-control-input" value=2 v-model="legalRequest.reviewType" :disabled="!isOwner">
                        <label class="custom-control-label" for="review2">Red flags review</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="review3" name="review" class="custom-control-input" value=3 v-model="legalRequest.reviewType" :disabled="!isOwner">
                        <label class="custom-control-label" for="review3">Provide / Review PSA (For Secondary Transactions Only)</label>
                      </div>
                    </span>
                    <template v-if="$v.legalRequest.reviewType.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.reviewType.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="FundDocument" class="col-form-label">
                      Fund Documents for Review (LPA, Subscription Agreement) <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <input type="text" class="form-control" id="FundDocument" v-model="legalRequest.fundDocument" @blur="$v.legalRequest.fundDocument.$touch()" :class="{ 'parsley-error': $v.legalRequest.fundDocument.$error }" :disabled="!isOwner">
                    <template v-if="$v.legalRequest.fundDocument.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.fundDocument.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.fundDocument.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.fundDocument.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="InvestmentAuthorization" class="col-form-label">
                      Investment Authorization <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <input type="text" class="form-control" id="InvestmentAuthorization" v-model="legalRequest.investmentAuthorization" @blur="$v.legalRequest.investmentAuthorization.$touch()" :class="{ 'parsley-error': $v.legalRequest.investmentAuthorization.$error }" :disabled="!isOwner">
                    <template v-if="$v.legalRequest.investmentAuthorization.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.investmentAuthorization.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.investmentAuthorization.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.investmentAuthorization.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="NegotiationPower" class="col-form-label">
                      Negotiation Power <span v-if="isOwner" class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="NegotiationPower" v-model="legalRequest.negotiationPower" @blur="$v.legalRequest.negotiationPower.$touch()" :class="{ 'parsley-error': $v.legalRequest.negotiationPower.$error }" :disabled="!isOwner">
                    <template v-if="$v.legalRequest.negotiationPower.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.negotiationPower.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.negotiationPower.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.negotiationPower.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="other" class="col-form-label">
                      Any other relevant information
                    </label>
                    <textarea class="form-control" rows="5" id="other" v-model="legalRequest.comments" @blur="$v.legalRequest.comments.$touch()" :class="{ 'parsley-error': $v.legalRequest.comments.$error }" :disabled="!isOwner"></textarea>
                    <template v-if="$v.legalRequest.comments.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.comments.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group text-right m-b-0">
                    <router-link :to="{name: 'LegalList'}">
                        <button type="button" class="btn btn-custom btn-secondary waves-effect mr-2">
                            Back
                        </button>
                    </router-link>

                    <button class="btn btn-custom waves-effect btn-danger w-md waves-light mr-2" v-if="isOwner && legalRequest.statusId < 4"
                      @click.prevent="withdraw"><i class="mdi mdi-stop"></i> <span>Withdraw</span>
                    </button>

                    <button class="btn btn-custom waves-effect btn-defualt w-md waves-light" v-if="isOwner && legalRequest.statusId === 4"
                      @click.prevent="resubmit"><i class="mdi mdi-step-forward"></i> <span>Resubmit</span>
                    </button>

                    <button v-if="isOwner && legalRequest.statusId < 4" class="btn btn-custom btn-primary waves-effect waves-light" type="submit">Update Request</button>
                  </div>
                  
                  <div class="form-group mt-3" v-if="isOwner && legalRequest.statusId < 4">
                    <vue-dropzone :options="dropzoneOptions" @vdropzone-success="vsuccess"></vue-dropzone>
                    <p class="text-muted">Max file size: 5MB</p>
                  </div>
                </div>

                <div class="col-lg-6 col-xs-12">
                  <div>
                    <ul class="nav nav-tabs tabs-bordered nav-justified">
                      <li class="nav-item">
                          <a class="nav-link active show">
                              Attachments <span class="badge badge-primary noti-icon-badge" v-if="fileList != null">{{fileList.length}}</span>
                          </a>
                      </li>
                    </ul>
                    <span v-if="isLoadingFiles">Loading <i class="fa fa-spinner fa-spin"></i></span>
                    <FileList :files="fileList" :isEditMode="false" :canEdit="false" :canDelete="isOwner" @onSelect="showFile" />
                  </div>
                  <StatusBar :currentStatusId="legalRequest.statusId" />                 
                  <div v-if="isLegal || isAdmin">
                    <LegalActions :currentStatusId="legalRequest.statusId" @onUpdateStatus="updateStatus" />
                    <LegalAssign :id="legalRequest.requestId" :assignedTo="legalRequest.assignedTo" @onAssignTo='onAssignTo'/>
                    <StatusUpdate :id="legalRequest.requestId" :canAdd="isLegal || isAdmin" />
                    <DealTeamList v-if="legalRequest.fundId > 0" :fundId="legalRequest.fundId" :fundFiles="fundFiles" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="side-bar right-bar">
      <div class="col-sm-12">
        <FileItem  :objectId="legalRequest.fundId" :objectTypeId="1" :isEditMode="true" :isAdd="true" :forLegal="true" @onUpdate="onFileUpdate" />        
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import {mapActions, mapGetters} from 'vuex'
import { required, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { LegalService, LegalIfcService } from '@/services/legal-service'
import { FileService } from '@/services/file-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import StatusBar from '@/components/legal/TheStatusBar'
import LegalActions from '@/components/legal/TheActionBar'
import LegalAssign from '@/components/legal/LegalAssign'
import vue2Dropzone from 'vue2-dropzone'
import Constants from '@/constants.js'
import FileList from '@/components/FileListAll'
import FileItem from '@/components/FileItem'
import rightbar from '@/mixins/rightbar'
import StatusUpdate from '@/components/legal/TheStatusUpdate'
import DealTeamList from '@/components/legal/DealTeamInfo'
import moment from 'moment'

export default {
  props: {
    id: {
      required: true,
      type: Number,
      default: 0
    }
  },
  components: {
    DatePicker,
    Multiselect,
    StatusBar,
    LegalActions,
    FileList,
    FileItem,
    DealTeamList,
    StatusUpdate,
    LegalAssign,
    vueDropzone: vue2Dropzone
  },
  mixins: [asyncDataStatus, rightbar],
  data () {
    return {
      legalRequest: {fundId: 0, statusId: 0, assignedTo: 0, requestId: 0},
      endDate: null,
      fundObj: {fundName: ''},
      searchFunds: [],
      isSearchingList: false,
      isLegal: true,
      isLoadingEmail: false,
      authCookie: null,
      isLoadingFiles: false,
      fileList: null,
      rightbarItem: null,
      dealTeams: null,
      fundFiles: []
    }
  },
  validations: {
    legalRequest: {
      deadline: { required },
      closingDate: { required: requiredIf(function () { return !this.legalRequest.closingDateNa }) },
      investment: { required },
      fundId: { required },
      relationship: { required },
      reviewType: { required },
      subscriptionDueDateTypeId: { required },
      subscriptionDueDate: { required: requiredIf(function () { return parseInt(this.legalRequest.subscriptionDueDateTypeId) === 4 }) },
      amlKycDueDateTypeId: { required },
      amlKycDueDate: { required: requiredIf(function () { return parseInt(this.legalRequest.amlKycDueDateTypeId) === 4 }) },
      subscriptionOnline: { required: requiredIf(function () { return this.legalRequest.IsubscriptionOnline }) },
      fundDocument: { required, min: minLength(2), max: maxLength(150) },
      investmentAuthorization: { required, min: minLength(2), max: maxLength(150) },
      comments: { max: maxLength(500) },
      negotiationPower: { required, max: maxLength(100) },
      fundConcoleContact: { required, max: maxLength(100) },
      fundManagerContact: { required, max: maxLength(100) },
      secondarySellerContact: { max: maxLength(100) }
    }
  },
  methods: {
    ...mapActions(['searchAllFunds', 'fetchFund', 'fetchFile']),
     fetchFundFileList () {      
      FileService.getFundFiles(this.legalRequest.fundId).then(
          (response) => {            
            if (response.data.length > 0) {
              this.fundFiles = response.data
            } else {
              this.fundFiles = []
            }
          }
        )
    },
    fundSelected (value, id) {
      this.legalRequest.fundId = value ? value.fundId : null
      this.$v.legalRequest.fundId.$touch()
      this.$v.legalRequest.fundId.$reset()
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    },
    onAssignTo (user) {
      this.legalRequest.assignedTo = user
    },
    getDetails () {
      LegalIfcService.get(this.id)
      .then((response) => {
        this.legalRequest = response.data
        if (!this.legalRequest.closingDate) {
          this.legalRequest.closingDateNa = true
        }
        this.fetchFund({fundId: this.legalRequest.fundId})
        .then((fResponse) => {
          this.fundObj = fResponse.data
          this.fetchFundFileList()
        })
      })
      .then(() => { this.fetchFileList() })
      .then(() => { this.asyncDataStatus_fetched('Legal Workflow') })
    },
    save () {
      this.$v.legalRequest.$touch()
      if (this.$v.legalRequest.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }

      LegalIfcService.save(this.id, this.legalRequest)
      .then(
        (response) => {
          this.$snotify.success('Request Updated.')
          setTimeout(() => {
            this.$router.push({name: 'LegalList'})
          }, 400)
        })
        .catch(function (error) {
          console.error('SERVER Error:', error)
        })
    },
    withdraw () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to withdraw this request?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.updateStatus(4)
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    resubmit () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to resubmit this request?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.updateStatus(1)
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    updateStatus (statusId) {      
      if (statusId === 5) {
        const requiredFileTypes = [7,8,16]
        if (this.fundFiles.length === 0 || this.fundFiles.filter(x=> requiredFileTypes.indexOf(x.purposeId) > -1).length === 0) {
         //error
          this.$snotify.warning('Please upload LPA (or Equivalent), Side Letter, Background Check documents.')
          return
        } 
        if (this.fundFiles.filter(x=> x.purposeId === 7).length === 0) {
         //LPA required
          this.$snotify.warning('Please upload LPA (or Equivalent) documents.')
          return
        }
      }

      this.legalRequest.statusId = statusId
      LegalService.updateStatus(this.id, this.legalRequest)
      .then(
        (response) => {
          this.$snotify.success('Request Status Updated.')
        })
        .catch(function (error) {
          console.error('SERVER Error:', error)
        })      
    },
    setPickerDateDeadline (value) {
      if (value != null) {
        this.legalRequest.deadline = moment(value).format('YYYY-M-D')
      } else {
        this.legalRequest.deadline = null
      }
    },
    setPickerDateClosing (value) {
      if (value != null) {
        this.legalRequest.closingDate = moment(value).format('YYYY-M-D')
      } else {
        this.legalRequest.closingDate = null
      }
    },
    setPickerDateSubscription (value) {
      if (value != null) {
        this.legalRequest.subscriptionDueDate = moment(value).format('YYYY-M-D')
      } else {
        this.legalRequest.subscriptionDueDate = null
      }
    },
    setPickerDateAmlKycDueDate (value) {
      if (value != null) {
        this.legalRequest.amlKycDueDate = moment(value).format('YYYY-M-D')
      } else {
        this.legalRequest.amlKycDueDate = null
      }
    },
    loademail () {
      this.isLoadingEmail = true
      setTimeout(() => {
        this.isLoadingEmail = false
      }, 2000)
    },
    fetchFileList () {
      this.isLoadingFiles = true
      FileService.getLegalFiles(this.id).then(
        (response) => {
          this.isLoadingFiles = false
          if (response.data.length > 0) {
            this.fileList = response.data
          } else {
            this.fileList = null
          }
        }
      )
    },
    sidebarAction (item, isOpen) {
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    showFile (id) {
      this.fetchFile({fileId: id})
      this.sidebarAction('file', true)
    },
    vsuccess (file, response) {
      this.fetchFileList()
    },
    stageClass (stageId) {
      if (stageId >= 3) {
        return 'text-white stage' + stageId
      }
    },
    onFileUpdate () {
      this.fetchFundFileList()
      this.hideRightbar()
    },
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    isOwner () {
      return this.legalRequest.requestorId === this.user.userId && this.legalRequest.statusId !== 5
    },
    allUsers () {
      return this.$store.state.allUsers
    },
    emailBody () {
      const sbj = '☂ Investment/Fund Closings request: ' + this.fundObj.fundName
      const lnk = window.location.href
      return 'subject=' + sbj + '&body=Request link: ' + lnk + '%0A%0ALegal team,%0A%0A**PLEASE ATTACH YOUR DOCUMNETS**'
    },
    legalUsers () {
      return this.isAdmin || (this.$store.state.allUsers.filter(x => x.groupId === 2 && x.isActive && x.userId > 0))
    },
    dropzoneOptions () {
      return {
        url: Constants.API_URL + '/file/upload/dropzone/4/' + this.id + '/6',
        thumbnailWidth: 150,
        maxFilesize: 5,
        dictDefaultMessage: 'Drag and drop or click to select files to upload',
        headers: { 'Authorization': 'Bearer ' + this.authCookie }
      }
    },
    isAdmin () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0
    }
  },
  created () {
    this.authCookie = localStorage.getItem('auth_token')
    this.endDate = new Date(Date.now())
    this.getDetails()
    this.isLegal = this.user.roles.filter(x => x.roleId === this.$userRoles.LEGAL_TEAM_WORKFLOW).length > 0
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-dropzone/dist/vue2Dropzone.min.css"></style>
