<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'dashboard'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Global Search'" :icon="'fa fa-search'"/>
                    <div class="row">
                      <div class="col-lg-12">
                          <div class="row m-b-10">
                              <div class="col-sm-6 col-md-6">
                                  <div class="ml-5">
                                      <label>
                                          Search for:
                                          <input type="search" v-model="searchName" class="form-control" placeholder="" @keyup.enter="startSearch">
                                      </label>
                                      <button @click="startSearch" class="btn btn-icon waves-effect waves-light btn-primary m-b-5"> <i class="fa fa-search"></i> </button>
                                  </div>
                              </div>
                              <div class="col-sm-6 col-md-6 form-inline">
                                <span class="mr-2">Found: </span>
                                <toggle-button v-model="showDeals" :width="40" @input="setCookies" :sync="true" />
                                <label class="mr-4" v-tooltip="'Deal'" style="flex-direction: column"><i class="search-icon mdi mdi-square-inc-cash"></i> {{dealCount}} </label>
                                <toggle-button v-model="showFirms" :width="40"  @input="setCookies" :sync="true" />
                                <label class="mr-4" v-tooltip="'Firm'" style="flex-direction: column"><i class="search-icon mdi mdi-factory"></i> {{firmCount}} </label>
                                <toggle-button v-model="showContacts" :width="40"  @input="setCookies" :sync="true" />
                                <label class="mr-4" v-tooltip="'Contact'" style="flex-direction: column"><i class="search-icon mdi mdi-contacts"></i> {{contactCount}}</label>
                                <toggle-button v-model="showNoteSubject" :width="40" @input="setCookies" :sync="true" />
                                <label class="mr-4" v-tooltip="'Note - Subject'" style="flex-direction: column"><i class="search-icon mdi mdi-tag"></i> {{noteSbjCount}}</label>
                                <toggle-button v-model="showNoteText" :width="40" @input="setCookies" :sync="true" />
                                <label class="mr-4" v-tooltip="'Note - Body'" style="flex-direction: column"><i class="search-icon mdi mdi-tag-text-outline"></i> {{noteTxtCount}}</label>
                              </div>
                          </div>

                          <div class="card-box">
                              <div class="container-fluid">
                                <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                <div class="row">
                                  <div class="col-sm-12">
                                    <table class="table table-hover table-striped m-0 table-centered" role="grid">
                                      <tbody class="font-16">
                                        <tr v-if="searchResult.length == 0 && !isLoading">
                                          <td class="text-center">
                                            No result
                                          </td>
                                        </tr>
                                        <tr role="row" v-for="(item, index) in filterResult" :key="index">
                                          <td></td>
                                          <td>
                                              <span class="badge badge-pill">
                                                <i class="search-icon mdi" :class="item.objectClass"></i>
                                              </span>
                                              <router-link v-if="item.id > 0" :to="{name: item.urlName, params: {id: item.id}}">
                                                {{item.name}}
                                                <!-- <i class="mdi mdi-open-in-new"></i> -->
                                              </router-link>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div><!-- end col -->
                    </div>
              </div>
              <!-- end content -->
              <Footer />
        </div>
    </div>
    </div>

</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { SearchService } from '@/services/globalsearch-service'

export default {
  props: {
    id: {
      required: false,
      type: String
    }
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      searchName: null,
      isLoading: null,
      searchResult: [],
      nomore: true,
      readNotifications: false,
      showDeals: true,
      showFirms: true,
      showContacts: true,
      showNoteText: true,
      showNoteSubject: true
    }
  },
  created () {
    this.searchName = this.id
    const oldFilters = JSON.parse(localStorage.getItem('umb_globalsearch_filters'))
    if (oldFilters != null) {
      if (oldFilters.showDeals != null) {
        this.showDeals = oldFilters.showDeals
      }
      if (oldFilters.showFirms != null) {
        this.showFirms = oldFilters.showFirms
      }
      if (oldFilters.showContacts != null) {
        this.showContacts = oldFilters.showContacts
      }
      if (oldFilters.showNoteText != null) {
        this.showNoteText = oldFilters.showNoteText
      }
      if (oldFilters.showNoteSubject != null) {
        this.showNoteSubject = oldFilters.showNoteSubject
      }
    }

    this.startSearch()
  },
  methods: {
    startSearch () {
      this.isLoading = true
      this.searchResult = []
      if (this.searchName.length > 0) {
        SearchService.search(this.searchName)
        .then(
          (response) => {
            this.isLoading = false
            this.searchResult = response.data
          }
        ).then(() => {
          this.asyncDataStatus_fetched('Global Search')
        })
      }
    },
    setCookies () {
      const filters = {
        'showDeals': this.showDeals,
        'showFirms': this.showFirms,
        'showContacts': this.showContacts,
        'showNoteText': this.showNoteText,
        'showNoteSubject': this.showNoteSubject
      }
      
      localStorage.setItem('umb_globalsearch_filters', JSON.stringify(filters))
    }
  },
  computed: {
    dealCount () {
      return this.searchResult.filter(x => x.typeId === 1).length
    },
    firmCount () {
      return this.searchResult.filter(x => x.typeId === 2).length
    },
    contactCount () {
      return this.searchResult.filter(x => x.typeId === 3).length
    },
    noteSbjCount () {
      return this.searchResult.filter(x => x.typeId === 4).length
    },
    noteTxtCount () {
      return this.searchResult.filter(x => x.typeId === 5).length
    },
    filterResult () {
      if (this.showDeals && this.showFirms && this.showContacts && this.showNoteSubject && this.showNoteText) {
        return this.searchResult
      }
      let flist = []
      if (this.showDeals) {
        this.searchResult.filter(x => x.typeId === 1).forEach(d => {
          flist.push(d)
        })
      }
      if (this.showFirms) {
        this.searchResult.filter(x => x.typeId === 2).forEach(d => {
          flist.push(d)
        })
      }
      if (this.showContacts) {
        this.searchResult.filter(x => x.typeId === 3).forEach(d => {
          flist.push(d)
        })
      }
      if (this.showNoteSubject) {
        this.searchResult.filter(x => x.typeId === 4).forEach(d => {
          flist.push(d)
        })
      }
      if (this.showNoteText) {
        this.searchResult.filter(x => x.typeId === 5).forEach(d => {
          flist.push(d)
        })
      }

      return flist
    }
  }
}
</script>
