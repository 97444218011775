<template>
    <div class="mb-3">
      <h4 class="header-title m-t-5">Current Status:</h4>
        <span v-for="status in allStatuses" :key="status.statusId">
            <div class="btn btn-custom btn-rounded" :class="status.colorClass" v-if="isSameStatus(status.statusId)"><i class="mdi m-r-5" :class="status.actionIcon"></i> 
              <span>{{status.statusName}}</span>
            </div>
        </span>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    currentStatusId: {
      required: true,
      type: Number
    }
  },
  computed: {
    allStatuses () {
      return this.$store.state.allLegalRequestStatuses
    }
  },
  methods: {
    ...mapActions(['fetchAllLegalRequestStatuses']),
    isSameStatus (st) {
      return this.currentStatusId === st
    }
  },
  created () {
    this.fetchAllLegalRequestStatuses()
  }
}
</script>
