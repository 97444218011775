<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'legal-add'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Legal Workflow'" :icon="'mdi mdi-scale-balance'" />
                    <div class="card-box">
                        <h4 class="header-title m-t-0">Categories</h4>
                        <p class="text-muted font-14 m-b-20">
                            Please select your request category.
                        </p>

                        <div class="row">
                            <div class="col-sm-4 col-xs-12" v-for="(item, index) in categories" :key="index" >
                                <div class="card m-b-20 card-body">
                                    <div class="card-inverse">
                                        <h4 class="card-title">{{ item.categoryName }}</h4>
                                        <router-link :to="item.newFormUrl" class="btn btn-primary">
                                                Request
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- row -->

                    </div>

                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
  },
  mixins: [asyncDataStatus],
  computed: {
    categories () {
      return this.$store.state.allLegalRequestCategories.filter(x=> x.categoryId != 5)
    }
  },
  methods: {
    ...mapActions(['fetchAllLegalRequestCategories'])
  },
  created () {
    this.fetchAllLegalRequestCategories()
    this.asyncDataStatus_fetched('Submit a Legal Workflow')
  }
}
</script>