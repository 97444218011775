<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Document
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="PurposeId">Purpose <span v-show="isEditMode" class="text-danger">*</span></label>
          <span :class="{ 'parsley-error-radio': $v.file.purposeId.$error }">
            <div class="custom-control custom-radio" v-for="item in showFilePurposes" :key="item.purposeId">
              <input type="radio" :id="`purposeId` + item.purposeId" :value="item.purposeId" v-model="file.purposeId" :disabled="!isEditMode" name="type" class="custom-control-input">
              <label class="custom-control-label" :for="`purposeId` + item.purposeId">{{item.purposeName}}</label>
            </div>
          </span>
      </div>
      <div class="form-group" v-if="isAdd">
          <label for="file">File <span v-show="isEditMode" class="text-danger">*</span></label>
          <span :class="{ 'parsley-error-radio': fileError != null && currentFile == null }">
            <input type="file" name="file" id="file" accept=".pdf" class="input-file form-control" @change="fileChanged($event)" />
          </span>
      </div>

      <div class="form-group" v-if="!isAdd && forConnect">
          <label>Available in Connect</label>
          <toggle-button v-model="file.forConnect" :labels="{checked: 'Yes', unchecked: 'No'}" @input="updateConnect" />
      </div>
      <div class="form-group" v-if="!isAdd">
          <label for="OriginalFileName">Orignal File Name</label>
          <input type="text" id="OriginalFileName" class="form-control" readonly="readonly" v-model="file.originalFileName" />
      </div>

      <div class="form-group" v-if="!isAdd">
          <label for="UmbFileName">File Name</label>
          <input type="text" id="UmbFileName" class="form-control" readonly="readonly" v-model="file.umbFileName" />
      </div>
      <!-- <div class="form-group">
          <label for="FileDesc">Description</label>
          <input type="text" placeholder="Enter Description" class="form-control" id="FileDesc" v-model="file.fileDesc" :readonly="!isEditMode" @blur="$v.file.fileDesc.$touch()" :class="{ 'parsley-error': $v.file.fileDesc.$error }" />
      </div> -->

      <div class="form-group" v-if="!isAdd">
        <button class="btn btn-icon waves-effect waves-light btn-success" @click.prevent="view(file)"><i class="mdi mdi-download"></i> download</button>
        <!-- <pdfvue /> -->
      </div>

      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
            <div class="form-group">
                <label>Created On</label>
                <AppDate :date="file.createdOn" :show="'input'"/>
            </div>
            <div class="form-group">
                <label>Created By</label>
                <AppUser :userId="file.createdBy" />
            </div>
            <div class="form-group" v-if="file.updatedOn">
                <label>Modified On</label>
                <AppDate :date="file.updatedOn" :show="'input'"/>
            </div>
            <div class="form-group" v-if="file.updatedOn">
                <label>Modified By</label>
                <AppUser :userId="file.updatedBy" :emptyIfNull="true"/>
            </div>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode && isAdd" :disabled="isSaving" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> <span v-if="!isSaving">Add</span> <i v-else class="fa fa-spinner fa-spin"></i></button>
        <button v-if="isEditMode && !isAdd" :disabled="isSaving" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> <span v-if="!isSaving">Save</span> <i v-else class="fa fa-spinner fa-spin"></i></button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import { required, minValue, maxLength } from 'vuelidate/lib/validators'
import { FileService } from '@/services/file-service'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    },
    objectId: {
      required: true,
      type: Number
    },
    objectTypeId: {
      required: true,
      type: Number
    },
    forConnect: {
      required: false,
      default: false,
      type: Boolean
    },
    forPortfolio: {
      required: false,
      default: false,
      type: Boolean
    },
    isCoInvest: {
      required: false,
      default: false,
      type: Boolean
    },
    forLegal: {
      required: false,
      default: false,
      type: Boolean
    },
    forOps: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  components: {
  },
  data () {
    return {
      showLogs: false,
      currentFile: null,
      fileError: null,
      isSaving: null
    }
  },
  mixins: [rightbar],
  validations: {
    file: {
      purposeId: { required, min: minValue(1) },
      fileDesc: {max: maxLength(100)}
    }
  },
  methods: {
    ...mapActions(['fetchAllFilePurposes', 'addFile']),
    close () {
      this.$v.file.$reset()
      this.currentFile = ''
      this.hideRightbar()
    },
    onSave () {
      this.$v.file.$touch()
      if (this.currentFile == null) {
        this.$snotify.error('Please fix the errors.')
        this.fileError = true
        return
      }
      if (!this.$v.file.$error) {
        if (this.isAdd) {
          this.isSaving = true
          const formData = new FormData()
          formData.append('files', this.currentFile, this.currentFile.name)
          this.addFile({objectTypeId: this.objectTypeId, objectId: this.objectId, purposeId: this.file.purposeId, file: formData})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('File Added.')
              this.isSaving = false
              this.$emit('onUpdate')
            }
          })
        }
        // else {
        //   this.updatefile({fileId: this.file.fileId, file: this.file})
        //     .then((response) => {
        //       this.$snotify.success('File Updated.')
        //       this.$emit('onUpdate')
        //     })
        // }
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    fileChanged (event) {
      if (event.target.files.length > 0) {
        this.$v.file.$touch()
        this.currentFile = event.target.files[0]
      }
    },
    view (file) {
      FileService.download(file.fileId)
      .then(
        (response) => {
           var ext = file.originalFileName.split('.').reverse()[0]
          if(ext.toLowerCase()=='pdf') {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            window.open(fileURL)
          } else {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.target = "_blank"
            fileLink.href = fileURL
            fileLink.setAttribute('download', file.originalFileName)
            document.body.appendChild(fileLink)
            fileLink.click()
          }
        }
      )
    },
    updateConnect () {
      FileService.save(this.file.fileId, this.file)
        .then((response) => {
          this.$snotify.success('File Updated.')
          this.$emit('onUpdate')
        })
        .catch(error => this.$snotify.error('Server Error! ' + error.message))
    }
  },
  created () {
    this.fetchAllFilePurposes()
  },
  computed: {
    allFilePurposes () {      
      return this.$store.state.allFilePurposes.filter(x => x.objectTypeId === this.objectTypeId)
    },
    showFilePurposes () {
      // this.allFilePurposes.push({"purposeId":18,"purposeName":"ODD Report CLIENT","objectTypeId":1,"sort":16,"namePrefix":"foc","isActive":true})
      if (this.forLegal) {
        const allowed = [7,8,16]
        return this.allFilePurposes.filter(x => allowed.indexOf(x.purposeId) > -1)  
      } else if (this.forOps) {
        const allowed = [8,17,18]
        return this.allFilePurposes.filter(x => allowed.indexOf(x.purposeId) > -1)
      } else if (!this.isCoInvest && !this.forPortfolio) {
        return this.allFilePurposes.filter(x => x.purposeId < 10)      
      } else {
        return this.allFilePurposes
      }
    },
    file () {
      return this.$store.state.rb_file
    }
  }
}
</script>
