<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              RR Voting
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="vote">
      <div class="form-group">
        <label>Employee</label>
        <AppUser :userId="vote.createdBy" />
      </div>

      <div class="form-group" v-show="editable || vote.isFLRICFinalized">
        <label>Score<span class="text-danger">*</span></label>
        <select id="rate" class="form-control col-6" v-model="vote.score" :disabled="!editable">
            <option value=null>Vote</option>
            <option value="false">No</option>
            <option value="true">Yes</option>
        </select>
      </div>

      <div v-if="fundTypeId == 1">
          <div class="form-group">
            <label for="organization" class="col-form-label">
              Organization - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="organization" v-model="vote.organization" @blur="$v.vote.organization.$touch()" 
              :class="{ 'parsley-error': $v.vote.organization.$error }" :readonly="!editable"></textarea>
            <template v-if="$v.vote.organization.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.organization.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="team" class="col-form-label">
              Team - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="team" v-model="vote.team" @blur="$v.vote.team.$touch()" 
              :class="{ 'parsley-error': $v.vote.team.$error }" :readonly="!editable"></textarea>
            <template v-if="$v.vote.team.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.team.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="strategy" class="col-form-label">
              Strategy - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="strategy" v-model="vote.strategy" @blur="$v.vote.strategy.$touch()"
              :class="{ 'parsley-error': $v.vote.strategy.$error }" :readonly="!editable"></textarea>
            <template v-if="$v.vote.strategy.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.strategy.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="process" class="col-form-label">
              Process - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="process" v-model="vote.process" @blur="$v.vote.process.$touch()"
              :class="{ 'parsley-error': $v.vote.process.$error }" :readonly="!editable"></textarea>
            <template v-if="$v.vote.process.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.process.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="market" class="col-form-label">
              Market - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="market" v-model="vote.market" @blur="$v.vote.market.$touch()" 
              :class="{ 'parsley-error': $v.vote.market.$error }" :readonly="!editable"></textarea>
            <template v-if="$v.vote.market.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.market.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="performance" class="col-form-label">
              Performance - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="performance" v-model="vote.performance" @blur="$v.vote.performance.$touch()"
                :class="{ 'parsley-error': $v.vote.performance.$error }" :readonly="!editable"></textarea>
            <template v-if="$v.vote.performance.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.performance.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="terms" class="col-form-label">
              Terms - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="terms" v-model="vote.terms" @blur="$v.vote.terms.$touch()"
              :class="{ 'parsley-error': $v.vote.terms.$error }" :readonly="!editable"></textarea>
            <template v-if="$v.vote.terms.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.terms.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="terms" class="col-form-label">
              ESG - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="terms" v-model="vote.esg" @blur="$v.vote.esg.$touch()"
              :class="{'parsley-error': $v.vote.esg.$error}" :readonly="!editable"></textarea>
            <template v-if="$v.vote.esg.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.esg.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>
      </div>

      <div  v-if="fundTypeId == 2">
          <div class="form-group">
            <label for="generalPartner" class="col-form-label">
              General Partner - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="generalPartner" v-model.trim="vote.generalPartner" @blur="$v.vote.generalPartner.$touch()"
                :class="{ 'parsley-error': $v.vote.generalPartner.$error}" :readonly="!editable"></textarea>
            <template v-if="$v.vote.generalPartner.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.generalPartner.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="trackRecords" class="col-form-label">
              Track Record - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="trackRecords" v-model.trim="vote.trackRecord" @blur="$v.vote.trackRecord.$touch()"
                :class="{ 'parsley-error': $v.vote.trackRecord.$error }" :readonly="!editable"></textarea>
            <template v-if="$v.vote.trackRecord.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.trackRecord.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="transactions" class="col-form-label">
              Transaction - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="transactions" v-model.trim="vote.transactions" @blur="$v.vote.transactions.$touch()"
                :class="{ 'parsley-error': $v.vote.transactions.$error}" :readonly="!editable"></textarea>
            <template v-if="$v.vote.transactions.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.transactions.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="portfolio" class="col-form-label">
              Portfolio - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="portfolio" v-model.trim="vote.portfolio" @blur="$v.vote.portfolio.$touch()"
                :class="{ 'parsley-error': $v.vote.portfolio.$error}" :readonly="!editable"></textarea>
            <template v-if="$v.vote.portfolio.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.portfolio.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="potentialReturns" class="col-form-label">
              Potential Returns - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="potentialReturns" v-model.trim="vote.potentialReturns" @blur="$v.vote.potentialReturns.$touch()"
                :class="{ 'parsley-error': $v.vote.potentialReturns.$error}" :readonly="!editable"></textarea>
            <template v-if="$v.vote.potentialReturns.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.potentialReturns.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>
      </div>

      <div v-if="fundTypeId == 3">
          <div class="form-group">
            <label for="generalPartner" class="col-form-label">
              General Partner - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="generalPartner" v-model.trim="vote.generalPartner" @blur="$v.vote.generalPartner.$touch()" :class="{ 'parsley-error': $v.vote.generalPartner.$error }"></textarea>
            <template v-if="$v.vote.generalPartner.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.generalPartner.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="company" class="col-form-label">
              Company - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="company" v-model.trim="vote.company" @blur="$v.vote.company.$touch()" :class="{ 'parsley-error': $v.vote.company.$error }"></textarea>
            <template v-if="$v.vote.company.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.company.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="transactions" class="col-form-label">
              Transaction - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="transactions" v-model.trim="vote.transactions" @blur="$v.vote.transactions.$touch()" :class="{ 'parsley-error': $v.vote.transactions.$error }"></textarea>
            <template v-if="$v.vote.transactions.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.transactions.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="valueCreationPlan" class="col-form-label">
              Value Creation Plan - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="valueCreationPlan" v-model.trim="vote.valueCreationPlan" @blur="$v.vote.valueCreationPlan.$touch()" :class="{ 'parsley-error': $v.vote.valueCreationPlan.$error }"></textarea>
            <template v-if="$v.vote.valueCreationPlan.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.valueCreationPlan.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="targetMarket" class="col-form-label">
              Target Market - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="targetMarket" v-model.trim="vote.targetMarket" @blur="$v.vote.targetMarket.$touch()" :class="{ 'parsley-error': $v.vote.targetMarket.$error }"></textarea>
            <template v-if="$v.vote.targetMarket.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.targetMarket.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>

          <div class="form-group">
            <label for="potentialReturns" class="col-form-label">
              Potential Returns - Request Discussion or DD
            </label>
            <textarea class="form-control" rows="2" id="potentialReturns" v-model.trim="vote.potentialReturns" @blur="$v.vote.potentialReturns.$touch()" :class="{ 'parsley-error': $v.vote.potentialReturns.$error }"></textarea>
            <template v-if="$v.vote.potentialReturns.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.vote.potentialReturns.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
          </div>
      </div>

      <div class="form-group">
        <label for="other" class="col-form-label">
          Other - Request Discussion or DD
        </label>
        <textarea class="form-control" rows="2" id="other" v-model="vote.other" @blur="$v.vote.other.$touch()"
            :class="{ 'parsley-error': $v.vote.other.$error }" :readonly="!editable"></textarea>
        <template v-if="$v.vote.other.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.vote.other.max" class="parsley-required">This value is too long</li>
          </ul>
        </template>
      </div>

      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
            <div class="form-group">
                <label>Created On</label>
                <AppDate :date="vote.createdOn" :show="'input'"/>
            </div>
            <div class="form-group">
                <label>Created By</label>
                <AppUser :userId="vote.createdBy" />
            </div>
            <div class="form-group" v-if="vote.updatedOn">
                <label>Modified On</label>
                <AppDate :date="vote.updatedOn" :show="'input'"/>
            </div>
            <div class="form-group" v-if="vote.updatedOn">
                <label>Modified By</label>
                <AppUser :userId="vote.updatedBy" :emptyIfNull="true"/>
            </div>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode && editable" class="btn btn-success  waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Save</button>
      </div>
      
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rightbar from '@/mixins/rightbar'
import { maxLength } from 'vuelidate/lib/validators'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    fundTypeId: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      showLogs: false
    }
  },
  mixins: [rightbar],
  validations: {
    vote: {
      organization: { max: maxLength(50000) },
      team: { max: maxLength(50000) },
      strategy: { max: maxLength(50000) },
      process: { max: maxLength(50000) },
      market: { max: maxLength(50000) },
      performance: { max: maxLength(50000) },
      terms: { max: maxLength(50000) },
      operation: { max: maxLength(50000) },
      fgrmc: { max: maxLength(50000) },
      esg: { max: maxLength(50000) },
      other: { max: maxLength(50000) },
      generalPartner: { max: maxLength(50000) },
      company: { max: maxLength(50000) },
      transactions: { max: maxLength(50000) },
      valueCreationPlan: { max: maxLength(50000) },
      targetMarket: { max: maxLength(50000) },
      potentialReturns: { max: maxLength(50000) },
      trackRecord: { max: maxLength(50000) },
      portfolio: { max: maxLength(50000) }
    }
  },
  methods: {
    ...mapActions(['updateFundRrVotings']),
    close () {
      this.hideRightbar()
    },
    onSave () {
      this.$v.vote.$touch()
      if (!this.$v.vote.$error) {
        if (this.vote.score === 'null') {
          this.vote.score = null
        }
        this.updateFundRrVotings({votingId: this.vote.votingId, fundVotings: this.vote})
          .then((response) => {
            this.$snotify.success('Vote Updated.')
            this.$emit('onUpdate')
          })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allTypes () {
      return this.$store.state.allvoteTypes
    },
    editable () {
      if (this.vote) {
        return this.isEditMode && this.vote.createdBy === this.user.userId
      }
    },
    vote () {
      return this.$store.state.rb_fundRrVoting
    }
  }
}
</script>
