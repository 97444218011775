<template>
  <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Portfolio Name</th>
          <th>Currency</th>
          <th>Allocation Request (M)</th>
          <th v-if="stageId > 4">Attributable Source</th>
          <th>Status</th>
          <th v-if="canEdit" class="action-col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="allocation in list" :key="allocation.allocationRationaleId">
          <td>
            <a href="javascript:void(0)" @click.prevent="select(allocation)">{{allocation.portfolioName}}</a>
          </td>
          <td>{{allocation.currencyCode}}</td>
          <td>{{allocation.currencySymbol}}{{allocation.allocationRequest}}</td>
          <td v-if="stageId > 4">
            {{allocation.isAttributable ? 'Yes' : '-'}}
          </td>
          <td>
            {{allocation.isFinalized ? 'Final Request' : allocation.allocationRationaleId > 0 ? 'Initial Request' : '-'}}
          </td>
          <td v-if="canEdit">
            <button v-if="!isEditMode" class="btn btn-success btn-sm btn-mini btn-icon" @click.prevent="update(allocation)"><i class="mdi mdi-lead-pencil"></i></button>
          </td>
        </tr>
      </tbody>
  </table>
</template>

<script>
export default {
  props: {
    canEdit: {
      required: true,
      type: Boolean,
      default: false
    },
    isEditMode: {
      required: true,
      type: Boolean,
      default: false
    },
    list: {
      required: true,
      type: Array,
      default: () => []
    },
    stageId: {
      required: true,
      type: Number,
      default: 0
    }
  },
  methods: {
    select (item) {
      this.$emit('onSelect', item)
    },
    update (item) {
      this.$emit('onSelectUpdate', item)
    }
  }
}
</script>
