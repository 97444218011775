import Vue from 'vue'
import Vuex from 'vuex'
import constants from '../constants'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeUser: null,
    allFundinactiveReasons: [],
    allContacts: [],
    allFirms: [],
    allFundtypes: [],
    allFundtires: [],
    allFundTeamRoles: [],
    allStrategies: [],
    allTreeStrategies: [],
    allSectors: [],
    allSubsectors: [],
    allGeoFocus: [],
    allCurrencies: [],
    allMonitoringtypes: [],
    allBoardseats: [],
    allMngfeestructures: [],
    allFundperformanceSource: [],
    allActivityTypes: [],
    allAnnualMeetingAttendances: [],
    allLegalRequestCategories: [],
    allLegalRequestStatuses: [],
    allOprInvestors: [],
    allOprServiceProviders: [],
    rb_activity: null,
    rb_file: null,
    rb_fundTeam: null,
    rb_industryFocus: null,
    rb_regionalFocus: null,
    rb_meritConcern: null,
    rb_fundOpportunity: null,
    rb_coInvestmentClient: null,
    rb_firmContact: null,
    rb_firmAnnualMeeting: null,
    rb_portfolioSector: null,
    rb_portfolioVehicle: null,
    rb_portfolioYear: null,
    rb_portfolioGeo: null,
    rb_portfolioInv: null,
    rb_secFund: null,
    rb_isNew: null,
    rb_fundFlrVoting: null,
    rb_fundRrVoting: null,
    rb_fundKeySenior: null,
    rb_fundOnsiteMeeting: null,
    rb_calanderDeal: null,
    rb_homeRightBar: null,
    // typo below
    rb_portfolioMmeber: null,
    rb_trackRecord: null,
    allUsers: [],
    allUserGroups: [],
    allUserRoles: [],
    allUserTitles: [],
    allOffices: [],
    allEmailTemplates: null,
    icUsers: {},
    maUsers: {},
    pmmrUsers: {},
    allFirmTypes: [],
    allSecOutreachTiers: [],
    allCountries: [],
    allFilePurposes: [],
    allIndustryFocus: [],
    allFundList: [],
    allFirmClientTypes: [],
    allFirmOwnershipTypes: [],
    allQuartiles: [],
    allCalMeetingTypes: [],
    fundsStratObj: {},
    fundsActiveFilters: {},
    allLockupTypes: [],
    allTimeWinows: [],
    allRedemptions: [],
    allSubscriptions: [],
    msalConfig: {
      auth: {
        clientId: constants.CLIENTID,
        authority: constants.AUTHORITY,
        redirectUri: constants.REDIRECT_URI,
        postLogoutRedirectUri: constants.REDIRECT_URI + '/#/logout'
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    },
    msalInstance: null,
    accessToken: ''
  },
  getters, // = computed
  actions, // = method
  mutations // set/get state
})
