export default {
  authUser (state) {
    return state.activeUser
  },
  getActivity (state) {
    return state.rb_activity
  },
  getFundTeam (state) {
    return state.rb_fundTeam
  },
  getAllUserGroups (state) {
    return state.allUserGroups
  },
  getAllUserRoles (state) {
    return state.allUserRoles
  },
  getAllUserTitles (state) {
    return state.allUserTitles
  },
  getAllOffices (state) {
    return state.allOffices
  }
}
