<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Slot - {{sector.sectorName}}
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="vehicle">
      <div class="form-group">
          <label for="investmentTypeId">Investment Type <span class="text-danger">*</span></label>
          <span :class="{ 'parsley-error-radio': $v.vehicle.investmentTypeId.$error }">
            <div class="custom-control custom-radio" v-if="hasType1 || clientType == 1">
              <input type="radio" id="type1" value=1 v-model="vehicle.investmentTypeId" name="type" class="custom-control-input">
              <label class="custom-control-label" for="type1">Primary</label>
            </div>
            <div class="custom-control custom-radio" v-if="clientType == 2 && hasType2">
              <input type="radio" id="type2" value=2 v-model="vehicle.investmentTypeId" name="type" class="custom-control-input">
              <label class="custom-control-label" for="type2">Secondary</label>
            </div>
            <div class="custom-control custom-radio" v-if="clientType == 2 && hasType3">
              <input type="radio" id="type3" value=3 v-model="vehicle.investmentTypeId" name="type" class="custom-control-input">
              <label class="custom-control-label" for="type3">Co-Investment</label>
            </div>
          </span>
          <template v-if="$v.vehicle.investmentTypeId.$error">
            <ul class="parsley-errors-list filled">
              <li class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>
      <div class="form-group">
        <label for="vintageYear">Vintage<span class="text-danger">*</span></label>
        <span :class="{ 'parsley-error-radio': $v.vehicle.vintageYear.$error }">
          <select class="form-control form-control-sm" v-model="vehicle.vintageYear">
              <option v-for="year in years" :key="year.vintageYear" :value="year.vintageYear">{{year.vintageYear}}</option>
          </select>
        </span>
        <template v-if="$v.vehicle.vintageYear.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.vehicle.vintageYear.required" class="parsley-required">This value is required</li>
          </ul>
        </template>
      </div>

      <div class="form-group">
          <label for="GeographyId">Geography<span class="text-danger">*</span></label>
          <span :class="{ 'invalid': $v.vehicle.geographicId.$error }">
            <multiselect id="GeographyId" v-model="geographicObj" :options="geos" label="geographicName" track-by="geographicId" :allow-empty="false" @input="geographySelected" :disabled="vehicle.geographyGlobal"></multiselect>
          </span>
          <span v-if="!hasGlobalGeo && clientType == 1">
            <label for="GeographyId">Global</label>
            <toggle-button v-model="vehicle.geographyGlobal" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" color="#3bafda" /> 
          </span>
          <template v-if="$v.vehicle.geographicId.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.vehicle.geographicId.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>

        <label>Default Values</label>
        <toggle-button v-model="vehicle.defaultValues" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="forceDefaultCheck" /> 
        <div v-show="!vehicle.defaultValues">
          <div class="form-group">
              <label for="targetPosition">Target Position Value<span class="text-danger">*</span></label>
              <div class="input-group">
                  <div class="input-group-prepend">
                      <span class="input-group-text">{{currency}}</span>
                  </div>
              <input type="text" v-model.number="vehicle.targetPosition" @keyup="calcPositionPerc" class="form-control" id="targetPosition" @blur="$v.vehicle.targetPosition.$touch()" :class="{ 'parsley-error': $v.vehicle.targetPosition.$error }">
              </div>
              <template v-if="$v.vehicle.targetPosition.$error">
                <ul class="parsley-errors-list filled">
                  <li v-if="!$v.vehicle.targetPosition.required" class="parsley-required">This value is required</li>
                  <li v-if="!$v.vehicle.targetPosition.decimal" class="parsley-required">This value is not valid.</li>
                  <li v-if="!$v.vehicle.targetPosition.min" class="parsley-required">This value is not a valid number.</li>
                </ul>
              </template>
          </div>
          <div class="form-group">
              <label for="targetPositionPerc">Target Position</label>
              <div class="input-group">
                  <div class="input-group-prepend">
                      <span class="input-group-text">%</span>
                  </div>
                  <input type="text" v-model="vehicle.targetPositionPerc" class="form-control" id="targetPositionPerc" readonly="readonly">
              </div>
          </div>
          <div class="form-group" v-if="totalCommitmentToday > 0">
              <label for="targetPositionPerc">Amount Raised to-date</label>
              <div class="input-group">
                  <div class="input-group-prepend">
                      <span class="input-group-text">%</span>
                  </div>
                  <input type="text" v-model="vehicle.targetPositionTodayPerc" class="form-control" id="targetPositionTodayPerc" readonly="readonly">
              </div>
          </div>
        </div>

      <!-- <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
            <div class="form-group">
                <label>Created On</label>
                <AppDate :date="vehicle.createdOn" :show="'input'"/>
            </div>
            <div class="form-group">
                <label>Created By</label>
                <AppUser :userId="vehicle.createdBy" />
            </div>
            <div class="form-group" v-if="vehicle.updatedOn">
                <label>Modified On</label>
                <AppDate :date="vehicle.updatedOn" :show="'input'"/>
            </div>
            <div class="form-group" v-if="vehicle.updatedOn">
                <label>Modified By</label>
                <AppUser :userId="vehicle.updatedBy" :emptyIfNull="true"/>
            </div>
        </div>
      </div> -->

      <div class="form-group mt-4">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</button>
      </div>
    </form>
    <span v-else>
      Loading...
      <span class="fa fa-spinner fa-spin mr-1" role="status"></span>
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { required, minValue, maxValue, numeric, decimal, requiredIf, helpers } from 'vuelidate/lib/validators'

const validateIf = (prop, validator) =>
  helpers.withParams({ type: 'validatedIf', prop }, function (value, parentVm) {
    return this.vehicle.defaultValues ? true : validator(value)
  })

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    },
    currency: {
      required: true,
      type: String
    },
    sector: {
      required: true,
      type: Object
    },
    totalCommitment: {
      required: true,
      type: Number
    },
    totalCommitmentToday: {
      required: true,
      type: Number
    },
    portfolioSectorId: {
      required: true,
      type: Number
    },
    clientType: {
      required: true,
      type: Number
    },
    years: {
      required: true,
      type: Array
    },
    geos: {
      required: true,
      type: Array
    },
    invs: {
      required: true,
      type: Array
    },
    forceDefaultCheck: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      showLogs: false,
      geographicObj: null
    }
  },
  mixins: [rightbar],
  validations: {
    vehicle: {
      investmentTypeId: { required, min: minValue(1), max: maxValue(3) },
      vintageYear: { required, numeric, min: minValue(1980) },
      geographicId: { required: requiredIf(function () { return !this.vehicle.geographyGlobal }) },
      targetPosition: { required: requiredIf(function () { return this.vehicle.defaultValues }), decimal, min: validateIf('dummy', minValue(0.001)) },
      targetPositionPerc: { required: requiredIf(function () { return this.vehicle.defaultValues }), decimal, max: maxValue(100) }
    }
  },
  methods: {
    ...mapActions(['fetchAllGeoFocus', 'addPortfolioSlot', 'updatePortfolioSlot']),
    close () {
      this.$v.vehicle.$reset()
      this.hideRightbar()
    },
    geographySelected (value, id) {
      this.$v.vehicle.geographicId.$touch()
      this.vehicle.geographicId = value ? value.geographicId : null
    },
    calcPositionPerc () {
      this.vehicle.targetPositionPerc = ((this.vehicle.targetPosition / this.totalCommitment) * 100).toFixed(1)
      this.vehicle.targetPositionTodayPerc = ((this.vehicle.targetPosition / this.totalCommitmentToday) * 100).toFixed(1)
    },
    onSave () {
      this.$v.vehicle.$touch()
      if (!this.$v.vehicle.$error) {
        if (this.isAdd) {
          this.addPortfolioSlot({portfolioSlot: this.vehicle})
            .then((response) => {
              if (response == null) {
                this.$snotify.error('Server Error!')
              } else {
                this.$snotify.success('Slot Added.')
                this.$emit('onUpdate')
              }
            })
        } else {
          this.updatePortfolioSlot({portfolioSlotId: this.vehicle.portfolioSlotId, portfolioSlot: this.vehicle})
            .then((response) => {
              this.$snotify.success('Slot Updated.')
              this.$emit('onUpdate')
            })
        }
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    }
  },
  created () {
  },
  computed: {
    vehicle () {
      const v = this.$store.state.rb_portfolioVehicle
      if (v != null) {
        this.geographicObj = this.geos.filter(x => x.geographicId === v.geographicId)
      }
      return v
    },
    hasGlobalGeo () {
      return this.geos.filter(x => x.geographicId === 4).length > 0
    },
    hasType1 () {
      return this.invs.filter(x => x.invTypeId === 1).length > 0
    },
    hasType2 () {
      return this.invs.filter(x => x.invTypeId === 2).length > 0
    },
    hasType3 () {
      return this.invs.filter(x => x.invTypeId === 3).length > 0
    }
  }
}
</script>
