<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portal-invites'"/>

        <div class="content-page">
            <div class="content">
                <div class="container-fluid">
                  <PageTitle :title="'Invites'" :icon="'mdi mdi-web'" />
                    <div class="row-md-12 ml-2 mr-2" >
                        <div class="card-box table-responsive">
                          <!-- Full Invite Table & Search -->
                          <div>
                            <h4 class='header-title'>INVITE LIST</h4>   
                              <div class="d-flex align-items-center mb-2">
                                <div class='d-flex align-items-center p-0'>
                                  <router-link :to="{name: 'PortalInvite'}" class="btn btn-primary mr-4"> Invite More Users</router-link>
                                  <input type="search" v-model="searchTerm" class="form-control" placeholder="Search by email" @keyup.enter="startSearch">
                                  <button @click.prevent="startSearch" class="btn btn-icon waves-effect waves-light btn-primary pr-3"><i class="fa fa-search"></i></button>
                                </div>
                                <div class="col">
                                  <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="activeSearch" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                </div>
                              </div>
                            <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                                <thead>
                                <tr>
                                    <th @click.prevent="setOrder(0)">Email Address
                                      <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                      <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                    </th>
                                    <th @click.prevent="setOrder(1)">Date Invited
                                      <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                      <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                    </th>
                                    <th @click.prevent="setOrder(2)">Invited By
                                      <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                      <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                  <tr v-if="isLoading">
                                    <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                  </tr>
                                  <tr v-for="invite in allSentInvites"
                                    :key="invite.emailId">
                                    <td>
                                      {{invite.emailAddress}}
                                    </td>
                                    <td>
                                      <AppDate :date="invite.createdOn" :show="'span'"/>
                                      </td>
                                    <td>
                                      <AppUser :userId="invite.createdBy" :show="'text'" :showpic="true"/>                        
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                          </div>
                          <ThePaginate 
                            @onPageResize='onPageResize' 
                            @onPageChanged='onPageChanged'
                            topic='Invites'
                            :pageSize='pageSize'
                            :currentPage='currentPage'
                            :totalPages='totalPages'
                            :totalRecords='totalRecords'
                          />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import asyncDataStatus from '@/mixins/asyncDataStatus'
import { mapGetters } from 'vuex'
import { PortalInviteService } from '@/services/portal-service'
import ThePaginate from '@/components/ThePaginate'

export default {
  data () {
    return {
      allSentInvites: [],
      textInput: '',
      currentPage: 1,
      sortField: ['emailAddress', 'CreatedOn', 'CreatedBy'],
      sortIndex: 0,
      sortType: 'ASC',
      isLoading: null,
      totalRecords: 0,
      totalPages: 0,
      searchTerm: '',
      pageSize: 25,
      activeSearch: false,
      searchTimeout: null
    }
  },
  components: {
    ThePaginate
  },
  mixins: [asyncDataStatus],
  methods: {
    onPageResize (size) {
      this.pageSize = size
      this.startSearch()
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
      this.activeSearch = this.searchTerm.length > 0
    },
    onPageChanged (pageNumber) {
      this.currentPage = pageNumber
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.allSentInvites = []
      if (this.searchTerm == null) {
        this.searchTerm = ''
      }

      this.searchTimeout = setTimeout(() => {
        this.getInviteList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    refreshCookie () {
      const options = {
        'searchTerm': this.searchTerm,
        'pageSize': this.pageSize,
        'sortType': this.sortType,
        'sortIndex': this.sortIndex
      }
      
      localStorage.setItem('umb_invites_options', JSON.stringify(options))
    },
    getInviteList () {
      this.isLoading = true
      this.refreshCookie()
      PortalInviteService.getList(this.searchTerm, this.sortField[this.sortIndex], this.sortType, this.currentPage, this.pageSize).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.allSentInvites = response.data.distributionList
              this.totalRecords = response.data.totalRecords
              this.totalPages = response.data.totalPages
              this.isLoading = false
            }
          }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    resetFilter () {
      this.searchTerm = ''
      this.startSearch()
      this.activeSearch = false
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    hasFilter () {
      return this.searchTerm.length > 0
    }
  },
  created () {
    const cookiedOptions = JSON.parse(localStorage.getItem('umb_invites_options'))
    if (cookiedOptions != null) {
      if (cookiedOptions.pageSize != null) {
        this.pageSise = cookiedOptions.pageSize
      }
      if (cookiedOptions.sortType != null) {
        this.sortType = cookiedOptions.sortType
      }
      if (cookiedOptions.searchTerm != null) {
        this.searchTerm = cookiedOptions.searchTerm
      }
      if (cookiedOptions.sortIndex != null) {
        this.sortIndex = cookiedOptions.sortIndex
      }
    }
    this.startSearch()
    this.asyncDataStatus_fetched('Navigator Invitation List')
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>