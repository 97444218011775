<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'firms-add'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Add Firm'" :icon="'mdi mdi-factory'" />
                    <div class="card-box">
                      <div class="row">
                        <div class="col-lg-6">
                          <h4 class="header-title m-t-0">Add a New Firm</h4>
                          <p class="text-muted font-14 m-b-20">
                              Enter firm information.
                          </p>

                          <form action="#" autocomplete="off">
                            <div class="form-group">
                                <label for="firmName">Name<span class="text-danger">*</span></label>
                                <input type="text" v-model="firm.firmName" @blur="$v.firm.firmName.$touch()" placeholder="Enter Name" class="form-control" id="firmName" :class="{ 'parsley-error': $v.firm.firmName.$error }">
                                <template v-if="$v.firm.firmName.$error">
                                  <ul class="parsley-errors-list filled">
                                    <li v-if="!$v.firm.firmName.required" class="parsley-required">This value is required</li>
                                    <li v-if="!$v.firm.firmName.min" class="parsley-required">This value is too short</li>
                                    <li v-if="!$v.firm.firmName.max" class="parsley-required">This value is too long</li>
                                  </ul>
                                </template>
                            </div>
                            <div class="form-group">
                                <label for="typeId">Type<span class="text-danger">*</span></label>
                                <span :class="{ 'parsley-error-radio': $v.firm.typeId.$error }">
                                  <div class="custom-control custom-radio">
                                    <input type="radio" id="type1" value=1 v-model="firm.typeId" name="type" class="custom-control-input">
                                    <label class="custom-control-label" for="type1">General Partner</label>
                                  </div>
                                  <div class="custom-control custom-radio">
                                    <input type="radio" id="type2" value=2 v-model="firm.typeId" name="type" class="custom-control-input">
                                    <label class="custom-control-label" for="type2">Client</label>
                                  </div>
                                  <div class="custom-control custom-radio">
                                    <input type="radio" id="type3" value=3 v-model="firm.typeId" name="type" class="custom-control-input">
                                    <label class="custom-control-label" for="type3">Placement Agent</label>
                                  </div>
                                </span>
                                <template v-if="$v.firm.typeId.$error">
                                  <ul class="parsley-errors-list filled">
                                    <li v-if="!$v.firm.typeId.required" class="parsley-required">This value is required</li>
                                  </ul>
                                </template>
                            </div>
                            <div class="form-group">
                                <div class="checkbox checkbox-primary">
                                    <input id="checkbox6a" type="checkbox" v-model="addMore">
                                    <label for="checkbox6a">
                                        Add Another
                                    </label>
                                </div>
                            </div>

                            <div class="form-group text-right m-b-0">
                              <button type="reset" class="btn btn-secondary waves-effect m-l-5 mr-2" @click.prevent="reset">
                                  Reset
                              </button>
                              <button class="btn btn-primary waves-effect waves-light" type="submit" @click.prevent="save" v-if="user.groupId < 11">
                                  Add Firm
                              </button>
                            </div>
                        </form>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { required, minLength, maxLength, minValue } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { FirmService } from '@/services/firm-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
    Multiselect
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      addMore: false,
      firm: {firmName: '', typeId: null},
      firmTypeObj: null
    }
  },
  validations: {
    firm: {
      firmName: { required, min: minLength(3), max: maxLength(100) },
      typeId: { required, min: minValue(1) }
    }
  },
  methods: {
    ...mapActions(['fetchAllFirmTypes']),
    save () {
      this.$v.firm.$touch()
      if (!this.$v.firm.$error) {
        FirmService.add(this.firm).then(
          (response) => {
            this.$snotify.success('Firm Created.')
            if (!this.addMore) {
              setTimeout(() => {
                this.$router.push({name: 'FirmsDetail', params: {id: response.data.firmId}})
              }, 500)
            } else {
              this.reset()
            }
          })
          .catch(function (error) {
            console.error('SERVER Error:', error)
            // this.$snotify.error('SERVER Errors.' + error)
          })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    reset () {
      this.firm = {firmName: '', typeId: null}
      this.firmTypeObj = null
      this.$v.$reset()
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allFirmTypes () {
      return this.$store.state.allFirmTypes
    }
  },
  created () {
    this.fetchAllFirmTypes()
    this.asyncDataStatus_fetched('Add a new Firm')
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
