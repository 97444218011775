import Constants from '../constants.js'
import Axios from 'axios'

export const PortfolioService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/portfolio/' + id)
  },
  getVehicles (id) {
    return Axios.get(Constants.API_URL + '/portfolio/vehicles/' + id)
  },
  getVehicle (id) {
    return Axios.get(Constants.API_URL + '/portfolio/vehicle/' + id)
  },
  getChart (id) {
    return Axios.get(Constants.API_URL + '/portfolio/chart/' + id)
  },
  search (name, clientTypeId, onlyOpen, userId, sortField, sortType, pageNumber, pageSize) {
    
    if(clientTypeId==0){
      clientTypeId=null
    }
    if(userId==0){
      userId=null
    }
    if(onlyOpen === false ){
      onlyOpen = null
    }
    return Axios.get(Constants.API_URL + '/portfolio/search?name=' + name + '&clientTypeId=' + clientTypeId + '&onlyOpen=' + onlyOpen + '&userId=' + userId + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  searchVehicles (name, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/portfolio/search-vehicles?name=' + name + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio/' + id, value)
  },
  add (portfolio) {
    return Axios.post(Constants.API_URL + '/portfolio', portfolio)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/portfolio/' + id)
  },
  checkBeforeUpdate (value) {
    return Axios.post(Constants.API_URL + '/portfolio/check-percent', value)
  },
  reportSummary (year, typeId) {
    return Axios.get(Constants.API_URL + '/portfolio/availability-slots/' + typeId + '/' + year)
  },
  screeningReport () {
    return Axios.get(Constants.API_URL + '/portfolio/screening/')
  },
  calculateRisk (payload) {
    return Axios.post(Constants.API_URL + '/portfolio/risk-score', payload)
  },
}

export const PortfolioSectorService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/portfolio-sector/' + id)
  },
  getSingle (id, sector) {
    return Axios.get(Constants.API_URL + '/portfolio-sector/single/' + id + '/' + sector)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio-sector/' + id, value)
  },
  add (portfolioSector) {
    return Axios.post(Constants.API_URL + '/portfolio-sector', portfolioSector)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/portfolio-sector/' + id)
  }
}

export const PortfolioSlotService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/portfolio-slot/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio-slot/' + id, value)
  },
  add (portfolioSlot) {
    return Axios.post(Constants.API_URL + '/portfolio-slot', portfolioSlot)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/portfolio-slot/' + id)
  },
  assign (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio-slot/assign/' + id, value)
  },
  remove (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio-slot/remove/' + id, value)
  },
  approveAdvisory (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio-slot/approve-advisory/' + id, value)
  },
  approveOpsConfirm (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio-slot/approve-opr/' + id, value)
  },
  reopenSlot (id, payload) {
    return Axios.put(Constants.API_URL + '/portfolio-slot/reopen-slot/' + id, payload)
  }
}

export const PortfolioYearService = {
  getByPortolio (id) {
    return Axios.get(Constants.API_URL + '/portfolio-year/' + id)
  },
  getSingle (id, year) {
    return Axios.get(Constants.API_URL + '/portfolio-year/single/' + id + '/' + year)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio-year/' + id, value)
  },
  add (portfolioYear) {
    return Axios.post(Constants.API_URL + '/portfolio-year', portfolioYear)
  },
  delete (id, year) {
    return Axios.delete(Constants.API_URL + '/portfolio-year/' + id + '/' + year)
  }
}

export const PortfolioGeoService = {
  getSingle (id, geoId) {
    return Axios.get(Constants.API_URL + '/portfolio-geo/single/' + id + '/' + geoId)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio-geo/' + id, value)
  },
  add (portfolioGeo) {
    return Axios.post(Constants.API_URL + '/portfolio-geo', portfolioGeo)
  },
  delete (id, geoId) {
    return Axios.delete(Constants.API_URL + '/portfolio-geo/' + id + '/' + geoId)
  }
}

export const PortfolioInvService = {
  getSingle (id, invId) {
    return Axios.get(Constants.API_URL + '/portfolio-inv/single/' + id + '/' + invId)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio-inv/' + id, value)
  },
  add (portfolioInv) {
    return Axios.post(Constants.API_URL + '/portfolio-inv', portfolioInv)
  },
  delete (id, invId) {
    return Axios.delete(Constants.API_URL + '/portfolio-inv/' + id + '/' + invId)
  }
}

export const PortfolioVotingService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/portfolio-voting/' + id)
  },
  getList (id) {
    return Axios.get(Constants.API_URL + '/portfolio-voting/list/' + id)
  },
  add (id, value) {
    return Axios.post(Constants.API_URL + '/portfolio-voting/' + id, value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/portfolio-voting/' + id)
  },
  showVotes (id) {
    return Axios.post(Constants.API_URL + '/portfolio-voting/display/' + id)
  },
  finalize (id) {
    return Axios.post(Constants.API_URL + '/portfolio-voting/finalize/' + id)
  }
}

export const PortfolioVotingResultService = {
  check (id) {
    return Axios.get(Constants.API_URL + '/portfolio-voting-result/check/' + id)
  },
  save (id, value) {
    return Axios.post(Constants.API_URL + '/portfolio-voting-result/' + id, value)
  },
  answer (id, value) {
    return Axios.post(Constants.API_URL + '/portfolio-voting-result/answer/' + id, value)
  }
}

export const PortfolioTeamService = {
  add (id, value) {
    return Axios.post(Constants.API_URL + '/portfolio/team/' + id, value)
  },
  remove (id, value) {
    return Axios.put(Constants.API_URL + '/portfolio/team/' + id, value)
  }
}

export const PortfolioMonitoringService = {
  get({portfolioId, quarter, year}) {
    return Axios.get(Constants.API_URL + '/portfoliomonitoring/portfolio/' + portfolioId + '/' + quarter + '/' + year)
  },
  add(value) {
    return Axios.post(Constants.API_URL + '/portfoliomonitoring', value)
    
  },
  save(monitoringId, value) {
      return Axios.put(Constants.API_URL + '/portfoliomonitoring/' + monitoringId, value)
  }
}


