<template>
  <radar-chart :radardata="chartData" style="max-width: 500px;"></radar-chart>
</template>

<script>
  import RadarChart from '@/components/charts/radarBase.js'

  export default {
    props: {
      chartData: {
        type: Object,
        required: true
      }
    },
    components: {
      RadarChart
    }
  }
</script>