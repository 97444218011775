<template>
  <!-- Page-Title -->
  <div class="row">
    <div class="col-sm-12">
      <div class="page-title-box">
          <h4 class="page-title"><i class="mdi mdi-square-inc-cash" ></i> {{title}} </h4>
          
          <ol class="breadcrumb float-right">
            <li>
              <button class="btn-sm btn-icon waves-effect waves-light btn-primary m-b-0" :disabled="inEditMode" @click="onPin" v-if="pinId == 0"> <i class="mdi mdi-pin" v-tooltip="'Pin'"></i> </button>
              <button class="btn-sm btn-icon waves-effect waves-light btn-primary m-b-0" :disabled="inEditMode" @click="onPinOff" v-if="pinId > 0"> <i class="mdi mdi-pin-off" v-tooltip="'Unpin'"></i> </button>
            </li>
          </ol>
          <div class="clearfix"></div>
      </div>
      <div class="mb-3">
        <button class="btn btn-primary btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="inEditMode" @click="onCancel"><i class="mdi mdi-backup-restore"></i> <span>Cancel</span></button>
        <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5 mr-2" :disabled="!canEdit" v-show="!inEditMode" @click="onEdit"><i class="mdi mdi-lead-pencil m-r-5"></i> <span>Edit</span></button>
        <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5 mr-2" @click="onSave" v-show="inEditMode" :disabled="isSaving"><i class="mdi mdi-content-save m-r-5"></i> <span v-if="!isSaving">Save</span> <i v-else class="fa fa-spinner fa-spin"></i></button>
        <button class="btn btn-danger btn-custom waves-effect w-md waves-light m-b-5 mr-2" :disabled="inEditMode" v-if="canDelete" @click="onDelete"><i class="mdi mdi-delete m-r-5"></i> <span>Delete</span></button>
        <button class="btn btn-warning btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="canStartScreening" :disabled="inEditMode" @click="onStartScreening"> <i class="mdi mdi-play-box-outline m-r-5"></i> <span>Start Screening </span> </button>
        <button class="btn btn-warning btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="canStartFlr" :disabled="inEditMode" @click="onStartFlr"> <i class="mdi mdi-play-box-outline m-r-5"></i> <span>Start DD </span> </button>
        <button class="btn btn-primary btn-custom waves-effect w-md waves-light m-b-5 mr-2" :disabled="inEditMode" v-if="canEdit" @click="onNew"> <i class="mdi mdi-note-plus m-r-5"></i> <span>Add Next</span> </button>
        <router-link :to="{name: 'FlrSummary', params: {id: fundId}}" class="btn btn-light btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-if="showFlrVoting && stageId === 3 && !inEditMode">
            <i class="mdi mdi-thought-bubble"></i> FLR Summary
        </router-link>
        <router-link :to="{name: 'RrSummary', params: {id: fundId}}" class="btn btn-light btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-if="showRrVoting && stageId === 4 && !inEditMode">
            <i class="mdi mdi-thought-bubble-outline"></i> RR Summary
        </router-link>
        <router-link :to="{name: 'Allocation', params: {id: fundId}}" class="btn btn-warning btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-if="showAR && stageId >= 3 && !inEditMode">
            <i class="mdi mdi-folder-star"></i> Wilshire Allocation
        </router-link>
        <button class="btn btn-warning btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="canStartAr" :disabled="inEditMode" @click="onStartAr"> <i class="mdi mdi-play-box-outline m-r-5"></i> <span>Start Allocation</span> </button>
        
        <a :href="reportUrl" class="pull-right btn btn-primary btn-custom waves-effect w-md waves-light m-b-5 mr-2" target="_blank" v-if="showIs && !inEditMode">
            <i class="mdi mdi-newspaper"></i> IS Report
        </a>
        <button class="pull-right btn btn-secondary btn-custom waves-effect w-md waves-light m-b-5 mr-2" :disabled="inEditMode" v-if="showDDButtons && !inEditMode" @click="onOperationDDClicked"> 
          Operations DD <span class="badge badge-pill badge-info text-inverse">{{this.operationsDDStatus == null || this.operationsDDStatus == '' ? 'Initiate': this.operationsDDStatus}}</span>
        </button>
        <button class="pull-right btn btn-secondary btn-custom waves-effect w-md waves-light m-b-5 mr-2" :disabled="inEditMode" v-if="showDDButtons && !inEditMode" @click="onLegalDDClicked">
           Legal DD <span class="badge badge-pill badge-info text-inverse">{{this.legalDDStatus == null || this.legalDDStatus == '' ? 'Initiate': this.legalDDStatus}}</span>
        </button>        
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Constants from '@/constants'

export default {
  props: {
    title: {
      type: String,
      default: 'No Title'
    },
    fundId: {
      required: true,
      type: Number,
      default: 0
    },
    stageId: {
      required: true,
      type: Number,
      default: 0
    },
    showFlrVoting: {
      required: true,
      type: Boolean,
      default: false
    },
    showAR: {
      required: true,
      type: Boolean,
      default: false
    },
    showRrVoting: {
      required: true,
      type: Boolean,
      default: false
    },
    showIs: {
      required: true,
      type: Boolean,
      default: false
    },
    pinId: {
      type: Number,
      default: 0
    },
    inEditMode: {
      required: false,
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canStartScreening: {
      type: Boolean,
      default: false
    },
    canStartFlr: {
      type: Boolean,
      default: false
    },
    canStartAr: {
      type: Boolean,
      default: false
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    showDDButtons: {
      required:false,
      type: Boolean,
      default: false
    },
    legalDDStatus : {
      required: false,
      type: String,
      default: 'Initiate'
    },
    legalDDRequestId : {
      required: false,
      type: Number,
      default: 0
    },
    operationsDDStatus : {
      required: false,
      type: String,
      default: 'Initiate'
    },
    operationsDDRequestId : {
      required: false,
      type: Number,
      default: 0
    }
  },
  methods: {
    onEdit () {
      this.$emit('onEdit')
    },
    onNew () {
      this.$emit('onNew')
    },
    onStartScreening () {
      this.$emit('onStartScreening')
    },
    onStartFlr () {
      this.$emit('onStartFlr')
    },
    onStartAr () {
      this.$emit('onStartAr')
    },
    onCancel () {
      this.$emit('onCancel')
    },
    onSave () {
      this.$emit('onSave')
    },
    onDelete () {
      this.$emit('onDelete')
    },
    onPin () {
      this.$emit('onPin')
    },
    onPinOff () {
      this.$emit('onPinOff')
    },
    onLegalDDClicked () {
      this.$emit('onLegalDDClicked')
    },
    onOperationDDClicked () {
      this.$emit('onOperationDDClicked')
    }    
  }, 
  computed: {
    ...mapGetters({
      user: 'authUser'
    }),
    reportUrl () {
      return Constants.REPORT_SERVER + 'IS&rs:Command=Render&FundId=' + this.fundId
    }    
  }
}
</script>
