<template>
  <tbody>
    <tr v-if="opportunities === null">
      <td colspan="2" class="text-center">No Data to show</td>
    </tr>
    <tr v-for="opportunity in opportunities"
        :key="opportunity.fundsIndustryFocusId">
        <td><a @click.prevent="select(opportunity.fundOpportunitySetId)" href="javascript:void(0)">{{opportunity.targetFundName}}</a></td>
        <td>
            <button class="btn btn-danger btn-sm btn-mini btn-icon" v-if="canEdit" @click.prevent="onDelete(opportunity.fundOpportunitySetId)" v-tooltip="'Delete'"><i class="mdi mdi-delete"></i></button>
        </td>
    </tr>
    <tr>
      <td colspan="2">
        <button v-if="!isEditMode && canEdit" class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addNew"> <i class="mdi mdi-link-variant"></i> Add</button>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rightbar from '@/mixins/rightbar'

export default {
  props: {
    opportunities: {
      type: Array,
      default: null
    },
    isEditMode: {
      required: true,
      type: Boolean
    },
    canEdit: {
      default: false,
      type: Boolean
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['deleteFundOpportunitySet']),
    addNew () {
      this.hideRightbar()
      this.$emit('onClickNew')
    },
    select (id) {
      this.$emit('onSelect', id)
    },
    onDelete (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deleteFundOpportunitySet({fundOpportunitySetId: id})
                .then((response) => {
                  this.$snotify.success('Opportunity Set Deleted.')
                  this.$emit('onUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  }
}
</script>
