<template>
    <div class="account-pages mt-5 mb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card">
                        <div class="card-body p-4">                            
                            <div class="text-center w-85 m-auto">
                                <div class="text-center">
                                    <a class="logo-lg">
                                        <i class="mdi mdi-umbrella"></i>
                                        <span>Umbrella</span>
                                    </a>
                                </div>                                
                            </div>                            
                            <h4>You have successfully logout. Please close this browser session.</h4>                            
                            <div class="buttons-con">
                                <div class="action-link-wrap">
                                    <a href="/" class="btn btn-custom btn-primary waves-effect waves-light m-t-20">Go to Login Page</a>
                                </div>
                            </div>
                        </div> 
                        <!-- end card-body -->
                        <p class="text-center mt-3">
                            {{year}} &copy; Wilshire Private Markets ❤️<br/>Version: {{version}}
                        </p>
                    </div>
                    <!-- end card -->
                    <!-- end row -->
                </div> <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
</template>

<script>
import store from '@/store'
import Constants from '@/constants'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  data () {
    return {
      errorMessage: null,
      isLoading: false,
      year: new Date().getFullYear(),
      version: Constants.VERSION
    }
  },
  mixins: [asyncDataStatus],
  created () {
    this.$cookies.remove('auth_token')
    store.commit('setActiveUser', null)
    sessionStorage.clear()
    this.asyncDataStatus_fetched('Logout')
  }
}
</script>
