<template>
    <div>
        <Topbar />
        <Sidebar/>

        <div class="content-page">
            <div class="content">
                <div class="container-fluid">

                    <PageTitle :title="'Release Notes'"/>
                    Release Notes...
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
  },
  mixins: [asyncDataStatus],
  created () {
    this.asyncDataStatus_fetched('Release Notes')
  }
}
</script>
