import Constants from '../constants.js'
import Axios from 'axios'

export const LegalService = {
  search (categoryId, requestorId, deadline, statusId, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/legalrequest/search?categoryId=' + categoryId + '&requestorId=' + requestorId + '&deadline=' + deadline + '&statusId=' + statusId + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  getDashboardList () {
    return Axios.get(Constants.API_URL + '/legalrequest/dashboard-list')
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/legalrequest', id)
  },
  updateStatus (id, value) {
    return Axios.put(Constants.API_URL + '/legalrequest/status/' + id, value)
  },
  getStatusUpdateList () {
    return Axios.get(Constants.API_URL + '/legalrequest/statusupdates')
  },
  updateAssign (id, value) {
    return Axios.put(Constants.API_URL + '/legalrequest/assign/' + id, value)
  }
}

export const LegalNdaService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/legalrequest/nda/' + id)
  },
  add (request) {
    return Axios.post(Constants.API_URL + '/legalrequest/nda', request)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/legalrequest/nda/' + id, value)
  }
}

export const LegalAcaService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/legalrequest/aca/' + id)
  },
  add (request) {
    return Axios.post(Constants.API_URL + '/legalrequest/aca', request)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/legalrequest/aca/' + id, value)
  }
}

export const LegalWffService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/legalrequest/wff/' + id)
  },
  add (request) {
    return Axios.post(Constants.API_URL + '/legalrequest/wff', request)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/legalrequest/wff/' + id, value)
  }
}

export const LegalWfeService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/legalrequest/wfe/' + id)
  },
  add (request) {
    return Axios.post(Constants.API_URL + '/legalrequest/wfe', request)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/legalrequest/wfe/' + id, value)
  }
}

export const LegalIfcService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/legalrequest/ifc/' + id)
  },
  add (request) {
    return Axios.post(Constants.API_URL + '/legalrequest/ifc', request)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/legalrequest/ifc/' + id, value)
  }
}

export const LegalIcaService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/legalrequest/ica/' + id)
  },
  add (request) {
    return Axios.post(Constants.API_URL + '/legalrequest/ica', request)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/legalrequest/ica/' + id, value)
  }
}

export const LegalKycService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/legalrequest/kyc/' + id)
  },
  add (request) {
    return Axios.post(Constants.API_URL + '/legalrequest/kyc', request)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/legalrequest/kyc/' + id, value)
  }
}

export const LegalMicService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/legalrequest/mic/' + id)
  },
  add (request) {
    return Axios.post(Constants.API_URL + '/legalrequest/mic', request)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/legalrequest/mic/' + id, value)
  }
}

export const LegalStatusUpdateService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/legal-statusupdates/' + id)
  },
  add (id, value) {
    return Axios.post(Constants.API_URL + '/legal-statusupdates/' + id, value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/legal-statusupdates/' + id)
  }
}
