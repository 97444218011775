<template>
  <tbody>
    <tr v-if="clientFeedbackList === null">
      <td colspan="4" class="text-center">No client feedback to show</td>
    </tr>
    <tr v-for="client in clientFeedbackList" :key="client.coInvestmentClientId">
        <td><a @click.prevent="select(client.interestId)" href="javascript:void(0)">{{client.organizationName}}</a></td>
        <td><AppDate :date="client.createdOn" :show="'span'"/></td>
        <td>{{client.statusName}}</td>
    </tr>
  </tbody>
</template>

<script>
import rightbar from '@/mixins/rightbar'

export default {
  props: {
    clientFeedbackList: {
      type: Array,
      default: null
    }
  },
  mixins: [rightbar],
  methods: {
    select (id) {
      this.$emit('onSelect', id)
    }
  }
}
</script>
