<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'calmeeting-view'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Meetings Calendar'" :icon="'mdi mdi-timetable'"/>
                    <div class="card-box">
                        <div class="row m-b-10">
                            <div class="col-sm-12 col-md-6 form-inline">
                              <label class="col-form-label ml-2">
                                Only Invited
                                <toggle-button v-model="onlyInvited" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="submitSearch"/>
                              </label>
                              <label class="col-form-label ml-2">
                                Only Attending
                                <toggle-button v-model="onlyAttending" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="submitSearch"/>
                              </label>
                            </div>
                            <div class="col-sm-12 col-md-6">
                              <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                              <multiselect id="fundId" class="col-md-6" v-model="dealObj" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to search Deals"
                                @input="submitSearch" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                            </div>
                        </div>
                        <v-calendar is-expanded is-dark
                          class="custom-calendar max-w-full" 
                          :attributes="attrs" 
                          :masks="masks"
                          :first-day-of-week="2"                                             
                          @update:from-page="getMonth"
                          >
                          <div slot="day-content"
                            slot-scope="attrs"
                            class="flex flex-col h-full z-10 overflow-hidden" @click="dayClicked(attrs.day)">
                            <span class="day-label rounded-sm today-bg" v-if="attrs.day.isToday">Today</span>
                              <span class="day-label" v-else>{{ attrs.day.day }}</span>
                            <div class="flex-grow overflow-x-auto">
                              <div  v-for="dayEvent in attrs.attributes" :key="dayEvent.key"  >
                                <span class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1" :style="'background-color:' + dayEvent.customData.bgcolor + ';color: ' + dayEvent.customData.color">
                                  {{dayEvent.customData.title}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </v-calendar>
                      <div class="mt-2">
                        <span class="badge  mr-2" style="backgroundColor: orange; color: black;">Today</span>
                        <span class="badge stage3" style="color: white;">Meeting</span>
                        <span v-show="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
                      </div>
                      <div>
                        <div v-if="selectedDay" class='cal-selected-day mt-2'>
                            <h4>{{ selectedDay.date.toDateString() }}</h4>
                            <ul>
                                <li v-for="attr in selectedDay.attributes" :key="attr.key">
                                  <div v-if="attr.customData != null">
                                    <router-link :to="{name: 'CalendarDetail', params: {id: attr.customData.id}}" target="_blank" v-tooltip="'Open details'">
                                      <span class="badge badge-pill stage3">&nbsp;</span>
                                        {{ attr.customData.title }} <i class="mdi mdi-open-in-new"></i>
                                    </router-link>
                                  </div>
                                </li>
                            </ul>
                        </div>
                        <div v-else class='cal-selected-day mt-2'>
                          <h4>No Date Selected</h4>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { CalMeetingService } from '@/services/calmeeting-service'
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'

const color = '#fafafa'
const bgColor = '#333333'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      masks: {
        weekdays: 'WWW',
      },
      onlyInvited: false,
      onlyAttending: false,
      dealObj: null,
      isLoading: null,
      currentCal: null,
      loadedCal: [],
      selectedDay: null,
      isSearchingList: false,
      searchFunds: [],
      attrs: []
    }
  },
  mixins: [asyncDataStatus],
  created () {
    const dt = new Date()
    const pg1 = {key: dt.getFullYear().toString() + '.' + (dt.getMonth() + 1).toString(), year: dt.getFullYear(), month: dt.getMonth() + 1}
    //this.getMonth(pg1)
    this.asyncDataStatus_fetched('Meetings Calendar')
  },
  methods: {
    ...mapActions(['searchAllFunds']),
    dayClicked (day) {
      this.selectedDay = day
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    },
    submitSearch () {
      this.attrs = []
      this.loadedCal = []
      this.selectedDay = null
      const pg1 = {key: this.currentCal.year.toString() + '.' + this.currentCal.month.toString(), year: this.currentCal.year, month: this.currentCal.month}
      this.getMonth(pg1)
    },
    getMonth (page) {
      this.currentCal = {year: page.year, month: page.month}
      this.selectedDay = null
      if (this.loadedCal.indexOf(page.month) < 0) {
        this.isLoading = true
        const attendeeId = this.onlyAttending ? this.user.userId : 0
        const userId = this.onlyInvited ? this.user.userId : 0
        let fund = 0
        if (this.dealObj != null) {
          fund = this.dealObj.fundId
        }
        CalMeetingService.calendar(page.year, page.month, attendeeId, userId, fund)
        .then((response) => {
          this.loadedCal.push(page.month)
          response.data.forEach(cal => {
            this.addDate(page, cal)
          })
          this.isLoading = false
        }).catch(error => this.$snotify.error('Server Error! ' + error.message))
      }
    },
    addDate (pg, cal) {
      this.attrs.push(
        {
          key: 'event-' + pg.month + cal.meetingId,
          customData: {
            id: cal.meetingId,
            title: cal.title,
            bgcolor: '#0071c1',
            color: 'stage3'
          },
          dates: [new Date(cal.startTime)]
        })
    },
    resetFilter () {
      this.onlyInvited = false
      this.onlyAttending = false
      this.dealObj = null
      this.submitSearch()
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    hasFilter () {
      return this.onlyInvited || this.onlyAttending || this.dealObj != null
    }
  }
}
</script>



<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
