<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Portfolio Management Team
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="fund.Employee">Employee<span v-show="isAdd" class="text-danger">*</span></label>
          <multiselect id="fund.Employee" v-show="!isEditMode" :disabled="!isEditMode" v-model="userObj" :options="allUsers" label="fullName" track-by="userId"></multiselect>
          <multiselect id="fund.Employee" v-show="isEditMode" :disabled="!isAdd" v-model="userObj2" :options="allActiveUsers" label="fullName" track-by="userId" :allow-empty="false" @input="userSelected"></multiselect>
      </div>

      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="member.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="member.createdBy" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light  btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success  waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Add</button>
      </div>
    </form>
    <!-- <div v-if="isEditMode">
      <h5>Deal Workload <i class="mdi mdi-information-outline" v-tooltip="'Number of active Deals per member'"></i></h5>
      <span v-show="isLoadingStat">Loading <i class="fa fa-spinner fa-spin"></i></span>
      <div class="progress progress-md m-b-20" v-for="stat in activeStatList" :key="stat">
        <div class="progress-bar" role="progressbar" :style="{ width: stat.cnt + '%' }">{{stat.userName}} ({{stat.stat}})</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { PortfolioTeamService } from '@/services/portfolio-service'

export default {
  props: {
    activeMembers: {
      type: Array,
      default: null
    },
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      showLogs: false,
      userObj2: null,
      isLoadingStat: false,
      activeStatList: null,
      mustLoadstat: true
    }
  },
  mixins: [rightbar],
  methods: {
    close () {
      this.hideRightbar()
    },
    userSelected (value, id) {
      this.member.userId = value ? value.userId : null
    },
    // getActiveStat () {
    //   if (this.mustLoadstat) {
    //     this.isLoadingStat = true
    //     FundTeamService.getActiveStat()
    //       .then((response) => {
    //         this.isLoadingStat = false
    //         const list = response.data
    //         this.activeStatList = []
    //         const mx = Math.max(...list.map(x => x.stat))
    //         for (let i = 0; i < list.length; i++) {
    //           this.activeStatList.push({userName: list[i].userFullName, cnt: list[i].stat * 100 / mx, stat: list[i].stat})
    //         }
    //       })
    //   }
    // },
    onSave () {
      const i = this.activeMembers.findIndex(x => x.userId === this.member.userId)
      if (i >= 0) {
        this.$snotify.warning('Member already exists!')
        return
      }
      const mem = {portfolioId: this.member.portfolioId, userId: this.member.userId, createdBy: 0, createdOn: new Date()}
      PortfolioTeamService.add(this.member.portfolioId, mem)
        .then((response) => {
          if (response == null) {
            this.$snotify.error('Server Error!')
          } else {
            this.$snotify.success('Member Added.')
            this.$emit('onUpdate', mem)
          }
        })
      // this.getActiveStat()
    }
  },
  created () {
    // this.getActiveStat()
  },
  computed: {
    member () {
      return this.$store.state.rb_portfolioMmeber
    },
    allUsers () {
      return this.$store.state.allUsers.filter(x => x.userId > 0)
    },
    allActiveUsers () {
      return this.$store.state.allUsers.filter(u => u.isActive)
    },
    userObj () {
      if (this.member != null) {
        const user = this.allUsers.filter(t => t.userId === this.member.userId)
        this.userObj2 = user
        return user
      } else {
        return null
      }
    }
  }
}
</script>
