<template>
  <div>
    <div
      class="tab-pane fade"
      :class="{ 'active show': inEditMode ? activeTab == 1 : compareTabToggle ? activeTab == 3 : activeTab == 2 }"
    >
      <div class="row">
        <!-- whole bottom -->
        <div class="col-lg-6">
          <!-- left col bottom -->
          <span>
            <div class="form-group">
              <label for="strategyObj" class="col-form-label"
                >Strategy
                <span v-show="inEditMode" class="text-danger">*</span></label
              >
              <multiselect
                id="strategyObj"
                :class="{ 'updated_multi': fundUmbrella != null && fund.dealCurrencyId !== fundUmbrella.dealCurrencyId && !inEditMode }"
                v-model="strategyObj"
                :options="allStrategies"
                label="fullName"
                track-by="modelId"
                :allow-empty="false"
                :disabled="!inEditMode"
               />
            </div>
            <div class="form-group">
              <label for="sectorDescription" class="col-form-label"
                >Strategy Description
                <span v-show="inEditMode" class="text-danger">*</span></label
              >
              <textarea
                maxlength="300"
                @blur="$v.fund.sectorDescription.$touch()"
                :class="{ 'parsley-error': $v.fund.sectorDescription.$error, 'updated-portal': fundUmbrella != null && fund.sectorDescription !== fundUmbrella.sectorDescription }"
                class="form-control"
                id="sectorDescription"
                placeholder="Your Answer"
                rows="2"
                v-model="fund.sectorDescription"
                :disabled="!inEditMode"
              />
              <template v-if="$v.fund.sectorDescription.$error">
                <ul class="parsley-errors-list filled">
                  <li
                    v-if="$v.fund.sectorDescription.$error"
                    class="parsley-required"
                  >
                    Description must be between 2 and 300 characters
                  </li>
                </ul>
              </template>
            </div>
            <div class="form-group">
              <div class="input-group">
                <label for="esg-focus" :class="{ 'updated-portal-toggle' : !esgTest }">ESG Focus</label>
              </div>
              <div class="input-group">
                <toggle-button
                  :disabled="!inEditMode"
                  id="esg-focus"
                  v-model="fund.isSocialResponsible"
                  :sync="true"
                  :labels="{ checked: 'Yes', unchecked: 'No' }"
                />
              </div>
              <div class="mt-2" v-if="fund.isSocialResponsible">
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="social-check"
                    value="1"
                    v-model="fund.esgType"
                    name="Social"
                    class="custom-control-input"
                    :disabled="!inEditMode"
                  />
                  <label class="custom-control-label" for="social-check"
                    >Social</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="env-check"
                    value="2"
                    v-model="fund.esgType"
                    name="Environmental"
                    class="custom-control-input"
                    :disabled="!inEditMode"
                  />
                  <label class="custom-control-label" for="env-check"
                    >Environmental</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="SEG-check"
                    value="3"
                    v-model="fund.esgType"
                    name="SEG"
                    class="custom-control-input"
                    :disabled="!inEditMode"
                  />
                  <label class="custom-control-label" for="SEG-check"
                    >Social, Environmental and/or Governance</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="other-check"
                    value="4"
                    v-model="fund.esgType"
                    name="Other"
                    class="custom-control-input"
                    :disabled="!inEditMode"
                  />
                  <label class="custom-control-label" for="other-check"
                    >Other</label
                  >
                </div>
                <div class="input-group mt-2" v-if="fund.esgType == 4">
                  <textarea
                    class="form-control col-md-9"
                    id="other-text"
                    placeholder="Explain"
                    maxlength="250"
                    rows="1"
                    v-model="fund.esgTypeOther"
                    @blur="$v.fund.esgTypeOther.$touch()"
                    :class="{ 'parsley-error': $v.fund.esgTypeOther.$error, 'updated-portal': fundUmbrella != null && fund.esgTypeOther !== fundUmbrella.esgTypeOther }"
                    @keypress="isFormUpdated = true"
                    :disabled="!inEditMode"
                  ></textarea>
                </div>
                <template v-if="$v.fund.esgTypeOther.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.esgTypeOther.$error"
                      class="parsley-required"
                    >
                      This value is required
                    </li>
                  </ul>
                </template>
              </div>
            </div>
            <div class="form-group">
              <label for="targetSize" class="col-form-label"
                >Target Size (M)
                <span v-show="inEditMode" class="text-danger">*</span></label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ symbol }}</span>
                </div>
                <input
                  type="text"
                  @blur="$v.fund.targetSize.$touch()"
                  :class="{ 'parsley-error': $v.fund.targetSize.$error, 'updated-portal': fundUmbrella != null && fund.targetSize !== fundUmbrella.targetSize }"
                  class="form-control"
                  id="targetSize"
                  placeholder="Your Answer"
                  v-model.number="fund.targetSize"
                  :disabled="!inEditMode"
                />
              </div>
              <template v-if="$v.fund.targetSize.$error">
                <ul class="parsley-errors-list filled">
                  <li v-if="$v.fund.targetSize.$error" class="parsley-required">
                    Enter a number between 1 and 1,000,000
                  </li>
                </ul>
              </template>
            </div>
            <div class="form-group">
              <label for="hardCap" class="col-form-label">Hard Cap (M)</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ symbol }}</span>
                </div>
                <input
                  type="text"
                  :class="{ 'updated-portal': fundUmbrella != null && fund.hardCap !== fundUmbrella.hardCap }"
                  class="form-control"
                  id="hardCap"
                  placeholder="Your Answer"
                  v-model.number="fund.hardCap"
                  :disabled="!inEditMode"
                />
              </div>
            </div>
            <div class="form-group" v-if="inEditMode">
              <label for="docs" class="col-form-label"> Documents </label>
              <div id="docs" class="tab-pane fade show active">
                <FundDocumentList :files="fund.documents" />
              </div>
            </div>
          </span>
        </div>
        <div class="col-lg-6" v-if='compareTabToggle'>
          <CompareTabIS
            :fund='fund'
            :fundUmbrella='fundUmbrella'
            :inEditMode='inEditMode'
            :allStrategies='allStrategies'
            :symbol='symbol'
            :compareTabToggle='compareTabToggle'
          />
        </div>
        <div class="col-lg-6" v-if="!inEditMode && !compareTabToggle">
          <!-- right col bottom -->
          <ul class="nav nav-tabs tabs-bordered">
            <li class="nav-item">
              <a
                href="javascript:void(0)"
                class="nav-link"
                data-toggle="tab"
                :class="{ active: activeISTab == 1 }"
                @click="showISTab(1)"
              >
                Documents
                <span
                  class="badge badge-primary noti-icon-badge"
                  v-if="fund.documents"
                  >{{ fund.documents.length }}</span
                >
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane" :class="{ 'active show': activeISTab == 1 }">
              <div class="tab-pane fade show active">
                <FundDocumentList :files="fund.documents" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import FundDocumentList from '@/components/portal/FundDocumentList'
import FundKeyPersonnelList from '@/components/portal/FundKeyPersonnelList'
import FundPerformancesList from '@/components/portal/FundPerformancesList'
import {
  required,
  requiredIf,
  decimal,
  minLength,
  maxLength,
  minValue,
  maxValue
} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import ActivityList from '@/components/ActivityList'
import CompareTabIS from '@/components/portal/CompareTabIS'
import { mapActions } from 'vuex'

export default {
  components: {
    FundDocumentList,
    FundKeyPersonnelList,
    FundPerformancesList,
    Multiselect,
    DatePicker,
    ActivityList,
    CompareTabIS
  },
  data () {
    return {
      activeISTab: 1,
      showLogs: false
    }
  },
  props: {
    fund: {
      type: Object
    },
    fundUmbrella: {
      type: Object
    },
    activeTab: {
      type: Number
    },
    inEditMode: {
      type: Boolean
    },
    strategyObj: {
      type: Array
    },
    symbol: {
      type: String
    },
    compareTabToggle: {
      type: Boolean
    }
  },
  methods: {
    ...mapActions(['fetchAllStrategies']),
    showISTab (tabId) {
      this.activeISTab = tabId
    },
    onActivityUpdate () {
      this.$emit('onActivityUpdate')
    },
    clickAddActivity () {
      this.$emit('clickAddActivity')
    },
    showActivity () {
      this.$emit('showActivity')
    },
    editActivity () {
      this.$emit('editActivity')
    },
    updateStrategy (strategy) {
      this.$emit('updateStrategy', strategy)
    }
  },
  computed: {
    allStrategies () {
      return this.$store.state.allStrategies
    },
    esgTest () {
      return this.fundUmbrella !== null && (this.fundUmbrella.isSocialResponsible === this.fund.isSocialResponsible) && (this.fundUmbrella.esgType === this.fund.esgType) && (this.fundUmbrella.esgOther === this.fund.esgOther)
    }
  },
  validations: {
    fund: {
      sectorId: { required, min: minValue(1) },
      sectorDescription: {
        required,
        min: minLength(2),
        max: maxLength(300)
      },
      esgTypeOther: {
        required: requiredIf(function () { return !this.fund.isOpenEnded })
      },
      targetSize: {
        required,
        decimal,
        min: minValue(1),
        max: maxValue(1000000)
      }
    }
  },
  created () {
    this.fetchAllStrategies()
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>