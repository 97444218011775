<template>
  <div>
      <ul class="nav nav-tabs tabs-bordered nav-justified">
        <li class="nav-item">
            <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
            <a class="nav-link active show">
                Client Feedback
            </a>
        </li>
      </ul>
      <div v-if='loading'><i class="fa fa-spinner fa-spin"></i> Loading</div>
      <div v-else>
        <form class="m-t-10 m-b-10">
          <div class="form-group">
              <label for='orgName'>Client</label>
              <input class='form-control m-b-10' readonly="readonly" v-model='clientFeedbackDetails.organizationName' />
          </div>
          <div class='form-group'>
            <label for='Status'>Status</label>
            <input class='form-control m-b-10' readonly="readonly" v-model='clientFeedbackDetails.statusName' />
          </div>
          <div class='form-group'>
            <label for='Comments'>Comments</label>
            <textarea class='form-control m-b-10' rows='10' readonly="readonly" v-model='clientFeedbackDetails.comments' />
          </div>
          <div class='form-group'>
            <label for='toggle'>Created On</label>
            <AppDate :date="clientFeedbackDetails.createdOn" :show="'input'"/>
          </div>
        </form>
      <button class='btn btn-primary' @click.prevent='close'><i class="mdi mdi-close-circle-outline"></i> Close</button>
    </div>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import moment from 'moment'

export default {
  components: {
    moment
  },
  props: {
    clientFeedbackDetails: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      require: true
    }
  },
  mixins: [rightbar],
  methods: {
    close () {
      this.hideRightbar()
    }
  }
}
</script>
