<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portal-firms'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Navigator'" :icon="'mdi mdi-web'" />
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box table-responsive">
                                <h4 class="header-title">Firms list</h4>
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-12 form-inline">
                                      <label class="col-form-label">
                                        <span class="mr-1">Only new and updated</span>
                                        <toggle-button v-model="onlyNew" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="startSearch"/>
                                        <span class="m-1">Region</span>
                                        <select id="typeId" class="ml-3 form-control" v-model="geoId" @change="startSearch">
                                            <option value="0">All</option>
                                            <option :value="geo.geographicId" v-for="geo in allGeoFocuses" :key="geo.geographicId">{{geo.geographicName}}</option>
                                        </select>
                                        <span class="m-1">Only Agency</span>
                                        <toggle-button v-model="isAgency" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="startSearch"/>
                                        <span class="m-1">Agency Name</span>
                                        <select id="agencyFirm" class="ml-3 form-control" v-model="agencyFirmId" @change="startSearch">
                                            <option value="0">All</option>
                                            <option :value="item.firmId" v-for="item in allAgency" :key="item.firmId">{{item.firmName}}</option>
                                        </select>
                                        <span class="m-1">Firm Name</span>
                                        <input type="search" v-model="searchName" class="form-control" placeholder="Search by name" @keyup.enter="startSearch">
                                        <button @click="startSearch" class="btn btn-icon waves-effect waves-light btn-primary m-b-5 "> <i class="fa fa-search"></i> </button>
                                            <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-warning pull-right m-2" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                      </label>
                                    
                                        
                                            
                                            
                                        
                                    </div>
                                </div>

                                <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)">Name
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th>Agency Name</th>
                                        <th @click="setOrder(1)">Website
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(2)">Location
                                          <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(4)">Region
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(3)"> Submit Date
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="firm in firms"
                                        :key="firm.firmId">
                                        <td>
                                            <router-link :to="{name: 'PortalFirmsDetail', params: {id: firm.firmId}}">
                                                {{ firm.firmName }} 
                                            </router-link>
                                            <i v-if="firm.isAgency" class="mdi mdi-clipboard-account text-success" v-tooltip="'Placement Agent'"></i>
                                        </td>
                                        <td>{{ firm.agencyFirmName }}</td>
                                        <td>{{ firm.website }}</td>
                                        <td>
                                          <span v-if="firm.city" v-tooltip="firm.country">
                                            {{ firm.city }}, {{ firm.region }}
                                          </span>
                                        </td>
                                        <td>{{ firm.geographicName }}</td>
                                        <td>
                                            <AppDate :date="firm.createdOn" :show="'text'" />
                                            <span v-if="firm.umbFirmId > 0 && firm.umbrellaCheck" class="badge badge-success">Imported</span>
                                            <span v-if="firm.umbFirmId == 0 && !firm.umbrellaCheck" class="badge badge-warning">New</span>
                                            <span v-if="firm.umbFirmId > 0 && !firm.umbrellaCheck" class="badge badge-warning">Updated</span>
                                            <span v-if="firm.deletedOn != null" class="badge badge-danger">Deleted</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div class="" role="status" aria-live="polite">Page {{currentPage}} of {{totalPages}} - {{totalRecords | thousandNumber}} firms</div>
                                    <div class="px-3">
                                          <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                              <option value="250">250</option>
                                          </select> 
                                    </div>

                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end container -->
            </div>
            <!-- end content -->

            <Footer />
        </div>
    </div>
</template>

<script>
import { PortalFirmService } from '@/services/portal-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { mapActions } from 'vuex'

export default {
  components: {
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      firms: [],
      searchName: '',
      onlyNew: null,
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      sortBy: ['FirmName', 'Website', 'City', 'CreatedOn', 'GeographicName'],
      sortIndex: 0,
      sortType: 'ASC',
      searchTimeout: null,
      geoId: 0,
      isAgency:null,
      agencyFirmId:null,
      totalRecords: 0,
      totalPages: 0,
      allAgency:[]
    }
  },
  methods: {
    ...mapActions(['fetchAllGeoFocus']),
    getList () {
      this.isLoading = true
      const filters = {
        'searchName': this.searchName,
        'onlyNew': this.onlyNew,
        'geoId': this.geoId,
        'isAgency': this.isAgency,
        'agencyFirmId': this.agencyFirmId,
        'pageSize': this.pageSize,
        'sortIndex': this.sortIndex
      }
      
      localStorage.setItem('umb_portal_firms_filters', JSON.stringify(filters))

      PortalFirmService.search(this.searchName.trim() , this.onlyNew, this.geoId > 0 ? this.geoId : null, this.sortBy[this.sortIndex], this.sortType, this.isAgency==true? true: null, this.agencyFirmId > 0 ? this.agencyFirmId : null, this.currentPage, this.pageSize)
      .then((response) => {
        this.isLoading = false
        this.firms = response.data.firms
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Portal Firms List') })
    },
    onPageChanged (pageNumber) {
      // console.log('pageNumber', pageNumber)
      this.submitSearch()
    },
    startSearch () {
      
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.firms = []
      if (this.searchName == null) {
        this.searchName = ''
      }

      if(this.isAgency) {
        this.agencyFirmId = 0
      }

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    resetFilter () {
      this.searchName = ''
      this.onlyNew = null
      this.geoId = 0
      this.isAgency = null
      this.agencyFirmId = 0
      this.startSearch()
    }
  },
  mounted () {
    this.fetchAllGeoFocus()
    const oldFilters = JSON.parse(localStorage.getItem('umb_portal_firms_filters'))
    if (oldFilters != null) {
      if (oldFilters.searchName != null) {
        this.searchName = oldFilters.searchName
      }
      if (oldFilters.onlyNew != null) {
        this.onlyNew = oldFilters.onlyNew
      }
      if (oldFilters.geoId != null) {
        this.geoId = oldFilters.geoId
      }
      if(oldFilters.isAgency != null){
        this.isAgency = oldFilters.isAgency
      }
      if(oldFilters.agencyFirmId != null){
        this.agencyFirmId = oldFilters.agencyFirmId
      }
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
      if (oldFilters.sortIndex != null) {
        this.sortIndex = oldFilters.sortIndex
      }
    }
    this.startSearch()
  },
  created(){
    this.isLoading = true
    PortalFirmService.search('', null, null, 'FirmName', 'ASC', true,  null, 1, 100)
      .then((response) => {
        this.isLoading = false
        this.allAgency = response.data.firms
        
      })
  },
  computed: {
    hasFilter () {
      return this.searchName.length > 0 || this.onlyNew || this.geoId > 0 || this.isAgency || this.agencyFirmId > 0
    },
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>