import Constants from '../constants.js'
import Axios from 'axios'

export const PortalFirmService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/portal-firm/' + id)
  },
  save (id, payload) {
    return Axios.put(Constants.API_URL + '/portal-firm/' + id, payload)
  },
  search (name, onlyNew, geoId, sortField, sortType, isAgency, agencyFirmId, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/portal-firm/search?name=' + name + '&onlyNew=' + onlyNew + '&geoId=' + geoId + '&sortField=' + sortField + '&sortType=' + sortType + '&isAgency=' + isAgency + '&agencyFirmId=' + agencyFirmId + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  checkAndImport (id, value) {
    return Axios.post(Constants.API_URL + '/portal-firm/import-umbrella/' + id, value)
  },
  setUmbFirmId (id, value) {
    return Axios.post(Constants.API_URL + '/portal-firm/umbrella-firmid/' + id, value)
  },
  checkFirmFund (value) {
    return Axios.get(Constants.API_URL + '/portal-firm/check-firm/' + value)
  }
}

export const PortalFundService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/portal-fund/' + id)
  },
  save (id, payload) {
    return Axios.put(Constants.API_URL + '/portal-fund/' + id, payload)
  },
  search (name, firmgeoId, onlyNew, sortField, sortType, pageNumber, pageSize, types, geos, sectors) {
    return Axios.get(Constants.API_URL + '/portal-fund/search?name=' + name + '&firmId=' + firmgeoId + '&onlyNew=' + onlyNew + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&geoId=' + geos + '&typeId=' + types + '&sectorId=' + sectors)
  },
  download (id) {
    return Axios.get(Constants.API_URL + '/portal-fund/download/' + id, {responseType: 'blob'})
  },
  checkAndImport (id, value) {
    return Axios.post(Constants.API_URL + '/portal-fund/import-umbrella/' + id, value)
  },
  setUmbFundId (id, value) {
    return Axios.post(Constants.API_URL + '/portal-fund/umbrella-fundid/' + id, value)
  }
}

export const PortalContactService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/portal-contact/' + id)
  },
  save (id, payload) {
    return Axios.put(Constants.API_URL + '/portal-contact/' + id, payload)
  },
  activate (id) {
    return Axios.put(Constants.API_URL + '/portal-contact/activate/' + id)
  },
  resendActivationEmail (id) {
    return Axios.put(Constants.API_URL + '/portal-contact/resend-activation-email/' + id)
  },
  addByAdmin (id, value) {
    return Axios.post(Constants.API_URL + '/portal-contact/addbyadmin/' + id, value)
  },
  unrestrictUserByAdmin (id) {
    return Axios.put(Constants.API_URL + '/portal-contact/unrestrict-userbyadmin/' + id)
  },
  search (name, firmId, onlyNew, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/portal-contact/search?name=' + name + '&firmId=' + firmId + '&onlyNew=' + onlyNew + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  }
}

export const PortalDiversityService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/portal-diversity/' + id)
  },
  search (firmId, onlyNew, diversityCat, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/portal-diversity/search?firmId=' + firmId + '&onlyNew=' + onlyNew + '&diversityCat=' + diversityCat + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  }
}

export const PortalDiversitySkipService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/portal-diversity-skip/' + id)
  },
  save (firmId, payload) {
    return Axios.post(Constants.API_URL + '/portal-diversity-skip/' + firmId, payload)
  }
}

export const PortalInviteService = {
  sendInvite (value) {
    return Axios.post(Constants.API_URL + '/distributionlist/addall/', value, {
      headers: {
        'content-type': 'application/json'
      }
    })
  },
  getList (email, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/distributionlist/search?email=' + email + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  }
}
