<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Portfolio Vintage Years
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="portfolioYear">
      <div class="form-group">
          <label for="vintageYear">Vintage Year<span class="text-danger" v-if="isAdd">*</span></label>
          <input type="text" v-model.number="portfolioYear.vintageYear" class="form-control" :disabled="!isEditMode" id="vintageYear" :class="{ 'parsley-error': $v.portfolioYear.vintageYear.$error }" :readonly="lastYear > 0">
          <template v-if="$v.portfolioYear.vintageYear.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.portfolioYear.vintageYear.required" class="parsley-required">This value is required</li>
              <li v-if="!$v.portfolioYear.vintageYear.min" class="parsley-required">This value is too small (min: 2010)</li>
              <li v-if="!$v.portfolioYear.vintageYear.max" class="parsley-required">This value is too big</li>
            </ul>
          </template>
      </div>

      <div v-if="clientType === 2">
        <h4>Guideline</h4>
        <div class="row" >
          <div class="col-md-6">
            <div class="form-group">
                <label for="GuidelineMin">Min<span class="text-danger" v-if="isEditMode">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">%</span>
                    </div>
                    <input type="text" v-model.number="portfolioYear.guidelineMin" class="form-control" :disabled="!isEditMode" id="GuidelineMin" @blur="$v.portfolioYear.guidelineMin.$touch()" :class="{ 'parsley-error': $v.portfolioYear.guidelineMin.$error }">
                </div>
                <template v-if="$v.portfolioYear.guidelineMin.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="!$v.portfolioYear.guidelineMin.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.portfolioYear.guidelineMin.min" class="parsley-required">This value is too small</li>
                    <li v-if="!$v.portfolioYear.guidelineMin.max" class="parsley-required">This value is too big</li>
                  </ul>
                </template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
                <label for="GuidelineMax">Max<span class="text-danger" v-if="isEditMode">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">%</span>
                    </div>
                    <input type="text" v-model.number="portfolioYear.guidelineMax" class="form-control" :disabled="!isEditMode" id="GuidelineMax" @blur="$v.portfolioYear.guidelineMax.$touch()" :class="{ 'parsley-error': $v.portfolioYear.guidelineMax.$error }">
                </div>
                <template v-if="$v.portfolioYear.guidelineMax.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="!$v.portfolioYear.guidelineMax.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.portfolioYear.guidelineMax.min" class="parsley-required">This value is too small</li>
                    <li v-if="!$v.portfolioYear.guidelineMax.max" class="parsley-required">This value is too big</li>
                  </ul>
                </template>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group" v-if="clientType === 2">
        <label for="CommitmentPercent">Commitment % <span v-show="isEditMode" class="text-danger">*</span></label>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">%</span>
            </div>
            <input type="text" v-model.number="portfolioYear.commitmentPercent" @keyup="calcPositionPerc" :disabled="!isEditMode" @blur="$v.portfolioYear.commitmentPercent.$touch()" placeholder="Enter Commitment %" class="form-control" id="CommitmentPercent" :class="{ 'parsley-error': $v.portfolioYear.commitmentPercent.$error }">
        </div>
        <template v-if="$v.portfolioYear.commitmentPercent.$error || isExceed">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.portfolioYear.commitmentPercent.required" class="parsley-required">This value is required</li>
            <li v-if="!$v.portfolioYear.commitmentPercent.decimal && $v.portfolioYear.commitmentPercent.required" class="parsley-required">This value is not valid</li>
            <li v-if="!$v.portfolioYear.commitmentPercent.min" class="parsley-required">This value is too small</li>
            <li v-if="!$v.portfolioYear.commitmentPercent.max" class="parsley-required">This value is too big</li>
            <li v-if="isExceed" class="parsley-required">Total commitment will exceed the max</li>
          </ul>
        </template>
      </div>

      <div class="form-group">
        <label for="Commitment">Commitment (M) <span v-show="isEditMode" class="text-danger">*</span></label>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">{{currency}}</span>
            </div>
            <input type="text" v-model.number="portfolioYear.commitment" :disabled="!isEditMode" @blur="$v.portfolioYear.commitment.$touch()" placeholder="Enter Commitment" class="form-control" id="Commitment" :class="{ 'parsley-error': $v.portfolioYear.commitment.$error }" :readonly="clientType == 2">
        </div>
        <template v-if="$v.portfolioYear.commitment.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.portfolioYear.commitment.required" class="parsley-required">This value is required</li>
            <li v-if="!$v.portfolioYear.commitment.decimal && $v.portfolioYear.commitment.required" class="parsley-required">This value is not valid</li>
            <li v-if="!$v.portfolioYear.commitment.min" class="parsley-required">This value is not valid</li>
            <li v-if="!$v.portfolioYear.commitment.max" class="parsley-required">This value is too big</li>
          </ul>
        </template>
      </div>

      <div class="form-group" v-if="clientType === 1 && !isAdd">
          <label>Active</label>
          <toggle-button v-model="portfolioYear.isActive" :sync="true" :disabled="!isEditMode" :width="40"/>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect waves-light w-md" @click.prevent="onSave" :disabled="isLoading">
          <span v-if="isLoading">
            <span class="fa fa-spinner fa-spin mr-1" role="status"></span> Updating...
          </span>
          <span v-else><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { required, decimal, numeric, minValue, maxValue, requiredIf, helpers } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

const validateIf = (prop, validator) =>
  helpers.withParams({ type: 'validatedIf', prop }, function (value, parentVm) {
    return this.clientType === 2 ? validator(value) : true
  })

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    },
    currency: {
      required: true,
      type: String
    },
    totalCommitment: {
      required: true,
      type: Number
    },
    clientType: {
      required: true,
      type: Number
    },
    lastYear: {
      required: true,
      type: Number
    },
    lastSum: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      isExceed: false,
      isLoading: false
    }
  },
  mixins: [rightbar],
  validations: {
    portfolioYear: {
      vintageYear: { required, numeric, min: minValue(2010), max: maxValue(2050) },
      guidelineMin: { required: requiredIf(function () { return this.clientType === 2 }), numeric, min: minValue(0), max: maxValue(100) },
      guidelineMax: { required: requiredIf(function () { return this.clientType === 2 }), numeric, min: minValue(0), max: maxValue(100) },
      commitment: { required, decimal, min: minValue(0), max: maxValue(1000000) },
      commitmentPercent: { required: requiredIf(function () { return this.clientType === 2 }), decimal, min: validateIf('dummy', minValue(0)), max: maxValue(100) }
    }
  },
  methods: {
    ...mapActions(['addPortfolioYear', 'updatePortfolioYear', 'checkBeforePortfolioUpdate']),
    close () {
      this.isExceed = false
      this.$v.portfolioYear.$reset()
      this.hideRightbar()
    },
    onSave () {
      this.$v.portfolioYear.$touch()
      if (!this.$v.portfolioYear.$error) {
        this.isLoading = true
        this.isExceed = false
        const chkmodel = {portfolioId: this.portfolioYear.portfolioId, type: 'yer', paramId: this.portfolioYear.vintageYear, newPercent: this.portfolioYear.commitmentPercent}
        this.checkBeforePortfolioUpdate(chkmodel)
        .then((resonse) => {
          if (!resonse) {
            this.isExceed = true
            this.isLoading = false
          }
        })
        .then(() => {
          if (this.isExceed) {
            this.isLoading = false
            return
          }
          if (this.isAdd) {
            this.addPortfolioYear({portfolioYear: this.portfolioYear})
              .then((response) => {
                if (response == null) {
                  this.$snotify.error('Server Error!')
                } else {
                  this.$snotify.success('Commitment Year Added.')
                  this.$emit('onUpdate', response, 1)
                }
                this.isLoading = false
              })
          } else {
            this.updatePortfolioYear({portfolioId: this.portfolioYear.portfolioId, portfolioYear: this.portfolioYear})
              .then((response) => {
                this.$snotify.success('Commitment Year Updated.')
                this.$emit('onUpdate', this.portfolioYear, 0)
              })
          }
        })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    calcPositionPerc () {
      const p = (this.portfolioYear.commitmentPercent * this.totalCommitment) / 100
      this.portfolioYear.commitment = p.toFixed(2)
    }
  },
  computed: {
    portfolioYear () {
      return this.$store.state.rb_portfolioYear
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>
