<template>
  <div class="row">
      <div class="col-lg-12">
          <div class="card-box table-responsive">
              <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                  <thead>
                  <tr role="row">
                      <th @click="setOrder(0)"> Full Name
                        <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                        <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                      </th>
                      <th> Email </th>
                      <th @click="setOrder(2)"> Title
                        <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                        <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                      </th>
                      <th @click="setOrder(3)"> Group
                        <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                        <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                      </th>
                      <th @click="setOrder(4)"> Office
                        <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                        <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                      </th>
                      <th @click="setOrder(5)">Status
                        <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                        <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                      </th>
                  </tr>
                  </thead>
          
                  <tbody>
                  <tr role="row" v-for="user in list" :key="user.userId">
                      <td>
                        <router-link v-if="adminUser" :to="{name: 'UserInfo', params: {id: user.userId}}">{{user.fullName}}</router-link>
                        <span v-else>{{user.fullName}}</span>
                      </td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.titleName }}</td>
                      <td>{{ user.groupName }}</td>
                      <td>{{ user.officeName}}</td>
                      <!-- <td><span v-if="user.dob">{{ user.dob | birthday }}</span></td> -->
                      <td>{{ user.isActive ? 'Active' : 'Inactive' }}</td>
                  </tr>
                  </tbody>
              </table>
  
              <div class="row">
                <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                  <div class="" role="status">{{users.length}} Users</div>
                </div>
              </div>
          </div>

      </div>
  </div>

</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  props: {
    users: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      sortIndex: 0,
      sortType: 'ASC'
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    adminUser () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0
    },
    list () {
      let result = this.users
      // Sort
      switch (this.sortIndex) {
        case 0:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.fullName < b.fullName) ? 1 : -1)
          }
          break
        case 2:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.titleName > b.titleName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.titleName < b.titleName) ? 1 : -1)
          }
          break
        case 3:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.groupName > b.groupName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.groupName < b.groupName) ? 1 : -1)
          }
          break
        case 4:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.officeName > b.officeName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.officeName < b.officeName) ? 1 : -1)
          }
          break
        case 5:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.isActive > b.isActive) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.isActive < b.isActive) ? 1 : -1)
          }
          break
      }

      return result
    }
  },
  filters: {
    birthday (date) {
      return moment(date).format('MMMM D')
    }
  },
  methods: {
    setOrder (fIndex) {
      if (this.sortIndex === fIndex) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = fIndex
        this.sortType = 'ASC'
      }
    }
  }
}
</script>