import Constants from '../constants.js'
import Axios from 'axios'

export const UserpinService = {
  getByUber () {
    return Axios.get(Constants.API_URL + '/userpin/')
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/userpin', value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/userpin/' + id)
  }
}
