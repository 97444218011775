<template>
  <div class="col-lg-6 col-ms-12">
      <div class="card-box">
          <h4 class="header-title m-t-0">Your Deals</h4>
          <p class="text-muted m-b-25 font-13">
              <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
              <span v-else>You are in {{list.length}} active deal {{list.length > 1 ? 'teams' : 'team'}}</span>
          </p>
            <div class="table-responsive">
                <div class="inbox-widget1 notes-box" style="overflow: auto; outline: none;">
                  <table class="table mb-0 table-sortable table-striped">
                      <thead>
                      <tr>
                          <th>#</th>
                          <th @click="setOrder(0)">Deal Name
                            <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                            <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                          </th>
                          <th @click="setOrder(1)">Target
                            <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                            <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                          </th>
                          <th @click="setOrder(2)">Stage
                            <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                            <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                          </th>
                          <th @click="setOrder(3)">Role
                            <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                            <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                          </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in sortedList" :key="item.fundId">
                          <td>{{index + 1}}</td>
                          <td>
                            <router-link :to="{name: 'FundsDetail', params: {id: item.fundId}}" >
                              {{item.fundName}}
                            </router-link>
                          </td>
                          <td>
                            <AppDate :date="item.targetDate" :show="'text'"/><br/>
                          </td>
                          <td><span class="badge" :class="stageClass(item.stageId)">{{item.stageName}}</span></td>
                          <td>{{item.fundTeamRoleName}}</td>
                      </tr>
                      </tbody>
                      <tfoot v-if="sortedList.length == 0">
                        <tr>
                          <td colspan="5"><i>No deals to show</i></td>
                        </tr>
                      </tfoot>
                  </table>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { DashService } from '@/services/dash-service'

export default {
  data () {
    return {
      list: [],
      isLoading: true,
      sortIndex: 1,
      sortType: 'DESC'
    }
  },
  computed: {
    sortedList () {
      if (this.list.length === 0) {
        return []
      }
      let result = this.list
      // Sort
      switch (this.sortIndex) {
        case 0:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.fundName > b.fundName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.fundName < b.fundName) ? 1 : -1)
          }
          break
        case 1:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.targetDate > b.targetDate) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.targetDate < b.targetDate) ? 1 : -1)
          }
          break
        case 2:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.stageId > b.stageId) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.stageId < b.stageId) ? 1 : -1)
          }
          break
        case 3:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.fundTeamRoleName > b.fundTeamRoleName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.fundTeamRoleName < b.fundTeamRoleName) ? 1 : -1)
          }
          break
      }
      return result
    }
  },
  created () {
    DashService.getUserDeals()
    .then((response) => {
      this.list = response.data
      this.isLoading = false
    })
  },
  methods: {
    stageClass (stageId) {
      if (stageId >= 3) {
        return 'text-white stage' + stageId
      }
      return 'stage1-2'
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
    }
  }
}
</script>