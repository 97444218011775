<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portal-deals'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="fund">
                    <Toolbar :title="'Update Deal - '+onScreenFundName" :icon="'mdi mdi-web'" 
                      :showToolbar="true" :inEditMode="false" :firmImported="false" :canEdit="canbeEdited" :isSaving="inSaving"
                      :isNew="false" :isFund='true' :isUpdated="false" :isDeleted="false"
                      @onEditImport="EditForm" @onCancel="cancelForm"   
                      :isAdminEdit="true" :isNavAdmin="isNavAdmin"
                     @onSave="saveForm" @onCancelAdminEdit="onCancelAdminEdit"/>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-box">
                                <ul class="nav nav-tabs tabs-bordered">
                                    <li class="nav-item" v-if="!inEditMode">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(1)" :class="{'active': activeTab == 1, 'tab-error': smTabHasError}">
                                            <i class="mdi mdi-information-outline"></i> General
                                        </a>
                                    </li>
                                    <li class="nav-item" v-if="!inEditMode">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(2)" :class="{'active': activeTab == 2, 'tab-error': smTabHasError}">
                                            <i class="mdi mdi-chart-line-stacked"></i> {{fund.fundTypeId == 1 ? 'Investment Summary' : fund.fundTypeId == 2 ? 'Secondary' : 'Co-Investment'}}
                                        </a>
                                    </li>
                                    <li class="nav-item" v-if="!inEditMode && fund.umbFundId > 0">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(3)" :class="{'active': activeTab == 3, 'tab-error': smTabHasError}">
                                            <i class="mdi mdi-swap-horizontal"></i> Compare
                                        </a>
                                    </li>
                                    <li class="nav-item" v-if='inEditMode'>
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(1)" :class="{'active': activeTab == 1}">
                                            <i class="mdi mdi-information-outline"></i> Summary
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                  <div class="tab-pane fade" :class="{ 'active show': !compareTabToggle ? activeTab == 1 : inEditMode ? activeTab == 1 : activeTab == 3 }">
                                    <div class="row">
                                      <div class="col-lg-6">
                                        <div class="form-group">
                                          <label for="fundName" class="col-form-label">Name<span v-show="inEditMode" class="text-danger">*</span></label>
                                          <input
                                            type="text"
                                            @blur="$v.fund.fundName.$touch()" 
                                            :class="{ 'parsley-error': $v.fund.fundName.$error, 'updated-portal' : fundUmbrella != null && fund.fundName !== fundUmbrella.fundName }"
                                            class="form-control"
                                            id="fundName"
                                            placeholder="Your Answer"
                                            v-model="fund.fundName"
                                            :disabled="!inEditMode" />
                                          <template v-if="$v.fund.fundName.$error">
                                            <ul class="parsley-errors-list filled">
                                              <li class="parsley-required">Names must be over 3 and under 100 characters.</li>
                                            </ul>
                                          </template>
                                        </div>
                                        <div class="form-group">
                                          <label for="firmName" class="col-form-label"
                                            >General Partner
                                            <span v-show="inEditMode" class="text-danger">*</span></label>
                                          <input
                                            type="text"
                                            @blur="$v.fund.firmName.$touch()" 
                                            :class="{ 'parsley-error': $v.fund.firmName.$error, 'updated-portal' : fundUmbrella != null && fund.firmName !== fundUmbrella.firmName }"
                                            class="form-control"
                                            id="firmName"
                                            placeholder="Your Answer"
                                            v-model="fund.firmName"
                                            :disabled="true"
                                          />
                                          <template v-if="$v.fund.firmName.$error">
                                            <ul class="parsley-errors-list filled">
                                              <li class="parsley-required">Names must be over 3 and under 100 characters.</li>
                                            </ul>
                                          </template>
                                        </div>
                                        <div class="form-group">
                                          <label for="fundTypeId">Type
                                            <span v-show="inEditMode" class="text-danger">*</span></label>
                                              <div class="custom-control custom-radio">
                                                <input
                                                  @blur="$v.fund.fundTypeId.$touch()" 
                                                  type="radio"
                                                  id="type1"
                                                  value="1"
                                                  v-model="fund.fundTypeId"
                                                  name="type"
                                                  class="custom-control-input"
                                                  disabled />
                                            <label class="custom-control-label" for="type1">Primary</label>
                                          </div>
                                          <div class="custom-control custom-radio">
                                            <input
                                              @blur="$v.fund.fundTypeId.$touch()" 
                                              type="radio"
                                              id="type2"
                                              value="2"
                                              v-model="fund.fundTypeId"
                                              name="type"
                                              class="custom-control-input"
                                              disabled/>
                                            <label class="custom-control-label" for="type2">Secondary</label>
                                          </div>
                                          <div class="custom-control custom-radio">
                                            <input
                                              @blur="$v.fund.fundTypeId.$touch()" 
                                              type="radio"
                                              id="type3"
                                              value="3"
                                              v-model="fund.fundTypeId"
                                              name="type"
                                              class="custom-control-input"
                                              disabled />
                                            <label class="custom-control-label" for="type3"
                                              >Co-Investment</label
                                            >
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <label for="dealCurrencyId" class="col-form-label">Currency<span v-show="inEditMode" class="text-danger">*</span></label>
                                          <multiselect
                                            @select="$v.currency.$touch()" 
                                            id="dealCurrencyId"
                                            :class="{ 'updated_multi': fundUmbrella != null && fund.dealCurrencyId !== fundUmbrella.dealCurrencyId && !inEditMode }"
                                            v-model="dealCurrencyObj"
                                            :options="allCurrencies"
                                            label="currencyName"
                                            track-by="currencyId"
                                            :allow-empty="false"
                                            @input="dealCurrencySelected"
                                            :disabled="!inEditMode"></multiselect>
                                        </div>
                                        <div class="form-group">
                                          <label for="geographicFocusId" class="col-form-label">Geographic Focus
                                            <span v-show="inEditMode" class="text-danger">*</span></label>
                                          <multiselect
                                            @select="$v.geographicFocus.$touch()" 
                                            id="geographicFocusId"
                                            :class="{ 'updated_multi': fundUmbrella != null && fund.dealCurrencyId !== fundUmbrella.dealCurrencyId && !inEditMode }"
                                            v-model="geographicFocusObj"
                                            :options="allGeoFocuses"
                                            label="geographicName"
                                            track-by="geographicId"
                                            :allow-empty="false"
                                            @input="geoFocusSelected"
                                            :disabled="!inEditMode"></multiselect>
                                        </div>
                                        <div class="form-group">
                                          <label for="expectedStartDate" class="col-form-label"
                                            >Fundraise Start Date
                                            <span v-show="inEditMode" class="text-danger">*</span></label>
                                          <date-picker
                                            id="expectedstartdate"
                                            v-model="fund.expectedStartDate"
                                            placeholder="Select Date"
                                            calendar-class="calendar"
                                            :monday-first="true"
                                            :typeable="true"
                                            :clear-button="inEditMode"
                                            clear-button-icon="fa fa-times"
                                            format="MM/dd/yyyy"
                                            :bootstrap-styling="true"
                                            :class="{ 'updated-date' : fundUmbrella != null && fund.expectedStartDate !== fundUmbrella.expectedStartDate && !inEditMode }"
                                            :disabled="!inEditMode"></date-picker>
                                          <template v-if="$v.fund.expectedStartDate.$invalid">
                                            <ul class="parsley-errors-list filled">
                                              <li
                                                v-if="$v.fund.expectedStartDate.$invalid"
                                                class="parsley-required">
                                                Enter a valid date
                                              </li>
                                            </ul>
                                          </template>
                                        </div>
                                        
                                        <div class="form-group">
                                          <label for="expectedCloseDate" class="col-form-label">Fundraise Close Date
                                            <span v-show="!fund.isOpenEnded && inEditMode" class="text-danger">*</span></label>
                                          <date-picker
                                            id="expectedCloseDate"
                                            v-model="fund.expectedCloseDate"
                                            placeholder="Select Date"
                                            calendar-class="calendar"
                                            :monday-first="true"
                                            :typeable="true"
                                            :clear-button="inEditMode"
                                            clear-button-icon="fa fa-times"
                                            format="MM/dd/yyyy"
                                            :bootstrap-styling="true"
                                            :disabled="!inEditMode || fund.isOpenEnded"
                                            :class="{ 'updated-date' : fundUmbrella != null && fund.expectedStartDate !== fundUmbrella.expectedStartDate && !inEditMode }"></date-picker>
                                          <template v-if="$v.fund.expectedCloseDate.$invalid">
                                            <ul class="parsley-errors-list filled">
                                              <li
                                                v-if="$v.fund.expectedCloseDate.$invalid"
                                                class="parsley-required">
                                                Enter a valid date (must come after a valid start date)
                                              </li>
                                            </ul>
                                          </template>
                                          <div
                                            class="custom-control custom-checkbox"
                                            v-if="fund.fundTypeId < 3">
                                            <input
                                              type="checkbox"
                                              class="custom-control-input"
                                              id="OpenEnded"
                                              v-model="fund.isOpenEnded"
                                              @click="openEndedClear"
                                              :disabled="!inEditMode"
                                            />
                                            <label class="custom-control-label" for="OpenEnded">Open Ended</label>
                                          </div>
                                        </div>
                                      </div>
                                     
                                      <div class="col-lg-6" v-if='!inEditMode && !compareTabToggle'> 
                                        <!-- right col top -->
                                        <ul class="nav nav-tabs tabs-bordered">
                                          <li class="nav-item">
                                            <a
                                              href="javascript:void(0)"
                                              class="nav-link"
                                              data-toggle="tab"
                                              :class="{ active: activeSMTab == 1 }"
                                              @click="showSMTab(1)">
                                              Notes
                                              <span class="badge badge-primary noti-icon-badge" v-if="activities">{{ activities.length }}</span></a>
                                          </li>
                                        </ul>
                                        <div class="tab-content">
                                          <div class="tab-pane" :class="{ 'active show': activeSMTab == 1 }">
                                            <div class="tab-pane fade show active">
                                              <ActivityList
                                                :activities="activities"
                                                :isEditMode="inEditMode"
                                                @onUpdate="onActivityUpdate"
                                                @onClickNew="clickAddActivity"
                                                @onSelect="showActivity"
                                                @onSelectUpdate="editActivity"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <FundsDetailPrimary
                                    v-if='fund.fundTypeId == 1 && (displayUnderGeneral || compareTabToggle)' 
                                    :fund='fund'
                                    :fundUmbrella='fundUmbrella'
                                    :activeTab='activeTab' 
                                    :inEditMode='inEditMode'
                                    :strategyObj='strategyObj'
                                    :symbol='symbol'
                                    :compareTabToggle='compareTabToggle'
                                    :uSocialResponsible='uSocialResponsible'
                                    @updateStrategy='importStrategy' />
                                  <FundsDetailSecondary
                                    v-if='fund.fundTypeId == 2 && (displayUnderGeneral || compareTabToggle)' 
                                    :fund='fund'
                                    :fundUmbrella='fundUmbrella'
                                    :activeTab='activeTab'
                                    :inEditMode='inEditMode'
                                    :strategyObj='strategyObj'
                                    :symbol='symbol'
                                    :compareTabToggle='compareTabToggle'
                                    @updateStrategy='importStrategy' />
                                  <FundsDetailCoinvestment
                                    v-if='fund.fundTypeId == 3 && (displayUnderGeneral || compareTabToggle)' 
                                    :fund='fund'
                                    :fundUmbrella='fundUmbrella'
                                    :activeTab='activeTab'
                                    :inEditMode='inEditMode'
                                    :strategyObj='strategyObj'
                                    :symbol='symbol'
                                    :compareTabToggle='compareTabToggle'
                                    @updateStrategy='importStrategy' />
                                </div>
                                <div class="row">
                                  <div class='col-lg-6' v-if='!inEditMode'>
                                    <toggle-button
                                      v-model="showLogs"
                                      :labels="{ checked: 'Logs', unchecked: 'Logs' }" />
                                  </div>
                                </div>
                                <div class='row'>
                                  <div class='col-lg-6'>
                                    <div :class="{ collapse: !showLogs }">
                                      <div class="form-group">
                                        <label>Created On</label>
                                        <AppDate :date="fund.createdOn" />
                                      </div>
                                      <div class="form-group" v-if="fund.updatedOn">
                                        <label>Modified On</label>
                                        <AppDate :date="fund.updatedOn" :emptyIfNull="true" />
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if='activeTab === 3' class='col-lg-6'>
                                    <div :class="{ collapse: !showLogs }">
                                      <div class="form-group">
                                        <label>Created On</label>
                                        <AppDate :date="fundUmbrella.createdOn" />
                                      </div>
                                      <div class="form-group" v-if="fundUmbrella.updatedOn">
                                        <label>Modified On</label>
                                        <AppDate :date="fundUmbrella.updatedOn" :emptyIfNull="true" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>                          
                        </div> 
                        <!-- end col -->
                    </div>
                </div>
            </div>
        </div>

        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <ConflictList @onConfirm="bindUmbFundId" v-if="rightbarItem == 'similar'" :list="similarFundList" :relatedList="relatedFundList" :displayName="'fundName'" :valueName="'fundId'" :linkName="'FundsDetail'" :firmOrDeal="'Deal'"/>
              <ActivityItem @onUpdate="onActivityUpdate" v-if="rightbarItem == 'activity'" :objectId="7" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import { required, minLength, maxLength, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators'
import rightbar from '@/mixins/rightbar'
import { PortalFundService } from '@/services/portal-service'
import { ActivityService } from '@/services/activity-service'
import ActivityList from '@/components/ActivityList'
import ActivityItem from '@/components/ActivityItem'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Toolbar from '@/components/portal/PortalToolbar'
import moment from 'moment'
import ConflictList from '@/components/portal/ConflictList'
import FundsDetailPrimary from '@/components/portal/FundsDetailPrimary'
import FundsDetailSecondary from '@/components/portal/FundsDetailSecondary'
import FundsDetailCoinvestment from '@/components/portal/FundsDetailCoinvestment'
import CompareTabGeneral from '@/components/portal/CompareTabGeneral'
import { FundService } from '@/services/fund-service'
import { FirmService } from '@/services/firm-service'

const validCloseDate = (sdate, edate, isOpen) => {
  if (isOpen) {
    return true
  }
  if (sdate === null || sdate === undefined || edate === null || sdate === undefined) {
    return false
  }
  const s = moment(sdate)
  const e = moment(edate)
  return s < e
}

export default {
  components: {
    ActivityList,
    ActivityItem,
    Toolbar,
    Multiselect,
    DatePicker,
    FundsDetailPrimary,
    FundsDetailSecondary,
    FundsDetailCoinvestment,
    CompareTabGeneral,
    ConflictList
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  mixins: [rightbar, asyncDataStatus],
  data () {
    return {
      activeTab: 1,
      activeSMTab: 1,
      showLogs: false,
      inEditMode: true,
      inSaving: false,
      sideInEditMode: false,
      fund: {fundName: '', documents: []},
      strategyObj: null,
      strategyObj2: null,
      rightbarItem: null,
      addSidebar: null,
      firmTypeObj: null,
      dealCurrencyObj: null,
      geographicFocusObj: null,
      activities: null,
      fundUmbrella: null,
      similarFundList: null,
      relatedFundList: null,
      saveConfirmed: null,
      displayUnderGeneral: false,
      onScreenFundName: '',
      compareTabToggle: false
    }
  },
  validations: {
    fund: {
      firmName: { required, min: minLength(3), max: maxLength(100) },
      fundName: { required, min: minLength(3), max: maxLength(100) },
      fundTypeId: { required, min: minValue(1), max: maxValue(3) },
      dealCurrencyId: { required, min: minValue(1) },
      geographicFocusId: { required, min: minValue(1) },
      expectedStartDate: { required },
      expectedCloseDate: { required: requiredIf(function () { return !this.fund.isOpenEnded }), validCloseDate (value) { return validCloseDate(this.fund.expectedStartDate, value, this.fund.isOpenEnded) } }
    },
    currency: {
      required
    },
    geographicFocus: {
      required
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers', 'fetchActivity', 'fetchAllCurrencies', 'fetchAllGeoFocus', 'fetchAllStrategies']),
    showTab (tabId) {
      if (tabId === 3) {
        this.compareTabToggle = true
      } else {
        this.compareTabToggle = false
      }
      tabId === 2 ? this.displayUnderGeneral = true : this.displayUnderGeneral = false
      this.activeTab = tabId
    },
    openEndedClear () {
      this.fund.expectedCloseDate = ''
    },
    showSMTab (tabId) {
      this.activeSMTab = tabId
    },
    sidebarAction (item, isAdd, isOpen, isEdit) {
      this.addSidebar = isAdd
      this.sideInEditMode = isEdit || isAdd
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    loadRelatedFunds () {
      FirmService.getFunds(this.fund.generalPartnerId).then(
        (response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error Loading Related Deals!')
            return
          }
          this.relatedFundList = response.data
        }
      ).catch(error => this.$snotify.error('Server Error on Related Deals! ' + error.message))
    },
    saveForm () {
      this.$v.fund.$touch()
      if (!this.$v.fund.$error) {
        PortalFundService.save(this.fund.fundId, this.fund).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } 

            this.$snotify.success('Deal updated.')
            this.$router.push({name: 'PortalDealsDetail', params: {id: this.fund.fundId}})
          }
        ).catch(error => this.$snotify.error('Server Error! B ' + error.message))
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    importFund () {
      let isOk = false
      this.inSaving = true
      if (this.fund.umbFundId > 0) {
        PortalFundService.checkAndImport(this.fund.fundId, this.fund).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              FundService.get(this.fund.umbFundId)
              .then((responseMain) => {
                if (responseMain.data != null) {
                  this.fundUmbrella = responseMain.data
                  this.fund.umbrellaCheck = true
                }
              }).catch(error => this.$snotify.error('Server Error! A ' + error.message))
              this.$snotify.success('Import Completed')
            }
            this.inEditMode = false
            this.inSaving = false
            this.showTab(3)
          }
        ).catch(error => this.$snotify.error('Server Error! B ' + error.message))
      } else {
        FundService.doubleCheckFunds(this.fund.umbFundId, this.fund).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
              return
            }
            if (this.saveConfirmed) {
              isOk = true
            } else {
              this.similarFundList = response.data
              this.sidebarAction('similar', true, true, true)
            }

            if (!isOk) {
              this.inSaving = false
              return
            }
            PortalFundService.checkAndImport(this.fund.fundId, this.fund).then(
              (response) => {
                if (response.status !== 200) {
                  this.$snotify.error('Error!')
                } else {
                  this.fund = response.data
                  this.saveConfirmed = false
                  FundService.get(this.fund.umbFundId)
                  .then((responseMain) => {
                    if (responseMain.data != null) {
                      this.fundUmbrella = responseMain.data
                      this.fund.umbrellaCheck = true
                    }
                  }).catch(error => this.$snotify.error('Server Error! C ' + error.message))
                  this.$snotify.success('Import Completed')
                }
                this.inSaving = false
                this.inEditMode = false
              }
            ).catch(error => this.$snotify.error('Server Error! D ' + error.message))
          }
        ).catch(error => this.$snotify.error('Server Error! E' + error.message))
      }
    },
    bindUmbFundId (id) {
      if (id > 0) {
        const bind = {umbFundId: id, fundId: 0}
        PortalFundService.setUmbFundId(this.fund.fundId, bind)
        .then((response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error!')
          } else {
            this.$snotify.success('Done')
            this.inEditMode = false
            this.fund.umbFundId = id
            FundService.get(this.fund.umbFundId).then((responseMain) => {
              if (responseMain.data != null) {
                this.fundUmbrella = responseMain.data
              }
            })
          }
        })
      } else {
        this.saveConfirmed = true
        this.importFund()
      }
    },
    EditForm () {
      if (!this.fund.isFirmImported) {
        this.$snotify.warning('Associated firm must be imported')
        return
      }
      if (this.fund.umbFundId > 0) {
        this.compareTabToggle = true
      } else {
        this.compareTabToggle = false
      }
      this.inEditMode = true
      this.activeTab = 1
      this.addSidebar = false
      this.displayUnderGeneral = true
    },
    cancelForm () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to cancel?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.cancelFormOper()
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    cancelFormOper () {
      this.inEditMode = false
      this.inSaving = false
      this.compareTabToggle = false
      this.displayUnderGeneral = false
      this.$v.$reset()
      this.getDetails()
      if (this.addSidebar) {
        this.hideRightbar()
        this.addSidebar = false
      }
    },
    getDetails () {
      PortalFundService.get(this.id)
      .then((response) => {
        this.fund = response.data
        this.onScreenFundName = this.fund.fundName
        this.dealCurrencyObj = this.allCurrencies.filter(t => t.currencyId === this.fund.dealCurrencyId)
        this.geographicFocusObj = this.allGeoFocuses.filter(t => t.geographicId === this.fund.geographicFocusId)
        const ss = this.fund.subSectorId ? this.fund.subSectorId : 0
        this.strategyObj = this.allStrategies.filter(t => t.sectorId === this.fund.sectorId && t.subSectorId === ss)
        if (this.fund.umbFundId > 0) {
          FundService.get(this.fund.umbFundId).then((responseMain) => {
            if (responseMain.data != null) {
              this.fundUmbrella = responseMain.data
            }
          })
        }
      }).then(() => {
        this.fetchActivities()
      }).then(() => {
        this.asyncDataStatus_fetched('Portal Deal Details')
      }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    getTables () {
      this.fetchAllUsers()
      this.fetchAllCurrencies()
      this.fetchAllGeoFocus()
      this.fetchAllStrategies()
    },
    fetchActivities () {
      ActivityService.getPortalFundActivities(this.fund.fundId).then(
        (response) => {
          if (response.data.length > 0) {
            this.activities = response.data
          } else {
            this.activities = null
          }
        }
      )
    },
    showActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true)
    },
    editActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true, true)
    },
    clickAddActivity () {
      this.$store.commit('setNewActivity', this.fund.fundId)
      this.sidebarAction('activity', true, true, true)
    },
    onActivityUpdate () {
      this.fetchActivities()
      this.sidebarAction('', false, false)
    },
    importCurrency (currency) {
      this.dealCurrencyObj = currency
    },
    importGeoFocus (geoFocus) {
      this.geographicFocusObj = geoFocus
    },
    importStrategy (strategy) {
      this.strategyObj = strategy
    },
    onCancelAdminEdit() {
      this.$router.push({name: 'PortalDealsDetail', params: {id: this.fund.fundId}})
    },
    geoFocusSelected (value, id) {
      this.$v.geographicFocus.$touch()
      this.fund.geographicFocusId = value ? value.geographicId : null
    },
    dealCurrencySelected (value, id) {
      this.fund.dealCurrencyId = value ? value.currencyId : null
    },
  },
  created () {
    this.addSidebar = false
    this.hideRightbar()
    this.getTables()
    this.getDetails()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allStrategies () {
      return this.$store.state.allStrategies
    },
    allUsers () {
      return this.$store.state.allUsers
    },
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    symbol () {
      if (this.dealCurrencyObj != null) {
        return this.dealCurrencyObj.length > 0 ? this.dealCurrencyObj[0].symbol : this.dealCurrencyObj.symbol
      }
      return ''
    },
    canbeEdited () {
      return this.user.groupId < 11 && (this.fund.umbFundId === 0 || !this.fund.umbrellaCheck)
    },
    smTabHasError () {
      return false
    },
    uSocialResponsible () {
      if (this.fundUmbrella == null) {
        return false
      }
      return this.fundUmbrella.isSocialResponsible !== null ? this.fundUmbrella.isSocialResponsible : false
    },
    isNavAdmin () {      
      return this.user.roles.filter(x => x.roleId === this.$userRoles.NAVIGATOR_ADMINISTRATOR).length > 0
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
