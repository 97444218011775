export default {
  ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT, 
  API_URL : process.env.VUE_APP_API_URL,
  REPORT_SERVER: process.env.VUE_APP_REPORT_SERVER,
  VERSION: process.env.VUE_APP_VERSION,  
  ENABLE_CLOUD: process.env.VUE_APP_ENABLE_CLOUD,
  AUTHORITY: process.env.VUE_APP_AUTHORITY,
  CLIENTID: process.env.VUE_APP_CLIENTID,
  GRAPH_SCOPES: process.env.VUE_APP_GRAPH_SCOPES.split(','),
  API_SCOPES: process.env.VUE_APP_API_SCOPES.split(','),
  REDIRECT_URI: process.env.VUE_APP_REDIRECT_URI,  
  APPINSIGHT_INSTRUMENTATION_KEY: process.env.VUE_APP_APPINSIGHT_INSTRUMENTATION_KEY,
  USER_ROLES: Object.freeze({
    BUSINESS_COMMITTEE: 1, //
    LEGAL_TEAM_WORKFLOW: 2, //
    INVESTMENT_COMMITTEE: 3, //
    SYSTEM_ADMINISTRATOR: 4, //
    SOURCING1: 5,
    SOURCING2: 6,
    SOURCING3: 7,
    SOURCING4: 8,
    SOURCING5: 9,
    SOURCING6: 10,
    MEETING_MINUTE_TAKER: 11,
    CONNECT_ADMINISTRATOR: 12,
    ADVISORY_PORTFOLIO_ADMINISTRATOR: 13,
    OPS_TEAM_ADMINISTRATOR: 14,
    DISCRETIONARY_PORTFOLIO_ADMINISTRATOR: 15, //
    MONITORING_COMMENTS_ASSIGNMENT: 16,
    MONITORING_COMMENTS_ASSIGNMENT_EUROPE: 17,
    MARKETABLE_ALTERNATIVES_MANAGER_RESEARCH_COMMITTEE: 18,
    PRIVATE_MARKETS_INVESTMENT_COMMITTEE: 19, //
    FIRM_OUTREACH: 20,
    TASK_MANAGER: 21,
    NAVIGATOR_ADMINISTRATOR: 22
  })
}