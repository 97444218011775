<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'calmeeting-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Meetings'" :icon="'mdi mdi-timetable'"/>
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box table-responsive">
                                <h4 class="m-t-0 header-title mb-4">Meeting List</h4>                                
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-5 form-inline">
                                      <date-picker v-model="startDate" @input="startSearch" placeholder="Select Date" calendar-class="calendar form-control" input-class="form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                      <label class="col-form-label ml-2">
                                        Only Invited
                                        <toggle-button v-model="onlyInvited" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="submitSearch"/>
                                      </label>
                                      <label class="col-form-label ml-2">
                                        Only Attending
                                        <toggle-button v-model="onlyAttending" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="submitSearch"/>
                                      </label>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <div class="">
                                            <label>
                                                <input type="search" v-model="searchName" class="form-control" placeholder="Search Title" @keyup.enter="startSearch">
                                            </label>
                                            <button @click="startSearch" class="btn btn-icon waves-effect waves-light btn-primary m-b-5"> <i class="fa fa-search"></i> </button>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                            <multiselect id="fundId" v-model="dealObj" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search Deals"
                                              @input="startSearch" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                                    </div>
                                    <div class="col-sm-12 col-md-1">
                                        <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                    </div>
                                </div>

                                <table class="table table-bordered table-hover table-sortable table-striped">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)"> Title
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)"> Start Date
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(2)"> Avg Score
                                          <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(3)"> Organizer
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(4)"> Location
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(5)"> Type
                                          <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(6)"> Deal
                                          <i v-if="sortIndex == 6 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 6 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                         
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="item in meetings" :key="item.meetingId">
                                        <td>
                                            <router-link :to="{name: 'CalendarDetail', params: {id: item.meetingId}}">
                                                {{item.title}} <span v-if="item.isCancelled" class="badge badge-pink">Canceled</span>
                                            </router-link>
                                        </td>
                                          <td>
                                              <AppDate :date="item.startTime" :show="'text'"/>
                                          </td>
                                          <td class="text-center">
                                            {{item.avgScore > 0 ? item.avgScore : '-'}}
                                          </td>
                                          <td>
                                            {{item.organizer}}
                                          </td>
                                          <td>
                                            {{item.locationName}}
                                          </td>
                                          <td>
                                            {{item.typeName}}
                                          </td>
                                          <td>
                                            {{item.fundName}}
                                          </td>
                                      </tr>
                                    </tbody>
                                </table>
                                
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div role="status">Page {{currentPage}} of {{totalPages}} - {{totalRecords}} requests</div>
                                    <div class="px-3">
                                          <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                              <option value="250">250</option>
                                          </select>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- end container -->
            </div>
            <!-- end content -->

            <Footer />
        </div>
    </div>
</template>

<script>
import { CalMeetingService } from '@/services/calmeeting-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import DatePicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    DatePicker,
    Multiselect
  },
  data () {
    return {
      meetings: null,
      searchName: '',
      onlyInvited: false,
      onlyAttending: false,
      startDate: null,
      dealObj: null,
      searchFunds: [],
      isSearchingList: false,
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      sortBy: ['Title', 'startTime', 'AvgScore', 'Organizer', 'LocationName', 'TypeName', 'FundName'],
      sortIndex: 1,
      sortType: 'DESC',
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    ...mapActions(['searchAllFunds']),
    getList () {
      const filters = {
        'pageSize': this.pageSize
      }
      
      localStorage.setItem('umb_calmeetings_filters', JSON.stringify(filters))
      this.isLoading = true
      const attendeeId = this.onlyAttending ? this.user.userId : null
      const userId = this.onlyInvited ? this.user.userId : null
      let dt = ''
      let fund = null
      if (this.startDate != null) {
        // dt = new Date(this.startDate).toISOString()
        dt = moment(this.startDate).format('M/D/YYYY')
      }
      if (this.dealObj != null) {
        fund = this.dealObj.fundId
      }
      CalMeetingService.search(this.searchName, attendeeId, userId, dt, fund, this.sortBy[this.sortIndex], this.sortType, this.currentPage, this.pageSize)
      .then((response) => {
        this.isLoading = false
        this.meetings = response.data.meetings
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Meetings List') })
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.meetings = []

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    resetFilter () {
      this.startDate = null
      this.onlyInvited = false
      this.onlyAttending = false
      this.searchName = ''
      this.dealObj = null
      this.startSearch()
    }
  },
  mounted () {
    const oldFilters = JSON.parse(localStorage.getItem('umb_calmeetings_filters'))
    if (oldFilters != null) {
      if (oldFilters.pageSize != null) {
        this.year = oldFilters.year
      }
      if (oldFilters.pageSize != null) {
        this.month = oldFilters.month
      }
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
    }
    this.startSearch()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    hasFilter () {
      return this.startDate != null || this.onlyInvited || this.onlyAttending || this.searchName.length > 0 || this.dealObj != null
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
