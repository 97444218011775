<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portal-diversity'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Navigator'" :icon="'mdi mdi-web'"/>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box table-responsive">
                                <h4 class="header-title">Diversity list</h4>
                                <!-- <p class="text-muted font-14 m-b-30">
                                    Diversity list
                                </p> -->
                                
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-6">
                                      <label class="col-form-label">
                                        <select class="form-control form-control-sm" v-model="diversityCat" @change="startSearch">
                                            <option value="0">All</option>
                                            <option value="1">Only Substantially</option>
                                            <option value="2">Only Majority</option>
                                        </select>
                                        <!-- <span class="mr-1">Show only new items</span>
                                        <toggle-button v-model="onlyNew" :sync="true" :labels="{checked: ' ', unchecked: ' '}" @input="startSearch"/> -->
                                      </label>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <!-- <div>
                                            <label>
                                                <input type="search" v-model="searchName" class="form-control" placeholder="Search by name" @keyup.enter="startSearch">
                                            </label>
                                            <button @click="startSearch" class="btn btn-icon waves-effect waves-light btn-primary m-b-5"> <i class="fa fa-search"></i> </button>
                                            <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                        </div> -->
                                    </div>
                                </div>

                                <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)"> Firm
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)"> Total Count
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(2)"> Owned By Disabled
                                          <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(3)"> Owned By Veterans
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(4)"> Diverse
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(5)"> Submit Date
                                          <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                            
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="diversity in diversities"
                                          :key="diversity.firmId">
                                          <td>
                                              <router-link :to="{name: 'PortalDiversityDetail', params: {id: diversity.firmId}}">
                                                  {{ diversity.firmName }}
                                              </router-link>
                                          </td>
                                          <td>{{ diversity.totalCnt }}</td>
                                          <td>{{ diversity.ownedByDisabled ? 'Yes' : 'No' }}</td>
                                          <td>{{ diversity.ownedByVeterans ? 'Yes' : 'No' }}</td>
                                          <td>{{ diversityCategory(diversity.diversityPercent) }}</td>
                                          <td>
                                            <AppDate :date="diversity.createdOn" :show="'text'" />
                                            <span class="badge badge-warning">New</span>
                                          </td>
                                      </tr>
                                    </tbody>
                                </table>
                   
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div role="status">Page {{currentPage}} of {{totalPages}} - {{totalRecords | thousandNumber}} Diversities</div>
                                    <div class="px-3">
                                          <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                              <option value="250">250</option>
                                          </select>
                                    </div>
                                    
                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end container -->
        </div>
        <!-- end content -->

        <Footer />
    </div>
</template>

<script>
import { PortalDiversityService } from '@/services/portal-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
  },
  data () {
    return {
      diversities: [],
      isLoading: null,
      onlyNew: false,
      searchName: '',
      pageSize: 25,
      diversityCat: 0,
      currentPage: 1,
      sortBy: ['FirmName', 'TotalCnt', 'OwnedByDisabled', 'OwnedByVeterans', 'DiversityPercent', 'CreatedOn'],
      sortIndex: 0,
      sortType: 'ASC',
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0
    }
  },
  methods: {
    getList () {
      const filters = {
        'searchName': this.searchName,
        'diversityCat': this.diversityCat,
        'pageSize': this.pageSize
      }
      
      localStorage.setItem('umb_portal_diversities_filters', JSON.stringify(filters))
      this.isLoading = true
      PortalDiversityService.search(null, this.onlyNew, this.diversityCat, this.sortBy[this.sortIndex], this.sortType, this.currentPage, this.pageSize)
      .then((response) => {
        this.isLoading = false
        this.diversities = response.data.diversities
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Diversity Lists') })
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.diversities = []
      if (this.searchName == null) {
        this.searchName = ''
      }

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    resetFilter () {
      this.searchName = ''
      this.startSearch()
    },
    diversityCategory (percent) {
      if (percent < 25) {
        return 'No'
      } else if (percent >= 25 && percent <= 50) {
        return 'Substantially'
      } else {
        return 'Majority'
      }
    }
  },
  mounted () {
    const oldFilters = JSON.parse(localStorage.getItem('umb_portal_diversities_filters'))
    if (oldFilters != null) {
      if (oldFilters.searchName != null) {
        this.searchName = oldFilters.searchName
      }
      if (oldFilters.diversityCat != null) {
        this.diversityCat = oldFilters.diversityCat
      }
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
    }
    this.startSearch()
  },
  computed: {
    hasFilter () {
      return this.searchName.length > 0
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  },
  mixins: [asyncDataStatus]
}
</script>
