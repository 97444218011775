<template>
    <!-- typo below? -->
    <div class="col-lg-6 col-ms-12" v-if="sortedList.length > 0">
        <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
        <div class="card-box">
            <h4 class="header-title m-t-0">Voting</h4>
            <p class="text-muted m-b-25 font-13">
              Active actions assigned to you ({{sortedList.length}})
            </p>
              <div class="table-responsive">
                  <div class="inbox-widget1 notes-box" style="overflow: auto; outline: none;">
                    <table class="table mb-0 table-sortable table-striped">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th @click="setOrder(0)">Name
                              <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                              <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                            </th>
                            <th @click="setOrder(1)">Due Date
                              <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                              <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                            </th>
                            <th @click="setOrder(2)">Vote
                              <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                              <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in sortedList" :key="item.fundId">
                            <td>{{index + 1}}</td>
                            <td>
                              <router-link :to="{name: routeName(item.typeId), params: {id: item.voteId}}">
                                {{item.voteName}}
                              </router-link>
                            </td>
                            <td>
                              <AppDate :date="item.sortDate" :show="'text'"/><br/>
                            </td>
                            <td><span class="badge" :class="stageClass(item.typeId)">{{item.typeName}}</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DashService } from '@/services/dash-service'

export default {
  data () {
    return {
      list: [],
      isLoading: true,
      sortIndex: 1,
      sortType: 'ASC'
    }
  },
  computed: {
    sortedList () {
      let result = this.list
      // Sort
      switch (this.sortIndex) {
        case 0:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.voteName > b.voteName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.voteName < b.voteName) ? 1 : -1)
          }
          break
        case 1:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.sortDate > b.sortDate) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.sortDate < b.sortDate) ? 1 : -1)
          }
          break
        case 2:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.typeId > b.typeId) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.typeId < b.typeId) ? 1 : -1)
          }
          break
        case 3:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.fundTeamRoleName > b.fundTeamRoleName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.fundTeamRoleName < b.fundTeamRoleName) ? 1 : -1)
          }
          break
      }
      return result
    }
  },
  created () {
    DashService.getActiveVotes()
    .then((response) => {
      this.list = response.data
      this.isLoading = false
    })
  },
  methods: {
    routeName (typeId) {
      let name = ''
      switch (typeId) {
        case 1:
        case 2:
          name = 'PortfolioVotingDetail'
          break
        case 3:
          name = 'Allocation'
          break
        case 4:
          name = 'IcMeetingMrcPM'
          break
        case 5:
          name = 'IcMeetingIcPM'
          break
        case 6:
          name = 'IcMeetingMrcMA'
          break
        case 13:
          name = 'FundsFlrVoting'
          break
        case 14:
          name = 'FundsRrVoting'
          break
        case 20:
          name = 'FundsDetail'
          break
      }
      return name
    },
    stageClass (typeId) {
      let name = ''
      switch (typeId) {
        case 1:
        case 2:
          name = 'badge badge-purple'
          break
        case 3:
          name = 'stage1-2'
          break
        case 4:
        case 5:
        case 6:
          name = 'text-white bg-pink'
          break
        case 13:
          name = 'text-white stage3'
          break
        case 14:
          name = 'text-white stage4'
          break
        case 20:
          name = 'badge badge-warning'
          break
      }
      return name
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
    }
  }
}
</script>