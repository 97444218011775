<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show" @click.prevent="expandCollapse">
              <i class="mdi mdi-arrow-expand-left pull-left" v-if="!isExpand" v-tooltip="'Expand'"></i> 
              <i class="mdi mdi-arrow-expand-right pull-left" v-if="isExpand" v-tooltip="'Collapse'"></i>
              Allocation Rationale
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="allocationRationale">
      <div class="form-group">
          <label>Portfolio: <span class="text-primary">{{allocationRationale.portfolioName}}</span></label>
      </div>

      <div class="form-group">
          <label for="portfolioSlotId">Allocation Request <span v-show="isEditMode" class="text-danger">*</span></label>
          <select class="form-control" v-model="allocationRationale.portfolioSlotId" :disabled="!isEditMode">
            <option value="0">None</option>
            <option v-for="item in itemsFilter" :key="item.portfolioSlotId" :value="item.portfolioSlotId">{{item.symbol}}{{item.targetPosition}} {{item.typeName}} | {{item.geographicName}} | {{item.vintageYear}} | {{item.sectorName}} | SSID #{{item.portfolioSlotId}}</option>
          </select>
      </div>

      <div class="form-group">
          <label>Attributable Source</label>
          <toggle-button v-model="allocationRationale.isAttributable" :sync="true" :disabled="!isEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
      </div>
      <div class="form-group" v-if="stageId > 4">
          <label>Final Request</label>
          <toggle-button v-model="allocationRationale.isFinalized" :sync="true" :disabled="!isEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
      </div>

      <div class="form-group">
          <label for="rationale">Portfolio Recommendation & Rationale<span v-show="isEditMode && stageId > 4 && allocationRationale.allocationRequest > 0" class="text-danger">*</span></label>
          <span :class="{ 'parsley-error-radio': $v.allocationRationale.rationale.$error }">
            <textarea class="form-control" id="rationale" rows="5" :disabled="!isEditMode" v-model="allocationRationale.rationale"></textarea>
          </span>
          <template v-if="$v.allocationRationale.rationale.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.allocationRationale.rationale.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>
      <div class="form-group">
          <label for="objective">Portfolio Objective</label>
          <div class="divbox">{{allocationRationale.objective}}</div>
      </div>
      
      <div class="form-group">
          <label>IC Meeting Contingencies</label>
          <div class="divbox" v-html="allocationRationale.approvalContingencies"></div>
      </div>

      <div v-if="allocationRationale.updatedOn">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
            <div class="form-group" v-if="allocationRationale.updatedOn">
                <label>Modified On</label>
                <AppDate :date="allocationRationale.updatedOn" :show="'input'"/>
            </div>
            <div class="form-group" v-if="allocationRationale.updatedOn">
                <label>Modified By</label>
                <AppUser :userId="allocationRationale.updatedBy" :emptyIfNull="true"/>
            </div>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success btn-custom waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { AllocationRationaleService } from '@/services/allocation-service'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    allocationRationale: {
      required: true,
      type: Object
    },
    items: {
      required: true,
      type: Array
    },
    stageId: {
      required: true,
      type: Number
    },
    fundTypeId: {
      required: true,
      type: Number
    },
    fundSectorId: {
      required: true,
      type: Number
    },
    fundGeoId: {
      required: true,
      type: Number
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      showLogs: false,
      isExpand: false
    }
  },
  mixins: [rightbar],
  validations: {
    allocationRationale: {
      rationale: { required: requiredIf(function () { return this.stageId > 4 }), min: minLength(1), max: maxLength(2000) }
    }
  },
  methods: {
    close () {
      if (this.isExpand) {
        this.collapse()
      }
      this.isExpand = false
      this.$v.allocationRationale.$reset()
      this.hideRightbar()
    },
    onSave () {
      this.$v.allocationRationale.$touch()
      if (this.$v.allocationRationale.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      AllocationRationaleService.save(this.allocationRationale.allocationRationaleId, this.allocationRationale)
        .then((response) => {
          if (response.status !== 200) {
            this.$snotify.error('There was an error! Contact Admin')
          } else {
            this.$snotify.success('Allocation Rationale updated')
            this.$emit('onUpdate')
          }
        })
    },
    expandCollapse () {
      if (this.isExpand) {
        this.isExpand = false
        this.collapseRightbar()
      } else {
        this.expandRightbar()
        this.isExpand = true
      }
    }
  },
  computed: {
    // allocationRationaleCopy () {
    //   return {...this.allocationRationale}
    // }
    itemsFilter () {
      return this.items.filter(x => x.portfolioId === this.allocationRationale.portfolioId)
    }
  }
}
</script>

<style>
.divbox {
  border: 1px solid;
}
</style>