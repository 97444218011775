<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Add Track Record
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="fund.TargetFund">Select Deal <span v-show="isAdd" class="text-danger">*</span></label>
          <input type="text" v-model="fundTrackRecord" class="form-control" id="fund.TargetFund" v-show="!isAdd" readonly="readonly">
          <multiselect id="fund.TargetFund" v-model="fundTrackRecordObj" v-show="isAdd" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search"
            @input="fundSelected" @open='$v.fundTrackInfo.$touch()' @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
          <template v-if="$v.fundTrackInfo.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.fundTrackInfo.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
          <button class="btn btn-icon waves-effect waves-light btn-primary mt-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
          <button class="btn btn-success waves-effect w-md waves-light mt-2 ml-1" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Add</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    isEditMode: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: null
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect
  },
  validations: {
    fundTrackInfo: { required }
  },
  data () {
    return {
      searchFunds: [],
      isSearchingList: false,
      fundTrackRecordObj: null,
      radioNum: 1,
      currentTrackRecord: null,
      fundTrackInfo: {}
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['addFundTrackRecord', 'searchAllFunds']),
    close () {
      this.hideRightbar()
    },
    fundSelected (value, id) {
      this.fundTrackRecord.targetFundId = value ? value.fundId : null
      this.fundTrackInfo = {fundId: this.fundTrackRecord.fundId, trackReportFundId: this.fundTrackRecord.targetFundId}
    },
    onSave () {
      if (this.list.some(obj => obj.trackReportFundId === this.fundTrackRecord.targetFundId)) {
        this.$snotify.error('Track record already added.')
        return
      }
      if (this.$v.fundTrackInfo.$invalid) {
        this.$snotify.error('Please select a deal.')
        return
      }
      this.addFundTrackRecord({fundTrackRecord: this.fundTrackInfo})
        .then((response) => {
          if (response == null) {
            this.$snotify.error('Server Error!')
          } else {
            this.$snotify.success('Track Record Updated.')
            this.$emit('onUpdate')
          }
        })
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    }
  },
  computed: {
    fundTrackRecord () {
      return this.$store.state.rb_trackRecord
    }
  }
}
</script>
