<template>
  <div>
    <Topbar />
    <Sidebar :activeItem="'reports'" />

    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid">
          <PageTitle :title="'Reports'" :icon="'mdi mdi-chart-bar'" />
          <div class="row">
            <div class="col-lg-12">
              <div class="card-box table-responsive">
                <div class="row"  v-if="!displayReport">
                  <h4 class="header-title">Reports List</h4>
                </div>
                <div class="row m-b-5" v-if="displayReport">
                  <div class="col-sm-6"><h4 class="header-title">{{reportTitle}}</h4></div>
                  <div class="col-sm-6"><button @click="back()" class="btn btn-primary btn-mini pull-right" v-if="displayReport"><i class="mdi mdi-arrow-left-bold-box-outline"></i>Back to List</button></div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <PowerBIReportEmbed
                      v-if="displayReport"
                      :embed-config="PowerBIReportConfig"
                      :phased-embedding="phasedEmbeddingFlag"
                      :css-class-name="reportClass"
                      :event-handlers="eventHandlersMap"
                      @report-obj="setReportObj"
                    >
                    </PowerBIReportEmbed>
                  </div>
                </div>

                <table
                  class="
                    table table-striped table-bordered table-hover
                    no-footer
                    table-sortable
                  "
                  v-if="!displayReport"
                >
                  <thead>
                    <tr>
                      <th @click="setOrder(0)">
                        Name
                        <i
                          v-if="sortIndex == 0 && sortType == 'ASC'"
                          class="mdi mdi-arrow-up-drop-circle-outline"
                        ></i>
                        <i
                          v-if="sortIndex == 0 && sortType == 'DESC'"
                          class="mdi mdi-arrow-down-drop-circle-outline"
                        ></i>
                      </th>
                      <th @click="setOrder(1)">
                        Description
                        <i
                          v-if="sortIndex == 1 && sortType == 'ASC'"
                          class="mdi mdi-arrow-up-drop-circle-outline"
                        ></i>
                        <i
                          v-if="sortIndex == 1 && sortType == 'DESC'"
                          class="mdi mdi-arrow-down-drop-circle-outline"
                        ></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="isLoading">
                      <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                    </tr>
                    <tr v-for="report in reports" :key="report.reportId">
                      <td>
                        <a
                          href="javascript:void()"
                          @click="
                            showReport(report.powerBiReportId, report.reportName)
                          "
                        >
                          {{ report.reportName }}
                          <span
                            v-if="
                              new Date().getTime() - 86400000 * 10 <
                              new Date(report.createdOn).getTime()
                            "
                            class="badge badge-success push-right"
                            >New</span
                          >
                        </a>
                      </td>
                      <td>{{ report.reportDesc }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end container -->
    </div>
    <!-- end content -->

    <Footer />
  </div>
</template>

<script>
import asyncDataStatus from "@/mixins/asyncDataStatus";
import { ReportService } from "@/services/report-service";
import Constants from "@/constants";

import { PowerBIReportEmbed } from "powerbi-client-vue-js";
//import { defineComponent } from '@vue/composition-api'

// Flag which specifies whether to use phase embedding or not
const phasedEmbeddingFlag = false;

// CSS Class to be passed to the wrapper
const reportClass = "report-container";

let powerBIReportId = "";
let embedToken = "";
let embedUrl = "";

export default {
  components: {},
  data() {
    return {
      reports: null,
      searchName: "",
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      sortBy: ["ReportName", "ReportDesc"],
      sortIndex: 0,
      sortType: "ASC",
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0,
      displayReport: false,
      reportTitle:'',
      reportConfig: {
        type: "report",
        embedUrl: undefined,
        tokenType: 1,
        accessToken: undefined,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
          },
          background: 1, //models.BackgroundType.Transparent,
        },
      },
      // Store Embed object from Report component
      report: null,
      reportClass,
      phasedEmbeddingFlag,
      /**
       * Map of event handlers to be applied to the embedded report
       * Update event handlers for the report by redefining the map using this.eventHandlersMap
       * Set event handler to null if event needs to be removed
       * More events can be provided from here
       * https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/handle-events#report-events
       */
      eventHandlersMap: new Map([
        ["loaded", () => console.log("Report has loaded")],
        ["rendered", () => console.log("Report has rendered")],
        [
          "error",
          (event) => {
            if (event) {
              console.error(event.detail);
            }
          },
        ],
        ["visualClicked", () => console.log("visual clicked")],
        ["pageChanged", (event) => console.log(event)],
      ]),
    };
  },
  mixins: [asyncDataStatus],
  methods: {
    back() {
      this.displayReport = false;
    },
    getList() {
      this.isLoading = true;
      ReportService.getAll()
        .then((response) => {
          this.reports = response.data;
          this.isLoading = false;
          // this.totalRecords = response.data.totalRecords
          // if (this.totalRecords > 0) {
          //   this.totalPages = response.data.totalPages
          // } else {
          //   this.totalPages = 0
          //   this.currentPage = 0
          // }
        })
        .then(() => {
          this.asyncDataStatus_fetched("Reports List");
        });
    },
    startSearch() {
      this.currentPage = 1;
      this.submitSearch();
    },
    submitSearch() {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
      }

      this.reports = [];
      if (this.searchName == null) {
        this.searchName = "";
      }

      this.searchTimeout = setTimeout(() => {
        this.getList();
      }, 250);
    },
    showReport(reportId, title) {
      this.isLoading = true;
      this.reportTitle = title
      ReportService.getPowerBIEmbedInfo(reportId)
        .then((response) => {
          let embedInfo = response.data;

          this.displayReport = true;
          powerBIReportId = embedInfo.embedReport[0].reportId;
          embedUrl = embedInfo.embedReport[0].embedUrl;
          embedToken = embedInfo.embedToken.token;

          this.reportConfig = {
            ...this.reportConfig,
            id: powerBIReportId,
            embedUrl: embedUrl,
            accessToken: embedToken,
          };

          this.isLoading = false;
        })
        .then(() => {
          this.asyncDataStatus_fetched("Reports List");
        });
    },
    /**
     * Assign Embed Object from Report component to report
     * @param value
     */
    setReportObj(value) {
      this.report = value;
    },
    setOrder(id) {
      // if (this.sortIndex === id) {
      //   this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      // } else {
      //   this.sortIndex = id
      //   this.sortType = 'ASC'
      // }
      // this.startSearch()
    },
  },
  created() {
    // this.getList()
    this.startSearch();
  },
  computed: {
    ReportServerUrl() {
      return Constants.REPORT_SERVER;
    },
    PowerBIReportConfig() {
      return this.reportConfig;
    },
  },
};
</script>
<style>
.embed-report {
  margin-top: 18px;
  text-align: center;
  margin-right: 0;
}

iframe {
  border: none;
}

.report-container {
  height: 75vh;
  margin: auto;
  width: 100%;
}
</style>