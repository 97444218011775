<template>
    <footer class="footer">
        {{year}} &copy; Wilshire Private Markets❤️ Version: {{version}}
    </footer>
</template>

<script>
import Constants from '../constants'

export default {
  data () {
    return {
      year: new Date().getFullYear(),
      version: Constants.VERSION
    }
  }
}
</script>
