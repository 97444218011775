<template>
  <tbody>
    <tr v-if="meetings === null">
      <td colspan="5" class="text-center">No Meeting to show</td>
    </tr>
    <tr v-for="meeting in meetings"
        :key="meeting.meetingId">
        <td><a @click.prevent="select(meeting.meetingId)" href="javascript:void(0)"><AppDate :date="meeting.meetingDate" :show="'label'"/></a></td>
        <td>{{meeting.city}}</td>
        <td v-if="user.groupId < 11">
            <button class="btn btn-success btn-sm btn-mini btn-icon mr-1" @click.prevent="update(meeting.meetingId)"><i class="mdi mdi-lead-pencil"></i></button>
            <button class="btn btn-danger btn-sm btn-mini btn-icon" @click.prevent="onDelete(meeting.meetingId)"><i class="mdi mdi-delete"></i></button>
        </td>
    </tr>
    <tr>
      <td colspan="5">
        <button v-if="!isEditMode && user.groupId < 11" class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addNew"> <i class="mdi mdi-calendar-plus"></i> Add</button>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rightbar from '@/mixins/rightbar'

export default {
  props: {
    meetings: {
      type: Array,
      default: null
    },
    isEditMode: {
      required: true,
      type: Boolean
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['deleteFundsOnsiteMeetings']),
    addNew () {
      this.hideRightbar()
      this.$emit('onClickNew')
    },
    select (id) {
      this.$emit('onSelect', id)
    },
    update (id) {
      this.$emit('onSelectUpdate', id)
    },
    onDelete (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deleteFundsOnsiteMeetings({meetingId: id})
                .then((response) => {
                  this.$snotify.success('Onsite Meeting Deleted.')
                  this.$emit('onUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  }
}
</script>
