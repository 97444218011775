<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'contacts-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="contact">

                    <PageTitle :title="'Contact Details - '+contact.fullName" :icon="'mdi mdi-contacts'" 
                    :showToolbar="true" :inEditMode="inEditMode" :canDelete="canbeDeleted && canbeEdited" :canEdit="canbeEdited" :isSaving="inSaving"
                    @onEdit="EditForm"
                    @onCancel="cancelForm"
                    @onDelete="deleteForm"
                    @onSave="saveForm" />
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-box">
                                <ul class="nav nav-tabs tabs-bordered">
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(1)" :class="{'active': activeTab == 1}">
                                            <i class="mdi mdi-information-outline"></i> Summary
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="true" class="nav-link" @click="showTab(2)" :class="{'active': activeTab == 2}">
                                            <i class="mdi mdi-contact-mail"></i> Contact Info
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(3)" :class="{'active': activeTab == 3}">
                                            <i class="mdi mdi-ticket-account"></i> More
                                        </a>
                                    </li>
                                </ul>
                                
                                <div class="tab-content">
                                    <div class="tab-pane fade" :class="{'active show': activeTab == 1}">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label for="FirstName">First Name</label>
                                                    <input type="text" v-model.trim="contact.firstName" placeholder="Enter First Name" class="form-control" id="FirstName" :disabled="!inEditMode" 
                                                      @blur="$v.contact.firstName.$touch()" :class="{ 'parsley-error': $v.contact.firstName.$error }"/>
                                                    <template v-if="$v.contact.firstName.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.contact.firstName.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="LastName">Last Name<span v-show="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="contact.lastName" placeholder="Enter Last Name" class="form-control" id="LastName" :disabled="!inEditMode"
                                                      @blur="$v.contact.lastName.$touch()" :class="{ 'parsley-error': $v.contact.lastName.$error }">
                                                    <template v-if="$v.contact.lastName.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.contact.lastName.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.contact.lastName.min" class="parsley-required">This value is too short</li>
                                                        <li v-if="!$v.contact.lastName.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="FirmId">General Partner<span v-show="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model="contact.firmName" class="form-control" id="FirmId" v-show="!inEditMode" readonly="readonly">
                                                    <span :class="{ 'invalid': $v.contact.firmId.$error }">
                                                      <multiselect id="FirmId" v-model="firmObj" :disabled="!inEditMode" :options="searchFirms" label="firmName" track-by="firmId" :allow-empty="false" v-show="inEditMode" placeholder="Type to Search"
                                                        @input="firmSelected" @search-change="asyncSearchFirm" :loading="isSearchingList" :options-limit="50"></multiselect>
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <label for="JobTitle">Job Title</label>
                                                    <input type="text" v-model.trim="contact.jobTitle" placeholder="Enter Job Title" class="form-control" id="JobTitle" :disabled="!inEditMode" 
                                                      @blur="$v.contact.jobTitle.$touch()" :class="{ 'parsley-error': $v.contact.jobTitle.$error }"/>
                                                    <template v-if="$v.contact.jobTitle.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.contact.jobTitle.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label>Status</label>
                                                    <toggle-button v-model="contact.isActive" :sync="true" :disabled="!inEditMode" :width="65" :labels="{checked: 'Active', unchecked: 'Inactive'}"/>
                                                </div>

                                                <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                                                <div :class="{'collapse': !showLogs }">
                                                    <div class="form-group">
                                                        <label>Created On</label>
                                                        <AppDate :date="contact.createdOn"/>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Created By</label>
                                                        <AppUser :userId="contact.createdBy" />
                                                    </div>
                                                    <div class="form-group" v-if="contact.updatedOn">
                                                        <label>Modified On</label>
                                                        <AppDate :date="contact.updatedOn" :emptyIfNull="true"/>
                                                    </div>
                                                    <div class="form-group" v-if="contact.updatedOn">
                                                        <label>Modified By</label>
                                                        <AppUser :userId="contact.updatedBy" :emptyIfNull="true"/>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-lg-6">
                                              <ul class="nav nav-tabs tabs-bordered">
                                                  <li class="nav-item">
                                                      <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeLTab == 3}" @click="showLTab(3)">
                                                        Notes <span class="badge badge-primary noti-icon-badge" v-if="activities">{{activities.length}}</span></a>
                                                  </li>
                                                  <li class="nav-item">
                                                      <a href="javascript:void(0)" class="nav-link" :class="{'active': activeLTab == 1}" @click="showLTab(1)">
                                                          Related Deals <span class="badge badge-primary noti-icon-badge" v-if="linkFunds">{{linkFunds.length}}</span>
                                                      </a>
                                                  </li>
                                                  <li class="nav-item">
                                                      <a href="javascript:void(0)" class="nav-link" :class="{'active': activeLTab == 2}" @click="showLTab(2)">
                                                          Related Firms <span class="badge badge-primary noti-icon-badge" v-if="linkFirms">{{linkFirms.length}}</span>
                                                      </a>
                                                  </li>
                                                  <li class="nav-item">
                                                      <a href="javascript:void(0)" class="nav-link" :class="{'active': activeLTab == 4}" @click="showLTab(4)">
                                                          Related Meetings <span class="badge badge-primary noti-icon-badge" v-if="linkCalMeetings">{{linkCalMeetings.length}}</span>
                                                      </a>
                                                  </li>
                                              </ul>
                                              <div class="tab-content">
                                                  <div class="tab-pane fade" :class="{'show active': activeLTab == 1}">
                                                      <table class="table table-bordered table-hover">
                                                          <thead>
                                                            <tr>
                                                              <th>Deal Name <i class="mdi mdi-open-in-new"></i></th>
                                                            </tr>
                                                          </thead>
                                                        <LinkFundList :funds="linkFunds" />
                                                      </table>
                                                  </div>
                                                  <div class="tab-pane fade" :class="{'show active': activeLTab == 2}">
                                                      <table class="table table-bordered table-hover">
                                                          <thead>
                                                            <tr>
                                                              <th>Firm Name <i class="mdi mdi-open-in-new"></i></th>
                                                            </tr>
                                                          </thead>
                                                        <LinkFirmList :firms="linkFirms" />
                                                      </table>
                                                  </div>
                                                  <div class="tab-pane fade" :class="{'active show': activeLTab == 3}">
                                                      <div class="tab-pane show active">
                                                          <ActivityList :activities="activities" :isEditMode="inEditMode" @onUpdate="onActivityUpdate" @onClickNew="clickAddActivity" @onSelect="showActivity" @onSelectUpdate="editActivity" :isShowIndirects="false"/>
                                                      </div>
                                                  </div>
                                                  <div class="tab-pane fade" :class="{'show active': activeLTab == 4}">
                                                    <span v-if="isLoadinglinkCalMeetings">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                      <table class="table table-striped table-hover">
                                                          <thead>
                                                            <tr>
                                                              <th>Date<i class="mdi mdi-open-in-new"></i></th>
                                                              <th># Attendees</th>
                                                              <th>Avg. Score</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr v-if="linkCalMeetings.length == 0">
                                                              <td class="text-center" colspan="3">No Meeting to show</td>
                                                            </tr>
                                                            <tr v-for="(item, index) in linkCalMeetings" :key="index">
                                                              <td>
                                                                <router-link :to="{name: 'CalendarDetail', params: {id: item.meetingId}}" target="_blank">
                                                                  <AppDate :date="item.startTime" :show="'label'"/>
                                                                </router-link>
                                                              </td>
                                                              <td>{{item.cnt}}</td>
                                                              <td>{{item.scoreAvg}}</td>
                                                            </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" :class="{'active show': activeTab == 2}">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                  <label for="Email">Email</label>
                                                  <input type="text" v-model.trim="contact.email" placeholder="Enter Email" class="form-control" id="Email" :disabled="!inEditMode" 
                                                    @blur="$v.contact.email.$touch()" :class="{ 'parsley-error': $v.contact.email.$error }"/>
                                                  <template v-if="$v.contact.email.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.email.max" class="parsley-required">This value is too long</li>
                                                      <li v-if="!$v.contact.email.email" class="parsley-required">This value is not valid.</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="PhoneNo">Phone Number</label>
                                                  <input type="text" v-model.trim="contact.phoneNo" placeholder="Enter Phone Number" class="form-control" id="PhoneNo" :disabled="!inEditMode" 
                                                    @blur="$v.contact.phoneNo.$touch()" :class="{ 'parsley-error': $v.contact.phoneNo.$error }"/>
                                                  <template v-if="$v.contact.phoneNo.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.phoneNo.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="MobileNo">Mobile Number</label>
                                                  <input type="text" v-model.trim="contact.mobileNo" placeholder="Enter Mobile Number" class="form-control" id="MobileNo" :disabled="!inEditMode" 
                                                    @blur="$v.contact.mobileNo.$touch()" :class="{ 'parsley-error': $v.contact.mobileNo.$error }"/>
                                                  <template v-if="$v.contact.mobileNo.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.mobileNo.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="HomeNo">Home Number</label>
                                                  <input type="text" v-model.trim="contact.homeNo" placeholder="Enter Home Number" class="form-control" id="HomeNo" :disabled="!inEditMode" 
                                                    @blur="$v.contact.homeNo.$touch()" :class="{ 'parsley-error': $v.contact.homeNo.$error }"/>
                                                  <template v-if="$v.contact.homeNo.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.homeNo.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="FaxNo">Fax Number</label>
                                                  <input type="text" v-model.trim="contact.faxNo" placeholder="Enter Fax Number" class="form-control" id="FaxNo" :disabled="!inEditMode" 
                                                    @blur="$v.contact.faxNo.$touch()" :class="{ 'parsley-error': $v.contact.faxNo.$error }"/>
                                                  <template v-if="$v.contact.faxNo.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.faxNo.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="AdrLine1">Address Line1</label>
                                                  <input type="text" v-model.trim="contact.adrLine1" placeholder="Enter Address Line1" class="form-control" id="AdrLine1" :disabled="!inEditMode" 
                                                    @blur="$v.contact.adrLine1.$touch()" :class="{ 'parsley-error': $v.contact.adrLine1.$error }"/>
                                                  <template v-if="$v.contact.adrLine1.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.adrLine1.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="AdrLine2">Address Line2</label>
                                                  <input type="text" v-model.trim="contact.adrLine2" placeholder="Enter Address Line2" class="form-control" id="AdrLine2" :disabled="!inEditMode" 
                                                    @blur="$v.contact.adrLine2.$touch()" :class="{ 'parsley-error': $v.contact.adrLine2.$error }"/>
                                                  <template v-if="$v.contact.adrLine2.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.adrLine2.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="AdrCity">City</label>
                                                  <input type="text" v-model.trim="contact.adrCity" placeholder="Enter City" class="form-control" id="AdrCity" :disabled="!inEditMode" 
                                                    @blur="$v.contact.adrCity.$touch()" :class="{ 'parsley-error': $v.contact.adrCity.$error }"/>
                                                  <template v-if="$v.contact.adrCity.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.adrCity.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="AdrState">State/Province</label>
                                                  <input type="text" v-model.trim="contact.adrState" placeholder="Enter State/Province" class="form-control" id="AdrState" :disabled="!inEditMode" 
                                                    @blur="$v.contact.adrState.$touch()" :class="{ 'parsley-error': $v.contact.adrState.$error }"/>
                                                  <template v-if="$v.contact.adrState.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.adrState.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="AdrCountry">Country</label>
                                                  <input type="text" v-model.trim="contact.adrCountry" placeholder="Enter Country" class="form-control" id="AdrCountry" :disabled="!inEditMode" 
                                                    @blur="$v.contact.adrCountry.$touch()" :class="{ 'parsley-error': $v.contact.adrCountry.$error }"/>
                                                  <template v-if="$v.contact.adrCountry.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.adrCountry.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" :class="{'active show': activeTab == 3}">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label>Key Person</label>
                                                    <toggle-button v-model="contact.isKeyperson" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                </div>

                                                <div class="form-group">
                                                    <label for="MiddleName">Middle Name</label>
                                                    <input type="text" v-model.trim="contact.middleName" placeholder="Enter Middle Name" class="form-control" id="MiddleName" :disabled="!inEditMode" 
                                                      @blur="$v.contact.middleName.$touch()" :class="{ 'parsley-error': $v.contact.middleName.$error }"/>
                                                  <template v-if="$v.contact.middleName.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.middleName.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>
                                                <div class="form-group">
                                                  <label for="NickName">Nick Name</label>
                                                  <input type="text" v-model.trim="contact.nickName" placeholder="Enter Nick Name" class="form-control" id="NickName" :disabled="!inEditMode" 
                                                    @blur="$v.contact.nickName.$touch()" :class="{ 'parsley-error': $v.contact.nickName.$error }"/>
                                                  <template v-if="$v.contact.nickName.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.nickName.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>
                                                <div class="form-group">
                                                  <label for="Salutation">Salutation</label>
                                                  <input type="text" v-model.trim="contact.salutation" placeholder="Enter Salutation" class="form-control" id="Salutation" :disabled="!inEditMode" 
                                                    @blur="$v.contact.salutation.$touch()" :class="{ 'parsley-error': $v.contact.salutation.$error }"/>
                                                  <template v-if="$v.contact.salutation.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.salutation.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="Experiance">Experience</label>
                                                  <textarea type="text" v-model.trim="contact.experiance" placeholder="Enter Experience" class="form-control" id="Experiance" :disabled="!inEditMode" rows="4"
                                                    @blur="$v.contact.experiance.$touch()" :class="{ 'parsley-error': $v.contact.experiance.$error }"></textarea>
                                                  <template v-if="$v.contact.experiance.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.experiance.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group">
                                                  <label for="Comments">Description</label>
                                                  <textarea type="text" v-model.trim="contact.comments" placeholder="Enter Description" class="form-control" id="Comments" :disabled="!inEditMode" rows="4"
                                                    @blur="$v.contact.comments.$touch()" :class="{ 'parsley-error': $v.contact.comments.$error }"></textarea>
                                                  <template v-if="$v.contact.comments.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.contact.comments.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> 
                        <!-- end col -->
                    </div>
                </div>
            </div>
        </div>

        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <ActivityItem @onUpdate="onActivityUpdate" v-if="rightbarItem == 'activity'" :objectId=3 :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import rightbar from '@/mixins/rightbar'
import { ContactService } from '@/services/contact-service'
import { CalMeetingService } from '@/services/calmeeting-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import LinkFirmList from '@/components/contact/LinkFirmList'
import LinkFundList from '@/components/contact/LinkFundList'
import { ActivityService } from '@/services/activity-service'
import ActivityList from '@/components/ActivityList'
import ActivityItem from '@/components/ActivityItem'

export default {
  components: {
    DatePicker,
    Multiselect,
    LinkFirmList,
    LinkFundList,
    ActivityList,
    ActivityItem
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  mixins: [rightbar, asyncDataStatus],
  data () {
    return {
      activeTab: 1,
      activeLTab: 3,
      activeGPTab: 1,
      activeIFTab: 1,
      activeCTab: 1,
      showLogs: false,
      inEditMode: false,
      sideInEditMode: false,
      inSaving: false,
      contact: {fullName: ''},
      activities: [],
      linkFirms: [],
      linkFunds: [],
      rightbarItem: null,
      addSidebar: null,
      firmObj: null,
      searchFirms: [],
      isSearchingList: false,
      isLoadinglinkCalMeetings: false,
      linkCalMeetings: []
    }
  },
  validations: {
    contact: {
      lastName: { required, min: minLength(2), max: maxLength(200) },
      firmId: { required },
      firstName: { max: maxLength(200) },
      jobTitle: { max: maxLength(20) },
      email: { max: maxLength(100), email },
      phoneNo: { max: maxLength(20) },
      mobileNo: { max: maxLength(20) },
      homeNo: { max: maxLength(20) },
      faxNo: { max: maxLength(20) },
      adrLine1: { max: maxLength(100) },
      adrLine2: { max: maxLength(100) },
      adrCity: { max: maxLength(100) },
      adrState: { max: maxLength(100) },
      adrCountry: { max: maxLength(100) },
      middleName: { max: maxLength(100) },
      nickName: { max: maxLength(100) },
      salutation: { max: maxLength(10) },
      experiance: { max: maxLength(100) },
      comments: { max: maxLength(200) }
    }
  },
  methods: {
    ...mapActions(['searchAllFirms', 'fetchActivity']),
    showTab (tabId) {
      this.activeTab = tabId
    },
    showLTab (tabId) {
      this.activeLTab = tabId
    },
    showGPTab (tabId) {
      this.activeGPTab = tabId
    },
    showCTab (tabId) {
      this.activeCTab = tabId
    },
    sidebarAction (item, isAdd, isOpen, isEdit) {
      this.addSidebar = isAdd
      this.sideInEditMode = isEdit || isAdd
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    deleteForm () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                ContactService.delete(this.contact.contactId)
                .then((response) => {
                  this.$snotify.success('Contact Deleted.')
                  setTimeout(() => {
                    this.$router.push({name: 'ContactsList'})
                  }, 500)
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    saveForm () {
      this.$v.contact.$touch()
      if (!this.$v.contact.$error) {
        this.inSaving = true
        ContactService.save(this.contact.contactId, this.contact).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.inEditMode = false
              this.$snotify.success('Changes saved.')
            }
            this.inSaving = false
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    EditForm () {
      this.inEditMode = true
      this.addSidebar = false

      if (this.firmObj == null) {
        this.asyncSearchFirm(this.contact.firmName)
        setTimeout(() => {
          this.firmObj = this.searchFirms.filter(x => x.firmId === this.contact.firmId)
        }, 1000)
      }
    },
    cancelForm () {
      if (!this.$v.contact.$anyDirty) {
        this.cancelFormOper()
        return
      }

      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to cancel?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.cancelFormOper()
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    cancelFormOper () {
      this.inEditMode = false
      this.getDetails()
      if (this.addSidebar) {
        this.hideRightbar()
        this.addSidebar = false
      }
      this.$v.contact.$reset()
    },
    getDetails () {
      ContactService.get(this.id)
      .then((response) => {
        this.contact = response.data
      })
      .then(() => {
        this.fetchActivities()
      })
      .then(() => {
        this.fetchLinks()
      })
      .then(() => {
        this.fetchCalMeetingList()
      })
    },
    fetchLinks () {
      ContactService.getLinks(this.id)
      .then(
        (response) => {
          if (response.data.firms && response.data.firms.length > 0) {
            this.linkFirms = response.data.firms
          } else {
            this.linkFirms = []
          }
          if (response.data.funds && response.data.funds.length > 0) {
            this.linkFunds = response.data.funds
          } else {
            this.linkFunds = []
          }
        }
      )
    },
    fetchCalMeetingList () {
      this.isLoadinglinkCalMeetings = true
      CalMeetingService.getContactMeetings(this.id).then(
        (response) => {
          this.isLoadinglinkCalMeetings = false
          if (response.data.length > 0) {
            this.linkCalMeetings = response.data
          } else {
            this.linkCalMeetings = []
          }
        }
      )
    },
    fetchActivities () {
      ActivityService.getContactActivities(this.id).then(
        (response) => {
          if (response.data.length > 0) {
            this.activities = response.data
          } else {
            this.activities = []
          }
        }
      )
    },
    asyncSearchFirm (query) {
      this.isSearchingList = true
      this.searchAllFirms({query}).then((result) => {
        this.searchFirms = result
        this.isSearchingList = false
      })
    },
    firmSelected (value, id) {
      this.$v.contact.firmId.$touch()
      this.contact.firmId = value ? value.firmId : null
    },
    showActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true)
    },
    editActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true, true)
    },
    clickAddActivity () {
      this.$store.commit('setNewActivity', this.id)
      this.sidebarAction('activity', true, true, true)
    },
    onActivityUpdate () {
      this.fetchActivities()
      this.sidebarAction('', false, false)
    }
  },
  created () {
    this.addSidebar = false
    this.hideRightbar()
    this.getDetails()
    this.asyncDataStatus_fetched('Contacts Details')
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allUsers () {
      return this.$store.state.allUsers
    },
    canbeDeleted () {
      return this.linkFirms.length == 0 && this.linkFunds.length == 0 && this.activities.length == 0
    },
    canbeEdited () {
      return this.user.groupId < 11
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
