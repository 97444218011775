import Constants from '../constants.js'
import Axios from 'axios'

export const MonitoringCommentService = {
  getList () {
    return Axios.get(Constants.API_URL + '/monitoring-comment/list')
  },
  get (id) {
    return Axios.get(Constants.API_URL + '/monitoring-comment/' + id)
  },
  update (id, value) {
    return Axios.put(Constants.API_URL + '/monitoring-comment/' + id, value)
  },
  updateAssinee (id, value) {
    return Axios.put(Constants.API_URL + '/monitoring-comment/update-assinee/' + id, value)
  },
  approve (id) {
    return Axios.put(Constants.API_URL + '/monitoring-comment/approve/' + id)
  }
}
