<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Opportunity Set
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="fund.TargetFund">Deal <span v-show="isAdd" class="text-danger">*</span></label>
          <input type="text" v-model="fundOpportunitySet.targetFundName" class="form-control" id="fund.TargetFund" v-show="!isAdd" readonly="readonly">
          <multiselect id="fund.TargetFund" v-model="fundOpportunitySetObj" v-show="isAdd" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search"
            @input="opportunitySelected" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
      </div>

      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="fundOpportunitySet.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="fundOpportunitySet.createdBy" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isAdd" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Add</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      showLogs: false,
      searchFunds: [],
      isSearchingList: false,
      fundOpportunitySetObj: null
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['addFundOpportunitySet', 'updateFundOpportunitySet', 'deleteFundOpportunitySet', 'searchAllFunds']),
    close () {
      this.hideRightbar()
    },
    opportunitySelected (value, id) {
      this.fundOpportunitySet.targetFundId = value ? value.fundId : null
    },
    onSave () {
      if (this.isAdd) {
        this.addFundOpportunitySet({fundOpportunitySet: this.fundOpportunitySet})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Opportunity Set Added.')
              this.$emit('onUpdate')
            }
          })
      } else {
        this.updateFundOpportunitySet({fundOpportunitySetId: this.fundOpportunitySet.fundOpportunitySetId, fundOpportunitySet: this.fundOpportunitySet})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Opportunity Set Updated.')
              this.$emit('onUpdate')
            }
          })
      }
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    }
  },
  computed: {
    fundOpportunitySet () {
      return this.$store.state.rb_fundOpportunity
    }
  }
}
</script>
