<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              {{order}}
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="vehicle">
      <div class="form-group">
          <label>Sector</label>
          <div>
            <label class="text-primary">{{vehicle.sectorName}}</label>
          </div>
          <label>Vintage</label>
          <div>
            <label class="text-primary">{{vehicle.vintageYear}}</label>
          </div>
          <label>Geography</label>
          <div>
            <label class="text-primary" v-if="vehicle.geographyGlobal">Global</label>
            <label class="text-primary" v-else>{{vehicle.geographicName}}</label>
          </div>
          <label>Target Positions</label>
          <div>
            <label class="text-primary">{{vehicle.targetPosition}}</label>
          </div>
          <span v-if="fundOrder == 0">
            <label>Deal</label>
            <div>
              <label class="text-primary">{{vehicle.fundName}}</label>
            </div>
            <div class="form-group">
                <label for="filledPosition">Actual Position Value (Exact value)<span class="text-danger">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{vehicle.symbol}}</span>
                    </div>
                <input type="text" v-model.number="vehicle.filledPositionExact" class="form-control" id="filledPositionExact" @keyup="calcExact" @blur="$v.vehicle.filledPositionExact.$touch()" :class="{ 'parsley-error': $v.vehicle.filledPositionExact.$error }">
                </div>
                <template v-if="$v.vehicle.filledPositionExact.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="!$v.vehicle.filledPositionExact.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.vehicle.filledPositionExact.decimal" class="parsley-required">This value is not valid.</li>
                    <li v-if="!$v.vehicle.filledPositionExact.min" class="parsley-required">This value is not a valid number.</li>
                  </ul>
                </template>
            </div>
            <div class="form-group">
                <label for="filledPosition">Actual Position Value (M)<span class="text-danger">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{vehicle.symbol}}</span>
                    </div>
                <input type="text" v-model.number="vehicle.filledPosition" class="form-control" id="filledPosition" :class="{ 'parsley-error': $v.vehicle.filledPosition.$error }" readonly="readonly">
                </div>
                <template v-if="$v.vehicle.filledPosition.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="!$v.vehicle.filledPosition.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.vehicle.filledPosition.decimal" class="parsley-required">This value is not valid.</li>
                    <li v-if="!$v.vehicle.filledPosition.min" class="parsley-required">This value is not a valid number.</li>
                  </ul>
                </template>
            </div>
            <div class="form-group">
                <label for="OpsApprovalDate">Ops Approval Date</label>
                <date-picker v-model="vehicle.opsApprovalDate" @input="normalizeDate" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
            </div>            
          </span>
      </div>
      
      <div class="form-group" v-if="fundOrder > 0">
          <label for="fundId">Deal<span class="text-danger">*</span></label>
          <span :class="{ 'invalid': $v.vehicle.fundId.$error }">
            <multiselect id="fundId" v-model="fundObj" v-if="isAdd" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search"
              @input="fundSelected" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
          </span>
          <template v-if="$v.vehicle.fundId.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.vehicle.fundId.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>

      <div class="form-group" v-if="fundOrder == 0">
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="fundOrder > 0 && isEditMode && isComplete" class="btn btn-success waves-effect w-md waves-light mr-2" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</button>
        <button v-if="fundOrder == 0" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onOpsSave"><i class="mdi mdi-content-save m-r-5"></i> Submit</button>
        <div v-if="!isComplete" class="text-danger">Missing Data, please complete.</div>
      </div>
    </form>
    <span v-else>
      Loading...
      <span class="fa fa-spinner fa-spin mr-1" role="status"></span>
    </span>  
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { required, minValue, requiredIf, decimal, helpers } from 'vuelidate/lib/validators'
import DatePicker from 'vuejs-datepicker'
import { VueEditor } from 'vue2-editor'
import { PortfolioSlotService } from '@/services/portfolio-service'
import moment from 'moment'

const validateMinFilledPosition = (prop, validator) =>
  helpers.withParams({ type: 'validateMinFilledPosition', prop }, function (value, parentVm) {
    if (this.fundOrder > 0) {
      return true
    }
    return value > 0
  })

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    },
    fundOrder: {
      required: true,
      type: Number
    }
  },
  components: {
    Multiselect,
    DatePicker,
    VueEditor
  },
  data () {
    return {
      showLogs: false,
      searchFunds: [],
      isSearchingList: false,
      portfolioObj: null,
      sectorObj: null,
      geographicObj: null,
      fundObj: null
    }
  },
  mixins: [rightbar],
  validations: {
    vehicle: {
      fundId: { required, min: minValue(1) },
      filledPosition: { required: requiredIf(function () { return this.fundOrder < 1 }), min: validateMinFilledPosition(), decimal },
      filledPositionExact: { required: requiredIf(function () { return this.fundOrder < 1 }), min: validateMinFilledPosition(), decimal }
    }
  },
  methods: {
    ...mapActions(['searchAllFunds', 'portfolioVehicleAssign']),
    close () {
      this.$v.vehicle.$reset()
      this.hideRightbar()
    },
    fundSelected (value, id) {
      this.vehicle.fundId = value ? value.fundId : null
      this.$v.vehicle.fundId.$touch()
      this.$v.vehicle.fundId.$reset()
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    },
    onSave () {
      this.$v.vehicle.$touch()
      if (!this.$v.vehicle.$error) {
        const asn = { portfolioSlotId: this.vehicle.portfolioSlotId, fundId: this.vehicle.fundId, fundOrder: this.fundOrder, fundName: this.fundObj.fundName }
        this.portfolioVehicleAssign({slotId: this.vehicle.portfolioSlotId, assign: asn})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Done.')
              this.$emit('onUpdate', asn)
            }
          })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    onOpsSave () {
      this.$v.vehicle.$touch()
      if (!this.$v.vehicle.$error) {
        const apr = { portfolioSlotId: this.vehicle.portfolioSlotId, filledPosition: this.vehicle.filledPosition, filledPositionExact: this.vehicle.filledPositionExact, fundOrder: this.fundOrder, fundName: this.vehicle.fundName, opsApprovalDate: this.vehicle.opsApprovalDate }
        PortfolioSlotService.approveOpsConfirm(this.vehicle.portfolioSlotId, apr)
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Done.')
              this.$emit('onUpdate', apr)
            }
          })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    normalizeDate () {
      this.vehicle.opsApprovalDate = moment(this.vehicle.opsApprovalDate).format('MM/DD/YYYY')
    },
    calcExact () {
      this.vehicle.filledPosition = parseInt((this.vehicle.filledPositionExact / 1000000) * 100) / 100
    }
  },
  created () {
    // this.sectorObj = this.allSectors.filter(t => t.sectorId === this.vehicle.sectorId)
  },
  computed: {
    vehicle () {
      return this.$store.state.rb_portfolioVehicle
    },
    isComplete () {
      return this.vehicle.vintageYear && this.vehicle.targetPosition && (this.vehicle.geographicName || this.vehicle.geographyGlobal)
    },
    order () {
      let orderName = ''
      switch (this.fundOrder) {
        case 0:
          orderName = 'Operation Approval'
          break
        case 1:
          orderName = 'Suggest for Lead'
          break
        case 2:
          orderName = 'Suggest for Second'
          break
        case 3:
          orderName = 'Suggest for Other'
          break
      }
      return orderName
    }
  }
}
</script>

