<template>
  <div class='pr-2 pl-2'>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
        <a href="javascript:void(0);" @click.prevent="closeFilterMenu" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
        <a class="nav-link active show"> Filters </a>
      </li>
    </ul>
    <form class="form col-sm-12 mt-3">
      <div class="form-group">
        <label for='tree-strategy'>Strategy</label>
        <Treeselect
          id="tree-strategy"
          :multiple='true'
          :options="fullStrategiesTree"
          v-model="payloadObj.strategyObj"
          :disable-branch-nodes="true"
          :showCount="true"
          placeholder="Filter by Strategy">
          <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
        </Treeselect>
      </div>
      <div class="form-group">
        <label for='geography'>Geography</label>
        <multiselect v-model='payloadObj.geoObj' id='geography' :multiple="true" :options='allGeoFocuses' placeholder="Filter by Geography" label="geographicName" track-by='geographicId'></multiselect>
      </div>
      <div class="form-group">
        <label for='stage'>Stage</label>
        <multiselect v-model='payloadObj.stageObj' id='stage' :multiple="true" :options='allStages' placeholder="Filter by Stage" label="name" track-by='id'></multiselect>
      </div>
      <div class="form-group">
        <label for='type'>Type</label>
        <multiselect v-model='payloadObj.typeObj' id='type' :multiple="true" :options='allTypes' placeholder="Filter by Type" label="name" track-by='id'></multiselect>
      </div>
      <div class="form-group">
        <label for='status'>Status</label>
        <multiselect v-model='payloadObj.statusObj' id='status' :multiple="true" :options='allStatuses' placeholder="Filter by Status" label="name" track-by='id'></multiselect>
      </div>
      <div class="form-group">
        <label for='tier'>Tier</label>
        <multiselect v-model='payloadObj.tierObj' id='tier' :multiple="true" :options='allTiers' placeholder="Filter by Tier" label="fundTierName" track-by='fundTierId'></multiselect>
      </div>
      <div class="form-group">
        <label for='country'>Country</label>
        <multiselect id="country" v-model="payloadObj.countryObj" tag-placeholder="Add this as new tag" placeholder="Search or add a Country" 
          label="countryName" track-by="countryId" :options="allCountries" :multiple="true" :taggable="true" width="50%"></multiselect>
      </div>
      <button class="btn btn-primary mr-2" @click.prevent="closeFilterMenu">
        <i class="mdi mdi-close-circle-outline" /> Close
      </button>
      <button class="btn btn-success" @click.prevent="addFilters(payloadClone)">
        <i class="mdi mdi-check" /> Apply
      </button>
    </form>
  </div>
</template>


<script>
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
export default {
  components: {
    Multiselect,
    Treeselect
  },
  data () {
    return {
      payloadObj: {
        strategyObj: [],
        geoObj: [],
        stageObj: [],
        typeObj: [],
        statusObj: [],
        tierObj: [],
        countryObj: []
      }
    }
  },
  props: {
    allStatuses: {
      type: Array
    },
    allStages: {
      type: Array
    },
    allTypes: {
      type: Array
    }
  },
  methods: {
    closeFilterMenu () {
      this.$emit('closeFilterMenu')
    },
    clearFilterMenu () {
      this.payloadObj = {
        strategyObj: [],
        geoObj: [],
        stageObj: [],
        typeObj: [],
        statusObj: [],
        tierObj: []
      }
    },
    addFilters (payload) {
      if (payload.strategyObj) {
        payload.strategyObj.forEach(item => {
          if (item[0] === 'C') {
            payload.strategyObj = [...payload.strategyObj, this.allSectors.find(obj => obj.sectorId === parseInt(item.slice(1)))]
          } else {
            payload.strategyObj = [...payload.strategyObj, this.allSubsectors.find(obj => obj.subSectorId === parseInt(item.slice(1)))]
          }
        })
        payload.strategyObj = payload.strategyObj.slice(payload.strategyObj.length / 2)
      }
      this.$emit('addFilters', payload)
    },
    setCookiedFilters (payload) {
      this.payloadObj = payload
      if (payload.strategyObj) {
        this.payloadObj.strategyObj = payload.strategyObj.map(obj => `C${obj.sectorId}`)
      }
    }
  },
  computed: {
    allCountries () {
      return this.$store.state.allCountries
    },
    allSectors () {
      return this.$store.state.allSectors.filter(x => x.sort < 100)
    },
    allTiers () {
      return this.$store.state.allFundtires
    },
    allSubsectors () {
      return this.$store.state.allSubsectors
    },
    allStrategies () {
      return this.$store.state.allStrategies
    },
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    allTreeStrategies () {
      return this.$store.state.allTreeStrategies
    },
    payloadClone () {
      return { ...this.payloadObj }
    },
    fullStrategiesTree () {
      let groups = [{
        id: '1A',
        label: 'Private Markets',
        children: []
      },
      {
        id: '2A',
        label: 'Marketable Alternatives',
        children: []
      }]
      this.allTreeStrategies.forEach(obj => {
        groups[obj.groupId - 1].children = [...groups[obj.groupId - 1].children, {id: `B${obj.strategyId}`, label: obj.strategyName, children: []}]
      })
      groups.forEach(group => {
        group.children.forEach(strategy => {
          this.allSectors.forEach(item => {
            item.strategyId === parseInt(strategy.id.slice(1)) ? strategy.children = [...strategy.children, {id: `C${item.sectorId}`, label: item.sectorName, children: []}] : strategy.children = strategy.children
          })
        })
      })
      // add back if subsector neeeded:
      // groups.forEach(group => {
      //   group.children.forEach(strategy => {
      //     strategy.children.forEach(sector => {
      //       this.allSubsectors.forEach(item => {
      //         if (item.sectorId === parseInt(sector.id.slice(1))) {
      //           sector.children = [...sector.children, {id: `D${item.subSectorId}`, label: item.subSectorName, customLabel: `${group.label} > ${strategy.label} > ${sector.label} > ${item.subSectorName}`}]
      //         }
      //       })
      //     })
      //   })
      // })
      groups.forEach(group => {
        group.children.forEach(strategy => {
          strategy.children = strategy.children.sort((a, b) => b.children.length - a.children.length).map(sector => {
            if (sector.children.length === 0) {
              return { id: sector.id, label: sector.label, customLabel: `${group.label} > ${strategy.label} > ${sector.label}` }
            } else {
              return sector
            }
          })
        })
      })
      groups.forEach(group => {
        group.children = group.children.sort((a, b) => b.children.length - a.children.length).map(strategy => {
          if (strategy.children.length === 1) {
            return { id: strategy.children[0].id, label: strategy.label, customLabel: `${group.label} > ${strategy.label}` }
          } else {
            return strategy
          }
        })
      })
      return groups
    }
  }
}
</script>