export default {
  data () {
    return {
      asyncDataStatus_ready: false
    }
  },
  methods: {
    asyncDataStatus_fetched (title) {
      document.title = title + ' - Umbrella · Wilshire Private Markets Group'
      this.asyncDataStatus_ready = true
      this.$emit('ready')
    }
  }
}
