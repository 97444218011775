<template>
    <div class="topbar">
        <!-- LOGO -->
        <div class="topbar-left" :class="navbarClass">
            <div class="text-center">
                <router-link :to="{name: 'Home'}"
                    class="logo">
                    <i class="mdi mdi-umbrella"></i> <span>{{pageTitle}}</span>
                </router-link>
            </div>
        </div>
            <!-- Button mobile view to collapse sidebar menu -->
            <nav class="navbar-custom" :class="navbarClass">
                <ul class="list-inline float-right mb-0" v-if="user">

                    <li class="list-inline-item notification-list hide-phone">
                        <a class="nav-link waves-light waves-effect" href="https://umbrella-help.wilshire.com" target="_blank">
                            <i class="mdi mdi-help-circle-outline" style="font-size: 1.1rem;" v-tooltip="'Umbrella Help'"></i>
                        </a>
                    </li>

                    <li class="list-inline-item notification-list hide-phone">
                        <a class="nav-link waves-light waves-effect" href="javascript:void(0)" @click="toggleFullscreen">
                            <i class="mdi mdi-crop-free noti-icon" v-tooltip="'Full Screen'"></i>
                        </a>
                    </li>

                    <li class="list-inline-item dropdown notification-list" v-if="user.groupId < 11">
                        <a class="nav-link dropdown-toggle arrow-none waves-light waves-effect" href="javascript:void(0)" role="button" @click.prevent="nifyDropdownOpen = !nifyDropdownOpen">
                            <i class="mdi mdi-bell noti-icon"></i>
                            <span v-show="notifications.length > 0 && !readNotifications && !resetNotifications" class="badge badge-pink noti-icon-badge">{{notifications.length}}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right dropdown-arrow dropdown-menu-lg notification-panel" :class="{'show': nifyDropdownOpen}">
                            <div class="dropdown-item noti-title">
                                <h5 class="font-16"><a href="javascript:void(0);" @click.prevent="openNotifications" v-show="notifications.length > 0 && !readNotifications" class="badge badge-success float-right">Read all</a>Notifications</h5>
                            </div>

                            <span v-for="nfy in notifications" :key="nfy.notificationId">
                              <router-link v-if="nfy.notificationUrlId > 0 && nfy.typeId == 12" :to="{path: '/deals/allocation/' + nfy.notificationUrlId}" class="dropdown-item notify-item">
                                  <div class="notify-icon" :class="nfy.notificationClass"><i class="mdi" :class="nfy.objectClass"></i></div>
                                  <p class="notify-details">{{nfy.notificationText}}<small class="text-muted">{{formatedDateAgo(nfy.createdOn)}}</small></p>
                              </router-link>
                              <router-link v-if="nfy.notificationUrlId > 0 && nfy.typeId != 7 && nfy.typeId != 12" :to="{path: '/' + nfy.notificationUrlName + '/detail/' + nfy.notificationUrlId}" class="dropdown-item notify-item">
                                  <div class="notify-icon" :class="nfy.notificationClass"><i class="mdi" :class="nfy.objectClass"></i></div>
                                  <p class="notify-details">{{nfy.notificationText}}<small class="text-muted">{{formatedDateAgo(nfy.createdOn)}}</small></p>
                              </router-link>
                              <router-link v-if="nfy.notificationUrlId > 0 && nfy.typeId == 7" :to="{path: '/legal/' + nfy.notificationUrlName + '/' + nfy.notificationUrlId}" class="dropdown-item notify-item">
                                  <div class="notify-icon" :class="nfy.notificationClass"><i class="mdi" :class="nfy.objectClass"></i></div>
                                  <p class="notify-details">{{nfy.notificationText}}<small class="text-muted">{{formatedDateAgo(nfy.createdOn)}}</small></p>
                              </router-link>
                              <a href="javascript:void(0);" class="dropdown-item notify-item" v-if="nfy.notificationUrlId === 0">
                                  <div class="notify-icon" :class="nfy.notificationClass"><i class="mdi" :class="nfy.objectClass"></i></div>
                                  <p class="notify-details">{{nfy.notificationText}}<small class="text-muted">{{formatedDateAgo(nfy.createdOn)}}</small></p>
                              </a>
                            </span>
                            <a href="javascript:void(0);" class="dropdown-item notify-item" v-if="notifications.length == 0">
                                <p class="notify-details text-white">No New Notifications</p>
                            </a>

                            <router-link :to="{name: 'Notification'}" class="dropdown-item notify-item notify-all">
                                View All
                            </router-link>
                            <!-- <a href="javascript:void(0);" class="dropdown-item notify-item notify-all">
                                View All
                            </a> -->
                        </div>
                    </li>

                    <li class="list-inline-item dropdown notification-list">
                        <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" href="javascript:void(0)" role="button" @click.prevent="userDropdownOpen = !userDropdownOpen">
                            <AppUserPic :userId="user.userId" :size="1" />
                        </a>
                        <div class="dropdown-menu dropdown-menu-right profile-dropdown" :class="{'show': userDropdownOpen}">
                            <div class="dropdown-item noti-title">
                                <h5 class="text-overflow"><small>{{ user.firstName + ' ' + user.lastName }}</small> </h5>
                            </div>

                            <a href="javascript:void(0)" class="dropdown-item notify-item" @click="clearCache">
                              <i class="mdi mdi-flask-empty-outline"></i> <span>Clear Cache</span>
                            </a>

                            <router-link :to="{name: 'Profile'}" class="dropdown-item notify-item">
                              <i class="mdi mdi-account"></i> <span>Profile</span>
                            </router-link>

                            <!-- <a href="javascript:void(0)" class="dropdown-item notify-item">
                              <i class="mdi mdi-settings"></i> <span>Settings</span>
                            </a> -->

                            <a href="javascript:void(0)" class="dropdown-item notify-item" @click="logout">
                              <i class="mdi mdi-logout"></i> <span>Logout</span>
                            </a>

                        </div>
                    </li>

                </ul>

                <ul class="list-inline menu-left mb-0">
                    <li class="float-left">
                        <button class="button-menu-mobile open-left waves-light waves-effect" :class="navbarClass" @click="toggleSidebar">
                            <i class="mdi mdi-menu" v-tooltip="'Toggle Menu'"></i>
                        </button>
                    </li>
                    <li class="hide-phone app-search" v-if="user.groupId < 11">
                        <form role="search" @submit.prevent="doSearch">
                            <input v-model="searchTerm" type="text" placeholder="Search..." class="form-control">
                            <a href="javascript:void(0)" @click.prevent="doSearch"><i class="fa fa-search"></i></a>
                        </form>
                    </li>
                    <!-- <li>
                        <button class="button-menu-mobile open-left waves-light waves-effect" @click="openDeals">
                          <i class="mdi mdi-square-inc-cash" v-tooltip="'Deals'"></i>  
                        </button>
                    </li> -->
                </ul>

            </nav>

    </div>
</template>

<script>
import store from '@/store'
import { mapGetters, mapActions } from 'vuex'
import { NotificationService } from '@/services/notification-service'
import { BrowseService } from '@/services/user-service'
import moment from 'moment'
import Constants from '@/constants'

export default {
  props: {
    resetNotifications: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      userDropdownOpen: false,
      nifyDropdownOpen: false,
      notifications: [],
      readNotifications: false,
      searchTerm: null,
      pageTitle: Constants.ENVIRONMENT == 'Production' ? 'Umbrella' : process.env.VUE_APP_TITLE,
      navbarClass: Constants.ENVIRONMENT == 'Production' ? '': 'testmode-navbar'
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  },
  created () {
    this.fetchAllUsers()
    document.addEventListener('click', this.forceCloseDropdown)
    setTimeout(() => {
      NotificationService.getUnreads()
      .then(
        (response) => {
          this.notifications = response.data
          // force to read
          if (this.notifications.length > 1) {
            this.nifyDropdownOpen = true
          }
        }
      )
    }, 2000)
  },
  destroyed () {
    document.removeEventListener('click', this.forceCloseDropdown)
  },
  methods: {
    ...mapActions(['fetchAllUsers']),
    doSearch () {
      if (this.searchTerm.length > 0) {
        this.$router.push({name: 'Search', params: {id: this.searchTerm}})
      }
    },
    openNotifications () {
      if (this.notifications.length > 0) {
        const lastId = this.notifications[0].notificationId
        NotificationService.dismiss(lastId).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.readNotifications = true
            }
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      }
    },
    toggleFullscreen () {
      if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen()
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen()
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
      }
    },
    collapseSidebar () {
      document.getElementById('wrapper').classList.toggle('enlarged')
    },
    forceCloseDropdown (evt) {
      const target = evt.target
      if (!target.classList.contains('rounded-circle')) {
        this.userDropdownOpen = false
      }
      if (!target.classList.contains('noti-icon', 'noti-icon-badge')) {
        this.nifyDropdownOpen = false
      }
    },
    clearCache () {
      BrowseService.addLog({urlFromName: 'Home', urlToName: 'ClearCache'})
      localStorage.clear()
      this.$snotify.info('Cache cleared!')
    },
    // openDeals () {
    //   this.$router.push({name: 'FundsList'})
    // },
    toggleSidebar () {
      // let _body = document.getElementsByTagName('body')[0]
      let _slimScrollDiv = document.getElementsByClassName('slimScrollDiv')[0]
      let _sidebarinner = document.getElementsByClassName('sidebar-inner')[0]
      let _wrapper = document.getElementById('wrapper')
      _wrapper.classList.toggle('enlarged')
      if (_wrapper.classList.contains('enlarged')) {
        _slimScrollDiv.style.overflow = 'inherit'
        _sidebarinner.style.overflow = 'inherit'
        let _expandedMenus = document.querySelectorAll('.expanded')
        for (let m = 0; m < _expandedMenus.length; m++) {
          // _expandedMenus[m].classList.toggle('expanded')
          _expandedMenus[m].className = 'expandedB'
        }
      } else {
        let _active = document.querySelectorAll('li.has_sub.active ul.expandedB')
        if (_active.length > 0) {
          _active[0].className = 'expanded'
        }
      }
      // _wrapper.addClass('forced')

      // if($('#wrapper').hasClass('enlarged') && $('body').hasClass('fixed-left')) {
      //   $('body').removeClass('fixed-left').addClass('fixed-left-void');
      // } else if(!$('#wrapper').hasClass('enlarged') && $('body').hasClass('fixed-left-void')) {
      //   $('body').removeClass('fixed-left-void').addClass('fixed-left');
      // }
      //
      // if($('#wrapper').hasClass('enlarged')) {
      //   $('.left ul').removeAttr('style');
      // } else {
      //   $('.subdrop').siblings('ul:first').show();
      // }
      //
      // toggle_slimscroll('.slimscrollleft');
      // $('body').trigger('resize');
    },
    formatedDateAgo (date) {
      return moment(date).fromNow()
    },
    logout () {
      if (Constants.ENABLE_CLOUD) {
        const msalInstance = store.state.msalInstance
        if (msalInstance != null) {
          msalInstance
          .logout({})
          .then(() => {
            this.$router.push({name: 'Logout'})
          })
          .catch(error => {
            //console.log('logout error', error)
          })
        } else {
          this.$router.push({name: 'Logout'})
        }
      } else {
        this.$router.push({name: 'Signin'})
      }
    }
  }
}
</script>
