<template>
  <tbody>
    <tr v-if="list === null">
      <td class="text-center" colspan="8">No Data to show</td>
    </tr>
    <tr v-for="item in list"
        :key="item.fundPerformanceId">
        <td>
          {{item.fundName}}
        </td>
        <td>{{item.targetSize}}</td>
        <td>{{item.vintageYear}}</td>
        <td>{{item.grossROI}}</td>
        <td>{{item.netROI}}</td>
        <td>{{item.grossIRRPercent}}%</td>
        <td>{{item.netIRRPercent}}%</td>
        <td><AppDate :date="item.asOfDate" :show="'span'" /></td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: null
    }
  }
}
</script>
