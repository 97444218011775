<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
            Key Seniors
          </a>
      </li>
    </ul>

    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="ContactId">Contact<span v-show="isAdd" class="text-danger">*</span></label>
          <input type="text" v-model="keySenior.fullName" class="form-control" id="ContactId" v-if="!isAdd" readonly="readonly">
          <multiselect id="ContactId" v-model="contactObj" v-if="isAdd" :options="searchContacts" label="fullName" track-by="contactId" :allow-empty="false" placeholder="Type to Search"
            @input="contactSelected" @search-change="asyncSearchContact" :loading="isSearchingList" :options-limit="50"></multiselect>
      </div>

      <div class="form-group" v-if="!isAdd">
          <label for="keySenior.isActive">Status</label>
          <toggle-button v-model="keySenior.isActive" :disabled="!isEditMode" :sync="true" :width="65" :labels="{checked: 'Active', unchecked: 'Inactive'}" />
      </div>

      <div class="form-group">
          <label for="keySenior.Note">Notes<span v-show="isEditMode" class="text-danger">*</span></label>
          <textarea id="keySenior.Note" :readonly="!isEditMode" v-model="keySenior.note" placeholder="Enter Note" class="form-control" @blur="$v.keySenior.note.$touch()" :class="{ 'parsley-error': $v.keySenior.note.$error }" rows="3"/>
          <template v-if="$v.keySenior.note.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.keySenior.note.max" class="parsley-required">This value is too long</li>
            </ul>
          </template>
      </div>
      
      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="keySenior.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="keySenior.createdBy" />
          </div>
          <div class="form-group" v-if="keySenior.updatedOn">
              <label>Modified On</label>
              <AppDate :date="keySenior.updatedOn" :show="'input'"/>
          </div>
          <div class="form-group" v-if="keySenior.updatedOn">
              <label>Modified By</label>
              <AppUser :userId="keySenior.updatedBy" :emptyIfNull="true"/>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import { maxLength } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect
  },
  mixins: [rightbar],
  data () {
    return {
      showLogs: false,
      contactObj: null,
      searchContacts: [],
      isSearchingList: false
    }
  },
  validations: {
    keySenior: {
      note: { max: maxLength(500) }
    }
  },
  methods: {
    ...mapActions(['addFundKeySenior', 'updateFundKeySenior', 'searchAllContacts']),
    close () {
      this.hideRightbar()
    },
    contactSelected (value, id) {
      this.keySenior.contactId = value ? value.contactId : null
    },
    onSave () {
      this.$v.keySenior.$touch()
      if (!this.$v.keySenior.$error) {
        if (this.isAdd) {
          this.addFundKeySenior({fundKeySenior: this.keySenior})
            .then((response) => {
              if (response == null) {
                this.$snotify.error('Server Error!')
              } else {
                this.$snotify.success('Key-Senior Added.')
                this.$emit('onUpdate')
              }
            })
        } else {
          this.updateFundKeySenior({fundKeySeniorId: this.keySenior.fundKeySeniorId, fundKeySenior: this.keySenior})
            .then((response) => {
              this.$snotify.success('Key-Senior Updated.')
              this.$emit('onUpdate')
            })
        }
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    asyncSearchContact (query) {
      this.isSearchingList = true
      this.searchAllContacts({query}).then((result) => {
        this.searchContacts = result
        this.isSearchingList = false
      })
    }
  },
  computed: {
    keySenior () {
      return this.$store.state.rb_fundKeySenior
    }
  }
}
</script>
