<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Vote
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="rate">Vote</label>
          <select id="rate" class="form-control form-control-sm" v-model="vote.score">
              <option value="null">Vote</option>
              <option value="false">Decline</option>
              <option value="true">Approve</option>
          </select>
      </div>

      <div class="form-group">
        <label for="comment" class="col-form-label">
          Comment, Request Discussion or DD
        </label>
        <textarea class="form-control" rows="5" id="comment" v-model.trim="vote.comment" @blur="$v.vote.comment.$touch()" :class="{ 'parsley-error': $v.vote.comment.$error }"></textarea>
        <template v-if="$v.vote.comment.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.vote.comment.max" class="parsley-required">This value is too long</li>
          </ul>
        </template>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i>Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { maxLength } from 'vuelidate/lib/validators'
import { PortfolioVotingResultService } from '@/services/portfolio-service'
import { AllocationVotingService } from '@/services/allocation-service'

export default {
  props: {
    vote: {
      required: true,
      type: Object
    },
    type: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
    }
  },
  validations: {
    vote: {
      comment: { max: maxLength(50000) }
    }
  },
  mixins: [rightbar],
  methods: {
    close () {
      this.hideRightbar()
    },
    onSave () {      
      this.$v.vote.$touch()
      if (this.$v.vote.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      if (this.vote.score == null || this.vote.score === 'null') {
        this.vote.score = null
      } else {
        this.vote.score = (this.vote.score === true || this.vote.score === 'true')
      }
      if (this.type === 1) {
        // Save for Portfolio
        PortfolioVotingResultService.save(this.vote.portfolioVotingId, this.vote)
        .then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.warning('Voting has been Finalized and connot be submitted!')
              this.$emit('onUpdate', null)
            } else {
              this.$snotify.success('Your Vote has been Submitted.')
              this.$emit('onUpdate', this.vote)
            }
          })
          .catch(function (error) {
            console.error('SERVER Error:', error)
          })
      } else {
        // Save for Allocation
        AllocationVotingService.save(this.vote.votingId, this.vote)
        .then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.warning('Voting has been Finalized and connot be submitted!')
              this.$emit('onUpdate', null)
            } else {
              this.$snotify.success('Your Vote has been Submitted.')
              this.$emit('onUpdate', this.vote)
            }
          })
          .catch(function (error) {
            console.error('SERVER Error:', error)
          })
      }
    }
  }
}
</script>
