<template>
  <div class='form-group'>
    <span v-if='fund.fundTypeId == 3'>
          <div v-if='compareTabToggle'>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uStrategy != '' && fundUmbrella.sectorId != fund.sectorId" @click="updateStrategy"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="strategyObj_uCo" class="col-form-label"
              >Strategy</label
            >
            <multiselect
              id="strategyObj_uCo"
              class="vueselect"
              v-model="strategyUmb"
              :options="allStrategies"
              label="fullName"
              track-by="modelId"
              :allow-empty="false"
              disabled
             />
          </div>

          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uParentFund != '' && fund.parentinvestmentSizeRange != fundUmbrella.parentinvestmentSizeRange" @click="fund.parentinvestmentSizeRange = fundUmbrella.parentinvestmentSizeRange"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="parentinvestmentSizeRange_u" class="col-form-label"
              >Parent Fund</label
            >
            <input
              maxlength="300"
              type="text"
              class="form-control"
              id="parentinvestmentSizeRange_u"
              placeholder="Not Provided"
              v-model="fundUmbrella.parentinvestmentSizeRange"
              disabled
            />
          </div>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uLTMRevenue != '' && fund.ltmRevenue != fundUmbrella.ltmRevenue" @click="fund.ltmRevenue = fundUmbrella.ltmRevenue"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="ltmRevenue_u" class="col-form-label"
              >LTM Revenue (M)</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                class="form-control"
                id="ltmRevenue_u"
                placeholder="Not Provided"
                v-model.number="fundUmbrella.ltmRevenue"
                disabled
              />
            </div>
          </div>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uLTMEBITDA != '' && fund.ltmEbitda != fundUmbrella.ltmEbitda" @click="fund.ltmEbitda = fundUmbrella.ltmEbitda"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="ltmEbitda_u" class="col-form-label"
              >LTM EBITDA (M)</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                class="form-control"
                id="ltmEbitda_u"
                placeholder="Not Provided"
                v-model.number="fundUmbrella.ltmEbitda"
                disabled
              />
            </div>
          </div>

          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uEnterpriseValue != '' && fund.enterpriseValue != fundUmbrella.enterpriseValue" @click="fund.enterpriseValue = fundUmbrella.enterpriseValue"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="enterpriseValue_u">Enterprise Value (M)</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                class="form-control"
                id="enterpriseValue_u"
                placeholder="Not Provided"
                v-model.number="fundUmbrella.enterpriseValue"
                disabled
              />
            </div>
          </div>

          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uTotalDebt != '' && fund.totalCompanyDebt != fundUmbrella.totalCompanyDebt" @click="fund.totalCompanyDebt = fundUmbrella.totalCompanyDebt"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="totalCompanyDebt_u" class="col-form-label"
              >Total Company Debt (M)</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                class="form-control"
                id="totalCompanyDebt_u"
                placeholder="Not Provided"
                v-model.number="fundUmbrella.totalCompanyDebt"
                disabled
              />
            </div>
          </div>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uTotalEquity != '' && fund.totalCompanyEquity != fundUmbrella.totalCompanyEquity" @click="fund.totalCompanyEquity = fundUmbrella.totalCompanyEquity"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="totalCompanyEquity_u" class="col-form-label"
              >Total Company Equity (M)</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                class="form-control"
                id="totalCompanyEquity_u"
                placeholder="Not Provided"
                v-model.number="fundUmbrella.totalCompanyEquity"
                disabled
              />
            </div>
          </div>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uAmountOfferedAll != '' && fund.amountOffered != fundUmbrella.amountOffered" @click="fund.amountOffered = fundUmbrella.amountOffered"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="amountOffered_u" class="col-form-label"
              >Amount Offered All LPs (M)</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                class="form-control"
                id="amountOffered_u"
                placeholder="Not Provided"
                v-model.number="fundUmbrella.amountOffered"
                disabled
              />
            </div>
          </div>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uAmountOfferedWPM != '' && fund.amountOfferedWpm != fundUmbrella.amountOfferedWpm" @click="fund.amountOfferedWpm = fundUmbrella.amountOfferedWpm"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="amountOfferedWpm_u" class="col-form-label"
              >Amount Offered WPM (M)</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ symbol }}</span>
              </div>
              <input
                type="text"
                class="form-control"
                id="amountOfferedWpm_u"
                placeholder="Not Provided"
                v-model.number="fundUmbrella.amountOfferedWpm"
                disabled
              />
            </div>
          </div>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uManagementFeeIP != '' && fund.managementFeePercent != fundUmbrella.managementFeePercent" @click="fund.managementFeePercent = fundUmbrella.managementFeePercent"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="managementFeePercent_u" class="col-form-label"
              >Management Fee (Investment Period)</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">%</span>
              </div>
              <input
                type="text"
                class="form-control"
                id="managementFeePercent_u"
                placeholder="Not Provided"
                v-model.number="fundUmbrella.managementFeePercent"
                disabled
              />
            </div>
          </div>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uManagementFeeStructure != '' && fund.managementFeeStructureId != fundUmbrella.managementFeeStructureId" @click="fund.managementFeeStructureId = fundUmbrella.managementFeeStructureId"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="Structure1b_u" class="col-form-label"
              >Management Fee Structure</label
            >
            <span>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="Structure1b_u"
                  value="1"
                  v-model="fundUmbrella.managementFeeStructureId"
                  Term="Structureb"
                  class="custom-control-input"
                  disabled
                />
                <label class="custom-control-label" for="Structure1b"
                  >Committed</label
                >
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="Structure2b_u"
                  value="2"
                  v-model="fundUmbrella.managementFeeStructureId"
                  Term="Structureb"
                  class="custom-control-input"
                  disabled
                />
                <label class="custom-control-label" for="Structure2b_u"
                  >Invested</label
                >
              </div>
            </span>
          </div>

          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uCarriedInterest != '' && fund.carriedInterestPercent != fundUmbrella.carriedInterestPercent" @click="fund.carriedInterestPercent = fundUmbrella.carriedInterestPercent"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="carriedInterestPercent_u" class="col-form-label"
              >Carried Interest</label
            >
            <input
              type="text"
              class="form-control"
              id="carriedInterestPercent_u"
              placeholder="Not Provided"
              v-model.number="fundUmbrella.carriedInterestPercent"
              disabled
            />
          </div>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uCIDescription != '' && fund.carriedInterestDesc != fundUmbrella.carriedInterestDesc" @click="fund.carriedInterestDesc = fundUmbrella.carriedInterestDesc"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="carriedInterestDesc_u" class="col-form-label"
              >Carried Interest - Waterfall Type</label
            >
            <input
              type="text"
              maxlength="100"
              class="form-control"
              id="carriedInterestDesc_u"
              placeholder="Not Provided"
              v-model="fundUmbrella.carriedInterestDesc"
              disabled
            />
          </div>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uPreferredReturn != '' && fund.preferredReturnPercent != fundUmbrella.preferredReturnPercent" @click="fund.preferredReturnPercent = fundUmbrella.preferredReturnPercent"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="preferredReturnPercent_u" class="col-form-label"
              >Preferred Return</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">%</span>
              </div>
              <input
                type="text"
                class="form-control"
                id="preferredReturnPercent_u"
                placeholder="Not Provided"
                v-model.number="fundUmbrella.preferredReturnPercent"
                disabled
              />
            </div>
          </div>
          <div class="form-group">
            <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uDealDescription != '' && fund.dealDescription != fundUmbrella.dealDescription" @click="fund.dealDescription = fundUmbrella.dealDescription"><i class="mdi mdi-arrow-left-bold"></i></button>
            <label for="dealDescription_u" class="col-form-label"
              >Deal Description</label
            >
            <textarea
              maxlength="2000"
              class="form-control"
              id="dealDescription_u"
              placeholder="Not Provided"
              v-model="fundUmbrella.dealDescription"
              rows="5"
              disabled
            />
          </div>
          </div>
      </span>
    <span v-if="fund.fundTypeId < 3">
      <div class="form-group" v-if="fund.fundTypeId == 1">
        <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uVintage != '' && fund.vintageYear != fundUmbrella.vintageYear" @click="fund.vintageYear = fundUmbrella.vintageYear"><i class="mdi mdi-arrow-left-bold"></i></button>
        <label for="vintageYear_u" class="col-form-label"
          >Vintage Year</label
        >
        <input
          type="text"
          class="form-control"
          id="vintageYear_u"
          placeholder="Not Provided"
          v-model.number="fundUmbrella.vintageYear"
          disabled
        />
      </div>
      <div class="form-group">
        <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uStrategy != '' && fundUmbrella.sectorId != fund.sectorId" @click="updateStrategy"><i class="mdi mdi-arrow-left-bold"></i></button>
        <label for="strategyObj_u" class="col-form-label"
          >Strategy</label
        >
        <multiselect
          id="strategyObj_u"
          class="vueselect"
          v-model="strategyUmb"
          :options="allStrategies"
          label="fullName"
          track-by="modelId"
          :allow-empty="false"
          disabled
        ></multiselect>
      </div>
      <div class="form-group">
        <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uStrategyDescription.length > 0 && fundUmbrella.sectorDescription != fund.sectorDescription" @click="fund.sectorDescription = fundUmbrella.sectorDescription"><i class="mdi mdi-arrow-left-bold"></i></button>
        <label for="sectorDescription_u" class="col-form-label"
          >Strategy Description</label
        >
        <textarea
          maxlength="300"
          class="form-control"
          id="sectorDescription_u"
          placeholder="Not Provided"
          rows="2"
          v-model="fundUmbrella.sectorDescription"
          disabled
        />
      </div>
      <div class="form-group">
        <div class="input-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="!esgTest" @click="updateEsg"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="esg-focus_u">ESG Focus</label>
        </div>
        <div class="input-group">
          <toggle-button
            disabled
            id="esg-focus_u"
            v-model="fundUmbrella.isSocialResponsible"
            :sync="true"
            :labels="{ checked: 'Yes', unchecked: 'No' }"
          />
        </div>
        <div class="mt-2" v-if="fundUmbrella.isSocialResponsible || fund.isSocialResponsible">
          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="social-check_u"
              value="1"
              v-model="fundUmbrella.esgType"
              Term="Social"
              class="custom-control-input"
              disabled
            />
            <label class="custom-control-label" for="social-check_u"
              >Social</label
            >
          </div>
          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="env-check_u"
              value="2"
              v-model="fundUmbrella.esgType"
              Term="Environmental"
              class="custom-control-input"
              disabled
            />
            <label class="custom-control-label" for="env-check_u"
              >Environmental</label
            >
          </div>
          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="SEG-check_u"
              value="3"
              v-model="fundUmbrella.esgType"
              Term="SEG"
              class="custom-control-input"
              disabled
            />
            <label class="custom-control-label" for="SEG-check_u"
              >Social, Environmental and/or Governance</label
            >
          </div>
          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="other-check_u"
              value="4"
              v-model="fundUmbrella.esgType"
              Term="Other"
              class="custom-control-input"
              disabled
            />
            <label class="custom-control-label" for="other-check_u"
              >Other</label
            >
          </div>
          <div class="input-group mt-2" v-if="fund.esgType == 4">
            <textarea
              class="form-control"
              id="other-text_u"
              placeholder="Not Provided"
              maxlength="250"
              rows="1"
              v-model="fundUmbrella.esgTypeOther"
              @keypress="isFormUpdated = true"
              disabled
            ></textarea>
          </div>
        </div>
      </div>
      <span v-if="fund.fundTypeId == 1">
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uAbbreviation.length > 0 && fundUmbrella.fundAbbreviation != fund.fundAbbreviation" @click="fund.fundAbbreviation = fundUmbrella.fundAbbreviation"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="fundAbbreviation_u" class="col-form-label"
            >Deal Abbreviation</label
          >
          <input
            type="text"
            class="form-control"
            id="fundAbbreviation_u"
            placeholder="Not Provided"
            v-model="fundUmbrella.fundAbbreviation"
            disabled
          />
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uFirstCloseAmt !== '' && fundUmbrella.firstCloseAmount != fund.firstCloseAmount" @click="fund.firstCloseAmount = fundUmbrella.firstCloseAmount"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="firstCloseAmount_u" class="col-form-label"
            >First Close Amount (M)</label
          >
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ symbol }}</span>
            </div>
            <input
              type="text"
              class="form-control"
              id="firstCloseAmount_u"
              placeholder="Not Provided"
              v-model.number="fundUmbrella.firstCloseAmount"
              disabled
            />
          </div>
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uFirstCloseDate !== '' && fundUmbrella.firstCloseDate != fund.firstCloseDate" @click="fund.firstCloseDate = fundUmbrella.firstCloseDate"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="firstCloseDate_u" class="col-form-label"
            >First Close Date</label
          >
          <date-picker
            id="firstCloseDate_u"
            v-model="fundUmbrella.firstCloseDate"
            placeholder="Not Provided"
            calendar-class="calendar"
            :monday-first="true"
            :typeable="true"
            :clear-button="true"
            clear-button-icon="fa fa-times"
            format="MM/dd/yyyy"
            :bootstrap-styling="true"
            disabled
          ></date-picker>
        </div>
      </span>
      
      <div class="form-group">
        <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uTargetSize !== '' && fundUmbrella.targetSize != fund.targetSize" @click="fund.targetSize = fundUmbrella.targetSize"><i class="mdi mdi-arrow-left-bold"></i></button>
        <label for="targetSize_u" class="col-form-label"
          >Target Size (M)</label
        >
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{{ symbol }}</span>
          </div>
          <input
            type="text"
            class="form-control"
            id="targetSize_u"
            placeholder="Not Provided"
            v-model.number="fundUmbrella.targetSize"
            disabled
          />
        </div>
      </div>
      <div class="form-group">
        <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uHardCap !== '' && fund.hardCap != fundUmbrella.hardCap" @click="fund.hardCap = fundUmbrella.hardCap"><i class="mdi mdi-arrow-left-bold"></i></button>
        <label for="hardCap_u" class="col-form-label">Hard Cap (M)</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{{ symbol }}</span>
          </div>
          <input
            type="text"
            class="form-control"

            id="hardCap_u"
            placeholder="Not Provided"
            v-model.number="fundUmbrella.hardCap"
            disabled
          />
        </div>
      </div>
      <span v-if="fund.fundTypeId == 1">
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uInvestmentStrategy !== '' && fund.investmentStrategy != fundUmbrella.investmentStrategy" @click="fund.investmentStrategy = fundUmbrella.investmentStrategy"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="investmentStrategy_u" class="col-form-label"
            >Investment Strategy</label
          >
          <textarea
            class="form-control"
            id="investmentStrategy_u"
            placeholder="Not Provided"
            rows="5"
            v-model="fundUmbrella.investmentStrategy"
            disabled
          ></textarea>
        </div>

        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uTargetReturn !== '' && fund.targetReturn != fundUmbrella.targetReturn" @click="fund.targetReturn = fundUmbrella.targetReturn"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="targetReturn_u" class="col-form-label"
            >Target Return</label
          >
          <input
            type="text"
            class="form-control"
            id="targetReturn_u"
            placeholder="Not Provided"
            v-model="fundUmbrella.targetReturn"
            disabled
          />
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uTargetInvRange !== '' && fund.investmentSizeRange != fundUmbrella.investmentSizeRange" @click="fund.investmentSizeRange = fundUmbrella.investmentSizeRange"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="investmentSizeRange_u" class="col-form-label"
            >Target Investment Size Range</label
          >
          <input
            type="text"
            class="form-control"
            id="investmentSizeRange_u"
            placeholder="Not Provided"
            v-model="fundUmbrella.investmentSizeRange"
            disabled
          />
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uTargetNumInvestments !== '' && fund.numberofInvestments != fundUmbrella.numberofInvestments" @click="fund.numberofInvestments = fundUmbrella.numberofInvestments"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="numberofInvestments_u" class="col-form-label"
            >Target Number of Investments</label
          >
          <input
            type="text"
            class="form-control"
            id="numberofInvestments_u"
            placeholder="Not Provided"
            v-model="fundUmbrella.numberofInvestments"
            disabled
          />
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uInvestmentPeriod !== '' && fund.investmentPeriod != fundUmbrella.investmentPeriod" @click="fund.investmentPeriod = fundUmbrella.investmentPeriod"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="investmentPeriod_u" class="col-form-label"
            >Investment Period (Years)</label
          >
          <input
            type="text"
            class="form-control"
            id="investmentPeriod_u"
            placeholder="Not Provided"
            v-model.number="fundUmbrella.investmentPeriod"
            disabled
          />
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uFundTerm !== '' && fund.fundTerm != fundUmbrella.fundTerm" @click="fund.fundTerm = fundUmbrella.fundTerm"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="fundTerm_u" class="col-form-label"
            >Fund Term (Years)</label
          >
          <input
            type="text"
            class="form-control"
            id="fundTerm_u"
            placeholder="Not Provided"
            v-model="fundUmbrella.fundTerm"
            disabled
          />
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uGPCommitment !== '' && fund.gpCommitmentPercent != fundUmbrella.gpCommitmentPercent" @click="fund.gpCommitmentPercent = fundUmbrella.gpCommitmentPercent"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="gpCommitmentPercent_u" class="col-form-label"
            >GP Commitment</label
          >
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
            <input
              type="text"
              class="form-control"
              id="gpCommitmentPercent_u"
              placeholder="Not Provided"
              v-model.number="fundUmbrella.gpCommitmentPercent"
              disabled
            />
          </div>
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uManagementFeeIP !== '' && fund.managementFeePercent != fundUmbrella.managementFeePercent" @click="fund.managementFeePercent = fundUmbrella.managementFeePercent"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="managementFeePercent_u" class="col-form-label"
            >Management Fee (Investment Period)</label
          >
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
            <input
              type="text"
              class="form-control"
              id="managementFeePercent_u"
              placeholder="Not Provided"
              v-model.number="fundUmbrella.managementFeePercent"
              disabled
            />
          </div>
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uManagementFeeStructure !== '' && fund.managementFeeStructureId != fundUmbrella.managementFeeStructureId" @click="fund.managementFeeStructureId = fundUmbrella.managementFeeStructureId"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label class="col-form-label"
            >Management Fee Structure</label
          >
          <span>
            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="Structure1_u"
                value="1"
                v-model="fundUmbrella.managementFeeStructureId"
                Term="Structure"
                class="custom-control-input"
                disabled
              />
              <label class="custom-control-label" for="Structure1_u"
                >Committed</label
              >
            </div>
            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="Structure2_u"
                value="2"
                v-model="fundUmbrella.managementFeeStructureId"
                Term="Structure"
                class="custom-control-input"
                disabled
              />
              <label class="custom-control-label" for="Structure2_u"
                >Invested</label
              >
            </div>
          </span>
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uCarriedInterest !== '' && fund.carriedInterestPercent != fundUmbrella.carriedInterestPercent" @click="fund.carriedInterestPercent = fundUmbrella.carriedInterestPercent"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="carriedInterestPercent_u" class="col-form-label"
            >Carried Interest</label
          >
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
            <input
              type="text"
              class="form-control"
              id="carriedInterestPercent_u"
              placeholder="Not Provided"
              v-model.number="fundUmbrella.carriedInterestPercent"
            />
          </div>
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uCIDescription !== '' && fund.carriedInterestDesc != fundUmbrella.carriedInterestDesc" @click="fund.carriedInterestDesc = fundUmbrella.carriedInterestDesc"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="carriedInterestDesc_u" class="col-form-label"
            >Carried Interest - Waterfall Type</label
          >
          <input
            type="text"
            class="form-control"
            id="carriedInterestDesc_u"
            placeholder="Not Provided"
            v-model="fundUmbrella.carriedInterestDesc"
          />
        </div>

        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uPreferredReturn !== '' && fund.preferredReturnPercent != fundUmbrella.preferredReturnPercent" @click="fund.preferredReturnPercent = fundUmbrella.preferredReturnPercent"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="preferredReturnPercent_u" class="col-form-label"
            >Preferred Return</label
          >
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
            <input
              type="text"
              class="form-control"
              id="preferredReturnPercent_u"
              placeholder="Not Provided"
              v-model.number="fundUmbrella.preferredReturnPercent"
            />
          </div>
        </div>
        <div class="form-group">
          <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uTrackRecord !== '' && fund.trackRecordSummary != fundUmbrella.trackRecordSummary" @click="fund.trackRecordSummary = fundUmbrella.trackRecordSummary"><i class="mdi mdi-arrow-left-bold"></i></button>
          <label for="trackRecordSummary_u" class="col-form-label"
            >Track Record Summary</label
          >
          <textarea
            class="form-control"
            id="trackRecordSummary_u"
            placeholder="Not Provided"
            rows="5"
            v-model="fundUmbrella.trackRecordSummary"
          ></textarea>
        </div>
        <!-- <div name='kp-data-u' v-if='inEditMode'>
          <div class="tab-pane fade show active">
            <label for="kp-data-u" class="col-form-label">
              Key Personnel
            </label>
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Job Title</th>
                </tr>
              </thead>
              <FundKeyPersonnelList :list="fundUmbrella.fundKeyPersonnels" />
            </table>
          </div>
        </div>
        <div class="form-group" v-if="inEditMode">
          <label for="docs-u" class="col-form-label"> Documents </label>
          <div id="docs-u" class="tab-pane fade show active">
            <FundDocumentList :files="fundUmbrella.documents || []" />
          </div>
        </div>
        <div id="tr-data" class="form-group" v-if="inEditMode">
          <label for="tr-data-u" class="col-form-label">
            Track Record
          </label>
          <div class="tab-pane fade show active">
            <table class="table table-striped table-hover">
              <thead>
                <tr id='tr-data-u'>
                  <th>Fund Name</th>
                  <th>Target Size</th>
                  <th>Vintage</th>
                  <th>Gross ROI</th>
                  <th>Net ROI</th>
                  <th>Gross IRR</th>
                  <th>Net IRR</th>
                </tr>
              </thead>
              <FundPerformancesList :list="fundUmbrella.fundPerformances" />
            </table>
          </div>
        </div> -->
      </span>
    </span>
  </div>
</template>


<script>
import FundDocumentList from '@/components/portal/FundDocumentList'
import FundKeyPersonnelList from '@/components/portal/FundKeyPersonnelList'
import FundPerformancesList from '@/components/portal/FundPerformancesList'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import ActivityList from '@/components/ActivityList'
import { mapActions } from 'vuex'

export default {
  components: {
    FundDocumentList,
    FundKeyPersonnelList,
    FundPerformancesList,
    Multiselect,
    DatePicker,
    ActivityList
  },
  data () {
    return {
      strategyUmb: null,
      showLogs: false
    }
  },
  props: {
    fund: {
      type: Object
    },
    fundUmbrella: {
      type: Object
    },
    inEditMode: {
      type: Boolean
    },
    symbol: {
      type: String
    },
    compareTabToggle: {
      type: Boolean
    }
  },
  methods: {
    ...mapActions(['fetchAllStrategies']),
    updateStrategy () {
      this.fund.sectorId = this.fundUmbrella.sectorId
      this.$emit('updateStrategy', this.strategyUmb)
    },
    updateEsg () {
      this.fund.isSocialReponsible = this.fundUmbrella.isSocialResponsible
      this.fund.esgType = this.fundUmbrella.esgType
      this.fund.esgTypeOther = this.fundUmbrella.esgTypeOther
    }
  },
  computed: {
    allStrategies () {
      return this.$store.state.allStrategies
    },
    uVintage () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.vintageYear !== null ? this.fundUmbrella.vintageYear : ''
    },
    uStrategy () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.sectorId !== null ? this.fundUmbrella.sectorId : ''
    },
    uStrategyDescription () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.sectorDescription !== null ? this.fundUmbrella.sectorDescription : ''
    },
    uESG () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.isSocialResponsible !== null ? this.fundUmbrella.isSocialResponsible : ''
    },
    uESGType () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.esgType !== null ? this.fundUmbrella.esgType : ''
    },
    uESGTypeOther () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.esgTypeOther !== null ? this.fundUmbrella.esgTypeOther : ''
    },
    esgTest () {
      return (this.fundUmbrella.isSocialResponsible === this.fund.isSocialResponsible) && (this.fundUmbrella.esgType === this.fund.esgType) && (this.fundUmbrella.esgOther === this.fund.esgOther)
    },
    uAbbreviation () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.fundAbbreviation !== null ? this.fundUmbrella.fundAbbreviation : ''
    },
    uFirstCloseAmt () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.firstCloseAmount !== null ? this.fundUmbrella.firstCloseAmount : ''
    },
    uFirstCloseDate () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.firstCloseDate !== null ? this.fundUmbrella.firstCloseDate : ''
    },
    uTargetSize () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.targetSize !== null ? this.fundUmbrella.targetSize : ''
    },
    uHardCap () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.hardCap !== null ? this.fundUmbrella.hardCap : ''
    },
    uInvestmentStrategy () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.investmentStrategy !== null ? this.fundUmbrella.investmentStrategy : ''
    },
    uTargetReturn () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.targetReturn !== null ? this.fundUmbrella.targetReturn : ''
    },
    uTargetInvRange () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.investmentSizeRange !== null ? this.fundUmbrella.investmentSizeRange : ''
    },
    uTargetNumInvestments () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.numberofInvestments !== null ? this.fundUmbrella.numberofInvestments : ''
    },
    uInvestmentPeriod () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.investmentPeriod !== null ? this.fundUmbrella.investmentPeriod : ''
    },
    uFundTerm () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.fundTerm !== null ? this.fundUmbrella.fundTerm : ''
    },
    uGPCommitment () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.gpCommitmentPercent !== null ? this.fundUmbrella.gpCommitmentPercent : ''
    },
    uManagementFeeIP () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.managementFeePercent !== null ? this.fundUmbrella.managementFeePercent : ''
    },
    uManagementFeeStructure () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.managementFeeStructureId !== null ? this.fundUmbrella.managementFeeStructureId : ''
    },
    uCarriedInterest () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.carriedInterestPercent !== null ? this.fundUmbrella.carriedInterestPercent : ''
    },
    uCIDescription () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.carriedInterestDesc !== null ? this.fundUmbrella.carriedInterestDesc : ''
    },
    uPreferredReturn () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.preferredReturnPercent !== null ? this.fundUmbrella.preferredReturnPercent : ''
    },
    uTrackRecord () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.trackRecordSummary !== null ? this.fundUmbrella.trackRecordSummary : ''
    },
    uParentFund () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.parentFundName !== null ? this.fundUmbrella.parentFundName : ''
    },
    uLTMRevenue () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.ltmRevenue !== null ? this.fundUmbrella.ltmRevenue : ''
    },
    uLTMEBITDA () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.ltmEbitda !== null ? this.fundUmbrella.ltmEbitda : ''
    },
    uEnterpriseValue () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.enterpriseValue !== null ? this.fundUmbrella.enterpriseValue : ''
    },
    uTotalDebt () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.totalCompanyDebt !== null ? this.fundUmbrella.totalCompanyDebt : ''
    },
    uTotalEquity () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.totalCompanyEquity !== null ? this.fundUmbrella.totalCompanyEquity : ''
    },
    uAmountOfferedAll () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.amountOffered !== null ? this.fundUmbrella.amountOffered : ''
    },
    uAmountOfferedWPM () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.amountOfferedWpm !== null ? this.fundUmbrella.amountOfferedWpm : ''
    },
    uDealDescription () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.dealDescription !== null ? this.fundUmbrella.dealDescription : ''
    }
  },
  created () {
    this.fetchAllStrategies()
    const ss = this.fundUmbrella.subSectorId ? this.fundUmbrella.subSectorId : 0
    this.strategyUmb = this.allStrategies.filter(t => t.sectorId === this.fundUmbrella.sectorId && t.subSectorId === ss)
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
