<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'user-admin'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid position-relative">
                    <router-link v-if="isAdmin" :to="{name: 'UserInfo'}" style="right: 15px; top: -5px" class="position-absolute btn btn-icon btn-sm waves-effect waves-light btn-primary"><i class="mdi mdi-account-plus"></i> Add</router-link>
                    <PageTitle :title="title"/>
                    <div class="row mb-2">
                      <div class="col-12">
                        Only PMG <toggle-button v-model="onlyPMG" />
                        Only Active <toggle-button v-model="onlyActive" />
                        <input type="search" v-model="searchQuery" class="form-control form-control-sm w-auto d-inline-block ml-3" placeholder="Search by name or email">
                        <div class="btn-group pull-right">
                          <button @click="setView('g')" class="btn btn-icon waves-effect waves-light btn-primary mr-1 " v-tooltip="'Grid View'" :disabled="viewAs == 'g'"> <i class="mdi mdi-view-grid"></i> </button>
                          <button @click="setView('l')" class="btn btn-icon waves-effect waves-light btn-primary" v-tooltip="'List View'" :disabled="viewAs == 'l'"> <i class="mdi mdi-view-list"></i> </button>
                        </div>
                      </div>
                    </div>
                    <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
                    <UserCart :users="usersResult" v-if="viewAs == 'g'" />
                    <UserList :users="usersResult" v-if="viewAs == 'l'" />
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import UserCart from '@/pages/account/UsersCart'
import UserList from '@/pages/account/UsersList'
import { UserService } from '@/services/user-service'
import { mapGetters } from 'vuex'

export default {
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      list: [],
      isLoading: false,
      viewAs: null,
      officeFilterId: 0,
      title: '',
      onlyPMG: false,
      onlyActive: true,
      searchQuery: null
    }
  },
  components: {
    UserCart,
    UserList
  },
  mixins: [asyncDataStatus],
  created () {
    switch (this.id.toLowerCase()) {
      case 'santa-monica':
        this.officeFilterId = 1
        this.title = 'Users of Santa Monica Office'
        break
      case 'pittsburgh':
        this.officeFilterId = 2
        this.title = 'Users of Pittsburgh Office'
        break
      case 'amsterdam':
        this.officeFilterId = 3
        this.title = 'Users of Amsterdam Office'
        break
      case 'hong-kong':
        this.officeFilterId = 4
        this.title = 'Users of Hong Kong Office'
        break
      case 'new-york':
        this.officeFilterId = 5
        this.title = 'Users of New York Office'
        break
      case 'chicago':
        this.officeFilterId = 6
        this.title = 'Users of Chicago Office'
        break
      case 'all':
        this.officeFilterId = 0
        this.title = 'All Users'
        break
      default:
        this.$router.push({name: 'NotFound'})
        break
    }
    this.loadUsers()
    this.viewAs = this.id.toLowerCase() === 'all' ? 'l' : 'g'
    this.asyncDataStatus_fetched('Users')
  },
  methods: {
    loadUsers () {
      this.isLoading = true
      UserService.getOffice()
      .then((response) => {
        this.list = response.data
        this.list = this.list.filter(x => x.userId > 0)
        if (this.officeFilterId > 0) {
          this.list = this.list.filter(x => x.officeId === this.officeFilterId)
        }
        this.isLoading = false
        localStorage.setItem('Users', JSON.stringify(response.data))
      })
      .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    setView (v) {
      this.viewAs = v
    },
    // used to check if a search query is found in a string
    stringContainsSubstring(str, substr) {
      return substr.toLowerCase().split(" ").every(x => str.toLowerCase().includes(x))
    },
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    isAdmin () {
      return this.user.roles.some(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR)
    },
    usersResult () {
      let result = this.list
      if (this.onlyPMG) {
        result = result.filter(x => x.groupId < 11)
      }
      if (this.onlyActive) {
        result = result.filter(x => x.isActive)
      }
      if (this.searchQuery) {
        // checks if user name or email matches the search query
        return result.filter(user => {
          return [user.fullName, user.email].some(str => {
            return this.stringContainsSubstring(str, this.searchQuery)
          })
        });
      } else {
        return result;
      }
    }
  }
}
</script>
