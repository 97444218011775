/* eslint-disable no-dupe-keys */
import Constants from '../constants.js'
import Axios from 'axios'

export const UserService = {
  current () {
    return Axios.get(Constants.API_URL + '/user')
  },
  get (userId) {
    return Axios.get(Constants.API_URL + '/user/' + userId)
  },
  getAll () {
    return Axios.get(Constants.API_URL + '/user/all')
  },
  getByRoleId (roleId) {
    return Axios.get(Constants.API_URL + '/user/role/' + roleId)
  },
  getOffice () {
    return Axios.get(Constants.API_URL + '/user/office')
  },
  getSignHistory (userId, lastId) {
    return Axios.get(Constants.API_URL + '/user/login-history/' + userId + '/' + lastId)
  },
  getAllGroups () {
    return Axios.get(Constants.API_URL + '/user-group')
  },
  getAllRoles () {
    return Axios.get(Constants.API_URL + '/user-roles')
  },
  getAllTitles () {
    return Axios.get(Constants.API_URL + '/user-title')
  },
  updateRoles (userId, data) {
    return Axios.put(Constants.API_URL + '/user/roles/' + userId, data)
  },
  addUser (data) {
    return Axios.post(Constants.API_URL + '/user', data)
  },
  updateUser (userId, data) {
    return Axios.put(Constants.API_URL + '/user/' + userId, data)
  },
  deleteUser (userId) {
    return Axios.delete(Constants.API_URL + '/user/' + userId)
  }
}

export const BrowseService = {
  addLog (browse) {
    return Axios.post(Constants.API_URL + '/browse', browse)
  },
  getList (userId, lastId) {
    return Axios.get(Constants.API_URL + '/browse/list/' + userId + '/' + lastId)
  },
  getLast (lastId) {
    return Axios.get(Constants.API_URL + '/browse/last/' + lastId)
  }
}
