<template>
  <div>
    <div class="form-group">
      <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uName.length > 0 && fundUmbrella.fundName != fund.fundName" @click="fund.fundName = fundUmbrella.fundName"><i class="mdi mdi-arrow-left-bold"></i></button>
      <label for="fundName_u" class="col-form-label"
        >Name</label
      >
      <input
        type="text"
        class="form-control"
        id="fundName_u"
        v-model.trim="fundUmbrella.fundName"
        readonly="readonly"
        disabled
      />
    </div>
     <div class="form-group">
      <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uFirm.length > 0 && fundUmbrella.firmName != fund.firmName" @click="fund.firmName = fundUmbrella.firmName"><i class="mdi mdi-arrow-left-bold"></i></button>
      <label for="firmName" class="col-form-label"
        >General Partner</label
      >
      <input
        type="text"
        class="form-control"
        id="firmName"
        placeholder="Not Provided"
        v-model="fundUmbrella.firmName"
        disabled
      />
    </div>
    <div class="form-group">
      <label for="fundTypeId_u"
        >Type</label
      >
      <div class="custom-control custom-radio">
        <input
          type="radio"
          id="type1_u"
          value="1"
          v-model.trim="fundUmbrella.fundTypeId"
          name="type_u"
          class="custom-control-input"
          disabled
        />
        <label class="custom-control-label" for="type1_u">Primary</label>
      </div>
      <div class="custom-control custom-radio">
        <input
          type="radio"
          id="type2_u"
          value="2"
          v-model.trim="fundUmbrella.fundTypeId"
          name="type_u"
          class="custom-control-input"
          disabled
        />
        <label class="custom-control-label" for="type2_u">Secondary</label>
      </div>
      <div class="custom-control custom-radio">
        <input
          type="radio"
          id="type3_u"
          value="3"
          v-model.trim="fundUmbrella.fundTypeId"
          name="type_u"
          class="custom-control-input"
          disabled
        />
        <label class="custom-control-label" for="type3_u">Co-Investment</label>
      </div>
    </div>
    <div class="form-group">
      <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uCurrency != '' && fundUmbrella.dealCurrencyId != fund.dealCurrencyId" @click="updateCurrency"><i class="mdi mdi-arrow-left-bold"></i></button>
      <label for="dealCurrencyId_u" class="col-form-label"
        >Currency</label
      >
      <multiselect
        id="dealCurrencyId_u"
        class="vueselect"
        v-model="dealCurrencyUmb"
        :options="allCurrencies"
        label="currencyName"
        track-by="currencyId"
        :allow-empty="false"
        disabled
      ></multiselect>
    </div>
    <div class="form-group">
      <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uGeoFocus != '' && fundUmbrella.geographicFocusId != fund.geographicFocusId" @click="updateGeoFocus"><i class="mdi mdi-arrow-left-bold"></i></button>
      <label for="geographicFocusId_u" class="col-form-label"
        >Geographic Focus</label
      >
      <multiselect
        id="geographicFocusId_u"
        class="vueselect"
        v-model="geographicFocusUmb"
        :options="allGeoFocuses"
        label="geographicName"
        track-by="geographicId"
        :allow-empty="false"
        disabled
      ></multiselect>
    </div>
    <div class="form-group">
      <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uStartDate.length > 0 && fundUmbrella.expectedStartDate != fund.expectedStartDate" @click="fund.expectedStartDate = fundUmbrella.expectedStartDate"><i class="mdi mdi-arrow-left-bold"></i></button>
      <label for="expectedStartDate_u" class="col-form-label"
        >Fundraise Start Date</label
      >
      <date-picker
        id="expectedStartDate_u"
        v-model="fundUmbrella.expectedStartDate"
        placeholder="Not Provided"
        calendar-class="calendar"
        :monday-first="true"
        :typeable="true"
        format="MM/dd/yyyy"
        :bootstrap-styling="true"
        disabled
      ></date-picker>
    </div>
    <div class="form-group">
      <button :disabled='!inEditMode' class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uCloseDate.length > 0 && fundUmbrella.expectedCloseDate != fund.expectedCloseDate" @click="updateCloseDate"><i class="mdi mdi-arrow-left-bold"></i></button>
      <label for="expectedCloseDate_u" class="col-form-label"
        >Fundraise Close Date
        </label>
      <date-picker
        id="expectedCloseDate_u"
        v-model="fundUmbrella.expectedCloseDate"
        placeholder="Not Provided"
        calendar-class="calendar"
        :monday-first="true"
        :typeable="true"
        format="MM/dd/yyyy"
        :bootstrap-styling="true"
        disabled
      ></date-picker>
      <div class="custom-control custom-checkbox" v-if="fund.fundTypeId < 3">
        <input
          type="checkbox"
          class="custom-control-input"
          id="OpenEnded_u"
          v-model="fundUmbrella.isOpenEnded"
          disabled
        />
        <label class="custom-control-label" for="OpenEnded_u">Open Ended</label>
      </div>
    </div>
  </div>
</template>
<script>

import FundDocumentList from '@/components/portal/FundDocumentList'
import FundKeyPersonnelList from '@/components/portal/FundKeyPersonnelList'
import FundPerformancesList from '@/components/portal/FundPerformancesList'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import ActivityList from '@/components/ActivityList'
import { mapActions } from 'vuex'

export default {
  components: {
    FundDocumentList,
    FundKeyPersonnelList,
    FundPerformancesList,
    Multiselect,
    DatePicker,
    ActivityList
  },
  data () {
    return {
      dealCurrencyUmb: null,
      geographicFocusUmb: null
    }
  },
  props: {
    fund: {
      type: Object
    },
    fundUmbrella: {
      type: Object
    },
    inEditMode: {
      type: Boolean
    }
  },
  methods: {
    ...mapActions(['fetchAllCurrencies', 'fetchAllGeoFocus']),
    updateCurrency () {
      this.fund.dealCurrencyId = this.fundUmbrella.dealCurrencyId
      this.$emit('updateCurrency', this.dealCurrencyUmb)
    },
    updateGeoFocus () {
      this.fund.geographicFocusId = this.fundUmbrella.geographicFocusId
      this.$emit('updateGeoFocus', this.geographicFocusUmb)
    },
    updateCloseDate () {
      this.fund.expectedEndDate = this.fundUmbrella.expectedEndDate
      this.fund.isOpenEnded = this.fundUmbrella.isOpenEnded
    }
  },
  computed: {
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    uFirm () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.firmName !== null ? this.fundUmbrella.firmName : ''
    },
    uName () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.fundName !== null ? this.fundUmbrella.fundName : ''
    },
    uCurrency () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.dealCurrencyId !== null ? this.fundUmbrella.dealCurrencyId : ''
    },
    uGeoFocus () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.geographicFocusId !== null ? this.fundUmbrella.geographicFocusId : ''
    },
    uStartDate () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.expectedStartDate !== null ? this.fundUmbrella.expectedStartDate : ''
    },
    uCloseDate () {
      if (this.fundUmbrella == null) {
        return ''
      }
      return this.fundUmbrella.expectedCloseDate !== null ? this.fundUmbrella.expectedCloseDate : ''
    }
  },
  created () {
    this.fetchAllCurrencies()
    this.fetchAllGeoFocus()
    this.dealCurrencyUmb = this.allCurrencies.filter(t => t.currencyId === this.fundUmbrella.dealCurrencyId)
    this.geographicFocusUmb = this.allGeoFocuses.filter(t => t.geographicId === this.fundUmbrella.geographicFocusId)
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>