<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portfolio-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="portfolio">
                    <Toolbar :title="'Portfolio Details - '+portfolio.portfolioName"
                    :inEditMode="inEditMode" :canDelete="canbeDeleted" :canEdit="canbeEdited" :isSaving="inSaving" :canOpen="canbeOpened" :canClose="canbeClosed"
                    @onEdit="EditForm" @onCancel="cancelForm" @onDelete="deleteForm" @onSave="saveForm" :pinId="portfolio.pinId" @onPin="addPin" @onPinOff="delPin" @onOpening="startVote" @onClosing="startCloseVote" @onRiskCalculator="onRiskCalculator" />
                    <div class="row">
                      <form action="#" autocomplete="off" style="width:100%">
                        <div class="col-md-12">
                            <div class="card-box">
                                <ul class="nav nav-tabs tabs-bordered">
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(1)" :class="{'active': activeTab == 1}">
                                            <i class="mdi mdi-information-outline"></i> Summary
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(2)" :class="{'active': activeTab == 2}">
                                            <i class="mdi mdi-chart-gantt"></i> Slots
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(3)" :class="{'active': activeTab == 3}">
                                            <i class="mdi mdi-chart-pie"></i> Charts
                                        </a>
                                    </li>
                                       <li class="nav-item" v-if="this.portfolio.clientTypeId == 2">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(4)" :class="{'active': activeTab == 4}">
                                            <i class="mdi mdi-briefcase-check"></i> Votes
                                        </a>
                                    </li>
                                       <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="openPerformanceTab" :class="{'active': activeTab == 5}">
                                            <i class="mdi mdi-chart-line"></i> Performance
                                        </a>
                                    </li>
                                </ul>
                                
                                <div class="tab-content">
                                    <div class="tab-pane fade" :class="{'active show': activeTab == 1}">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label for="portfolioName">Client Name<span v-show="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="portfolio.portfolioName" placeholder="Enter Name" class="form-control" id="portfolioName" :disabled="!inEditMode" 
                                                      @blur="$v.portfolio.portfolioName.$touch()" :class="{ 'parsley-error': $v.portfolio.portfolioName.$error }"/>
                                                    <template v-if="$v.portfolio.portfolioName.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.portfolio.portfolioName.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.portfolio.portfolioName.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="clientId">Client Type: 
                                                      <span v-if="portfolio.clientTypeId == 1" class="text-primary">Advisory</span>
                                                      <span v-if="portfolio.clientTypeId == 2" class="text-primary">Discretionary</span>
                                                    </label>
                                                </div>

                                                <div class="form-group">
                                                    <label>Status:</label>
                                                      <span v-if="portfolio.isActive" class="badge badge-success">Active</span>
                                                      <span v-else class="badge white-bg">Inactive</span>
                                                      <span v-if="portfolio.isClosed" class="badge badge-primary">Closed</span>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 1">
                                                    <label for="clientName">Client (Connect): </label>
                                                    <input type="text" v-model.trim="portfolio.connectOrganizationName" placeholder="Enter Client Name" class="form-control" id="clientName" disabled="disabled" />
                                                </div>

                                                <div class="form-group">
                                                    <label for="CurrencyId">Currency <span v-show="inEditMode" class="text-danger">*</span></label>
                                                    <span :class="{ 'invalid': $v.portfolio.currencyId.$error }">
                                                      <multiselect id="CurrencyId" v-model="currencyObj" :options="allCurrencies" :disabled="!inEditMode" label="currencyName" track-by="currencyId" :allow-empty="false" @input="currencySelected"></multiselect>
                                                    </span>
                                                    <template v-if="$v.portfolio.currencyId.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.portfolio.currencyId.required" class="parsley-required">This value is required</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 2">
                                                  <label for="TotalCommitment">Target Fund Size <span v-show="inEditMode" class="text-danger">*</span></label>
                                                  <div class="input-group">
                                                      <div class="input-group-prepend">
                                                          <span class="input-group-text">{{symbol}}</span>
                                                      </div>
                                                      <input type="text" v-model.number="portfolio.totalCommitment" :disabled="!inEditMode || portfolio.totalCommitmentNa" @blur="$v.portfolio.totalCommitment.$touch()" placeholder="Enter Total Commitment" class="form-control" id="TotalCommitment" :class="{ 'parsley-error': $v.portfolio.totalCommitment.$error }">
                                                  </div>
                                                  <template v-if="$v.portfolio.totalCommitment.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.portfolio.totalCommitment.required" class="parsley-required">This value is required</li>
                                                      <li v-if="!$v.portfolio.totalCommitment.decimal" class="parsley-required">This value is not valid</li>
                                                      <li v-if="!$v.portfolio.totalCommitment.min" class="parsley-required">This value is not valid</li>
                                                      <li v-if="!$v.portfolio.totalCommitment.max" class="parsley-required">This value is too big</li>
                                                    </ul>
                                                  </template>
                                                  <div class="checkbox-primary checkbox" v-show="portfolio.totalCommitmentTodayNa || portfolio.totalCommitment == 0">
                                                      <input id='totalCommitmentNa' type="checkbox" v-model="portfolio.totalCommitmentNa" :disabled="!inEditMode" />
                                                      <label for='totalCommitmentNa'>NA</label>
                                                  </div>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 2">
                                                  <label for="TotalCommitmentToday">Amount Raised to-date <span v-show="inEditMode" class="text-danger">*</span></label>
                                                  <div class="input-group">
                                                      <div class="input-group-prepend">
                                                          <span class="input-group-text">{{symbol}}</span>
                                                      </div>
                                                      <input type="text" v-model.number="portfolio.totalCommitmentToday" :disabled="!inEditMode || portfolio.totalCommitmentTodayNa" @blur="$v.portfolio.totalCommitmentToday.$touch()" placeholder="Enter Total Commitment" class="form-control" id="TotalCommitmentToday" :class="{ 'parsley-error': $v.portfolio.totalCommitmentToday.$error }">
                                                  </div>
                                                  <template v-if="$v.portfolio.totalCommitmentToday.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.portfolio.totalCommitmentToday.required" class="parsley-required">This value is required</li>
                                                      <li v-if="!$v.portfolio.totalCommitmentToday.decimal" class="parsley-required">This value is not valid</li>
                                                      <li v-if="!$v.portfolio.totalCommitmentToday.min" class="parsley-required">This value is too small</li>
                                                      <li v-if="!$v.portfolio.totalCommitmentToday.max" class="parsley-required">This value is too big</li>
                                                    </ul>
                                                  </template>
                                                  <div class="checkbox-primary checkbox" v-show="portfolio.totalCommitmentNa || portfolio.totalCommitmentToday == 0">
                                                      <input id='totalCommitmentTodayNa' type="checkbox" v-model="portfolio.totalCommitmentTodayNa" :disabled="!inEditMode" />
                                                      <label for='totalCommitmentTodayNa'>NA</label>
                                                  </div>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 2">
                                                    <label for="FirstCloseDate">First Close <span v-show="inEditMode" class="text-danger">*</span></label>
                                                    <date-picker v-model="portfolio.firstCloseDate" :disabled="!inEditMode" @blur="$v.portfolio.firstCloseDate.$touch()" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.portfolio.firstCloseDate.$error }"></date-picker>
                                                    <template v-if="$v.portfolio.firstCloseDate.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.portfolio.firstCloseDate.required" class="parsley-required">This value is required</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 2">
                                                    <label for="FinalCloseDate">Final Close <span v-show="inEditMode" class="text-danger">*</span></label>
                                                    <date-picker v-model="portfolio.finalCloseDate" :disabled="!inEditMode || portfolio.finalCloseDateNa" @blur="$v.portfolio.finalCloseDate.$touch()" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.portfolio.finalCloseDate.$error }"></date-picker>
                                                    <template v-if="$v.portfolio.finalCloseDate.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.portfolio.finalCloseDate.required" class="parsley-required">This value is required</li>
                                                      </ul>
                                                    </template>
                                                    <div class="checkbox-primary checkbox" v-show="portfolio.finalCloseDateNa">
                                                        <input id='finalCloseDateNa' type="checkbox" v-model="portfolio.finalCloseDateNa" :disabled="!inEditMode" />
                                                        <label for='finalCloseDateNa'>NA</label>
                                                    </div>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 2">
                                                    <label for="InvEndDate">Investment Period End</label>
                                                    <date-picker v-model="portfolio.invEndDate" :disabled="!inEditMode || portfolio.invEndDateNa" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                    <div v-show="portfolio.invEndDateNa || !portfolio.invEndDate" class="checkbox-primary checkbox">
                                                      <input id='invEndDateNa' type="checkbox" v-model="portfolio.invEndDateNa" :disabled="!inEditMode" />
                                                      <label for='invEndDateNa'>NA</label>
                                                    </div>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 1">
                                                    <label for="MaterialDueDate">Material Due Date</label>
                                                    <date-picker v-model="portfolio.materialDueDate" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 1">
                                                    <label for="ICMeetingDate">IC Meeting Date</label>
                                                    <date-picker v-model="portfolio.icMeetingDate" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 2">
                                                  <label for="MaxCommitmentPercent">Max Commitment</label> <i class="mdi mdi-information" v-tooltip="'Maximum Commitment % allowed in LPA'"></i>
                                                  <div class="input-group">
                                                      <div class="input-group-prepend">
                                                          <span class="input-group-text">%</span>
                                                      </div>
                                                      <input type="text" v-model.number="portfolio.maxCommitmentPercent" :disabled="!inEditMode" @blur="$v.portfolio.maxCommitmentPercent.$touch()" placeholder="Enter Max Commitment" class="form-control" id="MaxCommitmentPercent" :class="{ 'parsley-error': $v.portfolio.maxCommitmentPercent.$error }">
                                                  </div>
                                                  <template v-if="$v.portfolio.maxCommitmentPercent.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.portfolio.maxCommitmentPercent.decimal" class="parsley-required">This value is not valid</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 2">
                                                  <label for="maxRecommendedCommitmentPercent">WPM Recommended Max Commitment</label>
                                                  <div class="input-group">
                                                      <div class="input-group-prepend">
                                                          <span class="input-group-text">%</span>
                                                      </div>
                                                      <input type="text" v-model.number="portfolio.maxRecommendedCommitmentPercent" :disabled="!inEditMode" @blur="$v.portfolio.maxRecommendedCommitmentPercent.$touch()" placeholder="Enter Max Recommended Commitment" class="form-control" id="maxRecommendedCommitmentPercent" :class="{ 'parsley-error': $v.portfolio.maxRecommendedCommitmentPercent.$error }">
                                                  </div>
                                                  <template v-if="$v.portfolio.maxRecommendedCommitmentPercent.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.portfolio.maxRecommendedCommitmentPercent.decimal" class="parsley-required">This value is not valid</li>
                                                    </ul>
                                                  </template>
                                                </div>
                                                
                                                <div class="form-group text-primary" v-if="portfolio.clientTypeId == 2">
                                                  <label for="proforma">Pro Forma Over Commitment as of % Target Fund Size</label>
                                                  <div class="input-group">
                                                      <div class="input-group-prepend">
                                                          <span class="input-group-text">%</span>
                                                      </div>
                                                      <input type="text" v-model="proformaOverCommitment" readonly="readonly" class="form-control" id="proforma">
                                                  </div>
                                                </div>

                                                <div class="form-group text-primary" v-if="portfolio.clientTypeId == 2">
                                                  <label for="proforma">Pro Forma Over Commitment as of % Amount Raised to date</label>
                                                  <div class="input-group">
                                                      <div class="input-group-prepend">
                                                          <span class="input-group-text">%</span>
                                                      </div>
                                                      <input type="text" v-model="proformaOverCommitmentToday" readonly="readonly" class="form-control" id="proforma">
                                                  </div>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 2">
                                                  <label for="guidelines">Additional Guidelines</label>
                                                  <textarea v-model="portfolio.guidelines" rows="3" maxlength="500" :disabled="!inEditMode" @blur="$v.portfolio.guidelines.$touch()" placeholder="Enter Guidelines" class="form-control" id="guidelines" :class="{ 'parsley-error': $v.portfolio.guidelines.$error }"></textarea>
                                                  <template v-if="$v.portfolio.guidelines.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.portfolio.guidelines.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="form-group" v-if="portfolio.clientTypeId == 2">
                                                  <label for="objective">Portfolio Objective</label>
                                                  <textarea v-model="portfolio.objective" rows="3" maxlength="1000" :disabled="!inEditMode" @blur="$v.portfolio.objective.$touch()" placeholder="Enter Objective for  Allocation Recommendation" class="form-control" id="objective" :class="{ 'parsley-error': $v.portfolio.objective.$error }"></textarea>
                                                  <template v-if="$v.portfolio.objective.$error">
                                                    <ul class="parsley-errors-list filled">
                                                      <li v-if="!$v.portfolio.objective.required" class="parsley-required">This value is required</li>
                                                      <li v-if="!$v.portfolio.objective.max" class="parsley-required">This value is too long</li>
                                                    </ul>
                                                  </template>
                                                </div>

                                                <div class="card card-body mb-3">
                                                  <table class="table table-hover table-bordered table-striped">
                                                    <thead>
                                                      <tr>
                                                        <th class="text-primary">Year</th>
                                                        <th v-if="portfolio.clientTypeId == 1">Commitment</th>
                                                        <th v-if="portfolio.clientTypeId == 2">Target %</th>
                                                        <th v-if="portfolio.clientTypeId == 2">Pro forma</th>
                                                        <th style="width:50px;" v-if="portfolio.clientTypeId == 1">Active</th>
                                                        <th v-if="portfolio.clientTypeId == 2">Guideline</th>
                                                        <th style="width:100px;" v-if="canbeEdited">
                                                          <button class="btn btn-primary btn-sm btn-icon" type="submit" @click.prevent="addYear" v-if="!inEditMode && canbeEdited && calcTotalYearCommitmentPerc < maxCommitmentValue">
                                                              <i class="mdi mdi-plus-circle-outline"></i> New
                                                          </button>
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr v-for="year in portfolio.years" :key="year.vintageYear">
                                                        <td>{{year.vintageYear}}</td>
                                                        <td v-if="portfolio.clientTypeId == 1">{{symbol}}{{year.commitment.toLocaleString()}}</td>
                                                        <td v-if="portfolio.clientTypeId == 2">{{year.commitmentPercent}}%</td>
                                                        <td v-if="portfolio.clientTypeId == 2">{{year.proforma}}%</td>
                                                        <td v-if="portfolio.clientTypeId == 1" class="text-center">
                                                          <i v-if="year.isActive" class="mdi mdi-checkbox-marked-outline"></i>
                                                          <i v-else class="mdi mdi-checkbox-blank-outline"></i>
                                                        </td>
                                                        <td v-if="portfolio.clientTypeId == 2">{{year.guidelineMin}}-{{year.guidelineMax}}%</td>
                                                        <td v-if="canbeEdited">
                                                          <div>
                                                            <button class="btn btn-success btn-sm btn-icon mr-1" @click.prevent="updateYear(year.vintageYear)"><i class="mdi mdi-lead-pencil"></i></button>
                                                            <button class="btn btn-danger btn-sm btn-icon" @click.prevent="deleteYear(year.vintageYear)" :disabled="canDeleteYear(year.vintageYear)"><i class="mdi mdi-delete"></i></button>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                    <tfoot v-if="portfolio.years.length > 0">
                                                      <tr>
                                                        <td>
                                                          <strong>Total</strong>
                                                        </td>
                                                        <td v-if="portfolio.clientTypeId == 1">
                                                          <strong :class="{'text-primary' : portfolio.clientTypeId == 2}">
                                                            {{symbol}}{{calcTotalYearCommitmentAll.toLocaleString()}}
                                                          </strong>
                                                        </td>
                                                        <td v-if="portfolio.clientTypeId == 1" colspan="2">
                                                          <strong class="text-primary">
                                                            {{symbol}}{{calcTotalYearCommitment.toLocaleString()}}
                                                          </strong>
                                                          <i class="mdi mdi-information-outline" v-tooltip="'Only Active Years'"></i>
                                                        </td>
                                                        <td v-if="portfolio.clientTypeId == 2" colspan="3">
                                                          <strong class="text-primary">
                                                            {{calcTotalYearCommitmentPerc}}%
                                                          </strong>
                                                        </td>
                                                      </tr>
                                                    </tfoot>
                                                    <tfoot v-else>
                                                      <tr class="bg-warning text-white">
                                                        <td colspan="5">
                                                          No data to show, Please add target Investment Years
                                                        </td>
                                                      </tr>
                                                    </tfoot>
                                                  </table>
                                                </div>

                                                <div class="card card-body mb-3">
                                                  <table class="table table-hover table-striped table-bordered">
                                                    <thead class="">
                                                      <tr>
                                                        <th class="text-primary">Geography</th>
                                                        <th v-if="portfolio.clientTypeId == 1">Commitment</th>
                                                        <th>Target %</th>
                                                        <th>Pro forma</th>
                                                        <th v-if="portfolio.clientTypeId == 2">Guideline</th>
                                                        <th style="width:100px;" v-if="canbeEdited">
                                                          <button class="btn btn-primary btn-sm btn-icon" type="submit" @click.prevent="addGeo" v-if="!inEditMode && notUsedGeos.length > 0 && canbeEdited && calcTotalGeoCommitmentPerc < maxCommitmentValue">
                                                              <i class="mdi mdi-plus-circle-outline"></i> New
                                                          </button>
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr v-for="geo in portfolio.geos" :key="geo.geographicId">
                                                        <td>{{geo.geographicName}}</td>
                                                        <td v-if="portfolio.clientTypeId == 1">{{symbol}}{{geo.commitment}}</td>
                                                        <td>{{geo.commitmentPercent}}%</td>
                                                        <td>{{geo.proforma}}%</td>
                                                        <td v-if="portfolio.clientTypeId == 2">{{geo.guidelineMin}}-{{geo.guidelineMax}}%</td>
                                                        <td v-if="canbeEdited">
                                                          <div>
                                                            <button class="btn btn-success btn-sm btn-icon mr-1" @click.prevent="updateGeo(geo.geographicId)"><i class="mdi mdi-lead-pencil"></i></button>
                                                            <button class="btn btn-danger btn-sm btn-icon" @click.prevent="deleteGeo(geo.geographicId)" :disabled="canDeleteGeo(geo.geographicId)"><i class="mdi mdi-delete"></i></button>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                    <tfoot v-if="portfolio.geos.length > 0">
                                                      <tr>
                                                        <td>
                                                          <strong>Total</strong>
                                                        </td>
                                                        <td v-if="portfolio.clientTypeId == 1">
                                                          <strong class="text-primary">
                                                            {{symbol}}{{calcTotalGeoCommitment.toLocaleString()}}
                                                          </strong>
                                                        </td>
                                                        <td colspan="3">
                                                          <strong class="text-primary">
                                                            {{calcTotalGeoCommitmentPerc.toLocaleString()}}%
                                                          </strong>
                                                        </td>
                                                      </tr>
                                                    </tfoot>
                                                    <tfoot v-else>
                                                      <tr class="bg-warning text-white">
                                                        <td colspan="5">
                                                          No data to show, Please add target Geographies
                                                        </td>
                                                      </tr>
                                                    </tfoot>
                                                  </table>
                                                </div>

                                                <div class="card card-body mb-3" v-if="portfolio.clientTypeId == 2">
                                                  <table class="table table-hover table-striped table-bordered">
                                                    <thead>
                                                      <tr>
                                                        <th class="text-primary">Investment</th>
                                                        <th v-if="portfolio.clientTypeId == 1">Commitment</th>
                                                        <th>Target %</th>
                                                        <th>Pro forma</th>
                                                        <th v-if="portfolio.clientTypeId == 2">Guideline</th>
                                                        <th style="width:100px;" v-if="canbeEdited">
                                                          <button class="btn btn-primary btn-sm btn-icon" type="submit" @click.prevent="addInv" v-if="!inEditMode && notUsedInvs.length > 0 && canbeEdited && calcTotalInvCommitmentPerc < maxCommitmentValue">
                                                              <i class="mdi mdi-plus-circle-outline"></i> New
                                                          </button>
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr v-for="inv in portfolio.invs" :key="inv.invTypeId">
                                                        <td>{{inv.typeName}}</td>
                                                        <td v-if="portfolio.clientTypeId == 1">{{symbol}}{{inv.commitment.toLocaleString()}}</td>
                                                        <td>{{inv.commitmentPercent}}%</td>
                                                        <td>{{inv.proforma}}%</td>
                                                        <td v-if="portfolio.clientTypeId == 2">{{inv.guidelineMin}}-{{inv.guidelineMax}}%</td>
                                                        <td v-if="canbeEdited">
                                                          <div>
                                                            <button class="btn btn-success btn-sm btn-icon mr-1" @click.prevent="updateInv(inv.invTypeId)"><i class="mdi mdi-lead-pencil"></i></button>
                                                            <button class="btn btn-danger btn-sm btn-icon" @click.prevent="deleteInv(inv.invTypeId)" :disabled="canDeleteInv(inv.invTypeId)"><i class="mdi mdi-delete"></i></button>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                    <tfoot v-if="portfolio.invs.length > 0">
                                                      <tr>
                                                        <td>
                                                          <strong>Total</strong>
                                                        </td>
                                                        <td v-if="portfolio.clientTypeId == 1">
                                                          <strong class="text-primary">
                                                            {{symbol}}{{calcTotalInvCommitment.toLocaleString()}}
                                                          </strong>
                                                        </td>
                                                        <td colspan="3">
                                                          <strong class="text-primary">
                                                            {{calcTotalInvCommitmentPerc.toLocaleString()}}%
                                                          </strong>
                                                        </td>
                                                      </tr>
                                                    </tfoot>
                                                    <tfoot v-else>
                                                      <tr class="bg-warning text-white">
                                                        <td colspan="5">
                                                          No data to show, Please add target Investments
                                                        </td>
                                                      </tr>
                                                    </tfoot>
                                                  </table>
                                                </div>

                                                <div class="card card-body mb-3">
                                                  <table class="table table-hover table-striped table-bordered">
                                                    <thead class="">
                                                      <tr>
                                                        <th class="text-primary">Strategy</th>
                                                        <th v-if="portfolio.clientTypeId == 1">Commitment</th>
                                                        <th>Target %</th>
                                                        <th>Pro forma</th>
                                                        <th v-if="portfolio.clientTypeId == 2">Guideline</th>
                                                        <th style="width:100px;" v-if="canbeEdited">
                                                          <button class="btn btn-primary btn-sm btn-icon" type="submit" @click.prevent="addSector" v-if="!inEditMode && notUsedSectors.length > 0 && canbeEdited && calcTotalSectorCommitmentPerc < maxCommitmentValue">
                                                              <i class="mdi mdi-plus-circle-outline"></i> New
                                                          </button>
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr v-for="sec in portfolio.sectors" :key="sec.sectorId">
                                                        <td>{{sectorLabels[`C${sec.sectorId}`]}}</td>
                                                        <td v-if="portfolio.clientTypeId == 1">{{symbol}}{{sec.targetSectorPosition.toLocaleString()}}</td>
                                                        <td>{{sec.targetSectorPercent}}%</td>
                                                        <td>{{sec.proforma}}%</td>
                                                        <td v-if="portfolio.clientTypeId == 2">{{sec.guidelineMin}}-{{sec.guidelineMax}}%</td>
                                                        <td v-if="canbeEdited">
                                                          <div>
                                                            <button class="btn btn-success btn-sm btn-icon mr-1" @click.prevent="updateSector(sec.sectorId)"><i class="mdi mdi-lead-pencil"></i></button>
                                                            <button class="btn btn-danger btn-sm btn-icon" @click.prevent="deleteSector(sec.portfolioSectorId, sec.sectorId)" :disabled="canDeleteSector(sec.sectorId)"><i class="mdi mdi-delete"></i></button>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                    <tfoot v-if="portfolio.sectors.length > 0">
                                                      <tr>
                                                        <td>
                                                          <strong>Total</strong>
                                                        </td>
                                                        <td v-if="portfolio.clientTypeId == 1">
                                                          <strong class="text-primary">
                                                            {{symbol}}{{calcTotalSectorCommitment.toLocaleString()}}
                                                          </strong>
                                                        </td>
                                                        <td colspan="3">
                                                          <strong class="text-primary">
                                                            {{calcTotalSectorCommitmentPerc.toLocaleString()}}%
                                                          </strong>
                                                        </td>
                                                      </tr>
                                                    </tfoot>
                                                    <tfoot v-else>
                                                      <tr class="bg-warning text-white">
                                                        <td colspan="5">
                                                          No data to show, Please add target Strategies
                                                        </td>
                                                      </tr>
                                                    </tfoot>
                                                  </table>
                                                </div>

                                                <div class="form-group">
                                                    <label for="OprCode">Operation Code</label>
                                                    <input type="text" v-model.trim="portfolio.oprCode" placeholder="Enter Operation Code" class="form-control" id="OprCode" :disabled="!inEditMode" @blur="$v.portfolio.oprCode.$touch()" :class="{ 'parsley-error': $v.portfolio.oprCode.$error }">
                                                    <template v-if="$v.portfolio.oprCode.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.portfolio.oprCode.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                                                <div :class="{'collapse': !showLogs }">
                                                    <div class="form-group">
                                                        <label>Created On</label>
                                                        <AppDate :date="portfolio.createdOn"/>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Created By</label>
                                                        <AppUser :userId="portfolio.createdBy" />
                                                    </div>
                                                    <div class="form-group" v-if="portfolio.updatedOn">
                                                        <label>Modified On</label>
                                                        <AppDate :date="portfolio.updatedOn" :emptyIfNull="true"/>
                                                    </div>
                                                    <div class="form-group" v-if="portfolio.updatedOn">
                                                        <label>Modified By</label>
                                                        <AppUser :userId="portfolio.updatedBy" :emptyIfNull="true"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                              <ul class="nav nav-tabs tabs-bordered">
                                                  <li class="nav-item">
                                                      <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeLTab == 1}" @click="showLTab(1)">
                                                        Notes <span class="badge badge-primary noti-icon-badge" v-if="activities">{{activities.length}}</span></a>
                                                  </li>
                                                  <li class="nav-item">
                                                      <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeLTab == 2}" @click="showLTab(2)" v-if="portfolio.clientTypeId == 2">
                                                        Portfolio Management Team <span class="badge badge-primary noti-icon-badge" v-if="members.length > 0">{{members.length}}</span></a>
                                                  </li>
                                                  <li class="nav-item">
                                                      <a href="javascript:void(0)" class="nav-link" :class="{'active': activeLTab == 3}" @click="showLTab(3)">
                                                          Documents <span class="badge badge-primary noti-icon-badge" v-if="fileList">{{fileList.length}}</span>
                                                      </a>
                                                  </li>
                                              </ul>
                                              <div class="tab-content">
                                                  <div class="tab-pane fade" :class="{'active show': activeLTab == 1}">
                                                      <ActivityList :activities="activities" :isEditMode="inEditMode" @onUpdate="onActivityUpdate" @onClickNew="clickAddActivity" @onSelect="showActivity" @onSelectUpdate="editActivity" :isShowIndirects="false"/>
                                                  </div>
                                                  <div class="tab-pane fade" :class="{'show active': activeLTab == 2}">
                                                      <TeamList :members="members" :canEdit="canbeEdited || isSysAdmin || isICMember" :isEditMode="inEditMode" @onClickNew="clickAddMember" :portfolioId="id" @onSelect="showMember" />
                                                  </div>
                                                  <div class="tab-pane fade" :class="{'show active': activeLTab == 3}">
                                                    <span v-if="isLoadingFiles">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                    <FileList :files="fileList" :isEditMode="inEditMode" :canEdit="canbeEdited || isSysAdmin || isICMember"  @onUpdate="onFileUpdate" @onClickNew="clickAddFile" @onSelect="showFile" @onSelectUpdate="editFile" />
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" :class="{'active show': activeTab == 2}">
                                        <div class="row">
                                          <div v-if="vehicles.length == 0">No Slot to show. Please build the portfolio first.</div>
                                            <div class="col-md-12 col-xs-12" v-for="(group) in sectorGroupsSorted" :key="group[0]">
                                                <div class="card m-b-20 card-body">
                                                    <h4 class="card-title text-primary">{{group[0]}}</h4>
                                                    <table class="table table-bordered table-hover table-responsive">
                                                        <thead class="text-center" v-if="portfolio.clientTypeId == 1">
                                                          <tr>
                                                            <th rowspan="2">#</th>
                                                            <th rowspan="2" style="width: 95px;">
                                                              <button class="btn btn-primary waves-effect waves-light" type="submit" @click.prevent="addSlot(group[1][0].sectorName, group[1][0].portfolioSectorId)" v-if="canbeEdited">
                                                                  <i class="mdi mdi-plus-circle-outline"></i> Slot
                                                              </button>
                                                            </th>
                                                            <th width="300px" rowspan="2"> Deal</th>
                                                            <th width="80px" rowspan="2"> Type</th>
                                                            <th width="80px" rowspan="2"> Vintage</th>
                                                            <th width="125px" rowspan="2"> Geography</th>
                                                            <th colspan="2" class="actual-perc"> Actual Position</th>
                                                            <th colspan="2" class="total-perc"> Target Position</th>
                                                            <th width="300px" rowspan="2"> Lead</th>
                                                            <th width="300px" rowspan="2"> Second</th>
                                                            <th width="300px" rowspan="2"> Other</th>
                                                            
                                                          </tr>
                                                          <tr>
                                                            <th width="80px" class="actual-perc"> Amount (m)</th>
                                                            <th width="80px" class="actual-perc"> %</th>
                                                            <th width="80px" class="total-perc"> Amount (m)</th>
                                                            <th width="80px" class="total-perc"> %</th>
                                                          </tr>
                                                        </thead>
                                                        <thead class="text-center" v-else>
                                                          <tr>
                                                            <th rowspan="2">#</th>
                                                            <th rowspan="2" style="width: 95px;">
                                                              <button class="btn btn-primary waves-effect waves-light" type="submit" @click.prevent="addSlot(group[1][0].sectorName, group[1][0].portfolioSectorId)" v-if="canbeEdited">
                                                                  <i class="mdi mdi-plus-circle-outline"></i> Slot
                                                              </button>
                                                            </th>
                                                            <th width="300px" rowspan="2"> Deal</th>
                                                            <th width="80px" rowspan="2"> Type</th>
                                                            <th width="80px" rowspan="2"> Vintage</th>
                                                            <th width="125px" rowspan="2"> Geography</th>
                                                            <th width="80px" rowspan="2"> Actual Position</th>
                                                            <th width="80px" rowspan="2" v-if="!portfolio.totalCommitmentNa"> Target Position</th>
                                                            <th colspan="2" class="actual-perc"> Actual Position</th>
                                                            <th colspan="2" class="total-perc"> Target Position</th>
                                                            <th width="300px" rowspan="2"> Lead</th>
                                                            <th width="300px" rowspan="2"> Second</th>
                                                            <th width="300px" rowspan="2"> Other</th>
                                                            
                                                          </tr>
                                                          <tr>
                                                            <th width="80px" class="actual-perc"> Target Fund Size</th>
                                                            <th width="80px" class="actual-perc"> Amount Raised</th>
                                                            <th width="80px" class="total-perc"> Target Fund Size</th>
                                                            <th width="80px" class="total-perc"> Amount Raised</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody v-if="group[1][0].portfolioSlotId > 0">
                                                          <tr v-for="(item, index) in group[1]" :key="item.portfolioSlotId" :class="slotBgClass(item.investmentTypeId, item.vintageYear)">
                                                              <td v-tooltip="'System Slot #' + item.portfolioSlotId">                                                              
                                                                {{index + 1}} 
                                                              </td>
                                                              <td v-if="portfolio.clientTypeId == 2">
                                                                <div v-if="!item.fundName && canbeEdited">
                                                                  <!-- HERE! -->
                                                                  <button class="btn btn-success btn-sm btn-icon mr-1" v-if="!item.isActive" @click.prevent="updateSlot(item.portfolioSlotId)"><i class="mdi mdi-lead-pencil"></i></button>
                                                                  <button class="btn btn-danger btn-sm btn-icon" v-if="!item.isActive" @click.prevent="deleteSlot(item.portfolioSlotId)"><i class="mdi mdi-delete"></i></button>
                                                                </div>
                                                                <div v-if="item.isOpsDone" class="text-center">
                                                                  <!-- THIS SHOULD BE FIXED:HOVIK - UPDATE AFTER FILLING AND MUST GENERATE A NEW ALLOCATION <button class="btn btn-success btn-sm btn-icon" v-if="!item.isActive" @click.prevent="updateSlot(item.portfolioSlotId)"><i class="mdi mdi-lead-pencil"></i></button> -->
                                                                  <i class="mdi mdi-check-circle done-done" v-tooltip=" item.opsApprovalDate != null ? 'Approved <br/> Ops Date: ' + opsFormatDate(item.opsApprovalDate) : 'Approved'"></i>
                                                                  <button class="btn btn-danger btn-sm btn-icon" v-if="canReOpenSlot" @click.prevent="reopenSlot(item.portfolioSlotId)" ><i class="mdi mdi-undo"></i></button>
                                                                </div>
                                                              </td>
                                                              <td v-else>
                                                                <div v-if="!item.fundName && canbeEdited && !item.isOpsDone">
                                                                  <button class="btn btn-success btn-sm btn-icon mr-1" v-if="!item.isActive" @click.prevent="updateSlot(item.portfolioSlotId)"><i class="mdi mdi-lead-pencil"></i></button>
                                                                  <button class="btn btn-danger btn-sm btn-icon" v-if="!item.isActive" @click.prevent="deleteSlot(item.portfolioSlotId)"><i class="mdi mdi-delete"></i></button>
                                                                </div>
                                                                <div v-if="item.isOpsDone" class="text-center">
                                                                  <i class="mdi mdi-check-circle done-done" v-tooltip=" item.opsApprovalDate != null ? 'Approved <br/> Ops Date: ' + opsFormatDate(item.opsApprovalDate) : 'Approved'"></i>
                                                                </div>
                                                              </td>
                                                              <td>
                                                                  {{item.fundName}}
                                                                  <a v-tooltip="'Undo'" v-if="item.fundName && !item.isOpsDone && (isPortfolioAdmin || isAdminOps)" href="javascript:void(0)"><span @click.prevent="removeFund(0, item.portfolioSlotId)" class="hand badge badge-danger push-right"><i class="mdi mdi-undo"></i></span></a>
                                                                  <a v-tooltip="'Ops Approval'" v-if="item.fundName && item.filledPosition == 0 && portfolio.clientTypeId == 1 && (isPortfolioAdmin || isAdminOps)" href="javascript:void(0)"><span @click.prevent="approveAdvisoryOps(item.portfolioSlotId)" class="hand badge badge-success push-right"><i class="mdi mdi-seal"></i></span></a>
                                                                  <a v-tooltip="'Ops Approval'" v-if="item.fundName && item.filledPosition > 0 && portfolio.clientTypeId == 2 && isAdminOps && !item.isOpsDone" href="javascript:void(0)"><span @click.prevent="approveAdvisoryOps(item.portfolioSlotId)" class="hand badge badge-success push-right"><i class="mdi mdi-seal"></i></span></a>
                                                                <!-- <span v-if="item.isApproved && item.isOpsReady != null">
                                                                </span> -->
                                                              </td>
                                                              <td>
                                                                  {{showTypeName(item.investmentTypeId)}}
                                                              </td>
                                                              <td>
                                                                  {{item.vintageYear}}
                                                              </td>
                                                              <td>
                                                                  {{showGeoName(item.geographicId, item.geographyGlobal)}}
                                                              </td>
                                                              <td v-if="portfolio.clientTypeId == 2">
                                                                <span v-if="item.filledPosition > 0">
                                                                  {{symbol}}{{item.filledPosition.toLocaleString()}}
                                                                </span>
                                                              </td>
                                                              <td v-else class="actual-perc">
                                                                  <span v-if="item.filledPosition > 0">
                                                                    {{symbol}}{{item.filledPosition.toLocaleString()}}
                                                                  </span>
                                                              </td>
                                                              <td v-if="portfolio.clientTypeId == 2">
                                                                <span v-if="item.filledPosition == 0">
                                                                  {{symbol}}{{item.targetPosition.toLocaleString()}}
                                                                </span>
                                                              </td>
                                                              <td v-if="portfolio.clientTypeId == 1" class="actual-perc">
                                                                <span v-if="item.filledPosition > 0">
                                                                  {{item.advActualPerc}}%
                                                                </span>
                                                              </td>
                                                              <td v-if="portfolio.clientTypeId == 2" class="actual-perc">
                                                                <span v-if="item.filledPosition > 0">
                                                                  {{item.actualTargetPerc}}%
                                                                </span>
                                                              </td>
                                                              <td v-else class="total-perc">
                                                                <span v-if="item.filledPosition == 0">
                                                                  {{symbol}}{{item.targetPosition.toLocaleString()}}
                                                                </span>
                                                              </td>
                                                              <td v-if="portfolio.clientTypeId == 2" class="actual-perc">
                                                                <span v-if="item.filledPosition > 0">
                                                                  {{item.actualAmountPerc}}%
                                                                </span>
                                                              </td>
                                                              <td v-if="portfolio.clientTypeId == 1" class="total-perc">
                                                                <span v-if="item.filledPosition == 0">
                                                                  {{item.advTargetPerc}}%
                                                                </span>
                                                              </td>
                                                              <td v-else class="total-perc">
                                                                <span v-if="item.filledPosition == 0">
                                                                  {{item.totalTargetPerc}}%
                                                                </span>
                                                              </td>
                                                              <td v-if="portfolio.clientTypeId == 2" class="total-perc">
                                                                <span v-if="item.filledPosition == 0">
                                                                  {{item.totalAmountPerc}}%
                                                                </span>
                                                              </td>
                                                              <td>
                                                                <span v-if="!item.fundName">
                                                                  <a href="javascript:void(0)" @click.prevent="addFund(1, item.portfolioSlotId)" v-if="!item.fund1Name">
                                                                    <button class="btn btn-primary btn-sm btn-icon" v-tooltip="'Suggest Deal'"><i class="mdi mdi-bookmark-plus-outline"></i></button>
                                                                  </a>
                                                                  <span v-else-if="item.fund1Name">
                                                                    {{item.fund1Name}} <a href="javascript:void(0)"><span @click.prevent="removeFund(1, item.portfolioSlotId)" class="hand badge badge-danger push-right"><i class="mdi mdi-bookmark-remove"></i></span></a>
                                                                    <a v-if="isPortfolioAdmin && portfolio.clientTypeId == 1" href="javascript:void(0)"><span @click.prevent="approveAdvisory(item.portfolioSlotId, item.fund1Id, 1)" class="hand badge badge-success push-right"><i class="mdi mdi-bookmark-check"></i></span></a>
                                                                  </span>
                                                                </span>
                                                              </td>
                                                              <td>
                                                                <span v-if="!item.fundName">
                                                                  <a href="javascript:void(0)" @click.prevent="addFund(2, item.portfolioSlotId)" v-if="!item.fundName && !item.fund2Name">
                                                                    <button class="btn btn-primary btn-sm btn-icon" v-tooltip="'Suggest Deal'"><i class="mdi mdi-bookmark-plus-outline"></i></button>
                                                                  </a>
                                                                  <span v-else-if="item.fund2Name">
                                                                    {{item.fund2Name}} <a href="javascript:void(0)"><span @click.prevent="removeFund(2, item.portfolioSlotId)" class="hand badge badge-danger push-right"><i class="mdi mdi-bookmark-remove"></i></span></a>
                                                                    <a v-if="isPortfolioAdmin && portfolio.clientTypeId == 1" href="javascript:void(0)"><span @click.prevent="approveAdvisory(item.portfolioSlotId, item.fund2Id, 2)" class="hand badge badge-success push-right"><i class="mdi mdi-bookmark-check"></i></span></a>
                                                                  </span>
                                                                </span>
                                                              </td>
                                                              <td>
                                                                <span v-if="!item.fundName">
                                                                  <a href="javascript:void(0)" @click.prevent="addFund(3, item.portfolioSlotId)" v-if="!item.fundName && !item.fund3Name">
                                                                    <button class="btn btn-primary btn-sm btn-icon" v-tooltip="'Suggest Deal'"><i class="mdi mdi-bookmark-plus-outline"></i></button>
                                                                  </a>
                                                                  <span v-else-if="item.fund3Name">
                                                                    {{item.fund3Name}} <a href="javascript:void(0)"><span @click.prevent="removeFund(3, item.portfolioSlotId)" class="hand badge badge-danger push-right"><i class="mdi mdi-bookmark-remove"></i></span></a>
                                                                    <a v-if="isPortfolioAdmin && portfolio.clientTypeId == 1" href="javascript:void(0)"><span @click.prevent="approveAdvisory(item.portfolioSlotId, item.fund3Id, 3)" class="hand badge badge-success push-right"><i class="mdi mdi-bookmark-check"></i></span></a>
                                                                  </span>
                                                                </span>
                                                              </td>
                                                              
                                                          </tr>
                                                        </tbody>
                                                        <tfoot>
                                                          <tr class="font-weight-bold">
                                                            <td colspan="6">
                                                              <!-- Total {{group[1][0].sectorName}} -->
                                                              Total
                                                            </td>
                                                            <td v-if="portfolio.clientTypeId == 2">
                                                              {{symbol}}{{sectorFilledTotal(group[1][0].sectorId)}}
                                                            </td>
                                                            <td v-else class="actual-perc">
                                                              {{symbol}}{{sectorFilledTotal(group[1][0].sectorId)}}
                                                            </td>
                                                            <td v-if="portfolio.clientTypeId == 2">
                                                              {{symbol}}{{sectorTargetTotal(group[1][0].sectorId)}}
                                                            </td>
                                                            <td class="actual-perc">
                                                              <span v-if="portfolio.clientTypeId == 2">
                                                                <!-- {{sectorPercentTotal(group[0].sectorId)}}% class="targetValueClass(sectorPercentTotal(group[0].sectorId), group[0].targetSectorPercent)" -->
                                                                {{sectorActualAmountSum(group[1][0].sectorId)}}%
                                                              </span>
                                                              <span v-else>
                                                                {{sectorAdvFilledSum(group[1][0].sectorId)}}%
                                                              </span>
                                                            </td>
                                                            <!-- <td>
                                                              {{group[0].targetSectorPercent}}% <i class="mdi mdi-information-outline" v-tooltip="'Sector Target Position'"></i>
                                                              </td> -->
                                                            <td v-if="portfolio.clientTypeId == 2" class="actual-perc">
                                                              {{sectorActualTargetSum(group[1][0].sectorId)}}%
                                                            </td>
                                                            <td v-if="portfolio.clientTypeId == 2" class="total-perc">
                                                              {{sectorTargetTargetSum(group[1][0].sectorId)}}%
                                                            </td>
                                                            <td v-else class="total-perc">
                                                              {{symbol}}{{targetTotal}}
                                                            </td>
                                                            <td v-if="portfolio.clientTypeId == 2" class="total-perc">
                                                              {{sectorTargetAmountSum(group[1][0].sectorId)}}%
                                                            </td>
                                                            <td v-else class="total-perc">
                                                              {{sectorAdvTargetSum(group[1][0].sectorId)}}%
                                                            </td>
                                                            <td colspan="3">
                                                              {{group[1][0].targetSectorPercent}}% <i class="mdi mdi-information-outline" v-tooltip="'Sector Target'"></i>
                                                              <!-- {{symbol}}{{group[0].targetSectorPosition.toLocaleString()}} <i class="mdi mdi-information-outline" v-tooltip="'Sector Target Position Value'"></i> -->
                                                            </td>
                                                          </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>

                                            <div class="col-md-12 col-xs-12" v-if="vehicles.length > 0">
                                              <div class="card m-b-20 card-body">
                                                <table class="table table-bordered table-hover">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <h4 class="text-primary">
                                                          Total
                                                        </h4>
                                                      </td>
                                                      <td>
                                                        <div>
                                                          Actual Position: <strong class="text-primary">{{symbol}}{{filledTotal}}</strong> <i class="mdi mdi-information-outline" v-tooltip="'Only vehicles with active year'"></i>
                                                        </div>
                                                        <div>
                                                          Target Positions: <strong class="text-primary">{{symbol}}{{targetTotal}}</strong> <i class="mdi mdi-information-outline" v-tooltip="'Only vehicles with active year'"></i>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" :class="{'active show': activeTab == 3}">
                                      <div class="row" v-if="activeTab == 3">
                                        <div class="col-lg-12">
                                            <div class="portlet">
                                                <div class="portlet-heading bg-warning">
                                                    <h3 class="portlet-title">
                                                        Target
                                                    </h3>
                                                    <div class="portlet-widgets">
                                                        <a v-show="showTargetPalet" href="javascript:void(0)" @click="showTargetPalet = !showTargetPalet"><i class="ion-minus-round"></i></a>
                                                        <a v-show="!showTargetPalet" href="javascript:void(0)" @click="showTargetPalet = !showTargetPalet" class="collapsed"><i class="ion-minus-round"></i></a>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="panel-collapse collapse" :class="{'show' : showTargetPalet}">
                                                    <div class="portlet-body">
                                                      <div class="row">
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Investment Type
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataTargetInv" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Sector
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataTargetSec" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Vintage
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataTargetYer" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Geography
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataTargetGeo" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>

                                      <div class="row" v-if="activeTab == 3">
                                        <div class="col-lg-12">
                                            <div class="portlet">
                                                <div class="portlet-heading bg-success">
                                                    <h3 class="portlet-title">
                                                        Proforma
                                                    </h3>
                                                    <div class="portlet-widgets">
                                                        <a v-show="showProformaPalet" href="javascript:void(0)" @click="showProformaPalet = !showProformaPalet"><i class="ion-minus-round"></i></a>
                                                        <a v-show="!showProformaPalet" href="javascript:void(0)" @click="showProformaPalet = !showProformaPalet" class="collapsed" ><i class="ion-minus-round"></i></a>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="panel-collapse collapse" :class="{'show' : showProformaPalet}">
                                                    <div class="portlet-body">
                                                      <div class="row">
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Investment Type
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataProformaInv" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Sector
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataProformaSec" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Vintage
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataProformaYer" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Geography
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataProformaGeo" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>

                                      <div class="row" v-if="hasAnyFilled && activeTab == 3">
                                        <div class="col-lg-12">
                                            <div class="portlet">
                                                <div class="portlet-heading bg-info">
                                                    <h3 class="portlet-title">
                                                        Actual
                                                    </h3>
                                                    <div class="portlet-widgets">
                                                        <a v-show="showActualPalet" href="javascript:void(0)" @click="showActualPalet = !showActualPalet"><i class="ion-minus-round"></i></a>
                                                        <a v-show="!showActualPalet" href="javascript:void(0)" @click="showActualPalet = !showActualPalet" class="collapsed" ><i class="ion-minus-round"></i></a>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="panel-collapse collapse" :class="{'show' : showActualPalet}">
                                                    <div class="portlet-body">
                                                      <div class="row">
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Investment Type
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataActualInv" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Sector
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataActualSec" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Vintage
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataActualYer" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                          <div class="portlet">
                                                            <div class="portlet-heading">
                                                              <h3 class="portlet-title text-dark">
                                                                  Geography
                                                              </h3>
                                                              <div class="clearfix"></div>
                                                            </div>
                                                            <div class="portlet-body">
                                                              <pie-chart :chartData="pieDataActualGeo" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>

                                    </div>

                                     <div class="tab-pane fade" :class="{'active show': activeTab == 4}">
                                      <div class="row">
                                          <table class="table table-hover table-striped table-bordered">
                                            <thead>
                                              <tr>
                                                <th>Type</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr v-for="vote in portfolio.votes" :key="vote.portfolioVotingId">
                                                <td>
                                                  <router-link :to="{name: 'PortfolioVotingDetail', params: {id: vote.portfolioVotingId}}">
                                                    {{vote.typeName}}
                                                  </router-link>
                                                </td>
                                                <td>
                                                  <AppDate :date="vote.createdOn" :show="'label'"/>
                                                </td>
                                                <td>
                                                  {{vote.isFinalized ? 'Closed - ' : 'In Process'}}
                                                  <span v-if="vote.isFinalized" :class="{'text-danger' : !vote.isApproved, 'text-success' : vote.isApproved}">{{vote.isApproved ? 'Approved' : 'Declined'}}</span>
                                                </td>
                                              </tr>
                                            </tbody>
                                            <tfoot v-if="portfolio.votes && portfolio.votes.length == 0">
                                              <tr>
                                                <td colspan="4">
                                                  No Vote to show
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                      </div>
                                    </div>
                                     <div class="tab-pane fade" :class="{'active show': activeTab == 5}">
                                      <Quarters :isLoading="isLoadingPerformance" :inSaving="inSaving" :quarterTree="quarterTree" :minQ="minQ" :maxQ="maxQ" :enableAutofill=false :canAutofillQ="false" :jumpToArr="jumpToArr" :quarterSelection="quarterSelection" @quarterChanged="quarterSelected"/>
                                      <div class="form-group mt-2 mb-3 col-12">
                                        <table class="table table-striped table-hover">
                                          <thead>
                                            <tr>
                                              <th colspan="5">Portfolio Performance</th>
                                            </tr>
                                            <tr>
                                              <th>IRR pa (%)</th>
                                              <th>PIC</th>
                                              <th>DPI</th>
                                              <th>RVPI</th>
                                              <th>TVPI</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-if="isLoadingPerformance"><td colspan="5" class="text-center">Loading <i class="fa fa-spinner fa-spin"></i></td></tr>
                                            <tr v-if="performanceErrorMsg"><td colspan="5" class="text-center">{{ performanceErrorMsg }}</td></tr>
                                            <tr v-else class="hover-cells editable">
                                              <td><input v-model.number="pmData.irRpa" class="form-control-plaintext" type="text" :readonly="!inEditMode"></td>
                                              <td><input v-model.number="pmData.pic" class="form-control-plaintext" type="text" :readonly="!inEditMode"></td>
                                              <td><input v-model.number="pmData.dpi" class="form-control-plaintext" type="text" :readonly="!inEditMode"></td>
                                              <td><input v-model.number="pmData.rvpi" class="form-control-plaintext" type="text" :readonly="!inEditMode"></td>
                                              <td><input v-model.number="pmData.tvpi" class="form-control-plaintext" type="text" :readonly="!inEditMode"></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                        <div class="col">
                                          <table class="table table-hover table-striped table-bordered">
                                            <thead>
                                              <tr>
                                                <th>Deal Name</th>
                                                <th>Vintage</th>
                                                <th>Inv Type</th>
                                                <th>Geography</th>
                                                <th>Strategy</th>
                                                <th width="80px">IRR pa</th>
                                                <th width="80px">PIC</th>
                                                <th width="80px">DPI</th>
                                                <th width="80px">RVPI</th>
                                                <th width="80px">TVPI</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr v-if="isLoadingPerformance"><td colspan="10" class="text-center">Loading <i class="fa fa-spinner fa-spin"></i></td></tr>
                                              <tr v-else-if="!fmData.length"><td class="text-center" colspan="10">No data available</td></tr>
                                              <tr v-else v-for="x in fmData" :key="x.portfolioSlotId">
                                                <td>
                                                  <router-link :to="{ name: 'FundsDetail', params: { id: x.fundId }, query: { tab: 'monitoring' } }" target="_blank" >
                                                    {{ x.fundName }}
                                                  </router-link>
                                                </td>
                                                <td>
                                                  {{ x.vintageYear }}
                                                </td>
                                                <td>
                                                  {{showTypeName(x.investmentTypeId)}}
                                                </td>
                                                <td>
                                                  {{showGeoName(x.geographicId, x.geographyGlobal)}}
                                                </td>
                                                <td>
                                                  {{ x.sectorName }}
                                                </td>
                                                <td>
                                                  {{ calcIRRpa(x) }}
                                                </td>
                                                <td>
                                                  {{ calcPIC(x) }}
                                                </td>
                                                <td>
                                                  {{ calcDPI(x) }}
                                                </td>
                                                <td>
                                                  {{ calcRVPI(x) }}
                                                </td>
                                                <td>
                                                  {{ calcTVPI(x) }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                      </form>
                    </div>
                  <!-- end col -->
                </div>
              </div>
        </div>

        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <ActivityItem @onUpdate="onActivityUpdate" v-if="rightbarItem == 'activity'" :objectId=6 :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
              <PortfolioRiskCalc v-if="rightbarItem == 'portfolio-risk'" :portfolioId='portfolio.portfolioId' :cSymbol="symbol" @close='close'/>
              <PortfolioSector @onUpdate="onSectorUpdate" v-if="rightbarItem == 'portfolio-sec'" :isEditMode="sideInEditMode" :isAdd="addSidebar" :currency="symbol" :clientType="portfolio.clientTypeId" :usedSectors="usedSectors" :sectorOptions="sectorTree" :sectorLabels="sectorLabels" :totalCommitment="portfolio.clientTypeId == 2 ? commitmentValue : calcTotalYearCommitment" :lastSum="calcTotalSectorCommitmentPerc"/>
              <PortfolioSlot @onUpdate="onVehicleUpdate" v-if="rightbarItem == 'portfolio-def'" :isEditMode="sideInEditMode" :isAdd="addSidebar" :currency="symbol" :clientType="portfolio.clientTypeId" :sector="selectedSector" :totalCommitment="portfolio.clientTypeId == 2 ? commitmentValue : calcTotalYearCommitment" :totalCommitmentToday="portfolio.totalCommitmentToday" :portfolioSectorId="selectedPortfolioSectorId" :years="portfolio.years" :geos="portfolio.geos" :invs="portfolio.invs" :forceDefaultCheck="!forceDefaultValues" />
              <PortfolioYear @onUpdate="onYearUpdate" v-if="rightbarItem == 'portfolio-yer'" :isEditMode="sideInEditMode" :isAdd="addSidebar" :currency="symbol" :clientType="portfolio.clientTypeId" :totalCommitment="portfolio.clientTypeId == 2 ? commitmentValue : calcTotalYearCommitment" :lastYear="genLastYear" :lastSum="calcTotalYearCommitmentPerc"/>
              <PortfolioGeo @onUpdate="onGeoUpdate" v-if="rightbarItem == 'portfolio-geo'" :isEditMode="sideInEditMode" :isAdd="addSidebar" :currency="symbol" :clientType="portfolio.clientTypeId" :totalCommitment="portfolio.clientTypeId == 2 ? commitmentValue : calcTotalYearCommitment" :availableList="notUsedGeos" :lastSum="calcTotalGeoCommitmentPerc"/>
              <PortfolioInv @onUpdate="onInvUpdate" v-if="rightbarItem == 'portfolio-inv'" :isEditMode="sideInEditMode" :isAdd="addSidebar" :currency="symbol" :clientType="portfolio.clientTypeId" :totalCommitment="portfolio.clientTypeId == 2 ? commitmentValue : calcTotalYearCommitment" :availableList="notUsedInvs" :lastSum="calcTotalInvCommitmentPerc"/>
              <PortfolioAssign @onUpdate="onSlotUpdate" v-if="rightbarItem == 'portfolio-asg'" :isEditMode="sideInEditMode" :isAdd="addSidebar" :fundOrder="selectedSlotFundOrder"/>
              <PortfolioVotingStart @onUpdate="onVoteStartUpdate" v-if="rightbarItem == 'portfolio-start'" :portfolioId="portfolio.portfolioId" :actionType="portfolioActionType" />
              <TeamItem @onUpdate="onMemberUpdate" v-if="rightbarItem == 'portfolio-mem'" :isEditMode="sideInEditMode" :isAdd="addSidebar" :activeMembers="members" />
              <FileItem @onUpdate="onFileUpdate" v-if="rightbarItem == 'file'" :objectId="id" :objectTypeId="6" :isEditMode="sideInEditMode" :isAdd="addSidebar" :forPortfolio="true" />
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
import { required, minLength, maxLength, minValue, maxValue, decimal, requiredIf, helpers } from 'vuelidate/lib/validators'
import FileList from '@/components/FileListAll'
import FileItem from '@/components/FileItem'
import rightbar from '@/mixins/rightbar'
import { PortfolioService, PortfolioYearService, PortfolioMonitoringService } from '@/services/portfolio-service'
import { FundMonitoringService } from '@/services/fund-service'
import { FileService } from '@/services/file-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { ActivityService } from '@/services/activity-service'
import PortfolioRiskCalc from '@/components/portfolio/PortfolioRiskCalc'
import ActivityList from '@/components/ActivityList'
import ActivityItem from '@/components/ActivityItem'
import PortfolioSlot from '@/components/portfolio/PortfolioSlot'
import PortfolioAssign from '@/components/portfolio/PortfolioAssign'
import PortfolioSector from '@/components/portfolio/PortfolioSector'
import PortfolioYear from '@/components/portfolio/PortfolioYear'
import PortfolioGeo from '@/components/portfolio/PortfolioGeo'
import PortfolioInv from '@/components/portfolio/PortfolioInv'
import PortfolioVotingStart from '@/components/portfolio/PortfolioVotingStart'
import TeamList from '@/components/portfolio/TeamList'
import TeamItem from '@/components/portfolio/TeamItem'
import PieChart from '@/components/charts/pieChart'
import Toolbar from '@/components/portfolio/PortfolioToolbar'
import { UserpinService } from '@/services/userpin-service'
import moment from 'moment'
import Quarters from '@/components/custom/Quarters'
import quarterHandler from '@/mixins/quarterConfig'


const validateIf2 = (prop, validator) =>
  helpers.withParams({ type: 'validateIf2', prop }, function (value, parentVm) {
    const v = helpers.ref(prop, this, parentVm)
    // const m = this.portfolio.totalCommitmentNa
    const t = this.portfolio.totalCommitmentToday
    return parseInt(v) === 2 && t === 0 ? validator(value) : true
  })
const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

export default {
  components: {
    PortfolioRiskCalc,
    DatePicker,
    Multiselect,
    PieChart,
    ActivityList,
    ActivityItem,
    PortfolioSlot,
    PortfolioAssign,
    PortfolioSector,
    PortfolioYear,
    PortfolioGeo,
    PortfolioInv,
    PortfolioVotingStart,
    Toolbar,
    TeamList,
    TeamItem,
    FileList,
    FileItem,
    Quarters
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  mixins: [rightbar, asyncDataStatus, quarterHandler],
  data () {
    return {
      fmData: [], // funds monitoring data
      pmData: {}, //portfolio monitoring data
      pmDataUpdated: false,
      unwatchPM: null,
      performanceErrorMsg: null,
      activeTab: 1,
      activeLTab: 1,
      showLogs: false,
      inEditMode: false,
      isEditMode: null,
      vehicles: [],
      sideInEditMode: false,
      inSaving: false,
      portfolio: {name: '', sectors: [], years: [], invs: [], geos: []},
      portfolioChart: null,
      currencyObj: null,
      activities: null,
      members: [],
      fileList: null,
      rightbarItem: null,
      addSidebar: null,
      sectorTree: null,
      sectorLabels: null,
      selectedSlotFundOrder: null,
      selectedSector: null,
      selectedPortfolioSectorId: null,
      showTargetPalet: true,
      showActualPalet: true,
      showProformaPalet: true,
      forceDefaultValues: false,
      portfolioActionType: 0,
      isLoadingFiles: false,
      isLoadingPerformance: false,
    }
  },
  validations: {
    portfolio: {
      portfolioName: { required, min: minLength(2), max: maxLength(200) },
      clientTypeId: { required, min: minValue(1), max: maxValue(2) },
      currencyId: { required },
      totalCommitment: { required: requiredIf(function () { return !this.portfolio.totalCommitmentNa && this.portfolio.totalCommitmentToday > 0 }), decimal, min: validateIf2('clientTypeId', minValue(1)), max: maxValue(1000000) },
      totalCommitmentToday: { required: requiredIf(function () { return !this.portfolio.totalCommitmentTodayNa }), decimal, min: minValue(0), max: maxValue(1000000) },
      maxRecommendedCommitmentPercent: { decimal },
      maxCommitmentPercent: { decimal },
      firstCloseDate: { required: requiredIf(function () { return this.portfolio.clientTypeId === 2 }) },
      finalCloseDate: { required: requiredIf(function () { return this.portfolio.clientTypeId === 2 && !this.portfolio.finalCloseDateNa }) },
      guidelines: { max: maxLength(500) },
      objective: { required: requiredIf(function () { return this.portfolio.clientTypeId === 2 }), max: maxLength(1000) },
      oprCode: { max: maxLength(10) }
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers', 'fetchActivity', 'fetchAllCurrencies', 'fetchAllSectors', 'fetchAllGeoFocus', 'fetchAllFundtypes',
      'fetchPortfolioSector', 'fetchAllTreeStrategies', 'deletePortfolioSector', 'deletePortfolioSlot','reopenPortfolioSlot', 'fetchPortfolioSlot', 'fetchPortfolioVehicle', 'fetchPortfolioVehicleNoDefault',
      'deletePortfolioYear', 'fetchPortfolioYear', 'fetchPortfolioGeo', 'deletePortfolioGeo', 'fetchPortfolioInv', 'deletePortfolioInv',
      'portfolioVehicleRemove', 'portfolioVehicleApproveAdvisory', 'fetchFile']),
    close () {
      this.hideRightbar()
    },
    calcTVPI (fund) {
      if (!fund.fundMonitoringId) return null
      else return fund.ratioAndPerformanceFundTVPI
    },
    calcRVPI (fund) {
      if (!fund.fundMonitoringId) return null
      else return fund.ratioAndPerformanceFundRVPI
    },
    calcDPI (fund) {
      if (!fund.fundMonitoringId) return null
      else return fund.ratioAndPerformanceFundDPI
    },
    calcPIC (fund) {
      if (!fund.fundMonitoringId) return null
      else return fund.ratioAndPerformanceFundPIC
    },
    calcIRRpa (fund) {
      if (!fund.fundMonitoringId) return null
      else return fund.ratioAndPerformanceFundIRRpa + '%'
    },
    createSectorTree () {
    let groups = [{
      id: '1A',
      label: 'Private Markets',
      children: []
    },
    {
      id: '2A',
      label: 'Marketable Alternatives',
      children: []

    }]
    const sectorLabels =  {}
    this.allTreeStrategies.forEach(obj => {
      groups[obj.groupId - 1].children = [...groups[obj.groupId - 1].children, {id: `B${obj.strategyId}`, label: obj.strategyName.replace(/\r\n/ig, ''), children: []}]
    })
    groups.forEach(group => {
      group.children.forEach(strategy => {
        this.allSectors.forEach(item => {
          item.strategyId === parseInt(strategy.id.slice(1)) ? strategy.children = [...strategy.children, {id: `C${item.sectorId}`, label: item.sectorName.replace(/\r\n/ig, ''), children: []}] : strategy.children = strategy.children
        })
      })
    })
    groups.forEach(group => {
      group.children.forEach(strategy => {
        /* TODO: check why sort is sorting by length */
        strategy.children = strategy.children.sort((a, b) => b.children.length - a.children.length).map(sector => {
          if (sector.children.length === 0) {
              const customLabel = `${strategy.label} > ${sector.label}`
              sectorLabels[sector.id] = customLabel
            return { id: sector.id, label: sector.label, customLabel }
          } else {
            return sector
          }
        })
      })
    })
    groups.forEach(group => {
      group.children = group.children.sort((a, b) => b.children.length - a.children.length).map(strategy => {
        if (strategy.children.length === 1) {
          const customLabel = `${group.label} > ${strategy.label}`
          sectorLabels[strategy.children[0].id] = customLabel
          return { id: strategy.children[0].id, label: strategy.label, customLabel }
        } else {
          return strategy
        }
      })
    })
    this.sectorTree = groups
    this.sectorLabels = sectorLabels
    },
    showTab (tabId) {
      this.activeTab = tabId
    },
    showLTab (tabId) {
      this.activeLTab = tabId
    },
    sidebarAction (item, isAdd, isOpen, isEdit) {
      this.addSidebar = isAdd
      this.sideInEditMode = isEdit || isAdd
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    deleteForm () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                PortfolioService.delete(this.portfolio.portfolioId)
                .then((response) => {
                  this.$snotify.success('Portfolio Deleted.')
                  setTimeout(() => {
                    this.$router.push({name: 'PortfolioList'})
                  }, 500)
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    onRiskCalculator () {
      this.sidebarAction('portfolio-risk', true, true, true)
    },
    startVote () {
      this.portfolioActionType = 1
      this.sidebarAction('portfolio-start', true, true, true)
    },
    startCloseVote () {
      this.portfolioActionType = 2
      this.sidebarAction('portfolio-start', true, true, true)
    },
    async saveForm () {
      if (this.pmDataUpdated) {
          const performanceSaveResponse = await this.savePerformance()
          if (performanceSaveResponse === 'error') return
      }
      this.$v.portfolio.$touch()
      if (!this.$v.portfolio.$error) {
        this.inSaving = true
        if (this.portfolio.totalCommitmentNa) {
          this.portfolio.totalCommitment = 0
        }
        if (this.portfolio.totalCommitmentTodayNa) {
          this.portfolio.totalCommitmentToday = 0
        }
        // Set dates to ISO
        if (this.portfolio.firstCloseDate != null) {
          this.portfolio.firstCloseDate = new Date(this.portfolio.firstCloseDate).toISOString()
        }
        if (this.portfolio.finalCloseDate != null) {
          this.portfolio.finalCloseDate = new Date(this.portfolio.finalCloseDate).toISOString()
        }
        if (this.portfolio.invEndDate != null) {
          this.portfolio.invEndDate = new Date(this.portfolio.invEndDate).toISOString()
        }

        PortfolioService.save(this.portfolio.portfolioId, this.portfolio).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.inEditMode = false
              this.$snotify.success('Changes saved.')
            }
            this.inSaving = false
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    addPin () {
      const pin = {objectTypeId: 6, objectId: this.id}
      UserpinService.add(pin)
      .then(
        (response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error!')
          } else {
            this.portfolio.pinId = response.data.pinId
            this.$snotify.success('Pinned!')
          }
        })
        .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    delPin () {
      UserpinService.delete(this.portfolio.pinId)
      .then(
        (response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error!')
          } else {
            this.portfolio.pinId = 0
            this.$snotify.success('Unpinned!')
          }
        })
        .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    EditForm () {
      this.inEditMode = true
      this.addSidebar = false
    },
    cancelForm () {
      if (!this.$v.portfolio.$anyDirty) {
        this.cancelFormOper()
        return
      }

      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to cancel?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.cancelFormOper()
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    cancelFormOper () {
      this.inEditMode = false
      this.getDetails()
      if (this.pmDataUpdated) {
        this.fetchPerformance()
      }
      if (this.addSidebar) {
        this.hideRightbar()
        this.addSidebar = false
      }
      this.$v.portfolio.$reset()
    },
    getTables () {
      this.fetchAllUsers()
      this.fetchAllCurrencies()
      this.fetchAllSectors()
      this.fetchAllGeoFocus()
      this.fetchAllFundtypes()
      this.fetchAllTreeStrategies()
    },
    getDetails () {
      return new Promise ((resolve) => {

        PortfolioService.get(this.id)
        .then((response) => {
          this.portfolio = response.data
          const sortFunction = function (a,b) {
            return this.sectorLabels[`C${a.sectorId}`].localeCompare(this.sectorLabels[`C${b.sectorId}`])
          }
          this.portfolio.sectors.sort(sortFunction.bind(this))
          this.vehicles = this.portfolio.vehicles
          this.members = this.portfolio.members
          this.currencyObj = this.allCurrencies.filter(t => t.currencyId === this.portfolio.currencyId)
        })
        .then(() => {
          this.calcVehicleData()
        })
        .then(() => {
          this.fetchActivities()
        })
        .then(() => {
          this.loadChart()
        })
        .then(() => {
          this.fetchFileList()
        })
        .then(() => {
          this.asyncDataStatus_fetched('Portfolio Details')
          resolve()
        })
      })
    },
    // START PERFORMANCE METHODS
    async openPerformanceTab() {
      this.showTab(5)
      // only fetch performance data if this is the first time clicking performance tab
      if (Object.keys(this.quarterReqObj).length > 0) {
        return
      }
      else {
        await this.fetchPerformance()
      }
    },
    quarterSelected (value) {
      // If selection has not changed return
      this.quarterSelection = value 
      if (this.quarterSelection === this.currentQuarter) return
      // If portfolio performance changed, ask user to save quarter, else fetch new quarter
      if (this.pmDataUpdated) {
        // y and q will be from the quarter that needs to be saved, since quarterReqObj is not updated with selection until fetchMonitoring() is called
        const {year: y, quarter: q} = this.quarterReqObj
        this.$snotify.confirm(`Would you like to save changes for ${y} Q${q}?`, '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.$snotify.remove(toast.id)
                this.savePerformance(true)
              }
            },
            {
              text: 'No',
              action: (toast) => {
                this.$snotify.remove(toast.id),
                this.fetchPerformance()
              },
              bold: true
            }
          ]})
      } else this.fetchPerformance()
    },
    async fetchPerformance () {
      // 1. Unwatch Portfolio Monitoring Object (pmData)
      this.cancelPerformanceWatcher()
    // 2. set loading true, set data objs, etc. to original values
      this.isLoadingPerformance = true
      this.fmData = []
      this.pmData = {}
      this.pmDataUpdated = false
      this.performanceErrorMsg = null
      // 3. Set quarterReqObj
      const q = this.quarterSelection
      this.quarterReqObj = {
        portfolioId: this.id,
        quarter: +this.quarterSelection.slice(-1),
        year: +this.quarterSelection.slice(0, -1)
      } // quarterReqObjPrevQ and currentQuarter are computed
      try {
        // 5. if data does not exist in loadedQ fetch from API & store in loadedQ
        if (!this.loadedQuarters.hasOwnProperty(q)) {
          // A. fetch Data
          const [resFunds, resPortfolio] = await Promise.all([FundMonitoringService.getByPortfolio(this.quarterReqObj), PortfolioMonitoringService.get(this.quarterReqObj)])
          if ((!resFunds || resFunds.status !== 200) || (!resPortfolio || resPortfolio.status !== 200)) throw new Error('Could not load performance data')
          const { data: fundData } = resFunds
          const portfolioData = Object.keys(resPortfolio.data).length ? resPortfolio.data[0] : JSON.parse(JSON.stringify(this.quarterReqObj))
          // B. updated loadedQ obj
          this.$set(this.loadedQuarters, q, {funds: fundData, portfolio: portfolioData})
        }

        // 6. Now that data is in loadedQ oj, pull current quarter data from loadedQ obj
        this.pmData = JSON.parse(JSON.stringify(this.loadedQuarters[q].portfolio))// copying loadedQuarters so pmData obj can be updated without mutating loadedObj. gives ability to reset pmdData when clicking cancel
        this.fmData = this.loadedQuarters[q].funds// this data is readonly; no need to worry about mutating
        // 7. Set Watcher on Portfolio Data (if pmData changes, quarterSelected() method will notice and prompt user to save changes before next fetch)
        this.setPerformanceWatcher()
        // this.$snotify.success(`${this.quarterReqObj.year} Q${this.quarterReqObj.quarter} Loaded`)
        return 'success'
      } catch (error) {
        console.error(error)
        this.performanceErrorMsg = "Error Loading - Please Refresh Page"
        this.$snotify.error(`Error Loading ${this.quarterReqObj.year} Q${this.quarterReqObj.quarter}`)
      } finally {
        // 8. loading false
        this.isLoadingPerformance = false
      }
      return
    },
    async savePerformance (createMsg = false) {//arg tells function to display success msg, when called by saveForm, saveForm will handle the msg
      const monId = this.pmData.portfolioMonitoringId
      this.inSaving = true
      
      const payload = this.emptyStrToZero(this.pmData) //replace '' with 0 (handling blank fields)

      try {
        const response = monId ? await PortfolioMonitoringService.save(monId, payload) : await PortfolioMonitoringService.add(payload)
        if (response.status !== 200) throw new Error('Error saving monitoring data')
        this.inEditMode = false
        
        // saving copy of current pmData to loadedQuarters object
        const {quarter, year} = payload
        const q = `${year}${quarter}`
        this.loadedQuarters[q].portfolio = JSON.parse(JSON.stringify(payload))
        if(createMsg) {
          this.$snotify.success(`${this.pmData.year} Q${this.pmData.quarter} Saved`)
        }
        // will fetch new quarter, or current updated quarter
        await this.fetchPerformance()
      }
      catch (error) {
        console.error(error)
        this.$snotify.error(`Error Saving\n${this.pmData.year} Q${this.pmData.quarter}`, {timeout: 6000} )
        // set quarterSelection back to current quarter, since save failed
        this.quarterSelection = this.currentQuarter
        return 'error'
      } finally {
        this.inSaving = false
      }
    },
    emptyStrToZero (obj) {
      // any property set to '' will be converted to 0
      const arr = Object.entries(obj) 
      arr.forEach(item => item[1] = item[1] === '' ? 0 : item[1])
      return (Object.fromEntries(arr))
    },
    setPerformanceWatcher () {
       // set a watcher on fundMonitoring obj. if user inputs data, fmUpdated is true which prompts save button to save Fund monitoring
        if (Object.keys(this.pmData).length >= 3) {
          this.unwatchPM = this.$watch('pmData', function(newValue) {
            this.pmDataUpdated = true
            // watcher is removed onces fmUpdated is set to true, for performance purposes
            this.unwatchPM()
          }, { deep: true }); 
        }
    },
    cancelPerformanceWatcher () {
      if (this.unwatchPM !== null) {
        this.unwatchPM()
      }
    },
    // END PERFORMANCE METHODS
    calcVehicleData () {
      const totalCommitment = this.commitmentValue
      const totalCommitmentToday = this.portfolio.totalCommitmentToday
      if (this.portfolio.clientTypeId === 1) {
        // const currentYear = new Date().getFullYear()
        this.vehicles.forEach(v => {
          const totalFilled = this.sectorFilledTotalNumber(v.sectorId)
          const totalTarget = this.sectorTargetTotalNumber(v.sectorId)
          if (totalCommitment > 0) {
            if (totalFilled > 0) {
              v.advActualPerc = parseInt(((v.filledPosition) / totalFilled) * 10000) / 100
            } else {
              v.advActualPerc = 0 // prevent dev by zero
            }
            if (totalTarget > 0) {
              v.advTargetPerc = v.filledPosition > 0 ? 0 : parseInt(((v.targetPosition) / totalTarget) * 10000) / 100
            } else {
              v.advTargetPerc = 0 // prevent dev by zero
            }
          } else {
            v.advActualPerc = 0
            v.advTargetPerc = 0
          }
        })
      } else {
        this.vehicles.forEach(v => {
          if (totalCommitment > 0) {
            v.actualTargetPerc = parseInt(((v.filledPosition) / totalCommitment) * 10000) / 100
            v.totalTargetPerc = parseInt(((v.targetPosition) / totalCommitment) * 10000) / 100
            v.totalFilledPerc = parseInt(((v.filledPosition) / totalCommitment) * 10000) / 100
          } else {
            v.actualTargetPerc = 0
            v.totalTargetPerc = 0
          }
          if (totalCommitmentToday > 0) {
            v.actualAmountPerc = parseInt(((v.filledPosition) / totalCommitmentToday) * 10000) / 100
            v.totalAmountPerc = parseInt(((v.targetPosition) / totalCommitmentToday) * 10000) / 100
          } else {
            v.actualAmountPerc = 0
            v.totalAmountPerc = 0
          }
        })
      }
    },
    loadChart () {
      PortfolioService.getChart(this.id)
      .then((response) => {
        this.portfolioChart = response.data
      })
    },
    fetchActivities () {
      ActivityService.getPortfolioActivities(this.id).then(
        (response) => {
          if (response.data.length > 0) {
            this.activities = response.data
          } else {
            this.activities = null
          }
        }
      )
    },
    fetchYears () {
      PortfolioYearService.getByPortolio(this.id).then(
        (response) => {
          if (response.data.length > 0) {
            this.portfolio.years = response.data
          } else {
            this.portfolio.years = null
          }
        }
      )
    },
    fetchFileList () {
      this.isLoadingFiles = true
      FileService.getPortfolioFiles(this.id).then(
        (response) => {
          this.isLoadingFiles = false
          if (response.data.length > 0) {
            this.fileList = response.data
          } else {
            this.fileList = null
          }
        }
      )
    },
    showActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true)
    },
    editActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true, true)
    },
    clickAddActivity () {
      this.$store.commit('setNewActivity', this.id)
      this.sidebarAction('activity', true, true, true)
    },
    onActivityUpdate () {
      this.fetchActivities()
      this.sidebarAction('', false, false)
    },
    currencySelected (value, id) {
      this.portfolio.currencyId = value ? value.currencyId : null
    },
    initialValueChecks () {
      let hasError = false
      if (this.portfolio.years.length === 0) {
        this.$snotify.warning('Please add Vintage Years first!')
        hasError = true
      }
      if (this.portfolio.clientTypeId === 2 && this.commitmentValue === 0) {
        this.$snotify.warning('Total Commitment is required for a Discretionary portfolio!')
        hasError = true
      }
      return hasError
    },
    addSector () {
      const anyError = this.initialValueChecks()
      if (anyError) {
        return
      }
      this.$store.commit('setNewPortfolioSector', this.id)
      this.sidebarAction('portfolio-sec', true, true, true)
    },
    updateSector (sectorId) {
      this.fetchPortfolioSector({portfolioId: this.id, sectorId})
      this.sidebarAction('portfolio-sec', false, true, true)
    },
    deleteSector (psId, sId) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete the Sector?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deletePortfolioSector({portfolioSectorId: psId})
                .then((response) => {
                  const i = this.vehicles.findIndex(x => x.portfolioSectorId === psId)
                  if (i >= 0) {
                    this.vehicles.splice(i, 1)
                  }
                  const i2 = this.portfolio.sectors.findIndex(x => x.sectorId === sId)
                  if (i2 >= 0) {
                    this.portfolio.sectors.splice(i2, 1)
                  }
                  this.$snotify.success('Sector Deleted.')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    onSectorUpdate (sector, isNew) {
      this.sidebarAction('', false, false)
      this.getDetails()
    },
    addYear () {
      let ly = 0
      if (this.portfolio.years.length > 0) {
        ly = Math.max(...this.portfolio.years.map(x => x.vintageYear)) + 1
      }
      this.$store.commit('setNewPortfolioYear', {id: this.id, year: ly})
      this.sidebarAction('portfolio-yer', true, true, true)
    },
    updateYear (yr) {
      this.fetchPortfolioYear({portfolioId: this.id, year: yr})
      this.sidebarAction('portfolio-yer', false, true, true)
    },
    deleteYear (yr) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete the Year?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deletePortfolioYear({portfolioId: this.id, year: yr})
                .then((response) => {
                  const i = this.portfolio.years.findIndex(x => x.vintageYear === yr)
                  if (i >= 0) {
                    this.portfolio.years.splice(i, 1)
                  }
                })
                this.$snotify.remove(toast.id)
                this.$snotify.success('Commitment Year Deleted.')
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    onYearUpdate (yr, isNew) {
      this.sidebarAction('', false, false)
      this.getDetails()
    },
    addGeo () {
      const anyError = this.initialValueChecks()
      if (anyError) {
        return
      }
      this.$store.commit('setNewPortfolioGeo', {id: this.id, geo: 0})
      this.sidebarAction('portfolio-geo', true, true, true)
    },
    updateGeo (geoId) {
      this.fetchPortfolioGeo({portfolioId: this.id, geo: geoId})
      this.sidebarAction('portfolio-geo', false, true, true)
    },
    deleteGeo (geoId) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete the Geography?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deletePortfolioGeo({portfolioId: this.id, geo: geoId})
                .then((response) => {
                  const i = this.portfolio.geos.findIndex(x => x.geographicId === geoId)
                  if (i >= 0) {
                    this.portfolio.geos.splice(i, 1)
                  }
                })
                this.$snotify.remove(toast.id)
                this.$snotify.success('Geography Deleted.')
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    onGeoUpdate (geo, isNew) {
      this.sidebarAction('', false, false)
      this.getDetails()
    },
    addInv () {
      const anyError = this.initialValueChecks()
      if (anyError) {
        return
      }
      this.$store.commit('setNewPortfolioInv', {id: this.id, invTypeId: 0})
      this.sidebarAction('portfolio-inv', true, true, true)
    },
    updateInv (invId) {
      this.fetchPortfolioInv({portfolioId: this.id, invTypeId: invId})
      this.sidebarAction('portfolio-inv', false, true, true)
    },
    deleteInv (invId) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete the Investmnet Type?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deletePortfolioInv({portfolioId: this.id, invTypeId: invId})
                .then((response) => {
                  const i = this.portfolio.invs.findIndex(x => x.invTypeId === invId)
                  if (i >= 0) {
                    this.portfolio.invs.splice(i, 1)
                  }
                })
                this.$snotify.remove(toast.id)
                this.$snotify.success('Investment Deleted.')
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    onInvUpdate () {
      this.sidebarAction('', false, false)
      this.getDetails()
    },
    addSlot (sector, id) {
      const s = this.allSectors.filter(x => x.sectorName === sector)[0]
      if (s != null) {
        this.selectedSector = s
        this.selectedPortfolioSectorId = id
        this.forceDefaultValues = this.vehicles.filter(x => x.portfolioSectorId === this.selectedPortfolioSectorId).map(x => x.targetPosition).filter((v, i, a) => a.indexOf(v) === i).length === 1
        if (this.forceDefaultValues) {
          this.$store.commit('setNewPortfolioSlot', id)
        } else {
          this.$store.commit('setNewPortfolioSlotNoDefault', id)
        }
        this.sidebarAction('portfolio-def', true, true, true)
      } else {
        //console.log('cant find Sector - clear cache will possibly help.')
      }
    },
    updateSlot (id) {
      const slotIndex = this.vehicles.findIndex(s => s.portfolioSlotId === id)
      if (slotIndex >= 0) {
        const slot = this.vehicles[slotIndex]
        const sector = this.allSectors.filter(x => x.sectorId === slot.sectorId)[0]
        this.selectedPortfolioSectorId = slot.portfolioSectorId
        this.selectedSector = sector
        this.forceDefaultValues = this.vehicles.filter(x => x.portfolioSectorId === this.selectedPortfolioSectorId).map(x => x.targetPosition).filter((v, i, a) => a.indexOf(v) === i).length === 1
        if (this.forceDefaultValues) {
          this.fetchPortfolioVehicle({portfolioSlotId: id})
        } else {
          this.fetchPortfolioVehicleNoDefault({portfolioSlotId: id})
        }
        this.sidebarAction('portfolio-def', false, true, true)
      }
    },
    reopenSlot (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to reopen this Slot?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.reopenPortfolioSlot({portfolioSlotId: id})
                .then((response) => {
                  this.$snotify.success('Slot Reopened.')
                  this.onVehicleUpdate()
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    deleteSlot (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete the Slot?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deletePortfolioSlot({portfolioSlotId: id})
                .then((response) => {
                  this.$snotify.success('Slot Deleted.')
                  this.onVehicleUpdate()
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    async onVehicleUpdate () {
      await this.getDetails()
      this.sidebarAction('', false, false)
    },
    addFund (order, slotId) {
      this.fetchPortfolioVehicle({portfolioSlotId: slotId})
      this.selectedSlotFundOrder = order
      this.sidebarAction('portfolio-asg', true, true, true)
    },
    approveAdvisoryOps (slotId) {
      this.fetchPortfolioVehicle({portfolioSlotId: slotId})
      this.selectedSlotFundOrder = 0
      this.sidebarAction('portfolio-asg', true, true, true)
    },
    removeFund (order, slotId) {
      const msg = order > 0 ? 'Are you sure you want to remove the tag?' : 'Are you sure you want to remove the Deal Suggestion?'
      this.$snotify.clear()
      this.$snotify.confirm(msg, '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.portfolioVehicleRemove({slotId, order})
                .then((response) => {
                  const i = this.vehicles.findIndex(x => x.portfolioSlotId === slotId)
                  if (i >= 0) {
                    switch (order) {
                      case 0:
                        this.vehicles[i].fundName = null
                        this.vehicles[i].fundId = null
                        break
                      case 1:
                        this.vehicles[i].fund1Name = null
                        this.vehicles[i].fund1Id = null
                        break
                      case 2:
                        this.vehicles[i].fund2Name = null
                        this.vehicles[i].fund2Id = null
                        break
                      case 3:
                        this.vehicles[i].fund3Name = null
                        this.vehicles[i].fund3Id = null
                        break
                    }
                  }
                  this.$snotify.success('Tag removed.')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    approveAdvisory (slotId, fundId, order) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to confirm the Deal for Slot?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                const apr = { 'portfolioSlotId': slotId, 'fundId': fundId, 'fundOrder': order }
                this.portfolioVehicleApproveAdvisory({slotId, 'approve': apr})
                .then((response) => {
                  const i = this.vehicles.findIndex(x => x.portfolioSlotId === slotId)
                  if (i >= 0) {
                    switch (order) {
                      case 1:
                        this.vehicles[i].fundName = this.vehicles[i].fund1Name
                        break
                      case 2:
                        this.vehicles[i].fundName = this.vehicles[i].fund2Name
                        break
                      case 3:
                        this.vehicles[i].fundName = this.vehicles[i].fund3Name
                        break
                    }
                    this.vehicles[i].fundId = fundId
                  }
                  this.$snotify.success('Done')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    onVoteStartUpdate () {
      this.getDetails()
      this.hideRightbar()
    },
    onSlotUpdate (slot) {
      PortfolioService.getVehicles(this.id)
      .then((response) => {
        this.vehicles = response.data
      })
      .then(() => {
        this.calcVehicleData()
      })
      .then(() => {
        this.loadChart()
        this.sidebarAction('', false, false)
      })
    },
    sectorFilledTotal (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId)
        .forEach(k => {
          if (this.checkActiveYear(k.vintageYear)) {
            sum += k.filledPosition
          }
        })
      return sum.toLocaleString()
    },
    sectorFilledTotalNumber (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId)
        .forEach(k => {
          sum += k.filledPosition
        })
      return sum
    },
    sectorTargetTotal (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId)
        .forEach(k => {
          if (this.checkActiveYear(k.vintageYear) && k.fundId == null) {
            sum += k.targetPosition
          }
        })
      return sum.toLocaleString()
    },
    sectorTargetTotalNumber (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId)
        .forEach(k => {
          if (this.checkActiveYear(k.vintageYear) && k.fundId == null) {
            sum += k.targetPosition
          }
        })
      return sum
    },
    sectorPercentTotal (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId)
        .forEach(k => {
          if (this.checkActiveYear(k.vintageYear)) {
            sum += k.targetPositionPerc
          }
        })
      return sum.toLocaleString()
    },
    sectorActualTargetSum (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId)
        .forEach(k => {
          if (this.checkActiveYear(k.vintageYear)) {
            sum += k.actualAmountPerc
          }
        })
      return sum.toLocaleString()
    },
    sectorActualAmountSum (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId)
        .forEach(k => {
          if (this.checkActiveYear(k.vintageYear)) {
            sum += k.actualTargetPerc
          }
        })
      return sum.toLocaleString()
    },
    sectorTargetTargetSum (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId && x.filledPosition === 0)
        .forEach(k => {
          if (this.checkActiveYear(k.vintageYear)) {
            sum += k.totalTargetPerc
          }
        })
      return sum.toLocaleString()
    },
    sectorTargetAmountSum (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId && x.filledPosition === 0)
        .forEach(k => {
          if (this.checkActiveYear(k.vintageYear)) {
            sum += k.totalAmountPerc
          }
        })
      return sum.toLocaleString()
    },
    sectorFilledAmountSum (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId)
        .forEach(k => {
          if (this.checkActiveYear(k.vintageYear)) {
            sum += k.totalFilledPerc
          }
        })
      return sum.toLocaleString()
    },
    sectorAdvFilledSum (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId)
        .forEach(k => {
          sum += k.advActualPerc
        })
      return sum.toLocaleString()
    },
    sectorAdvTargetSum (sectorId) {
      let sum = 0
      this.vehicles.filter(x => x.sectorId === sectorId)
        .forEach(k => {
          sum += k.advTargetPerc
        })
      return sum.toLocaleString()
    },
    checkActiveYear (yr) {
      const y = this.portfolio.years.filter(x => x.vintageYear === yr)
      if (y.length > 0) {
        return y[0].isActive
      }
      return false
    },
    showGeoName (id, isGlobal) {
      if (isGlobal) {
        return 'Global*'
      }
      if (id > 0) {
        return this.allGeoFocuses.filter(x => x.geographicId === id)[0].geographicName
      } else {
        return ''
      }
    },
    calcTotalYearCommitmentFunc () {
      let sy = 0
      if (this.portfolio.years != null) {
        this.portfolio.years.forEach(yr => {
          sy += yr.commitment
        })
      }
      return sy
    },
    targetValueClass (v, t) {
      if (v > t) {
        return 'text-danger'
      } else {
        return 'text-success'
      }
    },
    canDeleteYear (id) {
      return this.vehicles.filter(x => x.vintageYear === id && x.portfolioSlotId > 0).length > 0
    },
    canDeleteGeo (id) {
      return this.vehicles.filter(x => x.geographicId === id && x.portfolioSlotId > 0).length > 0
    },
    canDeleteInv (id) {
      return this.vehicles.filter(x => x.investmentTypeId === id && x.portfolioSlotId > 0).length > 0
    },
    canDeleteSector (id) {
      return this.vehicles.filter(x => x.sectorId === id && x.portfolioSlotId > 0).length > 0
    },
    getChartColor (index) {
      let cc = '#fff'
      switch (index) {
        case 0:
          cc = '#00386b'
          break
        case 1:
          cc = '#007177'
          break
        case 2:
          cc = '#acd128'
          break
        case 3:
          cc = '#0071c1'
          break
        case 4:
          cc = '#ffaa00'
          break
        case 5:
          cc = '#5aa762'
          break
        case 6:
          cc = '#ed553b'
          break
        case 7:
          cc = '#7d4199'
          break
        case 8:
          cc = '#f6d55c'
          break
        case 9:
          cc = '#008fd6'
          break
      }
      return cc
    },
    showTypeName (id) {
      if (id > 0) {
        const fundTypes = this.allTypes.filter(x => x.typeId === id)
        if (fundTypes && fundTypes.length > 0) {
            return fundTypes[0].typeName
        } else {
            return ''
        }
        
      } else {
        return ''
      }
    },
    slotBgClass (key, yr) {
      let bgClass = ''
      if (!this.checkActiveYear(yr)) {
        return bgClass
      }

      switch (key) {
        case 1:
          bgClass = 'type1-bg'
          break
        case 2:
          bgClass = 'type2-bg'
          break
        case 3:
          bgClass = 'type3-bg'
          break
      }
      return bgClass
    },
    opsFormatDate (tm) {
      return moment(tm).format('MMM D YYYY')
    },
    showMember (id) {
      const memIdx = this.members.findIndex(x => x.userId === id)
      if (memIdx >= 0) {
        this.$store.commit('setRBPortfolioMember', this.members[memIdx])
        this.sidebarAction('portfolio-mem', false, true)
      }
    },
    onMemberUpdate (member) {
      member.createdBy = this.user.userId
      this.members.push(member)
      this.sidebarAction('', false, false)
    },
    clickAddMember () {
      this.$store.commit('setNewPortfolioMember', this.portfolio.portfolioId)
      this.sidebarAction('portfolio-mem', true, true)
    },
    showFile (id) {
      this.fetchFile({fileId: id})
      this.sidebarAction('file', false, true)
    },
    editFile (id) {
      this.fetchFile({fileId: id})
      this.sidebarAction('file', false, true, true)
    },
    clickAddFile () {
      this.$store.commit('setNewFile', this.portfolio.portfolioId)
      this.sidebarAction('file', true, true)
    },
    onFileUpdate () {
      this.fetchFileList()
      this.sidebarAction('', false, false)
    },
  },
  created () {
    this.addSidebar = false
    this.hideRightbar()
    this.getTables()
    this.createSectorTree()
    this.prepQuarterDropdown()
    this.getDetails()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
     minQ () {
      // traversing quarter Tree for the lowest minimum quarter
      // used to disable prev quarter button
      return this.quarterTree.slice(-1)[0].children.slice(-1)[0].id
    },
    maxQ () {
      // traversing quarter tree for the maximum quarter
      // used to disable next quarter button
      return this.quarterTree.slice(0,1)[0].children.slice(0,1)[0].id
    },
    allSectors () {
      return this.$store.state.allSectors
    },
    allTreeStrategies () {
      return this.$store.state.allTreeStrategies
    },
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    sectorGroups () {
      const groupBySector = groupBy('sectorId')
      return groupBySector(this.vehicles)
    },
    sectorGroupsSorted () {
      const copyLabels = {...this.sectorLabels}
      const copyGroups = {...this.sectorGroups}
      // convert groups to array, where [0] is group label, [1] is group vehicles 
      const groupsArray = Object.entries(copyGroups)
      groupsArray.forEach(function(group) {
        group[0] = copyLabels[`C${group[0]}`]
      })
      // sort the groups by their labels
      groupsArray.sort(function(a,b) {
        return a[0].localeCompare(b[0])
      })
      return groupsArray
      // sectorGroupsSorted will be used during render on slots page
    },
    notUsedSectors () {
      const result = []
      const used = []
      Object.keys(this.sectorGroups).forEach(function (prop) {
        used.push(+prop)
      })
      for (const item of this.allSectors) {
        if (used.indexOf(item.sectorId) < 0) {
          result.push(item)
        }
      }
      return result
    },
    usedSectors () {
      return this.portfolio.sectors.map(sector => sector.sectorId)
    },
    notUsedGeos () {
      const result = []
      const used = []
      this.portfolio.geos.forEach(function (prop) {
        used.push(prop.geographicId)
      })
      for (const item of this.allGeoFocuses) {
        if (used.indexOf(item.geographicId) < 0) {
          result.push(item)
        }
      }
      return result
    },
    notUsedInvs () {
      const result = []
      const used = []
      this.portfolio.invs.forEach(function (prop) {
        used.push(prop.invTypeId)
      })
      for (const item of this.allTypes) {
        if (used.indexOf(item.typeId) < 0) {
          result.push(item)
        }
      }
      return result
    },
    filledTotal () {
      let sum = 0
      const yrs = this.portfolio.years.filter(x => x.isActive).map(x => x.vintageYear)
      this.vehicles.forEach(k => { if (yrs.indexOf(k.vintageYear) >= 0) sum += k.filledPosition })
      return sum.toLocaleString()
    },
    targetTotal () {
      let sum = 0
      const yrs = this.portfolio.years.filter(x => x.isActive).map(x => x.vintageYear)
      this.vehicles.forEach(k => { if (yrs.indexOf(k.vintageYear) >= 0 && k.fundId == null) sum += k.targetPosition })
      return sum.toLocaleString()
    },
    proformaOverCommitment () {
      if (this.vehicles.length > 0) {
        return Math.round(((+this.targetTotal + +this.filledTotal) / this.portfolio.totalCommitment) * 1000) / 10
      }
      return 0
    },
    proformaOverCommitmentToday () {
      if (this.portfolio.totalCommitmentToday > 0 && this.vehicles.length > 0) {
        return Math.round((+this.targetTotal + +this.filledTotal) / this.portfolio.totalCommitmentToday * 1000) / 10
      }
      return 0
    },
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    allTypes () {
      return this.$store.state.allFundtypes
    },
    symbol () {
      if (this.currencyObj != null) {
        return this.currencyObj.length > 0 ? this.currencyObj[0].symbol : this.currencyObj.symbol
      }
    },
    calcTotalYearCommitmentAll () {
      let sy = 0
      if (this.portfolio.years.length > 0) {
        sy = this.portfolio.years.map(x => x.commitment).reduce((a, b) => a + b)
      }
      return sy
    },
    calcTotalYearCommitment () {
      let sy = 0
      if (this.portfolio.years.length > 0) {
        const list = this.portfolio.years.filter(x => x.isActive)
        if (list.length > 0) {
          sy = list.map(x => x.commitment).reduce((a, b) => a + b)
        }
      }
      return sy
    },
    calcTotalYearCommitmentPerc () {
      let sy = 0
      if (this.portfolio.years.length > 0) {
        const list = this.portfolio.years.filter(x => x.isActive)
        if (list.length > 0) {
          sy = list.map(x => x.commitmentPercent).reduce((a, b) => a + b)
        }
      }
      return sy
    },
    calcTotalGeoCommitment () {
      let sy = 0
      if (this.portfolio.geos.length > 0) {
        this.portfolio.geos.forEach(x => {
          sy += x.commitment
        })
      }
      return sy
    },
    calcTotalGeoCommitmentPerc () {
      let sy = 0
      if (this.portfolio.geos.length > 0) {
        sy = this.portfolio.geos.map(x => x.commitmentPercent).reduce((a, b) => a + b)
      }
      return sy
    },
    calcTotalSectorCommitment () {
      let sy = 0
      if (this.portfolio.sectors.length > 0) {
        sy = this.portfolio.sectors.map(x => x.targetSectorPosition).reduce((a, b) => a + b)
      }
      return sy
    },
    calcTotalSectorCommitmentPerc () {
      let sy = 0
      if (this.portfolio.sectors.length > 0) {
        sy = this.portfolio.sectors.map(x => x.targetSectorPercent).reduce((a, b) => a + b)
      }
      return sy
    },
    calcTotalInvCommitment () {
      let sy = 0
      if (this.portfolio.invs.length > 0) {
        sy = this.portfolio.invs.map(x => x.commitment).reduce((a, b) => a + b)
      }
      return sy
    },
    calcTotalInvCommitmentPerc () {
      let sy = 0
      if (this.portfolio.invs.length > 0) {
        sy = this.portfolio.invs.map(x => x.commitmentPercent).reduce((a, b) => a + b)
      }
      return sy
    },
    genLastYear () {
      let ly = 0
      if (this.portfolio.years.length > 0) {
        ly = Math.max(...this.portfolio.years.map(x => x.vintageYear)) + 1
      }
      return ly
    },
    allUsers () {
      return this.$store.state.allUsers
    },
    canReOpenSlot () {      
      return this.user.roles.filter(x => x.roleId === this.$userRoles.OPS_TEAM_ADMINISTRATOR).length > 0 
    },
    canbeEdited () {
      let canAdvisory = false
      const advisoryAdmin = this.user.groupId < 11 && this.user.roles.filter(x => x.roleId === this.$userRoles.ADVISORY_PORTFOLIO_ADMINISTRATOR).length > 0
      if (parseInt(this.portfolio.clientTypeId) === 1) {
        canAdvisory = advisoryAdmin
      }
      let teamMember = false
      if (this.members != null) {
        teamMember = this.members.filter(x => x.userId === this.user.userId).length > 0 && parseInt(this.portfolio.clientTypeId) === 2
      }
      return !this.portfolio.isClosed && (teamMember || canAdvisory)
    },
    canbeClosed () {
      if (parseInt(this.portfolio.clientTypeId) === 1) {
        return false
      }
      let teamMember = false
      let hasCloseVote = true
      if (this.members != null) {
        teamMember = this.members.filter(x => x.userId === this.user.userId).length > 0
      }
      if (this.portfolio.votes != null) {
        hasCloseVote = this.portfolio.votes.filter(x => x.typeId === 2).length > 0
      }
      return !this.portfolio.isClosed && this.portfolio.isActive && teamMember && !hasCloseVote
    },
    canbeOpened () {
      if (parseInt(this.portfolio.clientTypeId) === 1) {
        return false
      }
      let teamMember = false
      let hasStartVote = true
      if (this.members != null) {
        teamMember = this.members.filter(x => x.userId === this.user.userId).length > 0
      }
      if (this.portfolio.votes != null) {
        hasStartVote = this.portfolio.votes.filter(x => x.typeId === 1).length > 0
      }
      return !this.portfolio.isActive && teamMember && !hasStartVote
    },
    canbeDeleted () {
      return !this.hasAnyFilled && this.isPortfolioAdmin && this.activities === null
    },
    isAdminOps () {
      return this.user.groupId < 11 && this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR || x.roleId === this.$userRoles.OPS_TEAM_ADMINISTRATOR).length > 0 // Admin + OpsTeamAdministrator
    },
    isSysAdmin () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0
    },
    isICMember () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.INVESTMENT_COMMITTEE).length > 0
    },
    isPortfolioAdmin () {
      const advisoryAdmin = this.user.groupId < 11 && this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR || x.roleId === this.$userRoles.ADVISORY_PORTFOLIO_ADMINISTRATOR).length > 0
      // const discretionaryAdmin = this.user.groupId < 11 && this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR || x.roleId === this.$userRoles.DISCRETIONARY_PORTFOLIO_ADMINISTRATOR).length > 0
      if (parseInt(this.portfolio.clientTypeId) === 1) {
        return advisoryAdmin
      }
      return false
    },
    hasAnyFilled () {
      if (this.vehicles == null) {
        return false
      }
      return this.vehicles.filter(x => x.filledPosition > 0).length > 0
    },
    pieData () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      for (let i = 0; i < 10; i++) {
        chartData.push(i)
        chartColor.push(this.getChartColor(i))
        chartLabel.push(i)
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataTargetInv () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      if (this.portfolio.clientTypeId === 1) {
        chartData.push(100)
        chartLabel.push('Primary')
        chartColor.push(this.getChartColor(0))
      } else {
        for (let i = 0; i < this.portfolio.invs.length; i++) {
          // chartData.push(this.portfolio.invs[i].commitmentPercent)
          chartData.push(this.portfolio.invs[i].commitment)
          chartLabel.push(this.portfolio.invs[i].typeName)
          chartColor.push(this.getChartColor(i))
        }
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataTargetSec () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      for (let i = 0; i < this.portfolio.sectors.length; i++) {
        // chartData.push(this.portfolio.sectors[i].targetSectorPercent)
        chartData.push(this.portfolio.sectors[i].targetSectorPosition)
        chartLabel.push(this.portfolio.sectors[i].sectorName)
        chartColor.push(this.getChartColor(i))
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataTargetYer () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      let cIndex = 0
      for (let i = 0; i < this.portfolio.years.length; i++) {
        if (this.portfolio.years[i].isActive) {
          // chartData.push(this.portfolio.years[i].commitmentPercent > 0 ? this.portfolio.years[i].commitmentPercent : this.portfolio.years[i].commitment)
          chartData.push(this.portfolio.years[i].commitment)
          chartLabel.push(this.portfolio.years[i].vintageYear)
          chartColor.push(this.getChartColor(cIndex))
          cIndex++
        }
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataTargetGeo () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      for (let i = 0; i < this.portfolio.geos.length; i++) {
        // chartData.push(this.portfolio.geos[i].commitmentPercent)
        chartData.push(this.portfolio.geos[i].commitment)
        chartLabel.push(this.portfolio.geos[i].geographicName)
        chartColor.push(this.getChartColor(i))
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataActualInv () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      if (this.portfolioChart != null && this.portfolioChart.investActual != null) {
        for (let i = 0; i < this.portfolioChart.investActual.length; i++) {
          chartData.push(this.portfolioChart.investActual[i].sumValue)
          chartLabel.push(this.portfolioChart.investActual[i].chartGroup)
          chartColor.push(this.getChartColor(i))
        }
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataActualSec () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      if (this.portfolioChart != null && this.portfolioChart.sectorActual != null) {
        for (let i = 0; i < this.portfolioChart.sectorActual.length; i++) {
          chartData.push(this.portfolioChart.sectorActual[i].sumValue)
          chartLabel.push(this.portfolioChart.sectorActual[i].chartGroup)
          chartColor.push(this.getChartColor(i))
        }
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataActualYer () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      if (this.portfolioChart != null && this.portfolioChart.vintageActual != null) {
        for (let i = 0; i < this.portfolioChart.vintageActual.length; i++) {
          chartData.push(this.portfolioChart.vintageActual[i].sumValue)
          chartLabel.push(this.portfolioChart.vintageActual[i].chartGroup)
          chartColor.push(this.getChartColor(i))
        }
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataActualGeo () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      if (this.portfolioChart != null && this.portfolioChart.geographyActual != null) {
        for (let i = 0; i < this.portfolioChart.geographyActual.length; i++) {
          chartData.push(this.portfolioChart.geographyActual[i].sumValue)
          chartLabel.push(this.portfolioChart.geographyActual[i].chartGroup)
          chartColor.push(this.getChartColor(i))
        }
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataProformaInv () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      if (this.portfolioChart != null && this.portfolioChart.investProforma != null) {
        for (let i = 0; i < this.portfolioChart.investProforma.length; i++) {
          chartData.push(this.portfolioChart.investProforma[i].sumValue)
          chartLabel.push(this.portfolioChart.investProforma[i].chartGroup)
          chartColor.push(this.getChartColor(i))
        }
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataProformaSec () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      if (this.portfolioChart != null && this.portfolioChart.sectorProforma != null) {
        for (let i = 0; i < this.portfolioChart.sectorProforma.length; i++) {
          chartData.push(this.portfolioChart.sectorProforma[i].sumValue)
          chartLabel.push(this.portfolioChart.sectorProforma[i].chartGroup)
          chartColor.push(this.getChartColor(i))
        }
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataProformaYer () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      if (this.portfolioChart != null && this.portfolioChart.vintageProforma != null) {
        for (let i = 0; i < this.portfolioChart.vintageProforma.length; i++) {
          chartData.push(this.portfolioChart.vintageProforma[i].sumValue)
          chartLabel.push(this.portfolioChart.vintageProforma[i].chartGroup)
          chartColor.push(this.getChartColor(i))
        }
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    pieDataProformaGeo () {
      let chartData = []
      let chartColor = []
      let chartLabel = []
      if (this.portfolioChart != null && this.portfolioChart.geographyProforma != null) {
        for (let i = 0; i < this.portfolioChart.geographyProforma.length; i++) {
          chartData.push(this.portfolioChart.geographyProforma[i].sumValue)
          chartLabel.push(this.portfolioChart.geographyProforma[i].chartGroup)
          chartColor.push(this.getChartColor(i))
        }
      }
      return {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
    },
    commitmentValue () {
      let totalYrCommitment = 0
      if (this.portfolio.clientTypeId === 1) {
        totalYrCommitment = this.calcTotalYearCommitment
      } else if (!this.portfolio.totalCommitmentNa) {
        totalYrCommitment = this.portfolio.totalCommitment
      } else if (!this.portfolio.totalCommitmentTodayNa) {
        totalYrCommitment = this.portfolio.totalCommitmentToday
      }
      return totalYrCommitment
    },
    maxCommitmentValue () {
      let mx = 0
      if (this.portfolio.clientTypeId === 1) {
        mx = 100
      } else if (this.portfolio.maxRecommendedCommitmentPercent === 0 || this.portfolio.maxRecommendedCommitmentPercent == null) {
        mx = 100
      } else {
        mx = this.portfolio.maxRecommendedCommitmentPercent
      }
      return mx
    },
    filledVehicles () {
      return this.vehicles.filter(x => x.fundId)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.actual-perc {
  background-color: #49596b;
}
.total-perc {
  background-color: #415469;
}
.done-done {
  font-size: 1rem;
}
.type1-bg {
  background-color: #415465;
}
.type2-bg {
  background-color: #3a4c5c;
}
.type3-bg {
  background-color: #32414f;
}
.scroll-me {
  overflow-x: auto;
}
</style>