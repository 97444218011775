<template>
    <div>
        TERMS...
    </div>
</template>

<script>
export default {
}
</script>
