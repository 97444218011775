import Vue from 'vue'

export default {
  setItem (state, {item, id, resource}) {
    Vue.set(state[resource], id, item)
  },
  setActiveUser (state, user) {
    state.activeUser = user
  },
  setFundinactiveReasons (state, list) {
    state.allFundinactiveReasons = list
  },
  setFundlist (state, list) {
    state.allFundList = list
  },
  setFundTypes (state, list) {
    state.allFundtypes = list
  },
  setFundtires (state, list) {
    state.allFundtires = list
  },
  setSectors (state, list) {
    state.allSectors = list
  },
  setStrategies (state, list) {
    state.allStrategies = list
  },
  setTreeStrategies (state, list) {
    state.allTreeStrategies = list
  },
  setSubSectors (state, list) {
    state.allSubsectors = list
  },
  setUserGroups (state, list) {
    state.allUserGroups = list
  },
  setUserRoles (state, list) {
    state.allUserRoles = list
  },
  setOffices (state, list) {
    state.allOffices = list
  },
  setTitles (state, list) {
    state.allUserTitles = list
  },
  setGeographies (state, list) {
    state.allGeoFocus = list
  },
  setCurrencies (state, list) {
    state.allCurrencies = list
  },
  setEmailTemplates (state, list) {
    state.allEmailTemplates = list
  },
  setMonitoringtypes (state, list) {
    state.allMonitoringtypes = list
  },
  setBoardseats (state, list) {
    state.allBoardseats = list
  },
  setMngfeestructures (state, list) {
    state.allMngfeestructures = list
  },
  setFundperformanceSource (state, list) {
    state.allFundperformanceSource = list
  },
  setOprInvestors (state, list) {
    state.allOprInvestors = list
  },
  setOprServiceProviders (state, list) {
    state.allOprServiceProviders = list
  },
  setActivityTypes (state, list) {
    state.allActivityTypes = list
  },
  setUsers (state, list) {
    state.allUsers = list
  },
  setICUsers (state, list) {
    state.icUsers = list
  },
  setMAUsers (state, list) {
    state.maUsers = list
  },
  setPMMRUsers (state, list) {
    state.pmmrUsers = list
  },
  setRBActivity (state, item) {
    state.rb_activity = item
    state.rb_isNew = false
  },
  setRBFile (state, item) {
    state.rb_file = item
    state.rb_isNew = false
  },
  setNewActivity (state, entId) {
    state.rb_activity = {activityId: 0, activityTypeId: 1, activityTo: null, activityText: null, activitySubject: null, activityDate: new Date(), isActive: true, createdOn: null, createdBy: null, updatedOn: null, updatedBy: null, objectTypeId: 0, objectId: entId}
  },
  setNewFile (state, entId) {
    state.rb_file = {fileId: 0, objectTypeId: 0, originalFileName: null, umbFileName: null, fileType: null, purposeId: null, createdOn: null, createdBy: null, updatedOn: null, updatedBy: null, objectId: entId}
  },
  setRBFundTeam (state, item) {
    state.rb_fundTeam = item
  },
  setNewFundTeam (state, fundId) {
    state.rb_fundTeam = {fundTeamId: 0, userId: 1, fundTeamRoleId: 1, isActive: true, createdOn: null, createdBy: null, updatedOn: null, updatedBy: null, fundId: fundId}
  },
  setRBPortfolioMember (state, item) {
    state.rb_portfolioMmeber = item
  },
  setNewPortfolioMember (state, portfolioId) {
    state.rb_portfolioMmeber = {teamId: 0, userId: null, createdBy: 0, portfolioId: portfolioId}
  },
  setRBIndustryFocus (state, item) {
    state.rb_industryFocus = item
  },
  setNewIndustryFocus (state, fundId) {
    state.rb_industryFocus = {fundsIndustryFocusId: 0, industryFocusId: 1, createdOn: null, createdBy: null, fundId: fundId}
  },
  setRBFundRegionalFocus (state, item) {
    state.rb_regionalFocus = item
  },
  setNewFundRegionalFocus (state, fundId) {
    state.rb_regionalFocus = {fundsCountryFocusId: 0, countryId: 1, createdOn: null, createdBy: null, fundId: fundId}
  },
  setRBMeritConcern (state, item) {
    state.rb_meritConcern = item
  },
  setNewMerit (state, fundId) {
    state.rb_meritConcern = {meritConcernId: 0, meritConcernText: null, meritConcernTypeId: 1, createdOn: null, createdBy: null, updatedOn: null, updatedBy: null, fundId: fundId}
  },
  setRBKeySenior (state, item) {
    state.rb_fundKeySenior = item
  },
  setNewKeySenior (state, fundId) {
    state.rb_fundKeySenior = {fundKeySeniorId: 0, fundId: fundId, contactId: null, note: null}
  },
  setRBFundOpportunitySet (state, item) {
    state.rb_fundOpportunity = item
  },
  setNewFundOpportunitySet (state, fundId) {
    state.rb_fundOpportunity = {fundOpportunitySetId: 0, targetFundId: null, isActive: true, createdOn: null, createdBy: null, updatedOn: null, updatedBy: null, fundId: fundId}
  },
  setRBCoInvestmentClient (state, item) {
    state.rb_coInvestmentClient = item
  },
  // edit here
  setRBTrackRecord (state, item) {
    state.rb_trackRecord = item
  },
  setNewTrackRecord (state, fundId) {
    state.rb_trackRecord = {fundId: fundId, fundName: '', vintageYear: 0, netIRRPercent: 0, netROI: 0, targetSize: 0}
  },
  setNewCoInvestmentClient (state, fundId) {
    state.rb_coInvestmentClient = {organizationId: 0, approvedAmount: 0, createdBy: 0, fundId: fundId}
  },
  setRBFirmContact (state, item) {
    state.rb_firmContact = item
  },
  setNewFirmContact (state, firmId) {
    state.rb_firmContact = {firmContactId: 0, contactId: null, createdOn: null, createdBy: null, firmId: firmId}
  },
  setRBFirmAnnualMeeting (state, item) {
    state.rb_firmAnnualMeeting = item
  },
  setNewFirmAnnualMeeting (state, firmId) {
    state.rb_firmAnnualMeeting = {meetingId: 0, city: null, meetingDate: null, attendanceId: null, attendeeId: null, createdOn: null, createdBy: null, firmId: firmId}
  },
  setRBFundFlrVoting (state, item) {
    state.rb_fundFlrVoting = item
  },
  setRBFundRrVoting (state, item) {
    state.rb_fundRrVoting = item
  },
  setRBFundsOnsiteMeeting (state, item) {
    state.rb_fundOnsiteMeeting = item
  },
  setNewFundsOnsiteMeeting (state, fundId) {
    state.rb_fundOnsiteMeeting = {meetingId: 0, city: null, meetingDate: null, createdOn: null, createdBy: null, fundId: fundId, employees: []}
  },
  setCalanderDeal (state, deal) {
    state.rb_calanderDeal = deal
  },
  setHomeRightBar (state, type) {
    state.rb_homeRightBar = type
  },
  setNewPortfolioSector (state, id) {
    state.rb_portfolioSector = {portfolioSectorId: 0, sectorId: null, targetSectorPercent: null, targetSectorPosition: null, createdOn: null, createdBy: null, slotNo: 0, portfolioId: id}
  },
  setRBPortfolioSector (state, item) {
    state.rb_portfolioSector = item
  },
  setNewPortfolioSlot (state, id) {
    state.rb_portfolioVehicle = {portfolioSlotId: 0, investmentTypeId: null, vintageYear: null, geographicId: null, targetPosition: 0, targetPositionPerc: 0, targetPositionTodayPerc: 0, filledPosition: 0, fund1Id: 0, fund2Id: 0, fund3Id: 0, isApproved: 0, isOpsDone: 0, createdOn: null, createdBy: null, defaultValues: true, portfolioSectorId: id}
  },
  setNewPortfolioSlotNoDefault (state, id) {
    state.rb_portfolioVehicle = {portfolioSlotId: 0, investmentTypeId: null, vintageYear: null, geographicId: null, targetPosition: 0, targetPositionPerc: 0, targetPositionTodayPerc: 0, filledPosition: 0, fund1Id: 0, fund2Id: 0, fund3Id: 0, isApproved: 0, isOpsDone: 0, createdOn: null, createdBy: null, defaultValues: false, portfolioSectorId: id}
  },
  setRBPortfolioVehicle (state, item) {
    item.defaultValues = true
    // item.filledPositionExact = item.filledPosition * 1000000
    state.rb_portfolioVehicle = item
  },
  setRBPortfolioVehicleNoDefault (state, item) {
    item.defaultValues = false
    // item.filledPositionExact = item.filledPosition * 1000000
    state.rb_portfolioVehicle = item
  },
  setNewPortfolioYear (state, {id, year}) {
    state.rb_portfolioYear = {vintageYear: year, commitment: 0, commitmentPercent: 0, isApproved: 0, isOpsDone: 0, createdOn: null, createdBy: null, portfolioId: id}
  },
  setRBPortfolioYear (state, item) {
    state.rb_portfolioYear = item
  },
  setNewPortfolioGeo (state, {id, geo}) {
    state.rb_portfolioGeo = {geographicId: geo, commitment: 0, commitmentPercent: 0, isApproved: 0, isOpsDone: 0, createdOn: null, createdBy: null, portfolioId: id}
  },
  setRBPortfolioGeo (state, item) {
    state.rb_portfolioGeo = item
  },
  setNewPortfolioInv (state, {id, inv}) {
    state.rb_portfolioInv = {invTypeId: inv, commitment: 0, commitmentPercent: 0, isApproved: 0, isOpsDone: 0, createdOn: null, createdBy: null, portfolioId: id}
  },
  setRBPortfolioInv (state, item) {
    state.rb_portfolioInv = item
  },
  setNewSecFund (state, id) {
    state.rb_secFund = {firmId: 0, createdOn: null, createdBy: null, fundId: id}
  },
  setRBSecFund (state, item) {
    state.rb_secFund = item
  },
  setFundTeamRoles (state, list) {
    state.allFundTeamRoles = list
  },
  setCountries (state, list) {
    state.allCountries = list
  },
  setFilePurposes (state, list) {
    state.allFilePurposes = list
  },
  setIndustryFocus (state, list) {
    state.allIndustryFocus = list
  },
  setFirmTypes (state, list) {
    state.allFirmTypes = list
  },
  setSecOutreachTiers (state, list) {
    state.allSecOutreachTiers = list
  },
  setFirmClientTypes (state, list) {
    state.allFirmClientTypes = list
  },
  setFirmOwnershipTypes (state, list) {
    state.allFirmOwnershipTypes = list
  },
  setAnnualMeetingAttendances (state, list) {
    state.allAnnualMeetingAttendances = list
  },
  setLegalRequestCategories (state, list) {
    state.allLegalRequestCategories = list
  },
  setLegalRequestStatuses (state, list) {
    state.allLegalRequestStatuses = list
  },
  setQuartiles (state, list) {
    state.allQuartiles = list
  },
  setCalMeetingTypes (state, list) {
    state.allCalMeetingTypes = list
  },
  setLockupTypes (state, list) {
    state.allLockupTypes = list
  },
  setTimeWinows (state, list) {
    state.allTimeWinows = list
  },
  setRedemptions (state, list) {
    state.allRedemptions = list
  },
  setSubscriptions (state, list) {
    state.allSubscriptions = list
  },
  setMsalInstance (state, instance) {
    state.msalInstance = instance
  }
  // loginSuccess (state, user) {
  //   state.user = user
  // },
  // loginFailure (state) {
  //   state.user = null
  // },
  // logout (state) {
  //   state.user = null
  // }
}
