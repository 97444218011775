<template>
    <div>
        <Topbar :resetNotifications="readNotifications"/>
        <Sidebar :activeItem="'dashboard'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Notifications'" :icon="'mdi mdi-bell'"/>
                    <div class="row">
                      <div class="col-12">
                          <h4 class="m-t-0 header-title mb-4">Your Notifications
                            <a href="javascript:void(0);" @click.prevent="readAll" v-show="hasNew && !readNotifications" class="badge badge-success float-right">Read all</a>
                          </h4>

                          <div class="card-box">
                              <div class="container-fluid">
                                <div class="row">
                                  <div class="col-sm-12">
                                    <table class="table table-hover table-striped m-0 table-centered" role="grid">
                                      <tbody class="font-16">
                                        <tr v-if="notifications.length == 0">
                                          <td colspan="5" class="text-center">
                                            You have No Notification to show!
                                          </td>
                                        </tr>
                                        <tr role="row" v-for="nfy in notifications" :key="nfy.notificationId">
                                          <td></td>
                                          <td>
                                              <img :src="pic(nfy.createdBy)" class="img-responsive rounded-circle thumb-sm" alt="user">
                                              <span class="ml-2">{{userName(nfy.createdBy)}}</span>
                                          </td>
                                          <td>
                                              <span class="badge badge-pill text-white" :class="nfy.notificationClass">
                                                <i class="mdi" :class="nfy.objectClass"></i>
                                              </span>
                                              {{nfy.notificationText}}
                                              <router-link v-if="nfy.notificationUrlId > 0 && nfy.typeId == 12" :to="{path: '/deals/allocation/' + nfy.notificationUrlId}" target="_blank">
                                                <i class="mdi mdi-open-in-new"></i>
                                              </router-link>
                                              <router-link v-if="nfy.notificationUrlId > 0 && nfy.typeId != 7 && nfy.typeId != 12" :to="{path: '/' + nfy.notificationUrlName + '/detail/' + nfy.notificationUrlId}" target="_blank">
                                                <i class="mdi mdi-open-in-new"></i>
                                              </router-link>
                                              <router-link v-if="nfy.notificationUrlId > 0 && nfy.typeId == 7" :to="{path: '/legal/' + nfy.notificationUrlName + '/' + nfy.notificationUrlId}" target="_blank">
                                                <i class="mdi mdi-open-in-new"></i>
                                              </router-link>
                                          </td>
                                          <td>
                                              <span v-tooltip="formatedDate(nfy.createdOn)">
                                                {{formatedDateAgo(nfy.createdOn)}}
                                              </span>
                                          </td>
                                          <td>
                                            <span v-if="nfy.readOn == null">Unread</span>
                                            <span v-if="nfy.readOn != null" v-tooltip="formatedDate(nfy.readOn)">
                                              Read {{formatedDateAgo(nfy.readOn)}}
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colspan="5" class="text-center">
                                            <button type="button" class="btn btn-sm btn-primary btn-rounded waves-effect" v-if="nomore && notifications.length > 0" @click="loadMore">Load more <i v-show="isLoading" class="fa fa-spinner fa-spin"></i></button>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div><!-- end col -->
                    </div>
              </div>
              <!-- end content -->
              <Footer />
        </div>
    </div>
    </div>

</template>

<script>
import { mapActions } from 'vuex'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { NotificationService } from '@/services/notification-service'
import moment from 'moment'

export default {
  components: {
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      isLoading: null,
      notifications: [],
      nomore: true,
      readNotifications: false
    }
  },
  created () {
    this.fetchAllUsers()
    NotificationService.getAll(999999999)
    .then(
      (response) => {
        this.notifications = response.data
      }
    ).then(() => {
      this.asyncDataStatus_fetched('Notifications')
    })
  },
  methods: {
    ...mapActions(['fetchAllUsers']),
    readAll () {
      if (this.notifications.length > 0) {
        const lastId = this.notifications[0].notificationId
        NotificationService.dismiss(lastId).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.readNotifications = true
              this.notifications
                .filter(x => x.readOn == null)
                .forEach(read => {
                  read.readOn = new Date()
                })
            }
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      }
    },
    loadMore () {
      const lastId = this.notifications[this.notifications.length - 1].notificationId
      this.isLoading = true
      NotificationService.getAll(lastId)
      .then(
        (response) => {
          if (response.data.length > 0) {
            response.data.forEach(newNfy => {
              this.notifications.push(newNfy)
            })
          } else {
            this.nomore = false
          }
          this.isLoading = false
        }
      )
    },
    formatedDateAgo (date) {
      return moment(date).fromNow()
    },
    formatedDate (date) {
      return moment(date).format('M/D/YYYY, h:mm a')
    },
    pic (userId) {     
       if (this.userId === 0) {
        return require(`/static/img/users/system.png`)
      }
      const usr = this.allUsers.find(e => e.userId === userId)
      try {
        if (usr != null) {
        return require(`/static/img/users/${usr.userName.toLowerCase()}.jpg`)
      }
      } catch  {
        return require(`/static/img/users/user.jpg`)
      }
      return require(`/static/img/users/user.jpg`)
    },
    userName (userId) {
      let result = '(Not Found)'
      const usr = this.allUsers.find(e => e.userId === userId)
      if (this.userId === 0) {
        return 'System'
      }
      if (usr != null) {
        return usr.firstName + ' ' + usr.lastName
      }
      return result
    }
  },
  computed: {
    allUsers () {
      return this.$store.state.allUsers
    },
    hasNew () {
      return this.notifications.filter(x => x.readOn == null).length > 0
    }
  }
}
</script>
