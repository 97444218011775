<template>
  <div>
    <Topbar/>
    <Sidebar :activeItem="'legal-add'"/>
    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid">
          <PageTitle :title="'Legal Workflow'" :icon="'mdi mdi-scale-balance'" />
          <div class="card-box">
            <h4 class="header-title m-t-0">Submit a GP Consents/IC Approvals request</h4>
            <p class="text-muted font-14 m-b-20">Please fill the form.</p>

            <form action="#" autocomplete="off" @submit.prevent="save">
              <div class="row">

                <div class="col-sm-6 col-xs-12">

                  <div class="form-group">
                    <label>Requestor</label>
                    <input type="text" v-model="user.fullName" disabled class="form-control">
                  </div>

                  <div class="form-group">
                    <label for="deadline">
                      Deadline <span class="text-danger">*</span>
                    </label>
                    <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" 
                    format="MM/dd/yyyy" :bootstrap-styling="true" :disabledDates="{to: endDate}" v-model="legalRequest.deadline" @blur="$v.legalRequest.deadline.$touch()" @input="setPickerDate" :input-class="{ 'form-control parsley-error': $v.legalRequest.deadline.$error }"></date-picker>
                    <template v-if="$v.legalRequest.deadline.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.deadline.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="purpose" class="col-form-label">
                      What is the purpose of this request?
                      <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id="purpose" v-model="legalRequest.purpose" @blur="$v.legalRequest.purpose.$touch()" :class="{ 'parsley-error': $v.legalRequest.purpose.$error }">
                    <template v-if="$v.legalRequest.purpose.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.purpose.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.purpose.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.purpose.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="fundId" class="col-form-label">
                      Deal <span class="text-danger">*</span>
                    </label>
                    <span :class="{ 'invalid': $v.legalRequest.fundId.$error }">
                      <multiselect id="fundId" v-model="fundObj" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search"
                        @select="fundSelected" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                    </span>
                    <template v-if="$v.legalRequest.fundId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.fundId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="InvestingId" class="col-form-label">
                      Wilshire fund name
                      <span class="text-danger">*</span>
                    </label>
                    <span :class="{ 'invalid': $v.legalRequest.investingId.$error }">
                      <multiselect id="InvestingId" v-model="investingObj" :options="searchInvestings" label="investingName" track-by="investingId" :allow-empty="false" placeholder="Type to Search"
                        @select="investingSelected" @search-change="asyncSearchInvesting" :loading="isSearchingList" :options-limit="50"></multiselect>
                    </span>
                    <template v-if="$v.legalRequest.investingId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.investingId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="other" class="col-form-label">
                      Any other relevant information
                    </label>
                    <textarea class="form-control" rows="5" id="other" v-model="legalRequest.comments" @blur="$v.legalRequest.comments.$touch()" :class="{ 'parsley-error': $v.legalRequest.comments.$error }"></textarea>
                    <template v-if="$v.legalRequest.comments.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.comments.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                      <div class="checkbox checkbox-primary">
                          <input id="checkbox6a" type="checkbox" v-model="addMore">
                          <label for="checkbox6a">
                              Add Another
                          </label>
                      </div>
                  </div>

                  <div class="form-group text-right m-b-0">
                    <router-link :to="{name: 'LegalNew'}">
                        <button type="button" class="btn btn-secondary waves-effect mr-2">
                            Back
                        </button>
                    </router-link>

                    <button v-if="user.groupId < 11" class="btn btn-primary waves-effect waves-light" type="submit">Submit Request</button>
                  </div>

                </div>

              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import {mapActions, mapGetters} from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { LegalIcaService } from '@/services/legal-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
    DatePicker,
    Multiselect
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      legalRequest: {},
      endDate: null,
      investingObj: null,
      fundObj: null,
      searchInvestings: [],
      searchFunds: [],
      isSearchingList: false,
      addMore: false
    }
  },
  validations: {
    legalRequest: {
      deadline: { required },
      purpose: { required, min: minLength(2), max: maxLength(100) },
      fundId: { required },
      investingId: { required },
      comments: { max: maxLength(500) }
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  },
  methods: {
    ...mapActions(['searchAllInvestings', 'searchAllFunds']),
    fundSelected (value, id) {
      this.legalRequest.fundId = value ? value.fundId : null
      this.$v.legalRequest.fundId.$touch()
      this.$v.legalRequest.fundId.$reset()
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    },
    investingSelected (value, id) {
      this.legalRequest.investingId = value ? value.investingId : null
      this.$v.legalRequest.investingId.$touch()
      this.$v.legalRequest.investingId.$reset()
    },
    asyncSearchInvesting (query) {
      this.isSearchingList = true
      this.searchAllInvestings({query}).then((result) => {
        this.searchInvestings = result
        this.isSearchingList = false
      })
    },
    reset () {
      this.legalRequest = {
        deadline: null,
        investingId: null,
        purpose: null,
        comments: null
      }

      this.investingObj = null
      this.$v.$reset()
    },
    save () {
      this.$v.legalRequest.$touch()
      if (this.$v.legalRequest.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      this.legalRequest.requestorId = this.user.userId

      LegalIcaService.add(this.legalRequest)
      .then(
        (response) => {
          this.$snotify.success('Wilshire Fund Extensions Request Submitted.')
          if (!this.addMore) {
            setTimeout(() => {
              // this.$router.push({name: 'LegalList'})
              this.$router.push({name: 'LegalIca', params: {id: response.data.requestId}})
            }, 400)
          } else {
            this.reset()
          }
        })
        .catch(function (error) {
          console.error('SERVER Error:', error)
        })
    },
    setPickerDate (value) {
      if (value != null) {
        this.legalRequest.deadline = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0)
      } else {
        this.legalRequest.deadline = null
      }
    }
  },
  created () {
    this.endDate = new Date(Date.now())
    this.asyncDataStatus_fetched('Submit a Legal Workflow request')
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
