<template>
  <div v-if="asyncDataStatus_ready">
    <Topbar />
    <Sidebar :activeItem="'funds-list'"/>

    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid">
          <PageTitle :title="'Voting'" :icon="'mdi mdi-square-inc-cash'"/>
          <div class="row" v-if="versions !=null && versions.length > 0">
            <div class="col-md-12">
                <ul class="pagination float-right">
                  <li class="page-item disabled"><a class="page-link" href="#">Version(s)</a></li>
                  <li class="page-item" :class="item.versionId == versionId ? 'active disabled':''"  v-for="item in versions" :key="item.versionId">
                    <a href="javascript:void(0)"  @click="goToSummary(item.versionId)" class="page-link">{{item.versionNo}}</a>
                  </li>    
                  <li class="page-item" :class="versionId == '' ? 'active disabled':''"><a href="javascript:void(0)"  @click="goToSummary('')" class="page-link">Current</a></li>
                </ul>  
            </div>
          </div>
          <div class="card-box">
            <h4 class="header-title text-primary m-t-0">FLR Voting Summary {{voteStatus}} <span class="badge badge-secondary">{{versionStatus }}</span></h4>
            <p class="text-muted font-14 m-b-20">
              <strong>{{fundName}}</strong> <span v-if="isFinal" class="header-title" :class="{'text-danger' : !isFLRICApproved, 'text-success' : isFLRICApproved}">{{isFLRICApproved ? 'Approved' : 'Declined'}}</span>
            </p>

            <form action="#" autocomplete="off" class="vote-summary">
              <div class="row">
                <div class="col-sm-6 col-xs-12">

                  <div class="form-group">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Vote</th>
                          <th>Count</th>
                          <th>Percentage</th>
                        </tr>
                      </thead>
                      <tbody v-if="isShowFLRVotes || isShowVotes">
                        <tr v-for="(stat, index) in statData" :key="index">
                          <td>{{isShowFLRVotes || isShowVotes ? stat.rate : '?'}}</td>
                          <td>{{stat.result}}</td>
                          <td>{{stat.percent.toFixed(0)}}%</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td class="text-right">Total:</td>
                          <td>{{total}}</td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <h4 class="header-title text-primary">Questions</h4>
                  <p v-if="!anyQuestion">No Question</p>

                  <div class="form-group" v-if="organizations.length > 0">
                    <label for="organization" class="col-form-label">
                      Organization:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in organizations" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`organization`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="orgC[index]" class="voting-summary-checkbox" />
                            <label :for="`organization`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : orgC[index]}">{{vote.organization}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                        <div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="teams.length > 0">
                    <label for="team" class="col-form-label">
                      Team:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in teams" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`team`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="temC[index]" class="voting-summary-checkbox" />
                            <label :for="`team`+vote.votingId">
                                <span class="vote-question" :class="{'del-text' : temC[index]}">{{vote.team}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="strategys.length > 0">
                    <label for="strategy" class="col-form-label">
                      Strategy:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in strategys" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`strategy`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="strC[index]" class="voting-summary-checkbox" />
                            <label :for="`strategy`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : strC[index]}">{{vote.strategy}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="processes.length > 0">
                    <label for="process" class="col-form-label">
                      Process:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in processes" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`process`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="proC[index]" class="voting-summary-checkbox" />
                            <label :for="`process`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : proC[index]}">{{vote.process}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="markets.length > 0">
                    <label for="market" class="col-form-label">
                      Market:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in markets" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`market`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="marC[index]" class="voting-summary-checkbox" />
                            <label :for="`market`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : marC[index]}">{{vote.market}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="performances.length > 0">
                    <label for="performance" class="col-form-label">
                      Performance:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in performances" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`performance`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="perC[index]" class="voting-summary-checkbox" />
                            <label :for="`performance`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : perC[index]}">{{vote.performance}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="termses.length > 0">
                    <label for="terms" class="col-form-label">
                      Terms:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in termses" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`terms`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="trmC[index]" class="voting-summary-checkbox" />
                            <label :for="`terms`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : trmC[index]}">{{vote.terms}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="generalPartners.length > 0">
                    <label for="terms" class="col-form-label">
                      General Partner:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in generalPartners" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`generalPartner`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="gnpC[index]" class="voting-summary-checkbox" />
                            <label :for="`generalPartner`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : gnpC[index]}">{{vote.generalPartner}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="trackRecords.length > 0">
                    <label for="organization" class="col-form-label">
                      Track Record:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in trackRecords" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`organization`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="trkC[index]" class="voting-summary-checkbox" />
                            <label :for="`organization`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : trkC[index]}">{{vote.trackRecord}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                        <div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="companys.length > 0">
                    <label for="companys" class="col-form-label">
                      Company:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in companys" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`company`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="comC[index]" class="voting-summary-checkbox" />
                            <label :for="`company`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : comC[index]}">{{vote.company}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="transactionsz.length > 0">
                    <label for="terms" class="col-form-label">
                      Transaction:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in transactionsz" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`transactions`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="traC[index]" class="voting-summary-checkbox" />
                            <label :for="`transactions`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : traC[index]}">{{vote.transactions}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="valueCreationPlans.length > 0">
                    <label for="terms" class="col-form-label">
                      Value Creation Plan:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in valueCreationPlans" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`valueCreationPlan`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="valC[index]" class="voting-summary-checkbox" />
                            <label :for="`valueCreationPlan`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : valC[index]}">{{vote.valueCreationPlan}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="targetMarkets.length > 0">
                    <label for="terms" class="col-form-label">
                      Target Market:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in targetMarkets" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`targetMarket`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="tarC[index]" class="voting-summary-checkbox" />
                            <label :for="`targetMarket`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : tarC[index]}">{{vote.targetMarket}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="portfolios.length > 0">
                    <label for="terms" class="col-form-label">
                      Portfolio:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in portfolios" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`portfolio`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="porC[index]" class="voting-summary-checkbox" />
                            <label :for="`portfolio`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : porC[index]}">{{vote.portfolio}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="potentialReturnsz.length > 0">
                    <label for="terms" class="col-form-label">
                      Potential Returns:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in potentialReturnsz" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`potentialReturns`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="potC[index]" class="voting-summary-checkbox" />
                            <label :for="`potentialReturns`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : potC[index]}">{{vote.potentialReturns}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group" v-if="others.length > 0">
                    <label for="other" class="col-form-label">
                      Other:
                    </label>
                    <ul>
                      <li v-for="(vote, index) in others" :key="vote.votingId">
                        <div class="checkbox-primary" :class="{'checkbox' : !isFinal}">
                            <input :id="`other`+vote.votingId" type="checkbox" v-if="!isFinal" v-model="othC[index]" class="voting-summary-checkbox" />
                            <label :for="`other`+vote.votingId">
                              <span class="vote-question" :class="{'del-text' : othC[index]}">{{vote.other}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                            </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <hr v-if="missingVotes.length > 0">
                  <div class="form-group" v-if="missingVotes.length > 0">
                    <h4 class="header-title m-t-0 text-primary">Missing Votes: (Committee Members)</h4>
                    <ol>
                      <li v-for="mis in missingVotes" :key="mis.userId">
                        {{mis.fullName}}
                       </li>
                    </ol>
                  </div>

                </div>

                <div class="col-sm-6 col-xs-12" v-if="isShowFLRVotes || isShowVotes">
                  <h4 class="text-center">All Members</h4>
                  <div class="d-flex justify-content-center">
                    <pie-chart :chartData="pieData" />
                  </div>

                  <h4 class="text-center mt-5">Committee Members</h4>
                  <div class="d-flex justify-content-center">
                    <pie-chart :chartData="pieData3" />
                  </div>

                  <div class="mt-4">
                      <table class="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>
                              Committee Member
                            </th>
                            <th>
                              Vote
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="2" v-if="icVotes.length == 0" class="text-center">
                              No Voting to Show
                            </td>
                          </tr>
                          <tr v-for="(vote, index) in icVotes" :key="index">
                            <td>
                              <AppUser :userId="vote.createdBy" :show="'text'" :showpic="true"/>
                            </td>
                            <td>
                              {{vote.score}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  </div>                  
                </div>

              </div>
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <div class="form-group text-right ml-3">
                    <router-link :to="{name: 'FundsDetail', params: {id}}" class="btn btn-secondary waves-effect btn-custom mr-2">
                        Back
                    </router-link>
                    <template v-if="!hideButtons">
                      <button v-if="!isShowVotes" class="btn btn-primary btn-custom waves-effect w-md waves-light mr-2" @click.prevent="showVotes" :disabled="disableButtons">
                        <span v-if="disableButtons" class="dynamic-tooltip" v-tooltip="disableMessage"></span>
                        <i class="mdi mdi-eye-outline"></i> <span>Display Votes</span>
                      </button>
                      <button v-else-if="!isFLRICFinalized" class="btn btn-primary btn-custom waves-effect w-md waves-light" @click.prevent="finalize" :disabled="disableButtons">
                        <span v-if="disableButtons" class="dynamic-tooltip" v-tooltip="disableMessage"></span>
                        <i class="mdi mdi-approval"></i> <span>Close Voting</span>
                      </button>
                      <button v-else-if="canCreateNewVersion" class="btn btn-primary btn-custom waves-effect w-md waves-light mr-2" @click.prevent="createVersion" :disabled="disableButtons">
                        <span v-if="disableButtons" class="dynamic-tooltip" v-tooltip="disableMessage"></span>
                        <span>Create New Version</span>
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { FundVotingService, FundTeamService } from '@/services/fund-service'
import PieChart from '@/components/charts/pieChart'
import { mapGetters } from 'vuex'

const delay = (time) => new Promise((resolve, reject) => setTimeout(resolve, time))

export default {
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  components: {
    PieChart
  },
  data () {
    return {
      votes: null,
      versions: [],
      versionId:'',
      canFinalize: false,
      isShowVotes: false,
      dealTeam: [],
      missingVotes: {},
      statData: [],
      counts: [0, 0, 0, 0, 0],
      counts3: [0, 0, 0, 0, 0],
      pieData: null,
      pieData3: null,
      orgC: [],
      trkC: [],
      temC: [],
      strC: [],
      proC: [],
      marC: [],
      perC: [],
      trmC: [],
      gnpC: [],
      comC: [],
      traC: [],
      valC: [],
      tarC: [],
      porC: [],
      potC: [],
      othC: []
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    createChart (response) {
      this.votes = response.data.votings
      this.versions = response.data.versions
      this.makeDataSet()
      let chartData = []
      let chartData3 = []
      let chartColor = []
      let chartColor3 = []
      let chartLabel = []
      let chartLabel3 = []
      for (let i = 0; i < 5; i++) {
        if (this.counts[i] > 0) {
          chartData.push(this.counts[i])
          chartLabel.push(i + 1)
          chartColor.push(this.getChartColor(i))
        }
      }
      for (let i = 0; i < 5; i++) {
        if (this.counts3[i] > 0) {
          chartData3.push(this.counts3[i])
          chartLabel3.push(i + 1)
          chartColor3.push(this.getChartColor(i))
        }
      }
      this.pieData = {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColor
          }
        ],
        labels: chartLabel
      }
      this.pieData3 = {
        datasets: [
          {
            data: chartData3,
            backgroundColor: chartColor3
          }
        ],
        labels: chartLabel3
      }
    },
    async getListForVersion () {
      // FundVotingService.getFlrVersion(this.versionId)
      try {
        const [resSumm, resTeam] = await Promise.all([FundVotingService.getFlrVersion(this.versionId), FundTeamService.getByFundId(this.id)])
        if (!resSumm || resSumm.status !== 200 || !resTeam || resTeam.status !== 200) throw new Error('Could not load voting data')
        
        this.createChart(resSumm)
        this.checkData(resTeam)
        this.asyncDataStatus_fetched('RR Voting Summary')
        return
      } catch (error) {
        this.$snotify.error('Server Error! ' + error.message)
        throw error
      }
    },
    async getList () {
      try {
        const [resSumm, resTeam] = await Promise.all([FundVotingService.getFlrSummary(this.id), FundTeamService.getByFundId(this.id)])
        if (!resSumm || resSumm.status !== 200 || !resTeam || resTeam.status !== 200) throw new Error('Could not load voting data')
        
        this.createChart(resSumm)
        this.checkData(resTeam)
        this.asyncDataStatus_fetched('RR Voting Summary')
        return
      } catch (error) {
        this.$snotify.error('Server Error! ' + error.message)
        throw error
      }
    },
    makeDataSet () {
      const t = this.total
      for (let i = 1; i <= 5; i++) {
        const r = this.votes.filter(x => x.score === i).length
        if (r > 0) {
          this.counts[i - 1] = r
          this.statData.push({rate: i, result: r, percent: (r / t) * 100})
        }

        const r3 = this.votes.filter(x => x.score === i && x.roleId > 0).length
        if (r3 > 0) {
          this.counts3[i - 1] = r3
        }
      }
    },
    getChartColor (index) {
      let cc = null
      switch (index) {
        case 0:
          cc = '#ed553b'
          break
        case 1:
          cc = '#f6d55c'
          break
        case 2:
          cc = '#007177'
          break
        case 3:
          cc = '#5aa762'
          break
        case 4:
          cc = '#0071c1'
          break
      }
      return cc
    },
    checkData (response) {
      this.dealTeam = response.data
      this.canFinalize = this.dealTeam.filter(x => x.userId === this.user.userId && x.fundTeamRoleId === 1).length > 0 || this.user.roles.filter(x => x.roleId === this.$userRoles.BUSINESS_COMMITTEE).length > 0
      if (this.canFinalize && !this.isFinal) {
      FundVotingService.checkFlrFinalize(this.id).then((res) => this.missingVotes = res.data)
      }
    },
    createVersion () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want create new version? It cannot be undone!', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                FundVotingService.createFlrVersion(this.id)
                .then((response) => {
                  this.goToSummary('')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    finalize () {
      this.$snotify.clear()
      if (this.missingVotes.length > 0) {
        this.$snotify.warning('Deal is missing votes from Committee Members!')
      }

      this.$snotify.confirm('Are you sure you want to Close Voting? It cannot be undone!', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                FundVotingService.finalizeFlr(this.id)
                .then((response) => {
                  this.$snotify.clear()
                  this.$snotify.success('FLR Voting closed.')
                  if (response.data) {
                    this.$snotify.info('Fund FLR Approved!')
                  } else {
                    this.$snotify.info('Fund FLR Declined!')
                  }
                  return delay(1500)
                })
                .then(() => {
                  this.$snotify.remove(toast.id)
                  this.goToSummary(this.versionId)
                })
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    showVotes () {
      this.$snotify.clear()
      if (this.missingVotes.length > 0) {
        this.$snotify.warning('Deal is missing votes from Committee Members!')
      }

      this.$snotify.confirm('Are you sure you want to Display Votes? It cannot be undone!', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                FundVotingService.showFlrVotes(this.id)
                .then((response) => {
                  if (response.data) {
                    this.isShowVotes = true
                    this.$snotify.success('Done.')
                  }
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    goToSummary (vid) {
      this.versionId = vid
      this.votes= null
      this.versions= []      
      this.canFinalize= false
      this.isShowVotes= false
      this.missingVotes= {}
      this.statData= []
      this.counts= [0, 0, 0, 0, 0]
      this.counts3= [0, 0, 0, 0, 0]
      this.pieData= null
      this.pieData3= null
      this.orgC= []
      this.trkC= []
      this.temC= []
      this.strC= []
      this.proC= []
      this.marC= []
      this.perC= []
      this.trmC= []
      this.gnpC= []
      this.comC= []
      this.traC= []
      this.valC= []
      this.tarC= []
      this.porC= []
      this.potC= []
      this.othC= []
      
      if(this.versionId !='' && this.versionId.length > 0) {
        this.getListForVersion()
      } else {
        this.getList()
      }
    },
    handleMissingDealTeam (dealTeam) {
      // id deal team exists, or if their are no more actions to take, return 
      if ((dealTeam !== null && dealTeam.length > 0) || (this.isFinal && !this.canCreateNewVersion)) return
      this.$snotify.warning('This fund has no Deal Team.  Add a member before continuing', {
        timeout: 5000
      })
    }
  },
  async created () {    
    try {
      await this.getList()
      if (this.dealTeam === null || this.dealTeam.length < 1) this.handleMissingDealTeam(this.dealTeam)
    } catch (error) {
      console.error(error)
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    isFinal () {
      return (this.versionId !='' && this.versionId.length > 0) || this.isFLRICFinalized
    },
    canCreateNewVersion () {
      if (this.versionId.length > 0) {
        return false
      }
      return this.isFinal && this.votes.length > 0 && this.votes[0].stageId == 4
    },
    noVotes () {
      return this.votes === null || this.votes.length < 1
    },
    noDealTeam () {
      return this.dealTeam === null || this.dealTeam.length < 1
    },
    hideButtons () {
      return !this.canFinalize || this.versionId.length > 0
    },
    disableButtons() {
      return this.noVotes || this.noDealTeam
    },
    disableMessage () {
      return this.noVotes ? 'No votes to display' : this.noDealTeam ? 'Please add a member to the deal team first' : null
    },
    canDisplayVotes () {
      if (this.versionId.length > 0) {
        return false
      }
      return this.canFinalize && !this.isShowVotes
    },
    canCloseVoting () {
      if (this.versionId.length > 0) {
        return false
      }
      return this.canFinalize && this.isShowVotes && !this.isFLRICFinalized
    },
    versionStatus () {
      if (this.versionId.length > 0) {
        return 'INACTIVE'
      }
      return 'ACTIVE'
    },
    voteStatus () {
      if (this.versionId.length > 0) {
        return '(Closed)'
      }
      return this.isFinal ? '(Closed)' : ''
    },
    fundName () {
      if (this.votes != null && this.votes.length > 0) {
        return this.votes[0].fundName
      }
    },
    isFLRICFinalized () {
      if (this.votes != null && this.votes.length > 0) {
        return this.votes[0].isFLRICFinalized
      }
      return false
    },
    isFLRICApproved () {
      if (this.votes != null && this.votes.length > 0) {
        return this.votes[0].isFLRICApproved
      }
      return false
    },
    isShowFLRVotes () {
      if (this.votes != null && this.votes.length > 0) {
        this.isShowVotes = this.votes[0].isShowFLRVotes
        return this.votes[0].isShowFLRVotes
      }
      return false
    },
    total () {
      if (this.votes != null) {
        return this.votes.length
      }
      return 0
    },
    organizations () {
      if (this.votes != null) {
        return this.votes.filter(x => x.organization != null && x.organization.length > 0)
      }
      return []
    },
    trackRecords () {
      if (this.votes != null) {
        return this.votes.filter(x => x.trackRecord != null && x.trackRecord.length > 0)
      }
      return []
    },
    teams () {
      if (this.votes != null) {
        return this.votes.filter(x => x.team != null && x.team.length > 0)
      }
      return []
    },
    strategys () {
      if (this.votes != null) {
        return this.votes.filter(x => x.strategy != null && x.strategy.length > 0)
      }
      return []
    },
    processes () {
      if (this.votes != null) {
        return this.votes.filter(x => x.process != null && x.process.length > 0)
      }
      return []
    },
    markets () {
      if (this.votes != null) {
        return this.votes.filter(x => x.market != null && x.market.length > 0)
      }
      return []
    },
    performances () {
      if (this.votes != null) {
        return this.votes.filter(x => x.performance != null && x.performance.length > 0)
      }
      return []
    },
    termses () {
      if (this.votes != null) {
        return this.votes.filter(x => x.terms != null && x.terms.length > 0)
      }
      return []
    },
    generalPartners () {
      if (this.votes != null) {
        return this.votes.filter(x => x.generalPartner != null && x.generalPartner.length > 0)
      }
      return []
    },
    companys () {
      if (this.votes != null) {
        return this.votes.filter(x => x.company != null && x.company.length > 0)
      }
      return []
    },
    transactionsz () {
      if (this.votes != null) {
        return this.votes.filter(x => x.transactions != null && x.transactions.length > 0)
      }
      return []
    },
    valueCreationPlans () {
      if (this.votes != null) {
        return this.votes.filter(x => x.valueCreationPlan != null && x.valueCreationPlan.length > 0)
      }
      return []
    },
    targetMarkets () {
      if (this.votes != null) {
        return this.votes.filter(x => x.targetMarket != null && x.targetMarket.length > 0)
      }
      return []
    },
    portfolios () {
      if (this.votes != null) {
        return this.votes.filter(x => x.portfolio != null && x.portfolio.length > 0)
      }
      return []
    },
    potentialReturnsz () {
      if (this.votes != null) {
        return this.votes.filter(x => x.potentialReturns != null && x.potentialReturns.length > 0)
      }
      return []
    },
    others () {
      if (this.votes != null) {
        return this.votes.filter(x => x.other != null && x.other.length > 0)
      }
      return []
    },
    anyQuestion () {
      return this.organizations.length > 0 || this.teams.length > 0 || this.strategys.length > 0 || this.processes.length > 0 || this.markets.length > 0 || this.performances.length > 0 || this.termses.length > 0 || this.others.length > 0 || this.trackRecords.length > 0 || this.portfolios.length > 0
    },
    icVotes () {
      return this.votes.filter(x => x.roleId === this.$userRoles.INVESTMENT_COMMITTEE)
    }
  }
}
</script>

<style scoped>
.dynamic-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>