<template>
  <table class="table table-striped table-hover">
    <tbody>
      <tr v-if="members.length === 0">
        <td colspan="3" class="text-center">No Member to show - contact System Admin</td>
      </tr>
      <tr v-for="member in members"
          :key="member.userId">
          <td>
            <a @click.prevent="select(member.userId)" href="javascript:void(0)">
              <AppUserPic :userId="member.userId" :size="2" />
              {{userName(member.userId)}}
            </a>
          </td>
          <td class="action-col">
              <button class="btn btn-danger btn-sm btn-mini btn-icon" v-if="canEdit && members.length > 1" @click.prevent="onDelete(member.userId)" v-tooltip="'Delete'"><i class="mdi mdi-delete"></i></button>
          </td>
      </tr>
      <tr>
        <td colspan="3">
          <button class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addNew" v-if="canEdit"> <i class="mdi mdi-account-plus"></i> Add</button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { mapGetters } from 'vuex'
import { PortfolioTeamService } from '@/services/portfolio-service'

export default {
  props: {
    members: {
      type: Array,
      default: []
    },
    isEditMode: {
      required: true,
      type: Boolean
    },
    canEdit: {
      default: false,
      type: Boolean
    },
    portfolioId: {
      required: true,
      type: Number
    }
  },
  mixins: [rightbar],
  methods: {
    addNew () {
      this.hideRightbar()
      this.$emit('onClickNew')
    },
    select (id) {
      this.$emit('onSelect', id)
    },
    update (id) {
      this.$emit('onSelectUpdate', id)
    },
    onDelete (id) {
      if (this.members.length <= 1) {
        this.$snotify.warning('Last Member cannot be removed!')
        return
      }
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                const mem = {portfolioId: this.portfolioId, userId: id, createdBy: 0}
                PortfolioTeamService.remove(this.portfolioId, mem)
                .then((response) => {
                  if (response == null) {
                    this.$snotify.error('Server Error!')
                  } else {
                    const i = this.members.findIndex(x => x.userId === id)
                    this.members.splice(i, 1)
                    this.$snotify.success('Member Deleted.')
                  }
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    userName (userId) {
      let result = ''
      const usr = this.allUsers.find(e => e.userId === userId)
      if (this.userId === 0) {
        return 'System'
      }
      if (usr != null) {
        return usr.firstName + ' ' + usr.lastName
      }
      return result
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allUsers () {
      return this.$store.state.allUsers
    }
  }
}
</script>
