<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Assign Deal to a Meeting
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="calanderDeal">
      <div class="form-group">
          <label>Meeting </label>
          <div class="text-primary">
            {{calanderDeal.title}}
          </div>
      </div>

      <div class="form-group">
          <label>Start Date Time</label>
          <div class="text-white">
            {{startTime}}
          </div>
      </div>

      <div class="form-group">
          <label for="fundId">Deal <span class="text-danger">*</span></label>
          <span :class="{ 'invalid': $v.calanderDeal.fundId.$error }">
            <multiselect id="fundId" v-model="dealObj" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search"
              @input="dealSelected" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
          </span>
          <template v-if="$v.calanderDeal.fundId.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.calanderDeal.fundId.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>

      <div class="form-group">
          <label for="typeId">Meeting Purpose <span class="text-danger">*</span></label>
          <select id="typeId" class="form-control" v-model="calanderDeal.typeId" :class="{ 'parsley-error': $v.calanderDeal.typeId.$error }">
              <option :value="tp.typeId" v-for="tp in calMeetingTypes" :key="tp.typeId">{{tp.typeName}}</option>
          </select>
          <template v-if="$v.calanderDeal.typeId.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.calanderDeal.typeId.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Update</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { CalMeetingService } from '@/services/calmeeting-service'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      showLogs: false,
      searchFunds: [],
      isSearchingList: false,
      dealObj: null
    }
  },
  validations: {
    calanderDeal: {
      fundId: { required },
      typeId: { required }
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['searchAllFunds', 'fetchAllCalMeetingTypes']),
    close () {
      this.$v.calanderDeal.$reset()
      this.hideRightbar()
    },
    dealSelected (value, id) {
      this.calanderDeal.fundId = value ? value.fundId : null
    },
    onSave () {
      this.$v.calanderDeal.$touch()
      if (this.$v.calanderDeal.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      this.calanderDeal.fundId = this.dealObj.fundId
      CalMeetingService.assignDeal(this.calanderDeal.meetingId, this.calanderDeal)
      .then((response) => {
        this.calanderDeal.fundName = this.dealObj.fundName
        this.calanderDeal.typeName = this.typeName
        this.hideRightbar()
        this.$snotify.success('Meeting Updated.')
      }).catch(() => this.$snotify.error('Meeting has been updated before your submit! Please refesh and try again'))
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    }
  },
  created () {
    this.fetchAllCalMeetingTypes()
  },
  computed: {
    calMeetingTypes () {
      return this.$store.state.allCalMeetingTypes
    },
    typeName () {
      const t = this.calMeetingTypes.filter(x => x.typeId === this.calanderDeal.typeId)
      if (t.length > 0) {
        return t[0].typeName
      }
      return ''
    },
    calanderDeal () {
      return this.$store.state.rb_calanderDeal
    },
    startTime (item) {
      return moment(this.calanderDeal.startTime).format('M/D/YYYY, h:mm a')
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
