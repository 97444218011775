import Constants from '../constants.js'
import Axios from 'axios'

export const StrategyService = {
  getAll () {
    return Axios.get(Constants.API_URL + '/strategy/models')
  },
  getAllTree () {
    return Axios.get(Constants.API_URL + '/strategy')
  }
}

export const SectorService = {
  getAll () {
    return Axios.get(Constants.API_URL + '/sector')
  }
}

export const SubSectorService = {
  getAll () {
    return Axios.get(Constants.API_URL + '/subsector')
  }
}
