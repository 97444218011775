<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'funds-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="fund">

                    <FundToolbar :title="'Deal Details - '+fund.fundName"
                      :fundId="fund.fundId"
                      :stageId="currentState"
                      :showFlrVoting="fund.isStartFlrVoting && fund.isActive"
                      :showRrVoting="fund.isStartRrVoting && fund.isActive" 
                      :showAR="fund.allocationRestartRequired == false && ((fund.isActive && (isTeamMember || isLegal) && fund.initAllocation > 0) || fund.hasAllocation)"
                      :showIs="fund.fundTypeId == 1"
                      :showDDButtons="showDDButtons"
                      :legalDDStatus ="fund.legalDDStatus"
                      :operationsDDStatus ="fund.operationDDStatus"                     
                      :pinId="fund.pinId"
                      :inEditMode="inEditMode"
                      :canDelete="canbeDeleted && canbeEdited"
                      :canEdit="canbeEdited" :canStartScreening="canStartScreening" :canStartFlr="canStartFlr" :canStartAr="canStartAr" :isSaving="inSaving"
                      @onEdit="EditForm"
                      @onCancel="cancelForm"
                      @onDelete="deleteForm"
                      @onSave="saveForm"
                      @onNew="addNext"
                      @onPin="addPin"
                      @onPinOff="delPin"
                      @onStartFlr="startFlr"
                      @onStartAr="startAr"
                      @onStartScreening="startScreening"
                      @onLegalDDClicked="onLegalDDClicked"
                      @onOperationDDClicked="onOperationDDClicked"
                       />
                    <div class="row">
                        <form action="#" autocomplete="off" style="width:100%">
                            <div class="col-lg-12">
                                <!-- State Start -->
                                <div class="card-box">
                                    <ul class="nav nav-pills navtab-bg nav-justified">
                                        <li class="nav-item" :class="{'flag': currentState == 1}">
                                          <div class='flag-div'>
                                            <div v-if='currentState == 1' class='flag-back-top-1-2 flag-back-bottom-1-2'/>
                                            <a href="javascript:void(0)" class="nav-link text-muted" @click="setState(1)" :class="{'active flag-body-1-2': currentState == 1}">
                                                1-Sourcing
                                            </a>
                                            <div v-if='currentState == 1' class='flag-point-1-2'/>
                                          </div>
                                        </li>
                                        <li class="nav-item" :class="{'flag': currentState == 2}">
                                          <div class='flag-div'>
                                            <div v-if='currentState == 2' class='flag-back-top-1-2 flag-back-bottom-1-2'/>
                                            <a href="javascript:void(0)" class="nav-link text-muted" @click="setState(2)" :class="{'active flag-body-1-2': currentState == 2}">
                                                2-Screening
                                            </a>
                                            <div v-if='currentState == 2' class='flag-point-1-2'/>
                                          </div>
                                        </li>
                                        <li class="nav-item" :class="{'flag': currentState == 3}">
                                          <div class='flag-div'>
                                            <div v-if='currentState == 3' class='flag-back-top-3 flag-back-bottom-3'/>
                                            <a href="javascript:void(0)" class="nav-link text-muted" @click="setState(3)" :class="{'active flag-body-3 white-text': currentState == 3}">
                                                3-Preliminary DD
                                            </a>
                                            <div v-if='currentState == 3' class='flag-point-3'/>
                                          </div>
                                        </li>
                                        <li class="nav-item" :class="{'flag': currentState == 4}">
                                          <div class='flag-div'>
                                            <div v-if='currentState == 4' class='flag-back-top-4 flag-back-bottom-4'/>
                                            <a href="javascript:void(0)" class="nav-link text-muted" @click="setState(4)" :class="{'active flag-body-4 text-white': currentState == 4}">
                                                4-Full DD
                                            </a>
                                            <div v-if='currentState == 4' class='flag-point-4'/>
                                          </div>
                                        </li>
                                        <li class="nav-item" :class="{'flag': currentState == 5}">
                                          <div class='flag-div'>
                                            <div v-if='currentState == 5' class='flag-back-top-5 flag-back-bottom-5'/>
                                            <a href="javascript:void(0)" class="nav-link text-muted" @click="setState(5)" :class="{'active flag-body-5 white-text': currentState == 5}">
                                                5-Approved
                                            </a>
                                            <div v-if='currentState == 5' class='flag-point-5'/>
                                          </div>
                                        </li>
                                    </ul>
                                </div>
                                <!-- State End -->

                                <div class="card-box">
                                    <ul class="nav nav-tabs tabs-bordered">
                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link" @click="showTab(1)" :class="{'active': activeTab == 1, 'tab-error': smTabHasError}">
                                                <i class="mdi mdi-information-outline"></i> Summary
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link" @click="showTab(2)" :class="{'active': activeTab == 2, 'tab-error': gfTabHasError}">
                                                <i class="mdi mdi-coin"></i> General
                                            </a>
                                        </li>
                                        <li class="nav-item" v-if="fund.fundTypeId == 2 && fund.fundGroupId == 1">
                                            <a href="javascript:void(0)" class="nav-link" @click="showTab(7)" :class="{'active': activeTab == 7, 'tab-error': seTabHasError}">
                                                <i class="mdi mdi-numeric-2-box-outline"></i> Secondary
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link" @click="showTab(3)" :class="{'active': activeTab == 3, 'tab-error': isTabHasError}" v-if="fund.fundTypeId == 1 && fund.fundGroupId == 1">
                                                <i class="mdi mdi-chart-line-stacked"></i> Investment Summary
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link" @click="showTab(8)" :class="{'active': activeTab == 8, 'tab-error': coTabHasError}" v-if="fund.fundTypeId == 3 && fund.fundGroupId == 1">
                                                <i class="mdi mdi-set-center"></i> Co-Investment
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link" @click="showTab(10)" :class="{'active': activeTab == 10, 'tab-error': maTabHasError}" v-if="fund.fundGroupId == 2">
                                                <i class="mdi mdi-chart-line"></i> Investment Summary
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link" @click="showTab(4)" :class="{'active': activeTab == 4, 'tab-error': rdTabHasError}">
                                                <i class="mdi mdi-radar"></i> Radar
                                            </a>
                                        </li>
                                        <li class="nav-item" v-if="connectStats">
                                            <a href="javascript:void(0)" class="nav-link" @click="showTab(9)" :class="{'active': activeTab == 9, 'tab-error': rdTabHasError}">
                                                <i class="mdi mdi-copyright"></i> Connect
                                            </a>
                                        </li>
                                        <li class="nav-item" v-if="true">
                                            <!-- <a href="javascript:void(0)" class="nav-link" @click="showTab(11)" :class="{'active': activeTab == 11, 'tab-error': mnTabHasError}"> -->
                                            <a href="javascript:void(0)" class="nav-link" @click="openMonitoringTab" :class="{'active': activeTab == 11}">
                                                <i class="mdi mdi-radio-tower"></i> Monitoring
                                            </a>
                                        </li>
                                        <!-- <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link" @click="showTab(5)" :class="{'active': activeTab == 5}">
                                                <i class="mdi mdi-key"></i> Investment Authorization
                                            </a>
                                        </li> -->
                                        <!-- <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link" @click="showTab(6)" :class="{'active': activeTab == 6}">
                                                <i class="mdi mdi-radio-tower"></i> Monitoring
                                            </a>
                                        </li> -->
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 1}">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="fundName">Name <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <input type="text" v-model.trim="fund.fundName" @blur="$v.fund.fundName.$touch()" placeholder="Enter Name" class="form-control" id="fundName" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.fundName.$error }" :readonly="!canRename">
                                                        <template v-if="$v.fund.fundName.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.fundName.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.fundName.min" class="parsley-required">This value is too short</li>
                                                            <li v-if="!$v.fund.fundName.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="fundTypeId">Type <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <span :class="{ 'parsley-error-radio': $v.fund.fundTypeId.$error }">
                                                          <div class="custom-control custom-radio">
                                                            <input type="radio" id="type1" value=1 v-model="fund.fundTypeId" :disabled="!inEditMode || !canRename" name="type" class="custom-control-input">
                                                            <label class="custom-control-label" for="type1">Primary</label>
                                                          </div>
                                                          <div class="custom-control custom-radio">
                                                            <input type="radio" id="type2" value=2 v-model="fund.fundTypeId" :disabled="!inEditMode || !canRename" name="type" class="custom-control-input">
                                                            <label class="custom-control-label" for="type2">Secondary</label>
                                                          </div>
                                                          <div class="custom-control custom-radio">
                                                            <input type="radio" id="type3" value=3 v-model="fund.fundTypeId" :disabled="!inEditMode || !canRename" name="type" class="custom-control-input">
                                                            <label class="custom-control-label" for="type3">Co-Investment</label>
                                                          </div>
                                                        </span>
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Status</label>
                                                        <toggle-button v-model="fund.isActive" :sync="true" :disabled="!inEditMode" :width="65" :labels="{checked: 'Active', unchecked: 'Inactive'}"/>
                                                    </div>
                                                    
                                                    <div v-if="!fund.isActive">
                                                      <div class="form-group">
                                                          <label for="inactiveReasonId">Inactive Reason</label>
                                                          <multiselect id="inactiveReasonId" v-model="inactiveReasonObj" :disabled="!inEditMode" :options="allInactiveReasons" label="reasonName" track-by="reasonId" :allow-empty="false" @input="inactiveReasonSelected"></multiselect>
                                                      </div>
                                                      <div v-if="fund.inactiveReasonId === 1">
                                                          <div class="form-group">
                                                            <label for="inactiveDate">Decline Date</label>
                                                            <date-picker v-model="fund.inactiveDate" placeholder="Select Date" :disabled="!inEditMode" calendar-class="calendar form-control" input-class="form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                          </div>

                                                          <div class="form-group">
                                                            <label for="inactiveNotes">Decline Notes</label>
                                                            <textarea type="text" v-model="fund.inactiveNotes" :disabled="!inEditMode" class="form-control" id="inactiveNotes" />
                                                          </div>
                                                      </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="inactiveNotes">Target Portfolio</label>
                                                        <select class="form-control" v-model.number="fund.targetPortfolioId" :disabled="!inEditMode">
                                                            <option value="0">Both (Advisory and Discretionary)</option>
                                                            <option value="1">Advisory Only</option>
                                                            <option value="2">Discretionary Only</option>
                                                        </select>
                                                    </div>

                                                    <div class="form-group">
                                                      <label>Forward Calendar</label>
                                                      <toggle-button v-model="fund.isForwardcalendar" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!inEditMode"/>
                                                    </div>

                                                    <div class="form-group">
                                                      <label>Wilshire Connect</label>
                                                      <toggle-button v-model="fund.isConnect" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="isConnectAdmin"/>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="OprCode">Operation Code</label>
                                                        <input type="text" v-model.trim="fund.oprCode" placeholder="Enter Operation Code" class="form-control" id="OprCode" disabled="disabled" @blur="$v.fund.oprCode.$touch()" :class="{ 'parsley-error': $v.fund.oprCode.$error }">
                                                        <template v-if="$v.fund.oprCode.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.oprCode.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="PreqinId">Preqin Code</label>
                                                        <a class="btn btn-primary btn-sm btn-mini btn-icon" :href="`https://pro.preqin.com/funds/`+fund.preqinId" target="_blank" v-if="fund.preqinId > 0">
                                                            <i class="mdi mdi-open-in-new" v-tooltip="'Show in preqin'"></i>
                                                        </a>
                                                        <input type="text" v-model.number="fund.preqinId" placeholder="Enter Preqin Code" class="form-control" id="PreqinId" disabled="disabled">
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="PitchbookId">Pitchbook Code</label>
                                                          <a class="btn btn-primary btn-sm btn-mini btn-icon" :href="`https://my.pitchbook.com/profile/`+fund.pitchbookId+`/fund/profile`" target="_blank" v-if="fund.pitchbookId != null">
                                                              <i class="mdi mdi-open-in-new" v-tooltip="'Show in pitchbook'"></i>
                                                          </a>
                                                        <input type="text" v-model.trim="fund.pitchbookId" placeholder="Enter Pitchbook Code" class="form-control" id="PitchbookId" disabled="disabled" @blur="$v.fund.pitchbookId.$touch()" :class="{ 'parsley-error': $v.fund.pitchbookId.$error }">
                                                        <template v-if="$v.fund.pitchbookId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.pitchbookId.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                                                    <div :class="{'collapse': !showLogs }">
                                                      <div class="form-group">
                                                          <label>Created On</label>
                                                          <AppDate :date="fund.createdOn" :show="'input'"/>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>Created By</label>
                                                          <AppUser :userId="fund.createdBy" />
                                                      </div>
                                                      <div class="form-group" v-if="fund.updatedOn">
                                                          <label>Modified On</label>
                                                          <AppDate :date="fund.updatedOn" :show="'input'"/>
                                                      </div>
                                                      <div class="form-group" v-if="fund.updatedOn">
                                                          <label>Modified By</label>
                                                          <AppUser :userId="fund.updatedBy" :emptyIfNull="true"/>
                                                      </div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                  <ul class="nav nav-tabs tabs-bordered">
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeSTab == 1}" @click="showSTab(1)">
                                                              Notes <span class="badge badge-primary noti-icon-badge" v-if="activities">{{activities.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeSTab == 3}" @click="showSTab(3)">
                                                              Documents <span class="badge badge-primary noti-icon-badge" v-if="fundFiles">{{fundFiles.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeSTab == 2}" @click="showSTab(2)">
                                                              Votes <span class="badge badge-primary noti-icon-badge" v-if="fundVotes">{{fundVotes.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeSTab == 4}" @click="showSTab(4)">
                                                              Minutes <span class="badge badge-primary noti-icon-badge" v-if="linkMeetings">{{linkMeetings.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeSTab == 6}" @click="showSTab(6)">
                                                              Meetings <span class="badge badge-primary noti-icon-badge" v-if="linkCalMeetings">{{linkCalMeetings.length}}</span>
                                                          </a>
                                                      </li>
                                                  </ul>
                                                  <div class="tab-content">
                                                      <div class="tab-pane fade" :class="{'show active': activeSTab == 1}">
                                                        <span v-if="isLoadingActivities">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                        <ActivityList :activities="activities" :isEditMode="inEditMode" @onUpdate="onActivityUpdate" @onClickNew="clickAddActivity" @onSelect="showActivity" @onSelectUpdate="editActivity" />
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeSTab == 2}">
                                                        <span v-if="isLoadingFundVotes">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-hover">
                                                            <FundVoteList :votes="fundVotes" :stage="fund.stageId" :fundId="id" :isStartFlrVoting="fund.isStartFlrVoting" :isStartRrVoting="fund.isStartRrVoting" :isActive="fund.isActive" :isEditMode="inEditMode" :isFlrFinalized="fund.isFLRICFinalized" :isRrFinalized="fund.isRRICFinalized" :canStop="isAdmin"
                                                              @onCancelVote="clickCancelVote(1)" @onCancelVote2="clickCancelVote(2)"
                                                              @onClickNew="clickAddVoting(1)" @onClickNew2="clickAddVoting(2)"
                                                              @onSelect="showVoting" @onSelect2="showVoting2"
                                                              @onSelectUpdate="updateVoting" @onSelectUpdate2="updateVoting2"
                                                            />
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeSTab == 3}">
                                                        <span v-if="isLoadingFundFiles">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                        <FileList :files="fundFiles" :isEditMode="inEditMode" :canEdit="canbeEdited" @onUpdate="onFileUpdate" @onClickNew="clickAddFile" @onSelect="showFile" @onSelectUpdate="editFile" />
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeSTab == 4}">
                                                        <span v-if="isLoadinglinkMeetings">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Meeting Date <i class="mdi mdi-open-in-new"></i></th>
                                                                  <th>Type</th>
                                                                </tr>
                                                              </thead>
                                                            <FundMeeetingsList :meetings="linkMeetings" />
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeSTab == 6}">
                                                        <span v-if="isLoadinglinkCalMeetings">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Date<i class="mdi mdi-open-in-new"></i></th>
                                                                  <th># Attendees</th>
                                                                  <th>Avg. Score</th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                <tr v-if="linkCalMeetings === null">
                                                                  <td class="text-center" colspan="3">No Meeting to show</td>
                                                                </tr>
                                                                <tr v-for="(item, index) in linkCalMeetings" :key="index">
                                                                  <td>
                                                                    <router-link :to="{name: 'CalendarDetail', params: {id: item.meetingId}}" target="_blank">
                                                                      <AppDate :date="item.startTime" :show="'label'"/>
                                                                    </router-link>
                                                                  </td>
                                                                  <td>{{item.cnt}}</td>
                                                                  <td>{{item.scoreAvg}}</td>
                                                                </tr>
                                                              </tbody>
                                                          </table>
                                                      </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 2}">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="generalPartnerId">General Partner<span v-show="inEditMode" class="text-danger">*</span>
                                                          <router-link :to="{name: 'FirmsDetail', params: {id: fund.generalPartnerId}}" target="_blank">
                                                              <i class="mdi mdi-open-in-new" v-tooltip="'Open firm details'"></i>
                                                          </router-link>
                                                        </label>
                                                        <input type="text" v-model="fund.generalPartner" class="form-control" id="generalPartnerId" v-show="!inEditMode" readonly="readonly">
                                                        <span :class="{ 'invalid': $v.fund.generalPartnerId.$error }" v-show="inEditMode">
                                                          <multiselect id="generalPartnerId" v-model="generalPartnerObj" :options="searchFirms" label="firmName" track-by="firmId" :allow-empty="false" placeholder="Type to Search"
                                                            @input="gpFirmSelected" @search-change="asyncSearchFirm" :loading="isSearchingList" :options-limit="50"></multiselect>
                                                        </span>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="targetFLRDate">Target FLR Date</label>
                                                        <date-picker v-model="fund.targetFLRDate" @input="checkCalander(fund.targetFLRDate)" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="inEditMode" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" ></date-picker>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="targetRRDate">Target RR Date</label>
                                                        <date-picker v-model="fund.targetRRDate" @input="checkCalander(fund.targetRRDate)" @blur="$v.fund.targetRRDate.$touch()" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="inEditMode" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.targetRRDate.$error }">
                                                        </date-picker>
                                                        <template v-if="$v.fund.targetRRDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.targetRRDate.validRRdate" class="parsley-required">Please enter a valid date</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="dealCurrencyId">Deal Currency <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <multiselect id="dealCurrencyId" v-model="dealCurrencyObj" :disabled="!inEditMode" :options="allCurrencies" label="currencyName" track-by="currencyId" :allow-empty="false" @open="$v.fund.dealCurrencyId.$touch()" @input="dealCurrencySelected" :class="{ 'form-control p-0 parsley-error': $v.fund.dealCurrencyId.$error }"></multiselect>
                                                        <template v-if="$v.fund.dealCurrencyId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.dealCurrencyId.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="fundTierId">Fund Tier <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <multiselect id="fundTierId" v-model="fundTierObj" :disabled="!inEditMode" :options="allTires" label="fundTierName" track-by="fundTierId" :allow-empty="true" @open="$v.fund.fundTierId.$touch()" @input="tireSelected" :class="{ 'form-control p-0 parsley-error': $v.fund.fundTierId.$error }"></multiselect>
                                                        <template v-if="$v.fund.fundTierId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.fundTierId.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group" v-if="fund.fundTypeId < 3">
                                                      <label>Open Ended</label>
                                                      <toggle-button v-model="fund.isOpenEnded" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!inEditMode" @input="openEnded"/>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="expectedstartdate">Expected Start Date<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <date-picker id="expectedstartdate" v-model="fund.expectedStartDate" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="inEditMode" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.expectedStartDate.$error }"></date-picker>
                                                        <template v-if="$v.fund.expectedStartDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="expectedclosedate">Expected Close Date <span v-show="inEditMode && !fund.isOpenEnded" class="text-danger">*</span></label>
                                                        <date-picker id="expectedclosedate" v-model="fund.expectedCloseDate" :disabled="!inEditMode || fund.isOpenEnded" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="inEditMode" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.expectedCloseDate.$error }"></date-picker>
                                                        <template v-if="$v.fund.expectedCloseDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.expectedCloseDate.required" class="parsley-required">This value is required.</li>
                                                            <li v-if="!$v.fund.expectedCloseDate.validCloseDate" class="parsley-required">Please enter a valid date</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="vintageYear">Vintage Year<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <input type="text" v-model.number="fund.vintageYear" :disabled="!inEditMode" placeholder="Enter Vintage Year" class="form-control" id="vintageYear" @blur="$v.fund.vintageYear.$touch()" :class="{ 'parsley-error': $v.fund.vintageYear.$error }">
                                                        <template v-if="$v.fund.vintageYear.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.vintageYear.required" class="parsley-required">This value is required.</li>
                                                            <li v-if="!$v.fund.vintageYear.min" class="parsley-required">Vintage year must be 1980 or later</li>
                                                            <li v-if="!$v.fund.vintageYear.numeric" class="parsley-required">This value is not valid.</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                      <span :class="{ 'invalid': $v.fund.sectorId.$error }" v-if="this.quarterTree">
                                                      <label for="tree-strategy">Strategy<span v-show="inEditMode" class="text-danger">*</span></label>
                                                      <Treeselect :class="{ 'form-control p-0 parsley-error': $v.fund.sectorId.$error }" @input="strategySelected" id="tree-strategy" :options="fullStrategiesTree" :disabled="!inEditMode" v-model="strategyObj" :disable-branch-nodes="true" :showCount="true" placeholder="Strategy">
                                                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
                                                      </Treeselect>
                                                      </span>
                                                      <template v-if="$v.fund.sectorId.$error">
                                                        <ul class="parsley-errors-list filled">
                                                          <li v-if="!$v.fund.sectorId.required" class="parsley-required">This value is required</li>
                                                        </ul>
                                                      </template>
                                                    </div>

                                                    <span v-if="fund.fundTypeId < 3">
                                                      <div class="form-group">
                                                          <label for="sectorDescription">Sector Description<span v-show="inEditMode" class="text-danger">*</span></label>
                                                          <textarea v-model="fund.sectorDescription" @blur="$v.fund.sectorDescription.$touch()" :disabled="!inEditMode" placeholder="Enter Sector Description" class="form-control" id="sectorDescription" :class="{ 'parsley-error': $v.fund.sectorDescription.$error }" rows="3"></textarea>
                                                          <template v-if="$v.fund.sectorDescription.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.sectorDescription.required" class="parsley-required">This value is required</li>
                                                              <li v-if="!$v.fund.sectorDescription.min" class="parsley-required">This value is too short</li>
                                                              <li v-if="!$v.fund.sectorDescription.max" class="parsley-required">This value is too long</li>
                                                            </ul>
                                                          </template>
                                                      </div>
                                                    </span>
                                                    <span v-else>
                                                      <div class="form-group">
                                                          <label for="investmentStrategy">Investment Strategy<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                          <textarea type="text" v-model="fund.investmentStrategy" :disabled="!inEditMode" placeholder="Enter Investment Strategy" class="form-control" id="investmentStrategy" rows="5" @blur="$v.fund.investmentStrategy.$touch()" :class="{ 'parsley-error': $v.fund.investmentStrategy.$error }"/>
                                                          <template v-if="$v.fund.investmentStrategy.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.investmentStrategy.min" class="parsley-required">This value is required</li>
                                                              <li v-if="!$v.fund.investmentStrategy.max" class="parsley-required">This value is too long</li>
                                                            </ul>
                                                          </template>
                                                      </div>
                                                      <div class="form-group">
                                                          <label for="trackRecordSummary">Track Record Summary<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                          <button type="button" class="btn btn-primary btn-sm btn-mini" v-show="inEditMode && needTrim(fund.trackRecordSummary)" @click.prevent="fund.trackRecordSummary = trimText(fund.trackRecordSummary)">
                                                              <i class="mdi mdi-flash-auto"></i>
                                                          </button>
                                                          <textarea type="text" v-model="fund.trackRecordSummary" :disabled="!inEditMode" placeholder="Enter Track Record Summary" class="form-control" id="trackRecordSummary" @blur="$v.fund.trackRecordSummary.$touch()" :class="{ 'parsley-error': $v.fund.trackRecordSummary.$error }" rows="5"></textarea>
                                                          <template v-if="$v.fund.trackRecordSummary.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.trackRecordSummary.required" class="parsley-required">This value is required</li>
                                                              <li v-if="!$v.fund.trackRecordSummary.max" class="parsley-required">This value is too long</li>
                                                            </ul>
                                                          </template>
                                                      </div>
                                                    </span>

                                                    <div class="form-group">
                                                        <label for="geographicFocusId">Geographic Focus<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <span :class="{ 'invalid': $v.fund.geographicFocusId.$error }">
                                                          <multiselect id="geographicFocusId" v-model="geographicFocusObj" :disabled="!inEditMode" :options="allGeoFocuses" label="geographicName" track-by="geographicId" :allow-empty="false" @input="geoFocusSelected"></multiselect>
                                                        </span>
                                                        <template v-if="$v.fund.geographicFocusId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.geographicFocusId.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <span v-if="fund.fundTypeId < 3">
                                                      <div class="form-group">
                                                        <label for="targetSize">Target Size (M)<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.targetSize" :disabled="!inEditMode || fund.targetSizeNa" @blur="$v.fund.targetSize.$touch()" placeholder="Enter Target Size" class="form-control" id="targetSize" :class="{ 'parsley-error': $v.fund.targetSize.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.targetSize.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.targetSize.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.targetSize.decimal && $v.fund.targetSize.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.targetSize.min" class="parsley-required">This value is not valid</li>
                                                          </ul>
                                                        </template>
                                                        <div class="checkbox-primary checkbox" v-show="fund.targetSize == 0 || fund.targetSize == null">
                                                            <input id='targetSizeNa' type="checkbox" v-model="fund.targetSizeNa" />
                                                            <label for='targetSizeNa'>NA</label>
                                                        </div>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="hardCap">Hard Cap (M)</label>
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">{{symbol}}</span>
                                                              </div>
                                                              <input type="text" v-model.number="fund.hardCap" :disabled="!inEditMode" class="form-control" id="hardCap">
                                                          </div>
                                                      </div>

                                                      <div class="form-group" v-if="fund.fundTypeId < 3">
                                                        <label>Access Constrained</label>
                                                        <toggle-button v-model="fund.isAccessConstrained" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!inEditMode"/>
                                                      </div>
                                                    </span>

                                                    <div class="form-group">
                                                      <label>Social Responsible, ESG and/or Impact Focus</label>
                                                      <toggle-button v-model="fund.isSocialResponsible" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!inEditMode"/>
                                                      <div class='mt-2' v-if="fund.isSocialResponsible">
                                                        <span :class="{ 'parsley-error-radio': $v.fund.esgType.$error }">
                                                          <div class="custom-control custom-radio">
                                                            <input type="radio" id="social-check" value=1 v-model="fund.esgType" name="Social" class="custom-control-input" :disabled="!inEditMode">
                                                            <label class="custom-control-label" for="social-check">Social</label>
                                                          </div>
                                                          <div class="custom-control custom-radio">
                                                            <input type="radio" id="env-check" value=2 v-model="fund.esgType" name="Environmental" class="custom-control-input" :disabled="!inEditMode">
                                                            <label class="custom-control-label" for="env-check">Environmental</label>
                                                          </div>
                                                          <div class="custom-control custom-radio">
                                                            <input type="radio" id="SEG-check" value=3 v-model="fund.esgType" name="SEG" class="custom-control-input" :disabled="!inEditMode">
                                                            <label class="custom-control-label" for="SEG-check">Social, Environmental and/or Governance</label>
                                                          </div>
                                                          <div class="custom-control custom-radio">
                                                            <input type="radio" id="other-check" value=4 v-model="fund.esgType" name="Other" class="custom-control-input" :disabled="!inEditMode">
                                                            <label class="custom-control-label" for="other-check">Other</label>
                                                          </div>
                                                          <div class="input-group mt-2" v-if='fund.esgType == 4'>
                                                            <textarea class="form-control" id="other-text" placeholder="Explain" maxlength=250 rows="1" v-model="fund.esgTypeOther" @blur="$v.fund.esgTypeOther.$touch()" :class="{ 'parsley-error': $v.fund.esgTypeOther.$error }"></textarea>
                                                          </div>
                                                        </span>
                                                        <template v-if="$v.fund.esgTypeOther.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="$v.fund.esgTypeOther.$error" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                      </div>
                                                    </div>

                                                    <span v-if="fund.fundTypeId != 3">
                                                      <div class="form-group">
                                                        <label>Client Sourced</label>
                                                        <toggle-button v-model="fund.isClientSourced" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!inEditMode"/>
                                                      </div>

                                                      <div class="form-group">
                                                        <label>First Institutional Fund</label>
                                                        <toggle-button v-model="fund.isFirstInstitutionalFund" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!inEditMode"/>
                                                      </div>

                                                      <div class="form-group">
                                                        <label>Alternative Yield</label>
                                                        <toggle-button v-model="fund.isAltYield" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!inEditMode"/>
                                                      </div>
                                                    </span>

                                                    <div class="form-group" v-if="fund.fundTypeId != 2">
                                                        <label for="placementAgentId">Placement Agent</label>
                                                        <input type="text" v-model="fund.placementAgent" class="form-control" id="placementAgentId" v-show="!inEditMode" readonly="readonly">
                                                        <multiselect id="placementAgentId" v-model="placementAgentObj" :disabled="!inEditMode" :options="searchParners" label="firmName" track-by="firmId" v-show="inEditMode"
                                                          @input="placementAgentSelected" @search-change="asyncSearchPartner" :loading="isSearchingList" :options-limit="50"></multiselect>
                                                    </div>

                                                    <span v-if="currentState > 4">
                                                      <div class="form-group">
                                                          <label for="monitoringTypeId">Monitoring Type</label>
                                                          <multiselect id="monitoringTypeId" v-model="monitoringTypeObj" :disabled="!inEditMode" :options="allMonitoringtypes" label="monitoringTypeName" track-by="monitoringTypeId" @input="monitoringTypeSelected"></multiselect>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="wpmBoardSeatId">WPM Board Seat</label>
                                                          <multiselect id="wpmBoardSeatId" v-model="wpmBoardSeatObj" :disabled="!inEditMode" :options="allBoardseats" label="boardSeatName" track-by="boardSeatId" :allow-empty="true" @input="wpmBoardSeatSelected"></multiselect>
                                                      </div>
                                                    </span>

                                                    <!-- <span v-if="fund.fundTypeId == 3">
                                                      <div class="form-group">
                                                          <label for="ParentFundId">Parent Fund</label>
                                                          <input type="text" v-model="fund.parentFund" class="form-control" id="parentFundId" v-show="!inEditMode" readonly="readonly">
                                                          <span :class="{ 'invalid': $v.fund.parentFundId.$error }" v-show="inEditMode">
                                                            <multiselect id="ParentFundId" v-model="parentFundObj" :disabled="!inEditMode" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="true" placeholder="Type to Search"
                                                              @select="fundSelected" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                                                          </span>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="LtmRevenue">LTM Revenue (M) <span v-show="inEditMode" class="text-danger">*</span></label>
                                                          <div class="form-inline">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">{{symbol}}</span>
                                                                </div>
                                                                <input id="LtmRevenue" type="text" v-model.number="fund.ltmRevenue" :disabled="!inEditMode" class="form-control" @blur="$v.fund.ltmRevenue.$touch()" placeholder="Enter LTM Revenue" :class="{ 'parsley-error': $v.fund.ltmRevenue.$error }">
                                                            </div>
                                                            <select class="form-control" v-model="fund.ltmRevenueTypeId" :disabled="!inEditMode">
                                                                <option value=""></option>
                                                                <option value="1">LTM</option>
                                                                <option value="2">RR</option>
                                                                <option value="3">NTM</option>
                                                            </select>
                                                            <template v-if="$v.fund.ltmRevenue.$error || $v.fund.ltmRevenueTypeId.$error">
                                                              <ul class="parsley-errors-list filled">
                                                                <li v-if="!$v.fund.ltmRevenue.required || !$v.fund.ltmRevenueTypeId.required" class="parsley-required">This value is required</li>
                                                                <li v-if="!$v.fund.ltmRevenue.decimal && $v.fund.ltmRevenue.required" class="parsley-required">This value is not valid</li>
                                                                <li v-if="!$v.fund.ltmRevenue.min" class="parsley-required">This value is not valid</li>
                                                                <li v-if="!$v.fund.ltmRevenue.max" class="parsley-required">This value is too big</li>
                                                              </ul>
                                                            </template>
                                                          </div>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="LtmEbitda">LTM EBITDA (M) <span v-show="inEditMode" class="text-danger">*</span></label>
                                                          <div class="form-inline">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">{{symbol}}</span>
                                                                </div>
                                                                <input id="LtmEbitda" type="text" v-model.number="fund.ltmEbitda" :disabled="!inEditMode" class="form-control" @blur="$v.fund.ltmEbitda.$touch()" placeholder="Enter LTM EBITDA" :class="{ 'parsley-error': $v.fund.ltmEbitda.$error }">
                                                            </div>
                                                            <select class="form-control" v-model="fund.ltmEbitdaTypeId" :disabled="!inEditMode">
                                                                <option value=""></option>
                                                                <option value="1">LTM</option>
                                                                <option value="2">RR</option>
                                                                <option value="3">NTM</option>
                                                            </select>
                                                            <template v-if="$v.fund.ltmEbitda.$error || $v.fund.ltmEbitdaTypeId.$error">
                                                              <ul class="parsley-errors-list filled">
                                                                <li v-if="!$v.fund.ltmEbitda.required || !$v.fund.ltmEbitdaTypeId.required" class="parsley-required">This value is required</li>
                                                                <li v-if="!$v.fund.ltmEbitda.decimal && $v.fund.ltmEbitda.required" class="parsley-required">This value is not valid</li>
                                                                <li v-if="!$v.fund.ltmEbitda.max" class="parsley-required">This value is too big</li>
                                                              </ul>
                                                            </template>
                                                          </div>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="EnterpriseValue">Enterprise Value (M) <span v-show="inEditMode" class="text-danger">*</span></label>
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">{{symbol}}</span>
                                                              </div>
                                                              <input id="EnterpriseValue" type="text" v-model.number="fund.enterpriseValue" :disabled="!inEditMode" class="form-control" @blur="$v.fund.enterpriseValue.$touch()" placeholder="Enter Enterprise Value" :class="{ 'parsley-error': $v.fund.enterpriseValue.$error }">
                                                          </div>
                                                          <template v-if="$v.fund.enterpriseValue.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.enterpriseValue.required" class="parsley-required">This value is required</li>
                                                              <li v-if="!$v.fund.enterpriseValue.decimal && $v.fund.enterpriseValue.required" class="parsley-required">This value is not valid</li>
                                                              <li v-if="!$v.fund.enterpriseValue.min" class="parsley-required">This value is not valid</li>
                                                              <li v-if="!$v.fund.enterpriseValue.max" class="parsley-required">This value is too big</li>
                                                            </ul>
                                                          </template>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="TotalCompanyDebt">Total Company Debt (M) <span v-show="inEditMode" class="text-danger">*</span></label>
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">{{symbol}}</span>
                                                              </div>
                                                              <input id="TotalCompanyDebt" type="text" v-model.number="fund.totalCompanyDebt" :disabled="!inEditMode" class="form-control" @blur="$v.fund.totalCompanyDebt.$touch()" placeholder="Enter Enterprise Value" :class="{ 'parsley-error': $v.fund.totalCompanyDebt.$error }">
                                                          </div>
                                                          <template v-if="$v.fund.totalCompanyDebt.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.totalCompanyDebt.required" class="parsley-required">This value is required</li>
                                                              <li v-if="!$v.fund.totalCompanyDebt.decimal && $v.fund.totalCompanyDebt.required" class="parsley-required">This value is not valid</li>
                                                              <li v-if="!$v.fund.totalCompanyDebt.min" class="parsley-required">This value is not valid</li>
                                                              <li v-if="!$v.fund.totalCompanyDebt.max" class="parsley-required">This value is too big</li>
                                                            </ul>
                                                          </template>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="TotalCompanyEquity">Total Company Equity (M) <span v-show="inEditMode" class="text-danger">*</span></label>
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">{{symbol}}</span>
                                                              </div>
                                                              <input id="TotalCompanyEquity" type="text" v-model.number="fund.totalCompanyEquity" :disabled="!inEditMode" class="form-control" @blur="$v.fund.totalCompanyEquity.$touch()" placeholder="Enter Enterprise Value" :class="{ 'parsley-error': $v.fund.totalCompanyEquity.$error }">
                                                          </div>
                                                          <template v-if="$v.fund.totalCompanyEquity.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.totalCompanyEquity.required" class="parsley-required">This value is required</li>
                                                              <li v-if="!$v.fund.totalCompanyEquity.decimal && $v.fund.totalCompanyEquity.required" class="parsley-required">This value is not valid</li>
                                                              <li v-if="!$v.fund.totalCompanyEquity.min" class="parsley-required">This value is not valid</li>
                                                              <li v-if="!$v.fund.totalCompanyEquity.max" class="parsley-required">This value is too big</li>
                                                            </ul>
                                                          </template>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="amountOffered">Amount Offered All LPs (M) <span v-show="inEditMode" class="text-danger">*</span></label>
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">{{symbol}}</span>
                                                              </div>
                                                              <input id="amountOffered" type="text" v-model.number="fund.amountOffered" :disabled="!inEditMode" @blur="$v.fund.amountOffered.$touch()" placeholder="Enter Amount Offered All LPs" class="form-control" :class="{ 'parsley-error': $v.fund.amountOffered.$error }">
                                                          </div>
                                                          <template v-if="$v.fund.amountOffered.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.amountOffered.required" class="parsley-required">This value is required</li>
                                                              <li v-if="!$v.fund.amountOffered.min" class="parsley-required">This value is not valid.</li>
                                                              <li v-if="!$v.fund.amountOffered.max" class="parsley-required">This value is too big</li>
                                                            </ul>
                                                          </template>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="amountOfferedWpm">Amount Offered WPM (M) <span v-show="inEditMode" class="text-danger">*</span></label>
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">{{symbol}}</span>
                                                              </div>
                                                              <input id="amountOfferedWpm" type="text" v-model.number="fund.amountOfferedWpm" :disabled="!inEditMode" @blur="$v.fund.amountOfferedWpm.$touch()" placeholder="Enter Amount Offered WPM" class="form-control" :class="{ 'parsley-error': $v.fund.amountOfferedWpm.$error }">
                                                          </div>
                                                          <template v-if="$v.fund.amountOfferedWpm.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.amountOfferedWpm.required" class="parsley-required">This value is required</li>
                                                              <li v-if="!$v.fund.amountOfferedWpm.min" class="parsley-required">This value is not valid.</li>
                                                              <li v-if="!$v.fund.amountOfferedWpm.max" class="parsley-required">This value is too big</li>
                                                            </ul>
                                                          </template>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="managementFeePercent">Management Fee - Investment Period</label>
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">%</span>
                                                              </div>
                                                              <input type="text" v-model.number="fund.managementFeePercent" :disabled="!inEditMode" placeholder="Enter Management Fee - Investment Period" class="form-control" id="managementFeePercent" @blur="$v.fund.managementFeePercent.$touch()" :class="{ 'parsley-error': $v.fund.managementFeePercent.$error }">
                                                          </div>
                                                          <template v-if="$v.fund.managementFeePercent.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.managementFeePercent.required" class="parsley-required">This value is required</li>
                                                              <li v-if="!$v.fund.managementFeePercent.min" class="parsley-required">This value is not valid.</li>
                                                              <li v-if="!$v.fund.managementFeePercent.max" class="parsley-required">This value is too big</li>
                                                            </ul>
                                                          </template>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="managementFeeStructureId">Management Fee Structure</label>
                                                          <span :class="{ 'invalid': $v.fund.managementFeeStructureId.$error }">
                                                            <multiselect id="managementFeeStructureId" v-model="managementFeeStructureObj" :disabled="!inEditMode" :options="allMngfeestructures" label="structureName" track-by="structureId" @input="managementFeeStructureSelected"></multiselect>
                                                          </span>
                                                          <template v-if="$v.fund.managementFeeStructureId.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.managementFeeStructureId.required" class="parsley-required">This value is required</li>
                                                            </ul>
                                                          </template>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="carriedInterestPercent">Carried Interest</label>
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">%</span>
                                                              </div>
                                                              <input type="text" v-model.number="fund.carriedInterestPercent" :disabled="!inEditMode" placeholder="Enter Carried Interest" class="form-control" id="carriedInterestPercent" @blur="$v.fund.carriedInterestPercent.$touch()" :class="{ 'parsley-error': $v.fund.carriedInterestPercent.$error }">
                                                          </div>
                                                          <template v-if="$v.fund.carriedInterestPercent.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.carriedInterestPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                              <li v-if="!$v.fund.carriedInterestPercent.min" class="parsley-required">This value is not valid.</li>
                                                              <li v-if="!$v.fund.carriedInterestPercent.max && $v.fund.carriedInterestPercent.decimal" class="parsley-required">This value can not be greater than 100.</li>
                                                            </ul>
                                                          </template>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="carriedInterestDesc">Carried Interest - Waterfall Type</label>
                                                          <input type="text" v-model="fund.carriedInterestDesc" :disabled="!inEditMode" placeholder="Enter Carried Interest Description" class="form-control" id="carriedInterestDesc" @blur="$v.fund.carriedInterestDesc.$touch()" :class="{ 'parsley-error': $v.fund.carriedInterestDesc.$error }">
                                                          <template v-if="$v.fund.carriedInterestDesc.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.carriedInterestDesc.max" class="parsley-required">This value is too long</li>
                                                            </ul>
                                                          </template>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="preferredReturnPercent">Preferred Return</label>
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">%</span>
                                                              </div>
                                                              <input type="text" v-model.number="fund.preferredReturnPercent" :disabled="!inEditMode" placeholder="Enter Preferred Return" class="form-control" id="preferredReturnPercent" @blur="$v.fund.preferredReturnPercent.$touch()" :class="{ 'parsley-error': $v.fund.preferredReturnPercent.$error }">
                                                          </div>
                                                          <template v-if="$v.fund.preferredReturnPercent.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.preferredReturnPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                              <li v-if="!$v.fund.preferredReturnPercent.max && $v.fund.preferredReturnPercent.decimal" class="parsley-required">This value can not be greater than 100.</li>
                                                            </ul>
                                                          </template>
                                                      </div>

                                                      <div class="form-group">
                                                          <label for="dealDescription">Deal Description <span v-show="inEditMode" class="text-danger">*</span></label>
                                                          <textarea id="dealDescription" v-model.trim="fund.dealDescription" :disabled="!inEditMode" @blur="$v.fund.dealDescription.$touch()" placeholder="Enter Deal Description" class="form-control" :class="{ 'parsley-error': $v.fund.dealDescription.$error }" rows="5" />
                                                          <template v-if="$v.fund.dealDescription.$error">
                                                            <ul class="parsley-errors-list filled">
                                                              <li v-if="!$v.fund.dealDescription.required" class="parsley-required">This value is required</li>
                                                              <li v-if="!$v.fund.dealDescription.max" class="parsley-required">This value is too long</li>
                                                            </ul>
                                                          </template>
                                                      </div>
                                                    </span> -->

                                                </div>
                                                <div class="col-lg-6">
                                                  <ul class="nav nav-tabs tabs-bordered">
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeGTab == 1, 'tab-error': dealTeamHasError}" @click="showGTab(1)">
                                                              Deal Team <span class="badge badge-primary noti-icon-badge" v-if="fundTeams">{{fundTeams.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeGTab == 2}" @click="showGTab(2)">
                                                              On-site Meetings <span class="badge badge-primary noti-icon-badge" v-if="fundOnsiteMeetings">{{fundOnsiteMeetings.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeGTab == 3}" @click="showGTab(3)">
                                                              Portfolios <span class="badge badge-primary noti-icon-badge" v-if="linkPortfolios">{{linkPortfolios.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeGTab == 4}" @click="showGTab(4)">
                                                              Allocation Rationales <span class="badge badge-primary noti-icon-badge" v-if="allocationRationales">{{allocationRationales.length}}</span>
                                                          </a>
                                                      </li>
                                                  </ul>
                                                  <div class="tab-content">
                                                      <div class="tab-pane fade" :class="{'show active': activeGTab == 1}">
                                                        <span v-if="isLoadingFundTeams">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Employee <span v-show="currentState > 1 && inEditMode" class="text-danger">*</span></th>
                                                                  <th>Role</th>
                                                                  <th class="action-col"></th>
                                                                </tr>
                                                              </thead>
                                                              <FundTeamList :members="fundTeams" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddTeamMember" @onSelect="showTeamMember" @onSelectUpdate="editTeamMember" @onUpdate="onTeamMemberUpdate" />
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeGTab == 2}">
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Date</th>
                                                                  <th>City</th>
                                                                  <th class="action-col"></th>
                                                                </tr>
                                                              </thead>
                                                              <FundOnsiteMeetingList :meetings="fundOnsiteMeetings" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddFundOnsiteMeeting" @onSelect="showFundOnsiteMeeting" @onSelectUpdate="editFundOnsiteMeeting" @onUpdate="onFundOnsiteMeetingUpdate" />
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeGTab == 3}">
                                                        <span v-if="isLoadinglinkPortfolios">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Portfolio<i class="mdi mdi-open-in-new"></i></th>
                                                                  <th>Type</th>
                                                                  <th>Position</th>
                                                                  <th>Commitment (M)</th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                <tr v-if="linkPortfolios === null">
                                                                  <td class="text-center" colspan="4">No Portfolio to show</td>
                                                                </tr>
                                                                <tr v-for="(item, index) in linkPortfolios" :key="index">
                                                                  <td>
                                                                    <router-link :to="{name: 'PortfolioDetail', params: {id: item.portfolioId}}" target="_blank">
                                                                        {{ item.portfolioName }}
                                                                    </router-link>
                                                                  </td>
                                                                  <td>{{item.typeName}}</td>
                                                                  <td>{{portolioFundPosition(item)}}</td>
                                                                  <td>
                                                                    <span v-if="item.filledPosition > 0">
                                                                      {{item.filledPosition}}
                                                                    </span>
                                                                    <span v-else>
                                                                      -
                                                                    </span>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeGTab == 4}">
                                                        <span v-if="isLoadingAllocationRationales">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                        <AllocationRationaleList :list="allocationRationales" :stageId="fund.stageId" :canEdit="canbeEdited" :isEditMode="inEditMode" @onSelect="showAllocationRationale" @onSelectUpdate="editAllocationRationale" @onUpdate="onAllocationRationaleItemUpdate" />
                                                      </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 3}">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="fundAbbreviation">Fund Abbreviation<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                        <input type="text" v-model="fund.fundAbbreviation" :disabled="!inEditMode" placeholder="Enter Fund Abbreviation" class="form-control" id="fundAbbreviation" @blur="$v.fund.fundAbbreviation.$touch()" :class="{ 'parsley-error': $v.fund.fundAbbreviation.$error }">
                                                        <template v-if="$v.fund.fundAbbreviation.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.fundAbbreviation.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.fundAbbreviation.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="firstCloseAmount">First Close Amount (M)<span v-show="inEditMode && currentState > 3 && fund.isActive && !firstCloseAmountNa" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.firstCloseAmount" :disabled="!inEditMode" placeholder="Enter First Close Amount" class="form-control" id="firstCloseAmount" @blur="$v.fund.firstCloseAmount.$touch()" :class="{ 'parsley-error': $v.fund.firstCloseAmount.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.firstCloseAmount.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.firstCloseAmount.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.firstCloseAmount.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.firstCloseAmount.min" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.firstCloseAmount.max" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                        <div class="checkbox-primary checkbox" v-show="inEditMode && fund.firstCloseAmount == 0">
                                                            <input id='firstCloseAmountNa' type="checkbox" v-model="firstCloseAmountNa" />
                                                            <label for='firstCloseAmountNa'>NA</label>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="firstCloseDate">First Close Date<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                        <date-picker v-model="fund.firstCloseDate" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.firstCloseDate.$error }"></date-picker>
                                                        <template v-if="$v.fund.firstCloseDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.firstCloseDate.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                        <div class="checkbox-primary checkbox" v-show="inEditMode && fund.firstCloseDate == null">
                                                            <input id='firstCloseDateNa' type="checkbox" v-model="firstCloseDateNa" />
                                                            <label for='firstCloseDateNa'>NA</label>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="finalCloseAmount">Final Close Amount (M)<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.finalCloseAmount" :disabled="!inEditMode" placeholder="Enter Final Close Amount" class="form-control" id="finalCloseAmount" @blur="$v.fund.finalCloseAmount.$touch()" :class="{ 'parsley-error': $v.fund.finalCloseAmount.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.finalCloseAmount.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.finalCloseAmount.required" class="parsley-required">This value is required.</li>
                                                            <li v-if="!$v.fund.finalCloseAmount.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.finalCloseAmount.max" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="investmentStrategy">Investment Strategy<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                        <button type="button" class="btn btn-primary btn-sm btn-mini" v-show="inEditMode && needTrim(fund.investmentStrategy)" @click.prevent="fund.investmentStrategy = trimText(fund.investmentStrategy)">
                                                            <i class="mdi mdi-flash-auto"></i>
                                                        </button>
                                                        <textarea type="text" v-model="fund.investmentStrategy" :disabled="!inEditMode" placeholder="Enter Investment Strategy" class="form-control" id="investmentStrategy" rows="5" @blur="$v.fund.investmentStrategy.$touch()" :class="{ 'parsley-error': $v.fund.investmentStrategy.$error }"/>
                                                        <template v-if="$v.fund.investmentStrategy.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.investmentStrategy.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.investmentStrategy.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="targetReturn">Target Return<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                        <input type="text" v-model="fund.targetReturn" :disabled="!inEditMode" placeholder="Enter Target Return" class="form-control" id="targetReturn" @blur="$v.fund.targetReturn.$touch()" :class="{ 'parsley-error': $v.fund.targetReturn.$error }">
                                                        <template v-if="$v.fund.targetReturn.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.targetReturn.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.targetReturn.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="investmentSizeRange">Investment Size Range<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                        <input type="text" v-model.trim="fund.investmentSizeRange" :disabled="!inEditMode" placeholder="Enter Investment Size Range" class="form-control" id="investmentSizeRange" @blur="$v.fund.investmentSizeRange.$touch()" :class="{ 'parsley-error': $v.fund.investmentSizeRange.$error }" />
                                                        <template v-if="$v.fund.investmentSizeRange.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.investmentSizeRange.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.investmentSizeRange.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="numberofInvestments">Number of Investments<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                        <input type="text" v-model.trim="fund.numberofInvestments" :disabled="!inEditMode" placeholder="Enter Number of Investments" class="form-control" id="numberofInvestments" @blur="$v.fund.numberofInvestments.$touch()" :class="{ 'parsley-error': $v.fund.numberofInvestments.$error }" />
                                                        <template v-if="$v.fund.numberofInvestments.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.numberofInvestments.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.numberofInvestments.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="investmentPeriod">Investment Period (Years)<span v-show="fund.isActive && currentState > 3 && inEditMode && !fund.isOpenEnded" class="text-danger">*</span></label>
                                                        <input type="text" v-model.number="fund.investmentPeriod" :disabled="!inEditMode" placeholder="Enter Investment Period" class="form-control" id="investmentPeriod" @blur="$v.fund.investmentPeriod.$touch()" :class="{ 'parsley-error': $v.fund.investmentPeriod.$error }" />
                                                        <template v-if="$v.fund.investmentPeriod.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.investmentPeriod.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.investmentPeriod.min" class="parsley-required">This value is too small.</li>
                                                            <li v-if="!$v.fund.investmentPeriod.max" class="parsley-required">This value is too big</li>
                                                            <li v-if="!$v.fund.investmentPeriod.numeric" class="parsley-required">This value is not valid. Use only numbers</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="fundTerm">Fund Term<span v-show="inEditMode && currentState > 3 && fund.isActive && !fund.isOpenEnded" class="text-danger">*</span></label>
                                                        <input type="text" v-model.trim="fund.fundTerm" :disabled="!inEditMode" placeholder="Enter Fund Term" class="form-control" id="fundTerm" @blur="$v.fund.fundTerm.$touch()" :class="{ 'parsley-error': $v.fund.fundTerm.$error }" />
                                                        <template v-if="$v.fund.fundTerm.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.fundTerm.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.fundTerm.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="gpCommitmentPercent">GP Commitment<span v-show="inEditMode && fund.isActive && currentState > 3" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.gpCommitmentPercent" :disabled="!inEditMode" placeholder="Enter GP Commitment" class="form-control" id="gpCommitmentPercent" @blur="$v.fund.gpCommitmentPercent.$touch()" :class="{ 'parsley-error': $v.fund.gpCommitmentPercent.$error }" />
                                                        </div>
                                                        <template v-if="$v.fund.gpCommitmentPercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.gpCommitmentPercent.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.gpCommitmentPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.gpCommitmentPercent.max" class="parsley-required">This value can not be greater than 100.</li>
                                                            <li v-if="!$v.fund.gpCommitmentPercent.min" class="parsley-required">This value is too small.</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                      <!-- primary -->
                                                        <label for="managementFeePercent">Management Fee - Investment Period<span v-show="inEditMode && fund.isActive" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.managementFeePercent" :disabled="!inEditMode" placeholder="Enter Management Fee - Investment Period" class="form-control" id="managementFeePercent" @blur="$v.fund.managementFeePercent.$touch()" :class="{ 'parsley-error': $v.fund.managementFeePercent.$error }" />
                                                        </div>
                                                        <template v-if="$v.fund.managementFeePercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.managementFeePercent.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.managementFeePercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.managementFeePercent.min" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.managementFeePercent.max" class="parsley-required">This value can not be greater than 100.</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="managementFeeStructureId">Management Fee Structure<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                        <span :class="{ 'invalid': $v.fund.managementFeeStructureId.$error }">
                                                          <multiselect id="managementFeeStructureId" v-model="managementFeeStructureObj" :disabled="!inEditMode" :options="allMngfeestructures" label="structureName" track-by="structureId" @input="managementFeeStructureSelected"></multiselect>
                                                        </span>
                                                        <template v-if="$v.fund.managementFeeStructureId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.managementFeeStructureId.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                      <!-- primary -->
                                                        <label for="carriedInterestPercent">Carried Interest<span v-show="inEditMode && fund.isActive" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.carriedInterestPercent" :disabled="!inEditMode" placeholder="Enter Carried Interest" class="form-control" id="carriedInterestPercent" @blur="$v.fund.carriedInterestPercent.$touch()" :class="{ 'parsley-error': $v.fund.carriedInterestPercent.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.carriedInterestPercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.carriedInterestPercent.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.carriedInterestPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.carriedInterestPercent.min" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.carriedInterestPercent.max && $v.fund.carriedInterestPercent.decimal" class="parsley-required">This value can not be greater than 100.</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="carriedInterestDesc">Carried Interest Description</label>
                                                        <input type="text" v-model="fund.carriedInterestDesc" :disabled="!inEditMode" placeholder="Enter Carried Interest Description" class="form-control" id="carriedInterestDesc" @blur="$v.fund.carriedInterestDesc.$touch()" :class="{ 'parsley-error': $v.fund.carriedInterestDesc.$error }" />
                                                        <template v-if="$v.fund.carriedInterestDesc.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.carriedInterestDesc.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                   
                                                    <div class="form-group">
                                                        <label for="preferredReturnPercent">Preferred Return<span v-show="inEditMode && fund.isActive && parseInt(fund.fundTypeId) === 1 && !preferredReturnPercentNA" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.preferredReturnPercent" :disabled="!inEditMode" placeholder="Enter Preferred Return" class="form-control" id="preferredReturnPercent" @blur="$v.fund.preferredReturnPercent.$touch()" :class="{ 'parsley-error': $v.fund.preferredReturnPercent.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.preferredReturnPercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.preferredReturnPercent.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.preferredReturnPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.preferredReturnPercent.min" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.preferredReturnPercent.max && $v.fund.preferredReturnPercent.decimal" class="parsley-required">This value can not be greater than 100.</li>
                                                          </ul>
                                                        </template>
                                                        <div class="checkbox-primary checkbox" v-show="inEditMode && fund.preferredReturnPercent == 0">
                                                            <input id='preferredReturnPercentNA' type="checkbox" v-model="preferredReturnPercentNA" />
                                                            <label for='preferredReturnPercentNA'>NA</label>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="trackRecordSummary">Track Record Summary<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                        <button type="button" class="btn btn-primary btn-sm btn-mini" v-show="inEditMode && needTrim(fund.trackRecordSummary)" @click.prevent="fund.trackRecordSummary = trimText(fund.trackRecordSummary)">
                                                            <i class="mdi mdi-flash-auto"></i>
                                                        </button>
                                                        <textarea type="text" v-model="fund.trackRecordSummary" :disabled="!inEditMode" placeholder="Enter Track Record Summary" class="form-control" id="trackRecordSummary" @blur="$v.fund.trackRecordSummary.$touch()" :class="{ 'parsley-error': $v.fund.trackRecordSummary.$error }" rows="5"></textarea>
                                                        <template v-if="$v.fund.trackRecordSummary.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.trackRecordSummary.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.trackRecordSummary.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="grossROI">Gross ROI (x)<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <input type="text" v-model.number="fund.grossROI" :disabled="!inEditMode" placeholder="Enter Gross ROI" class="form-control" id="grossROI" @blur="$v.fund.grossROI.$touch()" :class="{ 'parsley-error': $v.fund.grossROI.$error }">
                                                        <template v-if="$v.fund.grossROI.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.grossROI.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.grossROI.decimal" class="parsley-required">This value is not valid.</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="netROI">Net ROI (x)<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <input type="text" v-model.number="fund.netROI" :disabled="!inEditMode" placeholder="Enter Net ROI" class="form-control" id="netROI" @blur="$v.fund.netROI.$touch()" :class="{ 'parsley-error': $v.fund.netROI.$error }">
                                                        <template v-if="$v.fund.netROI.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.netROI.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.netROI.decimal" class="parsley-required">This value is not valid.</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="grossIRRPercent">Gross IRR</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.grossIRRPercent" :disabled="!inEditMode" placeholder="Enter Gross IRR" class="form-control" id="grossIRRPercent" @blur="$v.fund.grossIRRPercent.$touch()" :class="{ 'parsley-error': $v.fund.grossIRRPercent.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.grossIRRPercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.grossIRRPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.grossIRRPercent.max && $v.fund.grossIRRPercent.decimal" class="parsley-required">This value can not be greater than 500.</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="netIRRPercent">Net IRR</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.netIRRPercent" :disabled="!inEditMode" placeholder="Enter Net IRR" class="form-control" id="netIRRPercent" @blur="$v.fund.netIRRPercent.$touch()" :class="{ 'parsley-error': $v.fund.netIRRPercent.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.netIRRPercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.netIRRPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.netIRRPercent.max && $v.fund.netIRRPercent.decimal" class="parsley-required">This value can not be greater than 500.</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <label for="roiQuartile">ROI Quartile</label>
                                                        <multiselect id="roiQuartile" v-model="roiQuartileObj" :disabled="!inEditMode" :options="allQuartiles" label="quartileName" track-by="quartileId" @input="roiQuartileSelected"></multiselect>
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <label for="irrQuartile">IRR Quartile</label>
                                                        <multiselect id="irrQuartile" v-model="irrQuartileObj" :disabled="!inEditMode" :options="allQuartiles" label="quartileName" track-by="quartileId" @input="irrQuartileSelected"></multiselect>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="fundPerformanceSourceId">Fund Performance Data Source</label>
                                                        <multiselect id="fundPerformanceSourceId" v-model="fundPerformanceSourceObj" :disabled="!inEditMode" :options="allFundperformanceSource" label="sourceName" track-by="sourceId" @input="fundPerformanceSourceSelected"></multiselect>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="fundPerformanceDataAs">Fund Performance Data As of</label>
                                                        <date-picker v-model="fund.fundPerformanceDataAs" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" input-class="form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="symbol">Investment Summary Approved</label>
                                                        <toggle-button v-model="fund.isInvestmentSummaryApproved" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!inEditMode"/>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="investmentSummaryApprovedBy">Investment Summary Approved By</label>
                                                        <multiselect id="investmentSummaryApprovedBy" v-model="investmentSummaryApprovedObj" :disabled="!inEditMode" :options="userOptionsISApproved" label="fullName" track-by="userId" @input="investmentSummaryApprovedSelected"></multiselect>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="investmentSummaryApprovalDate">Investment Summary Approval Date </label>
                                                        <date-picker v-model="fund.investmentSummaryApprovalDate" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" input-class="form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                  <ul class="nav nav-tabs tabs-bordered">
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeITab == 1, 'tab-error': keySeniorHasError}" @click="showITab(1)">
                                                              Key Seniors <span class="badge badge-primary noti-icon-badge" v-if="keySeniors">{{keySeniors.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeITab == 2}" @click="showITab(2)">
                                                              Industry Focus <span class="badge badge-primary noti-icon-badge" v-if="industryFocuses">{{industryFocuses.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeITab == 3}" @click="showITab(3)">
                                                              Regional Focus <span class="badge badge-primary noti-icon-badge" v-if="regionalFocuses">{{regionalFocuses.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeITab == 4}" @click="showITab(4)">
                                                              Opp Set <span class="badge badge-primary noti-icon-badge" v-if="fundOpportunities">{{fundOpportunities.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeITab == 6}" @click="showITab(6)">
                                                              Track Record <span class="badge badge-primary noti-icon-badge" v-if="trackRecord.length > 0">{{trackRecord.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeITab == 5}" @click="showITab(5)">
                                                              Merits/Concerns <span class="badge badge-primary noti-icon-badge" v-if="investmentMeritsConcerns">{{investmentMeritsConcerns.length}}</span>
                                                          </a>
                                                      </li>
                                                  </ul>
                                                  <div class="tab-content">
                                                      <div class="tab-pane fade" :class="{'show active': activeITab == 1}">
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Full Name</th>
                                                                  <th class="action-col"></th>
                                                                </tr>
                                                              </thead>
                                                              <KeySeniorList :keySeniors="keySeniors" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddKeySenior" @onSelect="showKeySenior" @onSelectUpdate="editKeySenior" @onUpdate="onKeySeniorUpdate" />
                                                          </table>                                                            
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeITab == 2}">
                                                        <span v-if="isLoadingIndustryFocuses">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                              <tr>
                                                                  <th>Industry</th>
                                                                  <th>Group</th>
                                                                  <th class="action-col"></th>
                                                              </tr>
                                                              </thead>
                                                              <IndustryFocusList :industryFocuses="industryFocuses" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddIndustryFocus" @onSelect="showIndustryFocus" @onSelectUpdate="editIndustryFocus" @onUpdate="onIndustryFocusUpdate" />
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeITab == 3}">
                                                        <span v-if="isLoadingRegionalFocuses">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                              <tr>
                                                                  <th>Country</th>
                                                                  <th class="action-col"></th>
                                                              </tr>
                                                              </thead>
                                                              <RegionalFocusList :regionalFocuses="regionalFocuses" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddRegionalFocus" @onSelect="showRegionalFocus" @onSelectUpdate="editRegionalFocus" @onUpdate="onRegionalFocusUpdate"/>
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeITab == 4}">
                                                        <span v-if="isLoadingFundOpportunities">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Fund</th>
                                                                  <th class="action-col1"></th>
                                                                </tr>
                                                              </thead>
                                                              <FundOpportunityList :opportunities="fundOpportunities" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddOpportunity" @onSelect="showOpportunity" @onUpdate="onOpportunityUpdate"/>
                                                          </table>                                                            
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeITab == 5}">
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Description</th>
                                                                  <th>Type</th>
                                                                  <th class="action-col"></th>
                                                                </tr>
                                                              </thead>
                                                              <InvestmentMSList :meritsConcerns="investmentMeritsConcerns" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddMerit" @onSelect="showMerit" @onSelectUpdate="editMerit" @onUpdate="onMeritUpdate" />
                                                          </table>                                                            
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeITab == 6}">
                                                        <span v-if="isLoadingTrackRecord">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <div id="tr-data" class="form-group">
                                                            <div class="tab-pane fade show active">
                                                              <table class="table table-striped table-hover">
                                                                <thead>
                                                                  <tr id='tr-data'>
                                                                    <th>Fund Name</th>
                                                                    <th>Target Size</th>
                                                                    <th>Vintage Year</th>
                                                                    <th>Net ROI</th>
                                                                    <th>Net IRR</th>
                                                                    <th></th>
                                                                  </tr>
                                                                </thead>
                                                                <FundTrackRecordList :list="trackRecord" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddTrackRecord" @onSelect="showTrackRecord" @onUpdate="onTrackRecordUpdate" />
                                                              </table>
                                                            </div>
                                                          </div>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 4}">
                                            <div class="row" v-if="fund.fundTypeId == 1">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="organizationRadarScore">Organization Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.organizationRadarScore" id="organizationRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="teamRadarScore">Team Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.teamRadarScore" id="teamRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="strategyRadarScore">Strategy Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.strategyRadarScore" id="strategyRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="processRadarScore">Process Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.processRadarScore" id="processRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="marketRadarScore">Market Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.marketRadarScore" id="marketRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="performanceRadarScore">Performance Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.performanceRadarScore" id="performanceRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="termsRadarScore">Terms Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.termsRadarScore" id="termsRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group" v-if="currentState > 3">
                                                        <label for="esgRadarScore">ESG Radar Score<span v-show="currentState > 3 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.esgRadarScore" id="esgRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="d-flex justify-content-center">
                                                      <radar-chart v-if="activeTab == 4" :chartData="radar1Data" class="ml-3"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="fund.fundTypeId == 2">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="generalPartnerSecRadarScore">General Partner(s) Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.generalPartnerSecRadarScore" id="generalPartnerSecRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <label for="trackRecordSecRadarScore">Track Record Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.trackRecordSecRadarScore" id="trackRecordSecRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="transactionSecRadarScore">Transaction Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.transactionSecRadarScore" id="transactionSecRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="portfolioSecRadarScore">Portfolio Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.portfolioSecRadarScore" id="portfolioSecRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="potentialReturnsSecRadarScore">Potential Returns Radar Score<span v-show="currentState > 2 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.potentialReturnsSecRadarScore" id="potentialReturnsSecRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="d-flex justify-content-center">
                                                      <radar-chart v-if="activeTab == 4" :chartData="radar2Data" class="ml-3"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="fund.fundTypeId == 3">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="generalPartnerRadarScore">General Partner Radar Score<span v-show="currentState > 3 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.generalPartnerRadarScore" id="generalPartnerRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="companyRadarScore">Company Radar Score<span v-show="currentState > 3 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.companyRadarScore" id="companyRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="transactionRadarScore">Transaction Radar Score<span v-show="currentState > 3 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.transactionRadarScore" id="transactionRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="valueCreationPlanRadarScore">Value Creation Plan Radar Score<span v-show="currentState > 3 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.valueCreationPlanRadarScore" id="valueCreationPlanRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="targetMarketRadarScore">Target Market Radar Score<span v-show="currentState > 3 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.targetMarketRadarScore" id="targetMarketRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="potentialReturnsRadarScore">Potential Returns Radar Score<span v-show="currentState > 3 && inEditMode" class="text-danger">*</span></label>
                                                        <radar v-model="fund.potentialReturnsRadarScore" id="potentialReturnsRadarScore" :disabled="!inEditMode"></radar>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="d-flex justify-content-center">
                                                      <radar-chart v-if="activeTab == 4" :chartData="radar3Data" class="ml-3"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 5}">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="investmentLegalName">Investment Legal Name</label>
                                                        <input type="text" v-model="fund.investmentLegalName" :disabled="!inEditMode" placeholder="Enter Investment Legal Name" class="form-control" id="investmentLegalName">
                                                        <!-- <template v-if="$v.fund.investmentLegalName.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.investmentLegalName.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.investmentLegalName.min" class="parsley-required">This value is too short</li>
                                                            <li v-if="!$v.fund.investmentLegalName.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template> -->
                                                      </div>

                                                    <div class="form-group">
                                                        <label for="closingDate">Closing Date</label>
                                                        <date-picker v-model="fund.closingDate" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                        <!-- <template v-if="$v.fund.closingDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.closingDate.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template> -->
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Portfolio Disclosure</label>
                                                        <toggle-button v-model="fund.isPortfolioDisclosure" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!inEditMode" />
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="term">Term</label>
                                                        <textarea v-model="fund.term" :disabled="!inEditMode" placeholder="Enter Term" class="form-control" id="term" />
                                                        <!-- <template v-if="$v.fund.term.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.term.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.term.min" class="parsley-required">This value is too short</li>
                                                            <li v-if="!$v.fund.term.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template> -->
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="recallDisclosure">Recall Disclosure</label>
                                                        <textarea v-model="fund.recallDisclosure" :disabled="!inEditMode" placeholder="Enter Recall Disclosure" class="form-control" id="recallDisclosure" />
                                                        <!-- <template v-if="$v.fund.recallDisclosure.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.recallDisclosure.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.recallDisclosure.min" class="parsley-required">This value is too short</li>
                                                            <li v-if="!$v.fund.recallDisclosure.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template> -->
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="fundContactId">Fund Contact</label>
                                                        <!-- <input type="text" v-model="fund.fundContact" class="form-control" id="fundContactId" v-show="!inEditMode" readonly="readonly"> -->
                                                        <multiselect id="fundContactId" v-model="fundContactObj" :options="searchContacts" label="fullName" track-by="contactId" placeholder="Type to Search"
                                                          @input="fundContactSelected" @search-change="asyncSearchContact" :loading="isSearchingList" :options-limit="50"></multiselect>
                                                        <!-- <template v-if="$v.fund.fundContactId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.fundContactId.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template> -->
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="executionAuthorizedBy">Execution Authorized By</label>
                                                        <multiselect id="executionAuthorizedBy" v-model="executionAuthorizedObj" :disabled="!inEditMode" :options="allUsers" label="fullName" track-by="userId" @input="executionAuthorizedSelected"></multiselect>
                                                        <!-- <template v-if="$v.fund.executionAuthorizedBy.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.executionAuthorizedBy.min" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.executionAuthorizedBy.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template> -->
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="executionAuthorizationDate">Execution Authorization Date</label>
                                                        <date-picker v-model="fund.executionAuthorizationDate" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="legalFinalReviewedBy">Legal Final Reviewer</label>
                                                        <multiselect id="legalFinalReviewedBy" v-model="legalFinalReviewedObj" :disabled="!inEditMode" :options="allUsers" label="fullName" track-by="userId" @input="legalFinalReviewedSelected"></multiselect>
                                                        <!-- <span :class="{ 'invalid': $v.fund.legalFinalReviewedBy.$error }">
                                                        </span>
                                                        <template v-if="$v.fund.legalFinalReviewedBy.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.legalFinalReviewedBy.min" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.legalFinalReviewedBy.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template> -->
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="legalFinalReviewDate">Legal Final Review Date</label>
                                                        <date-picker v-model="fund.legalFinalReviewDate" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 6}">
                                            <!-- <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="">Projected Fund % Invested</label>
                                                        <input type="text" parsley-trigger="change"
                                                                placeholder="Enter " class="form-control" id="">
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="">Projected Uninvested ROI (x)</label>
                                                        <input type="text" parsley-trigger="change"
                                                                placeholder="Enter " class="form-control" id="">
                                                    </div>
                                                </div>
                                            </div> -->

                                            <!-- <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="card-box">
                                                        <ul class="nav nav-tabs tabs-bordered">
                                                            <li class="nav-item">
                                                                <a href="javascript:void(0)" class="nav-link" :class="{'active': activeMTab == 1}" @click="showMTab(1)">
                                                                    Internal Commentary
                                                                </a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a href="javascript:void(0)" class="nav-link" :class="{'active': activeMTab == 2}" @click="showMTab(2)">
                                                                    Underlying Investee Companies
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div class="tab-content">
                                                            <div class="tab-pane fade" :class="{'show active': activeMTab == 1}">
                                                                <table class="table table-striped table-hover">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Date</th>
                                                                        <th>Commentary</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>                                                            
                                                            </div>
                                                            <div class="tab-pane fade" :class="{'show active': activeMTab == 2}">
                                                                <table class="table table-striped table-hover">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Underlying Investee Company Firm</th>
                                                                        <th>Projected Exit Date</th>
                                                                        <th>Projected ROI</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th></th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="tab-pane fade" :class="{'show active': activeITab == 3}" @click="showITab(3)">
                                                                <table class="table table-striped table-hover">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Underlying Investee Company Firm</th>
                                                                        <th>Projected Exit Date</th>
                                                                        <th>Projected ROI</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th></th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>                                    
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 7}">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="placementAgentId">Placement Agent</label>
                                                        <input type="text" v-model="fund.placementAgent" class="form-control" id="placementAgentId" v-show="!inEditMode" readonly="readonly">
                                                        <multiselect id="placementAgentId" v-model="placementAgentObj" :disabled="!inEditMode" :options="searchParners" label="firmName" track-by="firmId" v-show="inEditMode"
                                                          @input="placementAgentSelected" @search-change="asyncSearchPartner" :loading="isSearchingList" :options-limit="50"></multiselect>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="secPreliminaryBidDate">Preliminary Bid Due Date</label>
                                                        <date-picker v-model="fund.secPreliminaryBidDate" id="secPreliminaryBidDate" @input="checkCalander(fund.secPreliminaryBidDate)" @blur="$v.fund.secPreliminaryBidDate.$touch()" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.secPreliminaryBidDate.$error }"></date-picker>
                                                        <template v-if="$v.fund.secPreliminaryBidDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.secPreliminaryBidDate.validPreliminaryBidDate" class="parsley-required">This value is required - Date cannot be after Close Date</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="secBindingBidDate">Binding Bid Due Date</label>
                                                        <date-picker v-model="fund.secBindingBidDate" id="secBindingBidDate" @input="checkCalander(fund.secBindingBidDate)" @blur="$v.fund.secBindingBidDate.$touch()" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.secBindingBidDate.$error }"></date-picker>
                                                        <template v-if="$v.fund.secBindingBidDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.secBindingBidDate.validBindingBidDate" class="parsley-required">This value is required - Date cannot be after Close Date</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="secPortfolioValuationDate">Portfolio Valuation Reference Date</label>
                                                        <date-picker v-model="fund.secPortfolioValuationDate" id="secPortfolioValuationDate" @input="checkCalander(fund.secPortfolioValuationDate)" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Pricing expectations at the total deal level</label>
                                                          <div class="form-inline">
                                                              <label class="mr-1">Min</label>
                                                              <div class="input-group">
                                                                  <div class="input-group-prepend">
                                                                      <span class="input-group-text">%</span>
                                                                  </div>
                                                                  <input type="text" v-model.number="fund.secPricingExpectationMin" placeholder="Enter Min %" class="form-control" id="fundName" :disabled="!inEditMode">
                                                              </div>
                                                              <label class="ml-2 mr-1">Max</label>
                                                              <div class="input-group">
                                                                  <div class="input-group-prepend">
                                                                      <span class="input-group-text">%</span>
                                                                  </div>
                                                                  <input type="text" v-model.number="fund.secPricingExpectationMax" placeholder="Enter Max %" class="form-control" id="fundName" :disabled="!inEditMode">
                                                              </div>
                                                          </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Strip Available</label>
                                                        <toggle-button v-model="fund.secIsStrip" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Whole Portfolio Only</label>
                                                        <toggle-button v-model="fund.secIsWholePortfolio" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Staple Required</label>
                                                        <toggle-button v-model="fund.secIsStaple" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                  <ul class="nav nav-tabs tabs-bordered">
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeDTab == 1}" @click="showDTab(1)">
                                                              Funds <span class="badge badge-primary noti-icon-badge" v-if="secfunds">{{secfunds.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeDTab == 2}" @click="showDTab(2)">
                                                              Industry Focus <span class="badge badge-primary noti-icon-badge" v-if="industryFocuses">{{industryFocuses.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeDTab == 3}" @click="showDTab(3)">
                                                              Regional Focus <span class="badge badge-primary noti-icon-badge" v-if="regionalFocuses">{{regionalFocuses.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeDTab == 4}" @click="showDTab(4)">
                                                              Merits/Concerns <span class="badge badge-primary noti-icon-badge" v-if="investmentMeritsConcerns">{{investmentMeritsConcerns.length}}</span>
                                                          </a>
                                                      </li>
                                                  </ul>
                                                  <div class="tab-content">
                                                      <div class="tab-pane fade" :class="{'show active': activeDTab == 1}">
                                                        <span v-if="isLoadingSecfunds">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                        <SecondaryFundsList :funds="secfunds" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddSecFunds" @onSelect="showSecFunds" @onUpdate="onSecFundsUpdate" @onAfterUpdate="secFundsAfterUpdate" />
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeDTab == 2}">
                                                        <span v-if="isLoadingIndustryFocuses">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                              <tr>
                                                                  <th>Industry</th>
                                                                  <th>Group</th>
                                                                  <th class="action-col"></th>
                                                              </tr>
                                                              </thead>
                                                              <IndustryFocusList :industryFocuses="industryFocuses" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddIndustryFocus" @onSelect="showIndustryFocus" @onSelectUpdate="editIndustryFocus" @onUpdate="onIndustryFocusUpdate" />
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeDTab == 3}">
                                                        <span v-if="isLoadingRegionalFocuses">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                              <tr>
                                                                  <th>Country</th>
                                                                  <th class="action-col"></th>
                                                              </tr>
                                                              </thead>
                                                              <RegionalFocusList :regionalFocuses="regionalFocuses" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddRegionalFocus" @onSelect="showRegionalFocus" @onSelectUpdate="editRegionalFocus" @onUpdate="onRegionalFocusUpdate"/>
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeITab == 4}">
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Description</th>
                                                                  <th>Type</th>
                                                                  <th class="action-col"></th>
                                                                </tr>
                                                              </thead>
                                                              <InvestmentMSList :meritsConcerns="investmentMeritsConcerns" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddMerit" @onSelect="showMerit" @onSelectUpdate="editMerit" @onUpdate="onMeritUpdate" />
                                                          </table>                                                            
                                                      </div>                                                      
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 8}">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="alias">Alias <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <input type="text" v-model.trim="fund.alias" class="form-control" id="alias" :disabled="!inEditMode" @blur="$v.fund.alias.$touch()" :class="{ 'parsley-error': $v.fund.alias.$error }" />
                                                        <template v-if="$v.fund.alias.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.alias.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.alias.max && $v.fund.alias.required" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="LtmRevenue">LTM Revenue (M) <span v-show="inEditMode && currentState > 3 && parseInt(fund.fundTypeId) === 3" class="text-danger">*</span></label>
                                                        <div class="form-inline">
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">{{symbol}}</span>
                                                              </div>
                                                              <input id="LtmRevenue" type="text" v-model.number="fund.ltmRevenue" @blur="$v.fund.ltmRevenue.$touch()" :disabled="!inEditMode" class="form-control" placeholder="Enter LTM Revenue" :class="{ 'parsley-error': $v.fund.ltmRevenue.$error }">
                                                          </div>
                                                          <select class="form-control" v-model="fund.ltmRevenueTypeId" @blur="$v.fund.ltmRevenueTypeId.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.ltmRevenueTypeId.$error }" >
                                                              <option value=""></option>
                                                              <option value="1">LTM</option>
                                                              <option value="2">RR</option>
                                                              <option value="3">NTM</option>
                                                          </select>
                                                        </div>
                                                        <template v-if="$v.fund.ltmRevenue.$error || $v.fund.ltmRevenueTypeId.$error">
                                                              <ul class="parsley-errors-list filled">
                                                                <li v-if="!$v.fund.ltmRevenue.required || !$v.fund.ltmRevenueTypeId.min || !$v.fund.ltmRevenueTypeId.required" class="parsley-required">Both values are required</li>
                                                                <li v-if="!$v.fund.ltmRevenue.min || !$v.fund.ltmRevenue.decimal && $v.fund.ltmRevenue.required" class="parsley-required">This value is not valid</li>
                                                                <li v-if="$v.fund.ltmRevenue.decimal && !$v.fund.ltmRevenue.max" class="parsley-required">This value is too big</li>
                                                              </ul>
                                                            </template>
                                                    </div>

                                                    <div class="form-group"> 
                                                        <label for="LtmEbitda">LTM EBITDA (M) <span v-show="inEditMode && currentState > 3 && parseInt(fund.fundTypeId) === 3" class="text-danger">*</span></label>
                                                        <div class="form-inline">
                                                          <div class="input-group">
                                                              <div class="input-group-prepend">
                                                                  <span class="input-group-text">{{symbol}}</span>
                                                              </div>
                                                              <input id="LtmEbitda" type="text" v-model.number="fund.ltmEbitda" :disabled="!inEditMode" class="form-control" @blur="$v.fund.ltmEbitda.$touch()" placeholder="Enter LTM EBITDA" :class="{ 'parsley-error': $v.fund.ltmEbitda.$error }">
                                                          </div>
                                                          <select class="form-control" v-model="fund.ltmEbitdaTypeId" :disabled="!inEditMode" @blur='$v.fund.ltmEbitdaTypeId.$touch()' :class="{ 'parsley-error': $v.fund.ltmEbitdaTypeId.$error }">
                                                              <option value=""></option>
                                                              <option value="1">LTM</option>
                                                              <option value="2">RR</option>
                                                              <option value="3">NTM</option>
                                                          </select>
                                                        </div>
                                                        <template v-if="$v.fund.ltmEbitda.$error || $v.fund.ltmEbitdaTypeId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.ltmEbitda.required || !$v.fund.ltmEbitdaTypeId.min || !$v.fund.ltmEbitdaTypeId.required" class="parsley-required">Both values are required</li>
                                                            <li v-if="!$v.fund.ltmEbitda.decimal && $v.fund.ltmEbitda.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="$v.fund.ltmEbitda.decimal && !$v.fund.ltmEbitda.max" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>
<!-- bookmark -->

                                                    <div class="form-group">
                                                        <label for="EnterpriseValue">Enterprise Value (M) <span v-show="inEditMode && currentState > 3 && parseInt(fund.fundTypeId) === 3" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input id="EnterpriseValue" type="text" v-model.number="fund.enterpriseValue" :disabled="!inEditMode" class="form-control" @blur="$v.fund.enterpriseValue.$touch()" placeholder="Enter Enterprise Value" :class="{ 'parsley-error': $v.fund.enterpriseValue.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.enterpriseValue.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.enterpriseValue.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.enterpriseValue.decimal && $v.fund.enterpriseValue.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.enterpriseValue.min" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.enterpriseValue.max" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="TotalCompanyDebt">Total Company Debt (M) <span v-show="inEditMode && currentState > 3 && parseInt(fund.fundTypeId) === 3" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input id="TotalCompanyDebt" type="text" v-model.number="fund.totalCompanyDebt" :disabled="!inEditMode" class="form-control" @blur="$v.fund.totalCompanyDebt.$touch()" placeholder="Enter Enterprise Value" :class="{ 'parsley-error': $v.fund.totalCompanyDebt.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.totalCompanyDebt.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.totalCompanyDebt.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.totalCompanyDebt.decimal && $v.fund.totalCompanyDebt.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.totalCompanyDebt.min" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.totalCompanyDebt.max" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="TotalCompanyEquity">Total Company Equity (M) <span v-show="inEditMode && currentState > 3 && parseInt(fund.fundTypeId) === 3" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input id="TotalCompanyEquity" type="text" v-model.number="fund.totalCompanyEquity" :disabled="!inEditMode" class="form-control" @blur="$v.fund.totalCompanyEquity.$touch()" placeholder="Enter Enterprise Value" :class="{ 'parsley-error': $v.fund.totalCompanyEquity.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.totalCompanyEquity.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.totalCompanyEquity.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.totalCompanyEquity.decimal && $v.fund.totalCompanyEquity.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.totalCompanyEquity.min" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.totalCompanyEquity.max" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="amountOffered">Amount Offered All LPs (M) <span v-show="inEditMode && currentState > 3 && parseInt(fund.fundTypeId) === 3" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input id="amountOffered" type="text" v-model.number="fund.amountOffered" :disabled="!inEditMode" @blur="$v.fund.amountOffered.$touch()" placeholder="Enter Amount Offered All LPs" class="form-control" :class="{ 'parsley-error': $v.fund.amountOffered.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.amountOffered.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.amountOffered.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.amountOffered.min" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.amountOffered.max" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="amountOfferedWpm">Amount Offered WPM (M) <span v-show="inEditMode && currentState > 3 && parseInt(fund.fundTypeId) === 3" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input id="amountOfferedWpm" type="text" v-model.number="fund.amountOfferedWpm" :disabled="!inEditMode" @blur="$v.fund.amountOfferedWpm.$touch()" placeholder="Enter Amount Offered WPM" class="form-control" :class="{ 'parsley-error': $v.fund.amountOfferedWpm.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.amountOfferedWpm.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.amountOfferedWpm.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.amountOfferedWpm.min" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.amountOfferedWpm.max" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                      <!-- coinvestment -->
                                                        <label for="gp-sponsor-fee">GP Sponsor Fee<span v-show="inEditMode && currentState > 3 && parseInt(fund.fundTypeId) !== 2" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.managementFeePercent" :disabled="!inEditMode" placeholder="Enter Management Fee - Investment Period" class="form-control" id="gp-sponsor-fee" @blur="$v.fund.managementFeePercent.$touch()" :class="{ 'parsley-error': $v.fund.managementFeePercent.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.managementFeePercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.managementFeePercent.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.managementFeePercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.managementFeePercent.min" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.managementFeePercent.max && $v.fund.managementFeePercent.decimal" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="gp-fee-structure">GP Sponsor Fee Structure</label>
                                                        <span :class="{ 'invalid': $v.fund.managementFeeStructureId.$error }">
                                                          <multiselect id="gp-fee-structure" v-model="managementFeeStructureObj" :disabled="!inEditMode" :options="allMngfeestructures" label="structureName" track-by="structureId" @input="managementFeeStructureSelected"></multiselect>
                                                        </span>
                                                        <template v-if="$v.fund.managementFeeStructureId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.managementFeeStructureId.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                      <!-- coinvestment -->
                                                        <label for="gp-carried">GP Carried Interest</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.carriedInterestPercent" :disabled="!inEditMode" placeholder="Enter Carried Interest" class="form-control" id="gp-carried" @blur="$v.fund.carriedInterestPercent.$touch()" :class="{ 'parsley-error': $v.fund.carriedInterestPercent.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.carriedInterestPercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.carriedInterestPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.carriedInterestPercent.min" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.carriedInterestPercent.max && $v.fund.carriedInterestPercent.decimal" class="parsley-required">This value can not be greater than 100.</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="gp-carried-desc">GP Carried Interest - Waterfall Type</label>
                                                        <input type="text" v-model="fund.carriedInterestDesc" :disabled="!inEditMode" placeholder="Enter Carried Interest Description" class="form-control" id="gp-carried-desc" @blur="$v.fund.carriedInterestDesc.$touch()" :class="{ 'parsley-error': $v.fund.carriedInterestDesc.$error }">
                                                        <template v-if="$v.fund.carriedInterestDesc.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.carriedInterestDesc.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                      <!-- primary -->
                                                        <!-- <label for="managementFeePercent">Management Fee - Investment Period<span v-show="inEditMode && fund.isActive" class="text-danger">*</span></label> -->
                                                        <label for="wilshire-fee">Wilshire Fee<span v-show="inEditMode && fund.isActive" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.wilshireFeePercent" :disabled="!inEditMode" placeholder="Enter Wilshire Fee" class="form-control" id="wilshire-fee" @blur="$v.fund.wilshireFeePercent.$touch()" :class="{ 'parsley-error': $v.fund.wilshireFeePercent.$error }" />
                                                        </div>
                                                        <template v-if="$v.fund.wilshireFeePercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.wilshireFeePercent.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.wilshireFeePercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.wilshireFeePercent.min && $v.fund.wilshireFeePercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.wilshireFeePercent.max && $v.fund.wilshireFeePercent.decimal" class="parsley-required">This value can not be greater than 100.</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                      <!-- primary -->
                                                        <label for="wilshire-carried-interest">Wilshire Carried Interest<span v-show="inEditMode && fund.isActive" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.wilshireCarriedIntPercent" :disabled="!inEditMode" placeholder="Enter Carried Interest" class="form-control" id="wilshire-carried-interest" @blur="$v.fund.wilshireCarriedIntPercent.$touch()" :class="{ 'parsley-error': $v.fund.wilshireCarriedIntPercent.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.wilshireCarriedIntPercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.wilshireCarriedIntPercent.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.wilshireCarriedIntPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.wilshireCarriedIntPercent.min && $v.fund.wilshireCarriedIntPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.wilshireCarriedIntPercent.max && $v.fund.wilshireCarriedIntPercent.decimal" class="parsley-required">This value can not be greater than 100.</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="preferredReturnPercent">Preferred Return</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.preferredReturnPercent" :disabled="!inEditMode" placeholder="Enter Preferred Return" class="form-control" id="preferredReturnPercent" @blur="$v.fund.preferredReturnPercent.$touch()" :class="{ 'parsley-error': $v.fund.preferredReturnPercent.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.preferredReturnPercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.preferredReturnPercent.decimal" class="parsley-required">This value is not valid.</li>
                                                            <li v-if="!$v.fund.preferredReturnPercent.max && $v.fund.preferredReturnPercent.decimal" class="parsley-required">This value can not be greater than 100.</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="ParentFundId">Parent Fund</label>
                                                        <input type="text" v-model="fund.parentFund" class="form-control" id="parentFundId" v-show="!inEditMode" readonly="readonly">
                                                        <span v-show="inEditMode">
                                                          <multiselect id="ParentFundId" v-model="parentFundObj" :disabled="!inEditMode" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="true" placeholder="Type to Search"
                                                            @select="fundSelected" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                                                        </span>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="dealDescription">Deal Description <span v-show="inEditMode && currentState > 3 && parseInt(fund.fundTypeId) === 3" class="text-danger">*</span></label>
                                                        <button type="button" class="btn btn-primary btn-sm btn-mini" v-show="inEditMode && needTrim(fund.dealDescription)" @click.prevent="fund.dealDescription = trimText(fund.dealDescription)">
                                                            <i class="mdi mdi-flash-auto"></i>
                                                        </button>
                                                        <textarea id="dealDescription" v-model.trim="fund.dealDescription" :disabled="!inEditMode" @blur="$v.fund.dealDescription.$touch()" placeholder="Enter Deal Description" class="form-control" :class="{ 'parsley-error': $v.fund.dealDescription.$error }" rows="5" />
                                                        <template v-if="$v.fund.dealDescription.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.dealDescription.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.dealDescription.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>                                                    
                                                    <div class="form-group">
                                                        <label for="indicationDueDateClient">Client Indication Due Date <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <date-picker id="indicationDueDateClient" v-model="fund.indicationDueDateClient" @input="$v.fund.indicationDueDateClient.$touch()" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.indicationDueDateClient.$error }"></date-picker>
                                                        <template v-if="$v.fund.indicationDueDateClient.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.indicationDueDateClient.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="indicationDueDateWilshire">Wilshire Indication Due Date <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <date-picker id="indicationDueDateWilshire" v-model="fund.indicationDueDateWilshire" @input="$v.fund.indicationDueDateWilshire.$touch()" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.indicationDueDateWilshire.$error }"></date-picker>
                                                        <template v-if="$v.fund.indicationDueDateWilshire.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.indicationDueDateWilshire.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="expectedclosedate">Expected Close Date <span v-show="inEditMode && !fund.isOpenEnded" class="text-danger">*</span></label>
                                                        <date-picker id="expectedclosedate" v-model="fund.expectedCloseDate" :disabled="!inEditMode || fund.isOpenEnded" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="inEditMode" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.expectedCloseDate.$error }"></date-picker>
                                                        <template v-if="$v.fund.expectedCloseDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.expectedCloseDate.required" class="parsley-required">This value is required.</li>
                                                            <li v-if="!$v.fund.expectedCloseDate.validCloseDate" class="parsley-required">Please enter a valid date</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="coTotalDealSize">Total Deal Size <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input id="coTotalDealSize" type="text" v-model.number="fund.coTotalDealSize" :disabled="!inEditMode" class="form-control" @blur="$v.fund.coTotalDealSize.$touch()" placeholder="Enter Total Deal Size Amount" :class="{ 'parsley-error': $v.fund.coTotalDealSize.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.coTotalDealSize.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.coTotalDealSize.required" class="parsley-required">This value is required</li>
                                                            <li v-if="(!$v.fund.coTotalDealSize.min || !$v.fund.coTotalDealSize.decimal) && $v.fund.coTotalDealSize.required" class="parsley-required">This value is not valid</li>
                                                          </ul>
                                                        </template>
                                                     </div>
                                                    <div class="form-group">
                                                        <label for="coStructureId">Security Structure <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="form-inline">
                                                          <select class="form-control" id="coStructureId" v-model="fund.coStructureId" :disabled="!inEditMode" @blur="$v.fund.coStructureId.$touch()" :class="{ 'parsley-error': $v.fund.coStructureId.$error }">
                                                              <option value="1">Senior Loan</option>
                                                              <option value="2">Junior Loan</option>
                                                              <option value="3">Mezzanine Loan</option>
                                                              <option value="4">Unitranche Loan</option>
                                                              <option value="5">Convertible Debt</option>
                                                              <option value="6">Preferred Equity</option>
                                                              <option value="7">Common Equity</option>
                                                          </select>
                                                          <select class="form-control" id="coStructure2Id" v-model="fund.coStructure2Id" :disabled="!inEditMode">
                                                              <option value=""></option>
                                                              <option value="1">with Cash Interest</option>
                                                              <option value="2">with PIK Interest</option>
                                                              <option value="3">with Cash + PIK Interest</option>
                                                              <option value="4">+ Warrants</option>
                                                              <option value="5">with Cash Interest + Warrants</option>
                                                              <option value="6">with PIK Interest + Warrants</option>
                                                              <option value="7">with Cash + PIK Interest + Warrants</option>
                                                          </select>
                                                        </div>
                                                        <template v-if="$v.fund.coStructureId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li class="parsley-required">This values is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="postClose">Post Close Leverage<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="form-inline">
                                                          <div class="input-group rm-3">
                                                              <div class="input-group-prepend">
                                                                  <span v-if="fund.postCloseType == 1" class="input-group-text">%</span>
                                                                  <span v-if="fund.postCloseType == 2" class="input-group-text">x</span>
                                                              </div>
                                                              <input id="postClose" type="text" v-model.number="fund.postClose" class="form-control" :disabled="!inEditMode" @blur="$v.fund.postClose.$touch()" placeholder="Enter Post Close Leverage Amount" :class="{ 'parsley-error': $v.fund.postClose.$error }" />
                                                          </div>
                                                          <select class="form-control" id="postClose" v-model="fund.postCloseType" :disabled="!inEditMode" @blur="$v.fund.postCloseType.$touch()" :class="{ 'parsley-error': $v.fund.postCloseType.$error }">
                                                              <option value="1">LTV</option>
                                                              <option value="2">Debt/EBITDA</option>
                                                          </select>
                                                        </div>
                                                        <template v-if="$v.fund.postClose.$error || $v.fund.postCloseType.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li class="parsley-required">Both values are required</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="capacityAmount">Capacity<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <span :class="{ 'invalid': $v.fund.capacityCurrencyId.$error }">
                                                          <multiselect id="capacityCurrencyId" v-model="capacityCurrencyObj" :disabled="!inEditMode" :options="allCurrencies" label="currencyName" track-by="currencyId" :allow-empty="false" @input="capacityCurrencySelected"></multiselect>
                                                        </span>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbolCo}}</span>
                                                            </div>
                                                            <input id="capacityAmount" type="text" v-model.number="fund.capacityAmount" :disabled="!inEditMode" class="form-control" @blur="$v.fund.capacityAmount.$touch()" placeholder="Enter Excess Co-Capacity Amount" :class="{ 'parsley-error': $v.fund.capacityAmount.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.capacityAmount.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.capacityAmount.required || !$v.fund.capacityAmount.min" class="parsley-required">Both values are required</li>
                                                            <li v-if="!$v.fund.capacityAmount.decimal && $v.fund.capacityAmount.required" class="parsley-required">This value is not valid</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                      <label for="baseCaseReturns">Base Case Returns<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <input id="baseCaseReturns" type="text" v-model="fund.baseCaseReturns" :disabled="!inEditMode" placeholder="Enter Base Case Returns" class="form-control" @blur="$v.fund.baseCaseReturns.$touch()" :class="{ 'parsley-error': $v.fund.baseCaseReturns.$error }">
                                                        <template v-if="$v.fund.baseCaseReturns.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.baseCaseReturns.required" class="parsley-required">A value above 0 is required</li>
                                                            <li v-if="!$v.fund.baseCaseReturns.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                      <label for="baseCaseHoldPeriod">Base Case Hold Period<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <input id="baseCaseHoldPeriod" type="text" v-model.number="fund.baseCaseHoldPeriod" :disabled="!inEditMode" class="form-control" @blur="$v.fund.baseCaseHoldPeriod.$touch()" placeholder="Enter Total Deal Size Amount" :class="{ 'parsley-error': $v.fund.baseCaseHoldPeriod.$error }">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">Years</span>
                                                            </div>
                                                        </div>
                                                        <template v-if="$v.fund.baseCaseHoldPeriod.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.baseCaseHoldPeriod.required || !$v.fund.baseCaseHoldPeriod.min" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.baseCaseHoldPeriod.decimal && $v.fund.baseCaseHoldPeriod.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.baseCaseHoldPeriod.max" class="parsley-required">This value is not valid (Max 100)</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="additionalNotes">Additional Notes</label>
                                                        <i class="help-tip mdi mdi-help-circle-outline px-1" v-tooltip="'Additional notes for Connect\'s Key Highlights section.'"></i>
                                                        <button type="button" class="btn btn-primary btn-sm btn-mini" v-show="inEditMode && needTrim(fund.keyHighlightNotes)" @click.prevent="fund.keyHighlightNotes = trimText(fund.keyHighlightNotes)">
                                                            <i class="mdi mdi-flash-auto"></i>
                                                        </button>
                                                          <textarea id="additionalNotes" v-model.trim="fund.keyHighlightNotes" :disabled="!inEditMode" placeholder="Enter Notes for Connect" class="form-control" rows="3" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Additional NDA/Joinder</label>
                                                        <toggle-button v-model="fund.hasJointer" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                        <div class="form-group pb-5" v-if="fund.hasJointer">
                                                            <label for="jointerLanguage">Jointer Language</label>
                                                            <div :class="{ 'parsley-error-radio': $v.fund.jointerLanguage.$error }">
                                                              <vue-editor style="height: 400px;" id="jointerLanguage" v-model="fund.jointerLanguage" :editor-toolbar="customToolbar" :disabled="!inEditMode"/>
                                                            </div>
                                                            <template v-if="$v.fund.jointerLanguage.$error">
                                                              <ul class="parsley-errors-list filled">
                                                                <li v-if="!$v.fund.jointerLanguage.required" class="parsley-required">This value is required</li>
                                                              </ul>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="noneAttributableSummary">Non attributable Summary</label>
                                                        <div :class="{ 'parsley-error-radio': $v.fund.noneAttributableSummary.$error }">
                                                          <vue-editor style="height: 400px;" id="noneAttributableSummary" v-model="fund.noneAttributableSummary" :editor-toolbar="customToolbar" :disabled="!inEditMode"/>
                                                        </div>
                                                        <template v-if="$v.fund.noneAttributableSummary.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.noneAttributableSummary.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                  <ul class="nav nav-tabs tabs-bordered">
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeCTab == 1, 'tab-error': industryFocusesHasError}" @click="showCTab(1)">
                                                              Industry Focus <span class="badge badge-primary noti-icon-badge" v-if="industryFocuses">{{industryFocuses.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeCTab == 2}" @click="showCTab(2)">
                                                              Regional Focus <span class="badge badge-primary noti-icon-badge" v-if="regionalFocuses">{{regionalFocuses.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeCTab == 3}" @click="showCTab(3)">
                                                              Merits/Concerns <span class="badge badge-primary noti-icon-badge" v-if="investmentMeritsConcerns">{{investmentMeritsConcerns.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeCTab == 4}" @click="showCTab(4)">
                                                              Client Feedback <span class="badge badge-primary noti-icon-badge" v-if="clientFeedbackList">{{clientFeedbackList.length}}</span>
                                                          </a>
                                                      </li>
                                                  </ul>
                                                  <div class="tab-content">
                                                      <div class="tab-pane fade" :class="{'show active': activeCTab == 1}">
                                                        <span v-if="isLoadingIndustryFocuses">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                              <tr>
                                                                  <th>Industry <span v-show="currentState > 1 && inEditMode" class="text-danger">*</span></th>
                                                                  <th>Group</th>
                                                                  <th class="action-col"></th>
                                                              </tr>
                                                              </thead>
                                                              <IndustryFocusList :industryFocuses="industryFocuses" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddIndustryFocus" @onSelect="showIndustryFocus" @onSelectUpdate="editIndustryFocus" @onUpdate="onIndustryFocusUpdate" />
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeCTab == 2}">
                                                        <span v-if="isLoadingRegionalFocuses">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                              <tr>
                                                                  <th>Country</th>
                                                                  <th class="action-col"></th>
                                                              </tr>
                                                              </thead>
                                                              <RegionalFocusList :regionalFocuses="regionalFocuses" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddRegionalFocus" @onSelect="showRegionalFocus" @onSelectUpdate="editRegionalFocus" @onUpdate="onRegionalFocusUpdate"/>
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeCTab == 3}">
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Description</th>
                                                                  <th>Type</th>
                                                                  <th class="action-col"></th>
                                                                </tr>
                                                              </thead>
                                                              <InvestmentMSList :meritsConcerns="investmentMeritsConcerns" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddMerit" @onSelect="showMerit" @onSelectUpdate="editMerit" @onUpdate="onMeritUpdate" />
                                                          </table>                                                            
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeCTab == 4}">
                                                        <span v-if="isLoadingClientFeedback">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Name</th>
                                                                  <th>Date</th>
                                                                  <th>Status</th>
                                                                </tr>
                                                              </thead>
                                                              <ClientFeedbackList :clientFeedbackList="clientFeedbackList" @onSelect="showClientInterest" />
                                                          </table>                                                            
                                                      </div>                                                     
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 10}">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label for="investmentStrategy">Investment Strategy<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                        <textarea type="text" v-model="fund.investmentStrategy" :disabled="!inEditMode" placeholder="Enter Investment Strategy" class="form-control" id="investmentStrategy" rows="5" @blur="$v.fund.investmentStrategy.$touch()" :class="{ 'parsley-error': $v.fund.investmentStrategy.$error }"/>
                                                        <template v-if="$v.fund.investmentStrategy.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.investmentStrategy.min" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.investmentStrategy.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <!-- <div class="form-group">
                                                        <label for="firmAum">Firm AUM (M) <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.firmAum" :disabled="!inEditMode" placeholder="Enter Firm AUM" class="form-control" id="firmAum" @blur="$v.fund.firmAum.$touch()" :class="{ 'parsley-error': $v.fund.firmAum.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.firmAum.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.firmAum.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.firmAum.decimal && $v.fund.firmAum.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.firmAum.min" class="parsley-required">This value is not valid</li>
                                                          </ul>
                                                        </template>
                                                    </div> -->

                                                    <div class="form-group">
                                                        <label for="aumAsOfDate">AUM As-of Date <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <date-picker v-model="fund.aumAsOfDate" @blur="$v.fund.aumAsOfDate.$touch()" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.aumAsOfDate.$error }"></date-picker>
                                                        <template v-if="$v.fund.aumAsOfDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.aumAsOfDate.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="strategyAum">Strategy AUM (M) <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">{{symbol}}</span>
                                                            </div>
                                                            <input type="text" v-model.number="fund.strategyAum" :disabled="!inEditMode" placeholder="Enter Strategy AUM" class="form-control" id="strategyAum" @blur="$v.fund.strategyAum.$touch()" :class="{ 'parsley-error': $v.fund.strategyAum.$error }">
                                                        </div>
                                                        <template v-if="$v.fund.strategyAum.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.strategyAum.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.strategyAum.decimal && $v.fund.strategyAum.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.strategyAum.min" class="parsley-required">This value is not valid</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="firmInceptionDate">Firm Inception Date <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <date-picker v-model="fund.firmInceptionDate" @blur="$v.fund.firmInceptionDate.$touch()" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.firmInceptionDate.$error }"></date-picker>
                                                        <template v-if="$v.fund.firmInceptionDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.firmInceptionDate.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="fundInceptionDate">Fund Inception Date <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <date-picker v-model="fund.fundInceptionDate" @blur="$v.fund.fundInceptionDate.$touch()" :disabled="!inEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.fundInceptionDate.$error }"></date-picker>
                                                        <template v-if="$v.fund.fundInceptionDate.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.fundInceptionDate.required" class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="trackRecordSummary">Track Record Summary<span v-show="inEditMode && currentState > 3 && fund.isActive" class="text-danger">*</span></label>
                                                        <button type="button" class="btn btn-primary btn-sm btn-mini" v-show="inEditMode && needTrim(fund.trackRecordSummary)" @click.prevent="fund.trackRecordSummary = trimText(fund.trackRecordSummary)">
                                                            <i class="mdi mdi-flash-auto"></i>
                                                        </button>
                                                        <textarea type="text" v-model="fund.trackRecordSummary" :disabled="!inEditMode" placeholder="Enter Track Record Summary" class="form-control" id="trackRecordSummary" @blur="$v.fund.trackRecordSummary.$touch()" :class="{ 'parsley-error': $v.fund.trackRecordSummary.$error }" rows="5"></textarea>
                                                        <template v-if="$v.fund.trackRecordSummary.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.trackRecordSummary.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.trackRecordSummary.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="subscriptionId">Subscriptions <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <select class="form-control" v-model="fund.subscriptionId" :disabled="!inEditMode" @blur="$v.fund.subscriptionId.$touch()" :class="{ 'parsley-error': $v.fund.subscriptionId.$error }">
                                                          <option :value="item.subscriptionId" v-for="item in allSubscriptions" :key="item.subscriptionId">{{ item.subscriptionName }}</option>
                                                        </select>
                                                        <template v-if="$v.fund.subscriptionId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="redemptionId">Redemptions <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <select class="form-control" v-model="fund.redemptionId" :disabled="!inEditMode" @blur="$v.fund.redemptionId.$touch()" :class="{ 'parsley-error': $v.fund.redemptionId.$error }">
                                                          <option :value="item.redemptionId" v-for="item in allRedemptions" :key="item.redemptionId">{{ item.redemptionName }}</option>
                                                        </select>
                                                        <template v-if="$v.fund.redemptionId.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li class="parsley-required">This value is required</li>
                                                          </ul>
                                                        </template>
                                                      </div>

                                                    <div class="form-group">
                                                      <label for="noticeDays">Notice <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <input id="noticeDays" type="text" v-model.number="fund.noticeDays" :disabled="!inEditMode" class="form-control" @blur="$v.fund.noticeDays.$touch()" placeholder="Enter Days" :class="{ 'parsley-error': $v.fund.noticeDays.$error }">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">Days</span>
                                                            </div>
                                                        </div>
                                                        <template v-if="$v.fund.noticeDays.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.noticeDays.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.noticeDays.decimal && $v.fund.noticeDays.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.noticeDays.max" class="parsley-required">This value is not valid (Max 250)</li>
                                                          </ul>
                                                        </template> 
                                                    </div>

                                                    <div class="form-group">
                                                      <label for="lockUpMonths">Lock-Up</label>
                                                        <div class="input-group">
                                                            <input id="lockUpMonths" type="text" v-model.number="fund.lockUpMonths" :disabled="!inEditMode" class="form-control" @blur="$v.fund.lockUpMonths.$touch()" placeholder="Enter Months" :class="{ 'parsley-error': $v.fund.lockUpMonths.$error }">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">Month</span>
                                                            </div>
                                                            <select class="form-control" v-model="fund.lockUpTypeId" :disabled="!inEditMode" @blur="$v.fund.lockUpTypeId.$touch()" :class="{ 'parsley-error': $v.fund.lockUpTypeId.$error }">
                                                              <option :value="item.lockUpTypeId" v-for="item in allLockupTypes" :key="item.lockUpTypeId">{{ item.lockUpTypeName }}</option>
                                                            </select>                                                          
                                                            <input id="lockUpPercent" type="text" v-model.number="fund.lockUpPercent" :disabled="!inEditMode" class="form-control" @blur="$v.fund.lockUpPercent.$touch()" placeholder="Enter %" :class="{ 'parsley-error': $v.fund.lockUpPercent.$error }">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                        <template v-if="$v.fund.lockUpPercent.$error || $v.fund.lockUpMonths.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.lockUpPercent.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.lockUpPercent.numeric && $v.fund.lockUpPercent.required" class="parsley-required">Decimal values are not valid</li>
                                                            <li v-if="!$v.fund.lockUpPercent.max && $v.fund.lockUpPercent.numeric" class="parsley-required">This value is not valid (Max 100)</li>
                                                          </ul>
                                                        </template> 
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="LtmEbitda">Gate</label>
                                                        <div class="input-group">
                                                            <select class="form-control" v-model="fund.gateWindowId" :disabled="!inEditMode" @blur="$v.fund.gateWindowId.$touch()" :class="{ 'parsley-error': $v.fund.gateWindowId.$error }">
                                                              <option :value="item.windowId" v-for="item in allTimeWinows" :key="item.windowId">{{ item.windowName }}</option>
                                                            </select>
                                                            <input id="gatePercent" type="text" v-model.number="fund.gatePercent" :disabled="!inEditMode" class="form-control" @blur="$v.fund.gatePercent.$touch()" placeholder="Enter %" :class="{ 'parsley-error': $v.fund.gatePercent.$error || $v.fund.gateWindowId.$error }">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                        <template v-if="$v.fund.gateWindowId.$error || $v.fund.gatePercent.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.gateWindowId.required || !$v.fund.gatePercent.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.gatePercent.numeric && $v.fund.gatePercent.required" class="parsley-required">Decimal values not are not valid</li>
                                                            <li v-if="!$v.fund.gatePercent.max && $v.fund.gatePercent.numeric" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="managementFee">Management Fee<span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <input id="managementFee" type="text" v-model.number="fund.managementFee" :disabled="!inEditMode" class="form-control" @blur="$v.fund.managementFee.$touch()" placeholder="Enter Management Fee" :class="{ 'parsley-error': $v.fund.managementFee.$error }">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                        <template v-if="$v.fund.managementFee.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.managementFee.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.managementFee.decimal && $v.fund.managementFee.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.managementFee.max" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="LtmEbitda">Performance Fee <span v-show="inEditMode" class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <input id="performanceFee" type="text" v-model.number="fund.performanceFee" :disabled="!inEditMode" class="form-control" @blur="$v.fund.performanceFee.$touch()" placeholder="Enter Performance Fee" :class="{ 'parsley-error': $v.fund.performanceFee.$error }">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                        <template v-if="$v.fund.performanceFee.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.performanceFee.required" class="parsley-required">This value is required</li>
                                                            <li v-if="!$v.fund.performanceFee.decimal && $v.fund.performanceFee.required" class="parsley-required">This value is not valid</li>
                                                            <li v-if="!$v.fund.performanceFee.max" class="parsley-required">This value is too big</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="hurdle">Hurdle</label>
                                                        <input type="text" v-model="fund.hurdle" :disabled="!inEditMode" placeholder="Enter Hurdle" class="form-control" id="hurdle" @blur="$v.fund.hurdle.$touch()" :class="{ 'parsley-error': $v.fund.hurdle.$error }">
                                                        <template v-if="$v.fund.hurdle.$error">
                                                          <ul class="parsley-errors-list filled">
                                                            <li v-if="!$v.fund.hurdle.max" class="parsley-required">This value is too long</li>
                                                          </ul>
                                                        </template>
                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                  <ul class="nav nav-tabs tabs-bordered">
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeCTab == 1}" @click="showCTab(1)">
                                                              Track Record <span class="badge badge-primary noti-icon-badge" v-if="trackRecord.length > 0">{{trackRecord.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeCTab == 2, 'tab-error': keySeniorHasError}" @click="showCTab(2)">
                                                              Key Seniors <span class="badge badge-primary noti-icon-badge" v-if="keySeniors">{{keySeniors.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeCTab == 3}" @click="showCTab(3)">
                                                              Industry Focus <span class="badge badge-primary noti-icon-badge" v-if="industryFocuses">{{industryFocuses.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeCTab == 4}" @click="showCTab(4)">
                                                              Regional Focus <span class="badge badge-primary noti-icon-badge" v-if="regionalFocuses">{{regionalFocuses.length}}</span>
                                                          </a>
                                                      </li>
                                                      <li class="nav-item">
                                                          <a href="javascript:void(0)" class="nav-link" :class="{'active': activeCTab == 5}" @click="showCTab(5)">
                                                              Merits/Concerns <span class="badge badge-primary noti-icon-badge" v-if="investmentMeritsConcerns">{{investmentMeritsConcerns.length}}</span>
                                                          </a>
                                                      </li>
                                                  </ul>
                                                  <div class="tab-content">
                                                      <div class="tab-pane fade" :class="{'show active': activeCTab == 1}">
                                                        <AltTrackRecord :fund='fund' :inEditMode='inEditMode' ref="altTrackRecord" />
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeCTab == 2}">
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Full Name</th>
                                                                  <th class="action-col"></th>
                                                                </tr>
                                                              </thead>
                                                              <KeySeniorList :keySeniors="keySeniors" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddKeySenior" @onSelect="showKeySenior" @onSelectUpdate="editKeySenior" @onUpdate="onKeySeniorUpdate" />
                                                          </table>                                                            
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeCTab == 3}">
                                                        <span v-if="isLoadingIndustryFocuses">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                              <tr>
                                                                  <th>Industry</th>
                                                                  <th>Group</th>
                                                                  <th class="action-col"></th>
                                                              </tr>
                                                              </thead>
                                                              <IndustryFocusList :industryFocuses="industryFocuses" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddIndustryFocus" @onSelect="showIndustryFocus" @onSelectUpdate="editIndustryFocus" @onUpdate="onIndustryFocusUpdate" />
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeCTab == 4}">
                                                        <span v-if="isLoadingRegionalFocuses">Loading <i class="fa fa-spinner fa-spin"></i></span>
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                              <tr>
                                                                  <th>Country</th>
                                                                  <th class="action-col"></th>
                                                              </tr>
                                                              </thead>
                                                              <RegionalFocusList :regionalFocuses="regionalFocuses" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddRegionalFocus" @onSelect="showRegionalFocus" @onSelectUpdate="editRegionalFocus" @onUpdate="onRegionalFocusUpdate"/>
                                                          </table>
                                                      </div>
                                                      <div class="tab-pane fade" :class="{'show active': activeCTab == 5}">
                                                          <table class="table table-striped table-hover">
                                                              <thead>
                                                                <tr>
                                                                  <th>Description</th>
                                                                  <th>Type</th>
                                                                  <th class="action-col"></th>
                                                                </tr>
                                                              </thead>
                                                              <InvestmentMSList :meritsConcerns="investmentMeritsConcerns" :canEdit="canbeEdited" :isEditMode="inEditMode" @onClickNew="clickAddMerit" @onSelect="showMerit" @onSelectUpdate="editMerit" @onUpdate="onMeritUpdate" />
                                                          </table>                                                            
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 9}" v-if="connectStats != null">
                                          <div class="row">
                                                <div class="col-lg-12">
                                                  <table class="table table-striped table-hover">
                                                      <thead>
                                                        <tr>
                                                          <th>Client</th>
                                                          <th>Score</th>
                                                          <th>Last Status</th>
                                                          <th>Visit #</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr v-for="stat in connectStats" :key="stat.organizationName">
                                                          <td>{{stat.organizationName}}</td>
                                                          <td>{{stat.score}}</td>
                                                          <td>{{stat.statusName}}</td>
                                                          <td>{{stat.numberOfVisit}}</td>
                                                        </tr>
                                                      </tbody>
                                                  </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" :class="{'active show': activeTab == 11}">
                                            <div class="row align-items-center justify-content-between">
                                              <div class="col-12">
                                              <Quarters :isLoading="isLoadingFundMonitoring" :inSaving="inSaving" :quarterTree="quarterTree" :minQ="minQ" :maxQ="maxQ" :enableAutofill=true :canAutofillQ="canAutofillQ" :jumpToArr="jumpToArr" :quarterSelection="quarterSelection" @quarterChanged="promptToSaveFM" @autofillClicked="confirmAutofill"/>
                                              </div>
                                              <div class="form-group mt-2 col-12">
                                                <table class="table table-striped table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th colspan="6">Ratios and Performance</th>
                                                    </tr>
                                                    <tr>
                                                      <th></th>
                                                      <th>IRR pa (%)</th>
                                                      <th>PIC</th>
                                                      <th>DPI</th>
                                                      <th>RVPI</th>
                                                      <th>TVPI</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr class="hover-cells editable">
                                                      <th>Fund</th>
                                                      <td><input v-model.number="fundMonitoring.ratioAndPerformanceFundIRRpa" class="form-control-plaintext" type="text" :readonly="!inEditMode"></td>
                                                      <td><input v-model.number="fundMonitoring.ratioAndPerformanceFundPIC" class="form-control-plaintext" type="text" :readonly="!inEditMode"></td>
                                                      <td><input v-model.number="fundMonitoring.ratioAndPerformanceFundDPI" class="form-control-plaintext" type="text" :readonly="!inEditMode"></td>
                                                      <td><input v-model.number="fundMonitoring.ratioAndPerformanceFundRVPI" class="form-control-plaintext" type="text" :readonly="!inEditMode"></td>
                                                      <td><input v-model.number="fundMonitoring.ratioAndPerformanceFundTVPI" class="form-control-plaintext" type="text" :readonly="!inEditMode"></td>
                                                    </tr>
                                                    <tr class="hover-cells">
                                                      <th >Status</th>
                                                      <td colspan="5" class="text-center" style="padding-right: 400px; padding-left: 200px">
                                                        <select :style="{'borderBottomColor': ratioStatusColor}" :disabled="!inEditMode" v-model="fundMonitoring.ratioAndPerformanceStatus" name="ratio-status" id="ratio-status" type="text" class="text-center font-bold form-control-plaintext">
                                                          <option value="0">Select Status</option>
                                                          <option value="4">Too Early</option>
                                                          <option value="3">Below Expectations</option>
                                                          <option value="2">At Expectations</option>
                                                          <option value="1">Above Expectations</option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              <div class="col-lg-12">
                                                <!-- <div class="row flex-column"> -->
                                                  <div class="form-group">
                                                    <label for="investment-comm">Investment Commentary</label>
                                                    <p class="form-control" style="min-height: 24px" id="investment-comm">{{ fundMonitoring.investmentCommentary }}</p>
                                                  </div>
                                              <div class="form-group">
                                                <label>Semi Annual Monitoring</label>
                                                <table class="table table-striped table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th style="white-space: nowrap; width: 18%" >Monitoring Subject Matter</th>
                                                      <th>Last Status <i class="mdi mdi-help-circle-outline" v-tooltip="'Add a comment/describe if not in green'"></i></th>
                                                      <th style="width: 10%">Score</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>Organization</td>
                                                      <td>
                                                        <textarea :disabled="!inEditMode" type="text" v-model="fundMonitoring.organizationStatus" rows="3" class="form-control-sm form-control"/>
                                                      </td>
                                                      <td>
                                                        <select :disabled="!inEditMode" type="text" v-model="fundMonitoring.organizationScore" :style="organizationColor" :class="{'text-black': fundMonitoring.organizationScore == 2}" class="form-control form-control-sm">
                                                          <option v-for="n in [1,2,3]" style="background: rgb(67, 79, 92); color: white" :key="n" :value="n">{{n}}</option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Team</td>
                                                      <td>
                                                        <textarea :disabled="!inEditMode" type="text" v-model="fundMonitoring.teamStatus" rows="3" class="form-control form-control-sm"/>
                                                      </td>
                                                      <td>
                                                        <select :disabled="!inEditMode" type="text" v-model="fundMonitoring.teamScore" :style="teamColor" :class="{'text-black': fundMonitoring.teamScore == 2}" class="form-control form-control-sm">
                                                          <option v-for="n in [1,2,3]" style="background: rgb(67, 79, 92); color: white" :key="n" :value="n">{{n}}</option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Investment Strategy</td>
                                                      <td>
                                                        <textarea :disabled="!inEditMode" type="text" v-model="fundMonitoring.investmentStrategyStatus" rows="3" class="form-control form-control-sm"/>
                                                      </td>
                                                      <td>
                                                        <select :disabled="!inEditMode" type="text" v-model="fundMonitoring.investmentStrategyScore" :style="investmentStrategyColor" :class="{'text-black': fundMonitoring.investmentStrategyScore == 2}" class="form-control form-control-sm">
                                                          <option v-for="n in [1,2,3]" style="background: rgb(67, 79, 92); color: white" :key="n" :value="n">{{n}}</option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Process</td>
                                                      <td>
                                                        <textarea :disabled="!inEditMode" type="text" v-model="fundMonitoring.processStatus" rows="3" class="form-control form-control-sm"/>
                                                      </td>
                                                      <td>
                                                        <select :disabled="!inEditMode" type="text" v-model="fundMonitoring.processScore" :style="processColor" :class="{'text-black': fundMonitoring.processScore == 2}" class="form-control form-control-sm">
                                                          <option v-for="n in [1,2,3]" style="background: rgb(67, 79, 92); color: white" :key="n" :value="n">{{n}}</option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Market</td>
                                                      <td>
                                                        <textarea :disabled="!inEditMode" type="text" v-model="fundMonitoring.marketStatus" rows="3" class="form-control form-control-sm"/>
                                                      </td>
                                                      <td>
                                                        <select :disabled="!inEditMode" type="text" v-model="fundMonitoring.marketScore" :style="marketColor" :class="{'text-black': fundMonitoring.marketScore == 2}" class="form-control form-control-sm">
                                                          <option v-for="n in [1,2,3]" style="background: rgb(67, 79, 92); color: white" :key="n" :value="n">{{n}}</option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Performance</td>
                                                      <td>
                                                        <textarea :disabled="!inEditMode" type="text" v-model="fundMonitoring.performanceStatus" rows="3" class="form-control form-control-sm"/>
                                                      </td>
                                                      <td>
                                                        <select :disabled="!inEditMode" type="text" v-model="fundMonitoring.performanceScore" :style="performanceColor" :class="{'text-black': fundMonitoring.performanceScore == 2}" class="form-control form-control-sm">
                                                          <option v-for="n in [1,2,3]" style="background: rgb(67, 79, 92); color: white" :key="n" :value="n">{{n}}</option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Legal</td>
                                                      <td>
                                                        <textarea :disabled="!inEditMode" type="text" v-model="fundMonitoring.legalStatus" rows="3" class="form-control form-control-sm"/>
                                                      </td>
                                                      <td>
                                                        <select :disabled="!inEditMode" type="text" v-model="fundMonitoring.legalScore" :style="legalColor" :class="{'text-black': fundMonitoring.legalScore == 2}" class="form-control form-control-sm">
                                                          <option v-for="n in [1,2,3]" style="background: rgb(67, 79, 92); color: white" :key="n" :value="n">{{n}}</option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>ESG</td>
                                                      <td>
                                                        <textarea :disabled="!inEditMode" type="text" v-model="fundMonitoring.esgStatus" rows="3" class="form-control form-control-sm"/>
                                                      </td>
                                                      <td>
                                                        <select :disabled="!inEditMode" type="text" v-model="fundMonitoring.esgScore" :style="esgColor" :class="{'text-black': fundMonitoring.esgScore == 2}" class="form-control form-control-sm">
                                                          <option v-for="n in [1,2,3]" style="background: rgb(67, 79, 92); color: white" :key="n" :value="n">{{n}}</option>
                                                        </select>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                </div>
                                                <div class="form-group">
                                                  <label for="monitoring-comm">Monitoring Commentary</label>
                                                  <div>
                                                    <vue-editor id="monitoring-comm" v-model="fundMonitoring.monitoringCommentary" :editor-toolbar="customToolbar" :disabled="!inEditMode"/>
                                                  </div>
                                                </div>
                                                <div class="form-group">
                                                  <label>Portfolio</label>
                                                  <table style="font-size: 13px" class="table table-striped table-hover">
                                                    <thead>
                                                      <tr>
                                                        <th style="white-space: nowrap; width:18%">Company Name</th>
                                                        <!-- <th style="white-space: nowrap">Company Name</th> -->
                                                        <th>Inv Date</th>
                                                        <th>Cost</th>
                                                        <th>Realized Proceeds</th>
                                                        <th>Unrealized Value</th>
                                                        <th>Gross IRR</th>
                                                        <th>Gross ROI</th>
                                                        <th>Projected IRR</th>
                                                        <th>Projected ROI</th>
                                                        <th>Exit Date</th>
                                                        <th>
                                                          <button class="btn btn-primary btn-sm btn-mini btn-icon mr-2" @click.prevent="showMonitoring(null)"><i class="mdi mdi-plus"></i></button>
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody style="font-size: 14px">
                                                      <tr v-for="p in monitoringPortfolio" :key="p.fundMonitoringPortfolioId">
                                                        <td style="font-size: 13px" class="align-middle">
                                                          {{p.portfolioName}}
                                                        </td>
                                                        <td class="align-middle">
                                                          {{p.investmentDate | formatDate}}
                                                        </td>
                                                        <td class="align-middle">
                                                          {{p.cost | formatCurrency}}
                                                        </td>
                                                        <td class="align-middle">
                                                          {{p.realizedProceeds | formatCurrency}}
                                                        </td>
                                                        <td class="align-middle">
                                                          {{p.unrealizedValue | formatCurrency}}
                                                        </td>
                                                        <td class="align-middle">
                                                          {{p.grossIRR}}{{p.grossIRR ? '%' : ''}}
                                                        </td>
                                                        <td class="align-middle">
                                                          {{p.grossROI}}{{p.grossROI ? 'x' : ''}}
                                                        </td>
                                                        <td class="align-middle">
                                                          {{p.projectedIRR}}{{p.projectedIRR ? '%' : ''}}
                                                        </td>
                                                        <td class="align-middle">
                                                          {{p.projectedROI}}{{p.projectedROI ? 'x' : ''}}
                                                        </td>
                                                        <td class="align-middle">
                                                          {{p.exitDate | formatDate}}
                                                        </td>
                                                        <td>
                                                          <button class="btn btn-success btn-sm btn-mini btn-icon mr-2" @click.prevent="showMonitoring(p)"><i class="mdi mdi-lead-pencil"></i></button>
                                                          <button class="btn btn-danger btn-sm btn-mini btn-icon" @click.prevent="deleteMPRecord(p)"><i class="mdi mdi-delete"></i></button>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            <!-- </div> -->
                        </form>
                    </div>
                 </div>
            </div>
        </div>
        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <ActivityItem @onUpdate="onActivityUpdate" v-if="rightbarItem == 'activity'" :objectId="1" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
              <FundTeamItem @onUpdate="onTeamMemberUpdate" v-if="rightbarItem == 'fundteam'" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
              <IndustryFocusItem @onUpdate="onIndustryFocusUpdate" v-if="rightbarItem == 'industryfocus'" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
              <RegionalFocusItem @onUpdate="onRegionalFocusUpdate" v-if="rightbarItem == 'regionalfocus'" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
              <InvestmentMSItem @onUpdate="onMeritUpdate" v-if="rightbarItem == 'merit'" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
              <MonitoringPortfolioItem @onUpdate="fetchFMPortfolio" v-if="rightbarItem == 'monitoring'" :isEditMode="sideInEditMode" :item="activeMPItem"/>
              <FundOpportunityItem @onUpdate="onOpportunityUpdate" v-if="rightbarItem == 'opportunity'" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
              <FundVoteItem @onUpdate="onVotingUpdate" v-if="rightbarItem == 'voting'" :isEditMode="sideInEditMode" :fundTypeId="fund.fundTypeId" />
              <FundVoteItemRr @onUpdate="onVotingUpdate" v-if="rightbarItem == 'voting2'" :isEditMode="sideInEditMode" :fundTypeId="fund.fundTypeId"/>
              <KeySeniorItem @onUpdate="onKeySeniorUpdate" v-if="rightbarItem == 'keysenior'" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
              <FileItem @onUpdate="onFileUpdate" v-if="rightbarItem == 'file'" :objectId="id" :objectTypeId="1" :isEditMode="sideInEditMode" :isAdd="addSidebar" :forConnect="fund.isConnect" :isCoInvest="fund.fundTypeId == 3" />
              <FundOnsiteMeetingItem @onUpdate="onFundOnsiteMeetingUpdate" v-if="rightbarItem == 'onsitemeeting'" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
              <SecondaryFundsItem @onUpdate="secFundsAfterUpdate" v-if="rightbarItem == 'secfunds'" :isEditMode="sideInEditMode" :isAdd="addSidebar" :fundId="fund.fundId"/>
              <ClientFeedbackItem v-if="rightbarItem == 'client-comments'" :loading='isLoadingClientItem' :clientFeedbackDetails='clientFeedbackDetails' />
              <AllocationRationaleItem @onUpdate="onAllocationRationaleItemUpdate" v-if="rightbarItem == 'allocation'" :isEditMode="sideInEditMode" :allocationRationale="rb_allocationRationale" :stageId="fund.stageId" :items="allocationRationalesItems" :fundTypeId="fund.fundTypeId" :fundSectorId="fund.sectorId" :fundGeoId="fund.geographicFocusId" />
              <FundTrackRecordItem @onUpdate="onTrackRecordUpdate" :list="trackRecord" v-if="rightbarItem == 'track-record'" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { FundService, FundTrackRecordService, FundTeamService, FundIndustryFocusService, FundRegionalFocusService, FundMeritConcernService, FundOpportunityService, FundVotingService, FundKeySeniorService, FundOnsiteMeetingService, SecFundService, FundMonitoringService } from '@/services/fund-service'
import { ActivityService } from '@/services/activity-service'
import { ConnectService } from '@/services/connect-service'
import FundToolbar from '@/components/fund/TheFundToolbar'
import FileList from '@/components/FileList'
import FileItem from '@/components/FileItem'
import ActivityList from '@/components/ActivityList'
import ActivityItem from '@/components/ActivityItem'
import FundTeamList from '@/components/fund/FundTeamList'
import FundTeamItem from '@/components/fund/FundTeamItem'
import IndustryFocusList from '@/components/fund/IndustryFocusList'
import IndustryFocusItem from '@/components/fund/IndustryFocusItem'
import RegionalFocusList from '@/components/fund/RegionalFocusList'
import RegionalFocusItem from '@/components/fund/RegionalFocusItem'
import InvestmentMSList from '@/components/fund/InvestmentMSList'
import InvestmentMSItem from '@/components/fund/InvestmentMSItem'
import MonitoringPortfolioItem from '@/components/fund/MonitoringPortfolioItem'
import FundOpportunityList from '@/components/fund/FundOpportunityList'
import FundOpportunityItem from '@/components/fund/FundOpportunityItem'
import SecondaryFundsItem from '@/components/fund/SecondaryFundsItem'
import SecondaryFundsList from '@/components/fund/SecondaryFundsList'
import FundVoteList from '@/components/fund/FundVoteList'
import FundVoteItem from '@/components/fund/FundVoteItem'
import FundVoteItemRr from '@/components/fund/FundVoteItemRr'
import KeySeniorList from '@/components/fund/KeySeniorList'
import KeySeniorItem from '@/components/fund/KeySeniorItem'
import FundMeeetingsList from '@/components/fund/LinkIcMeetingList'
import FundOnsiteMeetingList from '@/components/fund/FundOnsiteMeetingList'
import FundOnsiteMeetingItem from '@/components/fund/FundOnsiteMeetingItem'
import ClientFeedbackList from '@/components/fund/ClientFeedbackList'
import ClientFeedbackItem from '@/components/fund/ClientFeedbackItem'
import AllocationRationaleItem from '@/components/fund/AllocationRationaleItem'
import AllocationRationaleList from '@/components/fund/AllocationRationaleList'
import DatePicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import Radar from '@/components/custom/RadarSelect'
import { required, numeric, decimal, minLength, maxLength, minValue, maxValue, requiredIf, helpers } from 'vuelidate/lib/validators'
import rightbar from '@/mixins/rightbar'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import quarterHandler from '@/mixins/quarterConfig'
import RadarChart from '@/components/charts/radarChart'
import { UserpinService } from '@/services/userpin-service'
import moment from 'moment'
import { FileService } from '@/services/file-service'
import { IcMeetingService } from '@/services/icmeeting-service'
import { CalendarService } from '@/services/calendar-service'
import { CalMeetingService } from '@/services/calmeeting-service'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { VueEditor } from 'vue2-editor'
import { AllocationRationaleService } from '@/services/allocation-service'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import FundTrackRecordList from '@/components/fund/FundTrackRecordList'
import FundTrackRecordItem from '@/components/fund/FundTrackRecordItem'
import AltTrackRecord from '@/components/fund/AltTrackRecord'
import Quarters from '@/components/custom/Quarters'

//  NOT IN USE
// const validateIf1 = (prop, validator) =>
//   helpers.withParams({ type: 'validateIf1', prop }, function (value, parentVm) {
//     const v = helpers.ref(prop, this, parentVm)
//     const a = this.fund.isActive
//     return parseInt(v) === 1 && a ? validator(value) : true
//   })
const validateIf13S4 = (prop, validator) =>
  helpers.withParams({ type: 'validateIf13S4', prop }, function (value, parentVm) {
    const v = helpers.ref(prop, this, parentVm)
    const s = this.currentState
    const a = this.fund.isActive
    return parseInt(v) !== 2 && s > 3 && a ? validator(value) : true
  })
const validateIf1S4 = (prop, validator) =>
  helpers.withParams({ type: 'validateIf1S4', prop }, function (value, parentVm) {
    const v = helpers.ref(prop, this, parentVm)
    const s = this.currentState
    const a = this.fund.isActive
    return parseInt(v) === 1 && s > 3 && a && this.fund.fundGroupId === 1 ? validator(value) : true
  })
const validateipIf1S4 = (prop, validator) =>
  helpers.withParams({ type: 'validateipIf1S4', prop }, function (value, parentVm) {
    const v = helpers.ref(prop, this, parentVm)
    const s = this.currentState
    const a = this.fund.isActive
    const o = this.fund.isOpenEnded
    return parseInt(v) === 1 && s > 3 && a && !o ? validator(value) : true
  })
const validatefcaIf1S4 = (prop, validator) =>
  helpers.withParams({ type: 'validatefcaIf1S4', prop }, function (value, parentVm) {
    const v = helpers.ref(prop, this, parentVm)
    const s = this.currentState
    const a = this.fund.isActive
    return parseInt(v) === 1 && s > 3 && !this.firstCloseAmountNa && a && this.fund.fundGroupId === 1 ? validator(value) : true
  })

const validateIf3S4 = (prop, validator) =>
  helpers.withParams({ type: 'validateIf3S4', prop }, function (value, parentVm) {
    const v = helpers.ref(prop, this, parentVm)
    const s = this.currentState
    const a = this.fund.isActive
    return parseInt(v) === 3 && s > 3 && a ? validator(value) : true
  })
const validateIf12 = (prop, validator) =>
  helpers.withParams({ type: 'validateIf12', prop }, function (value, parentVm) {
    const v = helpers.ref(prop, this, parentVm)
    const a = this.fund.isActive
    return parseInt(v) < 3 && a ? validator(value) : true
  })
const validateIf12TargetSize = (prop, validator) =>
  helpers.withParams({ type: 'validateIf12TargetSize', prop }, function (value, parentVm) {
    const v = helpers.ref(prop, this, parentVm)
    const a = this.fund.isActive
    const s = this.currentState
    const na = this.fund.targetSizeNa
    return parseInt(v) < 3 && a && !na && s < 3 ? validator(value) : true
  })
// const validateIf12S4 = (prop, validator) =>
//   helpers.withParams({ type: 'validateIf12S4', prop }, function (value, parentVm) {
//     const v = helpers.ref(prop, this, parentVm)
//     const s = this.currentState
//     return parseInt(v) < 3 && s > 3 ? validator(value) : true
//   })

const validateIf3 = (prop, validator) =>
  helpers.withParams({ type: 'validatedIf3', prop }, function (value, parentVm) {
    const v = helpers.ref(prop, this, parentVm)
    const a = this.fund.isActive
    return parseInt(v) === 3 && a ? validator(value) : true
  })
const validateIfMa = (prop, validator) =>
  helpers.withParams({ type: 'validateIfMa', prop }, function (value, parentVm) {
    return parseInt(this.fund.fundGroupId) === 2 ? validator(value) : true
  })
// const validateIf1PM = (prop, validator) =>
//   helpers.withParams({ type: 'validateIf1PM', prop }, function (value, parentVm) {
//     const a = this.fund.isActive
//     return parseInt(this.fund.fundGroupId) === 1 && parseInt(this.fund.fundTypeId) === 1 && a ? validator(value) : true
//   })
// // const validateIfMa1 = (prop, validator) =>
// //   helpers.withParams({ type: 'validateIfMa1', prop }, function (value, parentVm) {
// //     const v = helpers.ref(prop, this, parentVm)
// //     return v == 2 ? validator(value) : true
// //   })
// const validateIfMa2 = (validator) =>
//   helpers.withParams({ type: 'validateIfMa2' }, function (parentVm) {
//     return this.fund.fundGroupId == 2 ? validator(value) : true
//   })

const validCloseDate = (sdate, edate, isOpen) => {
  // open ended funds do not require an end date
  if (isOpen) {
    return true
  }
  // if start date or end date are missing, return true
  // 'required' validator will handle erorrs for missing dates
  if (sdate === null || sdate === undefined || edate === null || sdate === undefined) {
    return true
  }

  const s = moment(sdate)
  const e = moment(edate)

  // start date must be ealier than end date
  return s < e
}
const validRrDate = (fdate, rdate, stage, status) => {
  if (!fdate || !rdate) {
    return true
  }
  if (!status) {
    return true
  }
  if (rdate == null && stage > 4) {
    return true
  }
  // if ((fdate === null || fdate === undefined || rdate === null || rdate === undefined) && stage >= 4) {
  //   return false
  // }
  const f = moment(fdate)
  const r = moment(rdate)
  return f < r
}
const validRadar = (organization, team, strategy, process, market, performance, terms, esg, generalPartnerSec, trackRecordSec, transactionSec, portfolioSec, potentialReturnsSec, generalPartner, company, transaction, valueCreationPlan, targetMarket, stage, status) => {
  if (!status) {
    return true
  }
  return (status && (stage < 5 || (stage > 2 && (organization + team + strategy + process + market + performance + terms +  esg + generalPartnerSec + trackRecordSec + transactionSec + portfolioSec + potentialReturnsSec + generalPartner + company + transaction + valueCreationPlan + targetMarket) >= 10)))
}
const validPreliminaryBidDate = (cdate, bidDate, typeId) => {
  if (typeId !== 2) {
    return true
  }
  if (bidDate === null || bidDate === undefined) {
    return true
  }
  const c = moment(cdate)
  const b = moment(bidDate)
  return c >= b
}
const validBindingBidDate = (cdate, bidDate, typeId) => {
  if (typeId !== 2) {
    return true
  }
  if (bidDate === null || bidDate === undefined) {
    return true
  }
  const c = moment(cdate)
  const b = moment(bidDate)
  return c >= b
}

export default {
  components: {
    FundToolbar,
    DatePicker,
    Multiselect,
    Radar,
    ActivityList,
    ActivityItem,
    FileList,
    FileItem,
    FundTeamList,
    FundTeamItem,
    IndustryFocusList,
    IndustryFocusItem,
    RegionalFocusList,
    RegionalFocusItem,
    InvestmentMSList,
    InvestmentMSItem,
    MonitoringPortfolioItem,
    FundOpportunityList,
    FundOpportunityItem,
    FundVoteList,
    FundVoteItem,
    FundVoteItemRr,
    RadarChart,
    KeySeniorList,
    KeySeniorItem,
    FundMeeetingsList,
    FundOnsiteMeetingList,
    FundOnsiteMeetingItem,
    SecondaryFundsItem,
    SecondaryFundsList,
    ClientFeedbackList,
    ClientFeedbackItem,
    AllocationRationaleItem,
    AllocationRationaleList,
    ChartDataLabels,
    VueEditor,
    Treeselect,
    FundTrackRecordList,
    FundTrackRecordItem,
    AltTrackRecord,
    Quarters
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      activeMPItem: null,
      fmUpdated: false,
      unwatchFM: null,
      fundMonitoring: {},
      monitoringPortfolio: {},
      canAutofillQ : false,
      monitoringColors: {
        3: '#F45B69',
        2: '#F6F5AE',
        1: '#00b19d'
      },
      ratioStatusColors: {
        1: '#3bafda',
        2: '#00b19d',
        3: '#dc3545',
        4: 'white'
      },
        // end fund monitoring data
      activeTab: 1,
      activeSTab: 1, // Summary
      activeDTab: 1, // Secondary
      activeGTab: 1, // General Fund
      activeITab: 1, // Investment Summary
      activeMTab: 1, // Monitoring
      activeCTab: 1, // Co-Investment
      showLogs: false,
      inEditMode: false,
      inSaving: false,
      sideInEditMode: false,
      currentState: 1,
      rightbarItem: null,
      generalPartnerObj: null,
      inactiveReasonObj: null,
      fundTypeObj: null,
      fundTierObj: null,
      strategyObj: null,
      geographicFocusObj: null,
      dealCurrencyObj: null,
      capacityCurrencyObj: null,
      placementAgentObj: null,
      monitoringTypeObj: null,
      wpmBoardSeatObj: null,
      parentFundObj: null,
      managementFeeStructureObj: null,
      fundPerformanceSourceObj: null,
      investmentSummaryApprovedObj: null,
      irrQuartileObj: null,
      roiQuartileObj: null,
      executionAuthorizedObj: null,
      legalFinalReviewedObj: null,
      fundContactObj: null,
      fund: {fundName: '', fundTypeId: 0, organizationRadarScore: 0, teamRadarScore: 0, strategyRadarScore: 0, processRadarScore: 0, marketRadarScore: 0, performanceRadarScore: 0, termsRadarScore: 0,  esgRadarScore: 0},
      fundTeams: null,
      linkMeetings: null,
      isLoadingFundMonitoring: false,
      isLoadinglinkMeetings: null,
      linkCalMeetings: null,
      isLoadingClientItem: null,
      isLoadinglinkCalMeetings: null,
      isLoadingFundTeams: false,
      isLoadingFundMeetings: false,
      fundOpportunities: null,
      isLoadingFundOpportunities: false,
      trackRecord: [],
      allocationRationales: [],
      allocationRationalesItems: null,
      isLoadingAllocationRationales: false,
      isLoadingTrackRecord: false,
      clientFeedbackList: null,
      clientFeedbackDetails: null,
      isLoadingClientFeedback: false,
      activities: null,
      isLoadingActivities: false,
      fundVotes: null,
      isLoadingFundVotes: false,
      fundFiles: null,
      isLoadingFundFiles: false,
      industryFocuses: null,
      isLoadingIndustryFocuses: false,
      regionalFocuses: null,
      isLoadingRegionalFocuses: false,
      investmentMeritsConcerns: null,
      isLoadingInvestmentMeritsConcerns: false,
      keySeniors: null,
      isLoadingKeySeniors: false,
      fundOnsiteMeetings: null,
      isLoadingFundOnsiteMeetings: false,
      secfunds: null,
      isLoadingSecfunds: false,
      addSidebar: null,
      isSearchingList: false,
      rb_allocationRationale: null,
      searchFirms: [],
      searchParners: [],
      searchContacts: [],
      searchFunds: [],
      connectOrgList: [],
      firstCloseAmountNa: false,
      firstCloseDateNa: false,
      preferredReturnPercentNA: false,
      linkPortfolios: null,
      isLoadinglinkPortfolios: false,
      connectStats: null,
      isLoadingConnectStats: false,
      isMaltsTrackRecError: false,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'color': [] }, { 'background': [] }]
      ]
    }
  },
  validations: {
    fund: {
      dealCurrencyId: { required },
      fundTierId: { required },
      fundName: { required, min: minLength(3), max: maxLength(100) },
      fundTypeId: { required, min: minValue(1), max: maxValue(3) },
      oprCode: { max: maxLength(10) },
      pitchbookId: { max: maxLength(10) },
      generalPartnerId: { required, min: minValue(1) },
      expectedStartDate: { required },
      expectedCloseDate: { required: requiredIf(function () { return !this.fund.isOpenEnded }), validCloseDate (value) { return validCloseDate(this.fund.expectedStartDate, value, this.fund.isOpenEnded) } },
      targetSize: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) < 3 && !this.fund.targetSizeNa }), decimal, min: validateIf12TargetSize('fundTypeId', minValue(1)), max: maxValue(1000000) },
      sectorId: { required, min: minValue(1) },
      sectorDescription: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) < 3 }), min: validateIf12('fundTypeId', minLength(2)), max: maxLength(300) },
      geographicFocusId: { required, min: minValue(1) },
      targetRRDate: { validRrDate (value) { return validRrDate(this.fund.targetFLRDate, value, this.currentState, this.fund.isActive) } },
      fundAbbreviation: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 1 && parseInt(this.fund.fundGroupId) === 1 }), max: maxLength(100) },
      firstCloseAmount: { required: requiredIf(function () { return !this.firstCloseAmountNa && this.fund.isActive }), decimal, min: validatefcaIf1S4('fundTypeId', minValue(0.1)), max: maxValue(1000000) },
      finalCloseAmount: { required, decimal, max: maxValue(1000000) },
      esgType: { required: requiredIf(function () { return this.fund.isSocialResponsible }) },
      esgTypeOther: { required: requiredIf(function () { return this.fund.isSocialResponsible && parseInt(this.fund.esgType) === 4 }) },
      firstCloseDate: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 1 && !this.firstCloseDateNa && parseInt(this.fund.fundGroupId) === 1 }) },
      investmentStrategy: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 1 && parseInt(this.fund.fundGroupId) === 1 }), max: maxLength(1000) },
      targetReturn: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 1 && parseInt(this.fund.fundGroupId) === 1 }), max: maxLength(20) },
      investmentSizeRange: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 1 && parseInt(this.fund.fundGroupId) === 1 }), max: maxLength(20) },
      numberofInvestments: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 1 && parseInt(this.fund.fundGroupId) === 1 }), max: maxLength(20) },
      investmentPeriod: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 1 }), min: validateipIf1S4('fundTypeId', minValue(1)), max: maxLength(20), numeric },
      fundTerm: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 1 && !this.fund.isOpenEnded }), max: maxLength(20) },
      dealDescription: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), max: maxLength(2000) },
      gpCommitmentPercent: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundGroupId) === 1 && parseInt(this.fund.fundGroupId) === 1 }), decimal, min: validateIf1S4('fundTypeId', minValue(0.1)), max: maxValue(100) },
      managementFeePercent: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) !== 2 }), decimal, min: validateIf13S4('fundTypeId', minValue(0)), max: maxValue(100) },
      managementFeeStructureId: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) !== 2 && parseInt(this.fund.fundGroupId) === 1 }) },
      carriedInterestPercent: { required: requiredIf(function () { return this.fund.isActive && this.fund.fundTypeId < 2 }), decimal, min: validateIf13S4('fundTypeId', minValue(0)), max: maxValue(100) },
      wilshireFeePercent: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3('fundTypeId', minValue(0)), max: maxValue(100) },
      wilshireCarriedIntPercent: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3('fundTypeId', minValue(0)), max: maxValue(100) },
      carriedInterestDesc: { max: maxLength(100) },
      preferredReturnPercent: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundTypeId) === 1 && !this.preferredReturnPercentNA }), decimal, min: minValue(0), max: maxValue(100) },
      trackRecordSummary: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 1 && parseInt(this.fund.fundGroupId) === 1 }), max: maxLength(250) },
      ltmRevenue: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3S4('fundTypeId', minValue(0.1)), max: maxValue(1000000) },
      ltmRevenueTypeId: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), min: validateIf3S4('fundTypeId', minValue(1)) },
      ltmEbitda: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), decimal, max: maxValue(1000000) },
      ltmEbitdaTypeId: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), min: validateIf3S4('fundTypeId', minValue(1)) },
      enterpriseValue: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3S4('fundTypeId', minValue(0.1)), max: maxValue(1000000) },
      totalCompanyDebt: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3S4('fundTypeId', minValue(0)), max: maxValue(1000000) },
      totalCompanyEquity: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3S4('fundTypeId', minValue(0.1)), max: maxValue(1000000) },
      amountOffered: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) !== 2 }), decimal, min: validateIf3S4('fundTypeId', minValue(0.1)), max: maxValue(1000000) },
      amountOfferedWpm: { required: requiredIf(function () { return this.fund.isActive && this.currentState > 3 && parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3S4('fundTypeId', minValue(0.1)), max: maxValue(1000000) },
      grossROI: { required, decimal },
      netROI: { required, decimal },
      grossIRRPercent: { decimal, max: maxValue(500) },
      netIRRPercent: { decimal, max: maxValue(500) },
      vintageYear: { required, numeric, min: minValue(1980) },
      secPreliminaryBidDate: { validPreliminaryBidDate (value) { return validPreliminaryBidDate(this.fund.expectedCloseDate, value, parseInt(this.fund.fundTypeId)) } },
      secBindingBidDate: { validBindingBidDate (value) { return validBindingBidDate(this.fund.expectedCloseDate, value, parseInt(this.fund.fundTypeId)) } },
      radars: { validRadar (organization, team, strategy, process, market, performance, terms, esg, generalPartnerSec, trackRecordSec, transactionSec, portfolioSec, potentialReturnsSec, generalPartner, company, transaction, valueCreationPlan, targetMarket, potentialReturns, stage, status) { return validRadar(this.fund.organizationRadarScore, this.fund.teamRadarScore, this.fund.strategyRadarScore, this.fund.processRadarScore, this.fund.marketRadarScore, this.fund.performanceRadarScore, this.fund.termsRadarScore, this.fund.esgRadarScore, this.fund.generalPartnerSecRadarScore, this.fund.trackRecordSecRadarScore, this.fund.transactionSecRadarScore, this.fund.portfolioSecRadarScore, this.fund.potentialReturnsSecRadarScore, this.fund.generalPartnerRadarScore, this.fund.companyRadarScore, this.fund.transactionRadarScore, this.fund.valueCreationPlanRadarScore, this.fund.targetMarketRadarScore, this.currentState, this.fund.isActive) } },
      alias: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }), max: maxLength(200) },
      indicationDueDateClient: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }) },
      indicationDueDateWilshire: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }) },
      capacityCurrencyId: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }), min: validateIf3('fundTypeId', minValue(1)) },
      capacityAmount: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3('fundTypeId', minValue(0.1)), max: maxValue(1000000) },
      coTotalDealSize: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3('fundTypeId', minValue(0.1)), max: maxValue(1000000) },
      postClose: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3('fundTypeId', minValue(0)), max: maxValue(1000000) },
      postCloseType: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }), min: validateIf3('fundTypeId', minValue(1)) },
      coStructureId: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }), min: validateIf3('fundTypeId', minValue(1)) },
      baseCaseReturns: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }), max: maxLength(50) },
      baseCaseHoldPeriod: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf3('fundTypeId', minValue(0.1)), max: maxValue(100) },
      jointerLanguage: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 && this.fund.hasJointer }) },
      noneAttributableSummary: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }) },
      // firmAum: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(0.1)), decimal },
      strategyAum: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(0.1)), decimal },
      firmInceptionDate: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }) },
      fundInceptionDate: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }) },
      aumAsOfDate: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }) },
      subscriptionId: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(1)) },
      redemptionId: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(1)) },
      noticeDays: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(1)), max: maxValue(250) },
      lockUpMonths: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(0)), max: maxValue(250) },
      lockUpTypeId: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(0)), max: maxValue(250) },
      lockUpPercent: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(0)), max: maxValue(100), numeric },
      gateWindowId: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(0)) },
      gatePercent: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(0)), max: maxValue(100), numeric },
      managementFee: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(0)), max: maxValue(100) },
      performanceFee: { required: requiredIf(function () { return this.fund.isActive && parseInt(this.fund.fundGroupId) === 2 }), min: validateIfMa('fundTypeId', minValue(0)), max: maxValue(100) },
      hurdle: { max: maxLength(100) },
    }
  },
  mixins: [rightbar, asyncDataStatus, quarterHandler],
  methods: {
    ...mapActions(['fetchAllFundInactiveReasons', 'searchAllFirms', 'fetchAllFundtypes', 'fetchAllFundtires', 'fetchAllStrategies',
      'fetchAllGeoFocus', 'fetchAllCurrencies', 'fetchAllMonitoringtypes', 'fetchAllBoardseats',
      'fetchAllMngfeestructures', 'fetchAllFundperformanceSource', 'fetchAllUsers', 'searchAllContacts', 'fetchActivity',
      'fetchFundTeam', 'fetchIndustryFocus', 'fetchRegionalFocus', 'fetchMeritConcern',
      'fetchFundKeySenior', 'fetchFile', 'fetchFundsOnsiteMeetings',
      'fetchFundOpportunitySet', 'fetchAllQuartiles', 'fetchFundFlrVotings', 'fetchFundRrVotings',
      'searchAllFunds', 'fetchSecFunds', 'fetchAllTreeStrategies', 'fetchAllSectors', 'fetchAllSubSectors', 'fetchAllLockupTypes',
      'fetchAllRedemptions', 'fetchAllSubscriptions', 'fetchAllTimeWinows']),
    // called when monitoring tab is clicked
    openMonitoringTab() {
      this.showTab(11)
      // only fetch monitoring data if this is the first time clicking monitoring tab
      if (Object.keys(this.quarterReqObj).length > 0) return
      this.fetchFundMonitoring()
    },
    handleDefaultTab() {
      const q = this.$route.query
      if (!q.tab) return
      else if (q.tab !== 'monitoring') return
      else {
        this.openMonitoringTab()
      }
    },
    // checks if monitoring data needs to be saved if user switches quarter
    promptToSaveFM (value) {
      this.quarterSelection = value 
      if (this.quarterSelection === this.currentQuarter) return
      if (this.fmUpdated) {
        // y and q will be from the quarter that needs to be saved, since quarterReqObj is not updated with selection until fetchMonitoring() is called
        const {year: y, quarter: q} = this.quarterReqObj
        this.$snotify.confirm(`Would you like to save changes for ${y} Q${q}?`, '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.$snotify.remove(toast.id)
                this.saveFundMonitoring()
              }
            },
            {
              text: 'No',
              action: (toast) => {
                this.$snotify.remove(toast.id),
                this.fetchFundMonitoring()
              },
              bold: true
            }
          ]})
      } else this.fetchFundMonitoring()
    },
    confirmAutofill () {
      if (!this.canAutofillQ) return
      const QuarterFrom = +this.previousQuarter.slice(-1)
      const YearFrom = +this.previousQuarter.slice(0, -1)
      this.$snotify.confirm(`Fill page with data from previous quarter (${YearFrom} Q${QuarterFrom})?`, '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.$snotify.remove(toast.id)
                this.importQData({QuarterFrom, YearFrom})
              }
            },
            {
              text: 'No',
              action: (toast) => {
                this.$snotify.remove(toast.id)
              },
              bold: true
            }
          ]})
    },
    async fetchFundMonitoring () {
      if (this.unwatchFM !== null) {
        this.unwatchFM()
      }
      this.isLoadingFundMonitoring = true
      this.fundMonitoring = {}
      this.monitoringPortfolio = []
      this.fmUpdated = false
       
      this.quarterReqObj = {
        fundId: this.id,
        quarter: +this.quarterSelection.slice(-1),
        year: +this.quarterSelection.slice(0, -1)
      } // quarterReqObjPrevQ and currQuarter are computed

      const q = this.quarterSelection
      await this.fetchQ(q, this.quarterReqObj).then(res => {
        this.fundMonitoring = JSON.parse(JSON.stringify(this.loadedQuarters[q].fm))
        this.monitoringPortfolio = this.loadedQuarters[q].mp
        // set a watcher on fundMonitoring obj. if user inputs data, fmUpdated is true which prompts save button to save Fund monitoring
        if (Object.keys(this.fundMonitoring).length >= 3) {
          this.unwatchFM = this.$watch('fundMonitoring', function(newValue) {
            this.fmUpdated = true
            // watcher is removed onces fmUpdated is set to true, for performance purposes
            this.unwatchFM()
          }, { deep: true }); 
        }
      }).catch(err => {
        console.error(err)
        this.$snotify.error(`Error Loading ${this.quarterReqObj.year} Q${this.quarterReqObj.quarter}`)
      }).finally(() => {
        this.isLoadingFundMonitoring = false
      })
      return 'success'
    },
    fetchQ (q, reqObj) {
      return new Promise((resolve, reject) => {
        if (this.loadedQuarters.hasOwnProperty(q)) resolve('success fetching quarter information')
        Promise.all([FundMonitoringService.get(reqObj), FundMonitoringService.getPortfolio(reqObj)]).then(([fmRes, mpRes]) => {
          if (!fmRes) throw new Error()
          const fm = fmRes.data[0] || JSON.parse(JSON.stringify(reqObj))
          const mp = mpRes.data
          if (!fm.ratioAndPerformanceStatus) fm.ratioAndPerformanceStatus = "0"
          this.canAutofillQ = !fm.hasOwnProperty('fundMonitoringId')
          this.$set(this.loadedQuarters, q, {fm, mp})
          resolve('success fetching quarter information')
        }).catch(err => {
          reject('failed to fetch quarter information')
        })
      })
    },
    async importQData ({QuarterFrom, YearFrom}) {
      try {
        const {quarter: QuarterTo, year: YearTo, fundId: FundId} = this.quarterReqObj
        this.isLoadingFundMonitoring = true
        const res = await FundMonitoringService.importPrevQData({QuarterFrom, YearFrom, QuarterTo, YearTo, FundId})
          if (res.status !== 200) throw new Error('Error loading previous quarter data')
          this.$delete(this.loadedQuarters, this.currentQuarter)
          const result = await this.fetchFundMonitoring()
          if (result === "success") this.$snotify.success('Success!')
      } catch (err) {
        console.error(err)
        this.$snotify.error('Error Loading previous quarter data')
        this.isLoadingFundMonitoring = false
      }
    },
    async fetchFMPortfolio () {
      try {
        this.monitoringPortfolio = []
        const response = await FundMonitoringService.getPortfolio(this.quarterReqObj)
        this.monitoringPortfolio = response.data
        if(this.monitoringPortfolio.length > 0) this.canAutofillQ = false
        this.loadedQuarters[this.quarterSelection].mp = JSON.parse(JSON.stringify(this.monitoringPortfolio))
      } catch(error) {
        this.$snotify.error('Error loading portfolio data')
        console.error(error.message)
      }
    },
    async saveFundMonitoring () {
      const monId = this.fundMonitoring.fundMonitoringId
      this.inSaving = true
      try {
        const response = monId ? await FundMonitoringService.save(monId, this.fundMonitoring) : await FundMonitoringService.add(this.fundMonitoring)
        if (response.status !== 200) throw new Error('Error saving monitoring data')
        this.inEditMode = false
        
        // saving copy of current fundMonitoring to loadedQuarters object
        const {quarter, year} = this.fundMonitoring
        const q = `${year}${quarter}`
        this.loadedQuarters[q].fm = JSON.parse(JSON.stringify(this.fundMonitoring))
        this.canAutofillQ = false
        // will fetch new quarter, or current updated quarter
        await this.fetchFundMonitoring()
      }
      catch (error) {
        this.$snotify.error(`Error saving Fund Monitoring\n${this.fundMonitoring.year} Q${this.fundMonitoring.quarter}`, {timeout: 6000} )
        // set quarterSelection back to current quarter, since save failed
        this.quarterSelection = this.currentQuarter
        return 'error'
      } finally {
        this.inSaving = false
      }
    },
    showTab (tabId) {
      this.activeTab = tabId
    },
    showSTab (tabId) {
      this.activeSTab = tabId
    },
    showDTab (tabId) {
      this.activeDTab = tabId
    },
    showGTab (tabId) {
      this.activeGTab = tabId
    },
    showCTab (tabId) {
      this.activeCTab = tabId
    },
    showITab (tabId) {
      this.activeITab = tabId
    },
    showMTab (tabId) {
      this.activeMTab = tabId
    },
    setState (st) {
      if (this.inEditMode && this.isAdmin) {
        if (this.fund.isStartFlrVoting && st < this.currentState && st < 3) {
          this.$snotify.info('Fund has FLR vote and can not go back!')
          return
        }
        if (this.fund.isStartRrVoting && st < this.currentState && st < 4) {
          this.$snotify.info('Fund has RR vote and can not go back!')
          return
        }
        this.currentState = st
        this.fund.stageId = st
      }
    },
    deleteForm () {
      if (this.fundTeams != null) {
        this.$snotify.info('Please remove Team Members first!')
        return
      }
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                FundService.delete(this.fund.fundId)
                .then((response) => {
                  this.$snotify.success('Fund Deleted.')
                  setTimeout(() => {
                    this.$router.push({name: 'FundsList'})
                  }, 500)
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    deleteMPRecord ({portfolioName, fundMonitoringPortfolioId}) {
      this.$snotify.clear()
      this.$snotify.confirm(`Delete ${portfolioName}?`, '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                FundMonitoringService.deletePortfolio(fundMonitoringPortfolioId)
                .then((response) => {
                  if (!response) throw new Error('Error deleting portfolio entry')
                  this.$snotify.success(`${portfolioName} deleted.`)
                  this.fetchFMPortfolio()
                }).catch(err => {
                  this.$snotify.error(err.message)
                  console.error(err.message)
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    async saveForm () {
      if (this.fmUpdated) {
          const fmSaveResponse = await this.saveFundMonitoring()
          if (fmSaveResponse === 'error') return
      }
      this.$v.fund.$touch()
      this.$refs.altTrackRecord.$v.$touch()
      if (this.$v.fund.radars.$error) {
        this.$snotify.warning('Radar data is missing!')
        return
      }
      this.isMaltsTrackRecError = this.$refs.altTrackRecord.$v.$error
      if (!this.$v.fund.$error && !this.isMaltsTrackRecError && !this.dealTeamHasError && !this.keySeniorHasError && !this.industryFocusesHasError) {
        // if target is null for Co-Investment
        if (this.fund.targetSize === '') {
          this.fund.targetSize = 0
        }

        if (this.firstCloseAmountNa && !this.fund.firstCloseAmount) {
          this.fund.firstCloseAmount = 0
        }

        if (this.preferredReturnPercentNA) {
          this.fund.preferredReturnPercent = 0
        }

        if (this.fund.ltmRevenue === '') {
          this.fund.ltmRevenue = 0
        }

        if (this.fund.ltmEbitda === '') {
          this.fund.ltmEbitda = 0
        }
          
        if (this.fund.enterpriseValue === '') {
          this.fund.enterpriseValue = 0
        }

        if (this.fund.wilshireFeePercent === '') {
          this.fund.wilshireFeePercent = 0
        }

        if (this.fund.wilshireCarriedIntPercent === '') {
          this.fund.wilshireCarriedIntPercent = 0
        }

        if (this.fund.managementFeePercent === '') {
          this.fund.managementFeePercent = 0
        }

        if (this.fund.preferredReturnPercent === '') {
          this.fund.preferredReturnPercent = 0
        }

        if (this.fund.carriedInterestPercent === '') {
          this.fund.carriedInterestPercent = 0
        }

        if (this.fund.amountOffered === '') {
          this.fund.amountOffered = 0
        }

        // if is Open-Ended
        if (this.fund.isOpenEnded) {
          this.fund.expectedCloseDate = null
        } else {
          this.fund.expectedCloseDate = moment(this.fund.expectedCloseDate).format('MM/DD/YYYY')
        }
        this.fund.expectedStartDate = moment(this.fund.expectedStartDate).format('MM/DD/YYYY')
        if (this.parentFundObj == null) {
          this.fund.parentFundId = null
          this.fund.parentFund = null
        }
        if (this.fund.subSectorId === 0) {
          this.fund.subSectorId = null
        }

        this.inSaving = true
        FundService.save(this.fund.fundId, this.fund)
        .then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.inEditMode = false
              this.$snotify.success('Changes saved.')
            }
            this.inSaving = false
          }
        ).catch(error => {
          this.$snotify.error('Server Error! ' + error.message)
          this.inSaving = false
        })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    EditForm () {
      this.inEditMode = true
      this.addSidebar = false
      // generalPartner
      this.asyncSearchFirm(this.fund.generalPartner)
      setTimeout(() => {
        this.generalPartnerObj = this.searchFirms.filter(x => x.firmId === this.fund.generalPartnerId)
      }, 1000)

      // placementAgent
      this.asyncSearchPartner(this.fund.placementAgent)
      setTimeout(() => {
        this.placementAgentObj = this.searchParners.filter(t => t.firmId === this.fund.placementAgentId)
      }, 1000)

      // fundContactObj
      this.asyncSearchContact(this.fund.fundContact)
      setTimeout(() => {
        this.fundContactObj = this.searchContacts.filter(t => t.contactId === this.fund.fundContactId)
      }, 1000)

      // parent Fund
      if (this.fund.fundTypeId === 3) {
        this.asyncSearchFund(this.fund.parentFund)
        setTimeout(() => {
          this.parentFundObj = this.searchFunds.filter(x => x.fundId === this.fund.parentFundId)
        }, 1000)
      }
    },
    cancelForm () {
      if (!this.$v.fund.$anyDirty) {
        this.cancelFormOper()
        return
      }

      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to cancel?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.cancelFormOper()
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    cancelFormOper () {
      this.inEditMode = false
      this.inSaving = false
      this.$v.fund.$reset()
      this.getDetails()
      if (this.fmUpdated) {
        this.fetchFundMonitoring()
      }
      if (this.addSidebar) {
        this.hideRightbar()
        this.addSidebar = false
      }
    },
    addNext () {
      this.$router.push({ name: 'FundsNewNext', params: { id: this.id } })
    },
    addPin () {
      const pin = {objectTypeId: 1, objectId: this.id}
      UserpinService.add(pin)
      .then(
        (response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error!')
          } else {
            this.fund.pinId = response.data.pinId
            this.$snotify.success('Pinned!')
          }
        })
        .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    delPin () {
      UserpinService.delete(this.fund.pinId)
      .then(
        (response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error!')
          } else {
            this.fund.pinId = 0
            this.$snotify.success('Unpinned!')
          }
        })
        .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    startScreening () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to start Screening?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                FundService.startScreening(this.fund.fundId)
                .then((response) => {
                  if (response.status !== 200) {
                    this.$snotify.error('Error!')
                  } else {
                    this.currentState = 2
                    this.fund.stageId = 2
                    this.$snotify.success('Done!')
                  }
                })
                .then(() => {
                  this.fetchActivities()
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    startFlr () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to start FLR?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                FundService.startFlr(this.fund.fundId)
                .then((response) => {
                  if (response.status !== 200) {
                    this.$snotify.error('Error!')
                  } else {
                    this.currentState = 3
                    this.fund.stageId = 3
                    this.$snotify.success('Done!')
                  }
                })
                .then(() => {
                  this.fetchActivities()
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    startAr () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to Start Allocation for this deal?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                FundService.startAr(this.fund.fundId)
                .then((response) => {
                  if (response.status !== 200) {
                    this.$snotify.error('Error!')
                  } else {
                    this.fund.initAllocation = 1
                    this.fund.allocationRestartRequired = false
                    this.$snotify.success('Done!')
                  }
                })
                .then(() => {
                  this.fetchActivities()
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    sidebarAction (item, isAdd, isOpen, isEdit) {
      this.addSidebar = isAdd
      this.sideInEditMode = isEdit || isAdd
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    showMonitoring (item = null) {
      // destructuring portfolio item obj so copy is sent to component
      this.activeMPItem = item ? {...item} : {...this.quarterReqObj}
      this.sidebarAction('monitoring', true, true, true)
    },
    showActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true)
    },
    editActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true, true)
    },
    clickAddActivity () {
      this.$store.commit('setNewActivity', this.fund.fundId)
      this.sidebarAction('activity', true, true)
    },
    onActivityUpdate () {
      this.fetchActivities()
      this.sidebarAction('', false, false)
    },
    showFile (id) {
      this.fetchFile({fileId: id})
      this.sidebarAction('file', false, true)
    },
    editFile (id) {
      this.fetchFile({fileId: id})
      this.sidebarAction('file', false, true, true)
    },
    clickAddFile () {
      this.$store.commit('setNewFile', this.fund.fundId)
      this.sidebarAction('file', true, true)
    },
    onFileUpdate () {
      this.fetchFileList()
      this.sidebarAction('', false, false)
    },
    showTeamMember (id) {
      this.fetchFundTeam({fundTeamId: id})
      this.sidebarAction('fundteam', false, true)
    },
    editTeamMember (id) {
      this.fetchFundTeam({fundTeamId: id})
      this.sidebarAction('fundteam', false, true, true)
    },
    clickAddTeamMember () {
      this.$store.commit('setNewFundTeam', this.fund.fundId)
      this.sidebarAction('fundteam', true, true)
    },
    onTeamMemberUpdate () {
      this.fetchFundTeamList()
      this.sidebarAction('', false, false)
    },
    showIndustryFocus (id) {
      this.fetchIndustryFocus({industryFocusId: id})
      this.sidebarAction('industryfocus', false, true)
    },
    editIndustryFocus (id) {
      this.fetchIndustryFocus({industryFocusId: id})
      this.sidebarAction('industryfocus', false, true, true)
    },
    clickAddIndustryFocus () {
      this.$store.commit('setNewIndustryFocus', this.fund.fundId)
      this.sidebarAction('industryfocus', true, true)
    },
    onIndustryFocusUpdate () {
      this.fetchIndustryFocusList()
      this.sidebarAction('', false, false)
    },
    showRegionalFocus (id) {
      this.fetchRegionalFocus({fundsRegionalFocusId: id})
      this.sidebarAction('regionalfocus', false, true)
    },
    editRegionalFocus (id) {
      this.fetchRegionalFocus({fundsRegionalFocusId: id})
      this.sidebarAction('regionalfocus', false, true, true)
    },
    clickAddRegionalFocus () {
      this.$store.commit('setNewFundRegionalFocus', this.fund.fundId)
      this.sidebarAction('regionalfocus', true, true)
    },
    onRegionalFocusUpdate () {
      this.fetchRegionalFocusList()
      this.sidebarAction('', false, false)
    },
    showMerit (id) {
      this.fetchMeritConcern({meritConcernId: id})
      this.sidebarAction('merit', false, true)
    },
    editMerit (id) {
      this.fetchMeritConcern({meritConcernId: id})
      this.sidebarAction('merit', false, true, true)
    },
    clickAddMerit () {
      this.$store.commit('setNewMerit', this.fund.fundId)
      this.sidebarAction('merit', true, true)
    },
    onMeritUpdate () {
      this.fetchMeritConcernList()
      this.sidebarAction('', false, false)
    },
    showKeySenior (id) {
      this.fetchFundKeySenior({fundKeySeniorId: id})
      this.sidebarAction('keysenior', false, true)
    },
    editKeySenior (id) {
      this.fetchFundKeySenior({fundKeySeniorId: id})
      this.sidebarAction('keysenior', false, true, true)
    },
    clickAddKeySenior () {
      this.$store.commit('setNewKeySenior', this.fund.fundId)
      this.sidebarAction('keysenior', true, true)
    },
    onKeySeniorUpdate () {
      this.fetchKeySeniorsList()
      this.sidebarAction('', false, false)
    },
    showFundOnsiteMeeting (id) {
      this.fetchFundsOnsiteMeetings({meetingId: id})
      this.sidebarAction('onsitemeeting', false, true)
    },
    editFundOnsiteMeeting (id) {
      this.fetchFundsOnsiteMeetings({meetingId: id})
      this.sidebarAction('onsitemeeting', false, true, true)
    },
    clickAddFundOnsiteMeeting () {
      this.$store.commit('setNewFundsOnsiteMeeting', this.fund.fundId)
      this.sidebarAction('onsitemeeting', true, true)
    },
    onFundOnsiteMeetingUpdate () {
      this.fetchFundOnsiteMeetingsList()
      this.sidebarAction('', false, false)
    },
    showOpportunity (id) {
      this.fetchFundOpportunitySet({fundOpportunitySetId: id})
      this.sidebarAction('opportunity', false, true)
    },
    clickAddTrackRecord () {
      this.$store.commit('setNewTrackRecord', this.fund.fundId)
      this.sidebarAction('track-record', true, true)
    },
    onTrackRecordUpdate () {
      this.fetchFundTrackRecord()
      this.sidebarAction('', false, false)
    },
    showTrackRecord (id) {
      this.fetchTrackRecord({fundTrackRecordId: id})
      this.sidebarAction('track-record', false, true)
    },
    clickAddOpportunity () {
      this.$store.commit('setNewFundOpportunitySet', this.fund.fundId)
      this.sidebarAction('opportunity', true, true)
    },
    onOpportunityUpdate () {
      this.fetchOpportunityList()
      this.sidebarAction('', false, false)
    },
    showClientInterest (id) {
      this.fetchClientFeedbackDetails(id)
      this.sidebarAction('client-comments', false, true)
    },
    showAllocationRationale (item) {
      this.rb_allocationRationale = item
      this.sidebarAction('allocation', false, true, false)
    },
    editAllocationRationale (item) {
      this.rb_allocationRationale = item
      this.sidebarAction('allocation', false, true, true)
    },
    onAllocationRationaleItemUpdate () {
      this.fetchFundAllocationRationaleList()
      this.sidebarAction('', false, false)
    },
    showSecFunds (id) {
      this.fetchSecFunds({secFundId: id})
      this.sidebarAction('secfunds', false, true)
    },
    clickAddSecFunds () {
      this.$store.commit('setNewSecFund', this.fund.fundId)
      this.sidebarAction('secfunds', true, true)
    },
    secFundsAfterUpdate () {
      this.fetchSecFundsList()
      this.sidebarAction('', false, false)
    },
    onSecFundsUpdate (id) {
      this.fetchSecFunds({secFundId: id})
      this.sidebarAction('secfunds', false, true, true)
    },
    showVoting (id) {
      this.fetchFundFlrVotings({votingId: id})
      this.sidebarAction('voting', false, true)
    },
    showVoting2 (id) {
      this.fetchFundRrVotings({votingId: id})
      this.sidebarAction('voting2', false, true)
    },
    updateVoting (id) {
      this.fetchFundFlrVotings({votingId: id})
      this.sidebarAction('voting', false, true, true)
    },
    updateVoting2 (id) {
      this.fetchFundRrVotings({votingId: id})
      this.sidebarAction('voting2', false, true, true)
    },
    clickCancelVote (code) {
      this.$snotify.clear()
      const vm = code === 1 ? 'Are you sure you want to PAUSE FLR Voting?' : 'Are you sure you want to PAUSE RR Voting?'
      const vw = code === 1 ? 'This is a temporary solution for pausing FLR Voting process!' : 'This is a temporary solution for pausing FLR Voting process!'
      this.$snotify.warning(vw)
      this.$snotify.confirm(vm, '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                if (code === 1) {
                  FundService.stopFlrVoting(this.fund.fundId)
                  .then((response) => {
                    this.$snotify.success('Voting Stopped.')
                    this.fund.isStartFlrVoting = false
                  })
                  .then(() => {
                    this.fetchActivities()
                  })
                } else {
                  FundService.stopRrVoting(this.fund.fundId)
                  .then((response) => {
                    this.$snotify.success('Voting Stopped.')
                    this.fund.isStartRrVoting = false
                  })
                  .then(() => {
                    this.fetchActivities()
                  })
                }
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    clickAddVoting (code) {
      this.$snotify.clear()
      if (code === 1) {
        if (this.fund.targetFLRDate == null) {
          this.$snotify.warning('Target FLR date is missing!')
          return
        }
        if (this.fundFiles == null || this.fundFiles.filter(x => x.purposeId === 1).length < 1) {
          this.$snotify.warning('FLR Document is missing!')
          return
        }
      } else if (code === 2) {
        if (this.fund.targetRRDate == null) {
          this.$snotify.warning('Target RR date is missing!')
          return
        }
        if (this.fundFiles == null || this.fundFiles.filter(x => x.purposeId === 2).length < 1) {
          this.$snotify.warning('RR Document is missing!')
          return
        }
        if (this.fund.IsStartFlrVoting && !this.fund.isFLRICApproved) {
          this.$snotify.warning('FLR Voting is not Approved, You cannot start RR Voting!')
          return
        }
        this.$v.fund.$touch()
        if (this.$v.fund.$error) {
          this.$snotify.error('Please fix the errors.')
          return
        }
        this.$v.fund.radars.$touch()
        if (this.$v.fund.radars.$error) {
          this.$snotify.warning('Radar data is missing!')
          return
        }
      }
      const vm = code === 1 ? 'Are you sure you want to start FLR Voting?' : 'Are you sure you want to start RR Voting?'
      this.$snotify.confirm(vm, '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                if (code === 1) {
                  FundService.startFlrVoting(this.fund.fundId)
                  .then((response) => {
                    this.$snotify.success('Voting Started.')
                    this.fund.isStartFlrVoting = true
                  })
                  .then(() => {
                    this.fetchActivities()
                  })
                } else {
                  FundService.startRrVoting(this.fund.fundId)
                  .then((response) => {
                    this.$snotify.success('Voting Started.')
                    this.fund.isStartRrVoting = true
                  })
                  .then(() => {
                    this.fetchActivities()
                  })
                }
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    onVotingUpdate () {
      this.fetchVotingList()
      this.sidebarAction('', false, false)
    },
    getDetails () {
      FundService.get(this.id)
      .then((response) => {
        this.fund = response.data
        this.currentState = this.fund.stageId
        this.inactiveReasonObj = this.allInactiveReasons.filter(r => r.reasonId === this.fund.inactiveReasonId)
        this.fundTypeObj = this.allTypes.filter(t => t.typeId === this.fund.fundTypeId)
        this.fundTierObj = this.allTires.filter(t => t.fundTierId === this.fund.fundTierId)
        const ss = this.fund.subSectorId ? this.fund.subSectorId : 0
        this.strategyObj = ss === 0 ? this.allSectors.filter(t => t.sectorId === this.fund.sectorId).map(item => `C${item.sectorId}`) : this.allSubsectors.filter(t => t.subSectorId === this.fund.subSectorId).map(item => `D${item.subSectorId}`)
        this.geographicFocusObj = this.allGeoFocuses.filter(t => t.geographicId === this.fund.geographicFocusId)
        this.dealCurrencyObj = this.allCurrencies.filter(t => t.currencyId === this.fund.dealCurrencyId)
        this.capacityCurrencyObj = this.allCurrencies.filter(t => t.currencyId === this.fund.capacityCurrencyId)
        this.monitoringTypeObj = this.allMonitoringtypes.filter(t => t.monitoringTypeId === this.fund.monitoringTypeId)
        this.wpmBoardSeatObj = this.allBoardseats.filter(t => t.boardSeatId === this.fund.wpmBoardSeatId)
        this.managementFeeStructureObj = this.allMngfeestructures.filter(t => t.structureId === this.fund.managementFeeStructureId)
        this.fundPerformanceSourceObj = this.allFundperformanceSource.filter(t => t.sourceId === this.fund.fundPerformanceSourceId)
        this.investmentSummaryApprovedObj = this.allUsers.filter(t => t.userId === this.fund.investmentSummaryApprovedBy)
        this.irrQuartileObj = this.allQuartiles.filter(t => t.quartileId === this.fund.irrQuartileId)
        this.roiQuartileObj = this.allQuartiles.filter(t => t.quartileId === this.fund.roiQuartileId)
        this.executionAuthorizedObj = this.allUsers.filter(t => t.userId === this.fund.executionAuthorizedBy)
        this.legalFinalReviewedObj = this.allUsers.filter(t => t.userId === this.fund.legalFinalReviewedBy)
      }).then(() => {
        this.fetchActivities()
      }).then(() => {
        this.fetchFundMeetingList()
      }).then(() => {
        this.fetchFundAllocationRationaleList()
      }).then(() => {
        this.fetchFundCalMeetingList()
      }).then(() => {
        this.fetchFundPortfolioList()
      }).then(() => {
        this.fetchKeySeniorsList()
      }).then(() => {
        this.fetchFundOnsiteMeetingsList()
      }).then(() => {
        this.fetchFundTeamList()
      }).then(() => {
        this.fetchIndustryFocusList()
      }).then(() => {
        this.fetchRegionalFocusList()
      }).then(() => {
        this.fetchMeritConcernList()
      }).then(() => {
        this.fetchOpportunityList()
      }).then(() => {
        this.fetchVotingList()
      }).then(() => {
        this.fetchFileList()
      }).then(() => {
        this.fetchConnectStat()
      }).then(() => {
        this.fetchFundTrackRecord()
      }).then(() => {
        if (this.fund.fundTypeId === 2) {
          this.fetchSecFundsList()
        }
        if (this.fund.fundTypeId === 3) {
          this.fetchClientFeedbackList()
          this.fetchConnectOrgs()
        }
      }).then(() => {
        this.asyncDataStatus_fetched('Deal Details')
      })
    },
    getTables () {
      this.fetchAllFundInactiveReasons()
      this.fetchAllFundtypes()
      this.fetchAllFundtires()
      this.fetchAllStrategies()
      this.fetchAllTreeStrategies()
      this.fetchAllSectors()
      this.fetchAllSubSectors()
      this.fetchAllGeoFocus()
      this.fetchAllCurrencies()
      this.fetchAllMonitoringtypes()
      this.fetchAllBoardseats()
      this.fetchAllMngfeestructures()
      this.fetchAllFundperformanceSource()
      this.fetchAllUsers()
      this.fetchAllQuartiles()
      this.fetchAllLockupTypes()
      this.fetchAllRedemptions()
      this.fetchAllSubscriptions()
      this.fetchAllTimeWinows()
    },
    fetchConnectOrgs () {
      ConnectService.getOrgList()
      .then((response) => {
        this.connectOrgList = response.data
      })
    },
    fetchFundPortfolioList () {
      this.isLoadinglinkPortfolios = true
      FundService.getPorfolioFunds(this.fund.fundId).then(
        (response) => {
          this.isLoadinglinkPortfolios = false
          if (response.data.length > 0) {
            this.linkPortfolios = response.data
          }
        }
      )
    },
    fetchConnectStat () {
      ConnectService.getFundStat(this.fund.fundId).then(
        (response) => {
          if (response.data.length > 0) {
            this.connectStats = response.data
          }
        }
      )
    },
    fetchFundMeetingList () {
      this.isLoadinglinkMeetings = true
      IcMeetingService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadinglinkMeetings = false
          if (response.data.length > 0) {
            this.linkMeetings = response.data
          } else {
            this.linkMeetings = null
          }
        }
      )
    },
    fetchFundCalMeetingList () {
      this.isLoadinglinkCalMeetings = true
      CalMeetingService.getFundMeetings(this.fund.fundId).then(
        (response) => {
          this.isLoadinglinkCalMeetings = false
          if (response.data.length > 0) {
            this.linkCalMeetings = response.data
          } else {
            this.linkCalMeetings = null
          }
        }
      )
    },
    fetchFundTrackRecord () {
      this.isLoadingTrackRecord = true
      FundTrackRecordService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadingTrackRecord = false
          if (response.data) {
            this.trackRecord = response.data
          } else {
            this.trackRecord = null
          }
        }
      )
    },
    fetchFundAllocationRationaleList () {
      this.isLoadingAllocationRationales = true
      AllocationRationaleService.get(this.fund.fundId).then(
        (response) => {
          this.isLoadingAllocationRationales = false
          if (response.data) {
            this.allocationRationales = response.data.list
            this.allocationRationalesItems = response.data.items
          } else {
            this.allocationRationales = null
            this.allocationRationalesItems = null
          }
        }
      )
    },
    fetchFundTeamList () {
      this.isLoadingFundTeams = true
      FundTeamService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadingFundTeams = false
          if (response.data.length > 0) {
            this.fundTeams = response.data
          } else {
            this.fundTeams = null
          }
        }
      )
    },
    fetchIndustryFocusList () {
      this.isLoadingIndustryFocuses = true
      FundIndustryFocusService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadingIndustryFocuses = false
          if (response.data.length > 0) {
            this.industryFocuses = response.data
          } else {
            this.industryFocuses = null
          }
        }
      )
    },
    fetchRegionalFocusList () {
      this.isLoadingRegionalFocuses = true
      FundRegionalFocusService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadingRegionalFocuses = false
          if (response.data.length > 0) {
            this.regionalFocuses = response.data
          } else {
            this.regionalFocuses = null
          }
        }
      )
    },
    fetchMeritConcernList () {
      this.isLoadingInvestmentMeritsConcerns = true
      FundMeritConcernService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadingInvestmentMeritsConcerns = false
          if (response.data.length > 0) {
            this.investmentMeritsConcerns = response.data
          } else {
            this.investmentMeritsConcerns = null
          }
        }
      )
    },
    fetchKeySeniorsList () {
      this.isLoadingKeySeniors = true
      FundKeySeniorService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadingKeySeniors = false
          if (response.data.length > 0) {
            this.keySeniors = response.data
          } else {
            this.keySeniors = null
          }
        }
      )
    },
    fetchFundOnsiteMeetingsList () {
      this.isLoadingFundOnsiteMeetings = true
      FundOnsiteMeetingService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadingFundOnsiteMeetings = false
          if (response.data.length > 0) {
            this.fundOnsiteMeetings = response.data
          } else {
            this.fundOnsiteMeetings = null
          }
        }
      )
    },
    fetchSecFundsList () {
      this.isLoadingSecfunds = true
      SecFundService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadingSecfunds = false
          if (response.data.length > 0) {
            this.secfunds = response.data
          } else {
            this.secfunds = null
          }
        }
      )
    },
    fetchActivities () {
      this.isLoadingActivities = true
      ActivityService.getFundActivities(this.fund.fundId).then(
        (response) => {
          this.isLoadingActivities = false
          if (response.data.length > 0) {
            this.activities = response.data
          } else {
            this.activities = null
          }
        }
      )
    },
    fetchOpportunityList () {
      this.isLoadingFundOpportunities = true
      FundOpportunityService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadingFundOpportunities = false
          if (response.data.length > 0) {
            this.fundOpportunities = response.data
          } else {
            this.fundOpportunities = null
          }
        }
      )
    },
    fetchClientFeedbackList () {
      this.isLoadingClientFeedback = true
      ConnectService.getCommentList(this.fund.fundId).then(
        (response) => {
          this.isLoadingClientFeedback = false
          if (response.data.length > 0) {
            this.clientFeedbackList = response.data
          } else {
            this.clientFeedbackList = null
          }
        }
      )
    },
    fetchClientFeedbackDetails (interestId) {
      this.isLoadingClientItem = true
      ConnectService.getCommentDetails(interestId).then(
        (response) => {
          this.isLoadingClientItem = false
          if (response.data) {
            this.clientFeedbackDetails = response.data
          } else {
            this.clientFeedbackDetails = null
          }
        }
      )
    },
    fetchVotingList () {
      this.isLoadingFundVotes = true
      FundVotingService.getByFundId(this.fund.fundId).then(
        (response) => {
          this.isLoadingFundVotes = false
          if (response.data.length > 0) {
            this.fundVotes = response.data
          } else {
            this.fundVotes = null
          }
        }
      )
    },
    fetchFileList () {
      this.isLoadingFundFiles = true
      FileService.getFundFiles(this.fund.fundId).then(
        (response) => {
          this.isLoadingFundFiles = false
          if (response.data.length > 0) {
            this.fundFiles = response.data
          } else {
            this.fundFiles = null
          }
        }
      )
    },
    inactiveReasonSelected (value, id) {
      this.fund.inactiveReasonId = value ? value.reasonId : null
    },
    strategySelected (value = 'none', id) {
      this.$v.fund.sectorId.$touch()
      if (value === 'none') {
        this.fund.sectorId = null
        return
      }
      if (value) {
        if (typeof value === 'string') {
          if (value[0] === 'C') {
            this.fund.sectorId = parseInt(value.slice(1))
            this.fund.subSectorId = null
          } else if (value[0] === 'D') {
            this.fund.sectorId = this.allSubsectors.filter(t => t.subSectorId === parseInt(value.slice(1)))[0].sectorId
            this.fund.subSectorId = parseInt(value.slice(1))
          }
        } else {
          if (value[0][0] === 'C') {
            this.fund.sectorId = parseInt(value[0].slice(1))
            this.fund.subSectorId = null
          } else if (value[0][0] === 'D') {
            this.fund.sectorId = this.allSubsectors.filter(t => t.subSectorId === parseInt(value[0].slice(1)))[0].sectorId
            this.fund.subSectorId = parseInt(value[0].slice(1))
          }
        }
      }
      let sector = this.allSectors.filter(sector => sector.sectorId === this.fund.sectorId)[0]
      this.fund.fundGroupId = this.allTreeStrategies.filter(strategy => strategy.strategyId === sector.strategyId)[0].groupId
    },
    openEnded () {
      if (this.fund.isOpenEnded) {
        this.fund.expectedCloseDate = null
      }
    },
    gpFirmSelected (value, id) {
      this.$v.fund.generalPartnerId.$touch()
      this.fund.generalPartnerId = value ? value.firmId : null
    },
    // typo
    tireSelected (value, id) {
      this.fund.fundTierId = value ? value.fundTierId : null
    },
    geoFocusSelected (value, id) {
      this.$v.fund.geographicFocusId.$touch()
      this.fund.geographicFocusId = value ? value.geographicId : null
    },
    dealCurrencySelected (value, id) {
      this.fund.dealCurrencyId = value ? value.currencyId : null
    },
    capacityCurrencySelected (value, id) {
      this.$v.fund.capacityCurrencyId.$touch()
      this.fund.capacityCurrencyId = value ? value.currencyId : null
    },
    monitoringTypeSelected (value, id) {
      this.fund.monitoringTypeId = value ? value.monitoringTypeId : null
    },
    wpmBoardSeatSelected (value, id) {
      this.fund.wpmBoardSeatId = value ? value.boardSeatId : null
    },
    placementAgentSelected (value, id) {
      this.fund.placementAgentId = value ? value.firmId : null
    },
    managementFeeStructureSelected (value, id) {
      this.fund.managementFeeStructureId = value ? value.structureId : null
    },
    fundPerformanceSourceSelected (value, id) {
      this.fund.fundPerformanceSourceId = value ? value.sourceId : null
    },
    irrQuartileSelected (value, id) {
      this.fund.irrQuartileId = value ? value.quartileId : null
    },
    roiQuartileSelected (value, id) {
      this.fund.roiQuartileId = value ? value.quartileId : null
    },
    investmentSummaryApprovedSelected (value, id) {
      this.fund.investmentSummaryApprovedBy = value ? value.userId : null
    },
    executionAuthorizedSelected (value, id) {
      this.$v.fund.executionAuthorizedBy.$touch()
      this.fund.executionAuthorizedBy = value ? value.userId : null
    },
    legalFinalReviewedSelected (value, id) {
      this.$v.fund.legalFinalReviewedBy.$touch()
      this.fund.legalFinalReviewedBy = value ? value.userId : null
    },
    fundContactSelected (value, id) {
      this.$v.fund.fundContactId.$touch()
      this.fund.fundContactId = value ? value.contactId : null
    },
    asyncSearchFirm (query) {
      this.isSearchingList = true
      this.searchAllFirms({query}).then((result) => {
        this.searchFirms = result
        this.isSearchingList = false
      })
    },
    asyncSearchPartner (query) {
      this.isSearchingList = true
      this.searchAllFirms({query}).then((result) => {
        this.searchParners = result
        this.isSearchingList = false
      })
    },
    asyncSearchContact (query) {
      this.isSearchingList = true
      this.searchAllContacts({query}).then((result) => {
        this.searchContacts = result
        this.isSearchingList = false
      })
    },
    fundSelected (value, id) {
      this.fund.parentFundId = value ? value.fundId : null
      if (this.parentFundObj != null) {
        this.fund.parentFund = value.fundName
      } else {
        this.fund.parentFund = null
      }
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    },
    checkCalander (day) {
      if (day != null) {
        const dt = new Date(day).toISOString()
        CalendarService.getCalendarDay(dt)
        .then((response) => {
          response.data.forEach(cal => {
            let office = ''
            switch (cal.calendarClass) {
              case 'calendar-holiday-all':
                office = ' an all office'
                break
              case 'calendar-holiday-hk':
                office = ' a Hong Kong'
                break
              case 'calendar-holiday-nd':
                office = ' an Amsterdam'
                break
              case 'calendar-holiday-nd-hk':
                office = ' an Amsterdam and Hong Kong'
                break
              case 'calendar-holiday-us':
                office = ' a USA'
                break
            }
            this.$snotify.warning('Selected day is ' + cal.calendarTitle + office + ' holiday')
          })
        }).catch(error => this.$snotify.error('Server Error! ' + error.message))
      }
    },
    portolioFundPosition (portolio) {
      let pos = ''
      if (portolio.fundId > 0) {
        pos = 'Committed'
      } else if (portolio.fund1Id > 0) {
        pos = 'Lead'
      } else if (portolio.fund2Id > 0) {
        pos = 'Second'
      } else if (portolio.fund3Id > 0) {
        pos = 'Other'
      }
      return pos
    },
    needTrim (val) {
      return val != null && val.length > 0 && val.indexOf('\n') > 0
    },
    trimText (val) {
      while (val.indexOf('\n') > 0) {
        val = val.replace('\n', ' ')
      }
      return val
    },
    openPathInNewWindow (path) {
        let resolvedRoute = this.$router.resolve(path)
        window.open(resolvedRoute.href, '_blank')
    },
    onLegalDDClicked () {
      if (this.fund.legalDDRequestId > 0) {        
        this.openPathInNewWindow({name: 'LegalIfc', params: {id: this.fund.legalDDRequestId}})
      } else if (this.fund.stageId === 5){
        this.openPathInNewWindow({name: 'LegalIfcNew', params: {id: this.fund.fundId}})
      } else {
        this.$snotify.clear()
        this.$snotify.confirm('Please acknowledge that the 2x MD approval has been granted for this Deal.', '',
        {
          buttons: [
            {
              text: 'Agree',
              action: (toast) => {
                this.openPathInNewWindow({name: 'LegalIfcNew', params: {id: this.fund.fundId}})
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'Disagree',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})        
      }
    },
    onOperationDDClicked () {      
      if (this.fund.operationDDRequestId > 0) {
        this.openPathInNewWindow({name: 'OpsDetail', params: {id: this.fund.operationDDRequestId}})
      } else if (this.fund.stageId === 5){
          this.openPathInNewWindow({name: 'OpsNew', params: {id: this.fund.fundId}, query: {categoryId: 1}})
      } else {
        this.$snotify.clear()
        this.$snotify.confirm('Please acknowledge that the 2x MD approval has been granted for this Deal.', '',
        {
          buttons: [
            {
              text: 'Agree',
              action: (toast) => {
                this.openPathInNewWindow({name: 'OpsNew', params: {id: this.fund.fundId}, query: {categoryId: 1}})
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'Disagree',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})     
        
      }
    },
  },
  created () {
    this.addSidebar = false
    this.hideRightbar()
    this.getTables()
    this.prepQuarterDropdown()
    this.handleDefaultTab()
    this.getDetails()
  },
  computed: {
    ...mapGetters({
      user: 'authUser'
    }),
    _validationErrorList () {
      let errorList = []
      for (let validation in this.$v.fund) {
        if (validation[0] !== '$') {
          if (this.$v.fund[validation].$anyError === true) {
            errorList.push([validation, this.$v.fund[validation]])
          }
        }
      }
      return errorList
    },
    canRename () {
      return this.fund.stageId < 3 || (this.fund.stageId > 3 && (this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR || x.roleId === this.$userRoles.BUSINESS_COMMITTEE).length > 0)) // Above Associate + Admin + BC
    },
    canStartScreening () {
      return this.fund.stageId === 1 && this.fund.isActive
    },
    canStartFlr () {
      return this.fund.stageId === 2 && this.fund.isActive && this.user.groupId < 11 // && (this.user.titleId <= 4 || this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0) > ALL TEAM CAN
    },
    canStartAr () {
      return this.fund.allocationRestartRequired || (this.fund.stageId > 2 && this.fund.isActive && this.user.groupId < 11 && this.fundTeams != null && this.fundTeams.filter(x => x.userId === this.user.userId).length > 0 && this.fund.initAllocation === 0)
    },
    validationRules () {
      return this.stageId === 1
      ? { fundName: { required, min: minLength(3), max: maxLength(10) } }
      : { fundName: { required, min: minLength(3) } }
    },
    allInactiveReasons () {
      return this.$store.state.allFundinactiveReasons
    },
    allTypes () {
      return this.$store.state.allFundtypes
    },
    allTires () {
      return this.$store.state.allFundtires
    },
    allStrategies () {
      return this.$store.state.allStrategies
    },
    allTreeStrategies () {
      return this.$store.state.allTreeStrategies
    },
    allSectors () {
      return this.$store.state.allSectors
    },
    allSubsectors () {
      return this.$store.state.allSubsectors
    },
    allLockupTypes () {
      return this.$store.state.allLockupTypes
    },
    allTimeWinows () {
      return this.$store.state.allTimeWinows
    },
    allRedemptions () {
      return this.$store.state.allRedemptions
    },
    allSubscriptions () {
      return this.$store.state.allSubscriptions
    },
    fullStrategiesTree () {
      let groups = [{
        id: '1A',
        label: 'Private Markets',
        children: []
      },
      {
        id: '2A',
        label: 'Marketable Alternatives',
        children: []
      }]
      this.allTreeStrategies.forEach(obj => {
        groups[obj.groupId - 1].children = [...groups[obj.groupId - 1].children, {id: `B${obj.strategyId}`, label: obj.strategyName, children: []}]
      })
      groups.forEach(group => {
        group.children.forEach(strategy => {
          this.allSectors.forEach(item => {
            item.strategyId === parseInt(strategy.id.slice(1)) ? strategy.children = [...strategy.children, {id: `C${item.sectorId}`, label: item.sectorName, children: []}] : strategy.children = strategy.children
          })
        })
      })
      groups.forEach(group => {
        group.children.forEach(strategy => {
          strategy.children.forEach(sector => {
            this.allSubsectors.forEach(item => {
              if (item.sectorId === parseInt(sector.id.slice(1))) {
                sector.children = [...sector.children, { id: `D${item.subSectorId}`, label: item.subSectorName, customLabel: `${group.label} > ${strategy.label} > ${sector.label} > ${item.subSectorName}` }]
              }
            })
          })
        })
      })
      groups.forEach(group => {
        group.children.forEach(strategy => {
          strategy.children = strategy.children.sort((a, b) => b.children.length - a.children.length).map(sector => {
            if (sector.children.length === 0) {
              return { id: sector.id, label: sector.label, customLabel: `${group.label} > ${strategy.label} > ${sector.label}` }
            } else {
              return sector
            }
          })
        })
      })
      groups.forEach(group => {
        group.children = group.children.sort((a, b) => b.children.length - a.children.length).map(strategy => {
          if (strategy.children.length === 1) {
            return { id: strategy.children[0].id, label: strategy.label, customLabel: `${group.label} > ${strategy.label}` }
          } else {
            return strategy
          }
        })
      })
      return groups
    },
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    allMonitoringtypes () {
      return this.$store.state.allMonitoringtypes
    },
    allBoardseats () {
      return this.$store.state.allBoardseats
    },
    allMngfeestructures () {
      return this.$store.state.allMngfeestructures
    },
    allFundperformanceSource () {
      return this.$store.state.allFundperformanceSource
    },
    allUsers () {
      return this.$store.state.allUsers
    },
    userOptionsISApproved () {
      return this.allUsers.filter(u => u.isActive || u.userId === this.fund.investmentSummaryApprovedBy)
    },
    allQuartiles () {
      return this.$store.state.allQuartiles
    },
    symbol () {
      if (this.dealCurrencyObj != null) {
        return this.dealCurrencyObj.length > 0 ? this.dealCurrencyObj[0].symbol : this.dealCurrencyObj.symbol
      }
    },
    symbolCo () {
      if (this.capacityCurrencyObj != null) {
        return this.capacityCurrencyObj.length > 0 ? this.capacityCurrencyObj[0].symbol : this.capacityCurrencyObj.symbol
      }
      return '?'
    },
    smTabHasError () {
      return this.$v.fund.fundName.$error || this.$v.fund.fundTypeId.$error || this.$v.fund.oprCode.$error || this.$v.fund.pitchbookId.$error
    },
    gfTabHasError () {
      return this.$v.fund.expectedStartDate.$error || this.$v.fund.expectedCloseDate.$error || this.$v.fund.dealCurrencyId.$error || this.$v.fund.fundTierId.$error || this.$v.fund.generalPartnerId.$error || this.$v.fund.expectedStartDate.$error || this.$v.fund.expectedCloseDate.$error || this.$v.fund.sectorId.$error || this.$v.fund.sectorDescription.$error || this.$v.fund.geographicFocusId.$error || this.$v.fund.targetRRDate.$error || this.$v.fund.targetSize.$error || this.dealTeamHasError || this.$v.fund.esgType.$error || this.$v.fund.esgTypeOther.$error || this.$v.fund.vintageYear.$error
    },
    seTabHasError () {
      return false
    },
    maTabHasError () {
      return this.$v.fund.strategyAum.$error || this.$v.fund.firmInceptionDate.$error || this.$v.fund.subscriptionId.$error || this.$v.fund.redemptionId.$error || this.$v.fund.noticeDays.$error || this.$v.fund.lockUpMonths.$error || this.$v.fund.lockUpTypeId.$error || this.$v.fund.lockUpPercent.$error || this.$v.fund.gateWindowId.$error || this.$v.fund.gatePercent.$error || this.$v.fund.managementFee.$error || this.$v.fund.performanceFee.$error || this.$v.fund.hurdle.$error || this.isMaltsTrackRecError
    },
    isTabHasError () {
      return this.$v.fund.carriedInterestPercent.$error || this.$v.fund.preferredReturnPercent.$error || this.$v.fund.managementFeePercent.$error || this.$v.fund.fundAbbreviation.$error || this.$v.fund.firstCloseAmount.$error || this.$v.fund.finalCloseAmount.$error || this.$v.fund.investmentStrategy.$error || this.$v.fund.targetReturn.$error || this.$v.fund.investmentSizeRange.$error || this.$v.fund.numberofInvestments.$error || this.$v.fund.investmentPeriod.$error || this.$v.fund.fundTerm.$error || this.$v.fund.gpCommitmentPercent.$error || this.$v.fund.managementFeePercent.$error || this.$v.fund.carriedInterestPercent.$error || this.$v.fund.preferredReturnPercent.$error || this.$v.fund.trackRecordSummary.$error || this.$v.fund.grossROI.$error || this.$v.fund.netROI.$error || this.$v.fund.grossIRRPercent.$error || this.$v.fund.netIRRPercent.$error || this.$v.fund.firstCloseDate.$error || this.$v.fund.investmentStrategy.$error || this.$v.fund.managementFeeStructureId.$error || this.keySeniorHasError
    },
    // iaTabHasError () {
    //   return this.$v.fund.investmentLegalName.$error || this.$v.fund.closingDate.$error || this.$v.fund.isPortfolioDisclosure.$error || this.$v.fund.term.$error || this.$v.fund.recallDisclosure.$error || this.$v.fund.fundContactId.$error || this.$v.fund.executionAuthorizedBy.$error || this.$v.fund.legalFinalReviewedBy.$error
    // },
    rdTabHasError () {
      return this.inEditMode && this.$v.fund.radars.$error && this.fund.isActive
    },
    // bookmark
    coTabHasError () {
      return this.inEditMode && (this.$v.fund.ltmRevenue.$error || this.$v.fund.carriedInterestPercent.$error || this.$v.fund.preferredReturnPercent.$error ||
       this.$v.fund.managementFeePercent.$error || this.$v.fund.dealDescription.$error || this.$v.fund.amountOfferedWpm.$error || this.$v.fund.amountOffered.$error ||
       this.$v.fund.totalCompanyEquity.$error || this.$v.fund.totalCompanyDebt.$error || this.$v.fund.enterpriseValue.$error || this.$v.fund.ltmEbitdaTypeId.$error ||
       this.$v.fund.ltmEbitda.$error || this.$v.fund.alias.$error || this.$v.fund.indicationDueDateClient.$error || this.$v.fund.indicationDueDateWilshire.$error ||
       this.$v.fund.capacityCurrencyId.$error || this.$v.fund.capacityAmount.$error || this.$v.fund.coTotalDealSize.$error || this.$v.fund.postClose.$error ||
       this.$v.fund.postCloseType.$error || this.$v.fund.coStructureId.$error || this.$v.fund.baseCaseReturns.$error ||
       this.$v.fund.baseCaseHoldPeriod.$error || this.$v.fund.noneAttributableSummary.$error || this.$v.fund.strategyAum.$error ||
       this.$v.fund.firmInceptionDate.$error || this.$v.fund.subscriptionId.$error || this.$v.fund.redemptionId.$error || this.$v.fund.noticeDays.$error ||
       this.$v.fund.lockUpMonths.$error || this.$v.fund.lockUpTypeId.$error || this.$v.fund.lockUpPercent.$error || this.$v.fund.gateWindowId.$error ||
       this.$v.fund.gatePercent.$error || this.$v.fund.managementFee.$error || this.$v.fund.performanceFee.$error || this.$v.fund.hurdle.$error ||
       this.$v.fund.ltmRevenueTypeId.$error || this.$v.fund.wilshireFeePercent.$error || this.$v.fund.wilshireCarriedIntPercent.$error || this.industryFocusesHasError)
    },
    dealTeamHasError () {
      return this.inEditMode && this.fund.isActive && this.currentState > 1 && this.fundTeams == null
    },
    industryFocusesHasError () {
      return this.inEditMode && this.fund.isActive && parseInt(this.fund.fundTypeId) === 3 && this.currentState > 1 && this.industryFocuses == null
    },
    keySeniorHasError () {
      return this.inEditMode && this.fund.isActive && this.fund.fundTypeId === 1 && this.fund.fundGroupId === 1 && this.currentState > 3 && this.keySeniors == null
    },
    radar1Data () {
      if (this.fund.stageId < 4) {
        return {
          labels: ['Organization', 'Team', 'Strategy', 'Process', 'Market', 'Performance', 'Terms'],
          datasets: [
            {
              label: 'Radar',
              borderColor: '#007bff',
              backgroundColor: 'rgba(0, 123, 255, 0.3)',
              pointBackgroundColor: '#007bff',
              data: [this.fund.organizationRadarScore / 10, this.fund.teamRadarScore / 10, this.fund.strategyRadarScore / 10, this.fund.processRadarScore / 10, this.fund.marketRadarScore / 10, this.fund.performanceRadarScore / 10, this.fund.termsRadarScore / 10]
            }
          ]
        }
      } else {
        return {
          labels: ['Organization', 'Team', 'Strategy', 'Process', 'Market', 'Performance', 'Terms',  'ESG'],
          datasets: [
            {
              label: 'Radar',
              borderColor: '#007bff',
              backgroundColor: 'rgba(0, 123, 255, 0.3)',
              pointBackgroundColor: '#007bff',
              data: [this.fund.organizationRadarScore / 10, this.fund.teamRadarScore / 10, this.fund.strategyRadarScore / 10, this.fund.processRadarScore / 10, this.fund.marketRadarScore / 10, this.fund.performanceRadarScore / 10, this.fund.termsRadarScore / 10, this.fund.esgRadarScore / 10]
            }
          ]
        }
      }
    },
    radar2Data () {
      return {
        labels: ['General Partner(s)', 'Track Record', 'Transaction', 'Portfolio', 'Potential Returns'],
        datasets: [
          {
            label: 'Radar',
            borderColor: '#007bff',
            backgroundColor: 'rgba(0, 123, 255, 0.3)',
            pointBackgroundColor: '#007bff',
            data: [this.fund.generalPartnerSecRadarScore / 10, this.fund.trackRecordSecRadarScore / 10, this.fund.transactionSecRadarScore / 10, this.fund.portfolioSecRadarScore / 10, this.fund.potentialReturnsSecRadarScore / 10]
          }
        ]
      }
    },
    radar3Data () {
      return {
        labels: ['General Partner', 'Company', 'Transaction', 'Value Creation Plan', 'Target Market', 'Potential Returns'],
        datasets: [
          {
            label: 'Radar',
            borderColor: '#007bff',
            backgroundColor: 'rgba(0, 123, 255, 0.3)',
            pointBackgroundColor: '#007bff',
            data: [this.fund.generalPartnerRadarScore / 10, this.fund.companyRadarScore / 10, this.fund.transactionRadarScore / 10, this.fund.valueCreationPlanRadarScore / 10, this.fund.targetMarketRadarScore / 10, this.fund.potentialReturnsRadarScore / 10],
            minBarLength: 0
          }
        ]
      }
    },
    canbeDeleted () {
      if (!this.fund) {
        return false
      }
      return this.fund.stageId < 3
    },
    canbeEdited () {
      // let teamMember = false
      // if (this.fundTeams != null) {
      //   teamMember = this.fundTeams.filter(x => x.userId === this.user.userId).length > 0
      // }
      // return this.user.groupId < 11 && (this.fund.stageId < 3 || (this.fund.stageId >= 3 && (teamMember || this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR || x.roleId === this.$userRoles.INVESTMENT_COMMITTEE).length > 0))) // Admin + IC
      return this.isAdmin || this.user.groupId < 11
    },
    isTeamMember () {
      const sir = this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0 // Admin
      let isMember = false
      if (this.fundTeams != null) {
        isMember = this.fundTeams.filter(x => x.userId === this.user.userId).length > 0
      }
      return isMember || sir
    },
    isAdmin () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0
    },
    isLegal () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.LEGAL_TEAM_WORKFLOW).length > 0
    },
    isConnectAdmin () {
      if (this.inEditMode) {
        return !this.user.roles.filter(x => x.roleId === this.$userRoles.CONNECT_ADMINISTRATOR).length > 0
      } else {
        return true
      }
    },
    showDDButtons () {
      // Status = Preliminary DD or Full DD or Approved AND
      // (Target Portfolio = Advisory OR Fund is Marketable Alt) OR...
      // Cumulative Initial Allocation Interest must be > $0 and all initial portfolio allocation rationale should be submitted for eligible vehicles
      //      return this.fund.isAllocationApproved || this.fund.stageId >= 3 && this.fund.targetPortfolioId === 1 || (this.fund.stageId >=3 && this.fund.initialAllocationTotal > 0 && this.fund.isAllRationalesInitiated)
      return this.fund.isAllocationApproved || this.fund.stageId >= 3 && (this.fund.targetPortfolioId === 1 || this.fund.fundGroupId == 2) || (this.fund.stageId >=3 && this.fund.initialAllocationTotal > 0 && this.fund.isAllRationalesInitiated)
    },
    // background colors for fund monitoring select
    // :style="{background: monitoringColors[fundMonitoring.investmentStrategyScore]}"
    organizationColor () { return { backgroundColor: this.monitoringColors[this.fundMonitoring.organizationScore] } },
    teamColor () { return { backgroundColor: this.monitoringColors[this.fundMonitoring.teamScore] } },
    investmentStrategyColor () { return { backgroundColor: this.monitoringColors[this.fundMonitoring.investmentStrategyScore] } },
    processColor () { return { backgroundColor: this.monitoringColors[this.fundMonitoring.processScore] } },
    marketColor () { return { backgroundColor: this.monitoringColors[this.fundMonitoring.marketScore] } },
    performanceColor () { return { backgroundColor: this.monitoringColors[this.fundMonitoring.performanceScore] } },
    legalColor () { return { backgroundColor: this.monitoringColors[this.fundMonitoring.legalScore] } },
    esgColor () { return { backgroundColor: this.monitoringColors[this.fundMonitoring.esgScore] } },
    ratioStatusColor () {
      return this.ratioStatusColors[this.fundMonitoring.ratioAndPerformanceStatus]
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    },
    formatCurrency (number) {
      if(!number & number !== 0) return
      // if(number % 1 == 0)
      const usCurrencyFormat = new Intl.NumberFormat('en-US', {
        // style: "currency",
        // currency: "USD",
        currencySign: "accounting",
        minimumFractionDigits: number % 1 == 0 ? 0 : 2
      });
      return usCurrencyFormat.format(number)
    },
    formatPercent (number) {
      const percentFormat = new Intl.NumberFormat('en-US', {
        style: "percent",
        // currency: "USD",
        // currencySign: "accounting",
        // minimumFractionDigits: number % 1 == 0 ? 0 : 2
      });
      return percentFormat.format(number)
    },
    formatDate (date) {
      if (!date) return
      return moment(date).format('MM/DD/YYYY')
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.e-multiselect.e-input-group .e-multi-select-wrapper {
    background-color: red;
}
.text-black {
  color: black
}
.dynamic-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>