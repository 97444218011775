<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'funds-sor'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Deals'" :icon="'mdi mdi-square-inc-cash'" />
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box table-responsive sourcing-cart">
                                <h4 class="header-title">Sourcing new Deals</h4>
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-6 form-inline">
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                    </div>
                                </div>

                                <div class="row mb-3">
                                  <div class="col-sm-12 col-md-6">
                                    <div class="dt-buttons btn-group">
                                      <a class="btn btn-secondary buttons-html5" href="javascript:void(0)" @click="startSearch(0)" :class="{'btn-primary' : geoId == 0}">
                                        <span>All</span>
                                      </a>
                                      <a class="btn btn-secondary buttons-html5" href="javascript:void(0)" v-for="geo in allGeoFocuses" :key="geo.geographicId" 
                                      @click="startSearch(geo.geographicId)" :class="{'btn-primary' : geoId == geo.geographicId}">
                                        <span>{{geo.geographicName}}</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                                    <thead>
                                    <tr role="row">
                                        <th @click="setOrder(0)"> Name <i class="mdi mdi-open-in-new"></i>
                                          <!-- <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(1)"> Firm
                                          <!-- <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(2)"> Geography
                                          <!-- <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(3)"> Type
                                          <!-- <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(4)"> Sector
                                          <!-- <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(5)"> Sector Description
                                          <!-- <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(7)">Expected Start Date
                                          <!-- <i v-if="sortIndex == 7 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 7 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(8)">Expected Close Date
                                          <!-- <i v-if="sortIndex == 8 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 8 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th @click="setOrder(9)">Target Size
                                          <!-- <i v-if="sortIndex == 9 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 9 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i> -->
                                        </th>
                                        <th>Created On</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                            
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <td colspan="13">
                                          Loading <i class="fa fa-spinner fa-spin"></i>
                                        </td>
                                      </tr>
                                       <tr role="row" v-for="(fund, index) in funds" :key="index">
                                           <td>
                                               <router-link :to="{name: 'FundsDetail', params: {id: fund.fundId}}" target="_blank">
                                                   {{ fund.fundName }}
                                               </router-link>
                                           </td>
                                          <td>{{ fund.firmName }}</td>
                                          <td>{{ fund.geographicName }}</td>
                                          <td>{{ fund.typeName }}</td>
                                          <td>{{ fund.sectorName }}</td>
                                          <td>{{ fund.sectorDescription }}</td>
                                          <td> <AppDate :date="fund.expectedStartDate != null ? fund.expectedStartDate : ''" :show="'text'" /> </td>
                                          <td>
                                          <span v-if="fund.isOpenEnded">Open Ended</span>
                                            <AppDate v-else :date="fund.expectedCloseDate != null ? fund.expectedCloseDate : ''" :show="'text'" />
                                          </td>
                                          <td>{{ fund.currencySymbol }}{{ thousandNumber(fund.targetSize) }}</td>
                                          <td><AppDate :date="fund.createdOn" :show="'text'" /></td>
                                          <td class="sourcing-assign-col">                                           
                                            <div class="multiselect">
                                            <select id="fundTier" class="form-control form-control-sm" v-model="fundTierList[index]">
                                                <option value="-1">Select Sourcing</option>
                                                <option :value="t.fundTierId" v-for="t in allFundTiers" :key="t.fundTierId">{{t.fundTierName}}</option>
                                            </select>
                                            </div>
                                            <multiselect v-model="assignList[index]" :options="allActiveUsers" label="fullName" track-by="userId" placeholder="Assign to"></multiselect>
                                          </td>
                                       </tr>
                                    </tbody>
                                </table>

                                <div class="row soursing-footer">
                                  <div class="col-sm-12 col-md-7 col-lg-7">
                                    <div class="" role="status">Total: {{ totalRecords }} deals</div>
                                    <div class="" role="status">Selected: {{ selectCount }} deals</div>
                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5 text-right">
                                      <button type="reset" class="btn btn-secondary waves-effect m-l-5  mr-2" @click.prevent="reset">
                                          Reset
                                      </button>
                                      <button class="btn btn-primary waves-effect waves-light" type="submit" @click.prevent="save" v-if="canSumbit" :disabled="selectCount == 0">
                                          Submit
                                      </button>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end container -->
        </div>
        <!-- end content -->

        <Footer />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { FundService } from '@/services/fund-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      geoId: 0,
      isLoading: false,
      funds: [],
      searchName: '',
      sortBy: ['FundName', 'GeographicFocusId', 'StageId', 'FundTypeId', 'SectorId', 'TargetSize', 'ExpectedCloseDate'],
      sortIndex: 0,
      sortType: 'ASC',
      searchTimeout: null,
      totalRecords: 0,
      assignList: [],
      sourcing: [],
      fundTierList: []
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    ...mapActions(['fetchAllUsers', 'fetchAllGeoFocus', 'fetchAllFundtires']),
    getList (geoId) {
      FundService.sourcing(geoId)
      .then((response) => {
        this.funds = response.data
        this.totalRecords = response.data.length
        this.isLoading = false
        this.funds.forEach((x, i) => {
          this.fundTierList[i] = -1
        })
      })
      .then(() => { this.asyncDataStatus_fetched('Deal Sourcing') })
    },
    startSearch (geoId) {
      this.assignList = []
      this.submitSearch(geoId)
    },
    submitSearch (geoId) {
      this.isLoading = true
      this.geoId = geoId
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.funds = []
      if (this.searchName == null) {
        this.searchName = ''
      }

      this.searchTimeout = setTimeout(() => {
        this.getList(geoId)
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      // this.startSearch(this.startSearch)
    },
    reset () {
      this.assignList = []
      this.fundTierList = []
    },
    save () {
      FundService.sourcingPost(this.sourcing)
      .then(() => {
        this.$snotify.success('Sourcing Submited!')
        this.startSearch(this.geoId)
      })
      .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    thousandNumber (number) {
      return number.toLocaleString()
    }
  },
  mounted () {
    this.fetchAllUsers()
    this.fetchAllGeoFocus()
    this.fetchAllFundtires()
    this.startSearch(0)
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allFundTiers () {
      return this.$store.state.allFundtires.filter(x => x.isActive === true)
    },
    allUsers () {
      return this.$store.state.allUsers
    },
    allActiveUsers () {
      return this.allUsers.filter(u => u.isActive)
    },
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    canSumbit () {
      const isAdmin = this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0
      const group = this.user.roles.filter(x => x.roleName === 'Sourcing' + this.geoId).length > 0
      return this.funds.length > 0 && (isAdmin || group)
    },
    hasSumbit () {
      return !true
    },
    selectCount () {
      this.sourcing = []
      for (let i = 0; i < this.funds.length; i++) {
        if (this.fundTierList[i] > 0) {
          this.sourcing.push({'fundId': this.funds[i].fundId, 'actionId': this.fundTierList[i], 'assignTo': 0})
        }
        if (this.assignList[i] != null) {
          this.sourcing.push({'fundId': this.funds[i].fundId, 'actionId': 3, 'assignTo': this.assignList[i].userId})
        }
      }
      return this.sourcing.length
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
