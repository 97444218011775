<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'dashboard'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Coming Soon...'"/>
                        <div class="mt-5 mb-5">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <div class="text-center">
                                            <img :src="require('/static/img/soon.gif')" alt="" height="260">
                                            <h3 class="mt-4">Stay tunned, we're launching very soon</h3>
                                            <p class="text-muted">We're making the system more awesome!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Sidebar from '@/components/TheSidebar'
import PageTitle from '@/components/ThePageTitle'
import Topbar from '@/components/TheTopbar'
import Footer from '@/components/TheFooter'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
    Sidebar,
    PageTitle,
    Topbar,
    Footer
  },
  mixins: [asyncDataStatus],
  created () {
    this.asyncDataStatus_fetched('Comming Soon...')
  }
}
</script>
