<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'meeting-add'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Meeting Minutes'" :icon="'mdi mdi-file-document'"/>
                    <div class="card-box">
                      <div class="row">
                        <div class="col-lg-6">
                          <h4 class="header-title m-t-0">Create a New: Private Markets Investment Committee</h4>
                          <p class="text-muted font-14 m-b-20">
                              Complete Meeting information.
                          </p>

                          <form action="#" autocomplete="off" class="icmeeting">
                            <div class="form-group">
                              <label for="meetingDate">
                                Meeting Date
                              </label>
                              <date-picker id="meetingDate" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="false" clear-button-icon="fa fa-times" disabled="disabled"
                                format="MM/dd/yyyy" :bootstrap-styling="true" v-model="meeting.meetingDate" @blur="$v.meeting.meetingDate.$touch()" :input-class="{ 'form-control parsley-error': $v.meeting.meetingDate.$error }"></date-picker>
                            </div>

                            <div class="form-group">
                                <label class="text-primary">Members</label> <i>select present members</i>
                                <ul>
                                  <li v-for="ic in icUsers" :key="ic.userId">
                                    <div class="checkbox checkbox-primary">
                                        <input :id="`ic`+ic.userId" type="checkbox" :value="ic.userId" v-model="selectedMembers">
                                        <label :for="`ic`+ic.userId">
                                          <AppUser :userId="ic.userId" :show="'text'" :showpic="true"/>
                                        </label>
                                    </div>
                                  </li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="text-primary">Allocation Recommendation</label>
                                <div v-if="isLoading">
                                  Loading <i class="fa fa-spinner fa-spin"></i>
                                </div>
                                <ul v-else>
                                  <li v-for="alo in funds" :key="alo.fundId">
                                    <div class="checkbox checkbox-primary">
                                        <input :id="`alo`+alo.fundId" type="checkbox" :value="alo.fundId" v-model="selectedFunds">
                                        <label :for="`alo`+alo.fundId">
                                          {{alo.fundName}}
                                        </label>
                                    </div>
                                  </li>
                                  <li v-if="funds.length == 0 && !isLoading">
                                      No Deals Avaliable
                                  </li>
                                </ul>
                            </div>
                          </form>

                          <div class="row">
                            <div class="col-lg-12 col-ms-12 text-right">
                              <button type="reset" class="btn btn-secondary waves-effect m-l-5 mr-2" @click.prevent="reset">
                                  Reset
                              </button>
                              <button class="btn btn-primary waves-effect waves-light" type="submit" @click.prevent="save" :disabled="isLoading || isSaving" v-if="canCreate">
                                <span v-if="!isSaving">Create</span>
                                <span v-else>Creating <i class="fa fa-spinner fa-spin"></i></span>
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { AllocationService } from '@/services/allocation-service'
import { IcMeetingPmIcService } from '@/services/icmeeting-service'

export default {
  components: {
    DatePicker
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      meeting: {meetingDate: new Date()},
      funds: [],
      selectedFunds: [],
      selectedMembers: [],
      isLoading: true,
      isSearchingList: false,
      fundObj: null,
      fundObj2: null,
      isSaving: false
    }
  },
  validations: {
    meeting: {
      meetingDate: { required }
    }
  },
  methods: {
    ...mapActions(['fetchLiveICUsers']),
    getFunds () {
      AllocationService.getForMeetingPMIc()
      .then(
        (response) => {
          this.funds = response.data
          this.isLoading = false
        }
      )
    },
    save () {
      const t = this.icUsers.length
      const s = this.selectedMembers.length
      if (s < t / 2) {
        this.$snotify.warning('Quorum does not meet!')
      } else {
        const icm = {'meetingDate': this.meeting.meetingDate, 'newMembers': this.selectedMembers, 'newFunds': this.selectedFunds}
        this.isSaving = true
        IcMeetingPmIcService.add(icm)
        .then(
          (response) => {
            this.$snotify.success('Meeting created.')
            setTimeout(() => {
              this.isSaving = false
              this.$router.push({name: 'IcMeetingIcPM', params: {id: response.data.meetingPmIcId}})
            }, 500)
          })
          .catch(function (error) {
            console.error('SERVER Error:', error)
          })
      }
    },
    reset () {
      this.selectedFunds = []
      this.selectedMembers = []
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    icUsers () {
      return this.$store.state.icUsers
    },
    selectCount () {
      return this.selectedFunds.length
    },
    canCreate () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.MEETING_MINUTE_TAKER || x.roleId === this.$userRoles.INVESTMENT_COMMITTEE || x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0 // Meeting Minute Taker + Investment Committee + Admin
    }
  },
  created () {
    this.fetchLiveICUsers()
    this.getFunds()
    this.asyncDataStatus_fetched('Create a New Private Markets Investment Committee Meeting')
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
