<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portfolio-overview'"/>
        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Portfolio Overview'" :icon="'mdi mdi-briefcase'" />
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box table-responsive">
                                <h4 class="m-t-0 header-title mb-4">Portfolio Overview</h4>
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-6 form-inline mb-2">
                                      <button v-for="client in allClients" :key="client.id" type="button" class="btn btn-rounded w-md waves-effect waves-light mr-2" @click="selectClient(client.id)" :class="{'btn-primary' : clientSelected(client.id) }">
                                        {{client.name}}
                                      </button>
                                      <button type="button" class="btn btn-rounded waves-effect waves-light btn-success" @click="clearClient" v-show="selectedClients.length > 0" v-tooltip="'clear Client'">
                                        <i class="mdi mdi-delete"></i>
                                      </button>
                                    </div>
                                    <div class="col-sm-12 col-md-12 form-inline mb-2">
                                      <button v-for="year in years" :key="year" type="button" class="btn btn-rounded w-md waves-effect waves-light mr-2" @click="selectYear(year)" :class="{'btn-primary' : yearSelected(year), 'mb-1' : years.length > 10 }">
                                        {{year}}
                                      </button>
                                      <button type="button" class="btn btn-rounded waves-effect waves-light btn-success" @click="clearYear" v-show="selectedYears.length > 0" v-tooltip="'clear Year'">
                                        <i class="mdi mdi-delete"></i>
                                      </button>
                                    </div>
                                    <div class="col-sm-12 col-md-12 form-inline mb-2">
                                      <button v-for="geo in allGeoFocuses" :key="geo.geographicId" type="button" class="btn btn-rounded w-md waves-effect waves-light mr-2" @click="selectGeo(geo.geographicId)" :class="{'btn-primary' : geoSelected(geo.geographicId) }">
                                        {{geo.geographicName}}
                                      </button>
                                      <button type="button" class="btn btn-rounded waves-effect waves-light btn-success" @click="clearGeo" v-show="selectedGeos.length > 0" v-tooltip="'clear Geography'">
                                        <i class="mdi mdi-delete"></i>
                                      </button>
                                    </div>
                                    <div class="col-sm-12 col-md-12 form-inline mb-2">
                                      <button v-for="type in allTypes" :key="type.typeId" type="button" class="btn btn-rounded w-md waves-effect waves-light mr-2" @click="selectType(type.typeId)" :class="{'btn-primary' : typeSelected(type.typeId) }">
                                        {{type.typeName}}
                                      </button>
                                      <button type="button" class="btn btn-rounded waves-effect waves-light btn-success" @click="clearType" v-show="selectedTypes.length > 0" v-tooltip="'clear Type'">
                                        <i class="mdi mdi-delete"></i>
                                      </button>
                                    </div>
                                    <div class="text-primary ml-3">Private Market</div>
                                    <div class="col-sm-12 col-md-12 form-inline mb-2">
                                      <div v-for="strategy in allTreeStrategies.filter(x => x.groupId == 1)" :key="strategy.strategyId">
                                        <span v-if="avaliableSectors.filter(x => x.strategyId == strategy.strategyId).length > 1">
                                          <button v-for="sector in avaliableSectors.filter(x => x.strategyId == strategy.strategyId)" :key="sector.sectorId" type="button" class="btn btn-rounded w-md waves-effect waves-light mr-2 mb-1" @click="selectSector(sector.sectorId)" :class="{'btn-primary' : sectorSelected(sector.sectorId) }">
                                            {{strategy.strategyName}} > {{sector.sectorName}}
                                          </button>
                                        </span>
                                        <span v-else>
                                          <button v-for="sector in avaliableSectors.filter(x => x.strategyId == strategy.strategyId)" :key="sector.sectorId" type="button" class="btn btn-rounded w-md waves-effect waves-light mr-2 mb-1" @click="selectSector(sector.sectorId)" :class="{'btn-primary' : sectorSelected(sector.sectorId) }">
                                            {{strategy.strategyName}}
                                          </button>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="text-primary ml-3">Alts</div>
                                    <div class="col-sm-12 col-md-12 form-inline mb-2">
                                      <div v-for="strategy in allTreeStrategies.filter(x => x.groupId == 2)" :key="strategy.strategyId">
                                        <span v-if="avaliableSectors.filter(x => x.strategyId == strategy.strategyId).length > 1">
                                          <button v-for="sector in avaliableSectors.filter(x => x.strategyId == strategy.strategyId)" :key="sector.sectorId" type="button" class="btn btn-rounded w-md waves-effect waves-light mr-2 mb-1" @click="selectSector(sector.sectorId)" :class="{'btn-primary' : sectorSelected(sector.sectorId) }">
                                             {{strategy.strategyName}} > {{sector.sectorName}}
                                          </button>
                                        </span>
                                        <span v-else>
                                          <button v-for="sector in avaliableSectors.filter(x => x.strategyId == strategy.strategyId)" :key="sector.sectorId" type="button" class="btn btn-rounded w-md waves-effect waves-light mr-2 mb-1" @click="selectSector(sector.sectorId)" :class="{'btn-primary' : sectorSelected(sector.sectorId) }">
                                            {{strategy.strategyName}}
                                          </button>
                                        </span>
                                      </div>
                                      <button type="button" class="btn btn-rounded waves-effect waves-light btn-success" @click="clearSector" v-show="selectedSectors.length > 0" v-tooltip="'clear Sector'">
                                        <i class="mdi mdi-delete"></i>
                                      </button>
                                    </div>
                                    <div class="col-sm-12 col-md-6 mb-2">
                                      <div class="input-group col-sm-12 col-md-6">
                                        <input type="search" v-model.trim="searchName" class="form-control form-control-mini" placeholder="Type to filter by Vehicle Name" v-tooltip="'Filter by Vehicle Name'">
                                        <button type="button" class="btn btn-rounded waves-effect waves-light btn-success" @click="clearName" v-show="searchName.length > 0" v-tooltip="'clear Name'">
                                          <i class="mdi mdi-delete"></i>
                                        </button>
                                        <button type="button" class="btn btn-rounded w-md waves-effect waves-light mr-2 ml-2" @click="onlyOpens = !onlyOpens" :class="{'btn-primary' : onlyOpens }">
                                          Only Open
                                        </button>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 mb-2">
                                      <span class="text-primary">{{vehiclesFilterResult.length}}</span> Vehicles found
                                    </div>
                                </div>

                                <table class="table table-bordered table-hover table-sortable">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)"> Portfolio Name
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)"> Sector
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(2)"> Vehicle Type
                                          <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(3)"> Vintage
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(4)"> Geography
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(5)"> New positions
                                          <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(6)"> Client Type
                                          <i v-if="sortIndex == 6 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 6 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(7)"> Lead
                                          <i v-if="sortIndex == 7 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 7 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(8)"> Second
                                          <i v-if="sortIndex == 8 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 8 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(9)"> Other
                                          <i v-if="sortIndex == 9 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 9 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                         
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="(item, index) in vehiclesFilterResult" :key="index">
                                          <td>
                                              <router-link :to="{name: 'PortfolioDetail', params: {id: item.portfolioId}}">
                                                {{item.portfolioName}}
                                               </router-link>
                                          </td>
                                          <td>
                                              {{item.sectorName}}
                                          </td>
                                          <td>
                                              {{item.investmentTypeName}}
                                          </td>
                                          <td>
                                              {{item.vintageYear}}
                                          </td>
                                          <td>
                                              {{item.geographicName}}
                                          </td>
                                          <td>
                                            {{ showSymbol(item.currencyId) }}{{item.targetPosition}}
                                          </td>
                                          <td>
                                              {{item.clientTypeName}}
                                          </td>
                                          <td v-if="item.fundName != null" colspan="3">
                                              {{item.fundName}} <i v-if="item.isOpsDone" class="mdi mdi-check-circle done-done" v-tooltip="'Approved'"></i>
                                              <AppDate v-if="item.opsApprovalDate" :date="item.opsApprovalDate" :show="'label'"></AppDate>
                                          </td>
                                          <td v-if="item.fundName == null">
                                            <a href="javascript:void(0)" @click.prevent="addFund(1, item.portfolioSlotId)" v-if="!item.fundName && !item.fund1Name && item.portfolioSlotId > 0 && user.groupId < 11">
                                              <button class="btn btn-primary btn-sm btn-icon" v-tooltip="'Add Deal'"><i class="mdi mdi-bookmark-plus-outline"></i></button>
                                            </a>
                                              {{item.fund1Name}}
                                          </td>
                                          <td v-if="item.fundName == null">
                                            <a href="javascript:void(0)" @click.prevent="addFund(2, item.portfolioSlotId)" v-if="!item.fundName && !item.fund2Name && item.portfolioSlotId > 0 && user.groupId < 11">
                                              <button class="btn btn-primary btn-sm btn-icon" v-tooltip="'Add Deal'"><i class="mdi mdi-bookmark-plus-outline"></i></button>
                                            </a>
                                            {{item.fund2Name}}
                                          </td>
                                          <td v-if="item.fundName == null">
                                            <a href="javascript:void(0)" @click.prevent="addFund(3, item.portfolioSlotId)" v-if="!item.fundName && !item.fund3Name && item.portfolioSlotId > 0 && user.groupId < 11">
                                              <button class="btn btn-primary btn-sm btn-icon" v-tooltip="'Add Deal'"><i class="mdi mdi-bookmark-plus-outline"></i></button>
                                            </a>
                                            {{item.fund3Name}}
                                          </td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <PortfolioAssign @onUpdate="onSlotUpdate" v-if="rightbarItem == 'portfolio-asg'" :isEditMode="sideInEditMode" :isAdd="addSidebar" :fundOrder="selectedSlotFundOrder"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PortfolioService } from '@/services/portfolio-service'
import PortfolioAssign from '@/components/portfolio/PortfolioAssign'
import rightbar from '@/mixins/rightbar'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
    PortfolioAssign
  },
  data () {
    return {
      vehicles: [],
      searchName: '',
      isLoading: false,
      sortIndex: 0,
      sortType: 'ASC',
      allClients: [{'id': 1, 'name': 'Advisory'}, {'id': 2, 'name': 'Discretionary'}],
      selectedYears: [],
      selectedTypes: [],
      selectedSectors: [],
      selectedGeos: [],
      selectedClients: [],
      onlyOpens: true,
      sideInEditMode: false,
      rightbarItem: null,
      addSidebar: null,
      selectedSlotFundOrder: null
    }
  },
  mixins: [rightbar, asyncDataStatus],
  methods: {
    ...mapActions(['fetchAllFundtypes', 'fetchAllSectors', 'fetchAllGeoFocus', 'fetchAllCurrencies', 'fetchPortfolioVehicle', 'fetchAllTreeStrategies']),
    getTables () {
      this.fetchAllFundtypes()
      this.fetchAllSectors()
      this.fetchAllTreeStrategies()
      this.fetchAllGeoFocus()
      this.fetchAllCurrencies()
      this.getList()
    },
    getList () {
      this.isLoading = true
      PortfolioService.searchVehicles('', 'portfolioName', this.sortType, 0, 0)
      .then((response) => {
        this.isLoading = false
        this.vehicles = response.data.vehicles
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Portfolios Overview') })
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
    },
    selectYear (yr) {
      const i = this.selectedYears.findIndex(y => y === yr)
      if (i < 0) {
        this.selectedYears.push(yr)
      } else {
        this.selectedYears.splice(i, 1)
      }
    },
    yearSelected (yr) {
      return this.selectedYears.findIndex(y => y === yr) >= 0
    },
    clearYear () {
      this.selectedYears = []
    },
    selectType (ty) {
      const i = this.selectedTypes.findIndex(t => t === ty)
      if (i < 0) {
        this.selectedTypes.push(ty)
      } else {
        this.selectedTypes.splice(i, 1)
      }
    },
    typeSelected (ty) {
      return this.selectedTypes.findIndex(t => t === ty) >= 0
    },
    clearType () {
      this.selectedTypes = []
    },
    selectSector (sc) {
      const i = this.selectedSectors.findIndex(s => s === sc)
      if (i < 0) {
        this.selectedSectors.push(sc)
      } else {
        this.selectedSectors.splice(i, 1)
      }
    },
    sectorSelected (sc) {
      return this.selectedSectors.findIndex(s => s === sc) >= 0
    },
    clearSector () {
      this.selectedSectors = []
    },
    selectGeo (go) {
      const i = this.selectedGeos.findIndex(g => g === go)
      if (i < 0) {
        this.selectedGeos.push(go)
      } else {
        this.selectedGeos.splice(i, 1)
      }
    },
    geoSelected (go) {
      return this.selectedGeos.findIndex(g => g === go) >= 0
    },
    clearGeo () {
      this.selectedGeos = []
    },
    selectClient (cl) {
      const i = this.selectedClients.findIndex(c => c === cl)
      if (i < 0) {
        this.selectedClients.push(cl)
      } else {
        this.selectedClients.splice(i, 1)
      }
    },
    clientSelected (cl) {
      return this.selectedClients.findIndex(c => c === cl) >= 0
    },
    clearClient () {
      this.selectedClients = []
    },
    clearName () {
      this.searchName = ''
    },
    sidebarAction (item, isAdd, isOpen, isEdit) {
      this.addSidebar = isAdd
      this.sideInEditMode = isEdit || isAdd
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    addFund (order, slotId) {
      this.fetchPortfolioVehicle({portfolioSlotId: slotId})
      this.selectedSlotFundOrder = order
      this.sidebarAction('portfolio-asg', true, true, true)
    },
    onSlotUpdate (slot) {
      const i = this.vehiclesFilterResult.findIndex(x => x.portfolioSlotId === slot.portfolioSlotId)
      if (i >= 0) {
        switch (slot.fundOrder) {
          case 1:
            this.vehiclesFilterResult[i].fund1Name = slot.fundName
            this.vehiclesFilterResult[i].fund1Id = slot.fundId
            break
          case 2:
            this.vehiclesFilterResult[i].fund2Name = slot.fundName
            this.vehiclesFilterResult[i].fund2Id = slot.fundId
            break
          case 3:
            this.vehiclesFilterResult[i].fund3Name = slot.fundName
            this.vehiclesFilterResult[i].fund3Id = slot.fundId
            break
        }
        this.sidebarAction('', false, false)
      }
    },
    showSymbol (cur) {
      return this.allCurrencies.find(x => x.currencyId === cur).symbol
    }
  },
  created () {
    this.getTables()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allTypes () {
      return this.$store.state.allFundtypes
    },
    allSectors () {
      return this.$store.state.allSectors
    },
    allTreeStrategies () {
      return this.$store.state.allTreeStrategies
    },
    avaliableSectors () {
      const result = []
      for (const item of this.allSectors) {
        if (this.vehicles.map(x => x.sectorId).indexOf(item.sectorId) >= 0) {
          result.push(item)
        }
      }
      return result
    },
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    years () {
      let yrs = []
      const ly = new Date().getFullYear()
      for (let y = 2017; y < ly + 5; y++) {
        yrs.push(y)
      }
      return yrs
    },
    vehiclesFilterResult () {
      let result = this.vehicles

      // onlyOpens
      if (this.onlyOpens) {
        result = result.filter(function (item) {
          return item.fundName == null
        })
      }

      // Years
      if (this.selectedYears.length > 0) {
        const years = this.selectedYears
        result = result.filter(function (item) {
          return years.indexOf(item.vintageYear) >= 0
        })
      }

      // Type
      if (this.selectedTypes.length > 0) {
        const types = this.selectedTypes
        result = result.filter(function (item) {
          return types.indexOf(item.investmentTypeId) >= 0
        })
      }

      // Sectors
      if (this.selectedSectors.length > 0) {
        const sectors = this.selectedSectors
        result = result.filter(function (item) {
          return sectors.indexOf(item.sectorId) >= 0
        })
      }

      // Geos
      if (this.selectedGeos.length > 0) {
        const geos = this.selectedGeos
        result = result.filter(function (item) {
          return geos.indexOf(item.geographicId) >= 0
        })
      }

      // Clients
      if (this.selectedClients.length > 0) {
        const clients = this.selectedClients
        result = result.filter(function (item) {
          return clients.indexOf(item.clientTypeId) >= 0
        })
      }

      // Name
      if (this.searchName.length > 0) {
        const name = this.searchName.toLowerCase()
        result = result.filter(function (item) {
          return item.portfolioName.toLowerCase().indexOf(name) >= 0
        })
      }

      // Sort
      switch (this.sortIndex) {
        case 0:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.portfolioName > b.portfolioName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.portfolioName < b.portfolioName) ? 1 : -1)
          }
          break
        case 1:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.sectorName > b.sectorName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.sectorName < b.sectorName) ? 1 : -1)
          }
          break
        case 2:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.investmentTypeName > b.investmentTypeName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.investmentTypeName < b.investmentTypeName) ? 1 : -1)
          }
          break
        case 3:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.vintageYear > b.vintageYear) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.vintageYear < b.vintageYear) ? 1 : -1)
          }
          break
        case 4:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.geographicName > b.geographicName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.geographicName < b.geographicName) ? 1 : -1)
          }
          break
        case 5:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.targetPosition > b.targetPosition) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.targetPosition < b.targetPosition) ? 1 : -1)
          }
          break
        case 6:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.clientTypeName > b.clientTypeName) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.clientTypeName < b.clientTypeName) ? 1 : -1)
          }
          break
        case 7:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.fund1Name > b.fund1Name) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.fund1Name < b.fund1Name) ? 1 : -1)
          }
          break
        case 8:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.fund2Name > b.fund2Name) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.fund2Name < b.fund2Name) ? 1 : -1)
          }
          break
        case 9:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.fund3Name > b.fund3Name) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.fund3Name < b.fund3Name) ? 1 : -1)
          }
          break
      }
      return result
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
