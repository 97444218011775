<template>
  <tbody>
    <tr v-if="industryFocuses === null">
      <td colspan="3" class="text-center">No Data to show</td>
    </tr>
    <tr v-for="industry in industryFocuses"
        :key="industry.fundsIndustryFocusId">
        <td><a @click.prevent="select(industry.fundsIndustryFocusId)" href="javascript:void(0)">{{industry.industryFocusName}}</a></td>
        <td>{{industry.industryGroupName}}</td>
        <td class="action-col">
            <button class="btn btn-success btn-sm btn-mini btn-icon mr-1" v-if="canEdit" @click.prevent="update(industry.fundsIndustryFocusId)" v-tooltip="'Update'"><i class="mdi mdi-lead-pencil"></i></button>
            <button class="btn btn-danger btn-sm btn-mini btn-icon" v-if="canEdit" @click.prevent="onDelete(industry.fundsIndustryFocusId)" v-tooltip="'Delete'"><i class="mdi mdi-delete"></i></button>
        </td>
    </tr>
    <tr>
      <td colspan="3">
        <button v-if="canEdit" class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addNew"> <i class="mdi mdi-bell-plus"></i> Add</button>
      </td>
    </tr>
  </tbody>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    industryFocuses: {
      type: Array,
      default: null
    },
    isEditMode: {
      required: true,
      type: Boolean
    },
    canEdit: {
      default: false,
      type: Boolean
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['deleteIndustryFocus']),
    addNew () {
      this.hideRightbar()
      this.$emit('onClickNew')
    },
    select (id) {
      this.$emit('onSelect', id)
    },
    update (id) {
      this.$emit('onSelectUpdate', id)
    },
    onDelete (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deleteIndustryFocus({fundsIndustryFocusId: id})
                .then((response) => {
                  this.$snotify.success('Industry Focus Deleted.')
                  this.$emit('onUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  }
}
</script>
