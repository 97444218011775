<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portfolio-screening'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Allocations'" :icon="'mdi mdi-briefcase'" />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card-box">
                          <div class="tab-content">
                            <div class="tab-pane active">
                                <div class="row">
                                  <div class="card card-box col-md-6">
                                    <h4 class="m-t-0 header-title m-b-20">Allocations in the voting process</h4>
                                    <table class="table table-striped table-bordered table-sm no-footer">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Deal <i class="mdi mdi-open-in-new"></i></th>
                                            <th>Start Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-if="allocationsInVoting.length == 0">
                                            <td colspan="3" scope="row">No Data to Show</td>
                                          </tr>
                                          <tr v-for="(item, index) in allocationsInVoting" :key="index">
                                              <td scope="row">{{index+1}}</td>
                                              <td>
                                                <router-link :to="{name: 'FundsDetail', params: {id: item.fundId}}" target="_blank">
                                                    {{ item.fundName }}
                                                </router-link>
                                              </td>
                                              <td>
                                                <AppDate :date="item.createdOn" :show="'span'"/>
                                              </td>
                                          </tr>
                                        </tbody>
                                    </table>
                                  </div>
                                </div>
                                
                                <div class="row">
                                  <div class="card card-box col-md-6">
                                    <h4 class="m-t-0 header-title m-b-20">Deals with Activated Allocations in progress</h4>
                                    <table class="table table-striped table-bordered table-sm no-footer">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Deal <i class="mdi mdi-open-in-new"></i></th>
                                            <th>Eligible Vehicles</th>
                                            <th>Initial Allocations Submitted</th>
                                            <th>Final Allocations Submitted</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-if="allocationsInAction.length == 0">
                                            <td colspan="5" scope="row">No Data to Show</td>
                                          </tr>
                                          <tr v-for="(item, index) in allocationsInAction" :key="index">
                                              <td scope="row">{{index+1}}</td>
                                              <td>
                                                <router-link :to="{name: 'FundsDetail', params: {id: item.fundId}}" target="_blank">
                                                    {{ item.fundName }}
                                                </router-link>
                                              </td>
                                              <td>{{ item.allCnt }}</td>
                                              <td>
                                                <span class="text-primary">
                                                  {{ item.filledCnt }}
                                                </span>
                                                <i v-if="getMissingPortfolios(item.fundId).length > 0 && collapsed.indexOf(item.fundId) < 0" @click="openClose(item.fundId)" class="mdi mdi-arrow-up-drop-circle-outline" v-tooltip="'Expand to see Missing Portfolios'"></i>
                                                <b v-if="collapsed.indexOf(item.fundId) >= 0" @click="openClose(item.fundId)" class="mdi mdi-arrow-down-drop-circle-outline">Missing</b>
                                                <ul v-if="collapsed.indexOf(item.fundId) >= 0">
                                                  <li v-for="(missing, index) in getMissingPortfolios(item.fundId)" :key="index">{{missing.portfolioName}}</li>
                                                </ul>
                                                <span class="badge badge-pill badge-secondary ml-2" v-tooltip="'Initial Allocation Total'">{{item.currencySymbol}} {{item.initialAllocationTotal}}</span>
                                              </td>
                                              <td>
                                                <span class="text-primary">
                                                  {{ item.finalizedCnt }}
                                                </span>
                                                <i v-if="getMissingFinalPortfolios(item.fundId).length > 0 && collapsed.indexOf(item.fundId) < 0" @click="openClose(item.fundId)" class="mdi mdi-arrow-up-drop-circle-outline" v-tooltip="'Expand to see Missing Portfolios'"></i>
                                                <b v-if="collapsed.indexOf(item.fundId) >= 0" @click="openClose(item.fundId)" class="mdi mdi-arrow-down-drop-circle-outline">Missing</b>
                                                <ul v-if="collapsed.indexOf(item.fundId) >= 0">
                                                  <li v-for="(missing, index) in getMissingFinalPortfolios(item.fundId)" :key="index">{{missing.portfolioName}}</li>
                                                </ul>
                                                <span class="badge badge-pill badge-secondary ml-2" v-tooltip="'Final Allocation Total'">{{item.currencySymbol}} {{item.finalAllocationTotal}}</span>
                                              </td>
                                          </tr>
                                        </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="card card-box col-md-6">
                                    <h4 class="m-t-0 header-title m-b-20">Portfolios in the voting process</h4>
                                    <table class="table table-striped table-bordered table-sm no-footer">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Portfolio <i class="mdi mdi-open-in-new"></i></th>
                                            <th>Type</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-if="portfolioScreenings.length == 0">
                                            <td colspan="3" scope="row">No Data to Show</td>
                                          </tr>
                                          <tr v-for="(item, index) in portfolioScreenings" :key="index">
                                              <td scope="row">{{index+1}}</td>
                                              <td>
                                                <router-link :to="{name: 'PortfolioDetail', params: {id: item.portfolioId}}" target="_blank">
                                                    {{ item.portfolioName }}
                                                </router-link>
                                              </td>
                                              <td>{{ item.typeName }}</td>
                                          </tr>
                                        </tbody>
                                    </table>
                                  </div>
                                </div>

                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <!-- end container -->
        </div>
        <!-- end content -->
        <Footer />
    </div>
</template>

<script>
import { PortfolioService } from '@/services/portfolio-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  data () {
    return {
      allocationsInVoting: [],
      allocationsInAction: [],
      portfolioScreenings: [],
      notSubmitedPortfolios: [],
      notFinalizedPortfolios: [],
      collapsed: []
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    getList () {
      PortfolioService.screeningReport()
      .then((response) => {
        this.allocationsInVoting = response.data.allocationsInVoting
        this.allocationsInAction = response.data.allocationsInAction
        this.portfolioScreenings = response.data.portfolioScreenings
        this.notSubmitedPortfolios = response.data.missingPortfolios
        this.notFinalPortfolios = response.data.missingFinalPortfolios
      })
      .then(() => { this.asyncDataStatus_fetched('Allocations Screening') })
    },
    getMissingPortfolios (fundId) {
      return this.notSubmitedPortfolios.filter(x => x.fundId === fundId)
    },
    getMissingFinalPortfolios (fundId) {
      return this.notFinalPortfolios.filter(x => x.fundId === fundId)
    },
    openClose (id) {
      const idx = this.collapsed.indexOf(id)
      if (idx < 0) {
        this.collapsed.push(id)
      } else {
        this.collapsed.splice(idx, 1)
      }
    }
  },
  mounted () {
    this.getList()
  }
}
</script>
