import Constants from '../constants.js'
import Axios from 'axios'
import { PublicClientApplication } from '@azure/msal-browser'
import { UserService } from '@/services/user-service'
import { router } from '../router/index.js'

export const UmbrellaMsalService = {
    login(redirectTo) {
      
        let msalInstance = new PublicClientApplication(
            router.app.$store.state.msalConfig
          )
          router.app.$store.commit('setMsalInstance', msalInstance)
          const accounts = msalInstance.getAllAccounts()
          if (accounts.length === 0) {
            router.app.$store.commit('setActiveUser', null)
            msalInstance
              .loginPopup({})
              .then(() => {
                //console.log(' msalInstance.getAllAccounts()',  msalInstance.getAllAccounts().filter(x=>x.username.indexOf("@wilshire.com")>0))
                const myAccount = msalInstance.getAllAccounts().filter(x=>x.username.indexOf("@wilshire.com")>0)[0]
                const accessTokenRequest = {
                  scopes: Constants.API_SCOPES,
                  account: myAccount,
                  extraQueryParameters:{loginHint:myAccount.username}
                }
                msalInstance
                .acquireTokenSilent(accessTokenRequest)
                .then(function (accessTokenResponse) {
                  let accessToken = accessTokenResponse.accessToken
                  localStorage.setItem('auth_token', accessToken)
                  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
                  UserService.current()
                  .then((userResponse) => {
                    if (userResponse!= null && userResponse.data !== '') {
                      var userData = JSON.stringify(userResponse.data)
                      sessionStorage.setItem('currentUser', userData)
                      router.app.$store.commit('setActiveUser', JSON.parse(userData))
                      router.push(redirectTo)
                    }
                  })
                })
                .catch(error => {
                  console.error(`error during getting access token: ${error}`)
                  router.push({name: 'Restricted'})
                })
              })
              .catch(error => {
                console.error(`error during authentication: ${error}`)
                router.push({name: 'Restricted'})
              })
          } else {
            //console.log(' msalInstance.getAllAccounts()',  msalInstance.getAllAccounts().filter(x=>x.username.indexOf("@wilshire.com")>0))
            const myAccount = msalInstance.getAllAccounts().filter(x=>x.username.indexOf("@wilshire.com")>0)[0]
            router.app.$store.commit('setMsalInstance', msalInstance)
            const accessTokenRequest = {
              scopes: Constants.API_SCOPES,
              account: myAccount,
              extraQueryParameters:{loginHint:myAccount.username}
            }
            msalInstance
            .acquireTokenSilent(accessTokenRequest)
            .then(function (accessTokenResponse) {
              let accessToken = accessTokenResponse.accessToken
              localStorage.setItem('auth_token', accessToken)
  
              Axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
              UserService.current()
              .then((userResponse) => {
                if (userResponse!= null && userResponse.data !== '') {
                  var userData = JSON.stringify(userResponse.data)
                  sessionStorage.setItem('currentUser', userData)
                  router.app.$store.commit('setActiveUser', JSON.parse(userData))
                  router.push(redirectTo)
                }
              })
            })
            .catch(error => {
              router.app.$store.commit('setActiveUser', null)
            msalInstance
              .loginPopup({})
              .then(() => {
                //console.log(' msalInstance.getAllAccounts()',  msalInstance.getAllAccounts().filter(x=>x.username.indexOf("@wilshire.com")>0))
                const myAccount = msalInstance.getAllAccounts().filter(x=>x.username.indexOf("@wilshire.com")>0)[0]
                const accessTokenRequest = {
                  scopes: Constants.API_SCOPES,
                  account: myAccount,
                  extraQueryParameters:{loginHint:myAccount.username}
                }
                msalInstance
                .acquireTokenSilent(accessTokenRequest)
                .then(function (accessTokenResponse) {
                  let accessToken = accessTokenResponse.accessToken
                  localStorage.setItem('auth_token', accessToken)
                  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
                  UserService.current()
                  .then((userResponse) => {
                    if (userResponse!=null && userResponse.data !== '') {
                      var userData = JSON.stringify(userResponse.data)
                      sessionStorage.setItem('currentUser', userData)
                      router.app.$store.commit('setActiveUser', JSON.parse(userData))
                      router.push(redirectTo)
                    }
                  })
                })
                .catch(error => {
                  console.error(`error during getting access token: ${error}`)
                  router.push({name: 'Restricted'})
                })
              })
              .catch(error => {
                console.error(`error during authentication: ${error}`)
                router.push({name: 'Restricted'})
              })
            })
          }
        
    }
}