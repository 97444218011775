import { FundinactivereasonService } from '@/services/fundinactivereason-service'
import { UserService } from '@/services/user-service'
import { OfficeService } from '@/services/office-service'
import { ContactService } from '@/services/contact-service'
import { FirmService, FirmContactService, FirmAnnualMeetingService } from '@/services/firm-service'
import { FundTypeService } from '@/services/fundtype-service'
import { FundTierService } from '@/services/fundtier-service'
import { StrategyService, SectorService, SubSectorService } from '@/services/sector-service'
import { GeographyService } from '@/services/geography-service'
import { CurrencyService } from '@/services/currency-service'
import { MonitoringtypeService } from '@/services/monitoringtype-service'
import { BoardseatService } from '@/services/boardseat-service'
import { MngfeestructureService } from '@/services/managementfeestructure-service'
import { FundperformancesourceService } from '@/services/fundperformancesource-service'
import { ActivityTypeService, ActivityService } from '@/services/activity-service'
import { FundTeamRoleService } from '@/services/fundteamrole-service'
import { IndustryFocusService } from '@/services/industryfocus-service'
import { CountryService } from '@/services/country-service'
import { FirmTypeService } from '@/services/firmtype-service'
import { SecOutreachTierService } from '@/services/secoutreachtiers-service'
import { FirmClientTypeService } from '@/services/firmclienttype-service'
import { FirmOwnershipTypeService } from '@/services/firmownershiptype-service'
import { AnnualMeetingAttendanceService } from '@/services/annualmeetingattendance-service'
import { FundTrackRecordService, FundService, FundTeamService, FundIndustryFocusService, FundRegionalFocusService, FundMeritConcernService, FundOpportunityService, FundVotingService, FundKeySeniorService, FundOnsiteMeetingService, SecFundService, NotesService } from '@/services/fund-service'
import { LegalRequestCategoryService } from '@/services/legalrequestcategory-service'
import { LegalRequestStatusService } from '@/services/legalrequeststatus-service'
import { InvestingService } from '@/services/investing-service'
import { QuartilesService } from '@/services/quartile-service'
import { CalMeetingService } from '@/services/calmeeting-service'
import { FileService, FilePurposeService } from '@/services/file-service'
import { PortfolioService, PortfolioSectorService, PortfolioSlotService, PortfolioYearService, PortfolioGeoService, PortfolioInvService } from '@/services/portfolio-service'
import { OprInvestorService, OprServiceProviderService } from '@/services/opsteam-service'
import { LockupTypesService } from '../services/lockuptypes-service'
import { TimeWinowService } from '../services/timewindow-service'
import { RedemptionsService } from '../services/redemptions-service'
import { SubscriptionsService } from '../services/subscriptions-service'
// import { codePointAt } from 'core-js/core/string'

export default {
  fetchAuthUser ({commit}) {
    return new Promise((resolve, reject) => {
      UserService.current()
      .then((userResponse) => {
        if (userResponse.data !== '') {
          sessionStorage.setItem('currentUser', JSON.stringify(userResponse.data))
        }
        commit('setActiveUser', userResponse.data)
        resolve(userResponse.data)
      })
      .catch(() => resolve(null))
    })
  },
  fetchAllUsers ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Users')
      if (list != null) {
        list = JSON.parse(list)
        commit('setUsers', list)
      } else {
        UserService.getAll()
        .then((response) => {
          commit('setUsers', response.data)
          localStorage.setItem('Users', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllUserGroups ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('UserGroups')
      if (list != null) {
        list = JSON.parse(list)
        commit('setUserGroups', list)
        resolve(list)
      } else {
        UserService.getAllGroups()
        .then((response) => {
          commit('setUserGroups', response.data)
          localStorage.setItem('UserGroups', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllUserRoles ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('UserRoles')
      if (list != null) {
        list = JSON.parse(list)
        commit('setUserRoles', list)
        resolve(list)
      } else {
        UserService.getAllRoles()
        .then((response) => {
          commit('setUserRoles', response.data)
          localStorage.setItem('UserRoles', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllUserTitles ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Titles')
      if (list != null) {
        list = JSON.parse(list)
        commit('setTitles', list)
        resolve(list)
      } else {
        UserService.getAllTitles()
        .then((response) => {
          commit('setTitles', response.data)
          localStorage.setItem('Titles', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllOffices ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Offices')
      if (list != null) {
        list = JSON.parse(list)
        commit('setOffices', list)
        resolve(list)
      } else {
        OfficeService.getAll()
        .then((response) => {
          commit('setOffices', response.data)
          localStorage.setItem('Offices', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchICUsers ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('ICUsers')
      if (list != null) {
        list = JSON.parse(list)
        commit('setICUsers', list)
      } else {
        UserService.getByRoleId(3)
        .then((response) => {
          commit('setICUsers', response.data)
          localStorage.setItem('ICUsers', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchLiveICUsers ({commit}) {
    return new Promise((resolve, reject) => {
      UserService.getByRoleId(19)
      .then((response) => {
        commit('setICUsers', response.data)
        localStorage.setItem('ICUsers', JSON.stringify(response.data))
        resolve(response.data)
      })
      .catch(() => resolve(null))
    })
  },
  fetchLiveMAUsers ({commit}) {
    return new Promise((resolve, reject) => {
      UserService.getByRoleId(18)
      .then((response) => {
        commit('setMAUsers', response.data)
        localStorage.setItem('MAUsers', JSON.stringify(response.data))
        resolve(response.data)
      })
      .catch(() => resolve(null))
    })
  },
  fetchLivePMMRUsers ({commit}) {
    return new Promise((resolve, reject) => {
      UserService.getByRoleId(3)
      .then((response) => {
        commit('setPMMRUsers', response.data)
        localStorage.setItem('PMmrUsers', JSON.stringify(response.data))
        resolve(response.data)
      })
      .catch(() => resolve(null))
    })
  },
  fetchAllFundInactiveReasons ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('FundInactiveReasons')
      if (list != null) {
        list = JSON.parse(list)
        commit('setFundinactiveReasons', list)
      } else {
        FundinactivereasonService.getAll()
        .then((response) => {
          commit('setFundinactiveReasons', response.data)
          localStorage.setItem('FundInactiveReasons', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllOprInvestors ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('OprInvestors')
      if (list != null) {
        list = JSON.parse(list)
        commit('setOprInvestors', list)
      } else {
        OprInvestorService.getAll()
        .then((response) => {
          commit('setOprInvestors', response.data)
          localStorage.setItem('OprInvestors', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllOprServiceProviders ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('OprServiceProviders')
      if (list != null) {
        list = JSON.parse(list)
        commit('setOprServiceProviders', list)
      } else {
        OprServiceProviderService.getAll()
        .then((response) => {
          commit('setOprServiceProviders', response.data)
          localStorage.setItem('OprServiceProviders', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  searchAllContacts ({commit}, {query}) {
    return new Promise((resolve, reject) => {
      ContactService.search(query, 'FirstName', 'ASC', 0, 50)
      .then((response) => {
        resolve(response.data.contacts)
      })
      .catch(() => resolve(null))
    })
  },
  searchAllFirms ({commit}, {query}) {
    return new Promise((resolve, reject) => {
      FirmService.search(query, false, 'FirmName', 'ASC', 0, 50)
      .then((response) => {
        resolve(response.data.firms)
      })
      .catch(() => resolve(null))
    })
  },
  searchAllInvestings ({commit}, {query}) {
    return new Promise((resolve, reject) => {
      InvestingService.search(query, 'InvestingName', 'ASC', 0, 50)
      .then((response) => {
        resolve(response.data.investings)
      })
      .catch(() => resolve(null))
    })
  },
  searchAllFunds ({commit}, {query}) {
    return new Promise((resolve, reject) => {
      FundService.search(query, 'FundName', 'ASC', 0, 50, '', '', '', '', '', '', '')
      .then((response) => {
        resolve(response.data.funds)
      })
      .catch(() => resolve(null))
    })
  },
  fetchAllFundtypes ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('FundTypes')
      if (list != null) {
        list = JSON.parse(list)
        commit('setFundTypes', list)
      } else {
        FundTypeService.getAll()
        .then((response) => {
          commit('setFundTypes', response.data)
          localStorage.setItem('FundTypes', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllFundteamRoles ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('FundTeamRoles')
      if (list != null) {
        list = JSON.parse(list)
        commit('setFundTeamRoles', list)
      } else {
        FundTeamRoleService.getAll()
        .then((response) => {
          commit('setFundTeamRoles', response.data)
          localStorage.setItem('FundTeamRoles', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllFundtires ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('FundTiers')
      if (list != null) {
        list = JSON.parse(list)
        commit('setFundtires', list)
      } else {
        FundTierService.getAll()
        .then((response) => {
          commit('setFundtires', response.data)
          localStorage.setItem('FundTiers', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllStrategies ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Strategies')
      if (list != null) {
        list = JSON.parse(list)
        commit('setStrategies', list)
      } else {
        StrategyService.getAll()
        .then((response) => {
          commit('setStrategies', response.data)
          localStorage.setItem('Strategies', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllTreeStrategies ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('TreeStrategies')
      if (list != null) {
        list = JSON.parse(list)
        commit('setTreeStrategies', list)
      } else {
        StrategyService.getAllTree()
        .then((response) => {
          commit('setTreeStrategies', response.data)
          localStorage.setItem('TreeStrategies', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllSectors ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Sectors')
      if (list != null) {
        list = JSON.parse(list)
        commit('setSectors', list)
      } else {
        SectorService.getAll()
        .then((response) => {
          commit('setSectors', response.data)
          localStorage.setItem('Sectors', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllSubSectors ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('SubSectors')
      if (list != null) {
        list = JSON.parse(list)
        commit('setSubSectors', list)
      } else {
        SubSectorService.getAll()
        .then((response) => {
          commit('setSubSectors', response.data)
          localStorage.setItem('SubSectors', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllEmailTemplates ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('allEmailTemplates')
      if (list != null) {
        list = JSON.parse(list)
        commit('setEmailTemplates', list)
      } else {
        NotesService.getTemplates()
        .then((response) => {
          commit('setEmailTemplates', response.data)
          localStorage.setItem('allEmailTemplates', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllGeoFocus ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Geographics')
      if (list != null) {
        list = JSON.parse(list)
        commit('setGeographies', list)
      } else {
        GeographyService.getAll()
        .then((response) => {
          commit('setGeographies', response.data)
          localStorage.setItem('Geographics', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllCurrencies ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Currencies')
      if (list != null) {
        list = JSON.parse(list)
        commit('setCurrencies', list)
      } else {
        CurrencyService.getAll()
        .then((response) => {
          commit('setCurrencies', response.data)
          localStorage.setItem('Currencies', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllMonitoringtypes ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('MonitoringTypes')
      if (list != null) {
        list = JSON.parse(list)
        commit('setMonitoringtypes', list)
      } else {
        MonitoringtypeService.getAll()
        .then((response) => {
          commit('setMonitoringtypes', response.data)
          localStorage.setItem('MonitoringTypes', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllBoardseats ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('BoardSeats')
      if (list != null) {
        list = JSON.parse(list)
        commit('setBoardseats', list)
      } else {
        BoardseatService.getAll()
        .then((response) => {
          commit('setBoardseats', response.data)
          localStorage.setItem('BoardSeats', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllMngfeestructures ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('ManagementFeeStructures')
      if (list != null) {
        list = JSON.parse(list)
        commit('setMngfeestructures', list)
      } else {
        MngfeestructureService.getAll()
        .then((response) => {
          commit('setMngfeestructures', response.data)
          localStorage.setItem('ManagementFeeStructures', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllFundperformanceSource ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('FundPerformanceSources')
      if (list != null) {
        list = JSON.parse(list)
        commit('setFundperformanceSource', list)
      } else {
        FundperformancesourceService.getAll()
        .then((response) => {
          commit('setFundperformanceSource', response.data)
          localStorage.setItem('FundPerformanceSources', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllActivityTypes ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('ActivityTypes')
      if (list != null) {
        list = JSON.parse(list)
        commit('setActivityTypes', list)
      } else {
        ActivityTypeService.getAll()
        .then((response) => {
          commit('setActivityTypes', response.data)
          localStorage.setItem('ActivityTypes', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllCountries ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Countries')
      if (list != null) {
        list = JSON.parse(list)
        commit('setCountries', list)
      } else {
        CountryService.getAll()
        .then((response) => {
          commit('setCountries', response.data)
          localStorage.setItem('Countries', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllFilePurposes ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('FilePurposes')
      if (list != null) {
        list = JSON.parse(list)
        commit('setFilePurposes', list)
      } else {
        FilePurposeService.getAll()
        .then((response) => {
          commit('setFilePurposes', response.data)
          localStorage.setItem('FilePurposes', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllIndustryFocus ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('IndustryFocus')
      if (list != null) {
        list = JSON.parse(list)
        commit('setIndustryFocus', list)
      } else {
        IndustryFocusService.getAll()
        .then((response) => {
          commit('setIndustryFocus', response.data)
          localStorage.setItem('IndustryFocus', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllFirmTypes ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('FirmTypes')
      if (list != null) {
        list = JSON.parse(list)
        commit('setFirmTypes', list)
      } else {
        FirmTypeService.getAll()
        .then((response) => {
          commit('setFirmTypes', response.data)
          localStorage.setItem('FirmTypes', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllSecOutreachTiers ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('SecOutreachTiers')
      if (list != null) {
        list = JSON.parse(list)
        commit('setSecOutreachTiers', list)
      } else {
        SecOutreachTierService.getAll()
        .then((response) => {
          commit('setSecOutreachTiers', response.data)
          localStorage.setItem('SecOutreachTiers', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllFirmClientTypes ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('FirmClientTypes')
      if (list != null) {
        list = JSON.parse(list)
        commit('setFirmClientTypes', list)
      } else {
        FirmClientTypeService.getAll()
        .then((response) => {
          commit('setFirmClientTypes', response.data)
          localStorage.setItem('FirmClientTypes', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllFirmOwnershipTypes ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('FirmOwnershipTypes')
      if (list != null) {
        list = JSON.parse(list)
        commit('setFirmOwnershipTypes', list)
      } else {
        FirmOwnershipTypeService.getAll()
        .then((response) => {
          commit('setFirmOwnershipTypes', response.data)
          localStorage.setItem('FirmOwnershipTypes', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllAnnualMeetingAttendances ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('AnnualMeetingAttendances')
      if (list != null) {
        list = JSON.parse(list)
        commit('setAnnualMeetingAttendances', list)
      } else {
        AnnualMeetingAttendanceService.getAll()
        .then((response) => {
          commit('setAnnualMeetingAttendances', response.data)
          localStorage.setItem('AnnualMeetingAttendances', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllLegalRequestCategories ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('LegalRequestCategories')
      if (list != null) {
        list = JSON.parse(list)
        commit('setLegalRequestCategories', list)
      } else {
        LegalRequestCategoryService.getAll()
        .then((response) => {
          commit('setLegalRequestCategories', response.data)
          localStorage.setItem('LegalRequestCategories', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllLegalRequestStatuses ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('LegalRequestStatuses')
      if (list != null) {
        list = JSON.parse(list)
        commit('setLegalRequestStatuses', list)
      } else {
        LegalRequestStatusService.getAll()
        .then((response) => {
          commit('setLegalRequestStatuses', response.data)
          localStorage.setItem('LegalRequestStatuses', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllQuartiles ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Quartiles')
      if (list != null) {
        list = JSON.parse(list)
        commit('setQuartiles', list)
      } else {
        QuartilesService.getAll()
        .then((response) => {
          commit('setQuartiles', response.data)
          localStorage.setItem('Quartiles', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllCalMeetingTypes ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('CalMeetingTypes')
      if (list != null) {
        list = JSON.parse(list)
        commit('setCalMeetingTypes', list)
      } else {
        CalMeetingService.getTypes()
        .then((response) => {
          commit('setCalMeetingTypes', response.data)
          localStorage.setItem('CalMeetingTypes', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllLockupTypes ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('LockupTypes')
      if (list != null) {
        list = JSON.parse(list)
        commit('setLockupTypes', list)
      } else {
        LockupTypesService.getAll()
        .then((response) => {
          commit('setLockupTypes', response.data)
          localStorage.setItem('LockupTypes', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllTimeWinows ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('TimeWinows')
      if (list != null) {
        list = JSON.parse(list)
        commit('setTimeWinows', list)
      } else {
        TimeWinowService.getAll()
        .then((response) => {
          commit('setTimeWinows', response.data)
          localStorage.setItem('TimeWinows', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllRedemptions ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Redemptions')
      if (list != null) {
        list = JSON.parse(list)
        commit('setRedemptions', list)
      } else {
        RedemptionsService.getAll()
        .then((response) => {
          commit('setRedemptions', response.data)
          localStorage.setItem('Redemptions', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchAllSubscriptions ({commit}) {
    return new Promise((resolve, reject) => {
      var list = localStorage.getItem('Subscriptions')
      if (list != null) {
        list = JSON.parse(list)
        commit('setSubscriptions', list)
      } else {
        SubscriptionsService.getAll()
        .then((response) => {
          commit('setSubscriptions', response.data)
          localStorage.setItem('LockupSubscriptionsTypes', JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(() => resolve(null))
      }
    })
  },
  fetchFirm ({commit, state}, {firmId}) {
    return new Promise((resolve, reject) => {
      FirmService.get(firmId)
        .then((response) => {
          resolve(response)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFund ({commit, state}, {fundId}) {
    return new Promise((resolve, reject) => {
      FundService.get(fundId)
        .then((response) => {
          resolve(response)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFundModel ({commit, state}, {fundId}) {
    return new Promise((resolve, reject) => {
      FundService.getModel(fundId)
        .then((response) => {
          resolve(response)
        })
        .catch(() => resolve(null))
    })
  },
  fetchInvesting ({commit, state}, {investingId}) {
    return new Promise((resolve, reject) => {
      InvestingService.get(investingId)
        .then((response) => {
          resolve(response)
        })
        .catch(() => resolve(null))
    })
  },
  fetchActivity ({commit, state}, {activityId}) {
    return new Promise((resolve, reject) => {
      ActivityService.get(activityId)
        .then((response) => {
          commit('setRBActivity', response.data)
          resolve(state.rb_activity)
        })
        .catch(() => resolve(null))
    })
  },
  updateActivity ({commit, state}, {activityId, activity}) {
    return new Promise((resolve, reject) => {
      ActivityService.save(activityId, activity)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteActivity ({commit, state}, {activityId}) {
    return new Promise((resolve, reject) => {
      ActivityService.delete(activityId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  addActivity ({commit, state}, {objectId, activity}) {
    return new Promise((resolve, reject) => {
      activity.createdOn = new Date()
      activity.createdBy = 0
      ActivityService.addByObject(objectId, activity)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFile ({commit, state}, {fileId}) {
    return new Promise((resolve, reject) => {
      FileService.get(fileId)
        .then((response) => {
          commit('setRBFile', response.data)
          resolve(state.rb_file)
        })
        .catch(() => resolve(null))
    })
  },
  // updateFile ({commit, state}, {fileId, file}) {
  //   return new Promise((resolve, reject) => {
  //     FileService.save(fileId, file)
  //       .then((response) => {
  //         resolve(response.data)
  //       })
  //       .catch(() => resolve(null))
  //   })
  // },
  deleteFile ({commit, state}, {fileId}) {
    return new Promise((resolve, reject) => {
      FileService.delete(fileId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  addFile ({commit, state}, {objectTypeId, objectId, purposeId, file}) {
    return new Promise((resolve, reject) => {
      FileService.upload(objectTypeId, objectId, purposeId, file)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFundTeam ({commit, state}, {fundTeamId}) {
    return new Promise((resolve, reject) => {
      FundTeamService.get(fundTeamId)
        .then((response) => {
          commit('setRBFundTeam', response.data)
          resolve(state.rb_fundTeam)
        })
        .catch(() => resolve(null))
    })
  },
  addFundTeam ({commit, state}, {fundTeam}) {
    return new Promise((resolve, reject) => {
      fundTeam.createdOn = new Date()
      fundTeam.createdBy = 0
      FundTeamService.add(fundTeam)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updateFundTeam ({commit, state}, {fundTeamId, fundTeam}) {
    return new Promise((resolve, reject) => {
      FundTeamService.save(fundTeamId, fundTeam)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteFundTeam ({commit, state}, {fundTeamId}) {
    return new Promise((resolve, reject) => {
      FundTeamService.delete(fundTeamId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchIndustryFocus ({commit, state}, {industryFocusId}) {
    return new Promise((resolve, reject) => {
      FundIndustryFocusService.get(industryFocusId)
        .then((response) => {
          commit('setRBIndustryFocus', response.data)
          resolve(state.rb_fundTeam)
        })
        .catch(() => resolve(null))
    })
  },
  addIndustryFocus ({commit, state}, {industryFocus}) {
    return new Promise((resolve, reject) => {
      industryFocus.createdOn = new Date()
      industryFocus.createdBy = 0
      FundIndustryFocusService.add(industryFocus)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updateIndustryFocus ({commit, state}, {fundsIndustryFocusId, industryFocus}) {
    return new Promise((resolve, reject) => {
      FundIndustryFocusService.save(fundsIndustryFocusId, industryFocus)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteIndustryFocus ({commit, state}, {fundsIndustryFocusId}) {
    return new Promise((resolve, reject) => {
      FundIndustryFocusService.delete(fundsIndustryFocusId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchRegionalFocus ({commit, state}, {fundsRegionalFocusId}) {
    return new Promise((resolve, reject) => {
      FundRegionalFocusService.get(fundsRegionalFocusId)
        .then((response) => {
          commit('setRBFundRegionalFocus', response.data)
          resolve(state.rb_fundTeam)
        })
        .catch(() => resolve(null))
    })
  },
  addFundsRegionalFocus ({commit, state}, {fundsRegionalFocus}) {
    return new Promise((resolve, reject) => {
      fundsRegionalFocus.createdOn = new Date()
      fundsRegionalFocus.createdBy = 0
      FundRegionalFocusService.add(fundsRegionalFocus)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updateFundsRegionalFocus ({commit, state}, {fundsRegionalFocusId, fundsRegionalFocus}) {
    return new Promise((resolve, reject) => {
      FundRegionalFocusService.save(fundsRegionalFocusId, fundsRegionalFocus)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteFundsRegionalFocus ({commit, state}, {fundsRegionalFocusId}) {
    return new Promise((resolve, reject) => {
      FundRegionalFocusService.delete(fundsRegionalFocusId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchMeritConcern ({commit, state}, {meritConcernId}) {
    return new Promise((resolve, reject) => {
      FundMeritConcernService.get(meritConcernId)
        .then((response) => {
          commit('setRBMeritConcern', response.data)
          resolve(state.rb_meritConcern)
        })
        .catch(() => resolve(null))
    })
  },
  addFundsMeritConcern ({commit, state}, {meritConcern}) {
    return new Promise((resolve, reject) => {
      meritConcern.createdOn = new Date()
      meritConcern.createdBy = 0
      FundMeritConcernService.add(meritConcern)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updateFundsMeritConcern ({commit, state}, {meritConcernId, meritConcern}) {
    return new Promise((resolve, reject) => {
      FundMeritConcernService.save(meritConcernId, meritConcern)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteMeritConcern ({commit, state}, {meritConcernId}) {
    return new Promise((resolve, reject) => {
      FundMeritConcernService.delete(meritConcernId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFundKeySenior ({commit, state}, {fundKeySeniorId}) {
    return new Promise((resolve, reject) => {
      FundKeySeniorService.get(fundKeySeniorId)
        .then((response) => {
          commit('setRBKeySenior', response.data)
          resolve(state.rb_fundKeySenior)
        })
        .catch(() => resolve(null))
    })
  },
  addFundKeySenior ({commit, state}, {fundKeySenior}) {
    return new Promise((resolve, reject) => {
      fundKeySenior.createdOn = new Date()
      fundKeySenior.createdBy = 0
      FundKeySeniorService.add(fundKeySenior)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updateFundKeySenior ({commit, state}, {fundKeySeniorId, fundKeySenior}) {
    return new Promise((resolve, reject) => {
      FundKeySeniorService.save(fundKeySeniorId, fundKeySenior)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteFundKeySenior ({commit, state}, {fundKeySeniorId}) {
    return new Promise((resolve, reject) => {
      FundKeySeniorService.delete(fundKeySeniorId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  // edit here
  fetchTrackRecord ({commit, state}, {fundTrackRecordId}) {
    return new Promise((resolve, reject) => {
      FundTrackRecordService.get(fundTrackRecordId)
        .then((response) => {
          commit('setRBTrackRecord', response.data)
          resolve(state.rb_trackRecord)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFundOpportunitySet ({commit, state}, {fundOpportunitySetId}) {
    return new Promise((resolve, reject) => {
      FundOpportunityService.get(fundOpportunitySetId)
        .then((response) => {
          commit('setRBFundOpportunitySet', response.data)
          resolve(state.rb_fundOpportunity)
        })
        .catch(() => resolve(null))
    })
  },
  addFundOpportunitySet ({commit, state}, {fundOpportunitySet}) {
    return new Promise((resolve, reject) => {
      fundOpportunitySet.createdOn = new Date()
      fundOpportunitySet.createdBy = 0
      FundOpportunityService.add(fundOpportunitySet)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updateFundOpportunitySet ({commit, state}, {fundOpportunitySetId, fundOpportunitySet}) {
    return new Promise((resolve, reject) => {
      FundOpportunityService.save(fundOpportunitySetId, fundOpportunitySet)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteFundOpportunitySet ({commit, state}, {fundOpportunitySetId}) {
    return new Promise((resolve, reject) => {
      FundOpportunityService.delete(fundOpportunitySetId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  // edit here
  addFundTrackRecord ({commit, state}, {fundTrackRecord}) {
    return new Promise((resolve, reject) => {
      FundTrackRecordService.add(fundTrackRecord)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteFundTrackRecord ({commit, state}, {fundTrackRecordId}) {
    return new Promise((resolve, reject) => {
      FundTrackRecordService.delete(fundTrackRecordId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFirmContact ({commit, state}, {firmContactId}) {
    return new Promise((resolve, reject) => {
      FirmContactService.get(firmContactId)
        .then((response) => {
          commit('setRBFirmContact', response.data)
          resolve(state.rb_fundOpportunity)
        })
        .catch(() => resolve(null))
    })
  },
  addFirmContact ({commit, state}, {firmContact}) {
    return new Promise((resolve, reject) => {
      firmContact.createdOn = new Date()
      firmContact.createdBy = 0
      FirmContactService.add(firmContact)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updateFirmContact ({commit, state}, {firmContactId, firmContact}) {
    return new Promise((resolve, reject) => {
      FirmContactService.save(firmContactId, firmContact)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteFirmContact ({commit, state}, {firmContactId}) {
    return new Promise((resolve, reject) => {
      FirmContactService.delete(firmContactId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFirmAnnualMeetings ({commit, state}, {meetingId}) {
    return new Promise((resolve, reject) => {
      FirmAnnualMeetingService.get(meetingId)
        .then((response) => {
          commit('setRBFirmAnnualMeeting', response.data)
          resolve(state.rb_firmAnnualMeeting)
        })
        .catch(() => resolve(null))
    })
  },
  addFirmAnnualMeetings ({commit, state}, {firmAnnualMeeting}) {
    return new Promise((resolve, reject) => {
      firmAnnualMeeting.createdOn = new Date()
      firmAnnualMeeting.createdBy = 0
      FirmAnnualMeetingService.add(firmAnnualMeeting)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updateFirmAnnualMeetings ({commit, state}, {meetingId, firmAnnualMeeting}) {
    return new Promise((resolve, reject) => {
      FirmAnnualMeetingService.save(meetingId, firmAnnualMeeting)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteFirmAnnualMeetings ({commit, state}, {meetingId}) {
    return new Promise((resolve, reject) => {
      FirmAnnualMeetingService.delete(meetingId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFundFlrVotings ({commit, state}, {votingId}) {
    return new Promise((resolve, reject) => {
      FundVotingService.get(votingId)
        .then((response) => {
          commit('setRBFundFlrVoting', response.data)
          resolve(state.rb_fundVoting)
        })
        .catch(() => resolve(null))
    })
  },
  updateFundFlrVotings ({commit, state}, {votingId, fundVotings}) {
    return new Promise((resolve, reject) => {
      FundVotingService.save(votingId, fundVotings)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteFundFlrVotings ({commit, state}, {votingId}) {
    return new Promise((resolve, reject) => {
      FundVotingService.delete(votingId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFundRrVotings ({commit, state}, {votingId}) {
    return new Promise((resolve, reject) => {
      FundVotingService.getRr(votingId)
        .then((response) => {
          commit('setRBFundRrVoting', response.data)
          resolve(state.rb_fundVoting)
        })
        .catch(() => resolve(null))
    })
  },
  updateFundRrVotings ({commit, state}, {votingId, fundVotings}) {
    return new Promise((resolve, reject) => {
      FundVotingService.saveRr(votingId, fundVotings)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteFundRrVotings ({commit, state}, {votingId}) {
    return new Promise((resolve, reject) => {
      FundVotingService.deleteRr(votingId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchFundsOnsiteMeetings ({commit, state}, {meetingId}) {
    return new Promise((resolve, reject) => {
      FundOnsiteMeetingService.get(meetingId)
        .then((response) => {
          commit('setRBFundsOnsiteMeeting', response.data)
          resolve(state.rb_firmAnnualMeeting)
        })
        .catch(() => resolve(null))
    })
  },
  addFundsOnsiteMeetings ({commit, state}, {fundOnsiteMeeting}) {
    return new Promise((resolve, reject) => {
      fundOnsiteMeeting.createdOn = new Date()
      fundOnsiteMeeting.createdBy = 0
      FundOnsiteMeetingService.add(fundOnsiteMeeting)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updateFundsOnsiteMeetings ({commit, state}, {meetingId, fundOnsiteMeeting}) {
    return new Promise((resolve, reject) => {
      FundOnsiteMeetingService.save(meetingId, fundOnsiteMeeting)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deleteFundsOnsiteMeetings ({commit, state}, {meetingId}) {
    return new Promise((resolve, reject) => {
      FundOnsiteMeetingService.delete(meetingId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchPortfolioSector ({commit, state}, {portfolioId, sectorId}) {
    return new Promise((resolve, reject) => {
      PortfolioSectorService.getSingle(portfolioId, sectorId)
        .then((response) => {
          commit('setRBPortfolioSector', response.data)
          resolve(state.rb_portfolioSector)
        })
        .catch(() => resolve(null))
    })
  },
  addPortfolioSector ({commit, state}, {portfolioSector}) {
    return new Promise((resolve, reject) => {
      portfolioSector.createdOn = new Date()
      portfolioSector.createdBy = 0
      PortfolioSectorService.add(portfolioSector)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updatePortfolioSector ({commit, state}, {portfolioSectorId, portfolioSector}) {
    return new Promise((resolve, reject) => {
      PortfolioSectorService.save(portfolioSectorId, portfolioSector)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deletePortfolioSector ({commit, state}, {portfolioSectorId}) {
    return new Promise((resolve, reject) => {
      PortfolioSectorService.delete(portfolioSectorId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchPortfolioSlot ({commit, state}, {portfolioSlotId}) {
    return new Promise((resolve, reject) => {
      PortfolioSlotService.get(portfolioSlotId)
        .then((response) => {
          commit('setRBPortfolioVehicle', response.data)
          resolve(state.rb_portfolioVehicle)
        })
        .catch(() => resolve(null))
    })
  },
  fetchPortfolioVehicle ({commit, state}, {portfolioSlotId}) {
    return new Promise((resolve, reject) => {
      PortfolioService.getVehicle(portfolioSlotId)
        .then((response) => {
          commit('setRBPortfolioVehicle', response.data)
          resolve(state.rb_portfolioVehicle)
        })
        .catch(() => resolve(null))
    })
  },
  fetchPortfolioVehicleNoDefault ({commit, state}, {portfolioSlotId}) {
    return new Promise((resolve, reject) => {
      PortfolioService.getVehicle(portfolioSlotId)
        .then((response) => {
          commit('setRBPortfolioVehicleNoDefault', response.data)
          resolve(state.rb_portfolioVehicle)
        })
        .catch(() => resolve(null))
    })
  },
  addPortfolioSlot ({commit, state}, {portfolioSlot}) {
    return new Promise((resolve, reject) => {
      portfolioSlot.createdOn = new Date()
      portfolioSlot.createdBy = 0
      PortfolioSlotService.add(portfolioSlot)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updatePortfolioSlot ({commit, state}, {portfolioSlotId, portfolioSlot}) {
    return new Promise((resolve, reject) => {
      PortfolioSlotService.save(portfolioSlotId, portfolioSlot)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  deletePortfolioSlot ({commit, state}, {portfolioSlotId}) {
    return new Promise((resolve, reject) => {
      PortfolioSlotService.delete(portfolioSlotId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  reopenPortfolioSlot ({commit, state}, {portfolioSlotId}) {
    return new Promise((resolve, reject) => {
      PortfolioSlotService.reopenSlot(portfolioSlotId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  portfolioVehicleAssign ({commit, state}, {slotId, assign}) {
    return new Promise((resolve, reject) => {
      assign.createdOn = new Date()
      assign.createdBy = 0
      PortfolioSlotService.assign(slotId, assign)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  portfolioVehicleRemove ({commit, state}, {slotId, order}) {
    return new Promise((resolve, reject) => {
      const remove = { 'createdBy': 0, 'portfolioSlotId': slotId, 'fundOrder': order }
      PortfolioSlotService.remove(slotId, remove)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  portfolioVehicleApproveAdvisory ({commit, state}, {slotId, approve}) {
    return new Promise((resolve, reject) => {
      PortfolioSlotService.approveAdvisory(slotId, approve)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchPortfolioYear ({commit, state}, {portfolioId, year}) {
    return new Promise((resolve, reject) => {
      PortfolioYearService.getSingle(portfolioId, year)
        .then((response) => {
          commit('setRBPortfolioYear', response.data)
          resolve(state.rb_portfolioYear)
        })
        .catch(() => resolve(null))
    })
  },
  deletePortfolioYear ({commit, state}, {portfolioId, year}) {
    return new Promise((resolve, reject) => {
      PortfolioYearService.delete(portfolioId, year)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  addPortfolioYear ({commit, state}, {portfolioYear}) {
    return new Promise((resolve, reject) => {
      portfolioYear.createdOn = new Date()
      portfolioYear.createdBy = 0
      PortfolioYearService.add(portfolioYear)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updatePortfolioYear ({commit, state}, {portfolioId, portfolioYear}) {
    return new Promise((resolve, reject) => {
      PortfolioYearService.save(portfolioId, portfolioYear)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchPortfolioGeo ({commit, state}, {portfolioId, geo}) {
    return new Promise((resolve, reject) => {
      PortfolioGeoService.getSingle(portfolioId, geo)
        .then((response) => {
          commit('setRBPortfolioGeo', response.data)
          resolve(state.rb_portfolioGeo)
        })
        .catch(() => resolve(null))
    })
  },
  deletePortfolioGeo ({commit, state}, {portfolioId, geo}) {
    return new Promise((resolve, reject) => {
      PortfolioGeoService.delete(portfolioId, geo)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  addPortfolioGeo ({commit, state}, {portfolioGeo}) {
    return new Promise((resolve, reject) => {
      portfolioGeo.createdOn = new Date()
      portfolioGeo.createdBy = 0
      PortfolioGeoService.add(portfolioGeo)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updatePortfolioGeo ({commit, state}, {portfolioId, portfolioGeo}) {
    return new Promise((resolve, reject) => {
      PortfolioGeoService.save(portfolioId, portfolioGeo)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchPortfolioInv ({commit, state}, {portfolioId, invTypeId}) {
    return new Promise((resolve, reject) => {
      PortfolioInvService.getSingle(portfolioId, invTypeId)
        .then((response) => {
          commit('setRBPortfolioInv', response.data)
          resolve(state.rb_portfolioInv)
        })
        .catch(() => resolve(null))
    })
  },
  deletePortfolioInv ({commit, state}, {portfolioId, invTypeId}) {
    return new Promise((resolve, reject) => {
      PortfolioInvService.delete(portfolioId, invTypeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  addPortfolioInv ({commit, state}, {portfolioInv}) {
    return new Promise((resolve, reject) => {
      portfolioInv.createdOn = new Date()
      portfolioInv.createdBy = 0
      PortfolioInvService.add(portfolioInv)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updatePortfolioInv ({commit, state}, {portfolioId, portfolioInv}) {
    return new Promise((resolve, reject) => {
      PortfolioInvService.save(portfolioId, portfolioInv)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  checkBeforePortfolioUpdate ({commit, state}, {portfolioId, type, paramId, newPercent}) {
    return new Promise((resolve, reject) => {
      PortfolioService.checkBeforeUpdate({portfolioId, type, paramId, newPercent})
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  fetchSecFunds ({commit, state}, {secFundId}) {
    return new Promise((resolve, reject) => {
      SecFundService.get(secFundId)
        .then((response) => {
          commit('setRBSecFund', response.data)
          resolve(state.rb_secFund)
        })
        .catch(() => resolve(null))
    })
  },
  deleteSecFunds ({commit, state}, {secFundId}) {
    return new Promise((resolve, reject) => {
      SecFundService.delete(secFundId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  addSecFunds ({commit, state}, {secFund}) {
    return new Promise((resolve, reject) => {
      secFund.createdOn = new Date()
      secFund.createdBy = 0
      SecFundService.add(secFund)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
  updateSecFunds ({commit, state}, {secFundId, secFund}) {
    return new Promise((resolve, reject) => {
      SecFundService.save(secFundId, secFund)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => resolve(null))
    })
  },
}
