<template>
  <div>
    <div
      class="tab-pane fade"
      :class="{ 'active show': inEditMode ? activeTab == 1 : compareTabToggle ? activeTab == 3 : activeTab == 2 }"
    >
      <div class="row">
        <!-- whole bottom -->
        <div class="col-lg-6">
          <!-- left col bottom -->
          <span>
            <div class="form-group">
              <label for="vintageYear" class="col-form-label"
                >Vintage Year
                <span v-show="inEditMode" class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                @blur="$v.fund.vintageYear.$touch()"
                :class="{ 'parsley-error': $v.fund.vintageYear.$error, 'updated-portal' : fundUmbrella != null && fund.vintageYear !== fundUmbrella.vintageYear }"
                id="vintageYear"
                placeholder="Enter Vintage Year"
                v-model.number="fund.vintageYear"
                :disabled="!inEditMode"
              />
              <template v-if="$v.fund.vintageYear.$error">
                <ul class="parsley-errors-list filled">
                  <li
                    v-if="$v.fund.vintageYear.$error"
                    class="parsley-required"
                  >
                    Enter a valid year
                  </li>
                </ul>
              </template>
            </div>
            <div class="form-group">
              <label for="strategyObj" class="col-form-label"
                >Strategy
                <span v-show="inEditMode" class="text-danger">*</span></label
              >
              <multiselect
                id="strategyObj"
                :class="{ 'updated_multi': fundUmbrella != null && fund.dealCurrencyId !== fundUmbrella.dealCurrencyId && !inEditMode }"
                v-model="strategyObj"
                :options="allStrategies"
                label="fullName"
                track-by="modelId"
                :allow-empty="false"
                :disabled="!inEditMode"
               />
            </div>
            <div class="form-group">
              <label for="sectorDescription" class="col-form-label"
                >Strategy Description
                <span v-show="inEditMode" class="text-danger">*</span></label
              >
              <textarea
                maxlength="300"
                @blur="$v.fund.sectorDescription.$touch()"
                :class="{ 'parsley-error': $v.fund.sectorDescription.$error, 'updated-portal' : fundUmbrella != null && fund.sectorDescription !== fundUmbrella.sectorDescription }"
                class="form-control"
                id="sectorDescription"
                placeholder="Your Answer"
                rows="2"
                v-model="fund.sectorDescription"
                :disabled="!inEditMode"
              />
              <template v-if="$v.fund.sectorDescription.$error">
                <ul class="parsley-errors-list filled">
                  <li
                    v-if="$v.fund.sectorDescription.$error"
                    class="parsley-required"
                  >
                    Description must be between 2 and 300 characters
                  </li>
                </ul>
              </template>
            </div>
            <span>
                <div class="form-group">
                <div class="input-group">
                  <label for="esg-focus" :class="{ 'updated-portal-toggle' : !esgTest }" >ESG Focus</label>
                </div>
                <div class="input-group">
                  <toggle-button
                    :disabled="!inEditMode"
                    id="esg-focus"
                    v-model="fund.isSocialResponsible"
                    :sync="true"
                    :labels="{ checked: 'Yes', unchecked: 'No' }"
                  />
                </div>
                <div class="mt-2" v-if="fund.isSocialResponsible || (uSocialResponsible && compareTabToggle)">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="social-check"
                      value="1"
                      v-model="fund.esgType"
                      name="Social"
                      class="custom-control-input"
                      :disabled="!inEditMode"
                    />
                    <label class="custom-control-label" for="social-check"
                      >Social</label
                    >
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="env-check"
                      value="2"
                      v-model="fund.esgType"
                      name="Environmental"
                      class="custom-control-input"
                      :disabled="!inEditMode"
                    />
                    <label class="custom-control-label" for="env-check"
                      >Environmental</label
                    >
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="SEG-check"
                      value="3"
                      v-model="fund.esgType"
                      name="SEG"
                      class="custom-control-input"
                      :disabled="!inEditMode"
                    />
                    <label class="custom-control-label" for="SEG-check"
                      >Social, Environmental and/or Governance</label
                    >
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="other-check"
                      value="4"
                      v-model="fund.esgType"
                      name="Other"
                      class="custom-control-input"
                      :disabled="!inEditMode"
                    />
                    <label class="custom-control-label" for="other-check"
                      >Other</label
                    >
                  </div>
                  <div class="input-group mt-2" v-if="fund.esgType == 4">
                    <textarea
                      class="form-control"
                      id="other-text"
                      placeholder="Explain"
                      maxlength="250"
                      rows="1"
                      v-model="fund.esgTypeOther"
                      @blur="$v.fund.esgTypeOther.$touch()"
                      :class="{ 'parsley-error': $v.fund.esgTypeOther.$error,'updated-portal' : fundUmbrella != null && fund.esgTypeOther !== fundUmbrella.esgTypeOther }"
                      @keypress="isFormUpdated = true"
                      :disabled="!inEditMode"
                    ></textarea>
                  </div>
                  <template v-if="$v.fund.esgTypeOther.$error">
                    <ul class="parsley-errors-list filled">
                      <li
                        v-if="$v.fund.esgTypeOther.$error"
                        class="parsley-required"
                      >
                        This value must be between 1 and 250 characters.
                      </li>
                    </ul>
                  </template>
                </div>
              </div>
              <div class="form-group">
                <label for="fundAbbreviation" class="col-form-label"
                  >Deal Abbreviation
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <input
                  type="text"
                  @blur="$v.fund.fundAbbreviation.$touch()"
                  :class="{ 'parsley-error': $v.fund.fundAbbreviation.$error, 'updated-portal' : fundUmbrella != null && fund.fundAbbreviation !== fundUmbrella.fundAbbreviation }"
                  class="form-control"
                  id="fundAbbreviation"
                  placeholder="Your Answer"
                  v-model="fund.fundAbbreviation"
                  :disabled="!inEditMode"
                />
                <template v-if="$v.fund.fundAbbreviation.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.fundAbbreviation.$error"
                      class="parsley-required"
                    >
                      Must be between 1 and 100 characters.
                    </li>
                  </ul>
                </template>
              </div>
              
              <div class="form-group">
                <label for="firstCloseAmount" class="col-form-label"
                  >First Close Amount (M)
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{ symbol }}</span>
                  </div>
                  <input
                    type="text"
                    @blur="$v.fund.firstCloseAmount.$touch()"
                    :class="{
                      'parsley-error': $v.fund.firstCloseAmount.$error, 'updated-portal' : fundUmbrella != null && fund.firstCloseAmount !== fundUmbrella.firstCloseAmount
                    }"
                    class="form-control"
                    id="firstCloseAmount"
                    placeholder="Your Answer"
                    v-model.number="fund.firstCloseAmount"
                    :disabled="!inEditMode"
                  />
                </div>
                <template v-if="$v.fund.firstCloseAmount.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.firstCloseAmount.$error"
                      class="parsley-required"
                    >
                      Enter a number between 0 and 1,000,000
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group">
                <label for="firstCloseDate" class="col-form-label"
                  >First Close Date
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <date-picker
                  v-model="fund.firstCloseDate"
                  placeholder="Select Date"
                  @blur="$v.fund.firstCloseDate.$touch()"
                  :class="{ 'parsley-error': $v.fund.firstCloseDate.$invalid, 'updated-date' : fundUmbrella != null && fund.expectedStartDate !== fundUmbrella.expectedStartDate && !inEditMode }"
                  calendar-class="calendar"
                  :monday-first="true"
                  :typeable="true"
                  :clear-button="inEditMode"
                  clear-button-icon="fa fa-times"
                  format="MM/dd/yyyy"
                  :bootstrap-styling="true"
                  :disabled="!inEditMode"
                ></date-picker>
                <template v-if="$v.fund.firstCloseDate.$invalid">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.firstCloseDate.$invalid"
                      class="parsley-required"
                    >
                      Enter a date
                    </li>
                  </ul>
                </template>
              </div>
            </span>
            <div class="form-group">
              <label for="targetSize" class="col-form-label"
                >Target Size (M)
                <span v-show="inEditMode" class="text-danger">*</span></label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ symbol }}</span>
                </div>
                <input
                  type="text"
                  @blur="$v.fund.targetSize.$touch()"
                  :class="{ 'parsley-error': $v.fund.targetSize.$error, 'updated-portal' : fundUmbrella != null && fund.targetSize !== fundUmbrella.targetSize }"
                  class="form-control"
                  id="targetSize"
                  placeholder="Your Answer"
                  v-model.number="fund.targetSize"
                  :disabled="!inEditMode"
                />
              </div>
              <template v-if="$v.fund.targetSize.$error">
                <ul class="parsley-errors-list filled">
                  <li v-if="$v.fund.targetSize.$error" class="parsley-required">
                    Enter a number between 1 and 1,000,000
                  </li>
                </ul>
              </template>
            </div>
            <div class="form-group">
              <label for="hardCap" class="col-form-label">Hard Cap (M)</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ symbol }}</span>
                </div>
                <input
                  type="text"
                  :class="{ 'updated-portal': fundUmbrella != null && fund.hardCap !== fundUmbrella.hardCap }"
                  class="form-control"
                  id="hardCap"
                  placeholder="Your Answer"
                  v-model.number="fund.hardCap"
                  :disabled="!inEditMode"
                />
              </div>
            </div>
            <span>
              <div class="form-group">
                <label for="investmentStrategy" class="col-form-label"
                  >Investment Strategy
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <textarea
                  maxlength="1000"
                  @blur="$v.fund.investmentStrategy.$touch()"
                  :class="{
                    'parsley-error': $v.fund.investmentStrategy.$error, 'updated-portal' : fundUmbrella != null && fund.investmentStrategy !== fundUmbrella.investmentStrategy
                  }"
                  class="form-control"
                  id="investmentStrategy"
                  placeholder="Enter Investment Strategy"
                  rows="5"
                  v-model="fund.investmentStrategy"
                  :disabled="!inEditMode"
                ></textarea>
                <template v-if="$v.fund.investmentStrategy.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.investmentStrategy.$error"
                      class="parsley-required"
                    >
                      Must be between 1 and 1,000 characters in length.
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group">
                <label for="targetReturn" class="col-form-label"
                  >Target Return
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <input
                  type="text"
                  @blur="$v.fund.targetReturn.$touch()"
                  :class="{ 'parsley-error': $v.fund.targetReturn.$error, 'updated-portal' : fundUmbrella != null && fund.targetReturn !== fundUmbrella.targetReturn }"
                  class="form-control"
                  id="targetReturn"
                  placeholder="targetReturn"
                  v-model="fund.targetReturn"
                  :disabled="!inEditMode"
                />
                <template v-if="$v.fund.targetReturn.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.targetReturn.$error"
                      class="parsley-required"
                    >
                      Must be between 1 and 20 characters in length
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group">
                <label for="investmentSizeRange" class="col-form-label"
                  >Target Investment Size Range
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <input
                  type="text"
                  @blur="$v.fund.investmentSizeRange.$touch()"
                  :class="{
                    'parsley-error': $v.fund.investmentSizeRange.$error, 'updated-portal' : fundUmbrella != null && fund.investmentSizeRange !== fundUmbrella.investmentSizeRange
                  }"
                  class="form-control"
                  id="investmentSizeRange"
                  placeholder="Your Answer"
                  v-model="fund.investmentSizeRange"
                  :disabled="!inEditMode"
                />
                <template v-if="$v.fund.investmentSizeRange.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.investmentSizeRange.$error"
                      class="parsley-required"
                    >
                      Must be between 1 and 20 characters in length
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group">
                <label for="numberofInvestments" class="col-form-label"
                  >Target Number of Investments
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <input
                  type="text"
                  @blur="$v.fund.numberofInvestments.$touch()"
                  :class="{
                    'parsley-error': $v.fund.numberofInvestments.$error, 'updated-portal' : fundUmbrella != null && fund.numberofInvestments !== fundUmbrella.numberofInvestments
                  }"
                  class="form-control"
                  id="numberofInvestments"
                  placeholder="Your Answer"
                  v-model="fund.numberofInvestments"
                  :disabled="!inEditMode"
                />
                <template v-if="$v.fund.numberofInvestments.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.numberofInvestments.$error"
                      class="parsley-required"
                    >
                      Must be between 1 and 20 characters in length
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group">
                <label for="investmentPeriod" class="col-form-label"
                  >Investment Period (Years)
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <input
                  type="text"
                  @blur="$v.fund.investmentPeriod.$touch()"
                  :class="{ 'parsley-error': $v.fund.investmentPeriod.$error, 'updated-portal' : fundUmbrella != null && fund.investmentPeriod !== fundUmbrella.investmentPeriod }"
                  class="form-control"
                  id="investmentPeriod"
                  placeholder="Your Answer"
                  v-model.number="fund.investmentPeriod"
                  :disabled="!inEditMode"
                />
                <template v-if="$v.fund.investmentPeriod.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.investmentPeriod.$error"
                      class="parsley-required"
                    >
                      Must be between 1 and 20 characters in length
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group">
                <label for="fundTerm" class="col-form-label"
                  >Fund Term (Years)
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <input
                  type="text"
                  @blur="$v.fund.fundTerm.$touch()"
                  :class="{ 'parsley-error': $v.fund.fundTerm.$error, 'updated-portal' : fundUmbrella != null && fund.fundTerm !== fundUmbrella.fundTerm }"
                  class="form-control"
                  id="fundTerm"
                  placeholder="Your Answer"
                  v-model="fund.fundTerm"
                  :disabled="!inEditMode"
                />
                <template v-if="$v.fund.fundTerm.$error">
                  <ul class="parsley-errors-list filled">
                    <li v-if="$v.fund.fundTerm.$error" class="parsley-required">
                      Must be between 1 and 20 characters in length
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group">
                <label for="gpCommitmentPercent" class="col-form-label"
                  >GP Commitment
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">%</span>
                  </div>
                  <input
                    type="text"
                    @blur="$v.fund.gpCommitmentPercent.$touch()"
                    :class="{
                      'parsley-error': $v.fund.gpCommitmentPercent.$error, 'updated-portal' : fundUmbrella != null && fund.gpCommitmentPercent !== fundUmbrella.gpCommitmentPercent
                    }"
                    class="form-control"
                    id="gpCommitmentPercent"
                    placeholder="Your Answer"
                    v-model.number="fund.gpCommitmentPercent"
                    :disabled="!inEditMode"
                  />
                </div>
                <template v-if="$v.fund.gpCommitmentPercent.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.gpCommitmentPercent.$error"
                      class="parsley-required"
                    >
                      Must be between 0.1 and 100
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group">
                <label for="managementFeePercent" class="col-form-label"
                  >Management Fee (Investment Period)
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">%</span>
                  </div>
                  <input
                    type="text"
                    @blur="$v.fund.managementFeePercent.$touch()"
                    :class="{
                      'parsley-error': $v.fund.managementFeePercent.$error, 'updated-portal' : fundUmbrella != null && fund.managementFeePercent !== fundUmbrella.managementFeePercent
                    }"
                    class="form-control"
                    id="managementFeePercent"
                    placeholder="Your Answer"
                    v-model.number="fund.managementFeePercent"
                    :disabled="!inEditMode"
                  />
                </div>
                <template v-if="$v.fund.managementFeePercent.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.managementFeePercent.$error"
                      class="parsley-required"
                    >
                      Must be between 0 and 100
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group" >
                <label class="col-form-label" :class="{ 'updated-portal-radio' : fundUmbrella != null && fund.managementFeeStructureId !== fundUmbrella.managementFeeStructureId && !inEditMode }"
                  >Management Fee Structure
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <span>
                  <div class="custom-control custom-radio" >
                    <input
                      type="radio"
                      id="Structure1"
                      value="1"
                      v-model="fund.managementFeeStructureId"
                      name="Structure"
                      @blur="$v.fund.managementFeeStructureId.$touch()"
                      :class="{
                        'parsley-error':
                          $v.fund.managementFeeStructureId.$error, 
                      }"
                      class="custom-control-input"
                      :disabled="!inEditMode"
                    />
                    <label class="custom-control-label" for="Structure1"
                      >Committed</label
                    > 
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="Structure2"
                      value="2"
                      v-model="fund.managementFeeStructureId"
                      name="Structure"
                      @blur="$v.fund.managementFeeStructureId.$touch()"
                      :class="{
                        'parsley-error':
                          $v.fund.managementFeeStructureId.$error,
                      }"
                      class="custom-control-input"
                      :disabled="!inEditMode"
                    />
                    <label class="custom-control-label" for="Structure2"
                      >Invested</label
                    >
                  </div>
                </span>
              </div>
              <div class="form-group">
                <label for="carriedInterestPercent" class="col-form-label"
                  >Carried Interest
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">%</span>
                  </div>
                  <input
                    type="text"
                    @blur="$v.fund.carriedInterestPercent.$touch()"
                    :class="{
                      'parsley-error': $v.fund.carriedInterestPercent.$error, 'updated-portal' : fundUmbrella != null && fund.carriedInterestPercent !== fundUmbrella.carriedInterestPercent
                    }"
                    class="form-control"
                    id="carriedInterestPercent"
                    placeholder="Your Answer"
                    v-model.number="fund.carriedInterestPercent"
                    :disabled="!inEditMode"
                  />
                </div>
                <template v-if="$v.fund.carriedInterestPercent.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.carriedInterestPercent.$error"
                      class="parsley-required"
                    >
                      Must be between 0 and 100
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group">
                <label for="carriedInterestDesc" class="col-form-label"
                  >Carried Interest - Waterfall Type</label
                >
                <input
                  type="text"
                  @blur="$v.fund.carriedInterestDesc.$touch()"
                  :class="{
                    'parsley-error': $v.fund.carriedInterestDesc.$error, 'updated-portal' : fundUmbrella != null && fund.carriedInterestDesc !== fundUmbrella.carriedInterestDesc
                  }"
                  class="form-control"
                  id="carriedInterestDesc"
                  placeholder="Your Answer"
                  v-model="fund.carriedInterestDesc"
                  :disabled="!inEditMode"
                />
                <template v-if="$v.fund.carriedInterestDesc.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.carriedInterestDesc.$error"
                      class="parsley-required"
                    >
                      Must be under 100 characters in length
                    </li>
                  </ul>
                </template>
              </div>

              <div class="form-group">
                <label for="preferredReturnPercent" class="col-form-label"
                  >Preferred Return
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">%</span>
                  </div>
                  <input
                    type="text"
                    @blur="$v.fund.preferredReturnPercent.$touch()"
                    :class="{
                      'parsley-error': $v.fund.preferredReturnPercent.$error, 'updated-portal' : fundUmbrella != null && fund.preferredReturnPercent !== fundUmbrella.preferredReturnPercent
                    }"
                    class="form-control"
                    id="preferredReturnPercent"
                    placeholder="Your Answer"
                    v-model.number="fund.preferredReturnPercent"
                    :disabled="!inEditMode"
                  />
                </div>
                <template v-if="$v.fund.preferredReturnPercent.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.preferredReturnPercent.$error"
                      class="parsley-required"
                    >
                      Must be between 0 and 100
                    </li>
                  </ul>
                </template>
              </div>
              <div class="form-group">
                <label for="trackRecordSummary" class="col-form-label"
                  >Track Record Summary
                  <span v-show="inEditMode" class="text-danger">*</span></label
                >
                <textarea
                  maxlength="250"
                  @blur="$v.fund.trackRecordSummary.$touch()"
                  :class="{
                    'parsley-error': $v.fund.trackRecordSummary.$error, 'updated-portal' : fundUmbrella != null && fund.trackRecordSummary !== fundUmbrella.trackRecordSummary
                  }"
                  class="form-control"
                  id="trackRecordSummary"
                  placeholder="Your Answer"
                  rows="5"
                  v-model="fund.trackRecordSummary"
                  :disabled="!inEditMode"
                ></textarea>
                <template v-if="$v.fund.trackRecordSummary.$error">
                  <ul class="parsley-errors-list filled">
                    <li
                      v-if="$v.fund.trackRecordSummary.$error"
                      class="parsley-required"
                    >
                      Enter between 1 and 250 characters
                    </li>
                  </ul>
                </template>
              </div>
              <div id="key-personnel" class="form-group" v-if="inEditMode">
                <label for="key-personnel" class="col-form-label">
                  Key Personnel
                </label>
                <div class="tab-pane fade show active">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Full Name</th>
                        <th>Job Title</th>
                      </tr>
                    </thead>
                    <FundKeyPersonnelList :list="fund.fundKeyPersonnels" />
                  </table>
                </div>
              </div>
              <div class="form-group" v-if="inEditMode">
                <label for="docs" class="col-form-label"> Documents </label>
                <div id="docs" class="tab-pane fade show active">
                  <FundDocumentList :files="fund.documents" />
                </div>
              </div>
              <div id="tr-data" class="form-group" v-if="inEditMode">
                <label for="tr-data" class="col-form-label">
                  Track Record
                </label>
                <div class="tab-pane fade show active">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr id='tr-data'>
                        <th>Fund Name</th>
                        <th>Target Size</th>
                        <th>Vintage</th>
                        <th>Gross ROI</th>
                        <th>Net ROI</th>
                        <th>Gross IRR</th>
                        <th>Net IRR</th>
                      </tr>
                    </thead>
                    <FundPerformancesList :list="fund.fundPerformances" />
                  </table>
                </div>
              </div>
            </span>
          </span>
        </div>
        <div class="col-lg-6" v-if='compareTabToggle'>
          <CompareTabIS :fund='fund' :fundUmbrella='fundUmbrella' :inEditMode='inEditMode' :symbol='symbol' :compareTabToggle='compareTabToggle' @updateStrategy='updateStrategy' />
        </div>
        <div class="col-lg-6" v-if="!inEditMode && !compareTabToggle">
          <!-- right col bottom -->
          <ul class="nav nav-tabs tabs-bordered">
            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{ active: activeISTab == 1 }" @click="showISTab(1)">
                Documents
                <span class="badge badge-primary noti-icon-badge" v-if="fund.documents">{{ fund.documents.length }}</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{ active: activeISTab == 2 }" @click="showISTab(2)">
                Track Record
                <span class="badge badge-primary noti-icon-badge" v-if="fund.fundPerformances">{{ fund.fundPerformances.length }}</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{ active: activeISTab == 3 }" @click="showISTab(3)">
                Key Personnel
                <span class="badge badge-primary noti-icon-badge" v-if="fund.fundKeyPersonnels">{{ fund.fundKeyPersonnels.length }}</span>
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane" :class="{ 'active show': activeISTab == 2 }">
              <div class="tab-pane fade show active">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Fund Name</th>
                      <th>Target Size</th>
                      <th>Vintage</th>
                      <th>Gross ROI</th>
                      <th>Net ROI</th>
                      <th>Gross IRR</th>
                      <th>Net IRR</th>
                      <th>As of</th>
                    </tr>
                  </thead>
                  <FundPerformancesList :list="fund.fundPerformances" />
                </table>
              </div>
            </div>
            <div class="tab-pane" :class="{ 'active show': activeISTab == 3 }">
              <div class="tab-pane fade show active">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Job Title</th>
                    </tr>
                  </thead>
                  <FundKeyPersonnelList :list="fund.fundKeyPersonnels" />
                </table>
              </div>
            </div>
            <div class="tab-pane" :class="{ 'active show': activeISTab == 1 }">
              <div class="tab-pane fade show active">
                <FundDocumentList :files="fund.documents" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import FundDocumentList from '@/components/portal/FundDocumentList'
import FundKeyPersonnelList from '@/components/portal/FundKeyPersonnelList'
import FundPerformancesList from '@/components/portal/FundPerformancesList'
import {
  required,
  requiredIf,
  numeric,
  decimal,
  minLength,
  maxLength,
  minValue,
  maxValue
} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import ActivityList from '@/components/ActivityList'
import CompareTabIS from '@/components/portal/CompareTabIS'
import { mapActions } from 'vuex'

export default {
  components: {
    FundDocumentList,
    FundKeyPersonnelList,
    FundPerformancesList,
    Multiselect,
    DatePicker,
    ActivityList,
    CompareTabIS
  },
  data () {
    return {
      activeISTab: 1,
      showLogs: false
    }
  },
  props: {
    fund: {
      type: Object
    },
    fundUmbrella: {
      type: Object
    },
    activeTab: {
      type: Number
    },
    inEditMode: {
      type: Boolean
    },
    strategyObj: {
      type: Array
    },
    symbol: {
      type: String
    },
    compareTabToggle: {
      type: Boolean
    },
    uSocialResponsible: {
      type: Boolean
    }
  },
  methods: {
    ...mapActions(['fetchAllStrategies']),
    showISTab (tabId) {
      this.activeISTab = tabId
    },
    onActivityUpdate () {
      this.$emit('onActivityUpdate')
    },
    clickAddActivity () {
      this.$emit('clickAddActivity')
    },
    showActivity () {
      this.$emit('showActivity')
    },
    editActivity () {
      this.$emit('editActivity')
    },
    updateStrategy (strategy) {
      this.$emit('updateStrategy', strategy)
    }
  },
  computed: {
    allStrategies () {
      return this.$store.state.allStrategies
    },
    logToggle () {
      return this.activeTab === 1 || this.activeTab === 3
    },
    esgTest () {
      return this.fundUmbrella !== null && (this.fundUmbrella.isSocialResponsible === this.fund.isSocialResponsible) && (this.fundUmbrella.esgType === this.fund.esgType) && (this.fundUmbrella.esgOther === this.fund.esgOther)
    }
  },
  validations: {
    fund: {
      vintageYear: {
        required,
        numeric,
        min: minValue(1980)
      },
      sectorId: { required, min: minValue(1) },
      sectorDescription: {
        required,
        min: minLength(2),
        max: maxLength(300)
      },
      targetSize: {
        required,
        decimal,
        min: minValue(1),
        max: maxValue(1000000)
      },
      esgTypeOther: {
        required: requiredIf(function () { return !this.fund.isOpenEnded })
      },
      fundAbbreviation: {
        required,
        max: maxLength(100)
      },
      firstCloseAmount: {
        required,
        decimal,
        min: minValue(0),
        max: maxValue(1000000)
      },
      finalCloseAmount: { decimal, max: maxValue(1000000) },
      firstCloseDate: {
        required
      },
      investmentStrategy: {
        required,
        max: maxLength(1000)
      },
      targetReturn: {
        required,
        max: maxLength(20)
      },
      investmentSizeRange: {
        required,
        max: maxLength(20)
      },
      numberofInvestments: {
        required,
        max: maxLength(20)
      },
      investmentPeriod: {
        required,
        min: minValue(1),
        max: maxLength(20),
        numeric
      },
      fundTerm: {
        required,
        max: maxLength(20)
      },
      dealDescription: {
        max: maxLength(2000)
      },
      gpCommitmentPercent: {
        required,
        decimal,
        min: minValue(0.1),
        max: maxValue(100)
      },
      managementFeePercent: {
        required,
        decimal,
        min: minValue(0),
        max: maxValue(100)
      },
      managementFeeStructureId: {
        required
      },
      carriedInterestPercent: {
        required,
        decimal,
        min: minValue(0),
        max: maxValue(100)
      },
      carriedInterestDesc: { max: maxLength(100) },
      preferredReturnPercent: {
        required,
        decimal,
        min: minValue(0),
        max: maxValue(100)
      },
      trackRecordSummary: {
        required,
        max: maxLength(250)
      }
    }
  },
  created () {
    this.fetchAllStrategies()
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>