<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portal-diversity'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="diversity">
                    <Toolbar :title="'Navigator Diversity Details - '+ diversity.firmName " :icon="'mdi mdi-web'" 
                    :showToolbar="true" :inEditMode="inEditMode" :canEdit="canbeEdited" :isSaving="inSaving"
                    :isNew="!diversity.umbrellaCheck" :isDeleted="diversity.deletedOn != null"
                    :firmImported ="false" :isFund="false" :isUpdated="false"
                    @onEdit="EditForm" @onCancel="cancelForm" @onSave="saveForm"
                     />
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-box">
                                <ul class="nav nav-tabs tabs-bordered">
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(1)" :class="{'active': activeTab == 1}">
                                            <i class="mdi mdi-information-outline"></i> Summary
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane fade" :class="{'active show': activeTab == 1}">
                                        <div class="row">
                                            <div class="col-lg-6">

                                              <div class="form-group">
                                                  <label class="col-form-label" for="totalCnt">Total employees who are firm shareholders:</label>
                                                  <div class="input-group">
                                                    <input type="text" :disabled="!inEditMode" class="form-control" id="totalCnt" placeholder="Your Answer" v-model.number="diversity.totalCnt" />
                                                  </div>
                                              </div>

                                              <div class="form-group">
                                                  <label class="col-form-label">Is any portion of the firm owned by Disabled persons?</label> <toggle-button v-model="diversity.ownedByDisabled" :disabled="!inEditMode" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" />
                                              </div>
                                              <div class="form-group">
                                                  <label class="col-form-label">Is any portion of the firm owned by Veterans?</label> <toggle-button v-model="diversity.ownedByVeterans" :disabled="!inEditMode" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" />
                                              </div>

                                            <span>
                                              <h5 class="text-uppercase p-1 col-6">
                                                Non-Veterans (Non-Disabled)
                                              </h5>
                                              <div class="row form-group">
                                                <table class="table table-striped no-footer">
                                                  <thead>
                                                    <tr class="text-center">
                                                      <th></th>
                                                      <th>Male</th>
                                                      <th>Female</th>
                                                      <th>Non-binary</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        African American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :readonly="!inEditMode" class="form-control" v-model.number="diversity.africanMale" :class="{ 'diversity-filled-perc': diversity.africanMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text" :class="{ '': diversity.africanMale > 0 }">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :readonly="!inEditMode" class="form-control" v-model.number="diversity.africanFemale" :class="{ 'diversity-filled-perc': diversity.africanFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :readonly="!inEditMode" class="form-control" v-model.number="diversity.africanBinary" :class="{ 'diversity-filled-perc': diversity.africanBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Asian American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.asianMale" :class="{ 'diversity-filled-perc': diversity.asianMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.asianFemale" :class="{ 'diversity-filled-perc': diversity.asianFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.asianBinary" :class="{ 'diversity-filled-perc': diversity.asianBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Hispanic American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.hispanicMale" :class="{ 'diversity-filled-perc': diversity.hispanicMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.hispanicFemale" :class="{ 'diversity-filled-perc': diversity.hispanicFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.hispanicBinary" :class="{ 'diversity-filled-perc': diversity.hispanicBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Native American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.nativeMale" :class="{ 'diversity-filled-perc': diversity.nativeMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.nativeFemale" :class="{ 'diversity-filled-perc': diversity.nativeFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.nativeBinary" :class="{ 'diversity-filled-perc': diversity.nativeBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Other Minority Group
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.otherMale" :class="{ 'diversity-filled-perc': diversity.otherMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.otherFemale" :class="{ 'diversity-filled-perc': diversity.otherFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.otherBinary" :class="{ 'diversity-filled-perc': diversity.otherBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Non-Minority
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.nonMale" :class="{ 'diversity-filled-perc': diversity.nonMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.nonFemale" :class="{ 'diversity-filled-perc': diversity.nonFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.nonBinary" :class="{ 'diversity-filled-perc': diversity.nonBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </span>

                                            <span v-if="diversity.ownedByDisabled">
                                              <h5 class="text-uppercase p-1 col-6">
                                                Non-Veterans (Disabled)
                                              </h5>
                                              <div class="row form-group">
                                                <table class="table table-striped no-footer">
                                                  <thead>
                                                    <tr class="text-center">
                                                      <th></th>
                                                      <th>Male</th>
                                                      <th>Female</th>
                                                      <th>Non-binary</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        African American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledAfricanMale" :class="{ 'diversity-filled-perc': diversity.disabledAfricanMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledAfricanFemale" :class="{ 'diversity-filled-perc': diversity.disabledAfricanFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledAfricanBinary" :class="{ 'diversity-filled-perc': diversity.disabledAfricanBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Asian American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledAsianMale" :class="{ 'diversity-filled-perc': diversity.disabledAsianMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledAsianFemale" :class="{ 'diversity-filled-perc': diversity.disabledAsianFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledAsianBinary" :class="{ 'diversity-filled-perc': diversity.disabledAsianBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Hispanic American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledHispanicMale" :class="{ 'diversity-filled-perc': diversity.disabledHispanicMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledHispanicFemale" :class="{ 'diversity-filled-perc': diversity.disabledHispanicFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledHispanicBinary" :class="{ 'diversity-filled-perc': diversity.disabledHispanicBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Native American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledNativeMale" :class="{ 'diversity-filled-perc': diversity.disabledNativeMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledNativeFemale" :class="{ 'diversity-filled-perc': diversity.disabledNativeFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledNativeBinary" :class="{ 'diversity-filled-perc': diversity.disabledNativeBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Other Minority Group
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledOtherMale" :class="{ 'diversity-filled-perc': diversity.disabledOtherMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledOtherFemale" :class="{ 'diversity-filled-perc': diversity.disabledOtherFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledOtherBinary" :class="{ 'diversity-filled-perc': diversity.disabledOtherBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Non-Minority
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledNonMale" :class="{ 'diversity-filled-perc': diversity.disabledNonMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledNonFemale" :class="{ 'diversity-filled-perc': diversity.disabledNonFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledNonBinary" :class="{ 'diversity-filled-perc': diversity.disabledNonBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </span>

                                            <span v-if="diversity.ownedByVeterans">
                                              <h5 class="text-uppercase p-1 col-lg-6">
                                                Veterans (Non-Disabled)
                                              </h5>
                                              <div class="row form-group">
                                                <table class="table table-striped no-footer">
                                                  <thead>
                                                    <tr class="text-center">
                                                      <th></th>
                                                      <th>Male</th>
                                                      <th>Female</th>
                                                      <th>Non-binary</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        African American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransAfricanMale" :class="{ 'diversity-filled-perc': diversity.veteransAfricanMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransAfricanFemale" :class="{ 'diversity-filled-perc': diversity.veteransAfricanFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransAfricanBinary" :class="{ 'diversity-filled-perc': diversity.veteransAfricanBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Asian American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransAsianMale" :class="{ 'diversity-filled-perc': diversity.veteransAsianMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransAsianFemale" :class="{ 'diversity-filled-perc': diversity.veteransAsianFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransAsianBinary" :class="{ 'diversity-filled-perc': diversity.veteransAsianBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Hispanic American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransHispanicMale" :class="{ 'diversity-filled-perc': diversity.veteransHispanicMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransHispanicFemale" :class="{ 'diversity-filled-perc': diversity.veteransHispanicFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransHispanicBinary" :class="{ 'diversity-filled-perc': diversity.veteransHispanicBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Native American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransNativeMale" :class="{ 'diversity-filled-perc': diversity.veteransNativeMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransNativeFemale" :class="{ 'diversity-filled-perc': diversity.veteransNativeFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransNativeBinary" :class="{ 'diversity-filled-perc': diversity.veteransNativeBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Other Minority Group
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransOtherMale" :class="{ 'diversity-filled-perc': diversity.veteransOtherMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransOtherFemale" :class="{ 'diversity-filled-perc': diversity.veteransOtherFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransOtherBinary" :class="{ 'diversity-filled-perc': diversity.veteransOtherBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Non-Minority
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransNonMale" :class="{ 'diversity-filled-perc': diversity.veteransNonMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransNonFemale" :class="{ 'diversity-filled-perc': diversity.veteransNonFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.veteransNonBinary" :class="{ 'diversity-filled-perc': diversity.veteransNonBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </span>

                                            <span v-if="diversity.ownedByVeterans && diversity.ownedByDisabled">
                                              <h5 class="text-uppercase p-1 col-6">
                                                Veterans (Disabled)
                                              </h5>
                                              <!-- <h4 class="m-t-0 m-b-30 header-title">Veterans (Disabled)</h4> -->
                                              <div class="row form-group" >
                                                <table class="table table-striped no-footer">
                                                  <thead>
                                                    <tr class="text-center">
                                                      <th></th>
                                                      <th>Male</th>
                                                      <th>Female</th>
                                                      <th>Non-binary</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        African American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransAfricanMale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransAfricanMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransAfricanFemale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransAfricanFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransAfricanBinary" :class="{ 'diversity-filled-perc': diversity.disabledVeteransAfricanBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Asian American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransAsianMale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransAsianMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransAsianFemale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransAsianFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransAsianBinary" :class="{ 'diversity-filled-perc': diversity.disabledVeteransAsianBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Hispanic American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransHispanicMale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransHispanicMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransHispanicFemale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransHispanicFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransHispanicBinary" :class="{ 'diversity-filled-perc': diversity.disabledVeteransHispanicBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Native American
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransNativeMale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransNativeMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransNativeFemale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransNativeFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransNativeBinary" :class="{ 'diversity-filled-perc': diversity.disabledVeteransNativeBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Other Minority Group
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransOtherMale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransOtherMale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransOtherFemale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransOtherFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransOtherBinary" :class="{ 'diversity-filled-perc': diversity.disabledVeteransOtherBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                      <td>
                                                        Non-Minority
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransNonMale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransOtherBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransNonFemale" :class="{ 'diversity-filled-perc': diversity.disabledVeteransNonFemale > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                            <input type="text" :disabled="!inEditMode" class="form-control" v-model.number="diversity.disabledVeteransNonBinary" :class="{ 'diversity-filled-perc': diversity.disabledVeteransNonBinary > 0 }" />
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </span>

                                            <div class="form-group">
                                                <label class="col-form-label">Total Firm Ownership:</label>
                                                <div class="input-group">
                                                    <div>
                                                        <span class="input-group-text">{{totalOwnership}}%</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-form-label">Owned by Minority:</label>
                                                <div class="input-group">
                                                    <span class="input-group-text">{{totalMinority}}%</span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Owned by Minority/Female:</label>
                                                <div class="input-group">
                                                    <span class="input-group-text">{{totalMinorityFemale}}%</span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Owned by Minority/Female/Disabled:</label>
                                                <div class="input-group">
                                                    <span class="input-group-text">{{totalMinorityFemaleDisabled}}%</span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Owned by Minority/Female/Disabled/Veteran:</label>
                                                <div class="input-group">
                                                    <span class="input-group-text">{{totalMinorityFemaleDisabledVeteran}}%</span>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-form-label" for="summarizeEfforts">Summarize efforts your firm is making to increase diversity within your organization:</label>
                                                <div class="input-group">
                                                  <textarea :disabled="!inEditMode" rows="3" id="summarizeEfforts" class="form-control" v-model.trim="diversity.summarizeEfforts"></textarea>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-form-label">Is gender, ethnicity, and/or cognitive diversity a factor when making investment-related decisions?</label> <toggle-button v-model="diversity.isFactor" :disabled="!inEditMode" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" />
                                                <div class="form-group" v-if="diversity.isFactor">
                                                    <label class="col-form-label" for="factor">Please describe </label>
                                                    <textarea :disabled="!inEditMode" rows="3" id="factor" class="form-control" v-model.trim="diversity.factor"></textarea>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-form-label">Is your firm making efforts to ensure that diverse employees feel and are included?</label> <toggle-button v-model="diversity.isMakingEfforts" :disabled="!inEditMode" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" />
                                                <div class="form-group" v-if="diversity.isMakingEfforts">
                                                    <label class="col-form-label" for="makingEfforts">Please describe </label>
                                                    <div class="input-group">
                                                      <textarea :disabled="!inEditMode" rows="3" id="makingEfforts" class="form-control" v-model.trim="diversity.makingEfforts"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-form-label">Is enhancing diversity directly tied to compensation?</label> <toggle-button v-model="diversity.isCompensation" :disabled="!inEditMode" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                <div class="form-group" v-if="diversity.isCompensation">
                                                    <label class="col-form-label" for="compensation">Please describe </label>
                                                    <div class="input-group">
                                                      <textarea :disabled="!inEditMode" rows="3" id="compensation" class="form-control" v-model.trim="diversity.compensation"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-form-label">Please provide any additional information related to your firm’s diversity-related initiatives and/or philosophy:</label>
                                                  <div class="input-group">
                                                    <textarea :disabled="!inEditMode" rows="3" id="compensation" class="form-control" v-model.trim="diversity.additionalInfo"></textarea>
                                                  </div>
                                            </div>


                                                <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                                                <div :class="{'collapse': !showLogs }">
                                                    <div class="form-group">
                                                        <label>Created On</label>
                                                        <AppDate :date="diversity.createdOn"/>
                                                    </div>
                                                    <div class="form-group" v-if="diversity.updatedOn">
                                                        <label>Modified On</label>
                                                        <AppDate :date="diversity.updatedOn" :emptyIfNull="true"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <ul class="nav nav-tabs tabs-bordered">
                                                    <li class="nav-item">
                                                        <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeSMTab == 1}" @click="showSMTab(1)">
                                                          Notes <span class="badge badge-primary noti-icon-badge" v-if="activities">{{activities.length}}</span></a>
                                                    </li>
                                                </ul>

                                                <div class="tab-content">
                                                    <div class="tab-pane" :class="{'active show': activeSMTab == 1}">
                                                        <div class="tab-pane fade show active">
                                                            <ActivityList :activities="activities" :isEditMode="inEditMode" @onUpdate="onActivityUpdate" @onClickNew="clickAddActivity" @onSelect="showActivity" @onSelectUpdate="editActivity"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>
                </div>
            </div>
        </div>

        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <ActivityItem @onUpdate="onActivityUpdate" v-if="rightbarItem == 'activity'" :objectId="12" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rightbar from '@/mixins/rightbar'
import { PortalDiversityService } from '@/services/portal-service'
import { ActivityService } from '@/services/activity-service'
import ActivityList from '@/components/ActivityList'
import ActivityItem from '@/components/ActivityItem'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Toolbar from '@/components/portal/PortalToolbar'

export default {
  components: {
    ActivityList,
    ActivityItem,
    Toolbar
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  mixins: [rightbar, asyncDataStatus],
  data () {
    return {
      activeTab: 1,
      activeSMTab: 1,
      showLogs: false,
      inEditMode: false,
      inSaving: false,
      sideInEditMode: false,
      diversity: {},
      rightbarItem: null,
      addSidebar: null,
      firmTypeObj: null,
      ownershipIdObj: null,
      activities: null
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers', 'fetchActivity']),
    showTab (tabId) {
      this.activeTab = tabId
    },
    showSMTab (tabId) {
      this.activeSMTab = tabId
    },
    sidebarAction (item, isAdd, isOpen, isEdit) {
      this.addSidebar = isAdd
      this.sideInEditMode = isEdit || isAdd
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    saveForm () {
      // this.$v.diversity.$touch()
      // if (!this.$v.diversity.$error) {
      //   this.inSaving = true
      //   // contactService.save(this.diversity.contactId, this.contact).then(
      //   //   (response) => {
      //   //     if (response.status !== 200) {
      //   //       this.$snotify.error('Error!')
      //   //     } else {
      //   //       this.inEditMode = false
      //   //       this.$snotify.success('Changes saved.')
      //   //     }
      //   //     this.inSaving = false
      //   //   }
      //   // ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      // } else {
      //   this.$snotify.error('Please fix the errors.')
      // }
    },
    EditForm () {
      this.inEditMode = true
      this.addSidebar = false
    },
    cancelForm () {
      // if (!this.$v.diversity.$anyDirty) {
      //   this.cancelFormOper()
      //   return
      // }

      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to cancel?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.cancelFormOper()
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    cancelFormOper () {
      this.inEditMode = false
      this.getDetails()
      if (this.addSidebar) {
        this.hideRightbar()
        this.addSidebar = false
      }
    },
    getDetails () {
      PortalDiversityService.get(this.id)
      .then((response) => {
        this.diversity = response.data
      }).then(() => {
        this.fetchActivities()
      }).then(() => {
        this.asyncDataStatus_fetched('Portal Diversity Details')
      }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    getTables () {
      this.fetchAllUsers()
    },
    fetchActivities () {
      ActivityService.getPortalDiversityActivities(this.diversity.firmId).then(
        (response) => {
          if (response.data.length > 0) {
            this.activities = response.data
          } else {
            this.activities = null
          }
        }
      )
    },
    showActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true)
    },
    editActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true, true)
    },
    clickAddActivity () {
      this.$store.commit('setNewActivity', this.diversity.firmId)
      this.sidebarAction('activity', true, true, true)
    },
    onActivityUpdate () {
      this.fetchActivities()
      this.sidebarAction('', false, false)
    }
  },
  created () {
    this.addSidebar = false
    this.hideRightbar()
    this.getTables()
    this.getDetails()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allUsers () {
      return this.$store.state.allUsers
    },
    canbeEdited () {
      return this.user.groupId < 11
    },
    totalOwnership () {
      return this.diversity.africanMale + this.diversity.africanFemale + this.diversity.africanBinary +
        this.diversity.asianMale + this.diversity.asianFemale + this.diversity.asianBinary +
        this.diversity.hispanicMale + this.diversity.hispanicFemale + this.diversity.hispanicBinary +
        this.diversity.nativeMale + this.diversity.nativeFemale + this.diversity.nativeBinary +
        this.diversity.otherMale + this.diversity.otherFemale + this.diversity.otherBinary +
        this.diversity.nonMale + this.diversity.nonFemale + this.diversity.nonBinary +
        this.diversity.disabledAfricanMale + this.diversity.disabledAfricanFemale + this.diversity.disabledAfricanBinary +
        this.diversity.disabledAsianMale + this.diversity.disabledAsianFemale + this.diversity.disabledAsianBinary +
        this.diversity.disabledHispanicMale + this.diversity.disabledHispanicFemale + this.diversity.disabledHispanicBinary +
        this.diversity.disabledNativeMale + this.diversity.disabledNativeFemale + this.diversity.disabledNativeBinary +
        this.diversity.disabledOtherMale + this.diversity.disabledOtherFemale + this.diversity.disabledOtherBinary +
        this.diversity.disabledNonMale + this.diversity.disabledNonFemale + this.diversity.disabledNonBinary +
        this.diversity.disabledVeteransAfricanMale + this.diversity.disabledVeteransAfricanFemale + this.diversity.disabledVeteransAfricanBinary +
        this.diversity.disabledVeteransAsianMale + this.diversity.disabledVeteransAsianFemale + this.diversity.disabledVeteransAsianBinary +
        this.diversity.disabledVeteransHispanicMale + this.diversity.disabledVeteransHispanicFemale + this.diversity.disabledVeteransHispanicBinary +
        this.diversity.disabledVeteransNativeMale + this.diversity.disabledVeteransNativeFemale + this.diversity.disabledVeteransNativeBinary +
        this.diversity.disabledVeteransOtherMale + this.diversity.disabledVeteransOtherFemale + this.diversity.disabledVeteransOtherBinary +
        this.diversity.disabledVeteransNonMale + this.diversity.disabledVeteransNonFemale + this.diversity.disabledVeteransNonBinary +
        this.diversity.veteransAfricanMale + this.diversity.veteransAfricanFemale + this.diversity.veteransAfricanBinary +
        this.diversity.veteransAsianMale + this.diversity.veteransAsianFemale + this.diversity.veteransAsianBinary +
        this.diversity.veteransHispanicMale + this.diversity.veteransHispanicFemale + this.diversity.veteransHispanicBinary +
        this.diversity.veteransNativeMale + this.diversity.veteransNativeFemale + this.diversity.veteransNativeBinary +
        this.diversity.veteransOtherMale + this.diversity.veteransOtherFemale + this.diversity.veteransOtherBinary +
        this.diversity.veteransNonMale + this.diversity.veteransNonFemale + this.diversity.veteransNonBinary
    },
    totalOwnedByDisabled () {
      return this.diversity.disabledAfricanMale + this.diversity.disabledAfricanFemale + this.diversity.disabledAfricanBinary +
        this.diversity.disabledAsianMale + this.diversity.disabledAsianFemale + this.diversity.disabledAsianBinary +
        this.diversity.disabledHispanicMale + this.diversity.disabledHispanicFemale + this.diversity.disabledHispanicBinary +
        this.diversity.disabledNativeMale + this.diversity.disabledNativeFemale + this.diversity.disabledNativeBinary +
        this.diversity.disabledOtherMale + this.diversity.disabledOtherFemale + this.diversity.disabledOtherBinary +
        this.diversity.disabledNonMale + this.diversity.disabledNonFemale + this.diversity.disabledNonBinary +
        this.diversity.disabledVeteransAfricanMale + this.diversity.disabledVeteransAfricanFemale + this.diversity.disabledVeteransAfricanBinary +
        this.diversity.disabledVeteransAsianMale + this.diversity.disabledVeteransAsianFemale + this.diversity.disabledVeteransAsianBinary +
        this.diversity.disabledVeteransHispanicMale + this.diversity.disabledVeteransHispanicFemale + this.diversity.disabledVeteransHispanicBinary +
        this.diversity.disabledVeteransNativeMale + this.diversity.disabledVeteransNativeFemale + this.diversity.disabledVeteransNativeBinary +
        this.diversity.disabledVeteransOtherMale + this.diversity.disabledVeteransOtherFemale + this.diversity.disabledVeteransOtherBinary +
        this.diversity.disabledVeteransNonMale + this.diversity.disabledVeteransNonFemale + this.diversity.disabledVeteransNonBinary
    },
    totalOwnedByVeterans () {
      return this.diversity.veteransAfricanMale + this.diversity.veteransAfricanFemale + this.diversity.veteransAfricanBinary +
          this.diversity.veteransAsianMale + this.diversity.veteransAsianFemale + this.diversity.veteransAsianBinary +
          this.diversity.veteransHispanicMale + this.diversity.veteransHispanicFemale + this.diversity.veteransHispanicBinary +
          this.diversity.veteransNativeMale + this.diversity.veteransNativeFemale + this.diversity.veteransNativeBinary +
          this.diversity.veteransOtherMale + this.diversity.veteransOtherFemale + this.diversity.veteransOtherBinary +
          this.diversity.veteransNonMale + this.diversity.veteransNonFemale + this.diversity.veteransNonBinary +
          this.diversity.disabledVeteransAfricanMale + this.diversity.disabledVeteransAfricanFemale + this.diversity.disabledVeteransAfricanBinary +
          this.diversity.disabledVeteransAsianMale + this.diversity.disabledVeteransAsianFemale + this.diversity.disabledVeteransAsianBinary +
          this.diversity.disabledVeteransHispanicMale + this.diversity.disabledVeteransHispanicFemale + this.diversity.disabledVeteransHispanicBinary +
          this.diversity.disabledVeteransNativeMale + this.diversity.disabledVeteransNativeFemale + this.diversity.disabledVeteransNativeBinary +
          this.diversity.disabledVeteransOtherMale + this.diversity.disabledVeteransOtherFemale + this.diversity.disabledVeteransOtherBinary +
          this.diversity.disabledVeteransNonMale + this.diversity.disabledVeteransNonFemale + this.diversity.disabledVeteransNonBinary
    },
    totalMinority () {
      return this.diversity.africanMale + this.diversity.africanFemale + this.diversity.africanBinary +
        this.diversity.asianMale + this.diversity.asianFemale + this.diversity.asianBinary +
        this.diversity.hispanicMale + this.diversity.hispanicFemale + this.diversity.hispanicBinary +
        this.diversity.nativeMale + this.diversity.nativeFemale + this.diversity.nativeBinary +
        this.diversity.otherMale + this.diversity.otherFemale + this.diversity.otherBinary +
        this.diversity.disabledAfricanMale + this.diversity.disabledAfricanFemale + this.diversity.disabledAfricanBinary +
        this.diversity.disabledAsianMale + this.diversity.disabledAsianFemale + this.diversity.disabledAsianBinary +
        this.diversity.disabledHispanicMale + this.diversity.disabledHispanicFemale + this.diversity.disabledHispanicBinary +
        this.diversity.disabledNativeMale + this.diversity.disabledNativeFemale + this.diversity.disabledNativeBinary +
        this.diversity.disabledOtherMale + this.diversity.disabledOtherFemale + this.diversity.disabledOtherBinary +
        this.diversity.disabledVeteransAfricanMale + this.diversity.disabledVeteransAfricanFemale + this.diversity.disabledVeteransAfricanBinary +
        this.diversity.disabledVeteransAsianMale + this.diversity.disabledVeteransAsianFemale + this.diversity.disabledVeteransAsianBinary +
        this.diversity.disabledVeteransHispanicMale + this.diversity.disabledVeteransHispanicFemale + this.diversity.disabledVeteransHispanicBinary +
        this.diversity.disabledVeteransNativeMale + this.diversity.disabledVeteransNativeFemale + this.diversity.disabledVeteransNativeBinary +
        this.diversity.disabledVeteransOtherMale + this.diversity.disabledVeteransOtherFemale + this.diversity.disabledVeteransOtherBinary +
        this.diversity.veteransAfricanMale + this.diversity.veteransAfricanFemale + this.diversity.veteransAfricanBinary +
        this.diversity.veteransAsianMale + this.diversity.veteransAsianFemale + this.diversity.veteransAsianBinary +
        this.diversity.veteransHispanicMale + this.diversity.veteransHispanicFemale + this.diversity.veteransHispanicBinary +
        this.diversity.veteransNativeMale + this.diversity.veteransNativeFemale + this.diversity.veteransNativeBinary +
        this.diversity.veteransOtherMale + this.diversity.veteransOtherFemale + this.diversity.veteransOtherBinary
    },
    totalMinorityFemale () {
      return this.totalMinority + this.diversity.nonFemale + this.diversity.disabledNonFemale + this.diversity.disabledVeteransNonFemale + this.diversity.veteransNonFemale
    },
    totalMinorityFemaleDisabled () {
      return this.totalMinorityFemale + this.diversity.disabledVeteransNonMale + this.diversity.disabledNonMale + this.diversity.disabledNonBinary
    },
    totalMinorityFemaleDisabledVeteran () {
      return this.totalMinorityFemaleDisabled + this.diversity.disabledVeteransNonBinary + this.diversity.veteransNonMale + this.diversity.veteransNonBinary
    },
    validateVeterans () {
      return this.diversity.ownedByVeterans ? this.totalOwnedByVeterans > 0 : true
    },
    validateDisabled () {
      return this.diversity.ownedByDisabled ? this.totalOwnedByDisabled > 0 : true
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
