<template>
  <tbody>
    <tr v-if="funds === null">
      <td class="text-center">No Deal to show</td>
    </tr>
    <tr v-for="fund in funds"
        :key="fund.fundId">
        <td>
          <router-link :to="{name: 'FundsDetail', params: {id: fund.fundId}}" target="_blank">
              {{fund.fundName}}
          </router-link>
        </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    funds: {
      type: Array,
      default: null
    }
  }
}
</script>
