<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portal-firms'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="firm">
                    <Toolbar :title="'Navigator Firm Details - '+firm.firmName" :icon="'mdi mdi-web'" :isAgency="firm.isAgency" :agencyName="firm.agencyFirmName"
                    :showToolbar="true" :inEditMode="inEditMode" :canEdit="canbeEdited" :isSaving="inSaving"
                    :isNew="firm.umbFirmId == 0 && !firm.umbrellaCheck" :isUpdated="firm.umbFirmId > 0 && !firm.umbrellaCheck" :isDeleted="firm.deletedOn != null"
                    :firmImported ="false" :isFund="false" :isFirm="true" :isNavAdmin="isNavAdmin"
                    @onEditImport="EditForm" @onCancel="cancelForm" @onSave="saveForm" @onAdminEdit="onAdminEdit" />
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-box">
                                <ul class="nav nav-tabs tabs-bordered">
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(1)" :class="{'active': activeTab == 1}">
                                            <i class="mdi mdi-information-outline"></i> Summary
                                        </a>
                                    </li>
                                    <li class="nav-item" v-if="firmUmbrella != null">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(2)" :class="{'active': activeTab == 2}">
                                            <i class="mdi mdi-swap-horizontal"></i> Compare
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane fade" :class="{'active show': activeTab == 1}">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label for="firmName">Name <span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="firm.firmName" @blur="$v.firm.firmName.$touch()" placeholder="Enter Name" class="form-control" id="firmName" :disabled="!inEditMode" :class="{ 'parsley-error': $v.firm.firmName.$error }">
                                                    <template v-if="$v.firm.firmName.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.firmName.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.firmName.min" class="parsley-required">This value is too short</li>
                                                        <li v-if="!$v.firm.firmName.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="Website">Website <span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="firm.website" placeholder="Website" class="form-control" id="Website" :disabled="!inEditMode" @blur="$v.firm.website.$touch()" :class="{ 'parsley-error': $v.firm.website.$error, 'updated-portal' : firmUmbrella != null && firm.website !== firmUmbrella.website }">
                                                    <template v-if="$v.firm.website.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.website.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.website.isValidURL && $v.firm.website.required" class="parsley-required">Website is not valid</li>
                                                        <li v-if="!$v.firm.website.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="FirmOverview">Firm Overview <span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <textarea placeholder="Enter Overview" v-model.trim="firm.overview" :disabled="!inEditMode" class="form-control" rows="6" id="FirmOverview" @blur="$v.firm.overview.$touch()" :class="{ 'parsley-error': $v.firm.overview.$error, 'updated-portal' : firmUmbrella != null && firm.overview !== firmUmbrella.overview }"/>
                                                    <template v-if="$v.firm.overview.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.overview.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.overview.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="address1">Address</label>
                                                    <input type="text" v-model.trim="firm.address1" class="form-control" id="address1" :disabled="!inEditMode" @blur="$v.firm.address1.$touch()" :class="{ 'parsley-error': $v.firm.address1.$error, 'updated-portal' : firmUmbrella != null && firm.address1 !== firmUmbrella.address1 }" />
                                                    <template v-if="$v.firm.address1.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.address1.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.address1.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                    <input type="text" v-model.trim="firm.address2" class="form-control" id="address2" :disabled="!inEditMode" :class="{ 'updated-portal' : firmUmbrella != null && firm.address2 !== firmUmbrella.address2 }" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="city">City</label>
                                                    <input type="text" v-model.trim="firm.city" class="form-control" id="city" :disabled="!inEditMode" @blur="$v.firm.city.$touch()" :class="{ 'parsley-error': $v.firm.city.$error, 'updated-portal' : firmUmbrella != null && firm.city !== firmUmbrella.city }" />
                                                    <template v-if="$v.firm.city.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.city.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.city.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="region">State</label>
                                                    <input type="text" v-model.trim="firm.region" class="form-control" id="region" :disabled="!inEditMode" @blur="$v.firm.region.$touch()" :class="{ 'parsley-error': $v.firm.region.$error, 'updated-portal' : firmUmbrella != null && firm.region !== firmUmbrella.region }" />
                                                    <template v-if="$v.firm.region.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.region.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.region.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="zipcode">Zip Code</label>
                                                    <input type="text" v-model.trim="firm.zipCode" class="form-control" id="zipcode" :disabled="!inEditMode" @blur="$v.firm.zipCode.$touch()" :class="{ 'parsley-error': $v.firm.zipCode.$error, 'updated-portal' : firmUmbrella != null && firm.zipCode !== firmUmbrella.zipCode }" />
                                                    <template v-if="$v.firm.zipCode.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.zipCode.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.zipCode.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="country">Country</label>
                                                    <input type="text" v-model.trim="firm.country" class="form-control" id="country" :disabled="!inEditMode" @blur="$v.firm.country.$touch()" :class="{ 'parsley-error': $v.firm.country.$error, 'updated-portal' : firmUmbrella != null && firm.country !== firmUmbrella.country }" />
                                                    <template v-if="$v.firm.country.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.country.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.country.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                                                <div :class="{'collapse': !showLogs }">
                                                    <div class="form-group">
                                                        <label>Created On</label>
                                                        <AppDate :date="firm.createdOn"/>
                                                    </div>
                                                    <div class="form-group" v-if="firm.updatedOn">
                                                        <label>Modified On</label>
                                                        <AppDate :date="firm.updatedOn" :emptyIfNull="true"/>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-lg-6">
                                                <ul class="nav nav-tabs tabs-bordered">
                                                    <li class="nav-item">
                                                        <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeSMTab == 1}" @click="showSMTab(1)">
                                                          Notes <span class="badge badge-primary noti-icon-badge" v-if="activities">{{activities.length}}</span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeSMTab == 2}" @click="showSMTab(2)">
                                                          Employees <span class="badge badge-primary noti-icon-badge" v-if="firmContacts">{{firmContacts.length}}</span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeSMTab == 3}" @click="showSMTab(3)">
                                                          Deals <span class="badge badge-primary noti-icon-badge" v-if="relatedFunds">{{relatedFunds.length}}</span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeSMTab == 4}" @click="showSMTab(4)">
                                                          Diversity <span class="badge badge-primary noti-icon-badge" v-if="relatedDiversity">1</span></a>
                                                    </li>
                                                </ul>

                                                <div class="tab-content">
                                                    <div class="tab-pane" :class="{'active show': activeSMTab == 2}">
                                                      <table class="table table-bordered table-hover">
                                                          <thead>
                                                            <tr>
                                                                <th>Full Name <i class="mdi mdi-open-in-new"></i></th>
                                                                <th>Job Title</th>
                                                                <th>Email</th>
                                                                <th>Phone</th>
                                                                <th>Restricted User?</th>
                                                                <th>Status</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr v-if="firmContacts === null">
                                                              <td colspan="6" class="text-center">No Data to show</td>
                                                            </tr>
                                                            <tr v-for="employe in firmContacts"
                                                                :key="employe.userId">
                                                                <td>
                                                                  <router-link :to="{name: 'PortalContactsDetail', params: {id: employe.userId}}" target="_blank">
                                                                    {{employe.firstName}} {{employe.lastName}}
                                                                  </router-link>
                                                                  <i v-if="employe.emailConfirmed" class="mdi mdi-check-circle" v-tooltip="'Verified'"></i>
                                                                </td>
                                                                <td>{{ employe.jobTitle}}</td>
                                                                <td>{{ employe.email}}</td>
                                                                <td>Business: {{ employe.phone}} <br />
                                                                Mobile: {{ employe.mobile}}</td>
                                                                <td>{{ employe.isRestricted? "Yes": "No"}}</td>
                                                                <td>
                                                                    <span v-if="employe.umbrellaCheck" class="badge badge-success">Imported</span>
                                                                    <span v-else class="badge badge-warning">New</span>
                                                                    <span v-if="employe.deletedOn != null" class="badge badge-danger">Deleted</span>
                                                                </td>
                                                            </tr>
                                                          </tbody>
                                                          <tfoot v-if="isNavAdmin">
                                                            <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="AddUser"> <i class="mdi mdi-account-plus"></i> Add User</button>
                                                          </tfoot>
                                                      </table>                                                            
                                                    </div>
                                                    <div class="tab-pane" :class="{'active show': activeSMTab == 1}">
                                                        <div class="tab-pane fade show active">
                                                            <ActivityList :activities="activities" :isEditMode="inEditMode" @onUpdate="onActivityUpdate" @onClickNew="clickAddActivity" @onSelect="showActivity" @onSelectUpdate="editActivity"/>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane" :class="{'active show': activeSMTab == 3}">
                                                        <div class="form-group">
                                                            <table class="table table-bordered table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Deal Name <i class="mdi mdi-open-in-new"></i></th>
                                                                        <th>Type</th>
                                                                        <th>Geography</th>
                                                                        <th>Sector</th>
                                                                        <th>Vintage</th>
                                                                        <th>Target Size</th>
                                                                        <th>Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                  <tr v-if="relatedFunds === null">
                                                                    <td colspan="8" class="text-center">No Related Deals to show</td>
                                                                  </tr>
                                                                  <tr v-for="fund in relatedFunds"
                                                                      :key="fund.fundId">
                                                                      <td>
                                                                        <router-link :to="{name: 'PortalDealsDetail', params: {id: fund.fundId}}" target="_blank">
                                                                            {{fund.fundName}}
                                                                        </router-link>
                                                                      </td>
                                                                      <td>{{fund.typeName}}</td>
                                                                      <td>{{fund.geographicName}}</td>
                                                                      <td>{{fund.sectorName}}</td>
                                                                      <td>{{fund.vintageYear}}</td>
                                                                      <td>{{fund.targetSize}}</td>
                                                                      <td>
                                                                        <span v-if="fund.umbrellaCheck" class="badge badge-success">Imported</span>
                                                                        <span v-else class="badge badge-warning">New</span>
                                                                        <span v-if="fund.deletedOn != null" class="badge badge-danger">Deleted</span>
                                                                      </td>
                                                                  </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane" :class="{'active show': activeSMTab == 4}">
                                                        <div class="form-group">
                                                            <table class="table table-bordered table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Submitted <i class="mdi mdi-open-in-new"></i></th>
                                                                        <th>Total Count</th>
                                                                        <th>Owned By Disabled</th>
                                                                        <th>Owned By Veterans</th>
                                                                        <th>Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                  <tr v-if="relatedDiversity === null">
                                                                    <td colspan="5" class="text-center">No Data to show</td>
                                                                  </tr>
                                                                  <tr v-else>
                                                                      <td>
                                                                        <router-link :to="{name: 'PortalDiversityDetail', params: {id: relatedDiversity.firmId}}" target="_blank">
                                                                            <AppDate :date="relatedDiversity.createdOn" :show="'text'" />
                                                                        </router-link>
                                                                      </td>
                                                                      <td>{{ relatedDiversity.totalCnt }}</td>
                                                                      <td>{{ relatedDiversity.ownedByDisabled ? 'Yes' : 'No' }}</td>
                                                                      <td>{{ relatedDiversity.ownedByVeterans ? 'Yes' : 'No' }}</td>
                                                                      <td>
                                                                        <span v-if="relatedDiversity.umbrellaCheck" class="badge badge-success">Imported</span>
                                                                        <span v-else class="badge badge-warning">New</span>
                                                                        <span v-if="relatedDiversity.deletedOn != null" class="badge badge-danger">Deleted</span>
                                                                      </td>
                                                                  </tr>
                                                                </tbody>
                                                            </table>
                                                            <label class="navigator-align">Diversity skipped?</label>
                                                            <toggle-button v-model="isDiversitySkipped" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" class="m-2" :disabled="true" />
                                                            <button class="btn btn-warning btn-custom waves-effect w-md waves-light m-b-5 mr-2"  :disabled="isDiversitySkipSaving" v-if="isNavAdmin && allowSkipDiversity " @click="onDiversitySkipSave" ><span v-if="!isDiversitySkipSaving">Allow</span> <i v-else class="fa fa-spinner fa-spin"></i></button>
                                                            <div class="col-sm-6" v-if="SkipDiversity.firmId > 0">
                                                            <div class="form-group" >
                                                                <label>Allowed On</label>
                                                                <AppDate :date="SkipDiversity.craetedOn"/>
                                                            </div>
                                                            <label class="navigator-align">Notes</label>
                                                            <input type="text" v-model.trim="SkipDiversity.notes" class="form-control" id="notes" :disabled="true"  />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" :class="{'active show': activeTab == 2}">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label for="firmName_b">Name <span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="firm.firmName" @blur="$v.firm.firmName.$touch()" placeholder="Enter Name" class="form-control" id="firmName_b" :disabled="!inEditMode" :class="{ 'parsley-error': $v.firm.firmName.$error }">
                                                    <template v-if="$v.firm.firmName.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.firmName.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.firmName.min" class="parsley-required">This value is too short</li>
                                                        <li v-if="!$v.firm.firmName.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="Website_b">Website <span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="firm.website" placeholder="Website" class="form-control" id="Website_b" :disabled="!inEditMode" @blur="$v.firm.website.$touch()" :class="{ 'parsley-error': $v.firm.website.$error, 'updated-portal' : firmUmbrella != null && firm.website !== firmUmbrella.website }">
                                                    <template v-if="$v.firm.website.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.website.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.website.isValidURL && $v.firm.website.required" class="parsley-required">Website is not valid</li>
                                                        <li v-if="!$v.firm.website.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="FirmOverview_b" class="navigator-align">Firm Overview <span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <textarea placeholder="Enter Overview" v-model.trim="firm.overview" :disabled="!inEditMode" class="form-control" rows="6" id="FirmOverview_b" @blur="$v.firm.overview.$touch()" :class="{ 'parsley-error': $v.firm.overview.$error, 'updated-portal' : firmUmbrella != null && firm.overview !== firmUmbrella.overview }"/>
                                                    <template v-if="$v.firm.overview.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.overview.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.overview.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="address1_b" class="navigator-align">Address</label>
                                                    <input type="text" v-model.trim="firm.address1" class="form-control" id="address1_b" :disabled="!inEditMode" @blur="$v.firm.address1.$touch()" :class="{ 'parsley-error': $v.firm.address1.$error, 'updated-portal' : firmUmbrella != null && firm.address1 !== firmUmbrella.address1 }" />
                                                    <template v-if="$v.firm.address1.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.address1.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.address1.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                    <input type="text" v-model.trim="firm.address2" class="form-control" id="address2_b" :disabled="!inEditMode" @blur="$v.firm.address2.$touch()" :class="{ 'parsley-error': $v.firm.address2.$error, 'updated-portal' : firmUmbrella != null && firm.address2 !== firmUmbrella.address2 }" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="city_b" class="navigator-align">City</label>
                                                    <input type="text" v-model.trim="firm.city" class="form-control" id="city_b" :disabled="!inEditMode" @blur="$v.firm.city.$touch()" :class="{ 'parsley-error': $v.firm.city.$error, 'updated-portal' : firmUmbrella != null && firm.city !== firmUmbrella.city }" />
                                                    <template v-if="$v.firm.city.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.city.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.city.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="region_b" class="navigator-align">State</label>
                                                    <input type="text" v-model.trim="firm.region" class="form-control" id="region_b" :disabled="!inEditMode" @blur="$v.firm.region.$touch()" :class="{ 'parsley-error': $v.firm.region.$error, 'updated-portal' : firmUmbrella != null && firm.region !== firmUmbrella.region }" />
                                                    <template v-if="$v.firm.region.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.region.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.region.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="zipcode_b" class="navigator-align">Zip Code</label>
                                                    <input type="text" v-model.trim="firm.zipCode" class="form-control" id="zipcode_b" :disabled="!inEditMode" @blur="$v.firm.zipCode.$touch()" :class="{ 'parsley-error': $v.firm.zipCode.$error, 'updated-portal' : firmUmbrella != null && firm.zipCode !== firmUmbrella.zipCode }" />
                                                    <template v-if="$v.firm.zipCode.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.zipCode.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.zipCode.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="country_b" class="navigator-align">Country</label>
                                                    <input type="text" v-model.trim="firm.country" class="form-control" id="country_b" :disabled="!inEditMode" @blur="$v.firm.country.$touch()" :class="{ 'parsley-error': $v.firm.country.$error, 'updated-portal' : firmUmbrella != null && firm.country !== firmUmbrella.country }" />
                                                    <template v-if="$v.firm.country.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.country.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.country.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <hr/>

                                                <div class="form-group">
                                                    <label>Created On</label>
                                                    <!-- HOW ARE THESE DIFFERENT? -->
                                                    <AppDate :date="firm.createdOn"/>
                                                </div>
                                                <div class="form-group" v-if="firm.updatedOn">
                                                    <label>Modified On</label>
                                                    <AppDate :date="firm.updatedOn" :emptyIfNull="true"/>
                                                </div>
                                            </div>

                                            <div class="col-lg-6" v-if="firmUmbrella">
                                                <div class="form-group">
                                                    <button :disabled="!inEditMode" class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="firmUmbrella.firmName.length > 0 && firmUmbrella.firmName != firm.firmName" @click="firm.firmName = firmUmbrella.firmName"><i class="mdi mdi-arrow-left-bold"></i></button>
                                                    <label for="firmName_u" class="navigator-align">Name</label>
                                                    <input type="text" v-model.trim="firmUmbrella.firmName" placeholder="Enter Name" class="form-control" id="firmName_u" readonly="readonly" />
                                                </div>

                                                <div class="form-group">
                                                    <button :disabled="!inEditMode" class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="firmUmbrella.website != null && firmUmbrella.website != firm.website" @click="firm.website = firmUmbrella.website"><i class="mdi mdi-arrow-left-bold"></i></button>
                                                    <label for="Website_u" class="navigator-align">Website</label>
                                                    <input type="text" v-model.trim="firmUmbrella.website" placeholder="Website" class="form-control" id="Website_u" readonly="readonly" />
                                                </div>

                                                <div class="form-group">
                                                    <button :disabled="!inEditMode" class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="firmUmbrella.overview != null && firmUmbrella.overview.length > 0 && firmUmbrella.overview != firm.overview" @click="firm.overview = firmUmbrella.overview"><i class="mdi mdi-arrow-left-bold"></i></button>
                                                    <label for="FirmOverview_u" class="navigator-align">Firm Overview</label>
                                                    <textarea placeholder="Enter Overview" v-model.trim="firmUmbrella.overview" readonly="readonly" class="form-control" rows="6" id="FirmOverview_u" />
                                                </div>

                                                <div class="form-group">
                                                    <button :disabled="!inEditMode" class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uAddress.length > 0 && (firmUmbrella.address1 != firm.address1 || firmUmbrella.address2 != firm.address2)" @click="firm.address1 = firmUmbrella.address1, firm.address2 = firmUmbrella.address2"><i class="mdi mdi-arrow-left-bold"></i></button>
                                                    <label for="address1_u" class="navigator-align">Address</label>
                                                    <input type="text" v-model.trim="firmUmbrella.address1" class="form-control" id="address1_u" readonly="readonly" />
                                                    <input type="text" v-model.trim="firmUmbrella.address2" class="form-control" id="address2_u" readonly="readonly" />
                                                </div>
                                                <div class="form-group">
                                                    <button :disabled="!inEditMode" class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uCity.length > 0 && firmUmbrella.city != firm.city" @click="firm.city = firmUmbrella.city"><i class="mdi mdi-arrow-left-bold"></i></button>
                                                    <label for="city_u" class="navigator-align">City</label>
                                                    <input type="text" v-model.trim="firmUmbrella.city" class="form-control" id="city_u" readonly="readonly" />
                                                </div>
                                                <div class="form-group">
                                                    <button :disabled="!inEditMode" class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uRegion.length > 0 && firmUmbrella.region != firm.region" @click="firm.region = firmUmbrella.region"><i class="mdi mdi-arrow-left-bold"></i></button>
                                                    <label for="region_u" class="navigator-align">State</label>
                                                    <input type="text" v-model.trim="firmUmbrella.region" class="form-control" id="region_u" readonly="readonly" />
                                                </div>
                                                <div class="form-group">
                                                    <button :disabled="!inEditMode" class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uZipCode.length > 0 && firmUmbrella.zipCode != firm.zipCode" @click="firm.zipCode = firmUmbrella.zipCode"><i class="mdi mdi-arrow-left-bold"></i></button>
                                                    <label for="zipcode_u" class="navigator-align">Zip Code</label>
                                                    <input type="text" v-model.trim="firmUmbrella.zipCode" class="form-control" id="zipcode_u" readonly="readonly" />
                                                </div>
                                                <div class="form-group">
                                                    <button :disabled="!inEditMode" class="btn btn-mini btn-icon waves-effect waves-light btn-primary" v-if="uCountry.length > 0 && firmUmbrella.country != firm.country" @click="firm.country = firmUmbrella.country"><i class="mdi mdi-arrow-left-bold"></i></button>
                                                    <label for="country_u" class="navigator-align">Country</label>
                                                    <input type="text" v-model.trim="firmUmbrella.country" class="form-control" id="country_u" readonly="readonly" />
                                                </div>

                                                <hr/>

                                                <div class="form-group">
                                                    <label>Created On</label>
                                                    <AppDate :date="firmUmbrella.createdOn"/>
                                                </div>
                                                <div class="form-group" v-if="firmUmbrella.updatedOn">
                                                    <label>Modified On</label>
                                                    <AppDate :date="firmUmbrella.updatedOn" :emptyIfNull="true"/>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> 
                        <!-- end col -->
                    </div>
                </div>
            </div>
        </div>

        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <ConflictList @onConfirm="bindUmbFirmId" v-if="rightbarItem == 'similar'" :list="similarFundList" :displayName="'firmName'" :valueName="'firmId'" :linkName="'FirmsDetail'" :firmOrDeal="'Firm'"/>
              <ActivityItem @onUpdate="onActivityUpdate" v-if="rightbarItem == 'activity'" :objectId="8" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
              <ContactsAdd @onUpdate="onUsersUpdate" v-if="rightbarItem == 'newuser'" :isEditMode="sideInEditMode" :isAdd="addSidebar" :firmId="id"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import rightbar from '@/mixins/rightbar'
import { PortalFirmService, PortalDiversitySkipService } from '@/services/portal-service'
import { FirmService } from '@/services/firm-service'
import { ActivityService } from '@/services/activity-service'
import ActivityList from '@/components/ActivityList'
import ActivityItem from '@/components/ActivityItem'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Toolbar from '@/components/portal/PortalToolbar'
import ConflictList from '@/components/portal/ConflictList'
import ContactsAdd from '@/components/portal/ContactsAdd'
const isValidURL = (string) => {
  if (string == null) {
    return false
  }
  // eslint-disable-next-line no-useless-escape
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
  return (res !== null)
}

export default {
  components: {
    ActivityList,
    ActivityItem,
    Toolbar,
    ConflictList,
     ContactsAdd
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  mixins: [rightbar, asyncDataStatus],
  data () {
    return {
      activeTab: 1,
      activeSMTab: 1,
      showLogs: false,
      inEditMode: false,
      inSaving: false,
      sideInEditMode: false,
      firm: {firmName: ''},
      firmUmbrella: null,
      rightbarItem: null,
      addSidebar: null,
      firmTypeObj: null,
      ownershipIdObj: null,
      firmContacts: null,
      relatedFunds: null,
      relatedDiversity: null,
      activities: null,
      similarFundList: null,
      saveConfirmed: false,
      allowSkipDiversity: false,
      isDiversitySkipped:false,
      SkipDiversity : {
            firmId : null,
            craetedOn: null,
            notes: ''
          },
      isDiversitySkipSaving:false
    }
  },
  validations: {
    firm: {
      firmName: { required, max: maxLength(300) },
      overview: { required, max: maxLength(2000) },
      website: { required, max: maxLength(250), isValidURL (value) { return isValidURL(value) } },
      address1: { required, max: maxLength(200) },
      address2: { max: maxLength(200) },
      city: { required, max: maxLength(100) },
      region: { required, max: maxLength(100) },
      zipCode: { required, max: maxLength(10) },
      country: { required, max: maxLength(100) }
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers', 'fetchAllCurrencies', 'fetchActivity']),
    showTab (tabId) {
      this.activeTab = tabId
    },
    showSMTab (tabId) {
      this.activeSMTab = tabId
    },
    sidebarAction (item, isAdd, isOpen, isEdit) {
      this.addSidebar = isAdd
      this.sideInEditMode = isEdit || isAdd
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    saveForm () {
      this.$v.firm.$touch()
      if (!this.$v.firm.$error) {
        this.importFirm()
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    onAdminEdit() {
      this.$router.push({name: 'AdminPortalFirmsDetail', params: {id: this.firm.firmId}})
    },
    importFirm () {
      let isOk = false
      this.inSaving = true
      if (this.firm.umbFirmId > 0) {
        PortalFirmService.checkAndImport(this.firm.firmId, this.firm).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.firm = response.data.firm
              this.firmContacts = response.data.contacts
              this.relatedFunds = response.data.funds
              this.relatedDiversity = response.data.diversity
              FirmService.get(this.firm.umbFirmId)
              .then((responseMain) => {
                if (responseMain.data != null) {
                  this.firmUmbrella = responseMain.data
                }
              }).catch(error => this.$snotify.error('Server Error! ' + error.message))
              this.$snotify.success('Import Completed')
            }
            this.inEditMode = false
            this.inSaving = false
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      } else {
        FirmService.doubleCheckFirms(this.firm.umbFirmId, this.firm).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
              return
            }
            if (this.saveConfirmed) {
              isOk = true
            } else {
              this.similarFundList = response.data
              this.sidebarAction('similar', true, true, true)
            }

            if (!isOk) {
              this.inSaving = false
              return
            }
            PortalFirmService.checkAndImport(this.firm.firmId, this.firm).then(
              (response) => {
                if (response.status !== 200) {
                  this.$snotify.error('Error!')
                } else {
                  this.firm = response.data.firm
                  this.firmContacts = response.data.contacts
                  this.relatedFunds = response.data.funds
                  this.relatedDiversity = response.data.diversity
                  this.saveConfirmed = false
                  FirmService.get(this.firm.umbFirmId)
                  .then((responseMain) => {
                    if (responseMain.data != null) {
                      this.firmUmbrella = responseMain.data
                    }
                  }).catch(error => this.$snotify.error('Server Error! ' + error.message))
                  this.$snotify.success('Import Completed')
                }
                this.inSaving = false
                this.inEditMode = false
              }
            ).catch(error => this.$snotify.error('Server Error! ' + error.message))
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      }
    },
    EditForm () {
      this.activeTab = 2
      this.inEditMode = true
      this.addSidebar = false
    },
    saveDiversitySkip() {
      this.isDiversitySkipSaving = true    
      PortalDiversitySkipService.save(this.id, {
              firmId : this.id,
              notes: 'Allowed by - ' + this.user.email
            })
            .then((response) => {
              this.isDiversitySkipSaving = false              
              if (response.status == 200) {
                this.allowSkipDiversity = false
                this.isDiversitySkipped = true
                this.SkipDiversity = response.data
              } else {
                this.allowSkipDiversity = true     
                this.isDiversitySkipped = false               
              }
              
              
            }).catch(error => {
              this.isDiversitySkipSaving = false
              this.$snotify.error('Server Error! ' + error.message)
          })

    },
    onDiversitySkipSave () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to allow skip diversity for this firm?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {                
                this.$snotify.remove(toast.id)
                this.saveDiversitySkip()        
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    cancelForm () {
      if (!this.$v.firm.$anyDirty) {
        this.cancelFormOper()
        return
      }

      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to cancel?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.cancelFormOper()
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    cancelFormOper () {
      this.inEditMode = false
      this.$v.firm.$reset()
      this.getDetails()
      if (this.addSidebar) {
        this.hideRightbar()
        this.addSidebar = false
      }
    },
    getDetails () {
      PortalFirmService.get(this.id)
      .then((response) => {
        this.firm = response.data.firm
        this.firmContacts = response.data.contacts
        this.relatedFunds = response.data.funds
        this.relatedDiversity = response.data.diversity
      }).then(() => {
        this.fetchActivities()
      }).then(() => {
        this.asyncDataStatus_fetched('Portal Firm Details')
      }).catch(error => this.$snotify.error('Server Error! ' + error.message))

      PortalDiversitySkipService.get(this.id)
      .then((response) => {
        if (response.status == 204) {
          this.allowSkipDiversity = true
          this.isDiversitySkipped = false
          this.SkipDiversity = {
            firmId : null,
            craetedOn: null,
            notes: ''
          }
        } else {
          this.allowSkipDiversity = false
          this.SkipDiversity = response.data
          this.isDiversitySkipped = true
        }
        
        
      }).catch(error => this.$snotify.error('Server Error! ' + error.message))

    },
    getTables () {
      this.fetchAllUsers()
      this.fetchAllCurrencies()
    },
    fetchActivities () {
      ActivityService.getPortalFirmActivities(this.firm.firmId).then(
        (response) => {
          if (response.data.length > 0) {
            this.activities = response.data
          } else {
            this.activities = null
          }
        }
      )
    },
    showActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true)
    },
    editActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true, true)
    },
    clickAddActivity () {
      this.$store.commit('setNewActivity', this.firm.firmId)
      this.sidebarAction('activity', true, true, true)
    },
    onActivityUpdate () {
      this.fetchActivities()
      this.sidebarAction('', false, false)
    },
    onUsersUpdate () {
      this.getDetails()
      this.sidebarAction('', false, false)
    },
    bindUmbFirmId (id) {
      if (id > 0) {
        const bind = {umbFirmId: id, firmId: 0}
        PortalFirmService.setUmbFirmId(this.firm.firmId, bind)
        .then((response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error!')
          } else {
            this.$snotify.success('Done')
            this.inEditMode = false
            this.firm.umbFirmId = id
            FirmService.get(this.firm.umbFirmId).then((responseMain) => {
              if (responseMain.data != null) {
                this.firmUmbrella = responseMain.data
              }
            })
          }
        })
      } else {
        this.saveConfirmed = true
        this.importFirm()
      }
    },
    AddUser() {
      this.sidebarAction('newuser', true, true, true)
    }
  },
  created () {
    this.addSidebar = false
    this.hideRightbar()
    this.getTables()
    this.getDetails()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allUsers () {
      return this.$store.state.allUsers
    },
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    canbeEdited () {
      return this.user.groupId < 11 && (this.firm.umbFirmId === 0 || !this.firm.umbrellaCheck)
    },
    uAddress () {
      if (this.firmUmbrella == null) {
        return ''
      }
      return (this.firmUmbrella.address1 !== null ? this.firmUmbrella.address1 : '') + (this.firmUmbrella.address2 !== null ? this.firmUmbrella.address2 : '')
    },
    uCity () {
      if (this.firmUmbrella == null) {
        return ''
      }
      return this.firmUmbrella.city !== null ? this.firmUmbrella.city : ''
    },
    uRegion () {
      if (this.firmUmbrella == null) {
        return ''
      }
      return this.firmUmbrella.region !== null ? this.firmUmbrella.region : ''
    },
    uZipCode () {
      if (this.firmUmbrella == null) {
        return ''
      }
      return this.firmUmbrella.zipCode !== null ? this.firmUmbrella.zipCode : ''
    },
    uCountry () {
      if (this.firmUmbrella == null) {
        return ''
      }
      return this.firmUmbrella.country !== null ? this.firmUmbrella.country : ''
    },
    isNavAdmin () {      
      return this.user.roles.filter(x => x.roleId === this.$userRoles.NAVIGATOR_ADMINISTRATOR).length > 0
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
