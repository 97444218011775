<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'dashboard'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Dashboard'" :icon="'ti-home'"/>
                    <h1>Alternatives Under ONE ☂️</h1>
                    <Clocks/>
                    <div class="row">
                        <div class="col-lg-10 col-ms-9">
                          <div class="row">
                            <UserDealsList v-if="user.groupId === 1 || user.groupId === 5 || user.groupId === 7 || user.groupId == 9" />
                            <ActiveVotes v-if="user.groupId < 11" />
                            <UserTasksList v-if="user.groupId === 1 || user.groupId === 5 || user.groupId === 7 || user.groupId == 9" />
                            <LegalList v-if="user.groupId === 2 || user.groupId === 8 || user.groupId === 13" />
                            <OpsList v-if="user.groupId === 4 || user.groupId === 7 || user.groupId === 8 || user.groupId === 1 || user.groupId === 5" />
                          </div>
                          <CalendarList v-if="user.isTrackMeeting" />
                        </div>
                        <div class="col-lg-2 col-ms-3" v-if="user.groupId < 11">
                          <h4>Your Pins <i class="mdi mdi-pin"></i></h4>
                          <UserpinList /> 
                        </div>
                    </div>
                </div>
                <!-- end container -->
            </div>
            <!-- end content -->
            <div class="side-bar right-bar">
                <div class="col-sm-12">
                  <CalendarAssignDeal  v-if="homeRightBar == 'assignDeal'"/>
                  <ActivityItem v-if="homeRightBar == 'note'" :objectId="1" :isEditMode="true" :isAdd="true"/>
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import UserDealsList from '@/components/dashboard/UserDeals'
import UserTasksList from '@/components/dashboard/UserTasks'
import ActiveVotes from '@/components/dashboard/ActiveVotes'
import LegalList from '@/components/dashboard/LegalList'
import UserpinList from '@/components/UserpinList'
import OpsList from '@/components/dashboard/OpsList'
import CalendarList from '@/components/calmeeting/CalendarDashList'
import CalendarAssignDeal from '@/components/calmeeting/CalendarAssignDeal'
import ActivityItem from '@/components/ActivityItem'
import { mapGetters } from 'vuex'
import Clocks from '@/components/custom/OfficeClocks'

export default {
  components: {
    UserpinList,
    LegalList,
    OpsList,
    CalendarList,
    ActivityItem,
    CalendarAssignDeal,
    Clocks,
    UserDealsList,
    UserTasksList,
    ActiveVotes
  },
  mixins: [asyncDataStatus],
  created () {
    this.asyncDataStatus_fetched('Dashboard')
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    isICMember () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.INVESTMENT_COMMITTEE).length > 0
    },
    homeRightBar () {
      return this.$store.state.rb_homeRightBar
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
