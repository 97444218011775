<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portfolio-add'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Portfolio Details'" :icon="'mdi mdi-briefcase'" />
                        <div class="card-box">
                          <div class="row">
                            <div class="col-lg-6">
                              <h4 class="header-title m-t-0">Add a new Portfolio</h4>
                              <p class="text-muted font-14 m-b-20">
                                  Enter Portfolio information.
                              </p>

                              <form action="#" autocomplete="off">
                                  <div class="form-group">
                                      <label for="portfolioName">Name <span class="text-danger">*</span></label>
                                      <input type="text" v-model.trim="portfolio.portfolioName" @blur="$v.portfolio.portfolioName.$touch()" placeholder="Enter Name" class="form-control" id="portfolioName" :class="{ 'parsley-error': $v.portfolio.portfolioName.$error }">
                                      <template v-if="$v.portfolio.portfolioName.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.portfolio.portfolioName.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.portfolio.portfolioName.max" class="parsley-required">This value is too long</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                      <label for="clientId">Client <span class="text-danger">*</span></label>
                                      <span :class="{ 'parsley-error-radio': $v.portfolio.clientTypeId.$error }">
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="client1" value=1 v-model="portfolio.clientTypeId" name="client" class="custom-control-input">
                                          <label class="custom-control-label" for="client1">Advisory</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="client2" value=2 v-model="portfolio.clientTypeId" name="client" class="custom-control-input">
                                          <label class="custom-control-label" for="client2">Discretionary</label>
                                        </div>
                                      </span>
                                      <template v-if="$v.portfolio.clientTypeId.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group" v-if="portfolio.clientTypeId == 1">
                                      <label for="connectOrganizationId">Client (Connect) <span class="text-danger">*</span></label>
                                      <span :class="{ 'invalid': $v.portfolio.connectOrganizationId.$error }">
                                        <multiselect id="connectOrganizationId" v-model="connectOrgObj" :options="connectOrgList" label="organizationName" track-by="organizationId" :allow-empty="true" @input="connectOrgSelected"></multiselect>
                                      </span>
                                      <template v-if="$v.portfolio.connectOrganizationId.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.portfolio.connectOrganizationId.required" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                      <label for="CurrencyId">Currency <span class="text-danger">*</span></label>
                                      <span :class="{ 'invalid': $v.portfolio.currencyId.$error }">
                                        <multiselect id="CurrencyId" v-model="currencyObj" :options="allCurrencies" label="currencyName" track-by="currencyId" :allow-empty="true" @input="currencySelected"></multiselect>
                                      </span>
                                      <template v-if="$v.portfolio.currencyId.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.portfolio.currencyId.required" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group" v-show="portfolio.clientTypeId == 2">
                                      <label for="FirstCloseDate">First Close <span class="text-danger">*</span></label>
                                      <date-picker v-model="portfolio.firstCloseDate" @blur="$v.portfolio.firstCloseDate.$touch()" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.portfolio.firstCloseDate.$error }"></date-picker>
                                      <template v-if="$v.portfolio.firstCloseDate.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.portfolio.firstCloseDate.required" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group" v-show="portfolio.clientTypeId == 2">
                                      <label for="FinalCloseDate">Final Close <span class="text-danger">*</span></label>
                                      <date-picker ref="finalCloseDate" v-model="portfolio.finalCloseDate" @blur="$v.portfolio.finalCloseDate.$touch()" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.portfolio.finalCloseDate.$error }" :disabled="portfolio.finalCloseDateNa"></date-picker>
                                      <template v-if="$v.portfolio.finalCloseDate.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.portfolio.finalCloseDate.required" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                    <div class="checkbox-primary checkbox">
                                        <input id='finalCloseDateNa' @input="naChecked" type="checkbox" v-model="portfolio.finalCloseDateNa" />
                                        <label for='finalCloseDateNa'>NA</label>
                                    </div>
                                  </div>

                                  <div class="form-group" v-show="portfolio.clientTypeId == 2">
                                      <label for="InvEndDate">Investment Date End <span class="text-danger">*</span></label>
                                      <date-picker ref="invEndDate" v-model="portfolio.invEndDate" @blur="$v.portfolio.invEndDate.$touch()" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.portfolio.invEndDate.$error }" :disabled="portfolio.invEndDateNa"></date-picker>
                                      <template v-if="$v.portfolio.invEndDate.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.portfolio.invEndDate.required" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                      <div class="checkbox-primary checkbox">
                                        <input id='invEndDateNa' @input="naChecked" type="checkbox" v-model="portfolio.invEndDateNa" />
                                        <label for='invEndDateNa'>NA</label>
                                    </div>
                                  </div>

                                  <div class="form-group">
                                      <div class="checkbox checkbox-primary">
                                          <input id="checkbox6a" type="checkbox" v-model="addMore">
                                          <label for="checkbox6a">
                                              Add Another
                                          </label>
                                      </div>
                                  </div>

                                  <div class="form-group text-right m-b-0">
                                      <button type="reset" class="btn btn-secondary waves-effect m-l-5 mr-2" @click.prevent="reset">
                                          Reset
                                      </button>
                                      <button v-if="canAdd" class="btn btn-primary waves-effect waves-light" type="submit" @click.prevent="save">
                                          Add portfolio
                                      </button>
                                  </div>
                              </form>
                            </div>
                            <div class="col-lg-6">
                            </div>
                          </div>

                        </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import { mapActions, mapGetters } from 'vuex'
import { required, minLength, maxLength, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { PortfolioService } from '@/services/portfolio-service'
import { ConnectService } from '@/services/connect-service'

export default {
  components: {
    Multiselect,
    DatePicker
  },
  data () {
    return {
      addMore: false,
      portfolio: null,
      currencyObj: null,
      connectOrgObj: null,
      connectOrgList: []
    }
  },
  validations: {
    portfolio: {
      portfolioName: { required, min: minLength(2), max: maxLength(100) },
      clientTypeId: { required, min: minValue(1), max: maxValue(2) },
      connectOrganizationId: { required: requiredIf(function () { return parseInt(this.portfolio.clientTypeId) === 1 }) },
      currencyId: { required },
      firstCloseDate: { required: requiredIf(function () { return parseInt(this.portfolio.clientTypeId) === 2 }) },
      finalCloseDate: { required: requiredIf(function () { return parseInt(this.portfolio.clientTypeId) === 2 && !this.portfolio.finalCloseDateNa }) },
      invEndDate: { required: requiredIf(function () { return parseInt(this.portfolio.clientTypeId) === 2 && !this.portfolio.invEndDateNa }) }
    }
  },
  methods: {
    ...mapActions(['fetchAllCurrencies']),
    save () {
      this.$v.portfolio.$touch()
      if (!this.$v.portfolio.$error) {
        PortfolioService.add(this.portfolio).then(
          (response) => {
            this.$snotify.success('Portfolio Created.')
            if (!this.addMore) {
              setTimeout(() => {
                this.$router.push({name: 'PortfolioDetail', params: {id: response.data.portfolioId}})
              }, 500)
            } else {
              this.reset()
            }
          })
          .catch(function (error) {
            console.error('SERVER Error:', error)
            // this.$snotify.error('SERVER Errors.' + error)
          })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    reset () {
      this.portfolio = {}
      this.currencyObj = null
      this.$v.$reset()
    },
    currencySelected (value, id) {
      this.portfolio.currencyId = value ? value.currencyId : null
    },
    connectOrgSelected (value, id) {
      this.portfolio.connectOrganizationId = value ? value.organizationId : null
    },
    fetchConnectOrgs () {
      ConnectService.getOrgList()
      .then((response) => {
        this.connectOrgList = response.data
      })
    },
    naChecked (e) {
      if (!e.target.checked) return 
      // removing "Na" to find matching value that should be set to null
      const targetId = e.target.id
      const toNullify = targetId.slice(0,-2)
      this.portfolio[toNullify] = null
      const datePicker = this.$refs[toNullify]
      datePicker.clearDate()
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    canAdd () {
      // portfolio type determines types of admin
      const portfolioAdminType = parseInt(this.portfolio.clientTypeId) === 1 ? this.$userRoles.ADVISORY_PORTFOLIO_ADMINISTRATOR : this.$userRoles.DISCRETIONARY_PORTFOLIO_ADMINISTRATOR 
      return this.user.groupId < 11 && this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR || x.roleId === portfolioAdminType).length > 0 // Admin + OpsTeamAdministrator
    },
    symbol () {
      if (this.currencyObj != null) {
        return this.currencyObj.length > 0 ? this.currencyObj[0].symbol : this.currencyObj.symbol
      }
    }
  },
  mixins: [asyncDataStatus],
  created () {
    this.reset()
    this.fetchAllCurrencies()
    this.fetchConnectOrgs()
    this.asyncDataStatus_fetched('Add a New Portfolio')
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
