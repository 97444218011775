<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portal-contacts'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="contact">
                    <Toolbar :title="'Update Employee - '+contact.firstName + ' ' + contact.lastName + ' (' + contact.firmName + ')'" :icon="'mdi mdi-web'" 
                    :showToolbar="true" :inEditMode="false" :canEdit="canbeEdited" :isSaving="inSaving"
                    :isNew="false" :isDeleted="false"
                    :firmImported ="false" :isFund="false" :isUpdated="false"
                    :isNavAdmin="isNavAdmin" :isRestrictedUser="contact.isRestricted" @onUnrestrictUser="unrestrictUser"
                    :isEmailConfirmed="contact.emailConfirmed" @onActivateUser="activateUser"
                    :isAdminEdit="true"
                    @onEdit="EditForm" @onCancel="cancelForm" @onSave="saveForm" @onCancelAdminEdit="onCancelAdminEdit"
                     />
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-box">
                                <ul class="nav nav-tabs tabs-bordered">
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(1)" :class="{'active': activeTab == 1, 'tab-error': smTabHasError}">
                                            <i class="mdi mdi-information-outline"></i> Summary
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane fade" :class="{'active show': activeTab == 1}">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label for="firstName">First Name<span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="contact.firstName" @blur="$v.contact.firstName.$touch()" placeholder="First Name" class="form-control" id="firstName" :disabled="!inEditMode" :class="{ 'parsley-error': $v.contact.firstName.$error }">
                                                    <template v-if="$v.contact.firstName.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.contact.firstName.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.contact.firstName.min" class="parsley-required">This value is too short</li>
                                                        <li v-if="!$v.contact.firstName.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>

                                                <div class="form-group">
                                                    <label for="lastName">Last Name<span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="contact.lastName" placeholder="Last Name" class="form-control" id="lastName" :disabled="!inEditMode">
                                                </div>

                                                <div class="form-group">
                                                    <label for="jobTitle">Job Title<span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="contact.jobTitle" placeholder="Last Name" class="form-control" id="jobTitle" :disabled="!inEditMode">
                                                </div>

                                              <div class="form-group">
                                                <label for="email" class="col-form-label">Email Address<span class="text-danger">*</span></label>
                                                <div class="input-group">
                                                  <input type="text" class="form-control" id="email" placeholder="Email Address" v-model.trim="contact.email" :disabled="!inEditMode" />
                                                  <i v-if="contact.emailConfirmed" class="mdi mdi-check-circle verified-email">Verified</i>
                                                </div>
                                              </div>

                                              <div class="form-group">
                                                  <label for="Experience" class="col-form-label">Experience <span class="text-danger">*</span></label>
                                                  <textarea class="form-control" id="Experience" placeholder="Provide Experience" v-model.trim="contact.experience" rows="5" :disabled="!inEditMode" ></textarea>
                                              </div>

                                              <div class="form-group">
                                                  <label for="phone" class="col-form-label">Business Phone #</label>
                                                  <input type="text" class="form-control" id="phone" placeholder="Phone #" v-model="contact.phone" :disabled="!inEditMode" />
                                              </div>

                                              <div class="form-group">
                                                  <label for="mobile" class="col-form-label">Mobile #</label>
                                                  <input type="text" class="form-control" id="mobile" placeholder="Mobile #" v-model="contact.mobile" :disabled="!inEditMode" />
                                              </div>

                                              <div class="form-group form-inline">
                                                <label class="col-form-label mr-1">Restricted User</label>
                                                <toggle-button v-model="contact.isRestricted" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" :disabled="!inEditMode" />
                                              </div>

                                                <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                                                <div :class="{'collapse': !showLogs }">
                                                    <div class="form-group">
                                                        <label>Created On</label>
                                                        <AppDate :date="contact.createdOn"/>
                                                    </div>
                                                    <!-- <div class="form-group">
                                                        <label>Created By</label>
                                                        <AppUser :userId="contact.createdBy" />
                                                    </div> -->
                                                    <div class="form-group" v-if="contact.updatedOn">
                                                        <label>Modified On</label>
                                                        <AppDate :date="contact.updatedOn" :emptyIfNull="true"/>
                                                    </div>
                                                    <!-- <div class="form-group">
                                                        <label>Modified By</label>
                                                        <AppUser :userId="contact.updatedBy" :emptyIfNull="true"/>
                                                    </div> -->
                                                </div>

                                            </div>

                                            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> 
                        <!-- end col -->
                    </div>
                </div>
            </div>
        </div>

        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <ActivityItem @onUpdate="onActivityUpdate" v-if="rightbarItem == 'activity'" :objectId="9" :isEditMode="sideInEditMode" :isAdd="addSidebar"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import rightbar from '@/mixins/rightbar'
import { PortalContactService } from '@/services/portal-service'
import { ActivityService } from '@/services/activity-service'
import ActivityList from '@/components/ActivityList'
import ActivityItem from '@/components/ActivityItem'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Toolbar from '@/components/portal/PortalToolbar'

export default {
  components: {
    ActivityList,
    ActivityItem,
    Toolbar
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  mixins: [rightbar, asyncDataStatus],
  data () {
    return {
      activeTab: 1,
      activeSMTab: 1,
      showLogs: false,
      inEditMode: true,
      inSaving: false,
      sideInEditMode: false,
      contact: {contactName: ''},
      rightbarItem: null,
      addSidebar: null,
      firmTypeObj: null,
      ownershipIdObj: null,
      activities: null
    }
  },
  validations: {
    contact: {
      firstName: { required, max: maxLength(300) }
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers', 'fetchActivity']),
    showTab (tabId) {
      this.activeTab = tabId
    },
    showSMTab (tabId) {
      this.activeSMTab = tabId
    },
    sidebarAction (item, isAdd, isOpen, isEdit) {
      this.addSidebar = isAdd
      this.sideInEditMode = isEdit || isAdd
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    saveForm () {
      this.$v.contact.$touch()      
      if (!this.$v.contact.$error) {
        this.inSaving = true
         PortalContactService.save(this.contact.userId, this.contact).then(
           (response) => {
             if (response.status !== 200) {
               this.$snotify.error('Error!')
             } else {
              this.$router.push({name: 'PortalContactsDetail', params: {id: this.contact.userId}})
               this.$snotify.success('Contacts saved.')
             }
             this.inSaving = false
           }
         ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    EditForm () {
      this.inEditMode = true
      this.addSidebar = false
    },
    activateUser () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to activate this user?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.inSaving = true
                PortalContactService.activate(this.contact.userId)
                .then((response) => {
                  if (response.status !== 200) {
                    this.$snotify.error('Error!')
                  } else {
                    
                    this.$snotify.success('User activated')
                    this.contact.emailConfirmed = true
                    this.inSaving = false
                  }
                })                
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})   
    },
     unrestrictUser () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to remove restriction for this user?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.inSaving = true
                PortalContactService.unrestrictUserByAdmin(this.contact.userId)
                .then((response) => {
                  if (response.status !== 200) {
                    this.$snotify.error('Error!')
                  } else {
                    this.$snotify.info('An email notification will be sent shortly.')
                    this.$snotify.success('User Updated')
                    this.contact.isRestricted = false
                    this.inSaving = false
                  }
                })                
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})      
    },
    cancelForm () {
      if (!this.$v.contact.$anyDirty) {
        this.cancelFormOper()
        return
      }

      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to cancel?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.cancelFormOper()
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    cancelFormOper () {
      this.inEditMode = false
      this.$v.contact.$reset()
      this.getDetails()
      if (this.addSidebar) {
        this.hideRightbar()
        this.addSidebar = false
      }
    },
    getDetails () {
      PortalContactService.get(this.id)
      .then((response) => {
        this.contact = response.data
        //console.log(this.contact)
      }).then(() => {
        this.fetchActivities()
      }).then(() => {
        this.asyncDataStatus_fetched('Portal Contact Details')
      }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    getTables () {
      this.fetchAllUsers()
    },
    fetchActivities () {
      ActivityService.getPortalContactActivities(this.contact.userId).then(
        (response) => {
          if (response.data.length > 0) {
            this.activities = response.data
          } else {
            this.activities = null
          }
        }
      )
    },
    showActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true)
    },
    editActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true, true)
    },
    clickAddActivity () {
      this.$store.commit('setNewActivity', this.contact.userId)
      this.sidebarAction('activity', true, true, true)
    },
    onActivityUpdate () {
      this.fetchActivities()
      this.sidebarAction('', false, false)
    },
    onCancelAdminEdit() {
      this.$router.push({name: 'PortalContactsDetail', params: {id: this.contact.userId}})
    },
  },
  created () {
    this.addSidebar = false
    this.hideRightbar()
    this.getTables()
    this.getDetails()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allUsers () {
      return this.$store.state.allUsers
    },
    canbeEdited () {
      return this.user.groupId < 11
    },
    smTabHasError () {
      return this.$v.contact.firstName.$error
    },
    gpTabHasError () {
      return this.$v.firm.overview.$error || this.$v.firm.website.$error
    },
    isNavAdmin () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.NAVIGATOR_ADMINISTRATOR).length > 0
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
