<template>
  <tbody>
    <tr v-if="firms === null">
      <td class="text-center">No Firm to show</td>
    </tr>
    <tr v-for="firm in firms"
        :key="firm.firmId">
        <td>
          <router-link :to="{name: 'FirmsDetail', params: {id: firm.firmId}}" target="_blank">
              {{firm.firmName}}
          </router-link>
        </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    firms: {
      type: Array,
      default: null
    }
  }
}
</script>
