<template>
  <div id="wrapper" class="forced">
    <vue-snotify></vue-snotify>
    <router-view :key="$route.path" v-show="showPage" @ready="pageReady"/>
    <div class="d-flex align-items-center justify-content-center" v-if="!showPage" >
        <img :src="require('/static/img/loading.gif')" alt="loading" height="50" class="text-center">
    </div>

  </div>
</template>

<script>
import NProgress from 'nprogress'

export default {
  name: 'wrapper',
  data () {
    return {
      showPage: false
    }
  },
  created () {
    NProgress.configure({
      speed: 500,
      showSpinner: false
    })
    this.$router.beforeEach((to, from, next) => {
      this.showPage = false
      NProgress.start()
      next()
    })
  },
  methods: {
    pageReady () {
      this.showPage = true
      NProgress.done()
    }
  }
}
</script>

<style>
  @import '../static/css/bootstrap.min.css';
  @import '../static/css/icons.css';
  @import '../static/css/style_dark.css';
  @import '../static/css/umbrella.css';
  @import '~nprogress/nprogress.css';
</style>
