<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'funds-add'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Add Deal'" :icon="'mdi mdi-square-inc-cash'"/>
                        <div class="card-box">
                          <div class="row">
                            <div class="col-lg-6">
                              <h4 class="header-title m-t-0">Add a new Deal</h4>
                              <p class="text-muted font-14 m-b-20">
                                  Enter deal information.
                              </p>

                              <form action="#" autocomplete="off">
                                  <div class="form-group">
                                      <label for="fundName">Name <span class="text-danger">*</span></label>
                                      <input type="text" v-model.trim="$v.fund.fundName.$model" @blur="$v.fund.fundName.$touch()" parsley-trigger="change" placeholder="Enter Name" class="form-control" id="fundName" :class="{ 'parsley-error': $v.fund.fundName.$error || nameHasSlash }">
                                      <template v-if="$v.fund.fundName.$error || nameHasSlash">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.fundName.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.fund.fundName.min" class="parsley-required">This value is too short</li>
                                          <li v-if="!$v.fund.fundName.max" class="parsley-required">This value is too long</li>
                                          <li v-if="nameHasSlash" class="parsley-required">'\' or '/' are not valid</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                      <label for="fundTypeId">Type <span class="text-danger">*</span></label>
                                      <span :class="{ 'parsley-error-radio': $v.fund.fundTypeId.$error }">
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="type1" value=1 v-model.number="fund.fundTypeId" name="type" class="custom-control-input" @input="changeType">
                                          <label class="custom-control-label" for="type1">Primary</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="type2" value=2 v-model.number="fund.fundTypeId" name="type" class="custom-control-input" @input="changeType">
                                          <label class="custom-control-label" for="type2">Secondary</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="type3" value=3 v-model.number="fund.fundTypeId" name="type" class="custom-control-input" @input="changeType">
                                          <label class="custom-control-label" for="type3">Co-Investment</label>
                                        </div>
                                      </span>
                                      <template v-if="$v.fund.fundTypeId.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                      <label for="generalPartnerId">General Partner <span class="text-danger">*</span></label>
                                      <span :class="{ 'invalid': $v.fund.generalPartnerId.$error }">
                                        <multiselect id="generalPartnerId" v-model="generalPartnerObj" :options="searchFirms" label="firmName" track-by="firmName" :allow-empty="false" placeholder="Type to Search"
                                        @input="gpFirmSelected" @search-change="asyncSearchFirm" :loading="isSearchingList" :options-limit="50"></multiselect>
                                      </span>
                                      <template v-if="$v.fund.generalPartnerId.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.generalPartnerId.required" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                    <label>Open Ended</label>
                                    <toggle-button v-model="fund.isOpenEnded" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" @input="openEnded"/>
                                  </div>

                                  <div class="form-group">
                                      <label for="expectedstartdate">Expected Start Date <span v-show="!fund.isOpenEnded" class="text-danger">*</span></label>
                                      <date-picker id="expectedstartdate" v-model="fund.expectedStartDate" @blur="$v.fund.expectedStartDate.$touch()" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.expectedStartDate.$error }" @input="onexpectedStartDate"></date-picker>
                                      <template v-if="$v.fund.expectedStartDate.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.expectedStartDate.required" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                      <label for="expectedclosedate">Expected Close Date <span v-show="!fund.isOpenEnded" class="text-danger">*</span></label>
                                      <date-picker id="expectedclosedate" v-model="fund.expectedCloseDate" @blur="$v.fund.expectedCloseDate.$touch()" :disabled="fund.isOpenEnded" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.fund.expectedCloseDate.$error }"></date-picker>
                                      <template v-if="$v.fund.expectedCloseDate.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.expectedCloseDate.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.fund.expectedCloseDate.validCloseDate" class="parsley-required">This value is not valid.</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                    <span :class="{ 'invalid': $v.fund.sectorId.$error }">
                                    <label for="tree-strategy">Strategy<span class="text-danger">*</span></label>
                                    <Treeselect id="tree-strategy" placeholder="Strategy" v-model="strategyObj"
                                      :options="fullStrategiesTree" :disable-branch-nodes="true" :showCount="true" @input="strategySelected">
                                      <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
                                    </Treeselect>
                                    </span>
                                    <template v-if="$v.fund.sectorId.$error">
                                      <ul class="parsley-errors-list filled">
                                        <li v-if="!$v.fund.sectorId.required" class="parsley-required">This value is required</li>
                                      </ul>
                                    </template>
                                  </div>

                                  <span v-if="fund.fundTypeId != 3">
                                    <div class="form-group">
                                      <label for="sectorDescription">Sector Description <span class="text-danger">*</span></label>
                                      <input type="text" v-model.trim="fund.sectorDescription" @blur="$v.fund.sectorDescription.$touch()" required placeholder="Enter Sector Description" class="form-control" id="sectorDescription" :class="{ 'parsley-error': $v.fund.sectorDescription.$error }">
                                      <template v-if="$v.fund.sectorDescription.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.sectorDescription.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.fund.sectorDescription.max" class="parsley-required">This value is too long</li>
                                        </ul>
                                      </template>
                                    </div>
                                  </span>

                                  <div class="form-group">
                                      <label for="geographicFocusId">Geographic Focus <span class="text-danger">*</span></label>
                                      <span :class="{ 'invalid': $v.fund.geographicFocusId.$error }">
                                        <multiselect id="geographicFocusId" v-model="geographicFocusObj" :options="allGeoFocuses" label="geographicName" track-by="geographicId" :allow-empty="false" @input="geoFocusSelected"></multiselect>
                                      </span>
                                      <template v-if="$v.fund.geographicFocusId.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.geographicFocusId.required" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group" v-if="fund.fundTypeId != 3">
                                      <label for="targetSize">Target Size (M) <span class="text-danger">*</span></label>
                                      <input type="text" v-model.number="fund.targetSize" required @blur="$v.fund.targetSize.$touch()" placeholder="Enter Target Size" class="form-control" id="targetSize" :class="{ 'parsley-error': $v.fund.targetSize.$error }" :disabled="fund.targetSizeNa">
                                      <template v-if="$v.fund.targetSize.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.targetSize.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.fund.targetSize.min || !$v.fund.targetSize.max" class="parsley-required">This value is not valid.</li>
                                        </ul>
                                      </template>
                                      <div class="checkbox-primary checkbox" v-show="fund.targetSize == 0 || fund.targetSize == null">
                                          <input id='targetSizeNa' type="checkbox" v-model="fund.targetSizeNa" />
                                          <label for='targetSizeNa'>NA</label>
                                      </div>
                                  </div>

                                  <div class="form-group" v-if="fund.fundTypeId == 3">
                                      <label for="amountOffered">Amount Offered (M) <span class="text-danger">*</span></label>
                                      <input type="text" v-model.number="fund.amountOffered" required @blur="$v.fund.amountOffered.$touch()" placeholder="Enter Amount Offered" class="form-control" id="amountOffered" :class="{ 'parsley-error': $v.fund.amountOffered.$error }">
                                      <template v-if="$v.fund.amountOffered.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.amountOffered.required" class="parsley-required">This value is required</li>
                                          <li v-if="!$v.fund.amountOffered.min || !$v.fund.amountOffered.max" class="parsley-required">This value is not valid.</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                      <label for="dealCurrencyId">Deal Currency <span class="text-danger">*</span></label>
                                      <span :class="{ 'invalid': $v.fund.dealCurrencyId.$error }">
                                        <multiselect id="dealCurrencyId" v-model="dealCurrencyObj" :options="allCurrencies" label="currencyName" track-by="currencyId" :allow-empty="true" @input="dealCurrencySelected"></multiselect>
                                      </span>
                                      <template v-if="$v.fund.dealCurrencyId.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="!$v.fund.dealCurrencyId.required" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                  </div>

                                  <div class="form-group">
                                    <label>Social Responsible, ESG and/or Impact Focus</label>
                                    <toggle-button v-model="fund.isSocialResponsible" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}" />
                                    <div class='mt-2' v-if="fund.isSocialResponsible">
                                      <span :class="{ 'parsley-error-radio': $v.fund.esgType.$error }">
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="social-check" value=1 v-model="fund.esgType" name="Social" class="custom-control-input" >
                                          <label class="custom-control-label" for="social-check">Social</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="env-check" value=2 v-model="fund.esgType" name="Environmental" class="custom-control-input" >
                                          <label class="custom-control-label" for="env-check">Environmental</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="SEG-check" value=3 v-model="fund.esgType" name="SEG" class="custom-control-input" >
                                          <label class="custom-control-label" for="SEG-check">Social, Environmental and/or Governance</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="other-check" value=4 v-model="fund.esgType" name="Other" class="custom-control-input" >
                                          <label class="custom-control-label" for="other-check">Other</label>
                                        </div>
                                        <div class="input-group mt-2" v-if='fund.esgType == 4'>
                                          <textarea class="form-control" id="other-text" placeholder="Explain" maxlength=250 rows="1" v-model="fund.esgTypeOther" @blur="$v.fund.esgTypeOther.$touch()" :class="{ 'parsley-error': $v.fund.esgTypeOther.$error }"></textarea>
                                        </div>
                                      </span>
                                      <template v-if="$v.fund.esgTypeOther.$error">
                                        <ul class="parsley-errors-list filled">
                                          <li v-if="$v.fund.esgTypeOther.$error" class="parsley-required">This value is required</li>
                                        </ul>
                                      </template>
                                    </div>
                                  </div>
                                                    
                                  <div class="form-group">
                                      <div class="checkbox checkbox-primary">
                                          <input id="checkbox6a" type="checkbox" v-model="addMore">
                                          <label for="checkbox6a">
                                              Add Another
                                          </label>
                                      </div>
                                  </div>

                                  <div class="form-group text-right m-b-0">
                                      <button type="reset" class="btn btn-secondary waves-effect m-l-5 mr-2" @click.prevent="reset">
                                          Reset
                                      </button>
                                      <button class="btn btn-primary waves-effect waves-light" type="submit" @click.prevent="save" v-if="user.groupId < 11" :disabled="isLoading">
                                        <span v-if="!isLoading">Add Fund</span>
                                        <span v-else>Saving <i class="fa fa-spinner fa-spin"></i></span>
                                      </button>

                                  </div>
                              </form>
                            </div>
                          </div>

                        </div>
                </div>
            </div>
        </div>
        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <SimilarList :list="SimilarFundList" @onConfirm="confirmedSave"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import { mapActions, mapGetters } from 'vuex'
import { required, decimal, minLength, maxLength, minValue, maxValue, requiredIf, helpers } from 'vuelidate/lib/validators'
import { FundService } from '@/services/fund-service'
import moment from 'moment'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import SimilarList from '@/components/SimilarList'
import rightbar from '@/mixins/rightbar'
import Treeselect from '@riophae/vue-treeselect'

const validateIf12 = (prop, validator) =>
  helpers.withParams({ type: 'validateIf12', prop }, function (value, parentVm) {
    const v = helpers.ref(prop, this, parentVm)
    return parseInt(v) === 1 ? validator(value) : true
  })
const validCloseDate = (sdate, edate, isOpen) => {
  if (isOpen) {
    return true
  }
  if (sdate === null || sdate === undefined || edate === null || sdate === undefined) {
    return false
  }
  const s = moment(sdate)
  const e = moment(edate)
  return s < e
}

export default {
  components: {
    Multiselect,
    DatePicker,
    SimilarList,
    Treeselect
  },
  data () {
    return {
      addMore: false,
      fund: {fundGroupId: 1, fundName: '', fundTypeId: null, expectedStartDate: null, sectorId: 0},
      fundTypeObj: null,
      generalPartnerObj: null,
      strategyObj: null,
      geographicFocusObj: null,
      dealCurrencyObj: null,
      searchFirms: [],
      isSearchingList: false,
      SimilarFundList: [],
      saveConfirmed: false,
      isLoading: false
    }
  },
  validations: {
    fund: {
      fundName: { required, min: minLength(3), max: maxLength(100) },
      fundTypeId: { required, min: minValue(1), max: maxValue(3) },
      generalPartnerId: { required, min: minValue(1) },
      expectedStartDate: { required: requiredIf(function () { return !this.fund.isOpenEnded }) },
      expectedCloseDate: { required: requiredIf(function () { return !this.fund.isOpenEnded }), validCloseDate (value) { return validCloseDate(this.fund.expectedStartDate, value, this.fund.isOpenEnded) } },
      sectorId: { required, min: minValue(1) },
      targetSize: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) < 3 && !this.fund.targetSizeNa }), decimal, min: validateIf12('fundTypeId', minValue(1)), max: maxValue(1000000) },
      amountOffered: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) === 3 }), decimal, min: validateIf12('fundTypeId', minValue(1)), max: maxValue(1000000) },
      sectorDescription: { required: requiredIf(function () { return parseInt(this.fund.fundTypeId) !== 3 }), max: maxLength(300) },
      geographicFocusId: { required },
      dealCurrencyId: { required },
      esgType: { required: requiredIf(function () { return this.fund.isSocialResponsible }) },
      esgTypeOther: { required: requiredIf(function () { return this.fund.isSocialResponsible && parseInt(this.fund.esgType) === 4 }) }
    }
  },
  mixins: [rightbar, asyncDataStatus],
  methods: {
    ...mapActions(['searchAllFirms', 'fetchAllFundtypes', 'fetchAllStrategies', 'fetchAllGeoFocus', 'fetchAllCurrencies', 'fetchAllTreeStrategies', 'fetchAllSectors', 'fetchAllSubSectors']),
    gpFirmSelected () {
      this.$v.fund.generalPartnerId.$reset()
      this.$v.fund.generalPartnerId.$touch()
      this.fund.generalPartnerId = this.generalPartnerObj ? this.generalPartnerObj.firmId : null
    },
    strategySelected (value, id) {
      this.$v.fund.sectorId.$touch()
      if (value) {
        if (value[0] === 'C') {
          this.fund.sectorId = parseInt(value.slice(1))
        } else if (value[0] === 'D') {
          this.fund.sectorId = this.allSubsectors.filter(t => t.subSectorId === parseInt(value.slice(1)))[0].sectorId
          this.fund.subSectorId = parseInt(value.slice(1))
        }
      }
      let sector = this.allSectors.filter(sector => sector.sectorId === this.fund.sectorId)[0]
      this.fund.fundGroupId = this.allTreeStrategies.filter(strategy => strategy.strategyId === sector.strategyId)[0].groupId
    },
    geoFocusSelected () {
      // this.$v.fund.geographicFocusId.$reset()
      this.fund.geographicFocusId = this.geographicFocusObj ? this.geographicFocusObj.geographicId : null
      this.$v.fund.geographicFocusId.$touch()
    },
    dealCurrencySelected () {
      // this.$v.fund.dealCurrencyId.$reset()
      this.fund.dealCurrencyId = this.dealCurrencyObj ? this.dealCurrencyObj.currencyId : null
      this.$v.fund.dealCurrencyId.$touch()
    },
    confirmedSave () {
      this.saveConfirmed = true
      this.save()
    },
    save () {
      this.sidebarAction(false)
      this.$v.fund.$reset()
      this.$v.fund.$touch()
      if (!this.$v.fund.$error && !this.nameHasSlash) {
        // if is Open-Ended
        if (this.fund.isOpenEnded) {
          this.fund.expectedCloseDate = null
        } else {
          this.fund.expectedCloseDate = moment(this.fund.expectedCloseDate).format('MM/DD/YYYY')
        }

        if (this.fund.expectedStartDate !== null) {
          this.fund.expectedStartDate = moment(this.fund.expectedStartDate).format('MM/DD/YYYY')
        } else {
          this.fund.expectedStartDate = null
        }

        // check similar names
        FundService.doublecheckName(this.fund.fundName)
        .then(
          (response) => {
            if (response.data.length > 0) {
              this.SimilarFundList = response.data
              const isExact = this.SimilarFundList.filter(x => x.fundName.toLowerCase() === this.fund.fundName.toLowerCase()).length > 0
              if (isExact) {
                this.$snotify.warning('"' + this.fund.fundName + '" already exists')
                return
              }
              // response.data
              // .forEach(item => {
              //   item = {...item, '_name': item.fundName}
              //   this.SimilarFundList.push(item)
              // })
              if (!this.saveConfirmed) {
                this.sidebarAction(true)
              }
            } else {
              this.saveConfirmed = true // no need
            }

            if (this.saveConfirmed) {
              this.isLoading = true
              FundService.add(this.fund)
              .then(
                (response) => {
                  this.$snotify.success('Fund created.')
                  if (!this.addMore) {
                    setTimeout(() => {
                      this.$router.push({name: 'FundsDetail', params: {id: response.data.fundId}})
                    }, 500)
                  } else {
                    this.isLoading = true
                    this.reset()
                  }
                })
                .catch(error => this.$snotify.error('Server Error! ' + error.message)) // add
            }
          }
        )
        .catch(error => this.$snotify.error('Server Error! ' + error.message)) // doublecheckName
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    reset () {
      this.fund = {
        fundName: '',
        fundTypeId: 0,
        generalPartnerId: 0,
        expectedStartDate: '',
        expectedCloseDate: '',
        isOpenEnded: false,
        sectorId: 0,
        subSectorId: null,
        sectorDescription: '',
        targetSize: 0,
        amountOffered: 0,
        geographicFocusId: 0,
        dealCurrencyId: 0
      }

      this.saveConfirmed = false
      this.fundTypeObj = null
      this.strategyObj = null
      this.generalPartnerObj = null
      this.geographicFocusObj = null
      this.dealCurrencyObj = null
      this.$v.$reset()
      this.sidebarAction(false)
    },
    asyncSearchFirm (query) {
      this.isSearchingList = true
      this.searchAllFirms({query}).then((result) => {
        this.searchFirms = result
        this.isSearchingList = false
      })
    },
    openEnded () {
      if (this.fund.isOpenEnded) {
        this.fund.expectedCloseDate = null
      }
    },
    sidebarAction (isOpen) {
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    changeType () {
      this.$v.fund.$reset()
    },
    onexpectedStartDate () {
      if (!this.fund.isOpenEnded) {
        this.fund.expectedCloseDate = moment(this.fund.expectedStartDate).add(1, 'year').format('MM/DD/YYYY')
      }
    }
  },
  created () {
    this.sidebarAction(false)
    this.fetchAllFundtypes()
    this.fetchAllStrategies()
    this.fetchAllGeoFocus()
    this.fetchAllCurrencies()
    this.fetchAllTreeStrategies()
    this.fetchAllSectors()
    this.fetchAllSubSectors()
    this.asyncDataStatus_fetched('Add a New Deal')
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allTypes () {
      return this.$store.state.allFundtypes
    },
    allStrategies () {
      return this.$store.state.allStrategies
    },
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    allTreeStrategies () {
      return this.$store.state.allTreeStrategies
    },
    allSectors () {
      return this.$store.state.allSectors
    },
    allSubsectors () {
      return this.$store.state.allSubsectors
    },
    nameHasSlash () {
      return this.fund.fundName.indexOf('/') + this.fund.fundName.indexOf('\\') >= 0
    },
    fullStrategiesTree () {
      let groups = [{
        id: '1A',
        label: 'Private Markets',
        children: []
      },
      {
        id: '2A',
        label: 'Marketable Alternatives',
        children: []
      }]
      this.allTreeStrategies.forEach(obj => {
        groups[obj.groupId - 1].children = [...groups[obj.groupId - 1].children, {id: `B${obj.strategyId}`, label: obj.strategyName, children: []}]
      })
      groups.forEach(group => {
        group.children.forEach(strategy => {
          this.allSectors.forEach(item => {
            item.strategyId === parseInt(strategy.id.slice(1)) ? strategy.children = [...strategy.children, {id: `C${item.sectorId}`, label: item.sectorName, children: []}] : strategy.children = strategy.children
          })
        })
      })
      groups.forEach(group => {
        group.children.forEach(strategy => {
          strategy.children.forEach(sector => {
            this.allSubsectors.forEach(item => {
              if (item.sectorId === parseInt(sector.id.slice(1))) {
                sector.children = [...sector.children, {id: `D${item.subSectorId}`, label: item.subSectorName, customLabel: `${group.label} > ${strategy.label} > ${sector.label} > ${item.subSectorName}`}]
              }
            })
          })
        })
      })
      groups.forEach(group => {
        group.children.forEach(strategy => {
          strategy.children = strategy.children.sort((a, b) => b.children.length - a.children.length).map(sector => {
            if (sector.children.length === 0) {
              return { id: sector.id, label: sector.label, customLabel: `${group.label} > ${strategy.label} > ${sector.label}` }
            } else {
              return sector
            }
          })
        })
      })
      groups.forEach(group => {
        group.children = group.children.sort((a, b) => b.children.length - a.children.length).map(strategy => {
          if (strategy.children.length === 1) {
            return { id: strategy.children[0].id, label: strategy.label, customLabel: `${group.label} > ${strategy.label}` }
          } else {
            return strategy
          }
        })
      })
      return groups
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
