<template>
  <tbody>
    <tr v-if="employees === null">
      <td colspan="6" class="text-center">No Data to show</td>
    </tr>
    <tr v-for="employe in employees"
        :key="employe.firmContactId">
        <td>
         <i v-if="employe.isMain" class="mdi mdi-check-circle text-success" v-tooltip="'Primary Contact'"></i>
          <router-link :to="{name: 'ContactsDetail', params: {id: employe.contactId}}" target="_blank">
            {{employe.fullName}}
          </router-link>
           <button v-if="!employe.isMain" class="btn btn-success btn-sm btn-mini btn-icon" v-tooltip="'Set Primary Contact'" @click.prevent="onSave(employe.firmContactId, employe)">
          <i class="mdi mdi-account-star" ></i>
          </button>
        </td>
        <td>{{ employe.jobTitle}}</td>
        <td>{{ employe.phoneNo}}</td>
        <td>{{ employe.email}}</td>       
        <td v-if="user.groupId < 11">
          <button class="btn btn-danger btn-sm btn-mini btn-icon " @click.prevent="onDelete(employe.firmContactId)"><i class="mdi mdi-delete"></i></button>
        </td>
    </tr>
    <tr>
      <td colspan="6">
        <button v-if="!isEditMode && user.groupId < 11" class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addNew"> <i class="mdi mdi-tag-plus"></i> New</button>
      </td>
    </tr>
  </tbody>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    employees: {
      type: Array,
      default: null
    },
    isEditMode: {
      required: true,
      type: Boolean
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['deleteFirmContact', 'updateFirmContact']),
    addNew () {
      this.hideRightbar()
      this.$emit('onClickNew')
    },
    select (id) {
      this.$emit('onSelect', id)
    },
    onSave (id, contactObject) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to set primary contact to this person?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                contactObject.isMain = true
                this.updateFirmContact({firmContactId: id, firmContact: contactObject})
                .then((response) => {
                  this.$snotify.success('Contact updated.')
                  this.$emit('onUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    onDelete (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deleteFirmContact({firmContactId: id})
                .then((response) => {
                  this.$snotify.success('Contact removed.')
                  this.$emit('onUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    })
  }
}
</script>
