<template>
  <div>
    <Topbar/>
    <Sidebar :activeItem="'calmeeting-list'"/>

    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid" v-if="meeting">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                  <h4 class="page-title"><i class="mdi mdi-timetable"></i> Meeting Details </h4>
                  <div class="clearfix"></div>
              </div>
              <div class="mb-3">
                <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="!meeting.isCancelled && meeting.avgScore === 0" @click="onCancel"> <i class="mdi mdi-cancel m-r-5"></i> <span>Cancel </span> </button>
                <button class="btn btn-primary btn-custom waves-effect w-md waves-light m-b-5" v-show="!meeting.isCancelled && meeting.avgScore === 0 && meeting.attendees.filter(x => x.userId == user.userId).length > 0 && meeting.scores.filter(x => x.userId === user.userId).length == 0" @click="onJoin"> <i class="mdi mdi-human-greeting m-r-5"></i> 
                  <span v-if="!isJoing">Join </span>
                  <span v-else><i class="fa fa-spinner fa-spin"></i></span>
                </button>
                <h3 class="text-danger" v-if="meeting.isCancelled">Canceled</h3>
              </div>

            </div>
          </div>
          <div class="card-box">
            <form action="#" autocomplete="off" @submit.prevent="save">
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label>Title</label>
                      <label class="form-control text-muted">{{meeting.title}}</label>
                    </div>

                    <div class="form-group">
                      <label>Start Date/Time</label>
                      <label class="form-control text-muted" v-tooltip="formatedDateAgo(meeting.startTime)">{{formatedDate(meeting.startTime)}}</label>
                    </div>
                    
                    <!-- <div class="form-group">
                      <label>End Date/Time</label>
                      <label class="form-control text-muted" v-tooltip="formatedDateAgo(meeting.endTime)">{{formatedDate(meeting.endTime)}}</label>
                    </div> -->

                    <div class="form-group">
                      <label>Location</label>
                      <label class="form-control text-muted">{{location}}</label>
                    </div>

                    <div class="form-group">
                      <label>Organizer</label>
                      <label class="form-control text-muted">{{meeting.organizer}}</label>
                    </div>

                    <div class="form-group">
                      <label>Associated Fund</label>
                        <button v-if="meeting.fundName" class="btn btn-success btn-sm btn-mini btn-icon" v-tooltip="'Update'" @click.prevent="assignDeal"><i class="mdi mdi-lead-pencil"></i></button>
                      
                      <label class="form-control text-primary" v-if="meeting.fundName">{{meeting.fundName}}</label>
                      <div v-else-if="!meeting.isCancelled">
                        <a class="btn btn-primary" href="javascript:void(0)" @click.prevent="assignDeal">Assign a Deal</a>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Purpose</label>
                      <label v-if="meeting.typeName" class="form-control text-primary">{{meeting.typeName}}</label>
                    </div>

                    <div class="form-group">
                      <label>Invitations Sent</label>
                      <textarea readonly="readonly" class="form-control" rows="5" v-model="formatedInvites" placeholder="Invitation Not Found"></textarea>
                    </div>
                </div>

                <div class="col-lg-6 col-xs-12">
                  <span v-if="meeting.avgScore > 0">
                    Highlights
                    <toggle-button v-model="showComments" :labels="{checked: 'Words', unchecked: 'Comments'}" :width="80" />
                  </span>
                  <div class="card card-body mb-3" v-if="meeting.avgScore > 0">
                      <wordcloud
                      v-if="!showComments"
                      :data="commentComments"
                      nameKey="name"
                      valueKey="value"
                      :color="myColors"
                      :showTooltip="false"
                      :wordClick="wordClickHandler">
                      </wordcloud>
                      <wordcloud
                      v-if="showComments"
                      :data="commentWords"
                      nameKey="name"
                      valueKey="value"
                      :color="myColors"
                      :showTooltip="false"
                      :wordClick="wordClickHandler">
                      </wordcloud>
                  </div>

                  <div class="form-group" v-if="meeting.avgScore > 0">
                    <label>Avg. Score</label>
                    <div>
                      <i class="mdi mdi-numeric-1-box avg-score text-danger" v-if="meeting.avgScore < 2"></i>
                      <i class="mdi mdi-numeric-2-box avg-score text-default" v-if="meeting.avgScore >= 2 && meeting.avgScore < 3"></i>
                      <i class="mdi mdi-numeric-3-box avg-score text-warning" v-if="meeting.avgScore >= 3 && meeting.avgScore < 4"></i>
                      <i class="mdi mdi-numeric-4-box avg-score text-success" v-if="meeting.avgScore >= 4 && meeting.avgScore < 5"></i>
                      <i class="mdi mdi-numeric-5-box avg-score text-primary" v-if="meeting.avgScore >= 5"></i>
                      <i class="avg-score-dot" :class="avgScoreColorClass">.</i>
                      <i class="mdi mdi-numeric-0-box avg-score" :class="avgScoreColorClass" v-if="avgScoreDecimal === 0"></i>
                      <i class="mdi mdi-numeric-1-box avg-score" :class="avgScoreColorClass" v-if="avgScoreDecimal === 1"></i>
                      <i class="mdi mdi-numeric-2-box avg-score" :class="avgScoreColorClass" v-if="avgScoreDecimal === 2"></i>
                      <i class="mdi mdi-numeric-3-box avg-score" :class="avgScoreColorClass" v-if="avgScoreDecimal === 3"></i>
                      <i class="mdi mdi-numeric-4-box avg-score" :class="avgScoreColorClass" v-if="avgScoreDecimal === 4"></i>
                      <i class="mdi mdi-numeric-5-box avg-score" :class="avgScoreColorClass" v-if="avgScoreDecimal === 5"></i>
                      <i class="mdi mdi-numeric-6-box avg-score" :class="avgScoreColorClass" v-if="avgScoreDecimal === 6"></i>
                      <i class="mdi mdi-numeric-7-box avg-score" :class="avgScoreColorClass" v-if="avgScoreDecimal === 7"></i>
                      <i class="mdi mdi-numeric-8-box avg-score" :class="avgScoreColorClass" v-if="avgScoreDecimal === 8"></i>
                      <i class="mdi mdi-numeric-9-box avg-score" :class="avgScoreColorClass" v-if="avgScoreDecimal === 9"></i>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Attendees</label>
                    <toggle-button v-model="showAll" :labels="{checked: 'All', unchecked: 'All'}"/>
                      <div v-if="!showAll">
                        <span v-for="user in meeting.attendees.filter(x => x.userId != null)" :key="user.userId">
                          <AppUserPic :userId="user.userId" :size="2" class="mr-1" v-if="isAttended(user.userId)"/>
                        </span>
                      </div>
                      <div v-else>
                        <span v-for="user in meeting.attendees.filter(x => x.userId != null)" :key="user.userId">
                          <AppUserPic :userId="user.userId" :size="2" class="mr-1" :class="{'notaccepted' : !isAttended(user.userId)}" />
                        </span>
                      </div>
                  </div>

                  <table class="table table-striped table-hover" v-if="externalAttendees">
                      <thead>
                        <tr>
                          <th>External Attendees <i v-if="!meeting.fundId" class="mdi mdi-help-circle-outline" style="font-size: 1.1rem;" v-tooltip="'You must assign a deal before inviting external attendees'"></i>
                            <button :disabled="isLoading || inviteSent" v-if="needInvite && externalAttendees.length > 0" v-tooltip="'Invite to Navigator'" class="btn btn-primary  btn-icon" @click.prevent="confirmSend"><i class="mdi mdi-send"></i> 
                              <span v-if="!isLoading">Invite <span v-if="invites.length == 0"> All</span><span v-else> Selected</span></span>
                              <span v-else>Sending <i class="fa fa-spinner fa-spin"></i></span>
                            </button>
                            <router-link :to="{name: 'PortalFirmsDetail', params: {id: navData.firmId}}" class="btn btn-success btn-icon" :class="{'btn-warning' : navData.fundCnt == 0 }" target="_blank" v-if="navData.firmId > 0">
                              <span v-if="navData.fundCnt > 0">Registred Firm</span>
                              <span v-else>Firm has NO Registred Deal</span>
                              <i class="mdi mdi-open-in-new"></i>
                            </router-link>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="attendee in externalAttendees" :key="attendee.attendeeId">
                          <td>
                            <div class="checkbox checkbox-primary">
                                <input :id="`checkboxcmf`+attendee.attendeeId" type="checkbox" @change="selectInvites(attendee.attendeeId)">
                                <label :for="`checkboxcmf`+attendee.attendeeId">
                                    {{attendee.fullName}} - {{attendee.email}}
                                </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                  <button type='button' class='btn btn-success' :disabled='!meeting.fundId' @click='addContact'><i class="mdi mdi-plus"></i> Add</button>
                </div>

              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
      <div class="side-bar right-bar">
          <div v-if='!contactToggle' class="col-sm-12">
            <CalendarAssignDeal/>
          </div>
          <div v-if='contactToggle' class="col-sm-12">
            <CalendarAddContact :meetingId='meeting.meetingId' @saved='loadPage'/>
          </div>
      </div>
    <Footer/>
  </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import wordcloud from 'vue-wordcloud-tooltip-fix'
import { CalMeetingService } from '@/services/calmeeting-service'
import { PortalFirmService } from '@/services/portal-service'
import CalendarAssignDeal from '@/components/calmeeting/CalendarAssignDeal'
import moment from 'moment'
import { mapGetters } from 'vuex'
import rightbar from '@/mixins/rightbar'
import CalendarAddContact from '@/components/calmeeting/CalendarAddContact'

export default {
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  components: {
    wordcloud,
    CalendarAssignDeal,
    CalendarAddContact
  },
  mixins: [asyncDataStatus, rightbar],
  data () {
    return {
      meeting: null,
      myColors: ['#1f77b4', '#629fc9', '#94bedb', '#c9e0ef'],
      commentComments: [],
      commentWords: [],
      showComments: true,
      showAll: false,
      invites: [],
      sentInvites: null,
      isLoading: false,
      isJoing: false,
      inviteSent: false,
      needInvite: false,
      navData: {firmId: 0, fundCnt: 0},
      contactToggle: false
    }
  },
  methods: {
    wordClickHandler (name, value, vm) {
      this.$snotify.info(name + ': ' + value)
    },
    formatedDate (dt) {
      if (dt) {
        return moment(dt).format('dddd M/D/YYYY, h:mm a')
      } else {
        return ''
      }
    },
    formatedDateAgo (date) {
      return moment(date).fromNow()
    },
    addContact () {
      this.contactToggle = true
      this.showRightbar()
    },
    assignDeal () {
      this.contactToggle = false
      this.$store.commit('setHomeRightBar', 'assignDeal')
      this.$store.commit('setCalanderDeal', this.meeting)
      this.showRightbar()
    },
    extractComments () {
      let extracts = []
      this.meeting.scores.forEach(x => {
        if (x.comment != null) {
          const words = x.comment.split('\n')
          if (words.length > 0) {
            words.forEach(w => {
              extracts.push(w)
            })
          }
        }
      })
      const counts = extracts.reduce((p, c) => {
        if (!p.hasOwnProperty(c)) {
          p[c] = 0
        }
        p[c]++
        return p
      }, {})
      const countsExtended = Object.keys(counts).map(k => { return {name: k, count: counts[k]} })
      countsExtended.forEach(e => {
        this.commentComments.push({'name': e.name, 'value': e.count})
      })
    },
    extractWords () {
      let extracts = []
      this.meeting.scores.forEach(x => {
        if (x.comment != null) {
          const words = x.comment.split('\n')
          if (words.length > 0) {
            words.forEach(w => {
              const wordswords = w.split(' ')
              wordswords.forEach(ww => {
                extracts.push(ww.replace(',', ''))
              })
            })
          }
        }
      })
      const counts = extracts.reduce((p, c) => {
        if (!p.hasOwnProperty(c)) {
          p[c] = 0
        }
        p[c]++
        return p
      }, {})
      const countsExtended = Object.keys(counts).map(k => { return {name: k, count: counts[k]} })
      countsExtended.forEach(e => {
        if (this.whiteFilter(e.name)) {
          this.commentWords.push({'name': e.name, 'value': e.count})
        }
      })
    },
    whiteFilter (word) {
      return word.length > 2 && word !== 'with' && word !== 'for' && word !== 'and' && word !== 'that' && word !== 'are' && word !== 'but'
    },
    isAttended (userId) {
      return this.meeting.scores.filter(x => x.userId === userId).length > 0
    },
    onJoin () {
      this.isJoing = true
      CalMeetingService.joinMeeting(this.id)
      .then((response) => {
        this.isJoing = false
        this.meeting.scores.push({'userId': this.user.userId})
        this.$snotify.success('Meeting Updated')
      })
    },
    onCancel () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to cancel the meeting?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                CalMeetingService.cancelMeeting(this.id, this.meeting)
                .then((response) => {
                  this.$snotify.success('Meeting Canceled')
                  this.meeting.isCancelled = true
                })
                .catch(() => this.$snotify.error('Meeting has been updated before your submit! Please refesh and try again'))
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    selectInvites (id) {
      const fnd = this.invites.findIndex(x => x === id)
      if (fnd < 0) {
        this.invites.push(id)
      } else {
        this.invites.splice(fnd, 1)
      }
    },
    confirmSend () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to send the Invitation?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.sendInvites()
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    sendInvites () {
      let inviteList = []
      let inviteListOutput = []
      if (this.invites.length > 0) {
        inviteList = this.invites
        this.invites.forEach(x => {
          const idx = this.externalAttendees.findIndex(i => i.attendeeId === x)
          if (idx >= 0) {
            inviteListOutput.push(this.externalAttendees[idx].fullName)
          }
        })
      } else {
        inviteList = this.externalAttendees.map(x => x.attendeeId)
        inviteListOutput = this.externalAttendees.map(x => x.fullName)
      }
      this.isLoading = true
      CalMeetingService.invite(this.id, inviteList)
      .then((response) => {
        this.isLoading = false
        this.inviteSent = true
        this.$snotify.success('Invitations will be sent shortly')
        inviteListOutput.forEach(x => {
          const n = new Date()
          this.sentInvites.unshift({'createdOn': n, 'createdBy': this.user.userId, 'fullName': x})
        })
      })
      .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    loadInvites () {
      CalMeetingService.loadInvites(this.id)
      .then((response) => {
        this.sentInvites = response.data
      })
      .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    userName (userId) {
      let result = ''
      const usr = this.allUsers.find(e => e.userId === userId)
      if (this.userId === 0) {
        return 'System'
      }
      if (usr != null) {
        return usr.firstName + ' ' + usr.lastName
      }
      return result
    },
    loadPage () {
      CalMeetingService.get(this.id)
      .then(
        (response) => {
          if (response.data.meetingId > 0) {
            this.meeting = response.data
          } else {
            this.meeting = null
          }
        }
      ).then(() => {
        this.loadInvites()
      }).then(() => {
        this.extractComments()
      }).then(() => {
        this.extractWords()
      }).then(() => {
        if (this.externalAttendees.length > 0) {
          PortalFirmService.checkFirmFund(this.externalAttendees[0].email)
          .then(
            (response) => {
              this.navData = response.data
              this.needInvite = !response.data.firmId > 0 || !response.data.fundCnt > 0
            }
          )
        }
      })
      .then(() => {
        this.asyncDataStatus_fetched('Meeting Details')
      })
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    location () {
      return this.meeting.location == null ? '(empty)' : this.meeting.location
    },
    avgScoreDecimal () {
      if (parseInt(this.meeting.avgScore) !== this.meeting.avgScore) {
        return (this.meeting.avgScore * 10) - (parseInt(this.meeting.avgScore) * 10)
      } else {
        return 0
      }
    },
    avgScoreColorClass () {
      if (this.meeting.avgScore < 2) {
        return 'text-danger'
      } else if (this.meeting.avgScore >= 2 && this.meeting.avgScore < 3) {
        return 'text-default'
      } else if (this.meeting.avgScore >= 3 && this.meeting.avgScore < 4) {
        return 'text-warning'
      } else if (this.meeting.avgScore >= 4 && this.meeting.avgScore < 5) {
        return 'text-success'
      } else {
        return 'text-primary'
      }
    },
    externalAttendees () {
      return this.meeting.attendees.filter(x => x.userId == null && x.email.indexOf('@wilshire.com') < 0)
    },
    formatedInvites () {
      let res = ''
      if (this.sentInvites == null) {
        res = 'Loading...'
      } else {
        this.sentInvites.forEach(invite => {
          res += this.formatedDate(invite.createdOn) + ' to ' + invite.fullName + ' by ' + this.userName(invite.createdBy) + '\n'
        })
      }
      return res.length > 0 ? res : 'There is no invitation for this meeting'
    },
    allUsers () {
      return this.$store.state.allUsers
    }
  },
  created () {
    this.loadPage()
  }
}
</script>

<style scoped>
.notaccepted {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>>
