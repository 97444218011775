import Constants from '../constants.js'
import Axios from 'axios'

export const AllocationService = {
  getVehicles (fundId) {
    return Axios.get(Constants.API_URL + '/allocation/vehicles/' + fundId)
  },
  get (fundId) {
    return Axios.get(Constants.API_URL + '/allocation/' + fundId)
  },
  getVersion (fundId, versionNo) {
    return Axios.get(Constants.API_URL + '/allocation/' + fundId + '/' + versionNo)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/allocation/' + id, value)
  },
  add (value) {
    return Axios.post(Constants.API_URL + '/allocation', value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/allocation/' + id)
  },
  startVoting (id) {
    return Axios.post(Constants.API_URL + '/allocation/start/' + id)
  },
  finalize (id) {
    return Axios.post(Constants.API_URL + '/allocation/finalize/' + id)
  },
  getForMeetingPMIc () {
    return Axios.get(Constants.API_URL + '/allocation/meeting-icpm/')
  },
  geeLegalList (fundId) {
    return Axios.get(Constants.API_URL + '/allocation/legal/' + fundId)
  },
  createNewVersion (id) {
    return Axios.post(Constants.API_URL + '/allocation/create-version/' + id)
  }
}

export const AllocationVotingService = {
  check (id) {
    return Axios.get(Constants.API_URL + '/allocation-voting/check/' + id)
  },
  save (id, value) {
    return Axios.post(Constants.API_URL + '/allocation-voting/' + id, value)
  },
  answer (id, value) {
    return Axios.post(Constants.API_URL + '/allocation-voting/answer/' + id, value)
  }
}

export const AllocationRationaleService = {
  get (fundId) {
    return Axios.get(Constants.API_URL + '/allocation-rationale/' + fundId)
  },
  save (id, value) {
    return Axios.post(Constants.API_URL + '/allocation-rationale/' + id, value)
  }
}
