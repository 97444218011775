<template>
  <span :class="{'input-group' : showpic}">
      <AppUserPic :userId="userId" :size="1" v-if="showpic"/>
      <input v-if="show == 'input'" type="text" :value="user" readonly class="form-control">
      <span v-else>{{ user }}</span>
  </span>
</template>

<script>
export default {
  props: {
    userId: {
      required: false,
      type: Number,
      default: 0
    },
    show: {
      type: String,
      default: 'input'
    },
    emptyIfNull: {
      type: Boolean,
      default: false
    },
    showpic: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    user () {
      let result = this.emptyIfNull || !this.userId ? '' : '(Not Found)'
      const usr = this.$store.state.allUsers.find(e => e.userId === this.userId)
      if (usr != null) {
        return usr.firstName + ' ' + usr.lastName
      }
      return result
    }
  }
}
</script>
