<template>
  <div>
    <table class="table table-striped table-hover table-files">
      <tbody>
        <tr v-if="files === null">
          <td class="text-center" colspan="2">
            <p>No Document to show</p>
          </td>
        </tr>
        <tr v-for="file in fileList" :key="file.fileId">
          <td>
            <a href="javascript:void(0)" class="file-item-icon" @click.prevent="select(file.fileId)" v-tooltip="file.fileType">
              <i class="mdi file-type-icon" :class="fileIcon(file.fileType)"></i>
            </a>
          </td>
          <td>
            <a href="javascript:void(0)" @click.prevent="select(file.fileId)">
              {{file.purposeName}}
            </a>
            <i class="mdi mdi-information-outline" v-tooltip="file.originalFileName"></i>
          </td>
          <td>
            <i v-if="file.forConnect" class="mdi mdi-copyright" v-tooltip="'Available in Connect'"></i>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-success btn-sm btn-icon mr-1" @click.prevent="view(file)" v-tooltip="'Download'"><i class="mdi mdi-download"></i></button>
              <button class="btn btn-danger btn-sm btn-icon mr-1" v-if="canDelete && user.groupId < 11" @click.prevent="onDelete(file.fileId)" v-tooltip="'Delete'"><i class="mdi mdi-delete"></i></button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addNew" v-if="canEdit"> <i class="mdi mdi-file-plus"></i> New</button>
    <toggle-button v-if="hasMoreFiles && showMoreTiggle" v-model="showMoreFiles" :labels="{checked: 'More', unchecked: 'More'}" :width=55 />
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { FileService } from '@/services/file-service'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    files: {
      type: Array,
      default: []
    },
    isEditMode: {
      required: true,
      type: Boolean
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    showMoreTiggle: {
      default: true,
      type: Boolean
    }
  },
  components: {
  },
  data () {
    return {
      showMoreFiles: false
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['deleteFile']),
    addNew () {
      this.hideRightbar()
      this.$emit('onClickNew')
    },
    select (id) {
      this.$emit('onSelect', id)
    },
    view (file) {
      FileService.download(file.fileId)
      .then(
        (response) => {                
          var ext = file.originalFileName.split('.').reverse()[0]
          if(ext.toLowerCase()=='pdf') {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            window.open(fileURL)
          } else {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.target = "_blank"
            fileLink.href = fileURL
            fileLink.setAttribute('download', file.originalFileName)
            document.body.appendChild(fileLink)
            fileLink.click()
          }         
        }
      )
    },
    onDelete (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deleteFile({fileId: id})
                .then((response) => {
                  this.$snotify.success('File Deleted.')
                  this.$emit('onUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    fileIcon (type) {
      switch (type.toLowerCase()) {
        case 'pdf':
          return 'mdi mdi-file-pdf'
        case 'doc':
        case 'docx':
          return 'mdi mdi-file-word'
        case 'xls':
        case 'xlsx':
          return 'mdi mdi-file-excel'
        case 'png':
        case 'gif':
        case 'jpg':
        case 'jpeg':
          return 'mdi mdi-file-image'
        default:
          return 'mdi mdi-file'
      }
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    fileList () {
      if (this.showMoreFiles) {
        return this.files
      } else {
        return this.filesGroupBy
      }
    },
    filesGroupBy () {
      let outlist = []
      if (this.files == null) {
        return outlist
      }
      for (let i = 0; i < this.files.length; i++) {
        if (outlist.findIndex(e => e.purposeId === this.files[i].purposeId) < 0) {
          outlist.push(this.files[i])
        }
      }
      return outlist
    },
    hasMoreFiles () {
      if (this.files != null) {
        return this.filesGroupBy.length < this.files.length && this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR || x.roleId === this.$userRoles.BUSINESS_COMMITTEE).length > 0
      }
      return false
    }
  }
}
</script>
