<template>
  <div>
    <h4 v-if="list !== null">Your Meetings</h4>
    <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
    <div class="row" v-if="list">
      <div class="col-md-6 col-lg-4" v-for="item in list.meetings.filter(x => x.dashTypeId === 2)" :key="item.meetingId">
          <div class="portlet">
              <div class="portlet-heading bg-warning">
                  <h3 class="portlet-title calmeeting-title" v-tooltip="item.title">
                    {{item.title}}
                  </h3>
                  <div class="portlet-widgets">
                      <a href="javascript::void(0)" @click.prevent="collapse(item.meetingId)" :class="{'collapsed' : item.collapse != 'show'}"><i class="ion-minus-round"></i></a>
                  </div>
                  <div class="clearfix"></div>
              </div>
              <div class="panel-collapse collapse" :class="item.collapse">
                  <div class="portlet-body">
                      <div v-if="item.score">
                        <textarea class="form-control" rows="2" v-model="item.comment" maxlength="250" placeholder="Provide highlights in each-line"></textarea>
                      </div>
                      <span v-if="!item.score">
                          <div>
                            <i class="mdi mdi-square-inc-cash" v-tooltip="'Related Deal'"></i> <span>{{item.fundName}}</span>
                          </div>
                          <div>
                            <i class="mdi mdi-calendar-clock" v-tooltip="'Start Date/Time'"></i> <span>{{showStartTime(item)}}</span><span class="badge badge-primary ml-1">{{item.typeName}}</span>
                          </div>
                          <div>
                            <i class="mdi mdi-map-marker calmeeting-location" v-tooltip="'Location'"></i> <span>{{item.locationName}}</span>
                          </div>
                      </span>
                      <div class="mt-1 row">
                        <div class="col-sm-12 col-md-12 col-lg-9">
                          <fa-rating :glyph="icon" :show-rating="false" v-model="item.score" active-color="#ffc107"></fa-rating>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3">
                          <button class="btn btn-success btn-sm btn-icon pull-right mr-1" v-tooltip="'Save'" v-if="!item.canClose" @click.prevent="score(item.meetingId)" :disabled="!item.comment && !item.score"><i class="mdi mdi-content-save"></i></button>
                          <button class="btn btn-primary btn-sm btn-icon pull-right mr-1" v-tooltip="'Note'" @click.passive="addNote(item)" v-if="item.fundId > 0"><i class="mdi mdi-tag-plus"></i></button>                        
                          <button class="btn btn-custom btn-sm btn-icon pull-right mr-1" v-tooltip="'Remove'" @click.prevent="cannotMake(item.meetingId)"><i class="mdi mdi-account-off"></i></button>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-md-6 col-lg-4" v-for="item in list.meetings.filter(x => x.dashTypeId === 1)" :key="item.meetingId">
          <div class="portlet">
              <div class="portlet-heading bg-primary" :class="{'bg-pink' : checkForToday(item.startTime)}">
                  <h3 class="portlet-title calmeeting-title2" v-tooltip="item.title">
                    {{item.title}}
                  </h3>
                  <div class="portlet-widgets">
                      <a href="javascript::void(0)" @click.prevent="collapse(item.meetingId)" :class="{'collapsed' : item.collapse != 'show'}"><i class="ion-minus-round"></i></a>
                      <span class="divider"></span>
                      <a href="javascript::void(0)" @click.prevent="remove(item.meetingId)"><i class="ion-close-round"></i></a>
                  </div>
                  <div class="clearfix"></div>
              </div>
              <div class="panel-collapse collapse" :class="item.collapse">
                  <div class="portlet-body">
                      <div>
                        <i class="mdi mdi-square-inc-cash" v-tooltip="'Related Deal'"></i> <span class="text-white" v-if="item.fundName != null">{{item.fundName}}
                          <button class="btn btn-success btn-sm btn-mini btn-icon" @click.prevent="assignDeal(item)" v-tooltip="'Update'"><i class="mdi mdi-lead-pencil"></i>
                          </button>
                          </span>
                        <a v-else href="javascript:void(0)" @click="assignDeal(item)">Assign a Deal</a>
                      </div>
                      <div>
                        <i class="mdi mdi-calendar-clock" v-tooltip="'Start Date/Time'"></i> <span>{{showStartTime(item)}}</span><span class="badge badge-pink ml-1">{{item.typeName}}</span>
                      </div>
                      <div>
                        <i class="mdi mdi-clock" v-tooltip="'Duration'"></i> <span>{{duration(item)}}</span>
                      </div>
                      <div class="calmeeting-location">
                        <i class="mdi mdi-map-marker" v-tooltip="'Location'"></i> <span>{{item.locationName}}</span>
                      </div>
                      <div class="mt-1">
                        <span v-for="user in wilshireAttendees(item.meetingId)" :key="user">
                          <AppUserPic :userId="user.userId" :size="2" class="mr-1" :class="{'notaccepted' : user.scoreId == 0}"/>
                        </span>
                        <span v-if="countAttendees(item.meetingId) > 0">
                          +{{countAttendees(item.meetingId)}}
                        </span>
                        <button class="btn btn-success btn-sm btn-icon pull-right" v-tooltip="'Join'" @click.prevent="join(item.meetingId)"><i class="mdi mdi-human-greeting"></i></button>
                        <button class="btn btn-primary btn-sm btn-icon pull-right mr-1" v-tooltip="'Skip'" @click.prevent="skip(item.meetingId)"><i class="mdi mdi-close-circle-outline"></i></button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {FaRating} from 'vue-rate-it'
import Umbrella from 'vue-rate-it/glyphs/umbrella'
import rightbar from '@/mixins/rightbar'
import { CalMeetingService } from '@/services/calmeeting-service'

export default {
  data () {
    return {
      list: null,
      isLoading: true
    }
  },
  components: {
    FaRating
  },
  mixins: [rightbar],
  methods: {
    collapse (id) {
      const i = this.list.meetings.findIndex(e => e.meetingId === id)
      if (i > -1) {
        const d = this.list.meetings[i].collapse
        if (d === 'show') {
          this.list.meetings[i].collapse = ''
        } else {
          this.list.meetings[i].collapse = 'show'
        }
      }
    },
    remove (id) {
      const i = this.list.meetings.findIndex(e => e.meetingId === id)
      if (i > -1) {
        this.list.meetings.splice(i, 1)
      }
    },
    skip (id) {
      CalMeetingService.skipMeeting(id)
      .then((response) => {
        this.$snotify.success('Calendar Updated')
        this.remove(id)
      })
    },
    cannotMake (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                CalMeetingService.couldNotMeeting(id)
                .then((response) => {
                  this.$snotify.success('Calendar Updated')
                  this.remove(id)
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    join (id) {
      CalMeetingService.joinMeeting(id)
      .then((response) => {
        this.$snotify.success('Calendar Updated')
        this.remove(id)
      })
    },
    score (id) {
      const i = this.list.meetings.findIndex(x => x.meetingId === id)
      if (i >= 0) {
        const sk = {'meetingId': id, 'score': this.list.meetings[i].score, 'comment': this.list.meetings[i].comment}
        CalMeetingService.scoreMeeting(id, sk)
        .then((response) => {
          this.$snotify.success('Meeeting score Updated')
          this.remove(id)
        })
      } else {
        this.$snotify.error('Score is missing')
      }
    },
    duration (item) {
      const e = moment(item.endTime)
      const s = moment(item.startTime)
      return moment.duration(e.diff(s)).asMinutes()
    },
    showStartTime (item) {
      return moment(item.startTime).format('M/D/YYYY, h:mm a')
    },
    wilshireAttendees (meetingId) {
      let users = []
      let i = 0
      this.list.attendees.filter(x => x.meetingId === meetingId).forEach(x => {
        if (i++ > 3) {
          return users
        }
        if (x.userId > 0) {
          users.push(x)
        }
      })
      return users
    },
    countAttendees (meetingId) {
      let c = this.list.attendees.filter(x => x.meetingId === meetingId).length
      const w = this.list.attendees.filter(x => x.meetingId === meetingId && x.userId > 0).length
      c = c - w
      if (w >= 4) {
        c = c + (w - 4)
      }
      return c
    },
    assignDeal (meetingItem) {
      this.$store.commit('setHomeRightBar', 'assignDeal')
      this.$store.commit('setCalanderDeal', meetingItem)
      this.showRightbar()
    },
    addNote (meetingItem) {
      this.$store.commit('setHomeRightBar', 'note')
      this.$store.commit('setNewActivity', meetingItem.fundId)
      this.showRightbar()
    },
    checkForToday (date) {
      return moment().diff(date, 'days') === 0
    }
  },
  created () {
    this.icon = Umbrella
    CalMeetingService.getDashList()
    .then((response) => {
      if (response.data.meetings.length > 0) {
        this.list = response.data
      }
      this.isLoading = false
    })
  },
  computed: {
    allUsers () {
      return this.$store.state.allUsers
    }
  }
}
</script>                            

<style scoped>
.notaccepted {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>>
