<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
            On-site Meeting
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="meetingDate">Date<span v-show="isEditMode" class="text-danger">*</span></label>
          <date-picker v-model="onsiteMeeting.meetingDate" @blur="$v.onsiteMeeting.meetingDate.$touch()" :disabled="!isEditMode" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true" :input-class="{ 'form-control parsley-error': $v.onsiteMeeting.meetingDate.$error }"></date-picker>
          <template v-if="$v.onsiteMeeting.meetingDate.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.onsiteMeeting.meetingDate.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>
      <div class="form-group">
          <label for="city">City<span v-show="isEditMode" class="text-danger">*</span></label>
          <input type="text" v-model="onsiteMeeting.city" @blur="$v.onsiteMeeting.city.$touch()" :disabled="!isEditMode" placeholder="Enter City" class="form-control" id="city" :class="{ 'parsley-error': $v.onsiteMeeting.city.$error }">
          <template v-if="$v.onsiteMeeting.city.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.onsiteMeeting.city.required" class="parsley-required">This value is required</li>
              <li v-if="!$v.onsiteMeeting.city.max" class="parsley-required">This value is too long</li>
            </ul>
          </template>
      </div>
      <div class="form-group">
          <label for="Employee">Employees</label>
          <div class="onsite-employee-list">
            <div v-for="emp in onsiteMeeting.employees" :key="emp.employeeId">
              <div class="input-group-append">
                {{emp.fullName}}&nbsp;&nbsp;
                <button class="btn btn-danger btn-sm btn-mini btn-icon" @click.prevent="removeEmployee(emp.employeeId)" v-if="isEditMode"><i class="mdi mdi-delete"></i></button>
              </div>
            </div>
            <div v-if="onsiteMeeting.employees.length === 0">
              Add Employee
            </div>
          </div>
          <div class="input-group-append" v-show="isEditMode">
            <span :class="{ 'parsley-error-radio': doubleAddError }" style="width: 100%">
              <multiselect id="Employee" v-model="userObj" :options="allActiveUsers" label="fullName" track-by="userId" :allow-empty="false" placeholder="Search User"></multiselect>
            </span>
            <button class="btn btn-icon waves-effect waves-light btn-primary" @click.prevent="addEmployee"><i class=" mdi mdi-account-plus"></i></button>
          </div>
          <template v-if="doubleAddError">
            <ul class="parsley-errors-list filled">
              <li class="parsley-required">User already exists</li>
            </ul>
          </template>
      </div>
      <div class="form-group">
        <label for="comment">Notes</label>
        <textarea type="text" v-model="onsiteMeeting.comment" :disabled="!isEditMode" class="form-control" id="inactiveNotes" rows="3" placeholder="Enter Notes" @blur="$v.onsiteMeeting.comment.$touch()" :class="{ 'parsley-error': $v.onsiteMeeting.comment.$error }"/>
        <template v-if="$v.onsiteMeeting.comment.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.onsiteMeeting.comment.max" class="parsley-required">This value is too long</li>
          </ul>
        </template>
      </div>

      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="onsiteMeeting.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="onsiteMeeting.createdBy" />
          </div>
          <div class="form-group" v-if="onsiteMeeting.updatedOn">
              <label>Modified On</label>
              <AppDate :date="onsiteMeeting.updatedOn" :show="'input'"/>
          </div>
          <div class="form-group" v-if="onsiteMeeting.updatedOn">
              <label>Modified By</label>
              <AppUser :userId="onsiteMeeting.updatedBy" :emptyIfNull="true"/>
          </div>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect,
    DatePicker
  },
  data () {
    return {
      showLogs: false,
      userObj: null,
      doubleAddError: false
    }
  },
  validations: {
    onsiteMeeting: {
      meetingDate: { required },
      city: { required, max: maxLength(100) },
      comment: { max: maxLength(500) }
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['addFundsOnsiteMeetings', 'updateFundsOnsiteMeetings']),
    close () {
      this.$v.onsiteMeeting.$reset()
      this.doubleAddError = false
      this.hideRightbar()
    },
    onSave () {
      this.$v.onsiteMeeting.$touch()
      if (this.$v.onsiteMeeting.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }

      if (this.isAdd) {
        this.addFundsOnsiteMeetings({fundOnsiteMeeting: this.onsiteMeeting})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Onsite Meeting Added.')
              this.$emit('onUpdate')
            }
          })
      } else {
        this.updateFundsOnsiteMeetings({meetingId: this.onsiteMeeting.meetingId, fundOnsiteMeeting: this.onsiteMeeting})
          .then((response) => {
            this.$snotify.success('Onsite Meeting Updated.')
            this.$emit('onUpdate')
          })
      }
    },
    addEmployee () {
      this.doubleAddError = false
      if (this.onsiteMeeting.employees.findIndex(x => x.employeeId === this.userObj.userId) < 0) {
        const newUser = {employeeId: this.userObj.userId, fullName: this.userObj.fullName, meetingId: this.onsiteMeeting.meetingId}
        this.onsiteMeeting.employees.push(newUser)
        this.userObj = null
      } else {
        this.doubleAddError = true
      }
    },
    removeEmployee (id) {
      const i = this.onsiteMeeting.employees.findIndex(x => x.employeeId === id)
      this.onsiteMeeting.employees.splice(i, 1)
    }
  },
  created () {
  },
  computed: {
    onsiteMeeting () {
      return this.$store.state.rb_fundOnsiteMeeting
    },
    allActiveUsers () {
      return this.$store.state.allUsers.filter(u => u.userId > 0 && u.isActive)
    }
  }
}
</script>
