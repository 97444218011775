<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'tasks-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Tasks'" :icon="'mdi mdi-clipboard-check'" />
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box table-responsive">
                                <h4 class="header-title">Tasks List</h4>
                                <div class="row mb-1">
                                    <div class="col-sm-12 col-md-12 form-inline p-0">
                                      <div class="col-2 mr-3">
                                        <label class="col-form-label d-flex justify-content-start">
                                          <span class='mr-2'>Only Mine</span>
                                          <toggle-button :sync="true" :labels="{checked: ' ', unchecked: ' '}" v-model="assignedToMe" @input="startSearch" />
                                        </label>
                                      </div>
                                      <div class="col-3">
                                        <label class="col-form-label d-flex justify-content-start">
                                          <span class="mr-2">Only Incompleted</span>
                                          <toggle-button :sync="true" :labels="{checked: ' ', unchecked: ' '}" v-model="incomplete" @input="startSearch" />
                                        </label>
                                      </div>
                                      <div class='col-6'></div>
                                      <div class='col'>
                                        <button @click="clearFilters" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                      </div>
                                    </div>
                                </div>

                                <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)">Firm Name
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)">Assigned To
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th> 
                                        <th @click="setOrder(2)">Created On
                                          <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(3)">Completed On
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="task in tasks"
                                        :key="task.taskId">
                                        <td>
                                            {{task.firmName}}
                                        </td>
                                        <td>
                                          <div class='d-flex flex-row justify-content-center'>
                                            <AppUser v-if="task.userId > 0" :userId="task.userId" :show="'text'" :showpic="true"/><button v-if='showTransfer(task)' @click.prevent='onTransfer(task.taskId, task.userId)' class='btn btn-primary btn-sm' v-tooltip='"Transfer task to another user"'><i class='mdi mdi-account-edit'></i></button>
                                          </div>
                                        </td>
                                        <td>
                                          <AppDate :date="task.createdOn" :show="'span'"/>
                                        </td>
                                        <td>
                                          <AppDate :date="task.activityDate" :show="'span'"/>
                                        </td>
                                      </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div class="" role="status" aria-live="polite">Page {{currentPage}} of {{totalPages}} - {{thousandNumber(totalRecords)}} tasks</div>
                                    <div class="px-3">
                                          <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                              <option value="250">250</option>
                                          </select> 
                                    </div>

                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end container -->
            </div>
            <!-- end content -->

            <div class="side-bar right-bar">
                <div class="col-sm-12">
                  <TasksTransfer @onUpdate='onUpdate' :assigneeList='[]' :taskId='taskId' :originalUserId='user.userId' />
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import { TaskService } from '@/services/task-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import moment from 'moment'
import { mapGetters } from 'vuex'
import rightbar from '@/mixins/rightbar'
import TasksTransfer from '@/components/tasks/TasksTransfer'

export default {
  components: {
    moment,
    TasksTransfer
  },
  mixins: [asyncDataStatus, rightbar],
  data () {
    return {
      taskId: 0,
      tasks: [],
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      sortIndex: 0,
      sortType: 'ASC',
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0,
      assignedToMe: false,
      incomplete: false,
      sortField: ['firmName', 'userName', 'createdOn', 'activityDate']
    }
  },
  methods: {
    onUpdate (updateObj) {
      let idx = this.tasks.findIndex(obj => obj.taskId === updateObj.taskId)
      if (idx >= 0) {
        this.tasks[idx].userId = updateObj.userObj.userId
      }
    },
    clearFilters () {
      this.incomplete = false
      this.assignedToMe = false
      this.startSearch()
    },
    thousandNumber (number) {
      return number.toLocaleString()
    },
    onTransfer (taskId, assigneeId) {
      this.taskId = taskId
      this.assigneeId = assigneeId
      this.showRightbar()
    },
    getList () {
      this.isLoading = true
      const userId = this.assignedToMe ? this.user.userId : null
      const filters = {
        'assignedToMe': this.assignedToMe,
        'firmName': this.firmName,
        'incomplete': this.incomplete,
        'sortIndex': this.sortIndex,
        'sortType': this.sortType,
        'pageSize': this.pageSize
      }

      
      localStorage.setItem('umb_tasks_filters', JSON.stringify(filters))
      TaskService.search(userId, !filters.incomplete, this.sortField[filters.sortIndex], filters.sortType, this.currentPage, filters.pageSize)
      .then((response) => {
        this.isLoading = false
        this.tasks = response.data.tasks
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Tasks List') })
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    showTransfer (task) {
      return task.activityDate === null
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.tasks = []

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    resetFilter () {
      this.assignedToMe = false
      this.incomplete = false
      this.startSearch()
    }
  },
  mounted () {
    const oldFilters = JSON.parse(localStorage.getItem('umb_tasks_filters'))
    if (oldFilters != null) {
      this.assignedToMe = oldFilters.assignedToMe
      this.incomplete = oldFilters.incomplete
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
      if (oldFilters.sortIndex != null) {
        this.sortIndex = oldFilters.sortIndex
      }
      if (oldFilters.sortType != null) {
        this.sortType = oldFilters.sortType
      }
    }
    this.startSearch()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    hasFilter () {
      return this.assignedToMe || this.incomplete
    }
  }
}
</script>