import Constants from '../constants.js'
import Axios from 'axios'

export const NotificationService = {
  getUnreads () {
    return Axios.get(Constants.API_URL + '/notification/unread')
  },
  getAll (lastId) {
    return Axios.get(Constants.API_URL + '/notification/all/' + lastId)
  },
  dismiss (lastId) {
    return Axios.get(Constants.API_URL + '/notification/dismiss/' + lastId)
  }
}
