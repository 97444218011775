<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'tasks-outreach'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Firm Outreach'" :icon="'mdi mdi-clipboard-check'" />
                    <div class="card-box">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                              <label for="secOutreachTierId">Secondary Outreach Tier</label>
                              <select class="form-control" id="secOutreachTierId" v-model="form.outreachTierId">
                                  <option value="0">Select</option>
                                  <option :value="item.tierId" v-for="item in allSecOutreachTiers" :key="item.tierId">{{item.tierName}}</option>                
                              </select>
                          </div>

                          <div class="form-group text-right m-b-0">
                            <button type="reset" class="btn btn-secondary waves-effect m-l-5 mr-2" @click.prevent="reset">
                                Reset
                            </button>
                            <button class="btn waves-effect waves-light" type="submit" @click.prevent="generateTask" :class="{'btn-primary': form.outreachTierId > 0 && canCreate}" :disabled="form.outreachTierId == 0 || !canCreate">
                                Submit
                            </button>
                          </div>
                          <div v-if="messageResult">
                            <div class="text-primary">{{messageResult}} </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { mapActions, mapGetters } from 'vuex'
import { TaskService } from '@/services/task-service'
export default {
  components: {
  },
  data () {
    return {
      form: {
        outreachTierId: 0
      },
      messageResult: null
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    ...mapActions(['fetchAllSecOutreachTiers']),
    reset () {
      this.form.outreachTierId = 0
      this.messageResult = null
    },
    generateTask () {
      if (this.form.outreachTierId > 0) {
        TaskService.save(this.form.outreachTierId).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.messageResult = response.data + ' tasks has been generated'
              this.$snotify.success('Done')
            }
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      } else {
        this.$snotify.error('Please select outreach tier.')
      }
    }
  },
  created () {
    this.fetchAllSecOutreachTiers()
    this.asyncDataStatus_fetched('Firms Outreach')
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allSecOutreachTiers () {
      return this.$store.state.allSecOutreachTiers
    },
    canCreate () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.FIRM_OUTREACH || x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0 // Firm Outreach + System Administrator
    }
  }
}
</script>
