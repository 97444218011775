<template>
  <tbody>
    <tr v-if="meetings === null">
      <td class="text-center" colspan="2">No IC Meeting to show</td>
    </tr>
    <tr v-for="item in meetings"
        :key="item.meetingId">
        <td>
          <router-link :to="{name: linkName(item.meetingTypeId), params: {id: item.meetingId}}" target="_blank">
              <AppDate :date="item.meetingDate" :show="'text'"/>
          </router-link>
        </td>
        <td>
          {{item.typeName}}
        </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    meetings: {
      type: Array,
      default: null
    }
  },
  methods: {
    linkName (typeId) {
      let url = ''
      switch (typeId) {
        case 1:
          url = 'IcMeetingMrcPM'
          break
        case 2:
          url = 'IcMeetingIcPM'
          break
        case 3:
          url = 'IcMeetingMrcMA'
          break
      }
      return url
    }
  }
}
</script>
