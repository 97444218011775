<template>
  <tbody>
    <tr v-if="funds === null">
      <td colspan="8" class="text-center">No Related Deals to show</td>
    </tr>
    <tr v-for="fund in funds"
        :key="fund.fundId">
        <td>
          <router-link :to="{name: 'FundsDetail', params: {id: fund.fundId}}" target="_blank">
              {{fund.fundName}}
          </router-link>
        </td>
        <td>{{fund.isActive ? 'Active' : 'Inactive'}}</td>
        <td>{{fund.stageName}}</td>
        <td>{{fund.typeName}}</td>
        <td>{{fund.sectorName}}</td>
        <td>{{fund.vintageYear}}</td>
        <td>{{fund.targetSize}}</td>
        <td>{{fund.geographicName}}</td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    funds: {
      type: Array,
      default: null
    },
    isEditMode: {
      required: true,
      type: Boolean
    }
  }
}
</script>
