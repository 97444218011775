import Constants from '../constants.js'
import Axios from 'axios'

export const OpsTeamService = {
  search (typeId, categoryId, requestorId, assignedTo, deadline, requestDate, statusId, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/opr-request/search?typeId=' + typeId + '&categoryId=' + categoryId + '&requestorId=' + requestorId + '&assignedTo=' + assignedTo + '&requestDate=' + requestDate + '&deadline=' + deadline + '&statusId=' + statusId + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  get (id) {
    return Axios.get(Constants.API_URL + '/opr-request/' + id)
  },
  add (request) {
    return Axios.post(Constants.API_URL + '/opr-request', request)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/opr-request/' + id, value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/opr-request', id)
  },
  updateStatus (id, value) {
    return Axios.put(Constants.API_URL + '/opr-request/status/' + id, value)
  },
  updateAssign (id, value) {
    return Axios.put(Constants.API_URL + '/opr-request/assign/' + id, value)
  },
  getDashboardList () {
    return Axios.get(Constants.API_URL + '/opr-request/dashboard-list')
  }
}

export const OprInvestorService = {
  getAll () {
    return Axios.get(Constants.API_URL + '/opr-investor')
  }
}

export const OprServiceProviderService = {
  getAll () {
    return Axios.get(Constants.API_URL + '/opr-serviceprovider')
  }
}

export const OprStatusUpdateService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/opr-statusupdates/' + id)
  },
  add (id, value) {
    return Axios.post(Constants.API_URL + '/opr-statusupdates/' + id, value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/opr-statusupdates/' + id)
  }
}
