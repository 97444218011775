export default {
  data () {
    return {
    }
  },
  methods: {
    showRightbar () {
      document.getElementById('wrapper').classList.add('right-bar-enabled')
      document.addEventListener('keyup', this.escapeKeyListener)
    },
    hideRightbar () {
      document.getElementById('wrapper').classList.remove('right-bar-enabled')
      if (document.getElementsByClassName('side-bar').length > 0) {
        document.getElementsByClassName('side-bar')[0].classList.remove('side-bar-expanded')
      }
      document.removeEventListener('keyup', this.escapeKeyListener)
    },
    expandRightbar () {
      document.getElementsByClassName('side-bar')[0].classList.add('side-bar-expanded')
    },
    collapseRightbar () {
      document.getElementsByClassName('side-bar')[0].classList.remove('side-bar-expanded')
    },
    escapeKeyListener (evt) {
      if (evt.keyCode === 27) {
        this.hideRightbar()
      }
    }
  }
}
