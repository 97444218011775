<template>
  <!-- Page-Title -->
  <div class="row">
    <div class="col-sm-12">
      <div class="page-title-box">
          <h4 class="page-title"><i :class="icon"></i> {{title}} <i v-if="hasTask" class="badge badge-warning">Pending Task</i> </h4>
          
          <ol class="breadcrumb float-right">
            <li v-if="showPins">
              <button class="btn-sm btn-icon waves-effect waves-light btn-primary m-b-0" :disabled="inEditMode" @click="onPin" v-if="pinId == 0"> <i class="mdi mdi-pin" v-tooltip="'Pin'"></i> </button>
              <button class="btn-sm btn-icon waves-effect waves-light btn-primary m-b-0" :disabled="inEditMode" @click="onPinOff" v-if="pinId > 0"> <i class="mdi mdi-pin-off" v-tooltip="'Unpin'"></i> </button>
            </li>
          </ol>
          <div class="clearfix"></div>
      </div>
      <div v-show="showToolbar" class="mb-3">
        <button class="btn btn-primary btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="inEditMode" @click="onCancel"><i class="mdi mdi-backup-restore"></i> <span>Cancel</span></button>
        <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="!inEditMode" :disabled="!canEdit" @click="onEdit"><i class="mdi mdi-lead-pencil m-r-5"></i> <span>Edit</span></button>
        <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5 mr-2" @click="onSave" v-show="inEditMode" :disabled="isSaving"><i class="mdi mdi-content-save m-r-5"></i> <span v-if="!isSaving">Save</span> <i v-else class="fa fa-spinner fa-spin"></i></button>
        <button class="btn btn-danger btn-custom waves-effect w-md waves-light m-b-5 mr-2" :disabled="inEditMode" v-if="canDelete" @click="onDelete"><i class="mdi mdi-delete m-r-5"></i> <span>Delete</span></button>
        <button class="btn btn-warning btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="canApprove" :disabled="inEditMode" @click="onApprove"> <i class="mdi mdi-approval m-r-5"></i> <span>Approve </span> </button>
        <!-- <button class="btn btn-warning waves-effect btn-custom waves-effect w-md waves-light m-b-5" :disabled="inEditMode" @click="onNew"> <i class="mdi mdi-note-plus m-r-5"></i> <span>New</span> </button> -->
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'No Title'
    },
    icon: {
      type: String,
      default: ''
    },
    showToolbar: {
      type: Boolean,
      default: false
    },
    showPins: {
      type: Boolean,
      default: false
    },
    pinId: {
      type: Number,
      default: 0
    },
    inEditMode: {
      required: false,
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canApprove: {
      type: Boolean,
      default: false
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    hasTask: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    onEdit () {
      this.$emit('onEdit')
    },
    onNew () {
      this.$emit('onNew')
    },
    onCancel () {
      this.$emit('onCancel')
    },
    onSave () {
      this.$emit('onSave')
    },
    onDelete () {
      this.$emit('onDelete')
    },
    onPin () {
      this.$emit('onPin')
    },
    onPinOff () {
      this.$emit('onPinOff')
    },
    onApprove () {
      this.$emit('onApprove')
    }
  }
}
</script>
