<template>
  <div>
    <Topbar/>
    <Sidebar :activeItem="'legal-add'"/>
    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid">
          <PageTitle :title="'Legal Workflow'" :icon="'mdi mdi-scale-balance'" />
          <div class="card-box">
            <h4 class="header-title m-t-0">Wilshire Fund Formation request</h4>
            <p class="text-muted font-14 m-b-20">Please fill the form.</p>

            <form action="#" autocomplete="off" @submit.prevent="save">
              <div class="row">
                <div class="col-sm-6 col-xs-12">

                  <div class="form-group">
                    <label>Requestor</label>
                    <AppUser :userId="legalRequest.requestorId" />
                  </div>
                  <div class="form-group" v-if="legalRequest.assignedTo > 0">
                    <label>Assigned To</label>
                    <AppUser :userId="legalRequest.assignedTo" />
                  </div>

                  <div class="form-group">
                    <label for="deadline">
                      Deadline
                      <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <date-picker value="" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="true" clear-button-icon="fa fa-times" :disabled="!isOwner"
                    format="MM/dd/yyyy" :bootstrap-styling="true" :disabledDates="{to: endDate}" v-model="legalRequest.deadline" @blur="$v.legalRequest.deadline.$touch()" @input="setPickerDate" :input-class="{ 'form-control parsley-error': $v.legalRequest.deadline.$error }"></date-picker>
                    <template v-if="$v.legalRequest.deadline.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.deadline.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="CounterpartyId" class="col-form-label">
                      Firm <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <span :class="{ 'invalid': $v.legalRequest.counterpartyId.$error }">
                      <multiselect id="CounterpartyId" v-model="firmObj" :options="searchFirms" label="firmName" track-by="firmId" :allow-empty="false" placeholder="Type to Search" :disabled="!isOwner"
                        @select="firmSelected" @search-change="asyncSearchFirm" :loading="isSearchingList" :options-limit="50"></multiselect>
                    </span>
                    <template v-if="$v.legalRequest.counterpartyId.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.counterpartyId.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="purpose" class="col-form-label">
                      What is the purpose of this request?
                      <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <input type="text" class="form-control" id="purpose" v-model="legalRequest.purpose" @blur="$v.legalRequest.purpose.$touch()" :class="{ 'parsley-error': $v.legalRequest.purpose.$error }" :disabled="!isOwner">
                    <template v-if="$v.legalRequest.purpose.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.purpose.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.purpose.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.purpose.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="AccountType" class="col-form-label">
                      Account Type
                      <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.accountType.$error }">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio" id="account1" value=1 v-model="legalRequest.accountType" name="account" class="custom-control-input" :disabled="!isOwner">
                        <label class="custom-control-label" for="account1">Seperate</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input
                          type="radio" id="account2" value=2 v-model="legalRequest.accountType" name="account" class="custom-control-input" :disabled="!isOwner">
                        <label class="custom-control-label" for="account2">Commingled</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input
                          type="radio" id="account3" value=3 v-model="legalRequest.accountType" name="account" class="custom-control-input" :disabled="!isOwner">
                        <label class="custom-control-label" for="account3">Both Seperate and Commingled</label>
                      </div>
                    </span>
                  </div>

                  <div class="form-group">
                    <label for="fundType" class="col-form-label">
                      Firm Type <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <span :class="{ 'parsley-error-radio': $v.legalRequest.fundType.$error }">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio" id="type1" value=1 v-model="legalRequest.fundType" name="type" class="custom-control-input" :disabled="!isOwner">
                        <label class="custom-control-label" for="type1">U.S. Fund</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input
                          type="radio" id="type2" value=2 v-model="legalRequest.fundType" name="type" class="custom-control-input" :disabled="!isOwner">
                        <label class="custom-control-label" for="type2">Global</label>
                      </div>
                    </span>
                    <template v-if="$v.legalRequest.fundType.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.fundType.required" class="parsley-required">This value is required</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="Strategy" class="col-form-label">
                      What is the Strategy of the fund?
                      <span class="text-danger" v-if="isOwner">*</span>
                    </label>
                    <input type="text" class="form-control" id="Strategy" v-model="legalRequest.startegy" @blur="$v.legalRequest.startegy.$touch()" :class="{ 'parsley-error': $v.legalRequest.startegy.$error }" :disabled="!isOwner">
                    <template v-if="$v.legalRequest.startegy.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.startegy.required" class="parsley-required">This value is required</li>
                        <li v-if="!$v.legalRequest.startegy.max" class="parsley-required">This value is too long</li>
                        <li v-if="!$v.legalRequest.startegy.min" class="parsley-required">This value is too short</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group">
                    <label for="other" class="col-form-label">
                      Any other relevant information
                    </label>
                    <textarea class="form-control" rows="5" id="other" v-model="legalRequest.comments" @blur="$v.legalRequest.comments.$touch()" :class="{ 'parsley-error': $v.legalRequest.comments.$error }" :disabled="!isOwner"></textarea>
                    <template v-if="$v.legalRequest.comments.$error">
                      <ul class="parsley-errors-list filled">
                        <li v-if="!$v.legalRequest.comments.max" class="parsley-required">This value is too long</li>
                      </ul>
                    </template>
                  </div>

                  <div class="form-group text-right m-b-0">
                    <router-link :to="{name: 'LegalList'}">
                        <button type="button" class="btn btn-custom btn-secondary waves-effect mr-2">
                            Back
                        </button>
                    </router-link>

                    <button class="btn btn-custom waves-effect btn-danger w-md waves-light mr-2" v-if="isOwner && legalRequest.statusId < 4"
                      @click.prevent="withdraw"><i class="mdi mdi-stop"></i> <span>Withdraw</span>
                    </button>

                    <button class="btn btn-custom waves-effect btn-defualt w-md waves-light mr-2" v-if="isOwner && legalRequest.statusId === 4"
                      @click.prevent="resubmit"><i class="mdi mdi-step-forward"></i> <span>Resubmit</span>
                    </button>

                    <button v-if="isOwner && legalRequest.statusId < 4" class="btn btn-custom btn-primary waves-effect waves-light" type="submit">Update Request</button>
                  </div>

                  <div class="form-group mt-3" v-if="isOwner && legalRequest.statusId < 4">
                    <vue-dropzone :options="dropzoneOptions" @vdropzone-success="vsuccess"></vue-dropzone>
                    <p class="text-muted">Max file size: 5MB</p>
                  </div>
                </div>

                <div class="col-lg-6 col-xs-12">
                  <div>
                    <ul class="nav nav-tabs tabs-bordered nav-justified">
                      <li class="nav-item">
                          <a class="nav-link active show">
                              Attachments <span class="badge badge-primary noti-icon-badge" v-if="fileList != null">{{fileList.length}}</span>
                          </a>
                      </li>
                    </ul>
                    <span v-if="isLoadingFiles">Loading <i class="fa fa-spinner fa-spin"></i></span>
                    <FileList :files="fileList" :isEditMode="false" :canEdit="false" :canDelete="isOwner" @onSelect="showFile" />
                  </div>
                  <StatusBar :currentStatusId="legalRequest.statusId" />
                  <a class="btn btn-custom waves-effect btn-primary w-md waves-light" v-if="!isLegal" @click="loademail"
                    :href="'mailto:pmglegal@wilshire.com?'+emailBody"><i class="mdi mdi-email-outline"></i>
                      <span v-if="isLoadingEmail">Loading <i class="fa fa-spinner fa-spin"></i></span>
                      <span v-else>Email Legal</span>
                  </a>
                  <LegalActions v-if="isLegal" :currentStatusId="legalRequest.statusId" @onUpdateStatus="updateStatus" />
                  <LegalAssign :id="legalRequest.requestId" :assignedTo="legalRequest.assignedTo" @onAssignTo='onAssignTo'/>
                  <StatusUpdate :id="legalRequest.requestId" :canAdd="isLegal" />
                </div>

              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="side-bar right-bar">
      <div class="col-sm-12">
        <FileItem v-if="rightbarItem == 'file'" :objectId="id" :objectTypeId="4" :isEditMode="false" :isAdd="false"/>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import {mapActions, mapGetters} from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { LegalService, LegalWffService } from '@/services/legal-service'
import { FileService } from '@/services/file-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import StatusBar from '@/components/legal/TheStatusBar'
import LegalActions from '@/components/legal/TheActionBar'
import LegalAssign from '@/components/legal/LegalAssign'
import vue2Dropzone from 'vue2-dropzone'
import Constants from '@/constants.js'
import FileList from '@/components/FileListAll'
import FileItem from '@/components/FileItem'
import rightbar from '@/mixins/rightbar'
import StatusUpdate from '@/components/legal/TheStatusUpdate'

export default {
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  components: {
    DatePicker,
    Multiselect,
    StatusBar,
    LegalActions,
    FileList,
    FileItem,
    LegalAssign,
    StatusUpdate,
    vueDropzone: vue2Dropzone
  },
  mixins: [asyncDataStatus, rightbar],
  data () {
    return {
      legalRequest: { statusId: 0 },
      endDate: null,
      firmObj: null,
      searchFirms: [],
      isSearchingList: false,
      isLegal: true,
      isLoadingEmail: false,
      authCookie: null,
      isLoadingFiles: false,
      fileList: null,
      rightbarItem: null
    }
  },
  validations: {
    legalRequest: {
      deadline: { required },
      counterpartyId: { required },
      accountType: { required },
      fundType: { required },
      purpose: { required, min: minLength(2), max: maxLength(100) },
      startegy: { required, min: minLength(2), max: maxLength(100) },
      comments: { max: maxLength(500) }
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    isOwner () {
      return this.legalRequest.requestorId === this.user.userId && this.legalRequest.statusId !== 5
    },
    allUsers () {
      return this.$store.state.allUsers
    },
    emailBody () {
      const sbj = '☂ Wilshire Fund Formation request: ' + this.firmObj.firmName
      const lnk = window.location.href
      return 'subject=' + sbj + '&body=Request link: ' + lnk + '%0A%0ALegal team,%0A%0A**PLEASE ATTACH YOUR DOCUMNETS**'
    },
    dropzoneOptions () {
      return {
        url: Constants.API_URL + '/file/upload/dropzone/4/' + this.id + '/6',
        thumbnailWidth: 150,
        maxFilesize: 5,
        dictDefaultMessage: 'Drag and drop or click to select files to upload',
        headers: { 'Authorization': 'Bearer ' + this.authCookie }
      }
    }
  },
  methods: {
    ...mapActions(['fetchFirm', 'searchAllFirms', 'fetchFile']),
    firmSelected (value, id) {
      this.legalRequest.counterpartyId = value ? value.firmId : null
      this.$v.legalRequest.counterpartyId.$touch()
      this.$v.legalRequest.counterpartyId.$reset()
    },
    asyncSearchFirm (query) {
      this.isSearchingList = true
      this.searchAllFirms({query}).then((result) => {
        this.searchFirms = result
        this.isSearchingList = false
      })
    },
    onAssignTo (user) {
      this.legalRequest.assignedTo = user
    },
    getDetails () {
      LegalWffService.get(this.id)
      .then((response) => {
        console.log(response.data)
        this.legalRequest = response.data
        this.fetchFirm({firmId: this.legalRequest.counterpartyId})
        .then((firmResponse) => {
          this.firmObj = firmResponse.data
        })
      })
      .then(() => { this.fetchFileList() })
      .then(() => { this.asyncDataStatus_fetched('Legal Workflow') })
    },
    save () {
      this.$v.legalRequest.$touch()
      if (this.$v.legalRequest.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      this.legalRequest.requestorId = this.user.userId

      LegalWffService.save(this.id, this.legalRequest)
      .then(
        (response) => {
          this.$snotify.success('Request Updated.')
          setTimeout(() => {
            this.$router.push({name: 'LegalList'})
            // this.$router.push({name: 'LegalNDA', params: {id: response.data.requestId}})
          }, 400)
        })
        .catch(function (error) {
          console.error('SERVER Error:', error)
          // this.$snotify.error('SERVER Errors.' + error)
        })
    },
    withdraw () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to withdraw this request?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.updateStatus(4)
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    resubmit () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to resubmit this request?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.updateStatus(1)
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    updateStatus (statusId) {
      this.legalRequest.statusId = statusId
      LegalService.updateStatus(this.id, this.legalRequest)
      .then(
        (response) => {
          this.$snotify.success('Request Status Updated.')
        })
        .catch(function (error) {
          console.error('SERVER Error:', error)
        })
    },
    setPickerDate (value) {
      if (value != null) {
        this.legalRequest.deadline = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0)
      } else {
        this.legalRequest.deadline = null
      }
    },
    loademail () {
      this.isLoadingEmail = true
      setTimeout(() => {
        this.isLoadingEmail = false
      }, 2000)
    },
    fetchFileList () {
      this.isLoadingFiles = true
      FileService.getLegalFiles(this.id).then(
        (response) => {
          this.isLoadingFiles = false
          if (response.data.length > 0) {
            this.fileList = response.data
          } else {
            this.fileList = null
          }
        }
      )
    },
    sidebarAction (item, isOpen) {
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    showFile (id) {
      this.fetchFile({fileId: id})
      this.sidebarAction('file', true)
    },
    vsuccess (file, response) {
      this.fetchFileList()
    }
  },
  created () {
    this.authCookie = localStorage.getItem('auth_token')
    this.endDate = new Date(Date.now())
    this.getDetails()
    this.isLegal = this.user.roles.filter(x => x.roleId === this.$userRoles.LEGAL_TEAM_WORKFLOW).length > 0
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-dropzone/dist/vue2Dropzone.min.css"></style>
