<template>
    <div>
      <ul class="nav nav-tabs tabs-bordered nav-justified">
        <li class="nav-item">
            <a class="nav-link active show">
                Add New User
            </a>
        </li>
      </ul>
      <form action="#" autocomplete="off" class="m-t-10">
        <div class="form-group">
            <label for="firstName">First Name <span class="text-danger">*</span></label>
            <input type="text" v-model.trim="newUser.firstName" @blur="$v.newUser.firstName.$touch()" placeholder="First Name" class="form-control" id="firstName" :class="{ 'parsley-error': $v.newUser.firstName.$error }">
            <template v-if="$v.newUser.firstName.$error">
                <ul class="parsley-errors-list filled">
                <li v-if="!$v.newUser.firstName.required" class="parsley-required">This value is required</li>
                <li v-if="!$v.newUser.firstName.max" class="parsley-required">This value is too long</li>
                </ul>
            </template>
        </div>

        <div class="form-group">
            <label for="lastName">Last Name <span class="text-danger">*</span></label>
            <input type="text" v-model.trim="newUser.lastName" @blur="$v.newUser.lastName.$touch()" placeholder="Last Name" class="form-control" id="lastName" :class="{ 'parsley-error': $v.newUser.lastName.$error }">
            <template v-if="$v.newUser.lastName.$error">
                <ul class="parsley-errors-list filled">
                <li v-if="!$v.newUser.lastName.required" class="parsley-required">This value is required</li>
                <li v-if="!$v.newUser.lastName.max" class="parsley-required">This value is too long</li>
                </ul>
            </template>
        </div>

        <div class="form-group">
            <label for="email" class="col-form-label">Email Address <span class="text-danger">*</span></label>
                <input type="text" v-model.trim="newUser.email" @blur="$v.newUser.email.$touch()" placeholder="Email Address" class="form-control" id="email" :class="{ 'parsley-error': $v.newUser.email.$error }">
                <template v-if="$v.newUser.email.$error">
                <ul class="parsley-errors-list filled">
                    <li v-if="!$v.newUser.email.required" class="parsley-required">This value is required</li>
                    <li v-if="!$v.newUser.email.email" class="parsley-required">This value is not valid.</li>
                </ul>
            </template>
        </div>

        <p>This user will be active and able to login into Navigator</p>

        <div class="form-group m-b-0">
          <button class="btn btn-icon waves-effect waves-light btn-secondary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Cancel</button>
          <button class="btn btn-primary waves-effect waves-light" type="submit" :disabled="inSaving" @click.prevent="save"><i class="mdi mdi-account-plus"></i><i v-if="inSaving" class="fa fa-spinner fa-spin"></i><span v-else> Add</span></button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import rightbar from '@/mixins/rightbar'
  import { required, maxLength, email } from 'vuelidate/lib/validators'
  import { PortalContactService } from '@/services/portal-service'
  
  export default {
    props: {
      firmId: {
        required: true,
        type: Number
      }
    },
    data () {
      return {
        newUser: {},
        inSaving: false
      }
    },
    validations: {
      newUser: {
        firstName: { required, max: maxLength(300) },
        lastName: { required, max: maxLength(300) },
        email: { required, email, max: maxLength(100) }
      }
    },
    mixins: [rightbar],
    methods: {
      close () {
        this.hideRightbar()
      },
      save () {
        this.$v.newUser.$touch()
        if (!this.$v.newUser.$error) {
            this.inSaving = true
            PortalContactService.addByAdmin(this.firmId, this.newUser).then(
              (response) => {
                if (response.status !== 200) {
                  this.$snotify.error('Error!')
                } else {
                  this.close()
                  this.$snotify.info('An email notification will be sent shortly.')
                  this.$snotify.success('User added')
                  this.$emit('onUpdate')
                }
                this.inSaving = false
              }
            ).catch(error => {
              this.$snotify.error('Server Error! ' + error.message)
              this.inSaving = false
            })
        } else {
            this.$snotify.error('Please fix the errors.')
        }
      }
    }
  }
  </script>