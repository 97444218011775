<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portal-invites'"/>

        <div class="content-page">
            <div class="content">
                <div class="container-fluid">
                  <PageTitle :title="'Invites'" :icon="'mdi mdi-web'" />
                    <div>
                        <div v-if='!checkListView' class="row-md-12 ml-2 mr-2">
                            <div class="row card-box">
                                <p>Enter Email List (comma-seprated or in new lines)</p>
                                <textarea v-model='textInput' rows='20' class="form-control" style='resize: none' placeholder='Email list here'/>
                            </div>
                        </div>
                        <div v-if='checkListView && invitePreviewList.length === 0'>
                            <h2>No Email Addresses Entered</h2>
                        </div>
                        <!-- Invite table -->
                        <div v-if='checkListView && invitePreviewList.length > 0'>
                            <h3>Preview Invites</h3>
                            <table class="table w-50">
                                <thead>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Email
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for='(invite, index) in invitePreviewList' :key='index'>
                                      <td class='w-75' :class="{'text-danger': !invite.includes('@') || !invite.includes('.')}">
                                          {{index + 1}}
                                      </td>
                                      <td :class="{'text-danger': !invite.includes('@') || !invite.includes('.')}">
                                          {{invite}}
                                      </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row-md-12 ml-2 mr-2">
                            <div v-if='!checkListView' class="row card-box justify-content-between">
                                <router-link :to="{name: 'PortalInviteList'}" class="btn btn-primary"><i class="mdi mdi-format-list-bulleted"></i> Full Invite List </router-link>
                                <div>
                                    <button class='btn btn-secondary mr-2' @click.prevent='reset'>Reset</button>
                                    <button class='btn btn-primary' @click.prevent='onPreview'><i class="mdi mdi-eye"></i> Preview</button>
                                </div>
                            </div>
                            <div v-else class="row card-box d-flex flex-row-reverse">
                                <button class='btn btn-success ml-2 mr-2' @click.prevent='onSubmit'><i class="mdi mdi-send"></i> Send Invites</button>
                                <button class='btn btn-secondary' @click.prevent='previewToggle'>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import asyncDataStatus from '@/mixins/asyncDataStatus'
import { mapGetters } from 'vuex'
import { PortalInviteService } from '@/services/portal-service'

export default {
  data () {
    return {
      invitePreviewList: [],
      textInput: '',
      checkListView: false,
      activeSearch: false,
      searchTimeout: null
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    reset () {
      this.textInput = ''
    },
    previewToggle () {
      this.checkListView = !this.checkListView
    },
    onPreview () {
      if (this.textInput === '') {
        this.$snotify.error('Please enter an email address to invite')
      } else {
        this.invitePreviewList = this.textInput.split(/[\n,]+/).map(invite => invite.trim())
        this.previewToggle()
      }
    },
    onSubmit () {
      this.sendInvites()
      this.reset()
      this.previewToggle()
    },
    sendInvites () {
      PortalInviteService.sendInvite(this.invitePreviewList).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.$snotify.success('List submited to be sent!')
            }
          }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    hasFilter () {
      return this.searchTerm.length > 0
    }
  },
  created () {
    this.asyncDataStatus_fetched('Navigator Invitation')
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>