<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              {{newItem ? 'New' : 'Update'}} Portfolio Item
          </a>
      </li>
    </ul>

    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
        <label class="col-form-label" for="portfolio-name">Company Name</label>
        <input type="text" id="portfolio-name" v-model="item.portfolioName" class="form-control form-control-sm">
      </div>
      <div class="form-group">
      <label for="inactiveDate">Inv Date</label>
      <date-picker v-model="item.investmentDate" placeholder="Select Date" calendar-class="calendar form-control form-control-sm" input-class="form-control form-control-sm" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
    </div>
      <div class="form-group">
        <label class="col-form-label" for="portfolio-name">Cost</label>
        <number id="cost" class="form-control form-control-sm" v-model.number="item.cost" v-bind="number" :class="{ 'parsley-error': $v.item.cost.$error }" @blur="$v.item.cost.$touch()" ></number>
        <template v-if="$v.item.cost.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.item.cost.decimal" class="parsley-required">This value is not valid.</li>
          </ul>
        </template>
      </div>
      <div class="form-group">
        <label class="col-form-label" for="realized-proceeds">Realized Proceeds</label>
        <number type="text" id="realized-proceeds" v-model.number="item.realizedProceeds" v-bind="number" class="form-control form-control-sm" :class="{ 'parsley-error': $v.item.realizedProceeds.$error }" @blur="$v.item.realizedProceeds.$touch()"></number>
        <template v-if="$v.item.realizedProceeds.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.item.realizedProceeds.decimal" class="parsley-required">This value is not valid.</li>
          </ul>
        </template>
      </div>
      <div class="form-group">
        <label class="col-form-label" for="unrealized-value">Unrealized Value</label>
        <number type="text" id="unrealized-value" v-model.number="item.unrealizedValue" v-bind="number" class="form-control form-control-sm" :class="{ 'parsley-error': $v.item.unrealizedValue.$error }" @blur="$v.item.unrealizedValue.$touch()"></number>
        <template v-if="$v.item.unrealizedValue.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.item.unrealizedValue.decimal" class="parsley-required">This value is not valid.</li>
          </ul>
        </template>
      </div>
      <div class="form-group">
        <label class="col-form-label" for="gross-irr">Gross IRR</label>
        <input type="text" id="gross-irr" v-model.number="item.grossIRR" class="form-control form-control-sm" :class="{ 'parsley-error': $v.item.grossIRR.$error }" @blur="$v.item.grossIRR.$touch()">
        <template v-if="$v.item.grossIRR.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.item.grossIRR.decimal" class="parsley-required">This value is not valid.</li>
          </ul>
        </template>
      </div>
      <div class="form-group">
        <label class="col-form-label" for="gross-roi">Gross ROI</label>
        <input type="text" id="gross-roi" v-model.number="item.grossROI" class="form-control form-control-sm" :class="{ 'parsley-error': $v.item.grossROI.$error }" @blur="$v.item.grossROI.$touch()">
        <template v-if="$v.item.grossROI.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.item.grossROI.decimal" class="parsley-required">This value is not valid.</li>
          </ul>
        </template>
      </div>
      <div class="form-group">
        <label class="col-form-label" for="projected-irr">Projected IRR</label>
        <input type="text" id="projected-irr" v-model.number="item.projectedIRR" class="form-control form-control-sm" :class="{ 'parsley-error': $v.item.projectedIRR.$error }" @blur="$v.item.projectedIRR.$touch()">
        <template v-if="$v.item.projectedIRR.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.item.projectedIRR.decimal" class="parsley-required">This value is not valid.</li>
          </ul>
        </template>
      </div>
      <div class="form-group">
        <label class="col-form-label" for="projected-roi">Projected ROI</label>
        <input type="text" id="projected-roi" v-model.number="item.projectedROI" class="form-control form-control-sm" :class="{ 'parsley-error': $v.item.projectedROI.$error }" @blur="$v.item.projectedROI.$touch()">
        <template v-if="$v.item.projectedROI.$error">
          <ul class="parsley-errors-list filled">
            <li v-if="!$v.item.projectedROI.decimal" class="parsley-required">This value is not valid.</li>
          </ul>
        </template>
      </div>
      <div class="form-group">
      <label for="inactiveDate">Exit Date</label>
      <date-picker v-model="item.exitDate" placeholder="Select Date" calendar-class="calendar form-control form-control-sm" input-class="form-control form-control-sm" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
    </div>
      
      <div v-show="!newItem">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="item.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="item.createdBy" />
          </div>
          <div class="form-group" v-if="item.updatedOn">
              <label>Modified On</label>
              <AppDate :date="item.updatedOn" :show="'input'"/>
          </div>
          <div class="form-group" v-if="item.updatedOn">
              <label>Modified By</label>
              <AppUser :userId="item.updatedBy" :emptyIfNull="true"/>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> {{ newItem ? 'Add' : 'Save'}}</button>
      </div>
    </form>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { FundMonitoringService } from '@/services/fund-service'
import { decimal } from 'vuelidate/lib/validators'
import DatePicker from 'vuejs-datepicker'
import moment from 'moment'
import { component as number } from '@coders-tm/vue-number-format'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    item: {
      required: true,
      type: Object
    }

  },
  mixins: [rightbar],
  data () {
    return {
      showLogs: false,
      number: {
          decimal: '.',
          separator: ',',
          precision: 2,
          masked: false
      }
    }
  },
  components: {
    DatePicker,
    number
  },
  methods: {
    close () {
      this.hideRightbar()
    },
    nullifyFields (obj) {
      // does not mutate original obj. does not work on nested objects
      return JSON.parse(JSON.stringify(obj), (key, value) => {
        return value === '' ? null : value;
      });
    },
    formatDates (obj) {
      if (obj.investmentDate) obj.investmentDate = moment(obj.investmentDate).format('MM/DD/YYYY')
      if (obj.exitDate) obj.exitDate = moment(obj.exitDate).format('MM/DD/YYYY')
    },
    async onSave () {
      if (Object.keys(this.item).length < 4) return 
      this.$v.item.$touch()
      if (this.$v.item.$error) {
        this.$snotify.error('Please fix errors')
        return
      }
      try {
        const sendObj = this.nullifyFields(this.item)
        this.formatDates(sendObj)
        const response = this.newItem ? await FundMonitoringService.addPortfolio(sendObj) : await FundMonitoringService.savePortfolio(sendObj.fundMonitoringPortfolioId, sendObj)
        if (response.status !== 200) throw new Error('')
        this.$emit('onUpdate')
        this.$snotify.success(`Portfolio item ${this.newItem ? 'added' : 'updated'}!`)
        this.close()
      } catch (error) {
        this.$snotify.error('Error updating portfolio data ' + error.message)
      } 
    }
  },
  computed: {
    newItem () {
      return !this.item.fundMonitoringPortfolioId
    },
    calcGrossROI () {
      if ((this.item.unrealizedValue || this.item.realizedProceeds) && this.item.cost) {
        return (this.item.realizedProceeds + this.item.unrealizedValue) / this.item.cost
      } else return null
    },
    calcExitDate () {
      if (this.item.investmentDate && this.item.unrealizedValue) {
        const exitDate = moment(this.item.investmentDate).add(5, 'years').format()
        return exitDate
      } else return null
    }
  },
  watch: {
    calcGrossROI(newVal, oldVal) {
      if (newVal === oldVal) return
      const rounded = Math.round(newVal * 100) / 100
      this.item.grossROI = isNaN(rounded) ? null : rounded
    },
    calcExitDate(newVal, oldVal) {
      if (newVal === oldVal) return
      this.item.exitDate = newVal
    }
  },
  validations: {
    item: {
      cost: {decimal},
      realizedProceeds: {decimal},
      unrealizedValue: {decimal},
      grossIRR: {decimal},
      grossROI: {decimal},
      projectedIRR: {decimal},
      projectedROI: {decimal},
    }
  }
}
</script>
