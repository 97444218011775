<template>
  <div>
    <div class="inbox-widget nicescroll notes-box" style="overflow: auto; outline: none;">
      <div class="inbox-item" v-if="filterdActivities === null">
        <p class="text-center">No Note to show</p>
      </div>
      <div class="inbox-item" v-for="act in filterdActivities" :key="act.activityId">
        <a href="javascript:void(0)" class="inbox-item-img" @click.prevent="select(act.activityId)">
          <AppUserPic :userId="act.createdBy" :size="2" />
        </a>
        <a href="javascript:void(0)" class="inbox-item-author" @click.prevent="select(act.activityId)">
          {{act.activitySubject}}
        </a>
        <div class="inbox-item-text">
          <a href="javascript:void(0)" v-if="act.linkName != null" @click.prevent="select(act.activityId)">
            <i :class="act.objectTypeId === 1 ? 'mdi mdi-square-inc-cash' : 'mdi mdi-factory'"> {{act.linkName}}</i>
          </a>
          <div class="pull-right">
            <button v-if="act.createdBy === user.userId && act.linkName == null && act.activityTypeId > 0" class="btn btn-success btn-sm btn-mini btn-icon mr-2" @click.prevent="update(act.activityId)"><i class="mdi mdi-lead-pencil"></i></button>
            <button v-if="act.createdBy === user.userId && act.linkName == null && act.activityTypeId > 0" class="btn btn-danger btn-sm btn-mini btn-icon mr-2" @click.prevent="onDelete(act.activityId)"><i class="mdi mdi-delete"></i></button>
          </div>
        </div>
        <p class="inbox-item-date">
          <AppDate :date="act.activityDate" :show="'span'"/>
        </p>
      </div>
    </div>
    <button v-if="!isEditMode" class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="addNew"> <i class="mdi mdi-tag-plus"></i> New</button>
    <span v-if="filterdActivities">
      <toggle-button v-if="isShowIndirects" v-model="isOnlyDirect" :sync="true" :width="90" :height="35" :labels="{checked: 'Show All', unchecked: 'Only Direct'}" />
    </span>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    activities: {
      type: Array,
      default: null
    },
    isEditMode: {
      required: true,
      type: Boolean
    },
    isShowIndirects: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isOnlyDirect: true
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['deleteActivity']),
    addNew () {
      this.hideRightbar()
      this.$emit('onClickNew')
    },
    select (id) {
      this.$emit('onSelect', id)
    },
    update (id) {
      this.$emit('onSelectUpdate', id)
    },
    onDelete (id) {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.deleteActivity({activityId: id})
                .then((response) => {
                  this.$snotify.success('Note Deleted.')
                  this.$emit('onUpdate')
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    filterdActivities () {
      if (this.isOnlyDirect || this.activities == null) {
        return this.activities
      } else {
        return this.activities.filter(x => x.linkName == null && x.activityTypeId > 0)
      }
    }
  }
}
</script>
