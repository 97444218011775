<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'ops-comments'"/>
        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Monitoring Comments'" :icon="'mdi mdi-comment-processing-outline'"/>
                    <div class="row mb-2">
                      <div class="col-12 form-inline">
                        <select class="form-control form-control-sm mr-1" v-model="quarter">
                            <option value="0">Select Quarter</option>
                            <option :value="q.qmcQuarter" v-for="q in quarters" :key="q.qmcQuarter">{{q.qmcQuarterName}}</option>
                        </select>
                        <button @click="loadList" class="btn btn-primary btn-sm" :disabled="quarter == 0"> Load</button>
                        <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div class="card-box" v-if="commentList.length > 0">
                          <div class="col-sm-12 col-md-12 form-inline">
                            <button v-for="assign in assignList" :key="assign.assignedTo" type="button" class="btn btn-rounded w-md waves-effect waves-light mr-2 mb-2" @click="selectAssign(assign.assignedTo)" :class="{'btn-primary' : selectedAssign == assign.assignedTo }">
                              {{assign.assignedTo}} ({{assign.cnt}})
                            </button>
                            <button type="button" class="btn btn-rounded waves-effect waves-light btn-success" @click="clearAssign" v-show="selectedAssign != null" v-tooltip="'Clear Assignee'">
                              <i class="mdi mdi-delete"></i>
                            </button>
                          </div>

                          <div class="col-sm-12 col-md-6 mb-2">
                            <div class="input-group col-sm-12 col-md-6">
                              <input type="search" v-model.trim="searchOprCode" class="form-control form-control-mini" placeholder="Type to filter by IFCode" v-tooltip="'Filter by IFCode'">
                              <button type="button" class="btn btn-rounded waves-effect waves-light btn-success" @click="clearOprCode" v-show="searchOprCode.length > 0" v-tooltip="'clear'">
                                <i class="mdi mdi-delete"></i>
                              </button>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-6 mb-2">
                            <div class="input-group col-sm-12 col-md-6">
                              <input type="search" v-model.trim="searchInv" class="form-control form-control-mini" placeholder="Type to filter by Investment" v-tooltip="'Filter by Investment '">
                              <button type="button" class="btn btn-rounded waves-effect waves-light btn-success" @click="clearInv" v-show="searchInv.length > 0" v-tooltip="'clear'">
                                <i class="mdi mdi-delete"></i>
                              </button>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-6 mb-2">
                            <div class="input-group col-sm-12 col-md-6">
                              <input type="search" v-model.trim="searchComment" class="form-control form-control-mini" placeholder="Type to filter by Comment" v-tooltip="'Filter by Comment'">
                              <button type="button" class="btn btn-rounded waves-effect waves-light btn-success" @click="clearComment" v-show="searchComment.length > 0" v-tooltip="'clear'">
                                <i class="mdi mdi-delete"></i>
                              </button>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-6 mb-2">
                            <div class="input-group col-sm-12 col-md-6">
                              <toggle-button v-model="notUpdated" :sync="true" :width="110" :height="35" :labels="{checked: 'Not Updated', unchecked: 'Show All'}" />
                            </div>
                          </div>

                          <span class="text-primary">{{filteredResult.length}}</span> Item found

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div class="card-box table-responsive">
                          <table class="table table-bordered table-hover table-sortable table-striped">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th @click="setOrder(0)">IF Code
                                  <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                  <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                </th>
                                <th @click="setOrder(1)">Investment
                                  <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                  <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                </th>
                                <th @click="setOrder(2)">Region
                                  <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                  <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                </th>
                                <th @click="setOrder(3)">Assigned To
                                  <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                  <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                </th>
                                <th @click="setOrder(4)">
                                    Update <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                    <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                </th>
                                <th @click="setOrder(5)">Commentary
                                  <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                  <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                </th>
                                <th @click="setOrder(6)" v-if="needsMarketComment">Market Commentary
                                  <i v-if="sortIndex == 6 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                  <i v-if="sortIndex == 6 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in filteredResult" :key="item.qMCId">
                                <td>{{index + 1}}</td>
                                <td>{{item.oprCode}}</td>
                                <td>{{item.investment}}</td>
                                <td>{{item.region}}</td>
                                <td>
                                  {{item.assignedTo}}
                                  <button v-if="canAssign" class="btn btn-sm btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="updateAssignee(item)"><i class="mdi mdi-account-edit"></i></button>
                                  <button v-else-if="item.region.toLowerCase() == 'europe' && canAssignEurope" class="btn btn-sm btn-icon waves-effect waves-light btn-primary" @click.prevent="updateAssignee(item)"><i class="mdi mdi-account-edit"></i></button>
                                </td>
                                <td>
                                  <span v-tooltip="formatDate(item.updatedOn)">
                                    <img v-if="item.updatedBy != null" :src="pic(item.updatedBy)" class="img-responsive rounded-circle thumb-sm" alt="user">
                                    <span class="ml-2">{{userName(item.updatedBy)}}</span>
                                  </span>
                                </td>
                                <td>{{item.commentary}}
                                  <button class="btn btn-success btn-sm btn-icon mr-2" @click.prevent="editComment(item)" v-tooltip="'Edit'"><i class="mdi mdi-lead-pencil"></i></button>
                                  <button class="btn btn-primary btn-sm btn-icon" @click.prevent="okComment(item)" v-tooltip="'Approve'"><i class="mdi mdi-check"></i></button>
                                </td>
                                <td v-if="needsMarketComment">{{item.marketCommentary}}
                                  <button class="btn btn-success btn-sm btn-icon mr-2" @click.prevent="editComment(item)" v-tooltip="'Edit'"><i class="mdi mdi-lead-pencil"></i></button>
                                  <button class="btn btn-primary btn-sm btn-icon" @click.prevent="okComment(item)" v-tooltip="'Approve'"><i class="mdi mdi-check"></i></button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <MonitoringCommentItem @onUpdate="onCommentUpdate" v-if="rightbarItem == 'edit-comment'" :comment="selectedComment"/>
              <AssigneeItem @onUpdate="onAssigneeUpdate" v-if="rightbarItem == 'update-assignee'" :list="allAssignee" :comment="selectedComment" />
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import MonitoringCommentItem from '@/components/opsteam/MonitoringCommentItem'
import AssigneeItem from '@/components/opsteam/AssigneeItem'
import { MonitoringCommentService } from '@/services/monitoringcomment-service'
import rightbar from '@/mixins/rightbar'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {
    MonitoringCommentItem,
    AssigneeItem
  },
  data () {
    return {
      quarters: [],
      quarter: 0,
      commentList: [],
      assignList: [],      
      isLoading: false,
      selectedAssign: null,
      sortIndex: 0,
      sortType: 'ASC',
      searchOprCode: '',
      searchInv: '',
      searchComment: '',
      selectedComment: null,
      rightbarItem: null,
      notUpdated: false
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers']),

    selectAssign (a) {
      this.selectedAssign = a
    },
    clearAssign () {
      this.selectedAssign = null
    },
    clearOprCode () {
      this.searchOprCode = ''
    },
    clearComment () {
      this.searchComment = ''
    },
    clearInv () {
      this.searchInv = ''
    },
    loadList () {
      this.isLoading = true      
      MonitoringCommentService.get(this.quarter).then(
        (response) => {
          this.commentList = response.data.commentList
          this.assignList =  response.data.assignList
          this.isLoading = false
        }
      )
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
    },
    editComment (item) {
      this.rightbarItem = 'edit-comment'
      this.selectedComment = {...item, hasMarketCommentary: item.marketCommentary && item.marketCommentary.length > 0}
      this.showRightbar()
    },
    okComment (okItem) {
      MonitoringCommentService.approve(okItem.qmcId)
        .then((response) => {
          if (response == null) {
            this.$snotify.error('Server Error!')
          } else {
            const upd = this.filteredResult.filter(x => x.qmcId === okItem.qmcId)
            if (upd.length > 0) {
              upd[0].updatedBy = this.user.userId
              upd[0].updatedOn = new Date()
              this.$snotify.success('Updated.')
            }
          }
        })
    },
    onAssigneeUpdate (updatedItem) {
      const upd = this.filteredResult.filter(x => x.qmcId === updatedItem.qmcId)
      if (upd.length > 0) {
        upd[0].assignedTo = updatedItem.assignedTo
        this.hideRightbar()
      }
    },
    onCommentUpdate (updatedItem) {
      const upd = this.filteredResult.filter(x => x.qmcId === updatedItem.qmcId)
      if (upd.length > 0) {
        upd[0].commentary = updatedItem.commentary
        upd[0].marketCommentary = updatedItem.marketCommentary
        upd[0].updatedBy = this.user.userId
        upd[0].updatedOn = new Date()
        this.hideRightbar()
      }
    },
    pic (userId) {
      const usr = this.allUsers.find(e => e.userId === userId)

      try {
        if (usr != null) {
          return require(`/static/img/users/${usr.userName.toLowerCase()}.jpg`)
        }
      } catch  {
        return require(`/static/img/users/user.jpg`)
      }

      
      return require(`/static/img/users/user.jpg`)
    },
    userName (userId) {
      let result = ''
      const usr = this.allUsers.find(e => e.userId === userId)
      if (this.userId === 0) {
        return 'System'
      }
      if (usr != null) {
        return usr.firstName + ' ' + usr.lastName
      }
      return result
    },
    formatDate (tm) {
      return moment(tm).format('MMM D YYYY - h:mm A')
    },
    updateAssignee (item) {
      this.rightbarItem = 'update-assignee'
      this.selectedComment = {...item}
      this.showRightbar()
    }
  },
  computed: {
    ...mapGetters({
      user: 'authUser'
    }),
    allUsers () {
      return this.$store.state.allUsers
    },
    allAssignee () {
      const assigneeList = this.$store.state.allUsers.map(x=> ({
        assignedTo: x.firstName + ' ' + x.lastName
      }))
      return assigneeList
    },
    filteredResult () {
      let result = this.commentList

      // selectedAssign
      if (this.selectedAssign != null) {
        const name = this.selectedAssign.toLowerCase()
        result = result.filter(function (item) {
          return item.assignedTo.toLowerCase() === name
        })
      }

      // OprCode
      if (this.searchOprCode.length > 0) {
        const ifCode = this.searchOprCode.toLowerCase()
        result = result.filter(function (item) {
          return item.oprCode.toLowerCase().indexOf(ifCode) >= 0
        })
      }

      // Investment
      if (this.searchInv.length > 0) {
        const inv = this.searchInv.toLowerCase()
        result = result.filter(function (item) {
          return item.investment.toLowerCase().indexOf(inv) >= 0
        })
      }

      // Comment
      if (this.searchComment.length > 0) {
        const comm = this.searchComment.toLowerCase()
        result = result.filter(function (item) {
          return item.commentary.toLowerCase().indexOf(comm) >= 0
        })
      }

      // notUpdated
      if (this.notUpdated) {
        result = result.filter(function (item) {
          return item.updatedBy === null
        })
      }

      // Sort
      switch (this.sortIndex) {
        case 0:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.oprCode > b.oprCode) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.oprCode < b.oprCode) ? 1 : -1)
          }
          break
        case 1:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.investment > b.investment) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.investment < b.investment) ? 1 : -1)
          }
          break
        case 2:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.region > b.region) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.region < b.region) ? 1 : -1)
          }
          break
        case 3:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.assignedTo > b.assignedTo) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.assignedTo < b.assignedTo) ? 1 : -1)
          }
          break
        case 4:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.updatedBy > b.updatedBy) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.updatedBy < b.updatedBy) ? 1 : -1)
          }
          break
        case 5:
          if (this.sortType === 'ASC') {
            result.sort((a, b) => (a.commentary > b.commentary) ? 1 : -1)
          } else {
            result.sort((a, b) => (a.commentary < b.commentary) ? 1 : -1)
          }
          break
      }
      return result
    },
    canAssign () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR || x.roleId === this.$userRoles.MONITORING_COMMENTS_ASSIGNMENT).length > 0
    },
    canAssignEurope () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.MONITORING_COMMENTS_ASSIGNMENT_EUROPE).length > 0
    },
    needsMarketComment () {
      return this.commentList.filter(x => x.marketCommentary).length > 0
    }
  },
  mixins: [rightbar, asyncDataStatus],
  created () {
    this.fetchAllUsers()
    MonitoringCommentService.getList().then(
      (response) => {
        this.asyncDataStatus_fetched('Monitoring Comments')
        if (response.data.length > 0) {
          this.quarters = response.data
        }
      }
    )
  }
}
</script>
