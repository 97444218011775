import Constants from '../constants.js'
import Axios from 'axios'

export const IcMeetingService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/icmeeting/' + id)
  },
  search (year, month, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/icmeeting/search?year=' + year + '&month=' + month + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting/' + id, value)
  },
  add (icmeeting) {
    return Axios.post(Constants.API_URL + '/icmeeting', icmeeting)
  },
  delete (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting/delete/' + id, value)
  },
  approve (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting/approve/' + id, value)
  },
  complete (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting/complete/' + id, value)
  },
  getByFundId (id) {
    return Axios.get(Constants.API_URL + '/icmeeting/fund/' + id)
  }
}

export const IcMeetingPmIcService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/icmeeting-pmic/' + id)
  },
  search (year, month, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/icmeeting-pmic/search?year=' + year + '&month=' + month + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting-pmic/' + id, value)
  },
  add (icmeeting) {
    return Axios.post(Constants.API_URL + '/icmeeting-pmic', icmeeting)
  },
  delete (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting-pmic/delete/' + id, value)
  },
  approve (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting-pmic/approve/' + id, value)
  },
  complete (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting-pmic/complete/' + id, value)
  }
}

export const IcMeetingMaMrService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/icmeeting-mamr/' + id)
  },
  search (year, month, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/icmeeting-mamr/search?year=' + year + '&month=' + month + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting-mamr/' + id, value)
  },
  add (icmeeting) {
    return Axios.post(Constants.API_URL + '/icmeeting-mamr', icmeeting)
  },
  delete (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting-mamr/delete/' + id, value)
  },
  approve (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting-mamr/approve/' + id, value)
  },
  complete (id, value) {
    return Axios.put(Constants.API_URL + '/icmeeting-mamr/complete/' + id, value)
  }
}
