<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'portfolio-summary'"/>
        <div style="min-height: 500px" class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Portfolio Summary'" :icon="'mdi mdi-briefcase'" />
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box">
                              <h4 class="m-t-0 header-title mb-4">Portfolio Summary</h4>
                              <div class="form-row">
                                <div class="col-md-3 form-group">
                                  <label for="year">Vintage <span class="text-danger">*</span></label>
                                  <date-picker id="year" placeholder="Select Year" calendar-class="calendar form-control" :typeable="false" clear-button-icon="fa fa-times"
                                    format="yyyy" minimum-view="year" :bootstrap-styling="true" v-model="form.selectedYear"></date-picker>
                                  <template v-if="$v.form.selectedYear.$error">
                                    <ul class="parsley-errors-list filled">
                                      <li v-if="!$v.form.selectedYear.required" class="parsley-required">This value is required</li>
                                    </ul>
                                  </template>
                                </div>
                                <div class="col-md-3 form-group">
                                  <label for="type">Segment</label>
                                  <select id="type" v-model="form.selectedTypeId" class="form-control custom-select" :class="{ 'parsley-error': $v.form.selectedTypeId.$error }">
                                    <option value="1">Advisory</option>
                                    <option value="2">Discretionary</option>
                                    <option value="0">Both</option>
                                </select>
                                  <template v-if="$v.form.selectedTypeId.$error">
                                    <ul class="parsley-errors-list filled">
                                      <li v-if="!$v.form.selectedTypeId.required" class="parsley-required">This value is required</li>
                                    </ul>
                                </template>
                                </div>
                              </div>
                              <div class="form-group">
                                <button class="btn btn-primary waves-effect w-md waves-light" @click.prevent="showReport" :disabled="isLoading"> 
                                  <span v-if="!isLoading">Submit </span>
                                  <span v-else>Loading <i class="fa fa-spinner fa-spin"></i></span>                                          
                                </button>
                              </div>
                            </div>
                            <div v-if="yearEmpty" class="card-box">
                              <p style="margin-bottom: 0">No data found for {{yearEmpty | yearFormat}}</p>
                            </div>
                            <div v-else-if="list && list.length" class="card-box table-responsive">
                              <table class="table table-bordered table-hover table-sortable">
                                <thead>
                                  <tr>
                                    <td><strong>Sector</strong></td>
                                    <td v-if="list.some(obj => obj.geographicId === 6)"><strong>North America</strong></td>
                                    <td v-if="list.some(obj => obj.geographicId === 3)"><strong>Europe</strong></td>
                                    <td v-if="list.some(obj => obj.geographicId === 2)"><strong>Asia Pacific</strong></td>
                                    <td v-if="list.some(obj => obj.geographicId === 4)"><strong>Global</strong></td>
                                    <td v-if="list.some(obj => obj.geographicId === 1)"><strong>Africa</strong></td>
                                    <td v-if="list.some(obj => obj.geographicId === 5)"><strong>Latin America</strong></td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(sector, index) in sectorGroups" :key="index">
                                    <td>{{index}}</td>
                                    <td v-if="list.some(obj => obj.geographicId === 6)"><div v-html="sector.map(obj => obj.geographicId === 6 ? obj.content : null).filter(item => item !== null).join(' ')"></div></td>
                                    <td v-if="list.some(obj => obj.geographicId === 3)"><div v-html="sector.map(obj => obj.geographicId === 3 ? obj.content : null).filter(item => item !== null).join(' ')"></div></td>
                                    <td v-if="list.some(obj => obj.geographicId === 2)"><div v-html="sector.map(obj => obj.geographicId === 2 ? obj.content : null).filter(item => item !== null).join(' ')"></div></td>
                                    <td v-if="list.some(obj => obj.geographicId === 4)"><div v-html="sector.map(obj => obj.geographicId === 4 ? obj.content : null).filter(item => item !== null).join(' ')"></div></td>
                                    <td v-if="list.some(obj => obj.geographicId === 1)"><div v-html="sector.map(obj => obj.geographicId === 1 ? obj.content : null).filter(item => item !== null).join(' ')"></div></td>
                                    <td v-if="list.some(obj => obj.geographicId === 5)"><div v-html="sector.map(obj => obj.geographicId === 5 ? obj.content : null).filter(item => item !== null).join(' ')"></div></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { required } from 'vuelidate/lib/validators'
import { PortfolioService } from '@/services/portfolio-service'
import DatePicker from 'vuejs-datepicker'
import moment from 'moment'

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

export default {
  components: {
    DatePicker,
    moment
  },
  data () {
    return {
      form: {
        selectedYear: null,
        selectedTypeId: null
      },
      list: null,
      yearEmpty: null,
      isLoading: false
    }
  },
  validations: {
    form: {
      selectedYear: { required },
      selectedTypeId: { required }
    }
  },
  methods: {
    showReport () {
      this.yearEmpty = null
      this.$v.form.$touch()
      if (this.$v.form.$error) {
        return
      }

      this.isLoading = true
      const formattedYear = this.formatDate(this.form.selectedYear)
      PortfolioService.reportSummary(this.form.selectedTypeId, formattedYear)
      .then((response) => {
        this.isLoading = false
        this.list = response.data
        if (!this.list || !this.list.length) {
          this.yearEmpty = this.form.selectedYear
        }
      }).catch((err) => {
        this.yearEmpty = this.form.selectedYear
        this.isLoading = false
      })
    },
    formatDate (date) {
      if (!date) return
      else return moment(date).format('YYYY')
    }
  },
  filters: {
    yearFormat (date) {
      return moment(date).format('YYYY')
    }
  },
  computed: {
    contentArray () {
      return this.list.map(obj => {
        return { sectorName: obj.sectorName, geographicId: obj.geographicId, geographicName: obj.geographicName, content: `<strong>${obj.typeName}:</strong> ${obj.cnt} (${obj.symbol}${obj.total})<br/>` }
      })
    },
    sectorGroups () {
      const groupBySector = groupBy('sectorName')
      return groupBySector(this.contentArray)
    }
  },
  mixins: [asyncDataStatus],
  created () {
    this.asyncDataStatus_fetched('Portfolio Summary')
  }
}
</script>
