<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Assignment
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="filledPosition">User <span class="text-danger">*</span></label>
          <multiselect id="assignedTo" v-model="selectedUser" :options="list" label="assignedTo" track-by="assignedTo"></multiselect>
          <template v-if="$v.comment.assignedTo.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.comment.assignedTo.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>
      
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { required } from 'vuelidate/lib/validators'
import { MonitoringCommentService } from '@/services/monitoringcomment-service'
import Multiselect from 'vue-multiselect'

export default {
  props: {
    comment: {
      required: true,
      type: Object
    },
    list: {
      required: true,
      type: Array
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      selectedUser: null
    }
  },
  mixins: [rightbar],
  validations: {
    comment: {
      assignedTo: { required }
    }
  },
  methods: {
    close () {
      this.$v.comment.$reset()
      this.hideRightbar()
    },
    onSave () {
      this.$v.comment.$touch()
      if (!this.$v.comment.$error) {
        const upd = { qmcId: this.comment.qmcId, assignedTo: this.selectedUser.assignedTo }
        MonitoringCommentService.updateAssinee(this.comment.qmcId, upd)
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Updated.')
              this.$emit('onUpdate', upd)
              this.selectedUser = null
            }
          })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>