<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'meeting-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Meeting Minutes'" :icon="'mdi mdi-file-document'"/>
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box table-responsive">
                                <h4 class="m-t-0 header-title mb-4">Private Markets Manager Research Committee - List</h4>                                
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-6 form-inline">
                                      <select class="form-control form-control-sm" v-model="year" @change="startSearch">
                                          <option value="0">Year</option>
                                          <option :value="year" v-for="year in yearsList" :key="year">{{year}}</option>
                                      </select>
                                      <select class="form-control form-control-sm ml-2" v-model="month" @change="startSearch">
                                          <option value="0">Month</option>
                                          <option value="1">1-Jan</option>
                                          <option value="2">2-Feb</option>
                                          <option value="3">3-Mar</option>
                                          <option value="4">4-Apr</option>
                                          <option value="5">5-May</option>
                                          <option value="6">6-Jun</option>
                                          <option value="7">7-Jul</option>
                                          <option value="8">8-Aug</option>
                                          <option value="9">9-Sep</option>
                                          <option value="10">10-Oct</option>
                                          <option value="11">11-Nov</option>
                                          <option value="12">12-Dec</option>
                                      </select>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div>
                                          <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                            <!-- <label>
                                                Search:
                                                <input type="search" v-model="searchName" class="form-control" placeholder="" @keyup.enter="startSearch">
                                            </label>
                                            <button @click="startSearch" class="btn btn-icon waves-effect waves-light btn-primary m-b-5"> <i class="fa fa-search"></i> </button> -->
                                        </div>
                                    </div>
                                </div>

                                <table class="table table-bordered table-hover table-sortable table-striped">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)"> Meeting Date
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)"> Status
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(2)"> # FLR
                                          <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(3)"> # RR
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(4)"> # Members
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <!-- <th @click="setOrder(5)"> # DD Update
                                          <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th> -->
                                        <th @click="setOrder(6)"> # Misc
                                          <i v-if="sortIndex == 6 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 6 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <!-- <th @click="setOrder(7)"> Created On
                                          <i v-if="sortIndex == 7 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 7 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th> -->
                                    </tr>
                                    </thead>
                         
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="item in meetings" :key="item.meetingId">
                                          <td>
                                               <router-link :to="{name: 'IcMeetingMrcPM', params: {id: item.meetingId}}">
                                                   <AppDate :date="item.meetingDate" :show="'text'"/>
                                               </router-link>
                                          </td>
                                          <td> 
                                            <span v-if="item.approvedOn">
                                              Approved on <AppDate :date="item.approvedOn" :show="'text'"/> by {{ item.approvedByName }}
                                            </span>
                                            <span v-else-if="item.completedOn">
                                              Completed on <AppDate :date="item.completedOn" :show="'text'"/> by {{ item.completedByName }} and waiting for Approval
                                            </span>
                                            <span v-else>Draft</span>
                                          </td>
                                          <td>
                                            <span v-if="item.flrCnt > 0">
                                              {{item.flrCnt}}
                                            </span>
                                          </td>
                                          <td>
                                            <span v-if="item.rrCnt > 0">
                                              {{item.rrCnt}}
                                            </span>
                                          </td>
                                          <td>
                                            <span v-if="item.memberCnt > 0">
                                              {{item.memberCnt}}
                                            </span>
                                          </td>
                                          <!-- <td>
                                            <span v-if="item.ddUpdateCnt > 0">
                                              {{item.ddUpdateCnt}}
                                            </span>
                                          </td> -->
                                          <td>
                                            <span v-if="item.misCnt > 0">
                                              {{item.misCnt}}
                                            </span>
                                          </td>
                                          <!-- <td> <AppDate :date="item.createdOn" :show="'text'" /> </td> -->
                                      </tr>
                                    </tbody>
                                </table>
                                
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div role="status">Page {{currentPage}} of {{totalPages}} - {{totalRecords}} meetings</div>
                                    <div class="px-3">
                                          <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                              <option value="250">250</option>
                                          </select>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- end container -->
            </div>
            <!-- end content -->

            <Footer />
        </div>
    </div>
</template>

<script>
import { IcMeetingService } from '@/services/icmeeting-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
  },
  data () {
    return {
      meetings: null,
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      year: 0,
      month: 0,
      sortBy: ['MeetingDate', 'approvedOn', 'FlrCnt', 'RrCnt', 'MemberCnt', 'DDUpdateCnt', 'MisCnt', 'CreatedOn'],
      sortIndex: 0,
      sortType: 'DESC',
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0,
      yearsList: []
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    getList () {
      const filters = {
        'year': this.year,
        'month': this.month,
        'pageSize': this.pageSize
      }
      
      localStorage.setItem('umb_icmeetings_filters', JSON.stringify(filters))
      this.isLoading = true
      IcMeetingService.search(this.year, this.month, this.sortBy[this.sortIndex], this.sortType, this.currentPage, this.pageSize)
      .then((response) => {
        this.isLoading = false
        this.meetings = response.data.icMeetings
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Private Markets Manager Research Committee List') })
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.meetings = []

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    resetFilter () {
      const dt = new Date()
      this.year = dt.getFullYear()
      this.month = dt.getMonth() + 1
      this.startSearch()
    }
  },
  mounted () {
    const maxYear = new Date().getFullYear()
    for (let y = maxYear; y >= 2018; y--) {
      this.yearsList.push(y)
    }
    const oldFilters = JSON.parse(localStorage.getItem('umb_icmeetings_filters'))
    if (oldFilters != null) {
      if (oldFilters.pageSize != null) {
        this.year = oldFilters.year
      }
      if (oldFilters.pageSize != null) {
        this.month = oldFilters.month
      }
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
    }
    this.startSearch()
  },
  computed: {
    hasFilter () {
      const dt = new Date()
      return parseInt(this.year) !== dt.getFullYear() || parseInt(this.month) !== dt.getMonth() + 1
    }
  }
}
</script>
