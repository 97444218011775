<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Deal Team
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="fund.Employee">Employee<span v-show="isAdd" class="text-danger">*</span></label>
          <multiselect id="fund.Employee" v-show="!isEditMode" :disabled="!isEditMode" v-model="userObj" :options="allUsers" label="fullName" track-by="userId"></multiselect>
          <multiselect id="fund.Employee" v-show="isEditMode" :disabled="!isAdd" v-model="userObj2" :options="allActiveUsers" label="fullName" track-by="userId" :allow-empty="false" @input="userSelected"></multiselect>
      </div>
      <div class="form-group">
          <label for="fund.Role">Role<span v-show="isEditMode" class="text-danger">*</span></label>
          <multiselect id="fund.Role" v-model="roleObj" :disabled="!isEditMode" :options="allTeamRoles" label="fundTeamRoleName" track-by="fundTeamRoleId" :allow-empty="false" @input="roleSelected"></multiselect>
      </div>

      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="member.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="member.createdBy" />
          </div>
          <div class="form-group" v-if="member.updatedOn">
              <label>Modified On</label>
              <AppDate :date="member.updatedOn" :show="'input'"/>
          </div>
          <div class="form-group" v-if="member.updatedOn">
              <label>Modified By</label>
              <AppUser :userId="member.updatedBy" :emptyIfNull="true"/>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light  btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success  waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</button>
      </div>
    </form>
    <div v-if="isEditMode">
      <h5>Deal Workload <i class="mdi mdi-information-outline" v-tooltip="'Number of active Deals per member'"></i></h5>
      <span v-show="isLoadingStat">Loading <i class="fa fa-spinner fa-spin"></i></span>
      <div class="progress progress-md m-b-20" v-for="(stat, index) in activeStatList" :key="index">
        <div class="progress-bar" role="progressbar" :style="{ width: stat.cnt + '%' }">{{stat.userName}} ({{stat.stat}})</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { FundTeamService } from '@/services/fund-service'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      showLogs: false,
      userObj2: null,
      roleObj2: null,
      isLoadingStat: false,
      activeStatList: null,
      mustLoadstat: true
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['fetchAllUsers', 'fetchAllFundteamRoles', 'addFundTeam', 'updateFundTeam']),
    close () {
      this.hideRightbar()
    },
    userSelected (value, id) {
      this.member.userId = value ? value.userId : null
    },
    roleSelected (value, id) {
      this.member.fundTeamRoleId = value ? value.fundTeamRoleId : null
    },
    getActiveStat () {
      if (this.mustLoadstat) {
        this.isLoadingStat = true
        FundTeamService.getActiveStat()
          .then((response) => {
            this.isLoadingStat = false
            const list = response.data
            this.activeStatList = []
            const mx = Math.max(...list.map(x => x.stat))
            for (let i = 0; i < list.length; i++) {
              this.activeStatList.push({userName: list[i].userFullName, cnt: list[i].stat * 100 / mx, stat: list[i].stat})
            }
          })
      }
    },
    onSave () {
      if (this.isAdd) {
        this.addFundTeam({fundTeam: this.member})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Member Added.')
              this.$emit('onUpdate')
            }
          })
      } else {
        this.updateFundTeam({fundTeamId: this.member.fundTeamId, fundTeam: this.member})
          .then((response) => {
            this.$snotify.success('Member Updated.')
            this.$emit('onUpdate')
          })
      }
      this.getActiveStat()
    }
  },
  created () {
    this.fetchAllUsers()
    this.fetchAllFundteamRoles()
    this.getActiveStat()
  },
  computed: {
    member () {
      return this.$store.state.rb_fundTeam
    },
    allUsers () {
      return this.$store.state.allUsers.filter(x => x.userId > 0)
    },
    allActiveUsers () {
      return this.allUsers.filter(u => u.isActive)
    },
    userObj () {
      if (this.member != null) {
        const user = this.allUsers.filter(t => t.userId === this.member.userId)
        this.userObj2 = user
        return user
      } else {
        return null
      }
    },
    allTeamRoles () {
      return this.$store.state.allFundTeamRoles
    },
    roleObj () {
      if (this.member != null) {
        return this.allTeamRoles.filter(t => t.fundTeamRoleId === this.member.fundTeamRoleId)
      } else {
        return null
      }
    }
  }
}
</script>
