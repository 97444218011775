import Constants from '../constants.js'
import Axios from 'axios'

export const FileService = {
  upload (objTypeId, objId, purposeId, formData) {
    return Axios.post(Constants.API_URL + '/file/upload/' + objTypeId + '/' + objId + '/' + purposeId, formData)
  },
  download (id) {
    return Axios.get(Constants.API_URL + '/file/download/' + id, {responseType: 'blob'})
  },
  // downloadLast (objectId, objectTypeId, purposeId) {
  //   return Axios.get(Constants.API_URL + '/file/download-last/' + objectId + '/' + objectTypeId + '/' + purposeId, {responseType: 'blob'})
  // },
  // addByObject (objectId, value) {
  //   return Axios.post(Constants.API_URL + '/file/' + objectId, value)
  // },
  get (id) {
    return Axios.get(Constants.API_URL + '/file/' + id)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/file/' + id, value)
  },
  getFundFiles (fundId) {
    return Axios.get(Constants.API_URL + '/file/fund/' + fundId)
  },
  getLegalFiles (requestId) {
    return Axios.get(Constants.API_URL + '/file/legal/' + requestId)
  },
  getOpsRequestFiles (requestId) {
    return Axios.get(Constants.API_URL + '/file/opsteam/' + requestId)
  },
  getPortfolioFiles (portfolioId) {
    return Axios.get(Constants.API_URL + '/file/portfolio/' + portfolioId)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/file/' + id)
  }
}

export const FilePurposeService = {
  getAll () {
    return Axios.get(Constants.API_URL + '/filepurpose')
  }
}
