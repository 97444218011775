<template>
  <tbody>
    <tr v-if="list === null">
      <td class="text-center" colspan="2">No Data to show</td>
    </tr>
    <tr v-for="item in list"
        :key="item.userId">
        <td>
          {{item.firstName}}
          {{item.lastName}}
        </td>
        <td>{{item.jobTitle}}</td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: null
    }
  }
}
</script>
