import Constants from '../constants.js'
import axios from 'axios'

export const AuthService = {
  login (userName, password) {
    return axios.post(Constants.API_URL + '/signin/', {userName, password})
  },
  authenticate () {
    return axios.post(Constants.API_URL + '/signin/authenticate')
  },
  loginWithToken (userId, userName, token) {
    return axios.post(Constants.API_URL + '/signin/token', { 'userId': userId, 'userName': userName, 'token': token })
  }
}
