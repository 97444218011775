<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'meeting-list-icpm'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="meeting">
                    <MeetingToolbar :title="'Meeting Details - ' + meetingDate"
                      :inEditMode="inEditMode" :canDelete="canDelete && meeting.completedOn == null" :canApprove="canApprove && meeting.completedOn != null" :canComplete="canDelete && meeting.completedOn == null" :canEdit="canDelete" :isSaving="inSaving"
                      @onEdit="editForm"
                      @onCancel="cancelForm"
                      @onDelete="deleteForm"
                      @onSave="saveForm" 
                      @onComplete="completeForm"
                      @onApprove="approveForm" />
                    <div class="card-box">
                      <div class="row">
                        <div class="col-lg-6">
                          <form action="#" autocomplete="off" class="icmeeting">
                            <div class="form-group">
                              <label for="meetingDate">
                                Meeting Date
                              </label>
                              <date-picker id="meetingDate" disabled="disabled" format="MM/dd/yyyy" :bootstrap-styling="true" v-model="meeting.meetingDate"></date-picker>
                            </div>

                            <div v-if="meeting.approvedOn">
                              <div class="form-group">
                                <label>
                                  Approved By
                                </label>
                                <AppUser :userId="meeting.approvedBy" :show="'text'" :showpic="true"/>
                              </div>

                              <div class="form-group">
                                <label>
                                  Approved On
                                </label>
                                <AppDate :date="meeting.approvedOn" :emptyIfNull="true"/>
                              </div>
                            </div>

                            <div class="form-group">
                                <label class="text-primary">Present Committee Members</label>
                                <ol>
                                  <li v-for="ic in meeting.members" :key="ic.meetingMemberId">
                                    <AppUser :userId="ic.memberId" :show="'text'" :showpic="true"/>
                                  </li>
                                </ol>
                            </div>

                            <div class="form-group" v-if="meeting.minutes && meeting.minutes.length > 0">
                                <label class="text-primary">Allocation Recommendation</label>
                                <ul>
                                  <li v-for="rr in meeting.minutes" :key="rr.minuteId">
                                    <button type="button" class="btn btn-rounded w-md waves-effect waves-light m-b-5 mr-2" @click="selectMinute(rr.minuteId)" :class="{'btn-primary' : selectedMinute != null && selectedMinute.minuteId == rr.minuteId }">
                                      {{rr.fundName}}
                                    </button>
                                    <router-link :to="{name: 'FundsDetail', params: {id: rr.fundId}}" target="_blank" v-tooltip="'see deal details'">
                                        <i class="mdi mdi-open-in-new"></i>
                                    </router-link>
                                  </li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label for="meetingNotes">Meeting Notes</label>
                                <vue-editor id="meetingNotes" v-model="meeting.meetingNotes" :editor-toolbar="customToolbar" :disabled="!inEditMode"/>
                            </div>

                          </form>

                          <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                          <div :class="{'collapse': !showLogs }">
                              <div class="form-group">
                                  <label>Created On</label>
                                  <AppDate :date="meeting.createdOn"/>
                              </div>
                              <div class="form-group">
                                  <label>Created By</label>
                                  <AppUser :userId="meeting.createdBy" />
                              </div>
                              <div class="form-group" v-if="meeting.updatedOn">
                                  <label>Modified On</label>
                                  <AppDate :date="meeting.updatedOn" :emptyIfNull="true"/>
                              </div>
                              <div class="form-group" v-if="meeting.updatedOn">
                                  <label>Modified By</label>
                                  <AppUser :userId="meeting.updatedBy" :emptyIfNull="true"/>
                              </div>
                          </div>

                        </div>

                        <div class="col-lg-6" v-if="selectedMinute">
                            <div class="form-group">
                              <div>
                                <label>Deal Team:</label> {{selectedMinute.dealTeam}}
                              </div>
                              <div>
                                <label>Target Size / Hard Cap:</label> ({{selectedMinute.currency}}) {{selectedMinute.targetSize}} / {{selectedMinute.hardCap > 0 ? selectedMinute.hardCap : 'NA'}}
                              </div>
                              <div>
                                <label>Geographic Focus:</label> {{selectedMinute.geographicFocus}}
                              </div>
                              <div>
                                  <label>Sector:</label> {{selectedMinute.sector}}
                              </div>
                              <div>
                                  <h4 class="pt-1">Committee Voting Summary</h4>
                                  <table class="table table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <th>
                                          Committee Member
                                        </th>
                                        <th>
                                          Vote
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(vote, index) in votings" :key="index">
                                        <td>
                                          <AppUser :userId="vote.userId" :show="'text'" :showpic="true"/>
                                        </td>
                                        <td>
                                          {{vote.score == 1 ? 'Yes' : 'No'}}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                              </div>
                            </div>
                            <div class="form-group">
                                <label for="summary">Summary of Allocation Recommendation</label>
                                <vue-editor id="summary" v-model="selectedMinute.summary" :editor-toolbar="customToolbar" :disabled="!inEditMode"/>
                            </div>

                            <div class="form-group">
                                <label for="ChangesActions">Proposed Changes to Allocation Recommendation</label>
                                <vue-editor id="ChangesActions" v-model="selectedMinute.changesActions" :editor-toolbar="customToolbar" :disabled="!inEditMode"/>
                            </div>
                            <div class="form-group">
                                <label for="ApprovalContingencies">Approval Contingencies</label>
                                <vue-editor id="ApprovalContingencies" v-model="selectedMinute.approvalContingencies" :editor-toolbar="customToolbar" :disabled="!inEditMode"/>
                            </div>
                            <div class="form-group">
                                <label for="ApprovalGranted">Approval(s) Granted</label>
                                <vue-editor id="ApprovalGranted" v-model="selectedMinute.approvalGranted" :editor-toolbar="customToolbar" :disabled="!inEditMode"/>
                            </div>

                            <toggle-button v-model="showLogsMi" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                            <div :class="{'collapse': !showLogsMi }">
                                <div class="form-group" v-if="selectedMinute.updatedOn">
                                    <label>Modified On</label>
                                    <AppDate :date="selectedMinute.updatedOn" :emptyIfNull="true"/>
                                </div>
                                <div class="form-group" v-if="selectedMinute.updatedOn">
                                    <label>Modified By</label>
                                    <AppUser :userId="selectedMinute.updatedBy" :emptyIfNull="true"/>
                                </div>
                            </div>
                        </div>

                      </div>
                    </div>

                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MeetingToolbar from '@/components/icmeeting/MeetingToolbar'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import DatePicker from 'vuejs-datepicker'
import { required } from 'vuelidate/lib/validators'
import { IcMeetingPmIcService } from '@/services/icmeeting-service'
import { VueEditor } from 'vue2-editor'
import moment from 'moment'

export default {
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  components: {
    DatePicker,
    VueEditor,
    MeetingToolbar
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      meeting: {meetingDate: null},
      showLogs: false,
      showLogsMi: false,
      inEditMode: false,
      inSaving: false,
      selectedMinute: null,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },
  validations: {
    meeting: {
      meetingDate: { required }
    }
  },
  methods: {
    ...mapActions(['fetchICUsers']),
    getDetails () {
      IcMeetingPmIcService.get(this.id)
      .then((response) => {
        this.meeting = response.data
      })
      .then(() => {
        this.asyncDataStatus_fetched('Meetings Details')
      })
    },
    editForm () {
      this.inEditMode = true
      this.addSidebar = false
    },
    cancelForm () {
      if (!this.$v.meeting.$anyDirty) {
        this.cancelFormOper()
        return
      }

      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to cancel?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.cancelFormOper()
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    cancelFormOper () {
      this.inEditMode = false
      this.$v.meeting.$reset()
      this.getDetails()
    },
    deleteForm () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                IcMeetingPmIcService.delete(this.meeting.meetingPmIcId, this.meeting)
                .then((response) => {
                  if (response.status !== 200) {
                    this.$snotify.error('Error!')
                  } else {
                    this.inEditMode = false
                    this.$snotify.success('Meeting Deleted.')
                    setTimeout(() => {
                      this.$router.push({name: 'IcMeetingList'})
                    }, 500)
                  }
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    saveForm () {
      this.$v.meeting.$touch()
      if (!this.$v.meeting.$error) {
        this.inSaving = true
        IcMeetingPmIcService.save(this.meeting.meetingPmIcId, this.meeting)
        .then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.inEditMode = false
              this.$snotify.success('Changes saved.')
            }
            this.inSaving = false
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    selectMinute (id) {
      this.selectedMinute = this.meeting.minutes.filter(x => x.minuteId === id)[0]
    },
    approveForm () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to Approve? It cannot be undone!', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                IcMeetingPmIcService.approve(this.meeting.meetingPmIcId, this.meeting)
                .then((response) => {
                  if (response.status !== 200) {
                    this.$snotify.error('Error!')
                  } else {
                    this.meeting = response.data
                    this.$snotify.success('Meeting Approved.')
                  }
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
      .then(
        (response) => {
        }
      ).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    completeForm () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to Complete? It cannot be undone!', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                IcMeetingPmIcService.complete(this.meeting.meetingPmIcId, this.meeting)
                .then((response) => {
                  if (response.status !== 200) {
                    this.$snotify.error('Error!')
                  } else {
                    this.meeting = response.data
                    this.$snotify.success('Meeting Completed and it\'s ready for Approval')
                  }
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
      .then(
        (response) => {
        }
      ).catch(error => this.$snotify.error('Server Error! ' + error.message))
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allUsers () {
      return this.$store.state.allUsers
    },
    icUsers () {
      return this.$store.state.icUsers
    },
    canDelete () {
      return this.meeting.approvedBy === null && this.user.roles.filter(x => x.roleId === this.$userRoles.MEETING_MINUTE_TAKER || x.roleId === this.$userRoles.INVESTMENT_COMMITTEE).length > 0 // Meeting Minute Taker + Investment Committee
    },
    canApprove () {
      return this.meeting.approvedBy === null && this.user.roles.filter(x => x.roleId === this.$userRoles.INVESTMENT_COMMITTEE || x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0 // Investment Committee + Admin
    },
    meetingDate () {
      return moment(this.meeting.meetingDate).format('M/D/YYYY')
    },
    votings () {
      if (this.selectedMinute != null) {
        return this.meeting.votings.filter(x => x.fundId === this.selectedMinute.fundId)
      }
      return []
    }
  },
  created () {
    this.fetchICUsers()
    this.getDetails()
  }
}
</script>