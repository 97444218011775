<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'funds-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Deals'" :icon="'mdi mdi-square-inc-cash'" />
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box table-responsive">
                                <h4 class="header-title">Deals list</h4>
                                <div class="row align-items-start justify-content-between">
                                      <div class='d-flex align-items-center m-2 p-2'>
                                        <button class='btn btn-primary mr-4' @click='openFilterMenu'><i class="mdi mdi-filter" /> Filters</button>
                                        <div class='d-flex align-items-center'>
                                          <input type="search" v-model="searchName" class="form-control" placeholder="Search by name" @keyup.enter="startSearch">
                                          <button @click="startSearch" class="btn btn-icon waves-effect waves-light btn-primary"> <i class="fa fa-search"></i> </button>
                                        </div>
                                      </div>
                                      <!-- <div style="width:50%">
                                        <multiselect id="statusFilter" v-model="countryFilter" tag-placeholder="Add this as new tag" placeholder="Search or add a Country" 
                                            label="countryName" track-by="countryId" :options="allCountries" :multiple="true" :taggable="true" width="50%" @input="submitSearch"></multiselect>
                                      </div> -->
                                    <div class="col-sm-12 col-md-6 m-2 p-2">
                                        <div>
                                            <div class="btn-group dropdown-btn-group pull-right">
                                              <button @click="clearFilters" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                              <!-- <button class="btn btn-default btn-primary">Display all</button> -->
                                              <button class="btn btn-default dropdown-toggle hide-phone" @click="toggleExtras">More <span class="caret"></span></button>
                                                <ul class="dropdown-menu" :class="{'show' : showExtraList}" style="position: absolute; top: 38px; left: -24px; padding: 5px;">
                                                  <li class="checkbox checkbox-primary">
                                                    <input type="checkbox" id="extra-col-3" v-model="showFLRDate">
                                                    <label for="extra-col-3">Target FLR Date</label>
                                                  </li>
                                                  <li class="checkbox checkbox-primary">
                                                    <input type="checkbox" id="extra-col-4" v-model="showRRDate">
                                                    <label for="extra-col-4">Target RR Date</label>
                                                  </li>
                                                  <li class="checkbox checkbox-primary">
                                                    <input type="checkbox" id="extra-col-1" v-model="showColTier">
                                                    <label for="extra-col-1">Tier</label>
                                                  </li>
                                                  <li class="checkbox checkbox-primary">
                                                    <input type="checkbox" id="extra-col-2" v-model="showColFirm">
                                                    <label for="extra-col-2">Firm</label>
                                                  </li>
                                                </ul>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if='Object.values(activeFilters).some(arr => arr && arr.length > 0)' class='container-fluid'>
                                  <h4>Active Filters</h4>
                                  <div class='row col-12 m-2' v-if='activeFilters.stageObj'>
                                    <h5 class="funds-filter-rows m-1">Stage: </h5>
                                    <div class='badge badge-primary m-1' v-for='filter in activeFilters.stageObj' :key="activeFilters.stageObj.indexOf(filter)" >{{filter.name}}</div>
                                  </div>
                                  <div class='row col-12 m-2' v-if='activeFilters.strategyObj'>
                                    <h5 class="funds-filter-rows m-1">Strategy: </h5>
                                    <div class='badge badge-primary m-1' v-for='filter in activeFilters.strategyObj' :key="activeFilters.strategyObj.indexOf(filter)" >{{filter.sectorName || filter.subSectorName}}</div>
                                  </div>
                                  <div class='row col-12 m-2' v-if='activeFilters.geoObj'>
                                    <h5 class="funds-filter-rows m-1">Geography: </h5>
                                    <div class='badge badge-primary m-1' v-for='filter in activeFilters.geoObj' :key="activeFilters.geoObj.indexOf(filter)" >{{filter.geographicName}}</div>
                                  </div>
                                  <div class='row col-12 m-2' v-if='activeFilters.typeObj'>
                                    <h5 class="funds-filter-rows m-1">Type: </h5>
                                    <div class='badge badge-primary m-1' v-for='filter in activeFilters.typeObj' :key="activeFilters.typeObj.indexOf(filter)" >{{filter.name}}</div>
                                  </div>
                                  <div class='row col-12 m-2' v-if='activeFilters.tierObj'>
                                    <h5 class="funds-filter-rows m-1">Tiers: </h5>
                                    <div class='badge badge-primary m-1' v-for='filter in activeFilters.tierObj' :key="activeFilters.tierObj.indexOf(filter)" >{{filter.fundTierName}}</div>
                                  </div>
                                  <div class='row col-12 m-2' v-if='activeFilters.statusObj'>
                                    <h5 class="funds-filter-rows m-1">Status: </h5>
                                    <div class='badge badge-primary m-1' v-for='filter in activeFilters.statusObj' :key="activeFilters.statusObj.indexOf(filter)">{{filter.name}}</div>
                                  </div>
                                  <div class='row col-12 m-2' v-if='activeFilters.countryObj && activeFilters.countryObj.length > 0'>
                                    <h5 class="funds-filter-rows m-1">Country: </h5>
                                    <div class='badge badge-primary m-1' v-for='filter in activeFilters.countryObj' :key="activeFilters.countryObj.indexOf(filter)">{{filter.countryName}}</div>
                                  </div>
                                </div>
                                <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                                    <thead>
                                    <tr role="row">
                                        <th @click="setOrder(0)"> Name
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th>
                                          <span @click="setOrder(1)"> Geographic Focus</span>
                                          <i v-if="(sortIndex == 1 || sortIndex == 8) && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="(sortIndex == 1 || sortIndex == 8) && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th>
                                          <span @click="setOrder(2)"> Stage</span>
                                          <i v-if="(sortIndex == 2 || sortIndex == 8) && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="(sortIndex == 2 || sortIndex == 8) && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th>
                                          <span @click="setOrder(3)"> Type</span>
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th>
                                          <span @click="setOrder(4)"> Strategy</span>
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(5)">Target Size (M)
                                          <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(6)">Expected Close Date
                                          <i v-if="sortIndex == 6 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 6 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>

                                        <th v-if="showFLRDate" @click="setOrder(10)">Target FLR Date
                                          <i v-if="sortIndex == 10 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 10 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th v-if="showRRDate" @click="setOrder(11)">Target RR Date
                                          <i v-if="sortIndex == 11 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 11 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th>
                                          <span @click="setOrder(7)"> Status</span>
                                          <i v-if="sortIndex == 7 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 7 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th v-if="showColTier">
                                          <span @click="setOrder(8)"> Tier</span>
                                          <i v-if="sortIndex == 8 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 8 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th v-if="showColFirm" @click="setOrder(9)">Firm
                                          <i v-if="sortIndex == 9 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 9 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                            
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                       <tr role="row" v-for="fund in funds" :key="fund.fundId">
                                           <td>
                                               <router-link :to="{name: 'FundsDetail', params: {id: fund.fundId}}" >
                                                   {{ fund.fundName }}
                                               </router-link>
                                           </td>
                                           <td>{{ fund.geographicName }}</td>
                                           <td :class="stageClass(fund.stageId)">{{ fund.stageName }}</td>
                                           <td>{{ fund.typeName }}</td>
                                           <td>{{ fund.sectorName }}</td>
                                           <td>{{ fund.currencySymbol }}{{ fund.targetSize | thousandNumber }}</td>
                                           <td> <AppDate :date="fund.expectedCloseDate != null ? fund.expectedCloseDate : ''" :show="'text'" /> </td>
                                           <td v-if="showFLRDate"> <AppDate :date="fund.targetFLRDate != null ? fund.targetFLRDate : ''" :show="'text'" /> </td>
                                           <td v-if="showRRDate"> <AppDate :date="fund.targetRRDate != null ? fund.targetRRDate : ''" :show="'text'" /> </td>
                                           <td>{{ fund.isActive ? 'Active' : 'Inactive' }}</td>
                                           <td v-if="showColTier">{{ fund.fundTierName }}</td>
                                           <td v-if="showColFirm">{{ fund.firmName }}</td>
                                       </tr>
                                    </tbody>
                                </table>
                   
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div class="" role="status">Page {{currentPage}} of {{totalPages}} - {{totalRecords | thousandNumber}} deals</div>
                                    <div class="px-3">
                                      <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                          <option value="25">25</option>
                                          <option value="50">50</option>
                                          <option value="100">100</option>
                                          <option value="250">250</option>
                                      </select> 
                                    </div>

                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end container -->
        </div>
        <!-- end content -->
        <div class="side-bar right-bar">
          <FundsFilterMenu @closeFilterMenu='closeFilterMenu' @addFilters="addFilters" ref='fundsFilterMenu' :allStatuses='allStatuses' :allStages='allStages' :allTypes='allTypes' />
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { FundService } from '@/services/fund-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Multiselect from 'vue-multiselect'
import rightbar from '@/mixins/rightbar'
import FundsFilterMenu from '@/components/fund/FundsFilterMenu'
export default {
  components: {
    Multiselect,
    FundsFilterMenu
  },
  data () {
    return {
      funds: [],
      selectedGeos: [],
      selectedStages: [],
      selectedSectors: [],
      selectedTypes: [],
      selectedStatuses: [],
      selectedTires: [],
      searchName: '',
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      allStages: [{id: 1, name: '1-Sourcing'}, {id: 2, name: '2-Screening'}, {id: 3, name: '3-Preliminary DD'}, {id: 4, name: '4-Full DD'}, {id: 5, name: '5-Approved'}],
      allTypes: [{id: 1, name: 'Primary'}, {id: 2, name: 'Secondary'}, {id: 3, name: 'Co-Investment'}],
      allStatuses: [{id: 1, name: 'Active'}, {id: 0, name: 'Inactive'}],
      sortBy: ['FundName', 'GeographicName', 'StageId', 'FundTypeId', 'SectorId', 'TargetSize', 'ExpectedCloseDate', 'IsActive', 'fundTierName', 'firmName', 'targetFlrDate', 'targetRrDate'],
      sortIndex: 0,
      sortType: 'ASC',
      filterIndex: null,
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0,
      countryFilter: [],
      showExtraList: false,
      showColFirm: false,
      showColTier: false,
      showFLRDate: false,
      showRRDate: false,
      activeFilters: {}
    }
  },
  mixins: [asyncDataStatus, rightbar],
  methods: {
    ...mapActions(['fetchAllCountries', 'fetchAllSectors', 'fetchAllGeoFocus', 'fetchAllFundtires', 'fetchAllTreeStrategies', 'fetchAllSubSectors', 'fetchAllStrategies']),
    closeFilterMenu () {
      this.hideRightbar()
    },
    openFilterMenu () {
      this.showRightbar()
    },
    addFilters (payload) {
      this.activeFilters = payload
      for (let filter in this.activeFilters) {
        if (this.activeFilters[filter] && this.activeFilters[filter].length < 1) {
          this.activeFilters[filter] = null
        }
      }
      this.applyFilters()
      this.startSearch()
      this.closeFilterMenu()
    },
    clearFilters () {
      this.$refs.fundsFilterMenu.clearFilterMenu()
      this.activeFilters = {}
      this.resetFilter()
    },
    setFilterMenu () {
      this.$refs.fundsFilterMenu.setCookiedFilters({...this.activeFilters})
    },
    getList () {
      this.isLoading = true
      this.refreshCookie()
      FundService.search(this.searchName.trim(), this.sortBy[this.sortIndex], this.sortType, this.currentPage, this.pageSize, this.countryFilter, this.selectedGeos, this.selectedStages, this.selectedTypes, this.selectedSectors, this.selectedStatuses, this.selectedTires)
      .then((response) => {
        this.isLoading = false
        this.funds = response.data.funds
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Deals List') })
    },
    toggleExtras () {
      this.showExtraList = !this.showExtraList
      this.refreshCookie()
    },
    refreshCookie () {
      const filters = {
        'country': this.countryFilter,
        'searchName': this.searchName,
        'geo': this.selectedGeos,
        'stage': this.selectedStages,
        'type': this.selectedTypes,
        'sector': this.selectedSectors,
        'status': this.selectedStatuses,
        'tier': this.selectedTires,
        'pageSize': this.pageSize,
        'showColFirm': this.showColFirm,
        'showColTier': this.showColTier,
        'showFLRDate': this.showFLRDate,
        'showRRDate': this.showRRDate,
        'sortIndex': this.sortIndex,
        'sortType': this.sortType
      }
      
      localStorage.setItem('umb_funds_filters', JSON.stringify(filters))
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }
      this.funds = []
      this.showExtraList = false
      if (this.searchName == null) {
        this.searchName = ''
      }
      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    stageClass (stageId) {
      if (stageId >= 3) {
        return 'text-white stage' + stageId
      }
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.sortIndexBackup = this.sortIndex
      this.sortTypeBackup = this.sortType
      this.startSearch()
    },
    showFilter (id) {
      if (this.filterIndex !== id) {
        this.filterIndex = id
        if (id === 0) {
          this.startSearch()
        }
      } else {
        this.filterIndex = null
      }
    },
    loadActiveFilters () {
      if (this.selectedGeos && this.selectedGeos.length !== this.allGeoFocuses.length) {
        this.activeFilters.geoObj = this.selectedGeos.map(num => this.allGeoFocuses.find(geo => geo.geographicId === num))
      }
      if (this.selectedStages && this.selectedStages.length !== this.allStages.length) {
        this.activeFilters.stageObj = this.selectedStages.map(num => this.allStages.find(stage => stage.id === num))
      }
      if (this.selectedTypes && this.selectedTypes.length !== this.allTypes.length) {
        this.activeFilters.typeObj = this.selectedTypes.map(num => this.allTypes.find(type => type.id === num))
      }
      if (this.selectedSectors && this.selectedSectors.length !== this.allSectors.length) {
        this.activeFilters.strategyObj = this.selectedSectors.map(num => this.allSectors.find(sector => sector.sectorId === num))
      }
      if (this.selectedStatuses && this.selectedStatuses.length !== this.allStatuses.length) {
        this.activeFilters.statusObj = this.selectedStatuses.map(num => this.allStatuses.find(status => status.id === num))
      }
      if (this.selectedTires && this.selectedTires.length !== this.allTires.length) {
        this.activeFilters.tierObj = this.selectedTires.map(num => this.allTires.find(tier => tier.fundTierId === num))
      }
      if (this.countryFilter) {
        this.activeFilters.countryObj = this.countryFilter.map(num => this.allCountries.find(country => country.countryId === num))
      }
    },
    applyFilters () {
      for (let filter in this.activeFilters) {
        if (this.activeFilters[filter] && this.activeFilters[filter].length > 0) {
          switch (filter) {
            case 'geoObj':
              this.selectedGeos = this.activeFilters[filter].map(item => item.geographicId)
              break
            case 'strategyObj':
              this.selectedSectors = this.activeFilters[filter].map(item => item.sectorId)
              break
            case 'typeObj':
              this.selectedTypes = this.activeFilters[filter].map(item => item.id)
              break
            case 'tierObj':
              this.selectedTires = this.activeFilters[filter].map(item => item.fundTierId)
              break
            case 'statusObj':
              this.selectedStatuses = this.activeFilters[filter].map(item => item.id)
              break
            case 'stageObj':
              this.selectedStages = this.activeFilters[filter].map(item => item.id)
              break
            case 'countryObj':
              this.countryFilter = this.activeFilters[filter].map(item => item.countryId)
              break
          }
        } else if (!this.activeFilters[filter]) {
          switch (filter) {
            case 'geoObj':
              this.selectedGeos = this.allGeoFocuses.map(x => x.geographicId)
              break
            case 'strategyObj':
              this.selectedSectors = this.allSectors.map(x => x.sectorId)
              break
            case 'typeObj':
              this.selectedTypes = this.allTypes.map(x => x.id)
              break
            case 'tierObj':
              this.selectedTires = this.allTires.map(x => x.fundTierId)
              break
            case 'statusObj':
              this.selectedStatuses = this.allStatuses.map(x => x.id)
              break
            case 'stageObj':
              this.selectedStages = this.allStages.map(x => x.id)
              break
            case 'countryObj':
              this.countryFilter = []
              break
          }
        }
      }
    },
    setupFilters () {
      this.selectedGeos = this.allGeoFocuses.map(x => x.geographicId)
      this.selectedStages = this.allStages.map(x => x.id)
      this.selectedTypes = this.allTypes.map(x => x.id)
      this.selectedSectors = this.allSectors.map(x => x.sectorId)
      this.selectedStatuses = this.allStatuses.map(x => x.id)
      this.selectedTires = this.allTires.map(x => x.fundTierId)
    },
    resetFilter () {
      this.searchName = ''
      this.countryFilter = []
      this.setupFilters()
      this.startSearch()
    }
  },
  created () {
    this.fetchAllSectors()
    this.fetchAllGeoFocus()
    this.fetchAllStrategies()
    this.fetchAllTreeStrategies()
    this.fetchAllSubSectors()
    this.fetchAllCountries()
    this.fetchAllFundtires()
    this.setupFilters()
    
    const oldFilters = JSON.parse(localStorage.getItem('umb_funds_filters'))
    if (oldFilters != null) {
      if (oldFilters.searchName != null) {
        this.searchName = oldFilters.searchName
      }
      if (oldFilters.country != null) {
        this.countryFilter = oldFilters.country
      }
      if (oldFilters.geo != null) {
        this.selectedGeos = oldFilters.geo
      }
      if (oldFilters.stage != null) {
        this.selectedStages = oldFilters.stage
      }
      if (oldFilters.type != null) {
        this.selectedTypes = oldFilters.type
      }
      if (oldFilters.sector != null) {
        this.selectedSectors = oldFilters.sector
      }
      if (oldFilters.status != null) {
        this.selectedStatuses = oldFilters.status
      }
      if (oldFilters.tier != null) {
        this.selectedTires = oldFilters.tier
      }
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
      if (oldFilters.sortIndex != null) {
        this.sortIndex = oldFilters.sortIndex
      }
      if (oldFilters.sortType != null) {
        this.sortType = oldFilters.sortType
      }
      if (oldFilters.showColFirm != null) {
        this.showColFirm = oldFilters.showColFirm
      }
      if (oldFilters.showColTier != null) {
        this.showColTier = oldFilters.showColTier
      }
      if (oldFilters.showFLRDate != null) {
        this.showFLRDate = oldFilters.showFLRDate
      }
      if (oldFilters.showRRDate != null) {
        this.showRRDate = oldFilters.showRRDate
      }
    }
    this.startSearch()
  },
  mounted () {
    this.loadActiveFilters()
    this.setFilterMenu()
  },
  computed: {
    allCountries () {
      return this.$store.state.allCountries
    },
    allSectors () {
      return this.$store.state.allSectors.filter(x => x.sort < 100)
    },
    allGeoFocuses () {
      return this.$store.state.allGeoFocus
    },
    // typo
    allTires () {
      let t = this.$store.state.allFundtires
      t.push({'fundTierId': 0, 'fundTierName': '(Empty)'})
      return t
    },
    hasFilter () {
      return (this.searchName && this.searchName.length > 0) || (this.countryFilter && this.countryFilter.length > 0) || (this.selectedGeos && this.selectedGeos.length < 6) || (this.selectedStages && this.selectedStages.length < 5) || (this.selectedTypes && this.selectedTypes.length < 3) || (this.selectedSectors && this.selectedSectors.length < this.allSectors.filter(x => x.sort < 100).length) || (this.selectedStatuses && this.selectedStatuses.length < 2) || (this.selectedTires && this.selectedTires.length < this.allTires.length)
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>