import Constants from '../constants.js'
import Axios from 'axios'

export const InvestingService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/investing/' + id)
  },
  search (name, sortField, sortType, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + '/investing/search?name=' + name + '&sortField=' + sortField + '&sortType=' + sortType + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/investing/' + id, value)
  },
  add (investing) {
    return Axios.post(Constants.API_URL + '/investing', investing)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/investing/' + id)
  }
}
