<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Regional Focus
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="fund.Geography">Geography<span v-show="isEditMode" class="text-danger">*</span></label>
          <multiselect id="fund.Geography" v-model="countryObj" v-show="!isEditMode" :options="allCountries" label="countryName" track-by="countryId" :disabled="true"></multiselect>
          <multiselect id="fund.Geography" v-model="countryObj2" v-show="isEditMode" :options="allCountries" label="countryName" track-by="countryId" :allow-empty="false" @input="countrySelected"></multiselect>
      </div>

      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="regionalFocus.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="regionalFocus.createdBy" />
          </div>
          <div class="form-group" v-if="regionalFocus.updatedOn">
              <label>Modified On</label>
              <AppDate :date="regionalFocus.updatedOn" :show="'input'"/>
          </div>
          <div class="form-group" v-if="regionalFocus.updatedOn">
              <label>Modified By</label>
              <AppUser :userId="regionalFocus.updatedBy" :emptyIfNull="true"/>
          </div>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      showLogs: false,
      countryObj2: null
    }
  },
  mixins: [rightbar],
  methods: {
    ...mapActions(['fetchAllCountries', 'addFundsRegionalFocus', 'updateFundsRegionalFocus']),
    close () {
      this.hideRightbar()
    },
    countrySelected (value, id) {
      this.regionalFocus.countryId = value ? value.countryId : null
    },
    onSave () {
      if (this.isAdd) {
        this.addFundsRegionalFocus({fundsRegionalFocus: this.regionalFocus})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Region Added.')
              this.$emit('onUpdate')
            }
          })
      } else {
        this.updateFundsRegionalFocus({fundsRegionalFocusId: this.regionalFocus.fundsCountryFocusId, fundsRegionalFocus: this.regionalFocus})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Region Updated.')
              this.$emit('onUpdate')
            }
          })
      }
    }
  },
  created () {
    this.fetchAllCountries()
  },
  computed: {
    regionalFocus () {
      return this.$store.state.rb_regionalFocus
    },
    allCountries () {
      return this.$store.state.allCountries
    },
    countryObj () {
      if (this.regionalFocus != null) {
        const c = this.allCountries.filter(t => t.countryId === this.regionalFocus.countryId)
        this.countryObj2 = c
        return c
      } else {
        return null
      }
    }
  }
}
</script>
