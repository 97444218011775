import Constants from '../constants.js'
import Axios from 'axios'

export const TaskService = {
  search (assignedTo, isActive, sortField, type, pageNumber, pageSize) {
    return Axios.get(Constants.API_URL + `/firmoutreachtasks/search?assignedTo=${assignedTo}&isActive=${isActive}&sortField=${sortField}&sortType=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
  },
  save (id, value) {
    return Axios.post(Constants.API_URL + '/firmoutreachtasks/generate/' + id)
  },
  completeTask (id, value) {
    return Axios.put(Constants.API_URL + '/firmoutreachtasks/' + id, value)
  },
  transferTask (id, value) {
    return Axios.put(Constants.API_URL + '/firmoutreachtasks/update-assinee/' + id, value)
  }
}
