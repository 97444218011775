<template>
  <!-- Page-Title -->
  <div class="row">
    <div class="col-sm-12">
      <div class="page-title-box">
          <h4 class="page-title"><i class="mdi mdi-file-document"></i> {{title}} </h4>
          <div class="clearfix"></div>
      </div>
      <div class="mb-3">
        <button class="btn btn-primary btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="inEditMode" @click="onCancel"><i class="mdi mdi-backup-restore"></i> <span>Cancel</span></button>
        <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="!inEditMode" :disabled="!canEdit" @click="onEdit"><i class="mdi mdi-lead-pencil m-r-5"></i> <span>Edit</span></button>
        <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5 mr-2" @click="onSave" v-show="inEditMode" :disabled="isSaving"><i class="mdi mdi-content-save m-r-5"></i> <span v-if="!isSaving">Save</span> <i v-else class="fa fa-spinner fa-spin"></i></button>
        <button class="btn btn-danger btn-custom waves-effect w-md waves-light m-b-5 mr-2" :disabled="inEditMode" v-if="canDelete" @click="onDelete"><i class="mdi mdi-delete m-r-5"></i> <span>Delete</span></button>
        <button class="btn btn-warning btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="canComplete" :disabled="inEditMode" @click="onComplete"> <i class="mdi mdi-approval m-r-5"></i> <span>Complete </span> </button>
        <button class="btn btn-warning btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="canApprove" :disabled="inEditMode" @click="onApprove"> <i class="mdi mdi-approval m-r-5"></i> <span>Approve </span> </button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'No Title'
    },
    inEditMode: {
      required: false,
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canApprove: {
      type: Boolean,
      default: false
    },
    canComplete: {
      type: Boolean,
      default: false
    },
    isSaving: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onEdit () {
      this.$emit('onEdit')
    },
    onNew () {
      this.$emit('onNew')
    },
    onCancel () {
      this.$emit('onCancel')
    },
    onSave () {
      this.$emit('onSave')
    },
    onDelete () {
      this.$emit('onDelete')
    },
    onPin () {
      this.$emit('onPin')
    },
    onPinOff () {
      this.$emit('onPinOff')
    },
    onApprove () {
      this.$emit('onApprove')
    },
    onComplete () {
      this.$emit('onComplete')
    }
  }
}
</script>
