<template>
  <div class="row">
    <span v-if="isLoading">Loading <i class="fa fa-spinner fa-spin"></i></span>
    <div class="col-lg-12 col-md-12 col-sm-12" v-for="item in list" :key="item.pinId">
      <router-link :to="{path: item.objectUrl + '/detail/' + item.objectId}">
        <div class="widget-bg-color-icon-small text-center card-box card-box-mini">
          <div class="bg-icon bg-icon-primary pull-left">
            <i :class="`mdi `+item.objectIcon"></i>
          </div>
          <div class="text-right">
            <p class="text-muted mb-0">{{item.title}}</p>
          </div>
          <div class="clearfix"></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { UserpinService } from '@/services/userpin-service'

export default {
  data () {
    return {
      list: null,
      isLoading: true
    }
  },
  created () {
    UserpinService.getByUber()
    .then((response) => {
      this.list = response.data
      this.isLoading = false
    })
  }
}
</script>
