<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'meeting-add'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Meeting Minutes'" :icon="'mdi mdi-file-document'"/>
                    <div class="card-box">
                      <div class="row">
                        <div class="col-lg-6">
                          <h4 class="header-title m-t-0">Create a New: Private Markets Manager Research Committee</h4>
                          <p class="text-muted font-14 m-b-20">
                              Complete Meeting information.
                          </p>

                          <form action="#" autocomplete="off" class="icmeeting">
                            <div class="form-group">
                              <label for="meetingDate">
                                Meeting Date
                              </label>
                              <date-picker id="meetingDate" placeholder="Select Date" calendar-class="calendar form-control" :monday-first="true" :typeable="false" :clear-button="false" clear-button-icon="fa fa-times" @selected="meetingDateChanged" 
                                format="MM/dd/yyyy" :bootstrap-styling="true" v-model="meeting.meetingDate" @blur="$v.meeting.meetingDate.$touch()" :input-class="{ 'form-control parsley-error': $v.meeting.meetingDate.$error }"></date-picker>
                            </div>

                            <div class="form-group">
                                <label class="text-primary">Members</label> <i>select present members</i>
                                <ul>
                                  <li v-for="ic in pmmrUsers" :key="ic.userId">
                                    <div class="checkbox checkbox-primary">
                                        <input :id="`ic`+ic.userId" type="checkbox" :value="ic.userId" v-model="selectedMembers">
                                        <label :for="`ic`+ic.userId">
                                          <AppUser :userId="ic.userId" :show="'text'" :showpic="true"/>
                                        </label>
                                    </div>
                                  </li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="text-primary">Focus List Report</label>
                                <div v-if="isLoading">
                                  Loading <i class="fa fa-spinner fa-spin"></i>
                                </div>
                                <ul v-else>
                                  <li v-for="flr in fundsFLR" :key="flr.fundId">
                                    <div class="checkbox checkbox-primary">
                                        <input :id="`flr`+flr.fundId" type="checkbox" :value="flr.fundId" v-model="selectedFunds">
                                        <label :for="`flr`+flr.fundId">
                                          {{flr.fundName}}
                                        </label>
                                    </div>
                                  </li>
                                  <li v-if="fundsFLR.length == 0 && !isLoading">
                                      No Deals Avaliable
                                  </li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="text-primary">Research Report</label>
                                <div v-if="isLoading">
                                  Loading <i class="fa fa-spinner fa-spin"></i>
                                </div>
                                <ul>
                                  <li v-for="rr in fundsRR" :key="rr.fundId">
                                    <div class="checkbox checkbox-primary">
                                        <input :id="`rr`+rr.fundId" type="checkbox" :value="rr.fundId" v-model="selectedFunds">
                                        <label :for="`rr`+rr.fundId">
                                          {{rr.fundName}}
                                        </label>
                                    </div>
                                  </li>
                                  <li v-if="fundsRR.length == 0 && !isLoading">
                                      No Deals Avaliable
                                  </li>
                                </ul>
                            </div>

                            <!-- <div class="form-group">
                                <label class="text-primary">Due Diligence Update</label>
                                <toggle-button v-model="hadDD" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                <div class="form-group" v-show="hadDD">
                                    <label for="fund">Deal <span class="text-danger">*</span></label>
                                    <multiselect id="fund" v-model="fundObj" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search"
                                      @input="fundSelectedDD" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                                    <ul>
                                      <li v-for="dd in selectedDD" :key="dd.fundId">
                                        {{dd.fundName}}
                                      </li>
                                    </ul>
                                </div>
                            </div> -->

                            <div class="form-group">
                                <label class="text-primary">Miscellaneous Items</label>
                                <toggle-button v-model="hadMS" :sync="true" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                <div class="form-group" v-show="hadMS">
                                    <label for="fund">Deal <span class="text-danger">*</span></label>
                                    <multiselect id="fund" v-model="fundObj2" :options="searchFunds" label="fundName" track-by="fundId" :allow-empty="false" placeholder="Type to Search"
                                      @input="fundSelectedMS" @search-change="asyncSearchFund" :loading="isSearchingList" :options-limit="50"></multiselect>
                                    <ul>
                                      <li v-for="ms in selectedMS" :key="ms.fundId">
                                        {{ms.fundName}}
                                      </li>
                                    </ul>
                                </div>
                            </div>
                          </form>

                          <div class="row">
                            <div class="col-lg-12 col-ms-12 text-right">
                              <button type="reset" class="btn btn-secondary waves-effect m-l-5 mr-2" @click.prevent="reset">
                                  Reset
                              </button>
                              <button class="btn btn-primary waves-effect waves-light" type="submit" @click.prevent="save" :disabled="isLoading || selectCount == 0 || isSaving" v-if="canCreate">
                                <span v-if="!isSaving">Create</span>
                                <span v-else>Creating <i class="fa fa-spinner fa-spin"></i></span>
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { FundService } from '@/services/fund-service'
import { IcMeetingService } from '@/services/icmeeting-service'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    DatePicker,
    Multiselect
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      meeting: {meetingDate: new Date()},
      funds: [],
      selectedFunds: [],
      selectedMembers: [],
      selectedDD: [],
      selectedMS: [],
      isLoading: true,
      hadDD: false,
      hadMS: false,
      searchFunds: [],
      isSearchingList: false,
      fundObj: null,
      fundObj2: null,
      isSaving: false
    }
  },
  validations: {
    meeting: {
      meetingDate: { required }
    }
  },
  methods: {
    ...mapActions(['fetchLivePMMRUsers', 'searchAllFunds']),
    meetingDateChanged () {
      this.$nextTick(() => this.getFunds())
    },
    getFunds () {
      const date = new Date(this.meeting.meetingDate).toISOString()
      FundService.getForMeetingPMMrc(date)
      .then(
        (response) => {
          this.funds = response.data
          this.isLoading = false
        }
      )
    },
    save () {
      const t = this.pmmrUsers.length
      const s = this.selectedMembers.length
      if (s < t / 2) {
        this.$snotify.warning('Quorum does not meet!')
      } else {
        // collect new Due Dills
        let dd = this.selectedDD.map(a => a.fundId)
        if (!this.hadDD) {
          dd = []
        }
        // collect new Miscs
        let ms = this.selectedMS.map(a => a.fundId)
        if (!this.hadMS) {
          ms = []
        }
        this.isSaving = true
        const icm = {'meetingDate': this.meeting.meetingDate, 'newMembers': this.selectedMembers, 'newFunds': this.selectedFunds, 'newDueDills': dd, 'newMiscs': ms}
        IcMeetingService.add(icm)
        .then(
          (response) => {
            this.$snotify.success('Meeting created.')
            setTimeout(() => {
              this.isSaving = false
              this.$router.push({name: 'IcMeetingMrcPM', params: {id: response.data.meetingId}})
            }, 500)
          })
          .catch(function (error) {
            console.error('SERVER Error:', error)
            // this.$snotify.error('SERVER Errors.' + error)
          })
      }
    },
    reset () {
      this.selectedFunds = []
      this.selectedMembers = []
      this.selectedDD = []
      this.selectedMS = []
      this.hadDD = false
      this.hadMS = false
    },
    fundSelectedDD () {
      this.selectedDD.push(this.fundObj)
    },
    fundSelectedMS () {
      this.selectedMS.push(this.fundObj2)
    },
    asyncSearchFund (query) {
      this.isSearchingList = true
      this.searchAllFunds({query}).then((result) => {
        this.searchFunds = result
        this.isSearchingList = false
      })
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    pmmrUsers () {
      return this.$store.state.pmmrUsers
    },
    fundsFLR () {
      return this.funds.filter(x => x.stageId === 3)
    },
    fundsRR () {
      return this.funds.filter(x => x.stageId === 4)
    },
    selectCount () {
      let cnt = this.selectedFunds.length
      if (this.hadDD) {
        cnt += this.selectedDD.length
      }
      if (this.hadMS) {
        cnt += this.selectedMS.length
      }
      return cnt
    },
    canCreate () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.MEETING_MINUTE_TAKER || x.roleId === this.$userRoles.INVESTMENT_COMMITTEE || x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0 // Meeting Minute Taker + Investment Committee + Admin
    }
  },
  created () {
    this.fetchLivePMMRUsers()
    this.getFunds()
    this.asyncDataStatus_fetched('Create a New IC Meeting')
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
