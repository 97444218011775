<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Start Portfolio {{actionName}}
          </a>
      </li>
    </ul>
    <form action="#" class="m-t-10">
      <div class="form-group">
          <label for="comments">Comments</label>
          <textarea id="comments" v-model="form.comments" class="form-control" rows="10" @blur="$v.form.comments.$touch()" :class="{ 'parsley-error': $v.form.comments.$error }"></textarea>
          <template v-if="$v.form.comments.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.form.comments.max" class="parsley-required">This value is too long</li>
            </ul>
          </template>
      </div>
      
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import rightbar from '@/mixins/rightbar'
import { maxLength } from 'vuelidate/lib/validators'
import { PortfolioVotingService } from '@/services/portfolio-service'

export default {
  props: {
    portfolioId: {
      required: true,
      type: Number
    },
    actionType: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      form: {}
    }
  },
  mixins: [rightbar],
  validations: {
    form: {
      comments: { max: maxLength(500) }
    }
  },
  computed: {
    actionName () {
      if (this.actionType === 1) {
        return 'Activating'
      } else {
        return 'Closing'
      }
    }
  },
  methods: {
    close () {
      this.$v.form.$reset()
      this.hideRightbar()
    },
    onSave () {
      this.$v.form.$touch()
      if (!this.$v.form.$error) {
        const cmt = {'comments': this.form.comments}
        PortfolioVotingService.add(this.portfolioId, cmt)
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Vote Generated')
              this.$emit('onUpdate')
            }
          })
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    }
  }
}
</script>
