<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Transfer to Another User
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="filledPosition">User <span class="text-danger">*</span></label>
          <multiselect @open='$v.selectedUser.$touch()' id="selectedUser" v-model="selectedUser" :options="allActiveUsers" label="fullName" track-by="userId"></multiselect>
          <template v-if="$v.selectedUser.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.selectedUser.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>
      
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button :disabled='$v.selectedUser.$error || selectedUser === null' class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> Save</button>
      </div>
    </form>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'
import rightbar from '@/mixins/rightbar'
import { TaskService } from '@/services/task-service'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    assigneeList: {
      required: true,
      type: Array
    },
    taskId: {
      required: true,
      type: Number
    },
    originalUserId: {
      required: true,
      type: Number
    }
  },
  components: {
    Multiselect
  },
  mixins: [rightbar],
  validations: {
    selectedUser: { required }
  },
  data () {
    return {
      selectedUser: null
    }
  },
  methods: {
    close () {
      this.hideRightbar()
    },
    onSave () {
      const update = { taskId: this.taskId, userId: this.selectedUser.userId }
      if (!this.$v.selectedUser.$error) {
        TaskService.transferTask(this.taskId, update)
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Updated.')
              this.$emit('onUpdate', { userObj: this.selectedUser, taskId: this.taskId })
              this.close()
            }
          })
        setTimeout(() => { this.selectedUser = null }, 1500)
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    }
  },
  computed: {
    allUsers () {
      return this.$store.state.allUsers
    },
    allActiveUsers () {
      return this.allUsers.filter(u => u.isActive)
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>