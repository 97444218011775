import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    piedata: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      baseOption: {
        responsive: true,
        legend: {
          position: 'bottom',
          labels: {
            // fontColor: '#000000'
          }
        },
        plugins: {
          datalabels: {
            color: function (context) {
              const {dataIndex, dataset: {labelColors}} = context
              return labelColors ? labelColors[dataIndex] : '#fff'
            },
            display: function (context) {
              const {dataIndex, dataset: {data}} = context
              return data[dataIndex] ? true : false
            },
            opacity: 1,
            textAlign: 'left',
            // borderColor: 'rgba(128, 128, 128, 0.2)',
            // backgroundColor: 'white',
            // borderWidth: 2,
            // borderRadius: 50,
            labels: {
              title: {
                font: {
                  size: 11,
                  weight: 'bold'
                }
              }
            }
          }
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.piedata, this.baseOption)
  },
  watch: {
    piedata () {
      this.$data._chart.update()
    }
  }
}
