<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show">
              Investment Merit and Concerns
          </a>
      </li>
    </ul>

    <form action="#" autocomplete="off" class="m-t-10">
      <div class="form-group">
          <label for="fund.Type">Type<span v-show="isEditMode" class="text-danger">*</span></label>
          <toggle-button v-model="isMerit" v-show="!isEditMode" :sync="true" :labels="{checked: 'Merit', unchecked: 'Concern'}" :width="70" :disabled="true"/>
          <toggle-button v-model="isMerit2" v-show="isEditMode" :sync="true" :labels="{checked: 'Merit', unchecked: 'Concern'}" :width="70"/>
      </div>
      <div class="form-group">
          <label for="fund.Description">Description<span v-show="isEditMode" class="text-danger">*</span></label>
          <textarea id="fund.Description" :readonly="!isEditMode" v-model="meritConcern.meritConcernText" placeholder="Enter Description" class="form-control" @blur="$v.meritConcern.meritConcernText.$touch()" :class="{ 'parsley-error': $v.meritConcern.meritConcernText.$error }"/>
          <template v-if="$v.meritConcern.meritConcernText.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.meritConcern.meritConcernText.required" class="parsley-required">This value is required</li>
              <li v-if="!$v.meritConcern.meritConcernText.min" class="parsley-required">This value is too short</li>
              <li v-if="!$v.meritConcern.meritConcernText.max" class="parsley-required">This value is too long</li>
            </ul>
          </template>
      </div>
      
      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
          <div class="form-group">
              <label>Created On</label>
              <AppDate :date="meritConcern.createdOn" :show="'input'"/>
          </div>
          <div class="form-group">
              <label>Created By</label>
              <AppUser :userId="meritConcern.createdBy" />
          </div>
          <div class="form-group" v-if="meritConcern.updatedOn">
              <label>Modified On</label>
              <AppDate :date="meritConcern.updatedOn" :show="'input'"/>
          </div>
          <div class="form-group" v-if="meritConcern.updatedOn">
              <label>Modified By</label>
              <AppUser :userId="meritConcern.updatedBy" :emptyIfNull="true"/>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success waves-effect w-md waves-light" @click.prevent="onSave"><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    }
  },
  mixins: [rightbar],
  data () {
    return {
      showLogs: false,
      isMerit2: null
    }
  },
  validations: {
    meritConcern: {
      meritConcernText: { required, min: minLength(2), max: maxLength(48) }
    }
  },
  methods: {
    ...mapActions(['addFundsMeritConcern', 'updateFundsMeritConcern']),
    close () {
      this.hideRightbar()
    },
    onSave () {
      this.$v.meritConcern.$touch()
      if (!this.$v.meritConcern.$error) {
        const item = this.isMerit ? 'Merit' : 'Concern'
        this.meritConcern.meritConcernTypeId = this.isMerit2 ? 1 : 2
        if (this.isAdd) {
          this.addFundsMeritConcern({meritConcern: this.meritConcern})
            .then((response) => {
              if (response == null) {
                this.$snotify.error('Server Error!')
              } else {
                this.$snotify.success(item + ' Added.')
                this.$emit('onUpdate')
              }
            })
        } else {
          this.updateFundsMeritConcern({meritConcernId: this.meritConcern.meritConcernId, meritConcern: this.meritConcern})
            .then((response) => {
              this.$snotify.success(item + ' Updated.')
              this.$emit('onUpdate')
            })
        }
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    }
  },
  computed: {
    meritConcern () {
      return this.$store.state.rb_meritConcern
    },
    isMerit () {
      if (this.meritConcern != null) {
        const m = this.meritConcern.meritConcernTypeId === 1
        this.isMerit2 = m
        return m
      } else {
        return null
      }
    }
  }
}
</script>
