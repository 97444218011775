import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/Home'
import Terms from '@/pages/Terms'
import NotFound from '@/pages/Page404'
import Restricted from '@/pages/Page401'
import ServerError from '@/pages/Page500'
import Soon from '@/pages/Soon'
import VersionError from '@/pages/ErrorVersion'
import ReleaseNotes from '@/pages/release-notes'

import Signin from '@/pages/account/Signin'
import Logout from '@/pages/account/Logout'
import Profile from '@/pages/account/Profile'
import UserInfo from '@/pages/account/User'
import CacheInfo from '@/pages/admin/Cache'
import Office from '@/pages/account/office'

import FirmsList from '@/components/firm/FirmsList'
import FirmsNew from '@/components/firm/FirmsNew'
import FirmsDetail from '@/components/firm/FirmsDetail'

import TasksList from '@/components/tasks/TasksList'
import TasksOutreach from '@/components/tasks/TasksOutreach'

import FundsList from '@/components/fund/FundsList'
import FundsPipeline from '@/components/fund/FundsPipeline'
import FundsScreening from '@/components/fund/FundsScreening'
import FundsNew from '@/components/fund/FundsNew'
import FundsNewNext from '@/components/fund/FundsNewNext'
import FundsDetail from '@/components/fund/FundsDetail'
import FundsFlrVoting from '@/components/fund/VotingFlr'
import FundsRrVoting from '@/components/fund/VotingRr'
import FlrSummary from '@/components/fund/VotingFlrSummary'
import RrSummary from '@/components/fund/VotingRrSummary'
import FundsSourcing from '@/components/fund/FundsSourcing'

import ContactsList from '@/components/contact/Contactslist'
import ContactsNew from '@/components/contact/ContactsNew'
import ContactsDetail from '@/components/contact/ContactsDetail'

import LegalNew from '@/components/legal/LegalNew'
import LegalList from '@/components/legal/LegalList'
import LegalNDANew from '@/components/legal/LegalNDANew'
import LegalNDA from '@/components/legal/LegalNDA'
import LegalAcaNew from '@/components/legal/LegalAcaNew'
import LegalAca from '@/components/legal/LegalAca'
import LegalWffNew from '@/components/legal/LegalWffNew'
import LegalWff from '@/components/legal/LegalWff'
import LegalWfeNew from '@/components/legal/LegalWfeNew'
import LegalWfe from '@/components/legal/LegalWfe'
import LegalIcaNew from '@/components/legal/LegalIcaNew'
import LegalIca from '@/components/legal/LegalIca'
import LegalIfcNew from '@/components/legal/LegalIfcNew'
import LegalIfc from '@/components/legal/LegalIfc'
import LegalKycNew from '@/components/legal/LegalKycNew'
import LegalKyc from '@/components/legal/LegalKyc'
import LegalMisNew from '@/components/legal/LegalMisNew'
import LegalMis from '@/components/legal/LegalMis'
import LegalStatus from '@/components/legal/LegalStatus'

import IcMeetingStart from '@/components/icmeeting/MeetingStart'
import IcMeetingList from '@/components/icmeeting/meetingList'
import IcMeetingNewMrcPM from '@/components/icmeeting/MeetingNewMrcPM'
import IcMeetingMrcPM from '@/components/icmeeting/MeetingMrcPM'
import IcMeetingListMrcMa from '@/components/icmeeting/MeetingListMrcMa'
import IcMeetingNewMrcMA from '@/components/icmeeting/MeetingNewMrcMA'
import IcMeetingMrcMA from '@/components/icmeeting/MeetingMrcMA'
import IcMeetingListIcPM from '@/components/icmeeting/MeetingListIcPM'
import IcMeetingNewIcPM from '@/components/icmeeting/MeetingNewIcPM'
import IcMeetingIcPM from '@/components/icmeeting/MeetingIcPM'

import PortfolioList from '@/components/portfolio/PortfolioList'
import PortfolioNew from '@/components/portfolio/PortfolioNew'
import PortfolioDetail from '@/components/portfolio/PortfolioDetail'
import PortfolioOver from '@/components/portfolio/PortfolioOver'
import PortfolioSummary from '@/components/portfolio/PortfolioSummary'
import Allocation from '@/components/portfolio/Allocation'
import PortfolioVotingDetail from '@/components/portfolio/PortfolioVotingDetail'
import AllocationScreening from '@/components/portfolio/AllocationScreening'

import OpsList from '@/components/opsteam/OpsList'
import OpsNew from '@/components/opsteam/OpsNew'
import OpsDetail from '@/components/opsteam/OpsDetail'
import MonitoringComments from '@/components/opsteam/MonitoringComments'

import Reports from '@/components/report/ReportList'

import Calendar from '@/pages/calendar/list'
import Notification from '@/pages/notification/list'
import GlobalSearch from '@/pages/GlobalSearch'
import Browse from '@/pages/Browse'

import PortalFirmsList from '@/components/portal/FirmsList'
import PortalFirmsDetail from '@/components/portal/FirmsDetail'
import AdminPortalFirmsDetail from '@/components/portal/AdminFirmsDetail'
import PortalDealsList from '@/components/portal/FundsList'
import PortalDealsDetail from '@/components/portal/FundsDetail'
import AdminPortalDealsDetail from '@/components/portal/AdminFundsDetail'
import PortalContactsList from '@/components/portal/Contactslist'
import PortalContactsDetail from '@/components/portal/ContactsDetail'
import AdminPortalContactsDetail from '@/components/portal/AdminContactsDetail'
import PortalDiversityList from '@/components/portal/DiversityList'
import PortalDiversityDetail from '@/components/portal/DiversityDetail'
import PortalInviteList from '@/components/portal/PortalInviteList'
import PortalInvite from '@/components/portal/PortalInvite'

import CalendarDetail from '@/components/calmeeting/CalendarDetail'
import CalendarList from '@/components/calmeeting/CalendarList'
import CalendarView from '@/components/calmeeting/CalendarView'

import { BrowseService, UserService } from '@/services/user-service'

import store from '@/store'
import Axios from 'axios'
import Constants from '@/constants'
import { PublicClientApplication } from '@azure/msal-browser'
import { UmbrellaMsalService } from '../services/umbrella-msal-service'


Vue.use(Router)

function numberProp (route) {
  let number = route.params.id

  if (typeof number !== 'number') {
    number = Number(number)
  }
  return {
    id: number
  }
}

export const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {fmgCan: true, wilshireLegal: true}
    },
    {
      path: '/signin',
      name: 'Signin',
      component: Signin,
      meta: {ignoreAuth: true}
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
      meta: {ignoreAuth: true}
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {fmgCan: true, wilshireLegal: true}
    },
    {
      path: '/office/:id',
      name: 'Office',
      component: Office,
      props: true
    },
    {
      path: '/terms',
      name: 'Terms',
      component: Terms
    },
    {
      path: '/firms/list',
      name: 'FirmsList',
      component: FirmsList
    },
    {
      path: '/firms/add',
      name: 'FirmsNew',
      component: FirmsNew
    },
    {
      path: '/firms/detail/:id',
      name: 'FirmsDetail',
      component: FirmsDetail,
      props: numberProp
    },
    {
      path: '/tasks/list',
      name: 'TasksList',
      component: TasksList
    },
    {
      path: '/tasks/outreach',
      name: 'TasksOutreach',
      component: TasksOutreach
    },
    {
      path: '/deals/list',
      name: 'FundsList',
      component: FundsList
    },
    {
      path: '/deals/pipeline',
      name: 'FundsPipeline',
      component: FundsPipeline
    },
    {
      path: '/deals/screening',
      name: 'FundsScreening',
      component: FundsScreening
    },
    {
      path: '/deals/add',
      name: 'FundsNew',
      component: FundsNew
    },
    {
      path: '/deals/addnext/:id',
      name: 'FundsNewNext',
      component: FundsNewNext,
      props: numberProp
    },
    {
      path: '/deals/sourcing',
      name: 'FundsSourcing',
      component: FundsSourcing
    },
    {
      path: '/deals/detail/:id',
      name: 'FundsDetail',
      component: FundsDetail,
      props: numberProp,
      meta: {fmgCan: true}
    },
    {
      path: '/deals/voting-flr/:id',
      name: 'FundsFlrVoting',
      component: FundsFlrVoting,
      props: numberProp
    },
    {
      path: '/deals/rr-summary/:id',
      name: 'RrSummary',
      component: RrSummary,
      props: numberProp
    },
    {
      path: '/deals/voting-rr/:id',
      name: 'FundsRrVoting',
      component: FundsRrVoting,
      props: numberProp
    },
    {
      path: '/deals/flr-summary/:id',
      name: 'FlrSummary',
      component: FlrSummary,
      props: numberProp
    },
    {
      path: '/deals/allocation/:id',
      name: 'Allocation',
      component: Allocation,
      props: numberProp
    },    
    {
      path: '/contacts/list',
      name: 'ContactsList',
      component: ContactsList
    },
    {
      path: '/contacts/add',
      name: 'ContactsNew',
      component: ContactsNew
    },
    {
      path: '/contacts/detail/:id',
      name: 'ContactsDetail',
      component: ContactsDetail,
      props: numberProp
    },
    {
      path: '/legal/new',
      name: 'LegalNew',
      component: LegalNew
    },
    {
      path: '/legal/list',
      name: 'LegalList',
      component: LegalList,
      meta: {wilshireLegal: true}
    },
    {
      path: '/legal/status',
      name: 'LegalStatus',
      component: LegalStatus,
      meta: {wilshireLegal: true}
    },
    {
      path: '/legal/new-nda',
      name: 'LegalNDANew',
      component: LegalNDANew
    },
    {
      path: '/legal/nda/:id',
      name: 'LegalNDA',
      component: LegalNDA,
      props: numberProp,
      meta: {wilshireLegal: true}
    },
    {
      path: '/legal/new-aca',
      name: 'LegalAcaNew',
      component: LegalAcaNew
    },
    {
      path: '/legal/aca/:id',
      name: 'LegalAca',
      component: LegalAca,
      props: numberProp,
      meta: {wilshireLegal: true}
    },
    {
      path: '/legal/new-wff',
      name: 'LegalWffNew',
      component: LegalWffNew
    },
    {
      path: '/legal/wff/:id',
      name: 'LegalWff',
      component: LegalWff,
      props: numberProp,
      meta: {wilshireLegal: true}
    },
    {
      path: '/legal/new-wfe',
      name: 'LegalWfeNew',
      component: LegalWfeNew
    },
    {
      path: '/legal/wfe/:id',
      name: 'LegalWfe',
      component: LegalWfe,
      props: numberProp,
      meta: {wilshireLegal: true}
    },
    {
      path: '/legal/new-mis',
      name: 'LegalMisNew',
      component: LegalMisNew
    },
    {
      path: '/legal/mis/:id',
      name: 'LegalMis',
      component: LegalMis,
      props: numberProp,
      meta: {wilshireLegal: true}
    },
    {
      path: '/legal/new-ifc/:id?',
      name: 'LegalIfcNew',
      props: numberProp,
      component: LegalIfcNew
    },
    {
      path: '/legal/ifc/:id',
      name: 'LegalIfc',
      component: LegalIfc,
      props: numberProp,
      meta: {wilshireLegal: true}
    },
    {
      path: '/legal/new-ica',
      name: 'LegalNewIca',
      component: LegalIcaNew
    },
    {
      path: '/legal/ica/:id',
      name: 'LegalIca',
      component: LegalIca,
      props: numberProp,
      meta: {wilshireLegal: true}
    },
    {
      path: '/legal/new-kyc',
      name: 'LegalKycNew',
      component: LegalKycNew
    },
    {
      path: '/legal/kyc/:id',
      name: 'LegalKyc',
      component: LegalKyc,
      props: numberProp,
      meta: {wilshireLegal: true}
    },
    {
      path: '/meeting-minute/list',
      name: 'IcMeetingList',
      component: IcMeetingList
    },
    {
      path: '/meeting-minute/list-mrcma',
      name: 'IcMeetingListMrcMa',
      component: IcMeetingListMrcMa
    },
    {
      path: '/meeting-minute/list-icpm',
      name: 'IcMeetingListIcPM',
      component: IcMeetingListIcPM
    },
    {
      path: '/meeting-minute/mrcpm-new',
      name: 'IcMeetingNewMrcPM',
      component: IcMeetingNewMrcPM
    },
    {
      path: '/meeting-minute/mrcma-new',
      name: 'IcMeetingNewMrcMA',
      component: IcMeetingNewMrcMA
    },
    {
      path: '/meeting-minute/icpm-new',
      name: 'IcMeetingNewIcPM',
      component: IcMeetingNewIcPM
    },
    {
      path: '/meeting-minute/icpm/:id',
      name: 'IcMeetingIcPM',
      component: IcMeetingIcPM,
      props: numberProp
    },
    {
      path: '/meeting-minute/start',
      name: 'IcMeetingStart',
      component: IcMeetingStart
    },
    {
      path: '/meeting-minute/mrcpm/:id',
      name: 'IcMeetingMrcPM',
      component: IcMeetingMrcPM,
      props: numberProp
    },
    {
      path: '/meeting-minute/mrcma/:id',
      name: 'IcMeetingMrcMA',
      component: IcMeetingMrcMA,
      props: numberProp
    },
    {
      path: '/portfolio/list',
      name: 'PortfolioList',
      component: PortfolioList
    },
    {
      path: '/portfolio/new',
      name: 'PortfolioNew',
      component: PortfolioNew
    },
    {
      path: '/portfolio/detail/:id',
      name: 'PortfolioDetail',
      component: PortfolioDetail,
      props: numberProp
    },
    {
      path: '/portfolio/overview',
      name: 'PortfolioOver',
      component: PortfolioOver
    },
    {
      path: '/portfolio/summary',
      name: 'PortfolioSummary',
      component: PortfolioSummary
    },
    {
      path: '/portfolio/vote/:id',
      name: 'PortfolioVotingDetail',
      component: PortfolioVotingDetail,
      props: numberProp
    },
    {
      path: '/portfolio/screening/',
      name: 'AllocationScreening',
      component: AllocationScreening
    },
    {
      path: '/reports',
      name: 'Reports',
      component: Reports
    },
    {
      path: '/calendar',
      name: 'Calendar',
      component: Calendar
    },
    {
      path: '/notifications',
      name: 'Notification',
      component: Notification
    },
    {
      path: '/search/:id?',
      name: 'Search',
      component: GlobalSearch,
      props: true
    },
    {
      path: '/portal/deals',
      name: 'PortalDealsList',
      component: PortalDealsList
    },
    {
      path: '/portal/deal/:id',
      name: 'PortalDealsDetail',
      component: PortalDealsDetail,
      props: numberProp
    },
    {
      path: '/portal/admin-deal/:id',
      name: 'AdminPortalDealsDetail',
      component: AdminPortalDealsDetail,
      props: numberProp
    },
    {
      path: '/portal/contacts',
      name: 'PortalContactsList',
      component: PortalContactsList
    },
    {
      path: '/portal/contact/:id',
      name: 'PortalContactsDetail',
      component: PortalContactsDetail,
      props: numberProp
    },
    {
      path: '/portal/admin-contact/:id',
      name: 'AdminPortalContactsDetail',
      component: AdminPortalContactsDetail,
      props: numberProp
    },
    {
      path: '/portal/firms',
      name: 'PortalFirmsList',
      component: PortalFirmsList
    },
    {
      path: '/portal/firm/:id',
      name: 'PortalFirmsDetail',
      component: PortalFirmsDetail,
      props: numberProp
    },
    {
      path: '/portal/admin-firm/:id',
      name: 'AdminPortalFirmsDetail',
      component: AdminPortalFirmsDetail,
      props: numberProp
    },
    {
      path: '/portal/diversity',
      name: 'PortalDiversityList',
      component: PortalDiversityList
    },
    {
      path: '/portal/diversity/:id',
      name: 'PortalDiversityDetail',
      component: PortalDiversityDetail,
      props: numberProp
    },
    {
      path: '/portal/invite',
      name: 'PortalInvite',
      component: PortalInvite
    },
    {
      path: '/portal/invitelist',
      name: 'PortalInviteList',
      component: PortalInviteList
    },
    {
      path: '/meeting/list',
      name: 'CalendarList',
      component: CalendarList
    },
    {
      path: '/meeting/calendar',
      name: 'CalendarView',
      component: CalendarView
    },
    {
      path: '/meeting/detail/:id',
      name: 'CalendarDetail',
      component: CalendarDetail,
      props: numberProp
    },
    {
      path: '/opsteam/list',
      name: 'OpsList',
      component: OpsList
    },
    {
      path: '/opsteam/new/:id?',
      name: 'OpsNew',
      props: numberProp,
      component: OpsNew
    },
    {
      path: '/opsteam/detail/:id',
      name: 'OpsDetail',
      component: OpsDetail,
      props: numberProp
    },
    {
      path: '/User/:id?',
      name: 'UserInfo',
      component: UserInfo,
      props: numberProp
    },
    {
      path: '/Cache',
      name: 'CacheInfo',
      component: CacheInfo,
      props: numberProp
    },
    {
      path: '/opsteam/MonitoringComments',
      name: 'MonitoringComments',
      component: MonitoringComments
    },
    {
      path: '/Browse/',
      name: 'Browse',
      component: Browse
    },
    {
      path: '/version-check',
      name: 'VersionError',
      component: VersionError
    },
    {
      path: '/soon',
      name: 'Soon',
      component: Soon,
      meta: {ignoreAuth: true}
    },
    {
      path: '/release-notes',
      name: 'ReleaseNotes',
      component: ReleaseNotes
    },
    {
      path: '/no-access',
      name: 'Restricted',
      component: Restricted,
      meta: {ignoreAuth: true}
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
      meta: {ignoreAuth: true}
    },
    {
      path: '/server-error',
      name: 'ServerError',
      component: ServerError,
      props: (route) => ({
        errorObject: {},
        ...route.params
      }),
      meta: {ignoreAuth: true}
    }
  ]
})

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-useless-escape
  //const authCookie = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*\=\s*([^;]*).*$)|^.*$/, '$1')
  const authRequired = !to.matched.some(route => route.meta.ignoreAuth)
  const authCookie = localStorage.getItem('auth_token')
  var user = sessionStorage.getItem('currentUser')
  if (user !== null) {
    user = JSON.parse(user)
    store.commit('setActiveUser', user)
  }

  if (authCookie !== '' && user) {
    // const user = store.getters.authUser
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + authCookie
    BrowseService.addLog({urlFromName: from.name, urlToName: to.name, urlToId: to.params.id != null ? to.params.id.toString() : '', uiVersion: Constants.VERSION})
      .then((response) => {
        if (response.data !== Constants.VERSION) {
          next({name: 'VersionError'})
        }
      })

    next()
    // console.info('logged in user!', user)
    /* if ((user.groupId === 12 && !to.matched.some(route => route.meta.ignoreAuth) && !to.matched.some(route => route.meta.fmgCan)) ||
    (user.groupId === 13 && !to.matched.some(route => route.meta.ignoreAuth) && !to.matched.some(route => route.meta.wilshireLegal))) {
      // console.log('Restricted')
      next({name: 'Restricted'})
    } else {
      Axios.defaults.headers.common['Authorization'] = 'Bearer ' + authCookie
      next()
    } */
  } else if (authCookie === '' && !authRequired) {
    // console.info('no need to auth!')
    next()
  } else if (authCookie !== '' && user === null) {
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + authCookie
    store.dispatch('fetchAuthUser')
    .then(user => {
      next()
    })
  } else {
    if (authRequired) {
      if (Constants.ENABLE_CLOUD) {
        UmbrellaMsalService.login(to)
      } else {
        next({name: 'Signin', query: { redirectTo: to.path }})
      }
    } else {
      next()
    }
  }
})
