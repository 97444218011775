<template>
  <!-- Page-Title -->
  <div class="row">
    <div class="col-sm-12">
      <div class="page-title-box">
          <h4 class="page-title"><i :class="icon"></i> {{title}}
            <span v-if="isNew" class="badge badge-warning ml-2">New</span>
            <span v-if="isUpdated" class="badge badge-warning ml-2">Updated</span>
            <span v-if="!isNew && !isUpdated" class="badge badge-success ml-2">Imported</span>
            <span v-if="isDeleted" class="badge badge-danger ml-2">Deleted</span>
            <span v-if="isAgency" class="mdi mdi-clipboard-account text-success ml-2" v-tooltip="'Placement Agent'"></span>
            <span v-if="agencyName" class="badge badge-primary ml-2">{{agencyName}}</span>
            <span v-if="!firmImported && (isUpdated || isNew) && isFund"><i class='mdi mdi-flag text-danger' v-tooltip="'Associated firm must be imported before deal can be imported.'"/></span>
            <span v-if="firmImported && (isUpdated || isNew) && isFund"><i class='mdi mdi-flag text-success' v-tooltip="'Deal can be imported.'"/></span>
          </h4>
          <div class="clearfix"></div>
      </div>
      <div v-show="showToolbar" class="mb-3">
        <button class="btn btn-primary btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="inEditMode" @click="onCancel"><i class="mdi mdi-backup-restore"></i> <span>Cancel</span></button>
        <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="!inEditMode && !isAdminEdit" :disabled="(!firmImported && isFund) || !canEdit || isSaving" @click="onEditImport" ><i class="mdi mdi-import m-r-5"></i> <span>Start Import</span> </button>
        <button class="btn btn-warning btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="!inEditMode" :disabled="isSaving" v-if="isNavAdmin && isRestrictedUser  && !isAdminEdit" @click="onUnrestrictUser" ><i class="mdi mdi-account-check m-r-5"></i> <span v-if="!isSaving">Unrestrict</span> <i v-else class="fa fa-spinner fa-spin"></i></button>
        <button class="btn btn-secondary btn-custom waves-effect w-md waves-light m-b-5 mr-2" v-show="!inEditMode" :disabled="isSaving" v-if="isNavAdmin && !isEmailConfirmed  && !isAdminEdit" @click="onActivateUser" ><i class="mdi mdi-account-check m-r-5"></i> <span v-if="!isSaving">Activate</span> <i v-else class="fa fa-spinner fa-spin"></i></button>
        <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5" @click="onSave" v-show="inEditMode" :disabled="isSaving"><i class="mdi mdi-import m-r-5"></i> <span v-if="!isSaving">Import</span> <i v-else class="fa fa-spinner fa-spin"></i></button>
        <button class="btn btn-primary btn-custom waves-effect w-md waves-light m-b-5" @click="onAdminEdit" v-show="isNavAdmin && !isAdminEdit" :disabled="isSaving"><i class="mdi mdi-import m-r-5"></i> <span>Edit</span> </button>
        <button class="btn btn-success btn-custom waves-effect w-md waves-light m-b-5 m-r-5" @click="onSave" v-show="isAdminEdit && isNavAdmin" :disabled="isSaving"><i class="mdi mdi-import m-r-5"></i> <span>Save</span> </button>
        <button class="btn btn-warning btn-custom waves-effect w-md waves-light m-b-5" @click="onCancelAdminEdit" v-show="isAdminEdit && isNavAdmin" :disabled="isSaving"><i class="mdi mdi-import m-r-5"></i> <span>Cancel</span> </button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'No Title'
    },
    icon: {
      type: String,
      default: ''
    },
    showToolbar: {
      type: Boolean,
      default: false
    },
    inEditMode: {
      required: false,
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    isDeleted: {
      type: Boolean,
      required: true,
      default: false
    },
    isNew: {
      type: Boolean,
      required: true,
      default: false
    },
    isUpdated: {
      type: Boolean,
      required: true,
      default: false
    },
    isAgency: {
      type: Boolean,
      required: false,
      default: false
    },
    agencyName: {
      type: String,
      required: false,
      default: null
    },
    firmImported: {
      type: Boolean,
      required: true,
      default: false
    },
    isFund: {
      type: Boolean,
      required: true,
      default: false
    },
    isFirm: {
      type: Boolean,
      required: false,
      default: false
    },
    isAdminEdit: {
      type: Boolean,
      required: false,
      default: false
    },
     isNavAdmin: {
      type: Boolean,
      required: false,
      default: false
    },
    isRestrictedUser: {
      type: Boolean,
      required: false,
      default: false
    },
    isEmailConfirmed: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  methods: {
    onCancel () {
      this.$emit('onCancel')
    },
    onSave () {
      this.$emit('onSave')
    },
    onAdminEdit () {
       this.$emit('onAdminEdit')
    },
    onEditImport () {
      this.$emit('onEditImport')
    },
    onUnrestrictUser () {
      this.$emit('onUnrestrictUser')
    },
    onActivateUser () {
      this.$emit('onActivateUser')
    },
    onCancelAdminEdit () {
      this.$emit('onCancelAdminEdit')
    }
  }
}
</script>
