<template>
  <input v-if="show == 'input'" type="text" v-model="formatedDate" readonly class="form-control" v-tooltip="formatedDateAgo">
  <span v-else-if="show == 'full'" v-tooltip="formatedDateAgo">{{ fullFormatedDate }}</span>
  <span v-else v-tooltip="formatedDateAgo">{{ simpleFormatedDate }}</span>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    date: {
      required: false,
      type: String,
      default: ''
    },
    show: {
      type: String,
      default: 'input'
    },
    emptyIfNull: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatedDate () {
      if (this.date) {
        return moment(this.date).format('M/D/YYYY, h:mm a')
      } else {
        return ''
      }
    },
    fullFormatedDate () {
      if (this.date) {
        return moment(this.date).format('M/D/YYYY, h:mm:ss a')
      } else {
        return ''
      }
    },
    simpleFormatedDate () {
      if (this.date) {
        return moment(this.date).format('M/D/YYYY')
      } else {
        return ''
      }
    },
    formatedDateAgo () {
      if (this.date) {
        return moment(this.date).fromNow()
      } else {
        return ''
      }
    }
  }
}
</script>
