<template>
  <div v-if="asyncDataStatus_ready">
    <Topbar />
    <Sidebar :activeItem="'funds-list'"/>

    <div class="content-page">
      <!-- Start content -->
      <div class="content">
        <div class="container-fluid" v-if="allocation">
          <PageTitle :title="title" :icon="'mdi mdi-folder-star'"/>
          <div class="row pb-3 align-items-center" >
            <div class="col-md-3">
              <div class="mb-3">
                  <router-link :to="{name: 'FundsDetail', params: {id}}">
                    <button class="btn btn-primary btn-custom waves-effect w-md waves-light mr-2"><i class="mdi mdi-keyboard-backspace"></i> Back</button>
                  </router-link>
                  <button class="btn btn-danger btn-custom waves-effect w-md waves-light mr-2" v-if="isExists && canDelete && allocation.votes.length == 0" @click.prevent="deleteForm"><i class="mdi mdi-delete"></i> Delete</button>

                  <button class="btn btn-success btn-custom waves-effect w-md waves-light mr-2" v-if="isExists && allocation.isStartVoting && !allocation.isFinalized && isICMember" @click.prevent="submitVote"> <i class="mdi mdi-checkbox-marked-outline"></i> <span>Vote <i v-show="isLoading" class="fa fa-spinner fa-spin"></i></span> </button>
                  <button class="btn btn-warning btn-custom waves-effect w-md waves-light" v-if="isExists && allocation.isFinalized && allocation.versionNo == getMaxVersion && canCreateNewVersion" @click.prevent="createNewVersion"> <i class="mdi mdi-checkbox-marked-outline"></i> <span>Create New Version <i v-show="isLoading" class="fa fa-spinner fa-spin"></i></span> </button>
              </div>
            </div>
            <div class="col-md-9">
                <fieldset>
                    <legend class="w-auto">Investment Contingencies</legend>
                    <div class="row">
                      <div class="col-sm-3">
                        Legal DD Status <div class="text-primary">{{fundObj.legalDDStatus}}</div>
                      </div>
                      <div class="col-sm-3">
                        Operation DD Status <div class="text-primary">{{fundObj.operationDDStatus}}</div>
                      </div>
                      <div class="col-sm-6">
                        IC Meeting Contingencies <div class="text-primary" v-html="fundObj.approvalContingencies"></div>
                      </div>
                    </div>
                </fieldset> 
            </div>
            <div class="col-md-2" v-if="showVersionBar">                
                <ul class="pagination float-right">
                  <li class="page-item disabled"><a class="page-link" href="#">Other Version(s) <span><i v-show="isLoadingVersionData" class="fa fa-spinner fa-spin"></i></span></a></li>
                  <li class="page-item" :class="item.versionNo == allocation.versionNo ? 'active disabled':''" v-for="item in allocation.allocationVersions" :key="item.allocationId">
                    <a href="javascript:void(0)" v-if="getMaxVersion == item.versionNo" @click="getDetails()" class="page-link">{{item.versionNo}}</a>
                    <a href="javascript:void(0)" v-else @click="getVersion(item.versionNo)" class="page-link">{{item.versionNo}}</a>
                  </li>    
                </ul>           
            </div>
          </div>

          <div class="row">
            <form action="#" autocomplete="off" style="width:100%">
              <div class="col-md-12">
                <div class="card-box">
                    <ul class="nav nav-tabs tabs-bordered">
                        <li class="nav-item">
                            <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(1)" :class="{'active': activeTab == 1}">
                                <i class="mdi mdi-information-outline"></i> Summary
                            </a>
                        </li>
                        <li class="nav-item" v-if="isExists">
                            <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(2)" :class="{'active': activeTab == 2}">
                                <i class="mdi mdi-briefcase-check"></i> Votes
                            </a>
                        </li>
                    </ul>

                    <div class="tab-content">
                      <div class="tab-pane fade" :class="{'active show': activeTab == 1}">                          
                          <div class="row">
                            <div class="col-sm-6 col-xs-12">
                              <h4 class="header-title text-primary m-t-0" v-if="isExists && allocation.isStartRrVoting">Voting {{allocation.isFinalized ? 'Closed' : 'In Process'}}
                                <span v-if="allocation.isFinalized" class="header-title" :class="{'text-danger' : !allocation.isApproved, 'text-success' : allocation.isApproved}"> - {{allocation.isApproved ? 'Approved' : 'Declined'}}</span>
                                <span v-if="!(getStatusText == 'None')" class="badge badge-pill ml-2" :class="{'badge-secondary' : getStatusText == 'Inactive', 'badge-success' : getStatusText == 'Active'}">{{getStatusText}}</span>
                              </h4>
                              <div class="form-group">
                                <label for="InvestmentSize" class="col-form-label">Investment Size <span class="text-danger" v-if="!isExists">*</span></label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{curSymbol}}</span>
                                    </div>
                                    <input type="text" v-model.number="allocation.investmentSize" @input="resetAllocationAmounts" placeholder="Enter Investment Size" class="form-control" id="InvestmentSize" :disabled="isExists"
                                        @blur="$v.allocation.investmentSize.$touch()" :class="{ 'parsley-error': $v.allocation.investmentSize.$error }">
                                    <button class="btn btn-primary btn-custom waves-effect w-md waves-light" v-if="!isExists" :disabled="!notAnyNoneFinal" @click.prevent="calcAttributableSource"><span v-if="!notAnyNoneFinal" class="dynamic-tooltip" v-tooltip="'Finalize all rationales before calculating'"></span><i class="mdi mdi-calculator"></i> Calculate</button>
                                </div>
                                <template v-if="$v.allocation.investmentSize.$error">
                                  <ul class="parsley-errors-list filled">
                                    <li v-if="!$v.allocation.investmentSize.required" class="parsley-required">This value is required</li>
                                    <li v-if="!$v.allocation.investmentSize.min" class="parsley-required">This value is not valid.</li>
                                    <!-- <li v-if="!$v.allocation.investmentSize.max" class="parsley-required">This value is LESS than Total Allocation Amount.</li> -->
                                  </ul>
                                </template>
                              </div>
                            </div>
                            <div class="col-sm-6 col-xs-12" v-if="!isExists && differentXe.length > 0">
                              <label for="InvestmentSize" class="col-form-label">Exchange rates</label>
                                <div class="input-group mb-1" v-for="(xe, i) in differentXe" :key="xe[i]">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{xe.cur}}</span>
                                    </div>
                                    <input type="text" v-model="xe.rate" placeholder="Rate" class="form-control col-md-2" @input="calcEx" />
                                </div>
                            </div>
                          </div>

                        <div class="row" v-if="!isExists">
                            <div class="col-lg-12">                              
                                <div role="tablist" class="m-b-20">
                                    <div class="card">
                                        <div class="card-header" role="tab">
                                            <h5 class="mb-0 mt-0 font-16">
                                                <a href="javascript:void(0)" class="">
                                                    Eligible Vehicles <span class="text-white">({{eligibleVehicles.length}})</span>
                                                </a>
                                            </h5>
                                        </div>

                                        <div class="collapse" role="tabpanel" :class="{'show' : showEligibles} ">
                                            <div class="card-body">
                                                <table class="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th>Portfolio</th>
                                                      <th width="90">Allocation Request</th>
                                                      <th width="100">Attributable Source</th>
                                                      <th width="200">Allocation Amount</th>
                                                      <th width="100">Materiality Test</th>
                                                      <th>Rationale</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>                                                    
                                                    <tr v-for="vehicle in eligibleVehicles" :key="vehicle.portfolioId">
                                                      <!-- portoflio name -->
                                                      <td>
                                                        <span>{{vehicle.portfolioName}}</span>
                                                      </td>
                                                      <td>
                                                      <!-- allocation request -->
                                                        <span>
                                                          {{vehicle.symbol}}{{vehicle.allocationRequest}}
                                                          <div v-if="vehicle.symbol != curSymbol">
                                                            <small><em>{{curSymbol}}{{formatNumber(vehicle.allocationRequestFx)}}</em> <i class="mdi mdi-information-outline" v-tooltip="'Request Amount in Deal Currency'"></i></small>
                                                          </div>
                                                        </span>
                                                      </td>
                                                      <td>
                                                      <!-- attributable source -->
                                                        <span v-if="allocation.investmentSize < totalVehicleRequestSizeFx && vehicle.allocationRequest > 0 && countAllocationRequests > 1">
                                                           {{vehicle.isAttributable ? 'Yes' : 'No'}}
                                                        </span>
                                                        <span v-else>
                                                          N/A
                                                        </span>   
                                                      </td>
                                                      <td>
                                                      <!-- allocation amount -->
                                                        <!-- <span> -->
                                                        <span v-if="notAnyNoneFinal">
                                                          <div>
                                                            {{curSymbol}}{{formatNumber(vehicle.allocationAmountFx)}}
                                                            <i v-if="vehicle.currencyCode !== vehicle.dealCurrencyCode" class="mdi mdi-information-outline" v-tooltip="'Allocation Amount	in Deal Currency'"></i>
                                                            <i v-if="vehicle.force0" class="mdi mdi-undo-variant" v-tooltip="'Materiality threshold not met.  Allocation amount set to $0'"></i>                                         
                                                          </div>
                                                        </span>
                                                        <span v-else>-</span>
                                                      </td>
                                                      <td>
                                                        <!-- materiality test -->
                                                        <span v-if="allocation.investmentSize >= totalVehicleRequestSizeFx">
                                                          N/A
                                                        </span>
                                                        <span v-else-if="vehicle.allocationRequest > 0" :class="{'text-success' : vehicle.materialityResult, 'text-danger' : !vehicle.materialityResult}">
                                                          {{vehicle.materialityResult ? 'PASS' : 'FAIL'}}
                                                        </span>
                                                        <span v-else>
                                                          N/A
                                                        </span>
                                                      </td>
                                                      <td>
                                                      <!-- rationale -->
                                                        <span v-if="notAnyNoneFinal">
                                                          {{vehicle.rationale}}
                                                        </span>
                                                        <span v-else-if="vehicle.allocationRationaleId == 0" class="text-danger">Not Submitted - Contact Portfolio Management Team</span>
                                                        <span v-else-if="!vehicle.isFinalized" class="text-white">Not Finalized - Contact Portfolio Management Team</span>
                                                        <span v-else class="text-success">Finalized</span>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                  <tfoot>
                                                    <tr class="bold">
                                                      <th>Total:</th>
                                                      <th><span class="text-primary">{{curSymbol}}{{totalVehicleRequestSizeFx}}</span></th>
                                                      <th></th>
                                                      <th><span class="text-primary">{{curSymbol}}{{totalVehicleSizeFx}}</span></th>
                                                      <th></th>
                                                      <th></th>
                                                      <th></th>
                                                    </tr>
                                                  </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" role="tab" @click="showNonEligibles = !showNonEligibles">
                                            <h5 class="mb-0 mt-0 font-16">
                                                <a class="collapsed" href="javascript:void(0)">
                                                    Not Eligible Vehicles <span class="text-white">({{noneEligibleVehicles.length}})</span>
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="collapse" role="tabpanel" :class="{'show' : showNonEligibles} ">
                                            <div class="card-body">
                                                <table class="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th>Portfolio</th>
                                                      <th>Materiality Test</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr v-for="vehicle in noneEligibleVehicles" :key="vehicle.portfolioId">
                                                      <td>
                                                        <span>{{vehicle.portfolioName}}</span>
                                                      </td>
                                                      <td>
                                                        <span class="text-white">N/A</span>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-lg-12">                              
                                <div role="tablist" class="m-b-20">
                                    <div class="card">
                                        <div class="card-header" role="tab">
                                            <h5 class="mb-0 mt-0 font-16">
                                                Vehicles <span class="text-white">({{vehicles.length}})</span>
                                            </h5>
                                        </div>

                                        <div class="collapse show" role="tabpanel">
                                            <div class="card-body">
                                                <table class="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th>Portfolio</th>
                                                      <th width="90">Allocation Request</th>
                                                      <th width="100">Attributable Source</th>
                                                      <th width="200">Allocation Amount</th>
                                                      <th width="100">Materiality Test</th>
                                                      <th>Rationale</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr v-for="vehicle in allocation.vehicles" :key="vehicle.allocationVehicleId">
                                                      <td>
                                                        <label>{{vehicle.portfolioName}}</label>
                                                      </td>
                                                      <td>
                                                        <span>
                                                          {{vehicle.symbol}}{{vehicle.allocationRequest}}
                                                          <div v-if="vehicle.allocationRequest > 0 && vehicle.symbol != vehicle.dealSymbol">
                                                            <span class="initialism">{{vehicle.dealSymbol}}{{formatNumber(vehicle.allocationRequestFx)}}</span> <i class="mdi mdi-information-outline" v-tooltip="'Request Amount in Deal Currency'"></i>
                                                          </div>
                                                        </span>
                                                      </td>
                                                      <td>   
                                                        <!-- Two conditions need to be present for the attributable source to be active 
                                                        (1) Investment Size < Total AND 
                                                        (2) more than one portfolio needs to have allocation request > $0.  
                                                        && countAllocationRequests > 1 removed per Mark -->
                                                        <span v-if="allocation.investmentSize < totalVehicleRequestSizeFx && vehicle.allocationRequest > 0 && countAllocationRequests > 1">
                                                           {{vehicle.isAttributable ? 'Yes' : 'No'}}
                                                        </span>
                                                        <span v-else>
                                                          N/A
                                                        </span>   
                                                      </td>
                                                      <td>
                                                        <div class="input-group">
                                                          {{vehicle.symbol}}{{formatNumber(vehicle.allocationAmount)}}
                                                        </div>
                                                        <div v-if="vehicle.allocationAmount > 0 && vehicle.symbol != vehicle.dealSymbol">
                                                          <span class="initialism">{{vehicle.dealSymbol}}{{formatNumber(vehicle.allocationAmountFx)}}</span> <i class="mdi mdi-information-outline" v-tooltip="'Request Amount in Deal Currency'"></i>
                                                        </div>
                                                      </td>
                                                      <!-- materiality test -->
                                                      <td>
                                                        <span v-if="allocation.investmentSize >= totalVehicleRequestSizeFx">
                                                          N/A
                                                        </span>
                                                        <span v-else-if="vehicle.allocationRequest > 0" :class="{'text-success' : vehicle.materialityResult, 'text-danger' : !vehicle.materialityResult}">
                                                          {{vehicle.materialityResult ? 'PASS' : 'FAIL'}}
                                                        </span>
                                                        <span v-else>
                                                          N/A
                                                        </span>
                                                      </td>
                                                      <td>
                                                        {{vehicle.rationale}}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                  <tfoot>
                                                    <tr class="bold">
                                                      <th>Total:</th>
                                                      <th><span class="text-primary">{{curSymbol}}{{totalVehicleRequestSizeFx}}</span></th>
                                                      <th></th>
                                                      <th><span class="text-primary">{{curSymbol}}{{totalVehicleSizeFx}}</span></th>
                                                      <th></th>
                                                      <th></th>
                                                      <th></th>
                                                    </tr>
                                                  </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>

                        <div class="row">
                          <div class="col-sm-3 col-xs-6">
                            <div class="form-group">
                              <label class="col-form-label">Sum Attribute Source</label>
                              <div class="input-group">
                                  <div class="input-group-prepend">
                                      <span class="input-group-text">{{curSymbol}}</span>
                                  </div>
                                  <input type="text" v-model="sumAttribute" placeholder="Enter Investment Size" class="form-control" id="InvestmentSize" readonly="readonly">
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-3 col-xs-6">
                            <div class="form-group">
                              <label class="col-form-label">Sum Non-Attribute Source</label>
                              <div class="input-group">
                                  <div class="input-group-prepend">
                                      <span class="input-group-text">{{curSymbol}}</span>
                                  </div>
                                  <input type="text" v-model="sumNonAttribute" placeholder="Enter Investment Size" class="form-control" id="InvestmentSize" readonly="readonly">
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="row">
                          <div class="col-sm-6 col-xs-12">
                            <div class="form-group text-right ml-3">
                              <router-link :to="{name: 'FundsDetail', params: {id}}" class="btn btn-secondary waves-effect btn-custom mr-2" v-if="!isExists">
                                  Back
                              </router-link>
                              <button class="btn btn-primary btn-custom waves-effect w-md waves-light" :disabled="inSaving || totalVehicleSizeFx == 0" v-if="!isExists && canSubmit" @click.prevent="submit"><i class="mdi mdi-star-circle"></i> <i v-if="inSaving" class="fa fa-spinner fa-spin"></i><span v-else>Submit</span></button>
                            </div>

                            <span v-if="isExists">
                              <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                              <div :class="{'collapse': !showLogs }">
                                  <div class="form-group">
                                      <label>Created On</label>
                                      <AppDate :date="allocation.createdOn"/>
                                  </div>
                                  <div class="form-group">
                                      <label>Created By</label>
                                      <AppUser :userId="allocation.createdBy" />
                                  </div>
                                  <div class="form-group" v-if="allocation.updatedOn">
                                      <label>Modified On</label>
                                      <AppDate :date="allocation.updatedOn" :emptyIfNull="true"/>
                                  </div>
                                  <div class="form-group" v-if="allocation.updatedOn">
                                      <label>Modified By</label>
                                      <AppUser :userId="allocation.updatedBy" :emptyIfNull="true"/>
                                  </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="tab-pane fade" :class="{'active show': activeTab == 2}">
                          <div class="row" v-if="activeTab == 2">
                              <div class="col-lg-12">
                                <h4 class="header-title text-primary m-t-0">Voting {{allocation.isFinalized ? 'Closed' : 'In Process'}}
                                  <span v-if="allocation.isFinalized" class="header-title" :class="{'text-danger' : !allocation.isApproved, 'text-success' : allocation.isApproved}"> - {{allocation.isApproved ? 'Approved' : 'Declined'}}</span>
                                </h4>
                                <form action="#" autocomplete="off" class="vote-summary">
                                  <div class="row">
                                    <div class="col-sm-6 col-xs-12">

                                      <div class="form-group">
                                        <table class="table table-hover">
                                          <thead>
                                            <tr>
                                              <th>Vote</th>
                                              <th>Count</th>
                                              <th>Percentage</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr v-for="(stat, index) in statData" :key="index">
                                              <td>{{stat.rate}}</td>
                                              <td>{{stat.result}}</td>
                                              <td>{{stat.percent.toFixed(0)}}%</td>
                                            </tr>
                                          </tbody>
                                          <tfoot>
                                            <tr>
                                              <td class="text-right">Total:</td>
                                              <td>{{allocation.votes.length}}</td>
                                              <td></td>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </div>

                                      <div class="form-group" v-if="allocation.votes.filter(x => x.comment).length > 0">
                                        <h4 class="header-title text-primary">Comments</h4>
                                        <ul>
                                          <li v-for="(vote, index) in allocation.votes.filter(x => x.comment != null)" :key="vote.votingId">
                                            <div class="checkbox-primary" :class="{'checkbox' : !allocation.isFinalized}" @mouseover="hoverShow = 'v'+vote.votingId" @mouseleave="hoverShow = null">
                                                <input :id="`comm`+vote.votingId" type="checkbox" v-if="!allocation.isFinalized" v-model="comm[index]" class="voting-summary-checkbox" />
                                                <label :for="`comm`+vote.votingId">
                                                  <span class="vote-question" :class="{'del-text' : comm[index]}">{{vote.comment}}</span> <i class="text-dark">{{vote.fullName}} - <AppDate :date="vote.createdOn" :show="'label'"/></i>
                                                  <button class="btn btn-primary btn-sm btn-mini btn-icon" v-tooltip="'Answer'" type="submit" @click.prevent="showAnswer(vote.votingId)" v-if="!allocation.isFinalized && hoverShow == 'v'+vote.votingId" >
                                                      <i class="mdi mdi-comment-plus-outline"></i>
                                                  </button>
                                                </label>
                                            </div>
                                            <ul>
                                              <li v-for="(answer, index) in allocation.answers.filter(x => x.votingId === vote.votingId)" :key="answer.votingAnswerId">
                                                <div class="checkbox-primary" :class="{'checkbox' : !allocation.isFinalized}" @mouseover="hoverShow = 'a'+answer.votingAnswerId" @mouseleave="hoverShow = null">
                                                    <input :id="`answer`+answer.votingAnswerId" type="checkbox" v-if="!allocation.isFinalized" v-model="answer[index]" class="voting-summary-checkbox" />
                                                    <label :for="`answer`+answer.votingAnswerId">
                                                      <span class="vote-question" :class="{'del-text' : answer[index]}">{{answer.answer}}</span> <i class="text-dark">{{showUserName(answer.createdBy)}} - <AppDate :date="answer.createdOn" :show="'label'"/></i>
                                                      <button class="btn btn-primary btn-sm btn-mini btn-icon" v-tooltip="'Answer'" type="submit" @click.prevent="showAnswer(vote.votingId)" v-if="!allocation.isFinalized && hoverShow == 'a'+answer.votingAnswerId">
                                                          <i class="mdi mdi-comment-plus-outline"></i>
                                                      </button>
                                                    </label>
                                                </div>
                                              </li>
                                            </ul>
                                            <div v-show="vote.votingId == answeIndex">
                                              <textarea class="form-control" rows="3" id="answer" v-model.trim="forum.answer" @blur="$v.forum.answer.$touch()" :class="{ 'parsley-error': $v.forum.answer.$error }"></textarea>
                                              <template v-if="$v.forum.answer.$error">
                                                <ul class="parsley-errors-list filled">
                                                  <li v-if="!$v.forum.answer.required" class="parsley-required">This value is required</li>
                                                </ul>
                                              </template>
                                              <button class="btn btn-success btn-sm btn-icon mr-1" v-tooltip="'Submit Answer'" type="submit" @click.prevent="submitAnswer" >
                                                  <i v-if="isAnswering" class="fa fa-spinner fa-spin"></i>
                                                  <i v-else class="mdi mdi-content-save"></i>
                                              </button>
                                              <button class="btn btn-primary btn-sm btn-icon" v-tooltip="'Close'" type="submit" @click.prevent="hideAnswer" >
                                                  <i class="mdi mdi-close-circle"></i>
                                              </button>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>

                                      <div class="form-group" v-if="allocation.isStartVoting && allocation.missingVotes.length > 0">
                                        <h4 class="header-title text-warning m-t-0">Missing Votes:</h4>
                                        <ol>
                                          <li v-for="mis in allocation.missingVotes" :key="mis.fullName">
                                            {{mis.fullName}}
                                          </li>
                                        </ol>
                                      </div> 
                                      
                                    </div>

                                    <div class="col-sm-6 col-xs-12" v-if="allocation.isStartVoting">
                                      <h4 class="text-center mb-3 mt-3">IC Members</h4>
                                      <div class="d-flex justify-content-center">
                                        <pie-chart :chartData="pieData" />
                                      </div>

                                      <div class="mt-4">
                                          <table class="table table-bordered table-hover">
                                            <thead>
                                              <tr>
                                                <th>
                                                  IC Member
                                                </th>
                                                <th>
                                                  Vote
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td colspan="2" v-if="allocation.votes.length == 0" class="text-center">
                                                  No Voting to Show
                                                </td>
                                              </tr>
                                              <tr v-for="vote in allocation.votes" :key="vote.createdBy">
                                                <td>
                                                  <AppUser :userId="vote.createdBy" :show="'text'" :showpic="true"/>
                                                </td>
                                                <td>
                                                  {{vote.score == 1 ? 'Yes' : vote.score == null ? '-' : 'No'}}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                      </div>                  
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-sm-6 col-xs-12">
                                      <div class="form-group text-right ml-3">
                                        <button class="btn btn-warning btn-custom waves-effect w-md waves-light" v-if="!allocation.isStartVoting" @click.prevent="startVoting"><i class="mdi mdi-play-box-outline"></i> <span>Start Voting</span></button>
                                        <button class="btn btn-primary btn-custom waves-effect w-md waves-light" v-if="allocation.isStartVoting && isICMember && !allocation.isFinalized" @click.prevent="finalize"><i class="mdi mdi-approval"></i> <span>Close Voting</span></button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                          </div>
                      </div>

                    </div>

                </div>
              </div>
            </form>

          </div>


        </div>
      </div>
    </div>
    <div class="side-bar right-bar">
        <div class="col-sm-12">
          <SubmitVote @onUpdate="voteUpdate" :vote="sideVote" :type="2"/>
        </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'
import {mapActions, mapGetters} from 'vuex'
import { required, decimal, minValue } from 'vuelidate/lib/validators'
import { AllocationService, AllocationVotingService } from '@/services/allocation-service'
import { FundTeamService } from '@/services/fund-service'
import PieChart from '@/components/charts/pieChart'
import rightbar from '@/mixins/rightbar'
import SubmitVote from '@/components/portfolio/SubmitVote'

export default {
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  components: {
    PieChart,
    SubmitVote
  },
  data () {
    return {
      activeTab: 1,
      showEligibles: true,
      showNonEligibles: false,
      inSaving: false,
      showLogs: false,
      isExists: false,
      allocation: {investmentSize: 0},
      vehicles: [{'targetPosition': 0, 'allocationRequest': 0, 'attributableSource': 0, 'nonAttributableSource': 0, 'isAttributable': false, 'rationale': null, 'materialityResult': null}],
      currencyRates: [],
      differentXe: [],
      statData: [],
      pieData: {
        datasets: [
          {
            data: [0, 0],
            backgroundColor: ['#5aa762', '#007177']
          }
        ],
        labels: ['Approve', 'Decline']
      },
      yesCount: 0,
      noCount: 0,
      sideVote: {},
      hoverShow: false,
      answeIndex: null,
      isLoading: false,
      isLoadingVersionData: false,
      isAnswering: false,
      comm: [],
      forum: {answer: null},
      dealTeam: [],
      fundObj: []
    }
  },
  validations: {
    allocation: {
      investmentSize: { required, decimal, min: minValue(1) }
    },
    forum: {
      answer: { required }
    }
  },
  mixins: [asyncDataStatus, rightbar],
  methods: {
    ...mapActions(['fetchFundModel']),
    showTab (tabId) {
      this.activeTab = tabId
    },
    makeDataSet () {  
      this.statData = []
      this.yesCount = 0
      this.noCount = 0
      
      if (this.allocation == null || this.allocation.votes == null) {
        return
      }
      const t = this.allocation.votes.length
      const ry = this.allocation.votes.filter(x => x.score != null && x.score).length
      
      if (ry > 0) {
        this.statData.push({rate: 'Approve', result: ry, percent: (ry / t) * 100})
        this.yesCount = ry
      }

      const rn = this.allocation.votes.filter(x => x.score != null && !x.score).length
      if (rn > 0) {
        this.statData.push({rate: 'Decline', result: rn, percent: (rn / t) * 100})
        this.noCount = rn
      }
    },
    startVoting () {
      this.$snotify.clear()
      if (this.allocation.isRRICFinalized && !this.allocation.isRRICApproved) {
        this.$snotify.error('Voting for Allocation Recommendation can not be started for DECLINED Deal!')
        return
      }

      this.$snotify.confirm('Are you sure you want to Start Voting? It cannot be undone!', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                AllocationService.startVoting(this.allocation.allocationId)
                .then((response) => {
                  if (response.data) {
                    this.allocation.isStartVoting = true
                    this.$snotify.success('Voting Started')
                  }
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    finalize () {
      this.$snotify.clear()
      if (this.allocation.missingVotes.length > 0) {
        this.$snotify.warning('Allocation is missing votes from IC members!')
      }

      this.$snotify.confirm('Are you sure you want to finalize? It cannot be undone!', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                AllocationService.finalize(this.allocation.allocationId)
                .then((response) => {
                  this.allocation.isFinalized = true
                  this.isICMember = false
                  this.$snotify.clear()
                  this.$snotify.success('Voting closed.')
                  if (response.data) {
                    this.allocation.isApproved = response.data
                    this.$snotify.info('Allocation Approved!')
                  } else {
                    this.$snotify.info('Allocation Declined!')
                  }
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    createNewVersion () {
      this.$snotify.clear()
     
      this.$snotify.confirm('Are you sure you want to create new version? It cannot be undone!', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                AllocationService.createNewVersion(this.allocation.allocationId)
                .then((response) => {                 
                  this.$snotify.clear()                  
                  if (response && response.status === 200) {
                    this.isExists = false
                    this.$snotify.success('New version created.')
                  } else {
                    //this.allocation.votes.push({})
                    this.$snotify.warning('Unable to create new version for this allocation.')
                  }
                  this.getDetails()
                })                
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    submitVote () {
      this.isLoading = true
      AllocationVotingService.check(this.id)
      .then(
        (response) => {
          if (response.status !== 200) {
            this.sideVote = {votingId: this.id, score: 'null'}
          } else {
            this.sideVote = response.data
            this.sideVote.votingId = this.id
          }
          this.isLoading = false
          this.showRightbar()
        }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    voteUpdate (vt) {      
      if (vt == null) {
        this.allocation.isFinalized = true
        this.hideRightbar()
        return
      }
      // update Votes
      const v = this.allocation.votes.findIndex(x => x.createdBy === this.user.userId)
      if (v > -1) {
        this.allocation.votes[v].score = vt.score
        this.allocation.votes[v].comment = vt.comment
        this.makeDataSet()
      }
      // update Missing
      if (vt.score != null) {
        const i = this.allocation.missingVotes.findIndex(x => x.createdBy === this.user.userId)
        if (i > -1) {
          this.allocation.missingVotes.splice(i, 1)
        }
      }
      this.hideRightbar()
    },
    showAnswer (id) {
      if (this.answeIndex === id) {
        this.answeIndex = null
      } else {
        this.$v.forum.$reset()
        this.forum.answer = null
        this.answeIndex = id
      }
    },
    hideAnswer () {
      this.answeIndex = null
    },
    showUserName (userId) {
      let result = ''
      const usr = this.allUsers.find(e => e.userId === userId)
      if (this.userId === 0) {
        return 'System'
      }
      if (usr != null) {
        return usr.firstName + ' ' + usr.lastName
      }
      return result
    },
    submitAnswer () {
      this.$v.forum.$touch()
      if (this.$v.forum.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }
      const ans = { 'votingId': this.answeIndex, 'answer': this.forum.answer, 'createdOn': new Date(), 'createdBy': this.user.userId }
      this.isAnswering = true
      AllocationVotingService.answer(this.answeIndex, ans)
      .then(
        (response) => {
          if (response.status === 200) {
            this.allocation.answers.push(ans)
            this.$snotify.success('Your Answer submitted.')
          }
          this.isAnswering = false
          this.forum.answer = null
          this.answeIndex = null
        }).catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    submit () {
      this.$v.allocation.$touch()
      const errTA = this.totalVehicleSizeFx > 0
      if (!this.$v.allocation.$error && errTA) {        
        if (!this.isExists) {
          this.allocation.vehicles = this.vehicles
          this.inSaving = true
          if (this.allocation.allocationId > 0) {
            // this is new version
            AllocationService.save(this.allocation.allocationId, this.allocation)
              .then(
                (response) => {
                  if (response && response.status == 200) {
                    this.$snotify.success('Allocation updated')
                    this.inSaving = false
                    this.getDetails()
                  } else {
                    this.$snotify.warning('Unable to update allocation.')
                    this.inSaving = false
                  }                  
                }
              ).catch(error => this.$snotify.error('Server Error! ' + error.message))
          } else {
            AllocationService.add(this.allocation)
              .then(
                (response) => {
                  if (response && response.status == 200) {
                    this.$snotify.success('Allocation Created')
                    this.inSaving = false
                    this.getDetails()
                  } else {
                    this.$snotify.warning('Unable to create allocation.')
                    this.inSaving = false
                  }                  
                }
              ).catch(error => this.$snotify.error('Server Error! ' + error.message))
          }
          
        }
      } else {
        if (!errTA) {
          this.$snotify.warning('Total Allocation Amount is 0.')
        }
        this.$snotify.error('Please fix the errors.')
      }
    },
    deleteForm () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                AllocationService.delete(this.id)
                .then((response) => {
                  if (response.status === 200) {
                    this.isExists = false
                    this.$snotify.success('Allocation Deleted.')
                    this.getDetails()
                  } else {
                    this.allocation.votes.push({})
                    this.$snotify.warning('Allocation cannot be Deleted.')
                  }
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    calcAttributableSource () {
      
      if (this.allocation.investmentSize === 0) {
        return
      }
      let size = this.allocation.investmentSize
      let total = 0 // might delete - will check
      const vehicles = this.vehicles.filter(x => x.allocationRationaleId)
      vehicles.forEach(veh => {
        veh.materialityResult = false
        veh.force0 = null
      })
      const differentXe = this.differentXe

      new Promise(function (resolve, reject) {
        total = vehicles.map(x => x.allocationRequestFx).reduce((a, b) => a + b)
        total = (total * 100) / 100
        resolve(1)
      }).then(function () {
        // Attributable
        let sumA = 0
        let listA = vehicles.filter(x => x.isAttributable)
        if (listA.length > 0) {
          sumA = listA.map(x => x.allocationRequestFx).reduce((a, b) => a + b)
        }
        vehicles.filter(x => x.isAttributable && x.allocationRequestFx > 0).forEach(x => {
          const amnt = ((x.allocationRequestFx / sumA) * 100)
          if (size >= total) {
            x.allocationAmountFx = x.allocationRequestFx
            x.materialityResult = true
          } else {
            if (size >= sumA) {
              x.allocationAmountFx = x.allocationRequestFx
              x.materialityResult = true
            } else {
              x.allocationAmountFx = (size * amnt) / 100
              x.allocationAmountFx = (x.allocationAmountFx * 100) / 100
              x.materialityResult = x.allocationAmountFx > 0 && (x.allocationAmountFx >= x.allocationRequestFx / 2)
            }
          }
        })
        return 1
      }).then(function () {
        let sum = 0
        const list = vehicles.filter(x => x.isAttributable)
        if (list.length > 0) {
          sum = list.map(x => x.allocationAmountFx).reduce((a, b) => a + b)
        }
        size -= sum
        return 2
      }).then(function () {
        // None Attributable
        let sumN = 0
        const listN = vehicles.filter(x => !x.isAttributable)
        if (listN.length > 0) {
          sumN = listN.map(x => x.allocationRequestFx).reduce((a, b) => a + b)
        }
        const totalNonAtt = (sumN * 100) / 100
        vehicles.filter(x => !x.isAttributable && x.allocationRequestFx > 0).forEach(x => {
          const amnt = ((x.allocationRequestFx / sumN) * 100)
          if (size >= totalNonAtt) {
            x.allocationAmountFx = x.allocationRequestFx
            x.materialityResult = true
          } else {
            x.allocationAmountFx = (size * amnt) / 100
            x.allocationAmountFx = (x.allocationAmountFx * 100) / 100
            x.materialityResult = x.allocationAmountFx > 0 && (x.allocationAmountFx >= x.allocationRequestFx / 2)
          }
        })
        return 3
      }).then(function () {
        
        vehicles.filter(f => !f.materialityResult && f.allocationRequestFx === 0).forEach(x => {
          x.materialityResult = null
        })
        vehicles.filter(f => !f.materialityResult && f.allocationRequestFx > 0).forEach(x => {
          x.materialityResult = null
          x.allocationAmountFx = 0
          x.force0 = true
        })
        return 4
      }).then(function () {
        vehicles.forEach(x => {        
          if (x.currencyCode !== x.dealCurrencyCode) {
            const i = differentXe.findIndex(d => d.cur === x.currencyCode)
            if (i >= 0) {
              x.allocationAmount = (x.allocationAmountFx * differentXe[i].rate * 100) / 100
            }
          } else {
            x.allocationAmount = x.allocationAmountFx
          }
        })
        return 5
      })
    },
    getVehicles () {
      AllocationService.getVehicles(this.id)
      .then((response) => {                
        this.allocation.fundId = this.id
        this.vehicles = response.data      
        this.vehicles.map(a=> {
          a.materialityResult=null
          if (a.currencyCode === a.dealCurrencyCode) {
            a.allocationRequestFx = a.allocationRequest
          }
        })      
        this.asyncDataStatus_fetched('Wilshire Recommendation')
      })
      .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    prepareDataset () {
      this.makeDataSet()
      this.pieData = {
        datasets: [
          {
            data: [this.yesCount, this.noCount],
            backgroundColor: ['#5aa762', '#007177']
          }
        ],
        labels: ['Approve', 'Decline']
      }   
    },
    getVersion (versionNo) {
      this.isExists = false 
      this.isLoadingVersionData = true
      
      AllocationService.getVersion(this.id, versionNo)
        .then((response) => {                  
          if (response.status === 204) {
            this.getVehicles()
            this.prepareDataset()
            return
          }
          this.allocation = response.data          
          //if it is a new version then reload vehicles
          if (this.allocation.vehicles.length == 0) {            
            this.getVehicles()
            this.isLoadingVersionData = false
            this.prepareDataset()
            return
          }          
          this.vehicles = this.allocation.vehicles
          this.prepareDataset()
          this.isExists = true
          this.isLoadingVersionData = false
        })
        .then(() => {
          this.asyncDataStatus_fetched('Wilshire Allocation')
        })
        .then(() => {
          setTimeout(() => {
            if (this.vehicles.length > 0) {
              const xlist = []
              for (let v = 0; v < this.vehicles.length; v++) {
                if (this.vehicles[v].allocationRationaleId > 0 && this.vehicles[v].currencyCode !== this.vehicles[v].dealCurrencyCode && this.vehicles[v].isEligible) {
                  if (xlist.findIndex(x => x === this.vehicles[v].currencyCode) < 0) {
                    xlist.push(this.vehicles[v].currencyCode)
                    this.getRatesApi(this.vehicles[v].dealCurrencyCode, this.vehicles[v].currencyCode)
                  }
                }
              }
            }
          }, 1000)
        })
        .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    getDetails () {
      this.isExists = false           
      AllocationService.get(this.id)
      .then((response) => {                
        if (response.status === 204) {          
          this.showTab(1)    
          this.getVehicles()
          this.prepareDataset()
          return
        }
        this.allocation = response.data
        //if it is a new version then reload vehicles
        if (this.allocation.vehicles.length == 0) {
          this.showTab(1)    
          this.getVehicles()
          this.prepareDataset()
          return
        }
        this.vehicles = this.allocation.vehicles
        this.prepareDataset()        
        this.isExists = true
      })
      .then(() => {
        this.asyncDataStatus_fetched('Wilshire Allocation')
      })
      .then(() => {
        setTimeout(() => {
          if (this.vehicles.length > 0) {
            const xlist = []
            for (let v = 0; v < this.vehicles.length; v++) {
              if (this.vehicles[v].allocationRationaleId > 0 && this.vehicles[v].currencyCode !== this.vehicles[v].dealCurrencyCode && this.vehicles[v].isEligible) {
                if (xlist.findIndex(x => x === this.vehicles[v].currencyCode) < 0) {
                  xlist.push(this.vehicles[v].currencyCode)
                  this.getRatesApi(this.vehicles[v].dealCurrencyCode, this.vehicles[v].currencyCode)
                }
              }
            }
          }
        }, 1000)
      })
      .catch(error => this.$snotify.error('Server Error! ' + error.message))     
    },
    getRates (dest) {
      for (let key in this.currencyRates.rates) {
        if (key === dest) {
          return this.currencyRates.rates[key]
        }
      }
      return 0
    },
    getXeRates (c) {
      const r = this.differentXe.filter(x => x.cur === c)
      if (r.length > 0) {
        return r[0].rate
      }
      return 0
    },
    getRatesApi (base, dest) {
      this.differentXe = []
      fetch('https://api.exchangeratesapi.io/latest?base=' + base)
      .then((response) => {        
        return response.json()
      })
      .then((data) => {        
        this.currencyRates = data
      })
      .then(() => {        
        const rate = this.getRates(dest)
        this.differentXe.push({'cur': dest, 'rate': rate, 'xe': 0})
      })
      .catch(error => this.$snotify.error('API Error! ' + error.message))
    },
    getDealTeamMembers () {
      FundTeamService.getByFundId(this.id).then(
        (response) => {
          this.dealTeam = response.data
        }
      )
    },
    calcEx () {
      const vehicles = this.vehicles.filter(x => x.allocationRationaleId)
      vehicles.forEach(x => {
          const i = this.differentXe.findIndex(d => d.cur === x.currencyCode)
          if (i >= 0) {
            // set fx using rate if rate exists; else 0
              x.allocationRequestFx = 
              !(this.differentXe[i].rate > 0) ? 0
              : (x.allocationRequest / this.differentXe[i].rate * 100) / 100
          } else {
            x.allocationRequestFx = x.allocationRequest
          }
          x.allocationAmount = x.allocationAmountFx = 0
          x.materialityResult = false
          x.force0 = null
        })
    },
    resetAllocationAmounts () {
      this.vehicles.filter(x => x.allocationRationaleId).forEach(x => {
        x.allocationAmount = x.allocationAmountFx = 0
        x.materialityResult = false
        x.force0 = null
      })
    },
    formatNumber (value) {
      if (isNaN(value)) {
        return 0
      }
      return Math.round(parseFloat(value)*100)/100
    }
    // convertAllocation (row) {
    //   if (row.currencyCode !== row.dealCurrencyCode) {
    //     const i = this.differentXe.findIndex(d => d.cur === row.currencyCode)
    //     if (i >= 0) {
    //       return Math.round(row.allocationRequest * differentXe[i].rate * 100) / 100
    //     }
    //   } else {
    //     return row.allocationRequest
    //   }
    //   return 0
    // }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    eligibleVehicles () {
      return this.vehicles.filter(x => x.isEligible)
    },
    noneEligibleVehicles () {
      return this.vehicles.filter(x => !x.isEligible)
    },
    allUsers () {
      return this.$store.state.allUsers
    },    
    curSymbol () {
      if (this.vehicles.length > 0) {
        const list = this.vehicles.filter(x => x.dealSymbol != null)
        if (list.length > 0) {
          return list[0].dealSymbol
        }
      }
      return ''
    },
    isICMember () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.INVESTMENT_COMMITTEE).length > 0 || this.isSystemAdmin
    },
    isSystemAdmin () {
      return this.user.roles.filter(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR).length > 0
    },
    canDelete () {
      return this.isSystemAdmin ||
            (this.user.groupId < 11 && ((this.dealTeam.length > 0 && this.dealTeam.findIndex(x => x.userId === this.user.userId) >= 0) || this.isICMember))
    },
    canUpdate () {
      return  this.isSystemAdmin || (this.user.groupId < 11 
              && ((this.dealTeam.length > 0 && this.dealTeam.findIndex(x => x.userId === this.user.userId) >= 0) || this.isICMember)
              && this.allocation.versionNo > 1
              && this.allocation.isApproved == false
              && this.allocation.isFinalized == false)
    },
    canCreateNewVersion () {
      return (this.user.groupId < 11 || this.isICMember) || this.isSystemAdmin
    },
    notAnyNoneFinal () {
      return this.vehicles.filter(x => x.isEligible && !x.isFinalized).length === 0
    },
    sumAttribute () {
      const list = this.vehicles.filter(x => x.isAttributable)
      if (list.length > 0) {
        let sm = list.map(x => x.allocationAmountFx).reduce((a, b) => a + b)
        return Math.round(sm * 100) / 100
      }
      return 0
    },
    sumNonAttribute () {
      const list = this.vehicles.filter(x => !x.isAttributable)
      if (list.length > 0) {
        let sm = list.map(x => x.allocationAmountFx).reduce((a, b) => a + b)
        return Math.round(sm * 100) / 100
      }
      return 0
    },
    canSubmit () {
      return this.isSystemAdmin || (this.user.groupId < 11 && this.notAnyNoneFinal && (this.dealTeam.length > 0 && this.dealTeam.findIndex(x => x.userId === this.user.userId) >= 0))
    },
    countVehicles () {
      if (this.vehicles.length > 0) {
        return this.vehicles.filter(x => x.allocationAmountFx > 0).length
      }
      return 0
    },
    countAllocationRequests () {
      if (this.vehicles.length > 0) {
        return this.vehicles.filter(x => x.allocationRequestFx > 0).length
      }
      return 0
    },
    totalVehicleSizeFx () {
      if (this.vehicles.length > 0) {
        // Sum Array
        let sm = this.vehicles.map(x => x.allocationAmountFx).reduce((a, b) => a + b)
        return Math.round(sm * 100) / 100
      }
      return 0
    },
    totalVehicleRequestSizeFx () {
      if (this.vehicles.length > 0) {
        // Sum Array
        let sm = this.vehicles.map(x => x.allocationRequestFx).reduce((a, b) => a + b)
        return Math.round(sm * 100) / 100
      }
      return 0
    },
    title () {
      if (this.allocation && this.allocation.versionNo > 1) {
        return 'Wilshire Allocation [Version: ' + this.allocation.versionNo  + '] - ' + this.fundObj.fundName
      } else {
        return 'Wilshire Allocation - ' + this.fundObj.fundName
      }
    },
    getMaxVersion () {      
      if (this.allocation && this.allocation.allocationVersions) {
        return Math.max.apply(null, this.allocation.allocationVersions.map(function(o) { return o.versionNo }))
      }
      return 0
    },
    showVersionBar () {
      return this.allocation.allocationVersions && this.allocation.allocationVersions.length > 1
    },
    getStatusText () {  
      //todo: get isFinalized in allocation version      
      if (!this.isExists) return 'None'
      else return this.allocation.versionNo == this.getMaxVersion ? 'Active' : 'Inactive'
    }
  },
  created () {
     this.fetchFundModel({fundId: this.id})
      .then((fResponse) => {
        this.fundObj = fResponse.data
        //console.log('fResponse.data', fResponse.data)
      })   
    this.getDealTeamMembers()
    this.getDetails()
  }
}
</script>
<style scoped>
.dynamic-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>