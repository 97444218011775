<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'meeting-start'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Meeting Minutes'" :icon="'mdi mdi-file-document'"/>
                    <div class="card-box">
                      <div class="row">
                        <div class="col-lg-12">
                          <h4 class="header-title m-t-0">Create a New Meeting Minutes</h4>
                          <p class="text-muted font-14 m-b-20">
                              Please choose a Meeting Type
                          </p>

                          <div class="row">
                              <div class="col-sm-4 col-xs-12">
                                  <div class="card m-b-20 card-body">
                                      <div class="card-inverse">
                                          <h4 class="card-title">Private Markets Manager Research Committee</h4>
                                          <router-link :to="{name: 'IcMeetingNewMrcPM'}" class="btn btn-primary">
                                            Create
                                          </router-link>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-sm-4 col-xs-12">
                                  <div class="card m-b-20 card-body">
                                      <div class="card-inverse">
                                          <h4 class="card-title">Private Markets Investment Committee</h4>
                                          <router-link :to="{name: 'IcMeetingNewIcPM'}" class="btn btn-primary">
                                            Create
                                          </router-link>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-sm-4 col-xs-12">
                                  <div class="card m-b-20 card-body">
                                      <div class="card-inverse">
                                          <h4 class="card-title">Marketable Alternatives Manager Research Committee</h4>
                                          <router-link :to="{name: 'IcMeetingNewMrcMA'}" class="btn btn-primary">
                                            Create
                                          </router-link>
                                      </div>
                                  </div>
                              </div>

                          </div>

                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
  },
  mixins: [asyncDataStatus],
  created () {
    this.asyncDataStatus_fetched('Create a new Meeting Minutes')
  }
}
</script>
