<template>
    <div id="side-menu" class="left side-menu">
        <div class="slimScrollDiv">
            <div class="sidebar-inner slimscrollleft" >
                <!--- Divider -->
                <div id="sidebar-menu">
                    <ul>
                        <!-- <li class="menu-title">Main</li> -->
                        <li :class="{'active': activeItem === 'dashboard'}">
                            <router-link :to="{name: 'Home'}"
                                class="waves-effect waves-primary" :class="{'active': activeItem === 'dashboard'}">
                                <i class="mdi mdi-home"></i>
                                <span> Dashboard </span>
                            </router-link>
                        </li>

                        <li :class="{'active': activeItem === 'calendar'}">
                            <router-link :to="{name: 'Calendar'}"
                                class="waves-effect waves-primary" :class="{'active': activeItem === 'calendar'}">
                                <i class="mdi mdi-calendar"></i>
                                <span> Calendar </span>
                            </router-link>
                        </li>


                        <li class="has_sub" :class="{'active': activeItem === 'calmeeting-list' || activeItem === 'calmeeting-view'}">
                            <a id="menu-calmeeting-top" href="javascript:void(0)" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'calmeeting-list'}"  @click="openMenu('menu-calmeeting-top')">
                                <i class="mdi mdi-timetable"></i>
                                <span> Meetings </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-calmeeting" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'calmeeting-list' || activeItem === 'calmeeting-view'))}">
                                <li :class="{'active' : activeItem === 'calmeeting-list'}"><router-link :to="{name: 'CalendarList'}" :class="{'active' : activeItem === 'calmeeting-list'}">List</router-link></li>
                                <li :class="{'active' : activeItem === 'calmeeting-view'}"><router-link :to="{name: 'CalendarView'}" :class="{'active' : activeItem === 'calmeeting-list'}">Calendar</router-link></li>
                            </ul>
                        </li>

                        <li class="has_sub" :class="{'active': activeItem === 'funds-list' || activeItem === 'funds-add'}" v-if="user.groupId < 13">
                            <a id="menu-funds-top" href="javascript:void(0)" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'funds-list' || activeItem === 'funds-add'}" @click="openMenu('menu-funds-top')" >
                                <i class="mdi mdi-square-inc-cash"></i>
                                <span> Deals </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-funds" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'funds-list' || activeItem === 'funds-add' || activeItem === 'funds-sor' || activeItem === 'funds-pipe' || activeItem === 'funds-fmg' || activeItem === 'funds-screen'))}">
                                <li :class="{'active' : activeItem === 'funds-list'}"><router-link :to="{name: 'FundsList'}" :class="{'active' : activeItem === 'funds-list'}">List</router-link></li>
                                <li :class="{'active' : activeItem === 'funds-add'}"><router-link :to="{name: 'FundsNew'}" :class="{'active' : activeItem === 'funds-add'}">Add</router-link></li>
                                <li :class="{'active' : activeItem === 'funds-screen'}"><router-link :to="{name: 'FundsScreening'}" :class="{'active' : activeItem === 'funds-screen'}">Screening </router-link></li>
                                <li :class="{'active' : activeItem === 'funds-pipe'}"><router-link :to="{name: 'FundsPipeline'}" :class="{'active' : activeItem === 'funds-pipe'}">Pipeline </router-link></li>
                                <li :class="{'active' : activeItem === 'funds-sor'}"><router-link :to="{name: 'FundsSourcing'}" :class="{'active' : activeItem === 'funds-sor'}"> Sourcing</router-link></li>
                            </ul>
                        </li>

                        <li class="has_sub" :class="{'active': activeItem === 'firms-list' || activeItem === 'firms-add'}" v-if="user.groupId < 11 || user.groupId == 15">
                            <a id="menu-firms-top" href="javascript:void(0)" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'firms-list' || activeItem === 'firms-add' || activeItem === 'firms-outreach'}"  @click="openMenu('menu-firms-top')">
                                <i class="mdi mdi-factory"></i>
                                <span> Firms </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-firms" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'firms-list' || activeItem === 'firms-add' || activeItem === 'firms-outreach'))}">
                                <li :class="{'active' : activeItem === 'firms-list'}"><router-link :to="{name: 'FirmsList'}" :class="{'active' : activeItem === 'firms-list'}">List</router-link></li>
                                <li v-if="user.groupId < 11" :class="{'active' : activeItem === 'firms-add'}"><router-link :to="{name: 'FirmsNew'}" :class="{'active' : activeItem === 'firms-add'}">Add</router-link></li>
                            </ul>
                        </li>

                        <li class="has_sub" :class="{'active': activeItem === 'portfolio-list' || activeItem === 'portfolio-add'}">
                            <a href="javascript:void(0)" id="menu-portfolio-top" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'portfolio-list' || activeItem === 'portfolio-add' || activeItem === 'portfolio-overview' || activeItem === 'portfolio-summary' || activeItem === 'portfolio-screening' }" @click="openMenu('menu-portfolio-top')">
                                <i class="mdi mdi-briefcase"></i>
                                <span> Portfolios </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-portfolio" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'portfolio-list' || activeItem === 'portfolio-add' || activeItem === 'portfolio-overview' || activeItem === 'portfolio-summary' || activeItem === 'portfolio-screening'))}">
                                <li :class="{'active' : activeItem === 'portfolio-list'}"><router-link :to="{name: 'PortfolioList'}" :class="{'active' : activeItem === 'portfolio-list'}">List</router-link></li>
                                <li :class="{'active' : activeItem === 'portfolio-add'}"><router-link :to="{name: 'PortfolioNew'}" :class="{'active' : activeItem === 'portfolio-add'}">Add</router-link></li>
                                <li :class="{'active' : activeItem === 'portfolio-overview'}"><router-link :to="{name: 'PortfolioOver'}" :class="{'active' : activeItem === 'portfolio-overview'}"> Overview</router-link></li>
                                <li :class="{'active' : activeItem === 'portfolio-summary'}"><router-link :to="{name: 'PortfolioSummary'}" :class="{'active' : activeItem === 'portfolio-summary'}"> Summary </router-link></li>
                                <li :class="{'active' : activeItem === 'portfolio-screening'}"><router-link :to="{name: 'AllocationScreening'}" :class="{'active' : activeItem === 'portfolio-screening'}"> Allocations <span v-if="new Date().getTime() < 1638921600000" class="badge badge-success push-right">New</span></router-link></li>
                            </ul>
                        </li>

                        <li class="has_sub" :class="{'active': activeItem === 'tasks-list' || activeItem === 'tasks-add'}" v-if="user.groupId < 11 || user.groupId == 15">
                            <a id="menu-tasks-top" href="javascript:void(0)" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'tasks-list' || activeItem === 'tasks-add' || activeItem === 'tasks-outreach'}"  @click="openMenu('menu-tasks-top')">
                                <i class="mdi mdi-clipboard-check"></i>
                                <span> Tasks <span v-if="new Date().getTime() < 1639526400000" class="badge badge-success push-right">New</span></span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-tasks" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'tasks-list' || activeItem === 'tasks-add' || activeItem === 'tasks-outreach'))}">
                                <li :class="{'active' : activeItem === 'tasks-list'}"><router-link :to="{name: 'TasksList'}" :class="{'active' : activeItem === 'tasks-list'}">List</router-link></li>
                                <li v-if="user.groupId < 11" :class="{'active' : activeItem === 'tasks-outreach'}"><router-link :to="{name: 'TasksOutreach'}" :class="{'active' : activeItem === 'tasks-outreach'}">Outreach </router-link></li>
                            </ul>
                        </li>

                        <li class="has_sub" :class="{'active': activeItem === 'contacts-list' || activeItem === 'contacts-add'}">
                            <a id="menu-contacts-top" href="javascript:void(0)" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'contacts-list' || activeItem === 'contacts-add'}" @click="openMenu('menu-contacts-top')" >
                                <i class="mdi mdi-contacts"></i>
                                <span> Contacts </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-contacts" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'contacts-list' || activeItem === 'contacts-add'))}">
                                <li :class="{'active' : activeItem === 'contacts-list'}"><router-link :to="{name: 'ContactsList'}" :class="{'active' : activeItem === 'contacts-list'}">List</router-link></li>
                                <li :class="{'active' : activeItem === 'contacts-add'}"><router-link :to="{name: 'ContactsNew'}" :class="{'active' : activeItem === 'contacts-add'}">Add</router-link></li>
                            </ul>
                        </li>

                        <li class="has_sub" :class="{'active': activeItem === 'legal-list' || activeItem === 'legal-add'}" v-if="user.groupId < 11 || user.groupId == 13">
                            <a href="javascript:void(0)" id="menu-legal-top" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'legal-list' || activeItem === 'legal-add'}" @click="openMenu('menu-legal-top')">
                                <i class="mdi mdi-scale-balance"></i>
                                <span> Legal Workflow </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-legal" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'legal-list' || activeItem === 'legal-add' || activeItem === 'legal-status'))}">
                                <li :class="{'active' : activeItem === 'legal-list'}"><router-link :to="{name: 'LegalList'}" :class="{'active' : activeItem === 'legal-list'}">List</router-link></li>
                                <li :class="{'active' : activeItem === 'legal-add'}" v-if="user.groupId < 11"><router-link :to="{name: 'LegalNew'}" :class="{'active' : activeItem === 'legal-add'}">Submit</router-link></li>
                                <li :class="{'active' : activeItem === 'legal-status'}"><router-link :to="{name: 'LegalStatus'}" :class="{'active' : activeItem === 'legal-status'}">Status Update </router-link></li>
                            </ul>
                        </li>

                        <li class="has_sub" :class="{'active': activeItem === 'ops-list' || activeItem === 'ops-add' || activeItem === 'ops-comments'}">
                            <a href="javascript:void(0)" id="menu-ops-top" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'ops-list' || activeItem === 'ops-add' || activeItem === 'ops-comments'}" @click="openMenu('menu-ops-top')">
                                <i class="mdi mdi-opera"></i>
                                <span> Ops Workflow </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-ops" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'ops-list' || activeItem === 'ops-add' || activeItem === 'ops-comments'))}">
                                <li :class="{'active' : activeItem === 'ops-list'}"><router-link :to="{name: 'OpsList'}" :class="{'active' : activeItem === 'ops-list'}">List</router-link></li>
                                <li :class="{'active' : activeItem === 'ops-add'}" v-if="user.groupId < 11"><router-link :to="{name: 'OpsNew'}" :class="{'active' : activeItem === 'ops-add'}">Submit</router-link></li>
                                <li :class="{'active' : activeItem === 'ops-comments'}"><router-link :to="{name: 'MonitoringComments'}" :class="{'active' : activeItem === 'ops-comments'}">
                                  Monitoring Comments </router-link></li>
                            </ul>
                        </li>

                        <li class="has_sub" :class="{'active': activeItem === 'meeting-list' || activeItem === 'meeting-add'}">
                            <a href="javascript:void(0)" id="menu-meeting-top" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'meeting-list' || activeItem === 'meeting-add' || activeItem === 'meeting-start'}" @click="openMenu('menu-meeting-top')">
                                <i class="mdi mdi-file-document"></i>
                                <span> Meeting Minutes </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-meeting" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'meeting-list' || activeItem === 'meeting-list-icpm' || activeItem === 'meeting-list-mamrc' || activeItem === 'meeting-add' || activeItem === 'meeting-start'))}">
                                <li :class="{'active' : activeItem === 'meeting-list'}"><router-link :to="{name: 'IcMeetingList'}" :class="{'active' : activeItem === 'meeting-list'}">Private Markets MR</router-link></li>
                                <li :class="{'active' : activeItem === 'meeting-list-icpm'}"><router-link :to="{name: 'IcMeetingListIcPM'}" :class="{'active' : activeItem === 'meeting-list'}">Private Markets IC</router-link></li>
                                <li :class="{'active' : activeItem === 'meeting-list-mamrc'}"><router-link :to="{name: 'IcMeetingListMrcMa'}" :class="{'active' : activeItem === 'meeting-list'}">Marketable Alts MR</router-link></li>
                                <li :class="{'active' : activeItem === 'meeting-add' || activeItem === 'meeting-start'}"><router-link :to="{name: 'IcMeetingStart'}" :class="{'active' : activeItem === 'meeting-add'}">New</router-link></li>
                            </ul>
                        </li>

                        <li class="has_sub" :class="{'active': activeItem === 'portal-deals' || activeItem === 'portal-contacts' || activeItem === 'portal-firms'}">
                            <a href="javascript:void(0)" id="menu-portal-top" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'portal-contacts' || activeItem === 'portal-firms' || activeItem === 'portal-diversity'}" @click="openMenu('menu-portal-top')">
                                <i class="mdi mdi-web"></i>
                                <span> Navigator </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-portal" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'portal-deals' || activeItem === 'portal-contacts' || activeItem === 'portal-firms' || activeItem === 'portal-invites' || activeItem === 'portal-diversity'))}">
                                <li :class="{'active' : activeItem === 'portal-firms'}"><router-link :to="{name: 'PortalFirmsList'}" :class="{'active' : activeItem === 'portal-firms' || activeItem === 'portal-firm'}"> Firms</router-link></li>
                                <li :class="{'active' : activeItem === 'portal-deals'}"><router-link :to="{name: 'PortalDealsList'}" :class="{'active' : activeItem === 'portal-deals' || activeItem === 'portal-deal'}">Deals</router-link></li>
                                <li :class="{'active' : activeItem === 'portal-contacts'}"><router-link :to="{name: 'PortalContactsList'}" :class="{'active' : activeItem === 'portal-contacts' || activeItem === 'portal-contact'}">Employees </router-link></li>
                                <li :class="{'active' : activeItem === 'portal-diversity'}"><router-link :to="{name: 'PortalDiversityList'}" :class="{'active' : activeItem === 'portal-diversity' || activeItem === 'portal-diversity'}">Diversity </router-link></li>
                                <li :class="{'active' : activeItem === 'portal-invites'}"><router-link :to="{name: 'PortalInvite'}" :class="{'active' : activeItem === 'portal-invites' || activeItem === 'portal-invites'}">Invites <span v-if="new Date().getTime() < 1636675200000" class="badge badge-success push-right">New</span></router-link></li>
                            </ul>
                        </li>

                        <li :class="{'active': activeItem === 'reports'}">
                            <router-link :to="{name: 'Reports'}"
                                class="waves-effect waves-primary" :class="{'active': activeItem === 'reports'}">
                                <i class="mdi mdi-chart-bar"></i>
                                <span> Reports </span>
                            </router-link>
                        </li>

                        <li v-if="isAdmin" :class="{'active': activeItem === 'user-admin'}">
                            <router-link :to="{name: 'Office', params: {id: 'all'}}"
                                class="waves-effect waves-primary" :class="{'active': activeItem === 'user-admin'}">
                                <i class="mdi mdi-account-multiple"></i>
                                <span> Users </span>
                            </router-link>
                        </li>

                        <li v-if="isAdmin" :class="{'active': activeItem === 'cache-admin'}">
                            <router-link :to="{name: 'CacheInfo', params: {}}"
                                class="waves-effect waves-primary" :class="{'active': activeItem === 'cache-admin'}">
                                <i class="mdi mdi-access-point"></i>
                                <span> Cache </span>
                            </router-link>
                        </li>
                        
                        <!-- <li v-if="isAdmin" class="has_sub" :class="{'active': activeItem === 'user-admin' || activeItem === 'user-cache'}">
                            <a id="menu-users-top" href="javascript:void(0)" class="waves-effect waves-primary" :class="{'subdrop active': activeItem === 'user-admin' || activeItem === 'user-cache'}"  @click="openMenu('menu-users-top')">
                                <i class="mdi mdi-timetable"></i>
                                <span> Users </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul id="menu-users" class="list-unstyled" :class="{'expanded': (!isSideMenuCollapsed && (activeItem === 'user-admin' || activeItem === 'user-cache'))}">
                                <li :class="{'active' : activeItem === 'user-admin'}"><router-link :to="{name: 'Office', params: {id: 'all'}}" :class="{'active' : activeItem === 'user-admin'}">Admin</router-link></li>
                                <li :class="{'active' : activeItem === 'user-cache'}"><router-link :to="{name: 'Office', params: {id: 'all'}}" :class="{'active' : activeItem === 'user-cache'}">Cache</router-link></li>
                            </ul>
                        </li> -->
                        
                    </ul>

                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="slimScrollBar" style="background: rgb(152, 166, 173); width: 5px; position: absolute; top: 0px; opacity: 0.4; display: none; border-radius: 7px; z-index: 99; right: 1px; height: 535.096px; visibility: visible;"></div>
            <div class="slimScrollRail" style="width: 5px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    activeItem: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      isSideMenuCollapsed: null
    }
  },
  created () {
    this.isSideMenuCollapsed = document.getElementById('wrapper').classList.contains('enlarged')
  },
  methods: {
    openMenu (target) {
      let ttop = target.replace('-top', '')
      document.getElementById(target).classList.toggle('subdrop')
      document.getElementById(ttop).classList.toggle('expanded')
    }
  },
  computed: {
    ...mapGetters({
      user: 'authUser'
    }),
    isAdmin () {
      return this.user.roles.some(x => x.roleId === this.$userRoles.SYSTEM_ADMINISTRATOR)
    },
  }
}
</script>

<style scoped>
 .expanded {
    display: block !important;
 }
</style>
