<template>
  <div>
    <table class="table table-sm table-borderless">
      <tr>
        <td></td>
        <td></td>
        <td>
            <input v-model="fund.trsIndex1Name" @blur="$v.fund.trsIndex1Name.$touch()" maxlength="100" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsIndex1Name.$error }" class="form-control" placeholder="Enter Index Name 1"/>
            <template v-if="$v.fund.trsIndex1Name.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsIndex1Name.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
        </td>
        <td>
            <input v-model="fund.trsIndex2Name" @blur="$v.fund.trsIndex2Name.$touch()" maxlength="100" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsIndex2Name.$error }" class="form-control" placeholder="Enter Index Name 2"/>
            <template v-if="$v.fund.trsIndex2Name.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsIndex2Name.max" class="parsley-required">This value is too long</li>
              </ul>
            </template>
        </td>
      </tr>
      <tr>
        <td>
          <label>Return</label>
        </td>
        <td>
            <div class="input-group">
                <input v-model="fund.trsReturnDeal" @blur="$v.fund.trsReturnDeal.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsReturnDeal.$error }" class="form-control w-50"/>
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
            <template v-if="$v.fund.trsReturnDeal.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsReturnDeal.max" class="parsley-required">Cannot exceed 100%</li>
              </ul>
            </template>
        </td>
        <td>
            <div class="input-group">
                <input v-model="fund.trsReturnIndex1" @blur="$v.fund.trsReturnIndex1.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsReturnIndex1.$error }" class="form-control" />
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
            <template v-if="$v.fund.trsReturnIndex1.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsReturnIndex1.max" class="parsley-required">Cannot exceed 100%</li>
              </ul>
            </template>
        </td>
        <td>
            <div class="input-group">
                <input v-model="fund.trsReturnIndex2" @blur="$v.fund.trsReturnIndex2.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsReturnIndex2.$error }" class="form-control" />
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
            <template v-if="$v.fund.trsReturnIndex2.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsReturnIndex2.max" class="parsley-required">Cannot exceed 100%</li>
              </ul>
            </template>
        </td>
      </tr>
      <tr>
        <td>
          <label>Volatility</label>
        </td>
        <td>
            <div class="input-group">
                <input v-model="fund.trsVolatilityDeal" @blur="$v.fund.trsVolatilityDeal.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsVolatilityDeal.$error }" class="form-control" />
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
            <template v-if="$v.fund.trsVolatilityDeal.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsVolatilityDeal.max" class="parsley-required">Cannot exceed 100%</li>
              </ul>
            </template>
        </td>
        <td>
            <div class="input-group">
                <input v-model="fund.trsVolatilityIndex1" @blur="$v.fund.trsVolatilityIndex1.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsVolatilityIndex1.$error }" class="form-control" />
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
            <template v-if="$v.fund.trsVolatilityIndex1.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsVolatilityIndex1.max" class="parsley-required">Cannot exceed 100%</li>
              </ul>
            </template>
        </td>
        <td>
            <div class="input-group">
                <input v-model="fund.trsVolatilityIndex2" @blur="$v.fund.trsVolatilityIndex2.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsVolatilityIndex2.$error }" class="form-control" />
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
            <template v-if="$v.fund.trsVolatilityIndex2.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsVolatilityIndex2.max" class="parsley-required">Cannot exceed 100%</li>
              </ul>
            </template>
        </td>
      </tr>
      <tr>
        <td>
          <label>Sortino</label>
        </td>
        <td>
            <input v-model="fund.trsSortinoDeal" @blur="$v.fund.trsSortinoDeal.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsSortinoDeal.$error }" class="form-control" />
            <template v-if="$v.fund.trsSortinoDeal.$error">
              <ul class="parsley-errors-list filled">
                <li class="parsley-required">This value is not valid</li>
              </ul>
            </template>
        </td>
        <td>
            <input v-model="fund.trsSortinoIndex1" @blur="$v.fund.trsSortinoIndex1.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsSortinoIndex1.$error }" class="form-control" />
            <template v-if="$v.fund.trsSortinoIndex1.$error">
              <ul class="parsley-errors-list filled">
                <li class="parsley-required">This value is not valid</li>
              </ul>
            </template>
        </td>
        <td>
            <input v-model="fund.trsSortinoIndex2" @blur="$v.fund.trsSortinoIndex2.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsSortinoIndex2.$error }" class="form-control" />
            <template v-if="$v.fund.trsSortinoIndex2.$error">
              <ul class="parsley-errors-list filled">
                <li class="parsley-required">This value is not valid</li>
              </ul>
            </template>
        </td>
      </tr>
      <tr>
        <td>
          <label>Correlation</label>
        </td>
        <td></td>
        <td>
            <input v-model="fund.trsCorrelationIndex1" @blur="$v.fund.trsCorrelationIndex1.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsCorrelationIndex1.$error }" class="form-control"/>
            <template v-if="$v.fund.trsCorrelationIndex1.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsCorrelationIndex1.max" class="parsley-required">This value should be between -1 and 1</li>
              </ul>
            </template>
        </td>
        <td>
            <input v-model="fund.trsCorrelationIndex2" @blur="$v.fund.trsCorrelationIndex2.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsCorrelationIndex2.$error }" class="form-control"/>
            <template v-if="$v.fund.trsCorrelationIndex2.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsCorrelationIndex2.max" class="parsley-required">This value should be between -1 and 1</li>
              </ul>
            </template>
        </td>
      </tr>
      <tr>
        <td>
          <label>Beta</label>
        </td>
        <td></td>
        <td>
            <input v-model="fund.trsBetaIndex1" @blur="$v.fund.trsBetaIndex1.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsBetaIndex1.$error }" class="form-control" />
            <template v-if="$v.fund.trsBetaIndex1.$error">
              <ul class="parsley-errors-list filled">
                <li class="parsley-required">This value is not valid</li>
              </ul>
            </template>
        </td>
        <td>
            <input v-model="fund.trsBetaIndex2" @blur="$v.fund.trsBetaIndex2.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsBetaIndex2.$error }" class="form-control" />
            <template v-if="$v.fund.trsBetaIndex2.$error">
              <ul class="parsley-errors-list filled">
                <li class="parsley-required">This value is not valid</li>
              </ul>
            </template>
        </td>
      </tr>
      <tr>
        <td>
          <label>Alpha</label>
        </td>
        <td></td>
        <td>
            <div class="input-group">
                <input v-model="fund.trsAlphaIndex1" @blur="$v.fund.trsAlphaIndex1.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsAlphaIndex1.$error }" class="form-control" />
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
            <template v-if="$v.fund.trsAlphaIndex1.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsAlphaIndex1.max" class="parsley-required">Cannot exceed 100%</li>
              </ul>
            </template>
        </td>
        <td>
            <div class="input-group">
                <input v-model="fund.trsAlphaIndex2" @blur="$v.fund.trsAlphaIndex2.$touch()" :disabled="!inEditMode" :class="{ 'parsley-error': $v.fund.trsAlphaIndex2.$error }" class="form-control" />
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
            <template v-if="$v.fund.trsAlphaIndex2.$error">
              <ul class="parsley-errors-list filled">
                <li v-if="!$v.fund.trsAlphaIndex2.max" class="parsley-required">Cannot exceed 100%</li>
              </ul>
            </template>
        </td>
      </tr>
    </table>
    <div>
      <div class="form-group">
        <label for="trsInceptionDate"> Inception Date</label>
        <date-picker v-model="fund.trsInceptionDate" id="trsInceptionDate" placeholder="Select Year and Month" :disabled="!inEditMode" calendar-class="calendar form-control" input-class="form-control" :monday-first="true" :typeable="false" :clear-button="inEditMode" :minimumView="'month'" :maximumView="'year'" :initialView="'year'" clear-button-icon="fa fa-times" format="MMMM yyyy" :bootstrap-styling="true"></date-picker>
      </div>
      <div class="form-group">
        <label for="trsPerformanceAsOfDate"> Performance as of</label>
        <date-picker v-model="fund.trsPerformanceAsOfDate" id="trsPerformanceAsOfDate" placeholder="Select Date" :disabled="!inEditMode" calendar-class="calendar form-control" input-class="form-control" :monday-first="true" :typeable="true" :clear-button="inEditMode" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
      </div>
      <div class="form-group">
        <label for="trsSource"> Source</label>
        <input v-model="fund.trsSource" id="trsSource" type="text" maxlength="100" :class="{ 'parsley-error': $v.fund.trsSource.$error }" class="form-control" @blur="$v.fund.trsSource.$touch()" :disabled="!inEditMode" />
      </div>
      <template v-if="$v.fund.trsSource.$error">
        <ul class="parsley-errors-list filled">
          <li v-if="!$v.fund.trsSource.max" class="parsley-required">Cannot exceed 100%</li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>

import DatePicker from 'vuejs-datepicker'
import { minValue, maxValue, maxLength, decimal } from 'vuelidate/lib/validators'

export default {
  props: {
    fund: {
      type: Object,
      required: true
    },
    inEditMode: {
      type: Boolean,
      required: true
    }
  },
  components: {
    DatePicker
  },
  validations: {
    fund: {
      trsIndex1Name: { max: maxLength(100) },
      trsIndex2Name: { max: maxLength(100) },
      trsReturnDeal: { decimal, max: maxValue(100) },
      trsReturnIndex1: { decimal, max: maxValue(100) },
      trsReturnIndex2: { decimal, max: maxValue(100) },
      trsVolatilityDeal: { decimal, max: maxValue(100) },
      trsVolatilityIndex1: { decimal, max: maxValue(100) },
      trsVolatilityIndex2: { decimal, max: maxValue(100) },
      trsSortinoDeal: { decimal },
      trsSortinoIndex1: { decimal },
      trsSortinoIndex2: { decimal },
      trsCorrelationIndex1: { decimal, min: minValue(-1), max: maxValue(1) },
      trsCorrelationIndex2: { decimal, min: minValue(-1), max: maxValue(1) },
      trsBetaIndex1: { decimal },
      trsBetaIndex2: { decimal },
      trsAlphaIndex1: { decimal, max: maxValue(100) },
      trsAlphaIndex2: { decimal, max: maxValue(100) },
      trsSource: { max: maxLength(100) }
    }
  }
}
</script>