import Constants from '../constants.js'
import Axios from 'axios'

export const ActivityService = {
  get (id) {
    return Axios.get(Constants.API_URL + '/activity/' + id)
  },
  getByObject (objectId, id) {
    return Axios.get(Constants.API_URL + '/activity/' + objectId + '/' + id)
  },
  getFirmActivities (firmId) {
    return Axios.get(Constants.API_URL + '/activity/firm/' + firmId)
  },
  getFundActivities (fundId) {
    return Axios.get(Constants.API_URL + '/activity/fund/' + fundId)
  },
  getContactActivities (contactId) {
    return Axios.get(Constants.API_URL + '/activity/3/' + contactId)
  },
  getPortfolioActivities (contactId) {
    return Axios.get(Constants.API_URL + '/activity/6/' + contactId)
  },
  getPortalFundActivities (fundId) {
    return Axios.get(Constants.API_URL + '/activity/7/' + fundId)
  },
  getPortalFirmActivities (firmId) {
    return Axios.get(Constants.API_URL + '/activity/8/' + firmId)
  },
  getPortalContactActivities (firmId) {
    return Axios.get(Constants.API_URL + '/activity/9/' + firmId)
  },
  getPortalDiversityActivities (firmId) {
    return Axios.get(Constants.API_URL + '/activity/12/' + firmId)
  },
  addByObject (objectId, value) {
    return Axios.post(Constants.API_URL + '/activity/' + objectId, value)
  },
  save (id, value) {
    return Axios.put(Constants.API_URL + '/activity/' + id, value)
  },
  delete (id) {
    return Axios.delete(Constants.API_URL + '/activity/' + id)
  }
}

export const ActivityTypeService = {
  getAll () {
    return Axios.get(Constants.API_URL + '/activitytype')
  }
}
