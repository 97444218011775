<template>
    <div class="mb-3">
      <h4 class="header-title m-t-5">Legal Team actions:</h4>
      <form action="#">
        <span v-for="status in allStatuses" :key="status.statusId">
            <button class="btn btn-custom waves-effect w-md waves-light m-r-10" :class="status.colorClass" :disabled="isSameStatus(status.statusId)"
              @click.prevent="update(status.statusId)"><i class="mdi m-r-5" :class="status.actionIcon"></i> <span>{{status.actionName}}</span>
            </button>
        </span>
      </form>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    currentStatusId: {
      required: true,
      type: Number
    }
  },
  computed: {
    allStatuses () {
      return this.$store.state.allLegalRequestStatuses.filter(x => x.statusId > 1)
    }
  },
  methods: {
    ...mapActions(['fetchAllLegalRequestStatuses']),
    update (statusId) {
      this.$emit('onUpdateStatus', statusId)
    },
    isSameStatus (st) {
      return this.currentStatusId === st
    }
  },
  created () {
    this.fetchAllLegalRequestStatuses()
  }
}
</script>
