<template>
  <div>
    <ul class="nav nav-tabs tabs-bordered nav-justified">
      <li class="nav-item">
          <a href="javascript:void(0);" @click.prevent="close" class="right-bar-toggle float-right"><i class="mdi mdi-close-circle close-sidebar"></i></a>
          <a class="nav-link active show" @click.prevent="expandCollapse">
              <i class="mdi mdi-arrow-expand-left pull-left" v-if="!isExpand" v-tooltip="'Expand'"></i> 
              <i class="mdi mdi-arrow-expand-right pull-left" v-if="isExpand" v-tooltip="'Collapse'"></i>
              Note
          </a>
      </li>
    </ul>
    <form action="#" autocomplete="off" class="m-t-10" v-if="activity">
      <div class="form-group">
          <label for="act.Type">Type <span v-show="isEditMode" class="text-danger">*</span></label>
          <multiselect id="act.Type" v-show="!isEditMode" v-model="acTypeObj" :options="allTypes" label="activityTypeName" track-by="activityTypeId" :disabled="true"></multiselect>
          <multiselect id="act.Type" v-show="isEditMode" v-model="acTypeObj2" :options="allTypes2" label="activityTypeName" track-by="activityTypeId" :allow-empty="false" @input="typeSelected"></multiselect>
      </div>
      <div class="form-group">
          <label for="act.Subject">Subject <span v-show="isEditMode" class="text-danger">*</span></label>
          <input type="text" placeholder="Enter Subject" class="form-control" id="act.Subject" v-model="activity.activitySubject" :readonly="!isEditMode" @blur="$v.activity.activitySubject.$touch()" :class="{ 'parsley-error': $v.activity.activitySubject.$error }" />
          <template v-if="$v.activity.activitySubject.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.activity.activitySubject.required" class="parsley-required">This value is required</li>
              <li v-if="!$v.activity.activitySubject.min" class="parsley-required">This value is too short</li>
              <li v-if="!$v.activity.activitySubject.max" class="parsley-required">This value is too long</li>
            </ul>
          </template>
      </div>
      <div class="form-group">
          <label for="act.To">To <span v-if="isToSend" v-show="isEditMode" class="text-danger">*</span>
              <span class="checkbox checkbox-primary" v-if="activity.activityTypeId == 1">
                  <input id="sendEmail" type="checkbox" v-model="isToSend">
                  <label for="sendEmail">Send Email</label>
              </span>
          </label>

          <input type="text" placeholder="Enter To address" class="form-control" id="act.To" v-model="activity.activityTo" :readonly="!isEditMode" @blur="$v.activity.activityTo.$touch()" :class="{ 'parsley-error': $v.activity.activityTo.$error }" />
          <template v-if="$v.activity.activityTo.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.activity.activityTo.max" class="parsley-required">This value is too long</li>
              <li v-if="!$v.activity.activityTo.required" class="parsley-required">This value is required</li>
              <li v-if="$v.activity.activityTo.required && !$v.activity.activityTo.validEmail" class="parsley-required">Enter valid email address</li>
            </ul>
          </template>
      </div>
      <div class="form-group">
          <label for="act.Date">Date</label>
          <date-picker id="act.Date" v-model="activity.activityDate" placeholder="Select Date" calendar-class="calendar form-control" :disabled="!isEditMode" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
      </div>

      <div class="form-group" v-if="isEditMode && allTemplates.length > 0">
          <label for="act.Template">Template</label>
          <div class="form-inline">
            <select class="form-control form-control-sm col-11 mr-1" id="act.Template" v-model="selectedTemplate">
              <option value="0">Select</option>
              <option v-for="item in allTemplates" :key="item.templateId" :value="item.templateBody">{{item.templateName}}</option>
            </select>
            <div v-tooltip="'Apply Template to Body'">
              <button class="btn btn-primary btn-mini pull-right" :disabled='selectedTemplate == 0' @click.prevent="onLoadTemplate">
                <i class="mdi mdi-folder-download"></i>
              </button>
            </div>
          </div>
      </div>

      <div class="form-group">
          <label for="act.Text">Body <span v-show="isEditMode" class="text-danger">*</span></label>
          <span :class="{ 'parsley-error-radio': $v.activity.activityText.$error }">
            <vue-editor v-model="activity.activityText" :editor-toolbar="customToolbar" :disabled="!isEditMode" :class="{'hide-editor-toolbar' :!isEditMode }"/>
          </span>
          <template v-if="$v.activity.activityText.$error">
            <ul class="parsley-errors-list filled">
              <li v-if="!$v.activity.activityText.required" class="parsley-required">This value is required</li>
            </ul>
          </template>
      </div>

      <div v-show="!isAdd">
        <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
        <div :class="{'collapse': !showLogs }" >
            <div class="form-group">
                <label>Created On</label>
                <AppDate :date="activity.createdOn" :show="'input'"/>
            </div>
            <div class="form-group">
                <label>Created By</label>
                <AppUser :userId="activity.createdBy" />
            </div>
            <div class="form-group" v-if="activity.updatedOn">
                <label>Modified On</label>
                <AppDate :date="activity.updatedOn" :show="'input'"/>
            </div>
            <div class="form-group" v-if="activity.updatedOn">
                <label>Modified By</label>
                <AppUser :userId="activity.updatedBy" :emptyIfNull="true"/>
            </div>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-icon waves-effect waves-light btn-primary mr-2" @click.prevent="close"><i class="mdi mdi-close-circle-outline"></i> Close</button>
        <button v-if="isEditMode" class="btn btn-success btn-custom waves-effect waves-light mr-2" @click.prevent="onSave(false)">
          <span v-if="isToSend"><i class="mdi mdi-send m-r-5"></i> Send</span>
          <span v-else><i class="mdi mdi-content-save m-r-5"></i> {{ isAdd ? 'Add' : 'Save'}}</span>
        </button>
        <button v-if="isEditMode && taskId > 0" class="btn btn-success btn-custom waves-effect w-md waves-light mr-2" @click.prevent="onSave(true)" v-tooltip="isToSend ? 'Send & Complete Task' : ( isAdd ? 'Add & Complete Task' : 'Save & Complete Task')">
          <i class="mdi mdi-checkbox-marked-outline m-r-5"></i>Complete
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rightbar from '@/mixins/rightbar'
import Multiselect from 'vue-multiselect'
import { required, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import DatePicker from 'vuejs-datepicker'
import { VueEditor } from 'vue2-editor'

const validEmail = (toSend, emailAddress) => {
  if (toSend) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(emailAddress).toLowerCase())
  }
  return true
}

export default {
  props: {
    isEditMode: {
      required: true,
      type: Boolean
    },
    isAdd: {
      required: true,
      type: Boolean
    },
    objectId: {
      required: true,
      type: Number
    },
    taskId: {
      required: false,
      type: Number,
      default: 0
    }
  },
  components: {
    Multiselect,
    DatePicker,
    VueEditor
  },
  data () {
    return {
      showLogs: false,
      isExpand: false,
      isToSend: false,
      acTypeObj2: null,
      selectedTemplate: 0,
      templateList: [],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'color': [] }, { 'background': [] }]
      ]
    }
  },
  mixins: [rightbar],
  validations: {
    activity: {
      activitySubject: { required, min: minLength(3), max: maxLength(300) },
      activityTo: { max: maxLength(200), required: requiredIf(function () { return this.isToSend }), validEmail (value) { return validEmail(this.isToSend, value) } },
      activityText: { required, min: minLength(3) }
    }
  },
  methods: {
    ...mapActions(['fetchAllActivityTypes', 'updateActivity', 'addActivity', 'fetchAllEmailTemplates']),
    close () {
      if (this.isExpand) {
        this.collapse()
      }
      this.isExpand = false
      this.$v.activity.$reset()
      this.hideRightbar()
      this.selectedTemplate = 0
    },
    typeSelected (value, id) {
      this.activity.activityTypeId = value ? value.activityTypeId : null
      if (value.activityTypeId > 1) {
        this.isToSend = false
      }
    },
    onLoadTemplate () {
      if (this.selectedTemplate === 0) {
        return
      }
      this.activity.activityText = this.selectedTemplate
    },
    onSave (completeTask) {
      this.$v.activity.$touch()
      if (this.$v.activity.$error) {
        this.$snotify.error('Please fix the errors.')
        return
      }

      const newActivity = {...this.activity}
      if (completeTask) {
        newActivity.taskId = this.taskId
      } else {
        newActivity.taskId = 0
      }
      newActivity.isSendEmail = this.isToSend
      let activityId = newActivity.activityId
      this.selectedTemplate = 0
      if (this.isAdd) {
        this.addActivity({objectId: this.objectId, activity: newActivity})
          .then((response) => {
            if (response == null) {
              this.$snotify.error('Server Error!')
            } else {
              this.$snotify.success('Note Added.')
              activityId = response.activityId
              this.$emit('onUpdate', {completeTask, activityId, action: 'save'})
            }
          })
      } else {
        this.updateActivity({activityId: this.activity.activityId, activity: newActivity})
          .then((response) => {
            this.$snotify.success('Note Updated.')
            this.$emit('onUpdate', {completeTask, activityId, action: 'save'})
          })
      }
    },
    expandCollapse () {
      if (this.isExpand) {
        this.isExpand = false
        this.collapseRightbar()
      } else {
        this.expandRightbar()
        this.isExpand = true
      }
    }
  },
  created () {
    this.fetchAllActivityTypes()
    this.fetchAllEmailTemplates()
  },
  computed: {
    allTypes () {
      return this.$store.state.allActivityTypes
    },
    allTypes2 () {
      return this.$store.state.allActivityTypes.filter(x => x.activityTypeId > 0)
    },
    allTemplates () {
      const list = this.$store.state.allEmailTemplates
      if (list && list.length > 0) {
        return list.filter(item => item.objectId === this.objectId || item.objectId === 0)
      }
      return []
    },
    activity () {
      return this.$store.state.rb_activity
    },
    acTypeObj () {
      if (this.activity != null) {
        const o = this.allTypes.filter(t => t.activityTypeId === this.activity.activityTypeId)
        this.acTypeObj2 = o
        return o
      } else {
        return null
      }
    }
  }
}
</script>
