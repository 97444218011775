<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'ops-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Ops Workflow'" :icon="'mdi mdi-opera'" />
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box table-responsive">
                                <h4 class="m-t-0 header-title mb-4">Submitted Requests</h4>                                
                                <div class="row m-b-10">
                                    <div class="col-sm-6">
                                      <div style="width:50%">
                                          <multiselect id="statusFilter" v-model="statusFilter" tag-placeholder="Add this as new tag" placeholder="Search or add a Status" 
                                              label="statusName" track-by="statusId" :options="allStatuses" :multiple="true" :taggable="true" width="50%" @input="submitSearch"></multiselect>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="row">
                                        <div class="col-md-6">
                                          <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                          <label class="col-form-label">
                                            Only My Requests
                                            <toggle-button v-model="onlyMyRequests" :sync="true" :labels="false" @input="submitSearch"/>
                                          </label>
                                          <label class="col-form-label ml-2" v-if="user.groupId == 4">
                                            Only My Assignments
                                            <toggle-button v-model="onlyMyJobs" :sync="true" :labels="false" @input="submitSearch"/>
                                          </label>
                                          
                                        </div>
                                        <div class="col-md-6">
                                          <multiselect id="userFilter" v-model="userObj" :options="opsUsers" label="fullName" track-by="userId" :allow-empty="true" placeholder="Filter jobs by user" @input="submitSearch"></multiselect>
                                        </div>
                                      </div>
                                    </div>
                                    
                                </div>

                                <table class="table table-bordered t-hover table-sortable table-striped">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)"> Category
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(6)"> Type
                                          <i v-if="sortIndex == 6 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 6 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(5)"> Submited By
                                          <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)"> Status
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(2)"> Assigned to
                                          <i v-if="sortIndex == 2 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 2 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(3)"> Submited On
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(4)"> Deadline
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                         
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-if="!isLoading && legalRequests.length == 0">
                                        <td colspan="7">No Task to show</td>
                                      </tr>
                                      <tr v-for="item in legalRequests" :key="item.requestId">
                                          <td>
                                              <router-link :to="{name: 'OpsDetail', params: {id: item.requestId}}" v-tooltip="item.categoryName">
                                                {{shortedName(item.categoryName)}}
                                              </router-link>
                                          </td>
                                          <td>{{item.typeId == 1 ? 'Internal' : 'External' }}</td>
                                          <td>
                                            <AppUser v-if="item.createdBy > 0" :userId="item.createdBy" :show="'text'" :showpic="true"/>
                                          </td>
                                          <td :class="item.colorClass"> {{item.statusName}}</td>
                                          <td>
                                            <AppUser v-if="item.assignedTo > 0" :userId="item.assignedTo" :show="'text'" :showpic="true"/>
                                          </td>
                                          <td> <AppDate :date="item.createdOn" :show="'text'" /> </td>
                                          <td> <AppDate :date="item.deadline" :show="'text'" /> </td>
                                      </tr>
                                    </tbody>
                                </table>
                                
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div role="status">Page {{currentPage}} of {{totalPages}} - {{totalRecords}} requests</div>
                                    <div class="px-3">
                                          <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                              <option value="250">250</option>
                                          </select>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- end container -->
            </div>
            <!-- end content -->

            <Footer />
        </div>
    </div>
</template>

<script>
import { OpsTeamService } from '@/services/opsteam-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import Multiselect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      legalRequests: null,
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      sortBy: ['CategoryName', 'StatusName', 'UserFullName', 'RequestDate', 'Deadline', 'Requestor', 'typeId'],
      sortIndex: 4,
      sortType: 'ASC',
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0,
      statusFilter: [],
      onlyMyRequests: false,
      onlyMyJobs: false,
      assignedToUserId: 0,
      userObj: null
    }
  },
  mixins: [asyncDataStatus],
  methods: {
    ...mapActions(['fetchAllLegalRequestStatuses', 'fetchAllUsers']),
    getList () {
      this.isLoading = true
      const status = this.statusFilter.map(a => a.statusId)
      let requestedBy = null
      let assignedTo = null
      if (this.onlyMyRequests) {
        requestedBy = this.user.userId
      }
      if (this.onlyMyJobs) {
        assignedTo = this.user.userId
      }
      if (this.userObj) {
        assignedTo = this.userObj.userId
      }
      const filters = {
        'onlyMyRequests': requestedBy > 0,
        'onlyMyJobs': this.onlyMyJobs,
        'assignedTo': assignedTo,
        'sortIndex': this.sortIndex,
        'sortType': this.sortType,
        'pageSize': this.pageSize,
        'status': this.statusFilter
      }
      
      localStorage.setItem('umb_ops_filters', JSON.stringify(filters))
      OpsTeamService.search(null, null, requestedBy, assignedTo, null, null, status, this.sortBy[this.sortIndex], this.sortType, this.currentPage, this.pageSize)
      .then((response) => {
        this.isLoading = false
        this.legalRequests = response.data.requests
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Ops Team  Workflow Lists') })
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    clearUser () {
      this.userObj = null
      this.submitSearch()
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.legalRequests = []

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    resetFilter () {
      this.onlyMyRequests = false
      this.onlyMyJobs = false
      this.userObj = null
      this.statusFilter = this.allStatuses.filter(x => x.statusId <= 2)
      this.startSearch()
    },
    shortedName (cat) {
      return cat.length > 50 ? cat.substring(0, 50) + '...' : cat
    }
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allStatuses () {
      return this.$store.state.allLegalRequestStatuses
    },
    opsUsers () {
      return this.$store.state.allUsers.filter(x => (x.groupId === 2 || x.groupId === 4 || x.groupId === 7 || x.groupId === 8) && x.isActive)
    },
    hasFilter () {
      return this.onlyMyRequests || this.onlyMyJobs || this.statusFilter.filter(x => x.statusId <= 2).length !== 2 || this.statusFilter.filter(x => x.statusId > 2).length > 0 || this.userObj != null
    }
  },
  created () {
    this.fetchAllLegalRequestStatuses()
    this.fetchAllUsers()
    this.statusFilter = this.allStatuses.filter(x => x.statusId <= 2)
    const oldFilters = JSON.parse(localStorage.getItem('umb_ops_filters'))
    if (oldFilters != null) {
      if (oldFilters.assignedTo != null) {
        const user = this.opsUsers.filter(x => x.userId === oldFilters.assignedTo)
        if (user.length > 0) {
          this.userObj = user[0]
        }
      }
      if (oldFilters.onlyMyRequests != null) {
        this.onlyMyRequests = oldFilters.onlyMyRequests
      }
      if (oldFilters.onlyMyJobs != null) {
        this.onlyMyJobs = oldFilters.onlyMyJobs
      }
      if (oldFilters.sortIndex != null) {
        this.sortIndex = oldFilters.sortIndex
      }
      if (oldFilters.sortType != null) {
        this.sortType = oldFilters.sortType
      }
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
      if (oldFilters.status != null) {
        this.statusFilter = oldFilters.status
      }
    }
    this.startSearch()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
