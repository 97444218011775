<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'firms-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid">
                    <PageTitle :title="'Firms'" :icon="'mdi mdi-factory'" />
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box table-responsive">
                                <h4 class="header-title">Firms List</h4>
                                <div class="row m-b-10">
                                    <div class="col-sm-12 col-md-6 form-inline">
                                      <div class="ml-2">
                                        <label class="col-form-label">
                                          <span class="mr-1">Only Active</span>
                                          <toggle-button :sync="true" :labels="{checked: ' ', unchecked: ' '}" v-model="isActive" @input="startSearch" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div>
                                            <label>
                                                <input type="search" v-model="searchName" class="form-control" placeholder="Search by name" @keyup.enter="startSearch">
                                            </label>
                                            <button @click="startSearch" class="btn btn-icon waves-effect waves-light btn-primary m-b-5 mr-2"> <i class="fa fa-search"></i> </button>
                                            <button @click="resetFilter" class="btn btn-icon waves-effect waves-light btn-primary pull-right" v-show="hasFilter" v-tooltip="'Reset Filters'"> <i class="mdi mdi-filter-remove"></i> </button>
                                        </div>
                                    </div>
                                </div>

                                <table class="table table-striped table-bordered table-hover no-footer table-sortable">
                                    <thead>
                                    <tr>
                                        <th @click="setOrder(0)">Name
                                          <i v-if="sortIndex == 0 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 0 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(1)">City
                                          <i v-if="sortIndex == 1 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 1 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th> 
                                        <th>Contact
                                        </th>
                                        <th @click="setOrder(3)"> # Deals
                                          <i v-if="sortIndex == 3 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 3 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(4)"> # Investments
                                          <i v-if="sortIndex == 4 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 4 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(5)"> Last Note
                                          <i v-if="sortIndex == 5 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 5 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                        <th @click="setOrder(6)">Status
                                          <i v-if="sortIndex == 6 && sortType == 'ASC'" class="mdi mdi-arrow-up-drop-circle-outline"></i>
                                          <i v-if="sortIndex == 6 && sortType == 'DESC'" class="mdi mdi-arrow-down-drop-circle-outline"></i>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-if="isLoading">
                                        <span>Loading <i class="fa fa-spinner fa-spin"></i></span>
                                      </tr>
                                      <tr v-for="firm in firms"
                                        :key="firm.firmId">
                                        <td>
                                            <router-link :to="{name: 'FirmsDetail', params: {id: firm.firmId}}">
                                                {{ firm.firmName }}
                                            </router-link> <br />
                                            {{ firm.website }} <a v-if="firm.website && firm.website.length > 0"  :href="firm.website" target="blank"><i class="mdi mdi-open-in-new"></i></a>
                                        </td>
                                        <td>                                          
                                          <span v-if="firm.region" v-tooltip="firm.country">
                                            {{ firm.region }},
                                          </span>{{ firm.city }}
                                        </td>
                                        <td>
                                          <AppUser v-if="firm.contactId > 0" :userId="firm.contactId" :show="'text'" :showpic="true"/>
                                        </td>
                                        <td>{{ firm.fundCnt }}</td>
                                        <td>{{ firm.investCnt }}</td>
                                        <td><AppDate :date="firm.lastNoteOn != null ? firm.lastNoteOn : ''" :show="'text'" /></td>
                                        <td>{{ firm.isActive ? 'Active' : 'Inactive' }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                  <div class="col-sm-12 col-md-7 col-lg-7 form-inline">
                                    <div class="" role="status" aria-live="polite">Page {{currentPage}} of {{totalPages}} - {{totalRecords | thousandNumber}} firms</div>
                                    <div class="px-3">
                                          <select class="form-control form-control-sm" v-model="pageSize" @change="startSearch">
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                              <option value="250">250</option>
                                          </select> 
                                    </div>

                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <paginate :page-count="totalPages"
                                          v-model="currentPage"
                                          :click-handler="onPageChanged"
                                          :prev-text="'Previous'"
                                          :next-text="'Next'"
                                          :container-class="'pagination'"
                                          :page-class="'paginate_button page-item '"
                                          :prev-class="'paginate_button page-item previous'"
                                          :next-class="'paginate_button page-item next'">
                                        </paginate>
                                    </div>
                                  </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end container -->
            </div>
            <!-- end content -->

            <Footer />
        </div>
    </div>
</template>

<script>
import { FirmService } from '@/services/firm-service'
import asyncDataStatus from '@/mixins/asyncDataStatus'

export default {
  components: {
  },
  mixins: [asyncDataStatus],
  data () {
    return {
      firms: [],
      searchName: '',
      isActive: true,
      pageSize: 25,
      currentPage: 1,
      isLoading: false,
      sortBy: ['FirmName', 'City', 'ContactName', 'FundCount', 'InvestCount', 'lastNoteOn', 'Status'],
      sortIndex: 0,
      sortType: 'ASC',
      searchTimeout: null,
      totalRecords: 0,
      totalPages: 0
    }
  },
  methods: {
    getList () {
      this.isLoading = true
      const filters = {
        'searchName': this.searchName,
        'isActive': this.isActive,
        'sortIndex': this.sortIndex,
        'sortType': this.sortType,
        'pageSize': this.pageSize
      }      
      localStorage.setItem('umb_firms_filters', JSON.stringify(filters))
      FirmService.search(this.searchName.trim(), this.isActive, this.sortBy[this.sortIndex], this.sortType, this.currentPage, this.pageSize)
      .then((response) => {
        this.isLoading = false
        this.firms = response.data.firms
        this.totalRecords = response.data.totalRecords
        if (this.totalRecords > 0) {
          this.totalPages = response.data.totalPages
        } else {
          this.totalPages = 0
          this.currentPage = 0
        }
      })
      .then(() => { this.asyncDataStatus_fetched('Firms List') })
    },
    onPageChanged (pageNumber) {
      this.submitSearch()
    },
    startSearch () {
      this.currentPage = 1
      this.submitSearch()
    },
    submitSearch () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
      }

      this.firms = []
      if (this.searchName == null) {
        this.searchName = ''
      }

      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 250)
    },
    setOrder (id) {
      if (this.sortIndex === id) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.sortIndex = id
        this.sortType = 'ASC'
      }
      this.startSearch()
    },
    resetFilter () {
      this.searchName = ''
      this.startSearch()
    }
  },
  mounted () {    
    const oldFilters = JSON.parse(localStorage.getItem('umb_firms_filters'))
    if (oldFilters != null) {
      if (oldFilters.searchName != null) {
        this.searchName = oldFilters.searchName
      }
      if (oldFilters.isActive != null) {
        this.isActive = oldFilters.isActive
      }
      if (oldFilters.pageSize != null) {
        this.pageSize = oldFilters.pageSize
      }
      if (oldFilters.sortIndex != null) {
        this.sortIndex = oldFilters.sortIndex
      }
      if (oldFilters.sortType != null) {
        this.sortType = oldFilters.sortType
      }
    }
    this.startSearch()
  },
  computed: {
    hasFilter () {
      return this.searchName.length > 0
    }
  },
  filters: {
    thousandNumber (number) {
      return number.toLocaleString()
    }
  }
}
</script>