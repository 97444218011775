<template>
    <div>
        <Topbar />
        <Sidebar :activeItem="'firms-list'"/>

        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container-fluid" v-if="firm">
                    <PageTitle :title="'Firm Details - '+firm.firmName" :icon="'mdi mdi-factory'" :hasTask="firm.outreachTaskId > 0"
                    :showToolbar="true" :showPins="true" :pinId="firm.pinId" :inEditMode="inEditMode" :canDelete="canbeDeleted && canbeEdited" :canEdit="canbeEdited" :isSaving="inSaving"
                    @onEdit="EditForm"
                    @onCancel="cancelForm"
                    @onDelete="deleteForm"
                    @onSave="saveForm"
                    @onPin="addPin"
                    @onPinOff="delPin" />
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-box">
                                <ul class="nav nav-tabs tabs-bordered">
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(1)" :class="{'active': activeTab == 1, 'tab-error': smTabHasError}">
                                            <i class="mdi mdi-information-outline"></i> Summary
                                        </a>
                                    </li>
                                    <li class="nav-item" v-show="!isClient">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="true" class="nav-link" @click="showTab(2)" :class="{'active': activeTab == 2, 'tab-error': gpTabHasError}">
                                            <i class="mdi mdi-human-greeting"></i> General Partner
                                        </a>
                                    </li>
                                    <li class="nav-item" v-show="isClient">
                                        <a href="javascript:void(0)" data-toggle="tab" aria-expanded="false" class="nav-link" @click="showTab(3)" :class="{'active': activeTab == 3, 'tab-error': clTabHasError}">
                                            <i class="mdi mdi-account"></i> Client
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane fade" :class="{'active show': activeTab == 1}">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label for="firmName">Name<span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <input type="text" v-model.trim="firm.firmName" @blur="$v.firm.firmName.$touch()" placeholder="Enter Name" class="form-control" id="firmName" :disabled="!inEditMode" :class="{ 'parsley-error': $v.firm.firmName.$error }">
                                                    <template v-if="$v.firm.firmName.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.firmName.required" class="parsley-required">This value is required</li>
                                                        <li v-if="!$v.firm.firmName.min" class="parsley-required">This value is too short</li>
                                                        <li v-if="!$v.firm.firmName.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="typeId">Type<span v-if="inEditMode" class="text-danger">*</span></label>
                                                    <span :class="{ 'parsley-error-radio': $v.firm.typeId.$error }">
                                                      <div class="custom-control custom-radio">
                                                        <input type="radio" id="type1" value=1 v-model="firm.typeId" :disabled="!inEditMode" name="type" class="custom-control-input">
                                                        <label class="custom-control-label" for="type1">General Partner</label>
                                                      </div>
                                                      <div class="custom-control custom-radio">
                                                        <input type="radio" id="type2" value=2 v-model="firm.typeId" :disabled="!inEditMode" name="type" class="custom-control-input">
                                                        <label class="custom-control-label" for="type2">Client</label>
                                                      </div>
                                                      <div class="custom-control custom-radio">
                                                        <input type="radio" id="type3" value=3 v-model="firm.typeId" :disabled="!inEditMode" name="type" class="custom-control-input">
                                                        <label class="custom-control-label" for="type3">Placement Agent</label>
                                                      </div>
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <label>Status</label>
                                                    <toggle-button v-model="firm.isActive" :sync="true" :disabled="!inEditMode" :width="65" :labels="{checked: 'Active', unchecked: 'Inactive'}"/>
                                                </div>

                                                <div class="form-group">
                                                    <label for="Website">Website</label>
                                                    <input type="text" v-model.trim="firm.website" placeholder="Enter Website" class="form-control" id="Website" :disabled="!inEditMode" @blur="$v.firm.website.$touch()" :class="{ 'parsley-error': $v.firm.website.$error }">
                                                    <template v-if="$v.firm.website.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.website.isValidURL" class="parsley-required">Website is not valid</li>
                                                        <li v-if="!$v.firm.website.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label for="address1">Address</label>
                                                    <input type="text" v-model.trim="firm.address1" class="form-control" id="address1" :disabled="!inEditMode" />
                                                    <input type="text" v-model.trim="firm.address2" class="form-control" id="address2" :disabled="!inEditMode" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="city">City</label>
                                                    <input type="text" v-model.trim="firm.city" class="form-control" id="city" :disabled="!inEditMode" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="region">State</label>
                                                    <input type="text" v-model.trim="firm.region" class="form-control" id="region" :disabled="!inEditMode" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="zipcode">Zip Code</label>
                                                    <input type="text" v-model.trim="firm.zipCode" class="form-control" id="zipcode" :disabled="!inEditMode" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="address1">Country</label>
                                                    <input type="text" v-model.trim="firm.country" class="form-control" id="country" :disabled="!inEditMode" />
                                                </div>

                                                <div class="form-group">
                                                    <label for="oprCode">Operation Code</label>
                                                    <input type="text" v-model="firm.oprCode" :disabled="!inEditMode" placeholder="Enter Operation Code" class="form-control" id="oprCode">
                                                </div>

                                                <toggle-button v-model="showLogs" :labels="{checked: 'Logs', unchecked: 'Logs'}" />
                                                <div :class="{'collapse': !showLogs }">
                                                    <div class="form-group">
                                                        <label>Created On</label>
                                                        <AppDate :date="firm.createdOn"/>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Created By</label>
                                                        <AppUser :userId="firm.createdBy" />
                                                    </div>
                                                    <div class="form-group" v-if="firm.updatedOn">
                                                        <label>Modified On</label>
                                                        <AppDate :date="firm.updatedOn" :emptyIfNull="true"/>
                                                    </div>
                                                    <div class="form-group" v-if="firm.updatedOn">
                                                        <label>Modified By</label>
                                                        <AppUser :userId="firm.updatedBy" :emptyIfNull="true"/>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-lg-6">
                                                <ul class="nav nav-tabs tabs-bordered">
                                                    <li class="nav-item">
                                                        <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeSMTab == 1}" @click="showSMTab(1)">
                                                          Notes <span class="badge badge-primary noti-icon-badge" v-if="activities">{{activities.length}}</span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeSMTab == 2}" @click="showSMTab(2)">
                                                          Employees <span class="badge badge-primary noti-icon-badge" v-if="firmContacts">{{firmContacts.length}}</span></a>
                                                    </li>
                                                </ul>

                                                <div class="tab-content">
                                                    <div class="tab-pane" :class="{'active show': activeSMTab == 2}">
                                                      <table class="table table-bordered table-hover">
                                                          <thead>
                                                            <tr>
                                                                <th>Full Name <i class="mdi mdi-open-in-new"></i></th>
                                                                <th>Job Title</th>
                                                                <th>Business Phone</th>
                                                                <th>Email</th>                                                                
                                                                <th v-if="user.groupId < 11"></th>
                                                            </tr>
                                                          </thead>                         
                                                          <FirmContactList :employees="firmContacts" :isEditMode="inEditMode" @onUpdate="onEmployeeUpdate" @onClickNew="clickAddEmployee" @onSelect="showEmployee" />
                                                      </table>                                                            
                                                    </div>
                                                    <div class="tab-pane" :class="{'active show': activeSMTab == 1}">
                                                        <div class="tab-pane fade show active">
                                                            <ActivityList :activities="activities" :isEditMode="inEditMode" @onUpdate="onActivityUpdate" @onClickNew="clickAddActivity" @onSelect="showActivity" @onSelectUpdate="editActivity"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" :class="{'active show': activeTab == 2}">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label for="FirmOverview">Firm Overview</label>
                                                    <button type="button" class="btn btn-primary btn-sm btn-mini" v-show="inEditMode && needTrim(firm.overview)" @click.prevent="firm.overview = trimText(firm.overview)">
                                                        <i class="mdi mdi-flash-auto"></i>
                                                    </button>
                                                    <textarea placeholder="Enter Overview" v-model.trim="firm.overview" :disabled="!inEditMode" class="form-control" rows="6" id="FirmOverview" @blur="$v.firm.overview.$touch()" :class="{ 'parsley-error': $v.firm.overview.$error }"/>
                                                    <!-- <template v-if="$v.firm.overview.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.overview.max" class="parsley-required">This value is too long</li>
                                                      </ul>
                                                    </template> -->
                                                </div>
                                                <div class="form-group">
                                                    <label for="OwnershipId">Firm Ownership</label>
                                                    <multiselect id="OwnershipId" v-model="ownershipIdObj" :disabled="!inEditMode" required :options="allFirmOwnershipTypes" label="typeName" track-by="typeName" :allow-empty="false" @input="ownershipSelected"></multiselect>
                                                </div>
                                                <div class="form-group">
                                                    <label for="monitoringId">Monitoring</label>
                                                    <select class="form-control" id="monitoringId" v-model="firm.monitoringId" :disabled="!inEditMode">
                                                        <option value="">None</option>
                                                        <option value="1">Required</option>
                                                        <option value="2">Recommended</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label for="secOutreachTierId">Secondary Outreach Tier</label>
                                                    <select class="form-control" id="secOutreachTierId" v-model="firm.secOutreachTierId" :disabled="!inEditMode">
                                                        <option value="">None</option>
                                                        <option :value="item.tierId" v-for="item in allSecOutreachTiers" :key="item.tierId">{{item.tierName}}</option>                
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label for="ContactId">Wilshire Contact <span v-show="inEditMode && firm.monitoringId == 1" class="text-danger">*</span></label>
                                                    <span :class="{ 'invalid': $v.firm.contactId.$error }">
                                                      <multiselect id="ContactId" v-model="contactObj" :disabled="!inEditMode" :options="allActiveUsers" label="fullName" track-by="userId" @input="contactSelected" open-direction="top" :allow-empty="true"></multiselect>
                                                    </span>
                                                    <template v-if="$v.firm.contactId.$error">
                                                      <ul class="parsley-errors-list filled">
                                                        <li v-if="!$v.firm.contactId.required" class="parsley-required">This value is required for Required Monitoring</li>
                                                      </ul>
                                                    </template>
                                                </div>
                                                <div class="form-group">
                                                    <label>Track Annual Meeting</label>
                                                    <toggle-button v-model="firm.trackAnnualMeeting" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                </div>
                                            </div>
                                            <div class="col-lg-6" v-if="user.groupId < 11">
                                                <ul class="nav nav-tabs tabs-bordered">
                                                    <li class="nav-item">
                                                        <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeGPTab == 1}" @click="showGPTab(1)">
                                                          Related Deals <span class="badge badge-primary noti-icon-badge" v-if="relatedFunds">{{relatedFunds.length}}</span></a>
                                                    </li>
                                                    <li class="nav-item" v-if="firm.trackAnnualMeeting">
                                                        <a href="javascript:void(0)" class="nav-link" data-toggle="tab" :class="{'active': activeGPTab == 2}" @click="showGPTab(2)">
                                                          Annual Meetings <span class="badge badge-primary noti-icon-badge" v-if="annualMeetings">{{annualMeetings.length}}</span></a>
                                                    </li>
                                                </ul>

                                                <div class="tab-content">
                                                    <div class="tab-pane" :class="{'active show': activeGPTab == 1}">
                                                        <div class="form-group">
                                                            <table class="table table-bordered table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Deal Name <i class="mdi mdi-open-in-new"></i></th>
                                                                        <th>Status</th>
                                                                        <th>Stage</th>
                                                                        <th>Type</th>
                                                                        <th>Sector</th>
                                                                        <th>Vintage <i class="mdi mdi-arrow-down-drop-circle-outline"></i></th>
                                                                        <th>Target Size</th>
                                                                        <th>Geography</th>
                                                                    </tr>
                                                                </thead>
                                                                <FundsRelatedList :funds="relatedFunds" :isEditMode="inEditMode"/>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane" :class="{'active show': activeGPTab == 2}">
                                                        <div class="form-group" v-if="!firm.hasNoAnnualMeeting">
                                                            <table class="table table-bordered table-hover">
                                                                <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>City</th>
                                                                    <th>Attendance</th>
                                                                    <th>Attendee</th>
                                                                    <th class="action-col" v-if="user.groupId < 11"></th>
                                                                </tr>
                                                                </thead>
                                                                <FirmAnnualMeetingList :meetings="annualMeetings" :isEditMode="inEditMode" @onClickNew="clickAddAnnualMeeting" @onSelect="showAnnualMeeting" @onSelectUpdate="editAnnualMeeting" @onUpdate="onAnnualMeetingUpdate"/>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" :class="{'active show': activeTab == 3}">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label for="AbbreviatedClientName">Abbreviated Client Name</label>
                                                    <input type="text" v-model.trim="firm.abbreviatedClientName" placeholder="Enter Abbreviated Client Name" class="form-control" id="AbbreviatedClientName" :disabled="!inEditMode" @blur="$v.firm.abbreviatedClientName.$touch()" :class="{ 'parsley-error': $v.firm.abbreviatedClientName.$error }">
                                                </div>
                                                <div class="form-group">
                                                    <label for="WPMEmailAlias">WPM Email Alias</label>
                                                    <input type="text" v-model.trim="firm.wpmEmailAlias" placeholder="Enter WPM Email Alias" class="form-control" id="WPMEmailAlias" :disabled="!inEditMode" @blur="$v.firm.wpmEmailAlias.$touch()" :class="{ 'parsley-error': $v.firm.wpmEmailAlias.$error }">
                                                </div>
                                                <div class="form-group">
                                                    <label for="ClientTypeId">Client Type</label>
                                                    <multiselect id="ClientTypeId" v-model="clientTypeIdObj" :disabled="!inEditMode" required :options="allFirmClientTypes" label="typeName" track-by="typeName" :allow-empty="false" @input="clientTypeSelected"></multiselect>
                                                </div>
                                                <div class="form-group">
                                                    <label>Contract Award Date</label>
                                                    <date-picker v-model="firm.contractAwardDate" placeholder="Select Date" :disabled="!inEditMode" calendar-class="calendar form-control" input-class="form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                </div>
                                                <div class="form-group">
                                                    <label>Contract Expiration Date</label>
                                                    <date-picker v-model="firm.contractExpirationDate" placeholder="Select Date" :disabled="!inEditMode" calendar-class="calendar form-control" input-class="form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                </div>
                                                <div class="form-group">
                                                  <label>Access Platform Client</label>
                                                  <toggle-button v-model="firm.isAccess" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                </div>
                                                <div class="form-group">
                                                  <label>Advisory Client Portal</label>
                                                  <toggle-button v-model="firm.isAdvisoryPortal" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                </div>
                                                <div class="form-group">
                                                  <label>Wilshire Consulting Client</label>
                                                  <toggle-button v-model="firm.isConsultingClient" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                </div>
                                                <div class="form-group">
                                                  <label>Emerging Manager Interest</label>
                                                  <toggle-button v-model="firm.isEmergingManagerInterest" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                </div>
                                                <div class="form-group">
                                                  <label>ESG Requirements</label>
                                                  <toggle-button v-model="firm.isESGRequirements" :sync="true" :disabled="!inEditMode" :labels="{checked: 'Yes', unchecked: 'No'}"/>
                                                </div>
                                                <div class="form-group">
                                                    <label for="ClientIPSBenchmark">Client IPS Benchmark</label>
                                                    <textarea type="text" v-model.trim="firm.clientIPSBenchmark" :disabled="!inEditMode" class="form-control" id="ClientIPSBenchmark" @blur="$v.firm.clientIPSBenchmark.$touch()" :class="{ 'parsley-error': $v.firm.clientIPSBenchmark.$error }"/>
                                                </div>
                                                <div class="form-group">
                                                    <label for="ContractNotes">Contact Notes</label>
                                                    <textarea type="text" v-model.trim="firm.contractNotes" :disabled="!inEditMode" class="form-control" id="ContractNotes" @blur="$v.firm.contractNotes.$touch()" :class="{ 'parsley-error': $v.firm.contractNotes.$error }" />
                                                </div>

                                                <h4 class="header-title m-t-0">Investing Entity-Specific Pacing, Guidelines and Target Portfolio</h4>
                                                <div class="form-group">
                                                    <label for="CurrencyId">Currency</label>
                                                    <multiselect id="CurrencyId" v-model="currencyObj" :disabled="!inEditMode" :options="allCurrencies" label="currencyName" track-by="currencyId" :allow-empty="true" @input="currencySelected"></multiselect>
                                                </div>
                                                <div class="form-group">
                                                    <label for="TotalPlanNAV">Total Plan NAV (M)</label>
                                                      <div class="input-group">
                                                          <div class="input-group-prepend">
                                                              <span class="input-group-text">{{symbol}}</span>
                                                          </div>
                                                          <input type="number" v-model.number="firm.totalPlanNAV" placeholder="Enter Total Plan NAV (M)" class="form-control" id="TotalPlanNAV" :disabled="!inEditMode" />
                                                      </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Plan NAV As of</label>
                                                    <date-picker v-model="firm.planNAVAsofDate" placeholder="Select Date" :disabled="!inEditMode" calendar-class="calendar form-control" input-class="form-control" :monday-first="true" :typeable="true" :clear-button="true" clear-button-icon="fa fa-times" format="MM/dd/yyyy" :bootstrap-styling="true"></date-picker>
                                                </div>
                                                <div class="form-group">
                                                    <label for="PercentageCommitmentPercent">Percentage Commitment to Private Markets</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">%</span>
                                                        </div>
                                                        <input type="number" v-model.number="firm.percentageCommitmentPercent" placeholder="Enter Percentage Commitment to Private Markets %" class="form-control" id="PercentageCommitmentPercent" :disabled="!inEditMode" @blur="$v.firm.percentageCommitmentPercent.$touch()" :class="{ 'parsley-error': $v.firm.percentageCommitmentPercent.$error }" />
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="TargetMaxManagerConcentrationPercent">Target Max Manager Concentration</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">%</span>
                                                        </div>
                                                        <input type="number" v-model.number="firm.targetMaxManagerConcentrationPercent" placeholder="Enter Target Max Manager Concentration %<" class="form-control" id="TargetMaxManagerConcentrationPercent" :disabled="!inEditMode" @blur="$v.firm.targetMaxManagerConcentrationPercent.$touch()" :class="{ 'parsley-error': $v.firm.targetMaxManagerConcentrationPercent.$error }" />
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="TargetMaxDealConcentrationPercent">Target Max Deal Concentration</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">%</span>
                                                        </div>
                                                        <input type="number" v-model.number="firm.targetMaxDealConcentrationPercent" placeholder="Enter Target Max Deal Concentration (%)" class="form-control" id="TargetMaxDealConcentrationPercent" :disabled="!inEditMode" @blur="$v.firm.targetMaxDealConcentrationPercent.$touch()" :class="{ 'parsley-error': $v.firm.targetMaxDealConcentrationPercent.$error }" />
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="TargetMaxCommitmentSize">Target Max Commitment Size (M)</label>
                                                    <label for="TotalPlanNAV">Total Plan NAV (M)</label>
                                                      <div class="input-group">
                                                          <div class="input-group-prepend">
                                                              <span class="input-group-text">{{symbol}}</span>
                                                          </div>
                                                          <input type="number" v-model.number="firm.targetMaxCommitmentSize" placeholder="Enter Target Max Commitment Size (M)" class="form-control" id="TargetMaxCommitmentSize" :disabled="!inEditMode" />
                                                      </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="TargetMinCommitmentSize">Target Min Commitment Size (M)</label>
                                                    <label for="TotalPlanNAV">Total Plan NAV (M)</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">{{symbol}}</span>
                                                        </div>
                                                        <input type="number" v-model.number="firm.targetMinCommitmentSize" placeholder="Enter Target Min Commitment Size (M)" class="form-control" id="TargetMinCommitmentSize" :disabled="!inEditMode" />
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="TargetMaximumDealSize">Target Maximum Deal Size (M)</label>
                                                    <label for="TotalPlanNAV">Total Plan NAV (M)</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">{{symbol}}</span>
                                                        </div>
                                                        <input type="number" v-model.number="firm.targetMaximumDealSize" placeholder="Enter Target Maximum Deal Size (M)" class="form-control" id="TargetMaximumDealSize" :disabled="!inEditMode" />
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="TargetMinimumDealSize">Target Minimum Deal Size (M)</label>
                                                    <label for="TotalPlanNAV">Total Plan NAV (M)</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">{{symbol}}</span>
                                                        </div>
                                                        <input type="number" v-model.number="firm.targetMinimumDealSize" placeholder="Enter Target Minimum Deal Size (M)" class="form-control" id="TargetMinimumDealSize" :disabled="!inEditMode" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <!-- end col -->
                    </div>
                </div>
            </div>
        </div>

        <div class="side-bar right-bar">
            <div class="col-sm-12">
              <ActivityItem @onUpdate="onActivityUpdate" v-if="rightbarItem == 'activity'" :objectId="2" :isEditMode="sideInEditMode" :isAdd="addSidebar" :taskId="firm.outreachTaskId"/>
              <FirmContactItem @onUpdate="onEmployeeUpdate" v-if="rightbarItem == 'firmcontact'" :isEditMode="inEditMode" :isAdd="addSidebar"/>
              <FirmAnnualMeetingItem @onUpdate="onAnnualMeetingUpdate" v-if="rightbarItem == 'annualmeeting'" :isEditMode="sideInEditMode" :isAdd="addSidebar" />              
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
import { required, minLength, maxLength, minValue, maxValue, email, decimal, requiredIf } from 'vuelidate/lib/validators'
import rightbar from '@/mixins/rightbar'
import { FirmService, FirmContactService, FirmAnnualMeetingService } from '@/services/firm-service'
import { ActivityService } from '@/services/activity-service'
import FirmContactList from '@/components/firm/FirmContactList'
import FirmContactItem from '@/components/firm/FirmContactItem'
import FundsRelatedList from '@/components/firm/FundsRelatedList'
import FirmAnnualMeetingList from '@/components/firm/FirmAnnualMeetingList'
import FirmAnnualMeetingItem from '@/components/firm/FirmAnnualMeetingItem'
import ActivityList from '@/components/ActivityList'
import ActivityItem from '@/components/ActivityItem'
import asyncDataStatus from '@/mixins/asyncDataStatus'
import { UserpinService } from '@/services/userpin-service'
import { TaskService } from '@/services/task-service'

const isValidURL = (string) => {
  if (string === null) {
    return true
  }
  // eslint-disable-next-line no-useless-escape
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
  return (res !== null || string === '')
}
export default {
  components: {
    DatePicker,
    Multiselect,
    FirmContactList,
    FirmContactItem,
    FundsRelatedList,
    FirmAnnualMeetingList,
    FirmAnnualMeetingItem,
    ActivityList,
    ActivityItem
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  mixins: [rightbar, asyncDataStatus],
  data () {
    return {
      activeTab: 1,
      activeSMTab: 1,
      activeGPTab: 1,
      activeCTab: 1,
      showLogs: false,
      inEditMode: false,
      inSaving: false,
      sideInEditMode: false,
      firm: {firmName: ''},
      rightbarItem: null,
      addSidebar: null,
      firmTypeObj: null,
      ownershipIdObj: null,
      clientTypeIdObj: null,
      contactObj: null,
      currencyObj: [],
      firmContacts: [],
      relatedFunds: [],
      annualMeetings: [],
      activities: [],
      newAddress: true,
      autocomplete: null,
      places: null
    }
  },
  validations: {
    firm: {
      firmName: { required, min: minLength(2), max: maxLength(300) },
      typeId: { required, min: minValue(1) },
      overview: { max: maxLength(2000) },
      website: { isValidURL (value) { return isValidURL(value) }, max: maxLength(250) },
      wpmEmailAlias: { max: maxLength(100), email },
      abbreviatedClientName: { max: maxLength(100) },
      clientIPSBenchmark: { max: maxLength(200) },
      contractNotes: { max: maxLength(200) },
      percentageCommitmentPercent: { decimal, min: minValue(0), max: maxValue(100) },
      targetMaxManagerConcentrationPercent: { decimal, min: minValue(0), max: maxValue(100) },
      targetMaxDealConcentrationPercent: { decimal, min: minValue(0), max: maxValue(100) },
      contactId: { required: requiredIf(function () { return parseInt(this.firm.monitoringId) === 1 || parseInt(this.firm.secOutreachTierId) > 0 }) }
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers', 'fetchAllFirmTypes', 'fetchAllSecOutreachTiers', 'fetchAllFirmClientTypes', 'fetchAllFirmOwnershipTypes', 'fetchAllCurrencies',
      'fetchFirmContact', 'fetchFirmAnnualMeetings', 'fetchActivity']),
    showTab (tabId) {
      this.activeTab = tabId
    },
    showGPTab (tabId) {
      this.activeGPTab = tabId
    },
    showCTab (tabId) {
      this.activeCTab = tabId
    },
    showSMTab (tabId) {
      this.activeSMTab = tabId
    },
    sidebarAction (item, isAdd, isOpen, isEdit) {
      this.addSidebar = isAdd
      this.sideInEditMode = isEdit || isAdd
      this.rightbarItem = item
      if (isOpen) {
        this.showRightbar()
      } else {
        this.hideRightbar()
      }
    },
    deleteForm () {
      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to delete?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                FirmService.delete(this.firm.firmId)
                .then((response) => {
                  this.$snotify.success('Firm Deleted.')
                  setTimeout(() => {
                    this.$router.push({name: 'FirmsList'})
                  }, 500)
                })
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    saveForm () {
      this.$v.firm.$touch()
      if (!this.$v.firm.$error) {
        this.inSaving = true
        FirmService.save(this.firm.firmId, this.firm).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.inEditMode = false
              this.$snotify.success('Changes saved.')
            }
            this.inSaving = false
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
      } else {
        this.$snotify.error('Please fix the errors.')
      }
    },
    EditForm () {
      this.inEditMode = true
      this.addSidebar = false
    },
    cancelForm () {
      if (!this.$v.firm.$anyDirty) {
        this.cancelFormOper()
        return
      }

      this.$snotify.clear()
      this.$snotify.confirm('Are you sure you want to cancel?', '',
        {
          buttons: [
            {
              text: 'Yes',
              action: (toast) => {
                this.cancelFormOper()
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'No',
              action: (toast) => this.$snotify.remove(toast.id),
              bold: true
            }
          ]})
    },
    cancelFormOper () {
      this.inEditMode = false
      this.$v.firm.$reset()
      this.getDetails()
      if (this.addSidebar) {
        this.hideRightbar()
        this.addSidebar = false
      }
      if (this.activeTab > 1) { // to reset Tabs on TypeId change
        this.activeTab = this.isClient ? 3 : 2
      }
    },
    addPin () {
      const pin = {objectTypeId: 2, objectId: this.id}
      UserpinService.add(pin)
      .then(
        (response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error!')
          } else {
            this.firm.pinId = response.data.pinId
            this.$snotify.success('Pinned!')
          }
        })
        .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    delPin () {
      UserpinService.delete(this.firm.pinId)
      .then(
        (response) => {
          if (response.status !== 200) {
            this.$snotify.error('Error!')
          } else {
            this.firm.pinId = 0
            this.$snotify.success('Unpinned!')
          }
        })
        .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    getDetails () {
      FirmService.get(this.id)
      .then((response) => {
        this.firm = response.data
        this.firmTypeObj = this.allFirmTypes.filter(x => x.typeId === this.firm.typeId)
        this.ownershipIdObj = this.allFirmOwnershipTypes.filter(x => x.typeId === this.firm.ownershipId)
        this.clientTypeIdObj = this.allFirmClientTypes.filter(x => x.typeId === this.firm.clientTypeId)
        this.contactObj = this.allUsers.filter(x => x.userId === this.firm.contactId)
        this.currencyObj = this.allCurrencies.filter(t => t.currencyId === this.firm.currencyId)
      }).then(() => {
        this.fetchFirmContactList()
      }).then(() => {
        FirmService.getFunds(this.id)
        .then((response) => {
          if (response.data !== '') {
            this.relatedFunds = response.data
          } else {
            this.relatedFunds = null
          }
        }).then(() => {
          this.fetchFirmAnnualMeetingList()
        }).then(() => {
          this.fetchActivities()
          if (this.firm.outreachTaskId > 0) {
            this.clickAddActivity()
          }
        }).then(() => {
          this.asyncDataStatus_fetched('Firm Details')
        })
      })
      .catch(error => this.$snotify.error('Server Error! ' + error.message))
    },
    getTables () {
      this.fetchAllFirmTypes()
      this.fetchAllSecOutreachTiers()
      this.fetchAllUsers()
      this.fetchAllFirmClientTypes()
      this.fetchAllFirmOwnershipTypes()
      this.fetchAllCurrencies()
    },
    fetchFirmAnnualMeetingList () {
      FirmAnnualMeetingService.getFirm(this.id)
      .then((response) => {
        if (response.data !== '') {
          this.annualMeetings = response.data
        } else {
          this.annualMeetings = null
        }
      })
    },
    fetchFirmContactList () {
      FirmContactService.getFirm(this.firm.firmId).then(
        (response) => {
          if (response.data.length > 0) {
            this.firmContacts = response.data
          } else {
            this.firmContacts = null
          }
        }
      )
    },
    fetchActivities () {
      ActivityService.getFirmActivities(this.firm.firmId).then(
        (response) => {
          if (response.data.length > 0) {
            this.activities = response.data
          } else {
            this.activities = null
          }
        }
      )
    },
    // typeSelected (value, id) {
    //   this.firm.typeId = value ? value.typeId : null
    // },
    ownershipSelected (value, id) {
      this.firm.ownershipId = value ? value.typeId : null
    },
    clientTypeSelected (value, id) {
      this.firm.clientTypeId = value ? value.typeId : null
    },
    contactSelected (value, id) {
      this.firm.contactId = value ? value.userId : null
    },
    currencySelected (value, id) {
      this.firm.currencyId = value ? value.currencyId : null
    },
    clickAddEmployee () {
      this.$store.commit('setNewFirmContact', this.firm.firmId)
      this.sidebarAction('firmcontact', true, true)
    },
    showEmployee (id) {
      this.fetchFirmContact({firmContactId: id})
      this.sidebarAction('firmcontact', false, true)
    },
    onEmployeeUpdate () {
      this.fetchFirmContactList()
      this.sidebarAction('', false, false)
    },
    showAnnualMeeting (id) {
      this.fetchFirmAnnualMeetings({meetingId: id})
      this.sidebarAction('annualmeeting', false, true)
    },
    editAnnualMeeting (id) {
      this.fetchFirmAnnualMeetings({meetingId: id})
      this.sidebarAction('annualmeeting', false, true, true)
    },
    clickAddAnnualMeeting () {
      this.$store.commit('setNewFirmAnnualMeeting', this.firm.firmId)
      this.sidebarAction('annualmeeting', true, true, true)
    },
    onAnnualMeetingUpdate () {
      this.fetchFirmAnnualMeetingList()
      this.sidebarAction('', false, false)
    },
    showActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true)
    },
    editActivity (id) {
      this.fetchActivity({activityId: id})
      this.sidebarAction('activity', false, true, true)
    },
    clickAddActivity () {
      this.$store.commit('setNewActivity', this.firm.firmId)
      this.sidebarAction('activity', true, true, true)
    },
    onActivityUpdate (objUpdate) {
      this.fetchActivities()
      this.sidebarAction('', false, false)
      if (objUpdate && this.firm.outreachTaskId > 0 && objUpdate.action === 'save') {
        if (objUpdate.completeTask) {
          const outReachObj = {
            taskId: this.firm.outreachTaskId,
            firmId: this.firm.firmId,
            userId: this.firm.contactId ? this.firm.contactId : this.user.userId,
            activityId: objUpdate.activityId
          }
          TaskService.completeTask(this.firm.outreachTaskId, outReachObj).then(
          (response) => {
            if (response.status !== 200) {
              this.$snotify.error('Error!')
            } else {
              this.firm.outreachTaskId = 0
              this.$snotify.success('Changes saved.')
            }
          }
        ).catch(error => this.$snotify.error('Server Error! ' + error.message))
        } else {
          this.$snotify.confirm('Do you want to complete associated task?', '',
            {
              buttons: [
                {
                  text: 'Yes',
                  action: (toast) => {
                    this.$snotify.remove(toast.id)
                    const outReachObj = {
                      taskId: this.firm.outreachTaskId,
                      firmId: this.firm.firmId,
                      userId: this.firm.contactId ? this.firm.contactId : this.user.userId,
                      activityId: objUpdate.activityId
                    }
                    TaskService.completeTask(this.firm.outreachTaskId, outReachObj).then(
                    (response) => {
                      if (response.status !== 200) {
                        this.$snotify.error('Error!')
                      } else {
                        this.firm.outreachTaskId = 0
                        this.$snotify.success('Task completed.')
                      }
                    }
                  ).catch(error => this.$snotify.error('Server Error! ' + error.message))
                  }
                },
                {
                  text: 'No',
                  action: (toast) => {
                    this.$snotify.remove(toast.id)
                  },
                  bold: true
                }
              ]})
        }
      }
    },
    needTrim (val) {
      return val != null && val.length > 0 && val.indexOf('\n') > 0
    },
    trimText (val) {
      while (val.indexOf('\n') > 0) {
        val = val.replace('\n', ' ')
      }
      return val
    }
  },
  created () {
    this.addSidebar = false
    this.hideRightbar()
    this.getTables()
    this.getDetails()
  },
  computed: {
    ...mapGetters({
      'user': 'authUser'
    }),
    allUsers () {
      return this.$store.state.allUsers
    },
    allActiveUsers () {
      return this.allUsers.filter(u => u.isActive || u.userId === this.firm.contactId)
    },
    allFirmTypes () {
      return this.$store.state.allFirmTypes
    },
    allSecOutreachTiers () {
      return this.$store.state.allSecOutreachTiers
    },
    allFirmClientTypes () {
      return this.$store.state.allFirmClientTypes
    },
    allFirmOwnershipTypes () {
      return this.$store.state.allFirmOwnershipTypes
    },
    allCurrencies () {
      return this.$store.state.allCurrencies
    },
    isClient () {
      if (this.firm !== null) {
        return parseInt(this.firm.typeId) === 2
      }
    },
    symbol () {
      return this.currencyObj.length > 0 ? this.currencyObj[0].symbol : this.currencyObj.symbol
    },
    canbeDeleted () {
      return this.relatedFunds == null && this.activities == null
    },
    canbeEdited () {
      return this.user.groupId < 11
    },
    smTabHasError () {
      return this.$v.firm.firmName.$error || this.$v.firm.typeId.$error
    },
    gpTabHasError () {
      return this.$v.firm.overview.$error || this.$v.firm.website.$error
    },
    clTabHasError () {
      return this.$v.firm.wpmEmailAlias.$error || this.$v.firm.abbreviatedClientName.$error || this.$v.firm.clientIPSBenchmark.$error || this.$v.firm.contractNotes.$error || this.$v.firm.percentageCommitmentPercent.$error || this.$v.firm.targetMaxManagerConcentrationPercent.$error || this.$v.firm.targetMaxDealConcentrationPercent.$error
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
